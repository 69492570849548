import React from "react";

// Styles
import "./Footer.scss";

// Components
import { Container, Row, Col } from "react-bootstrap";
import { trackClickHelpCenter } from "instrumentation/tracking/page-tracking-events/DashboardPageTracking";

const Footer = (props) => {
  return (
    <section className={"footer"}>
      <Container>
        <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={"justify-content-center footer-links"}>
            <a href={"https://central.bbot.menu/"} className={"footer-link"} onClick={() => trackClickHelpCenter()}>
              <p>Help Center</p>
            </a>

            {/* ===== COMMENTED OUT PER STEVES REQUEST ===== */}
            {/*<a href={"https://try.bbot.menu/contact"} className={"footer-link"}>*/}
            {/*  <p>Support</p>*/}
            {/*</a>*/}
            {/*<a href={"https://ideas.bbot.menu/"} className={"footer-link"}>*/}
            {/*  <p>Submit an Idea</p>*/}
            {/*</a>*/}
            {/*<a*/}
            {/*  href={"https://roadmap.bbot.menu/roadmap"}*/}
            {/*  className={"footer-link"}*/}
            {/*>*/}
            {/*  <p>Roadmap</p>*/}
            {/*</a>*/}
            {/*<a*/}
            {/*  href={"https://roadmap.bbot.menu/changelogs"}*/}
            {/*  className={"footer-link"}*/}
            {/*>*/}
            {/*  <p>Release Notes</p>*/}
            {/*</a>*/}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
