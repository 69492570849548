import React, { useState, useEffect, useRef } from "react";
import {
  BbotTable,
  Button,
  BbotButton,
  Popover,
  Menu,
  Input,
  Tag,
  Row,
  Col,
  Typography,
  notification,
} from "../../bbot-component-library";
import { formatPrice, generalErrorAlert, generateUUIDv4 } from "../../util/Utils";
import axios from "axios";
import { DateTime } from "luxon";
import styled from "styled-components";
import moment from "moment";
import IssueRefundsModal from "../../components/owner-app/modals/search-past-orders/IssueRefundsModal";
import DateInput from "../../bbot-component-library/form-inputs/DateInput";
import {
  trackAddFilterDropdownClicked,
  trackNewFilterDropdownItemClicked,
  trackNewFilterPopoverSubmitted,
  trackSubmitUpdateFilter,
} from "instrumentation/tracking/page-tracking-events/PastOrdersPageTracking";

const PAGINATION_THRESHOLD = 200;
const PAGINATION_DEFAULTS = {
  current: 1,
  pageSize: 20,
};

const SEARCH_ORDER_FILTERS = {
  startDate: { filterName: "Start Date", filterKey: "start_date" },
  endDate: { filterName: "End Date", filterKey: "end_date" },
  last4: { filterName: "Credit / Gift Card Last 4", filterKey: "last4" },
  cardExpMonth: { filterName: "Credit Card Exp Month", filterKey: "exp_month" },
  cardExpYear: { filterName: "Credit Card Exp Year", filterKey: "exp_year" },
  orderNumber: { filterName: "Order Number", filterKey: "order_number" },
  locationCode: { filterName: "Location", filterKey: "locationShortId" },
  phoneNumber: { filterName: "Phone Number", filterKey: "phone_number" },
};

const PastOrdersLookupTab = (props) => {
  const [orders, setOrders] = useState([]);
  const [searchOrderFilters, setSearchOrderFilters] = useState([
    {
      filterType: "startDate",
      filterName: "Start Date",
      filterValue: DateTime.now().minus({ weeks: 1 }),
    },
    {
      filterType: "endDate",
      filterName: "End Date",
      filterValue: DateTime.now(),
    },
  ]); // controls the filters on the search
  const [orderFilterVisiblity, setOrderFilterVisiblity] = useState({
    startDate: false,
    endDate: false,
    last4: false,
    cardExpMonth: false,
    cardExpYear: false,
    orderNumber: false,
    locationCode: false,
    phoneNumber: false,
  });
  const [newFilterType, setNewFilterType] = useState(null);
  const [showAddFilterPopover, setShowAddFilterPopover] = useState(false);
  const [orderBeingRefunded, setOrderBeingRefunded] = useState(null); // null means we don't show the modal.
  const [orderFees, setOrderFees] = useState([]);
  const [emailPopoverOrderIds, setEmailPopoverOrderids] = useState([]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [currencyData, setCurrencyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [orderQueryPromiseId, setOrderQueryPromiseId] = useState(null);
  const orderQueryPromiseRef = useRef(); // Must use ref to get most up to date id within the callback. https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback

  // Table pagination
  const [paginationData, setPaginationData] = useState(PAGINATION_DEFAULTS);

  const { selectedCustomer, stations } = props;

  orderQueryPromiseRef.current = orderQueryPromiseId;

  useEffect(() => {
    if (!selectedCustomer) return;
    searchForOrders(PAGINATION_DEFAULTS);
    setPaginationData(PAGINATION_DEFAULTS);
  }, [searchOrderFilters, selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchForOrders = async (paginationInfo = paginationData) => {
    setIsLoading(true);

    const filtersByType = Object.fromEntries(
      searchOrderFilters.map((searchOrderFilter) => [
        SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterKey,
        searchOrderFilter.filterType === "startDate" || searchOrderFilter.filterType === "endDate"
          ? searchOrderFilter.filterValue.toUTC().toISO()
          : searchOrderFilter.filterValue,
      ])
    );
    const payload = {
      customer_id: selectedCustomer.customer_id,
      page: paginationInfo?.current,
      page_size: paginationInfo?.pageSize,
      ...filtersByType,
    };

    // set the order query id
    const newOrderQueryId = generateUUIDv4();
    setOrderQueryPromiseId(newOrderQueryId);

    // resolve all promises
    try {
      const res = await axios.get("api/findReceiptsFromOwner", {
        params: payload,
      });

      if (orderQueryPromiseRef.current !== newOrderQueryId) {
        return;
      }

      setOrders(res.data.orders);
      setOrderFees(res.data.orderFees);
      setCurrencyData(res.data.currencyData);
      setPaginationData((paginationData) => ({
        ...paginationData,
        total: res.data.numOrders,
      }));
    } catch (error) {
      generalErrorAlert(error, "Could not look up orders. ", true);
    }
    setIsLoading(false);
  };

  const sendEmailReceipt = async (orderId, email, customerId) => {
    if (email === "") return;

    setIsSendingEmail(true);
    try {
      const payload = {
        email: email,
        order_id: orderId,
        customer_id: customerId,
      };
      await axios.post("api/emailReceiptFromOwner", payload);
      notification.success({ message: "Sent receipt email to " + email });
      toggleEmailPopoverVisiblity(orderId, false);
    } catch (error) {
      generalErrorAlert(error, "Could not send email receipt.");
    }
    setIsSendingEmail(false);
  };

  const onTableChange = (pagination, filters, sorter) => {
    if (pagination.current && pagination.total > PAGINATION_THRESHOLD) {
      // THIS MUST MATCH THRESHOLD IN VIEWS_FOR_OWNERS.PY IN ENDPOINT
      searchForOrders(pagination);
    }
    setPaginationData(pagination);
  };

  const toggleFilterVisibility = (filterType, visible) => {
    setOrderFilterVisiblity((orderFilterVisibility) => ({
      ...orderFilterVisibility,
      [filterType]: visible,
    }));
  };

  const removeFilter = (filterType) => {
    setSearchOrderFilters((searchOrderFilters) =>
      searchOrderFilters.filter((searchOrderFilter) => searchOrderFilter.filterType !== filterType)
    );
  };

  const toggleEmailPopoverVisiblity = (orderId, visible) => {
    setEmailPopoverOrderids((emailPopoverOrderIds) => {
      if (visible) {
        return [...emailPopoverOrderIds, orderId];
      } else {
        return emailPopoverOrderIds.filter((emailPopoverOrderId) => emailPopoverOrderId !== orderId);
      }
    });
  };

  const addFilter = (filterType, filterValue) => {
    setSearchOrderFilters((searchOrderFilters) => {
      return [
        ...searchOrderFilters,
        {
          filterType: filterType,
          filterValue: filterValue,
        },
      ];
    });
  };

  const updateFilter = (filterType, filterValue) => {
    setSearchOrderFilters((searchOrderFilters) =>
      searchOrderFilters.map((searchOrderFilter) =>
        searchOrderFilter.filterType === filterType
          ? { filterType: filterType, filterValue: filterValue }
          : searchOrderFilter
      )
    );
  };

  const addFilterContent = () => {
    if (newFilterType) {
      return (
        <div className={"padding-y-2 padding-x-1"}>
          <StyledSearch
            placeholder={SEARCH_ORDER_FILTERS[newFilterType].filterName}
            onSearch={(newFilterValue) => {
              if (newFilterValue.length === 0) return;
              addFilter(newFilterType, newFilterValue);
              setShowAddFilterPopover(false);
              setNewFilterType(null);
              trackNewFilterPopoverSubmitted({ filter_type: newFilterType, filter_value: newFilterValue });
            }}
            data-test-id={`add-filter-input-${SEARCH_ORDER_FILTERS[newFilterType].filterKey}`}
            enterButton={
              <StyledSearchButton
                type={"primary"}
                data-test-id={`add-filter-button-${SEARCH_ORDER_FILTERS[newFilterType].filterKey}`}
                className={"ant-input-search-button"}
              >
                Add
              </StyledSearchButton>
            }
          />
        </div>
      );
    }

    const existingFilterTypes = searchOrderFilters.map((searchOrderFilter) => searchOrderFilter.filterType);
    const filtersToShow = Object.keys(SEARCH_ORDER_FILTERS).filter(
      (searchOrderFilterType) => !existingFilterTypes.includes(searchOrderFilterType)
    );

    return (
      <Menu
        onClick={(newFilter) => {
          setNewFilterType(newFilter.key);
          trackNewFilterDropdownItemClicked({ filter_type: newFilter.key });
        }}
      >
        {filtersToShow.map((filterType) => (
          <Menu.Item key={filterType} data-test-id={`new-filter-${SEARCH_ORDER_FILTERS[filterType].filterKey}`}>
            {SEARCH_ORDER_FILTERS[filterType].filterName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const editFilterContent = (searchOrderFilter) => {
    if (searchOrderFilter.filterType === "startDate" || searchOrderFilter.filterType === "endDate")
      return (
        <div className={"padding-y-2 padding-x-1"}>
          <DateInput
            showTime
            defaultValue={moment(searchOrderFilter.filterValue.toISO())}
            onOk={(newDate) => {
              updateFilter(searchOrderFilter.filterType, DateTime.fromISO(newDate.toISOString()));
              toggleFilterVisibility(searchOrderFilter.filterType, false);
              trackSubmitUpdateFilter({
                filter_type: searchOrderFilter.filterType,
                filter_value: newDate.toISOString(),
              });
            }}
          />
        </div>
      );

    return (
      <div className={"padding-y-2 padding-x-1"}>
        <StyledSearch
          defaultValue={searchOrderFilter.filterValue}
          onSearch={(newFilterValue) => {
            if (newFilterValue.length === 0) return;
            updateFilter(searchOrderFilter.filterType, newFilterValue);
            toggleFilterVisibility(searchOrderFilter.filterType, false);
            trackSubmitUpdateFilter({ filter_type: searchOrderFilter.filterType, filter_value: newFilterValue });
          }}
          placeholder={SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterName}
          data-test-id={`edit-filter-input-${SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterKey}`}
          enterButton={
            <StyledSearchButton
              type={"primary"}
              data-test-id={`save-edit-filter-${SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterKey}`}
            >
              Save
            </StyledSearchButton>
          }
        />
      </div>
    );
  };

  const emailReceiptContent = (orderId, customerId) => {
    return (
      <div className={"padding-y-2 padding-x-1"}>
        <StyledSearch
          placeholder={"Guest Email"}
          onSearch={(email) => {
            sendEmailReceipt(orderId, email, customerId);
          }}
          enterButton={"Send"}
          loading={isSendingEmail}
        />
      </div>
    );
  };

  const orderFilters = () => {
    return (
      <div className={"margin-top-4 margin-bottom-2"}>
        {searchOrderFilters.map((searchOrderFilter) => {
          const isDateFilter =
            searchOrderFilter.filterType === "startDate" || searchOrderFilter.filterType === "endDate";

          return (
            <Popover
              trigger={"click"}
              placement={"bottom"}
              title={`Update ${SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterName} Filter`}
              content={() => editFilterContent(searchOrderFilter)}
              visible={orderFilterVisiblity[searchOrderFilter.filterType]}
              onVisibleChange={(visible) => toggleFilterVisibility(searchOrderFilter.filterType, visible)}
              overlayInnerStyle={{
                borderRadius: 8,
              }}
              key={`edit-filter-${SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterKey}`}
            >
              <Filter
                className={"cursor-pointer margin-right-2"}
                closable={!isDateFilter}
                onClose={() => removeFilter(searchOrderFilter.filterType)}
                color={"blue"}
                data-test-id={`edit-filter-${SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterKey}`}
              >
                {SEARCH_ORDER_FILTERS[searchOrderFilter.filterType].filterName}:{" "}
                {isDateFilter
                  ? searchOrderFilter.filterValue.toLocaleString(DateTime.DATETIME_SHORT)
                  : searchOrderFilter.filterValue}
              </Filter>
            </Popover>
          );
        })}

        <Popover
          trigger={"click"}
          placement={"bottom"}
          title={newFilterType ? `Add ${SEARCH_ORDER_FILTERS[newFilterType].filterName} Filter` : "Add Filter"}
          content={addFilterContent}
          visible={showAddFilterPopover}
          onVisibleChange={(visible) => {
            setShowAddFilterPopover(visible);
            setNewFilterType(null);
          }}
          overlayInnerStyle={{
            borderRadius: 8,
          }}
        >
          <Filter
            className={"cursor-pointer"}
            data-test-id={"add-filter"}
            onClick={() => trackAddFilterDropdownClicked()}
          >
            + Add Filter
          </Filter>
        </Popover>
      </div>
    );
  };

  /**
   * Groups modifiers in the item by id, attaches quantity
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce
   * @param item
   */
  const groupModsByQuantity = (modifiers) => {
    return modifiers.reduce((mods, currentMod) => {
      const modIds = mods.map((mod) => mod.id);
      // if currentMod is already in aggregating array, increment the quantity
      if (modIds.includes(currentMod.id)) {
        return mods.map((mod) => (mod.id === currentMod.id ? { ...mod, quantity: mod.quantity + 1 } : mod));
      } else {
        // currentMod is new, add it to the aggregating array with quantity = 1
        return [...mods, { ...currentMod, quantity: 1 }];
      }
    }, []);
  };

  const formatCurrency = (amountCents) => {
    return formatPrice(amountCents, currencyData.currency, currencyData.locale, currencyData.centsMultiplier);
  };

  const getGiftCardCharges = (order) => {
    const orderItemsInThisOrder = order.items.map((item) => item.orderitemid);
    const orderFeesInThisOrder = order.order_fees.map((fee) => fee.id);
    return order.charges
      .filter((charge) => charge.tender_type === "gift_card")
      .map((charge) => {
        const totalAmountForOrderItems = charge.item_charge_fragments
          .filter((fragment) => orderItemsInThisOrder.includes(fragment.orderitem_id))
          .reduce((total, fragment) => total + fragment.total_cents, 0);

        const totalAmountForOrderFees = charge.fee_charge_fragments
          .filter((fragment) => orderFeesInThisOrder.includes(fragment.orderfee_id))
          .reduce((total, fragment) => total + fragment.total_cents, 0);

        return {
          giftCardDisplay: `Gift Card ${charge.gift_card_last4}`,
          totalAmountForOrder: totalAmountForOrderItems + totalAmountForOrderFees,
        };
      });
  };

  const calculateTotalGiftCardAmountAuthorized = (chargesFromGiftCards) => {
    return chargesFromGiftCards.reduce((total, charge) => total + charge.totalAmountForOrder, 0);
  };

  const orderDetails = (order) => {
    const relevantOrderFees = orderFees.filter((fee) => fee.primary_order === order.orderId);
    const includesFullRefundInOrder = order.line_item_summaries.every(
      (summary) => summary.refund_status === "full_refund"
    );
    const includesPartialRefundInOrder = order.line_item_summaries.some(
      (summary) => summary.refund_status === "partial_refund"
    );
    const chargesFromGiftCards = getGiftCardCharges(order);
    const totalGiftCardAmountAuthorized = calculateTotalGiftCardAmountAuthorized(chargesFromGiftCards);

    return (
      <div>
        <OrderDetailsContainer>
          {order.line_item_summaries?.map(
            ({
              quantity,
              name_for_customer,
              total_pretax_cents,
              total_pretax_cents_before_refunds,
              refund_status,
              mods,
              special_instructions,
            }) => (
              <div key={order.id}>
                <Row className={"full-width"}>
                  <ItemNameCol span={1}>{quantity}</ItemNameCol>
                  <Col span={1}>x</Col>
                  <ItemNameCol span={16}>{name_for_customer}</ItemNameCol>

                  <ItemPriceCol md={6}>
                    {refund_status === "full_refund" ? (
                      <span>
                        <Typography.Text delete className={"margin-right-1"}>
                          {formatCurrency(total_pretax_cents_before_refunds)}
                        </Typography.Text>
                        <span>REFUNDED</span>
                      </span>
                    ) : (
                      <span>
                        {total_pretax_cents < total_pretax_cents_before_refunds && (
                          <Typography.Text delete className={"margin-right-1"}>
                            {formatCurrency(total_pretax_cents_before_refunds)}
                          </Typography.Text>
                        )}
                        <span>{formatCurrency(total_pretax_cents)}</span>
                      </span>
                    )}
                  </ItemPriceCol>
                </Row>

                {mods.length > 0 &&
                  groupModsByQuantity(mods).map((modifier) => (
                    <Row>
                      <Col span={1}>{modifier.quantity > 1 ? modifier.quantity : ""}</Col>
                      <Col span={1}>{modifier.quantity > 1 ? "x" : ""}</Col>
                      <Col span={22}>{modifier.name}</Col>
                    </Row>
                  ))}

                {special_instructions && (
                  <Row>
                    <Col span={1} />
                    <Col span={1} />
                    <Col span={22}>{special_instructions}</Col>
                  </Row>
                )}

                <hr />
              </div>
            )
          )}
          {relevantOrderFees.map((fee) => (
            <FeeTaxRow>
              <span>
                {fee.name_for_customer}{" "}
                {fee.status === "refunded" && (
                  <PastOrdersLookupTab.AfterRefundLabel>(after refund)</PastOrdersLookupTab.AfterRefundLabel>
                )}
              </span>
              <span>{formatCurrency(fee.status === "refunded" ? 0 : fee.pretax_cents)}</span>
            </FeeTaxRow>
          ))}
          <FeeTaxRow>
            <span>
              Tax{" "}
              {(includesFullRefundInOrder || includesPartialRefundInOrder) && (
                <PastOrdersLookupTab.AfterRefundLabel>(after refund)</PastOrdersLookupTab.AfterRefundLabel>
              )}
            </span>
            <span>
              <span>{formatCurrency(order.tax_cents)}</span>
            </span>
          </FeeTaxRow>
          <FeeTaxRow>
            <span>
              Tip{" "}
              {(includesFullRefundInOrder || includesPartialRefundInOrder) && (
                <PastOrdersLookupTab.AfterRefundLabel>(after refund)</PastOrdersLookupTab.AfterRefundLabel>
              )}
            </span>
            <span>
              <span>{formatCurrency(order.tip_cents)}</span>
            </span>
          </FeeTaxRow>

          <hr />

          {chargesFromGiftCards.map((giftCardCharge) => (
            <FeeTaxRow className={"gift-card-row"} key={giftCardCharge.id}>
              <span>
                {giftCardCharge.giftCardDisplay}{" "}
                {(includesFullRefundInOrder || includesPartialRefundInOrder) && (
                  <PastOrdersLookupTab.AfterRefundLabel>(after refund)</PastOrdersLookupTab.AfterRefundLabel>
                )}
              </span>
              <span>
                <span>-{formatCurrency(giftCardCharge.totalAmountForOrder)}</span>
              </span>
            </FeeTaxRow>
          ))}
          <FeeTaxRow className={"total-row"}>
            <span>
              {chargesFromGiftCards.length ? "Total Paid by Customer" : "Order Total"}{" "}
              {(includesFullRefundInOrder || includesPartialRefundInOrder) && (
                <PastOrdersLookupTab.AfterRefundLabel>(after refund)</PastOrdersLookupTab.AfterRefundLabel>
              )}
            </span>
            <span>
              <span>{formatCurrency(Math.max(order.total_cents - totalGiftCardAmountAuthorized, 0))}</span>
            </span>
          </FeeTaxRow>

          <div className={"margin-y-1"}>
            <Popover
              trigger={"click"}
              placement={"bottom"}
              title={"Send Receipt Email"}
              content={() => emailReceiptContent(order.orderId, order.customerId)}
              visible={emailPopoverOrderIds.includes(order.orderId)}
              onVisibleChange={(visible) => {
                toggleEmailPopoverVisiblity(order.orderId, visible);
              }}
              overlayInnerStyle={{
                borderRadius: 8,
              }}
            >
              <BbotButton
                type={"primary"}
                className={"margin-right-2"}
                icon={<span className={"material-icons-outlined"}>email</span>}
              >
                Email Receipt
              </BbotButton>
            </Popover>
            <BbotButton onClick={() => setOrderBeingRefunded(order)}>Issue Refunds</BbotButton>
          </div>
        </OrderDetailsContainer>
      </div>
    );
  };

  const ordersTable = () => {
    return (
      <BbotTable
        id={"orders-table"}
        interactive={false}
        columns={["Order Number", "Account Name", "Date", "Location", "Last 4", "Total Charge", "Total Items"]}
        data={orders.map((order) => ({
          key: order.orderId,
          order_number: (
            <span>
              <PastOrdersLookupTab.OrderNumberWithPrefix>
                {order.orderNumberWithPrefix}
              </PastOrdersLookupTab.OrderNumberWithPrefix>
              {order.order_refund_status === "fully_refunded" && (
                <PastOrdersLookupTab.RefundLabel data-type="full_refund">
                  Fully Refunded
                </PastOrdersLookupTab.RefundLabel>
              )}
              {order.order_refund_status === "partially_refunded" && (
                <PastOrdersLookupTab.RefundLabel data-type="partial_refund">
                  Partially Refunded
                </PastOrdersLookupTab.RefundLabel>
              )}
            </span>
          ),
          account_name: order.customerName,
          date: DateTime.fromISO(order.time).toLocaleString(DateTime.DATETIME_SHORT),
          location: order.locationShortCode,
          last_4: order.charges[0]?.card_info.last4 || order.charges[0]?.gift_card_last4,
          total_charge: formatPrice(
            order.total_cents,
            currencyData?.currency,
            currencyData?.locale,
            currencyData?.centsMultiplier
          ),
          total_items: order.items.length,
          orderInfo: order,
        }))}
        pagination={paginationData}
        onChange={onTableChange}
        loading={isLoading}
        expandable={{
          expandedRowRender: (order) => orderDetails(order.orderInfo),
        }}
      />
    );
  };

  return (
    <div>
      {orderFilters()}
      {ordersTable()}

      <IssueRefundsModal
        onCancelCallback={() => setOrderBeingRefunded(null)}
        onSuccessCallback={searchForOrders}
        order={orderBeingRefunded}
        orderFees={orderFees}
        currencyData={currencyData}
        groupModsByQuantity={groupModsByQuantity}
        stations={stations}
      />
    </div>
  );
};

export default PastOrdersLookupTab;

const Filter = styled(Tag)`
  display: inline-flex;
  align-items: center;
  padding: 8px;
  font-size: 1rem;
  border-radius: 8px;
`;

const StyledSearch = styled(Input.Search)``;

const StyledSearchButton = styled(Button)`
  background-color: var(--color-primary__regular);
`;

const OrderDetailsContainer = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FeeTaxRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  &.total-row {
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

const ItemPriceCol = styled(Col)`
  text-align: right;
`;

const ItemNameCol = styled(Col)`
  font-weight: 700;
  font-size: 14px;
`;

PastOrdersLookupTab.AfterRefundLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  font-style: italic;
`;

PastOrdersLookupTab.OrderNumberWithPrefix = styled.span``;

PastOrdersLookupTab.RefundLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  font-style: italic;
  background-color: #ccc;
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;

  &[data-type="full_refund"] {
    color: var(--color-success__dark);
    background-color: var(--color-success__light);
  }

  &[data-type="partial_refund"] {
    color: var(--color-warning__dark);
    background-color: var(--color-warning__light);
  }
`;
