import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";

// Components
import {
  Button, // used for link
  Checkbox,
  Form,
  notification,
  Radio,
  Tooltip,
  Typography,
  BbotButton,
  BbotModal,
  NumberInput,
  SelectInput,
  TextInput,
  Col,
  Row,
} from "bbot-component-library";

// Utils
import { formatPrice, generalErrorAlert, generateUUIDv4 } from "util/Utils";

const REFUND_MODAL_STATES = {
  refundTypeAndSelectItems: "select_type_and_items",
  refundFull: "refund_full",
  refundPartial: "refund_partial",
};

const REFUND_MODE = {
  full: "refund_full",
  partialAmount: "refund_partial_amount",
  partialPercent: "refund_partial_percent",
};

const DEFAULT_REFUND_OPTIONS = [
  { label: "Out of Stock", value: "Out of Stock" },
  { label: "Guest Request", value: "Guest Request" },
  { label: "Closing Soon", value: "Closing Soon" },
  { label: "Test Order", value: "Test Order" },
];

const ALERT_MESSAGE = {
  percentage: "Please enter a percentage that is less than 100%. Full refunds can be selected on the previous page.",
  dollar: "Please enter an amount that is less than the total. Full refunds can be selected on the previous page.",
};

const IssueRefundsModal = (props) => {
  const { onCancelCallback, order, currencyData, onSuccessCallback, groupModsByQuantity, stations, orderFees } = props;

  const [refundModalState, setRefundModalState] = useState(REFUND_MODAL_STATES.refundTypeAndSelectItems);
  const [refundMode, setRefundMode] = useState(REFUND_MODE.full);
  const [, setFormValues] = useState({});
  const [refundsForm] = Form.useForm();
  const [idempotencyToken, setIdempotencyToken] = useState(generateUUIDv4());
  const [refundReasonOptions, setRefundReasonOptions] = useState([]);
  const [promptRefundReason, setPromptRefundReason] = useState("");
  const [isSubmittingRefund, setIsSubmittingRefund] = useState(false);
  const [isSubmittingTaxRefund, setIsSubmittingTaxRefund] = useState(false);

  // Fees
  const feesInOrder = orderFees.filter((fee) => fee.primary_order === order?.orderId) ?? [];
  const [refundFeeIndexesChecked, setRefundFeeIndexesChecked] = useState([]);
  const selectedFees = feesInOrder?.filter((orderFee, index) => refundFeeIndexesChecked[index]) || [];
  const selectedFeesPretaxTotal = selectedFees.reduce((total, currentFee) => total + currentFee.pretax_cents, 0);
  const selectedFeesTaxTotal = selectedFees.reduce((total, currentFee) => total + currentFee.tax_cents, 0);

  // Items
  const itemsInOrder = order?.items ?? [];
  const [refundItemIndexesChecked, setRefundItemIndexesChecked] = useState([]);
  const selectedItems = itemsInOrder?.filter((orderItem, index) => refundItemIndexesChecked[index]) || [];
  const selectedItemsPretaxTotal = selectedItems.reduce((total, currentItem) => total + currentItem.pretax_cents, 0);
  const selectedItemsTaxTotal = selectedItems.reduce((total, currentItem) => total + currentItem.tax_cents, 0);
  const selectedItemsTipsTotal = selectedItems.reduce((total, currentItem) => total + currentItem.tip_in_cents, 0);

  const getSetRefundSettings = useCallback(() => {
    if (!order) return;
    // Initially set refundReasonOptions to default choices and promptRefundReason to optional
    setRefundReasonOptions(DEFAULT_REFUND_OPTIONS);
    setPromptRefundReason("optional");

    stations.forEach((station) => {
      // If order station is in possible stations
      if (order.bartending_station_id === station.id) {
        setRefundReasonOptions(
          station.possible_refund_reasons.map((reason) => ({ label: reason.reason, value: reason.reason }))
        );
        setPromptRefundReason(station.prompt_for_refund_reason);
      }
    });
  }, [order, stations]);

  //on modal open, get and set refund reason settings
  useEffect(() => {
    if (order) {
      getSetRefundSettings();
    }
  }, [order, getSetRefundSettings]);

  const numItemsInOrder = itemsInOrder.length;

  useEffect(() => {
    if (numItemsInOrder > 0) {
      setRefundItemIndexesChecked([...Array(numItemsInOrder)].map((i) => false));
    }
  }, [numItemsInOrder]);

  const numFeesInOrder = feesInOrder.length;

  useEffect(() => {
    if (numFeesInOrder > 0) {
      setRefundFeeIndexesChecked([...Array(numFeesInOrder)].map((i) => false));
    }
  }, [numFeesInOrder]);

  useEffect(() => {
    refundsForm.setFieldsValue({
      percentOfSales: 0,
      percentOfTips: 0,
      amountOfSales: 0,
      amountOfTips: 0,
    });
  }, [refundsForm]);

  const onCancel = () => {
    refundsForm.setFieldsValue({
      percentOfSales: 0,
      percentOfTips: 0,
      amountOfSales: 0,
      amountOfTips: 0,
    });
    onCancelCallback();
  };

  const submitRefund = async (refundFormValues) => {
    if (refundMode === REFUND_MODE.full) {
      await refundItems(refundFormValues);
      return;
    }

    setIsSubmittingRefund(true);
    try {
      const payload = {
        order_id: order.orderId,
        idempotency_token: idempotencyToken,
        sale_cents_to_add: refundMode === REFUND_MODE.partialAmount ? -100 * refundFormValues.amountOfSales : 0,
        sale_fraction_multiplier:
          refundMode === REFUND_MODE.partialPercent ? 1 - refundFormValues.percentOfSales * 0.01 : 1,
        tip_cents_to_add: refundMode === REFUND_MODE.partialAmount ? -100 * refundFormValues.amountOfTips : 0,
        tip_fraction_multiplier:
          refundMode === REFUND_MODE.partialPercent ? 1 - refundFormValues.percentOfTips * 0.01 : 1,
        reason: refundFormValues.refundReason
          ? refundFormValues.refundReason === "Other"
            ? refundFormValues.customRefundReason
            : refundFormValues.refundReason
          : "Owner portal refund",
        items_to_refund: selectedItems.map((item) => item.orderitemid),
      };
      await axios.post("/api/ownerPartialRefund", payload);
      setIdempotencyToken(generateUUIDv4());
      onSuccessCallback();
      onCancel();
      notification.success({
        message: `Successfully refunded ${selectedItems.length} ${selectedItems.length === 1 ? " item." : " items."}`,
      });
      setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems);
    } catch (error) {
      generalErrorAlert(error, "Could not refund items.", true);
    }
    setIsSubmittingRefund(false);
  };

  const refundItems = async (refundFormValues) => {
    setIsSubmittingRefund(true);
    try {
      const payload = {
        itemIdsToRefund: selectedItems.map((item) => item.orderitemid),
        feeIdsToRefund: selectedFees.map((fee) => fee.id),
        reason: refundFormValues.refundReason
          ? refundFormValues.refundReason === "Other"
            ? refundFormValues.customRefundReason
            : refundFormValues.refundReason
          : "Owner portal refund",
      };
      await axios.post("/api/ownerRefundItems", payload);
      setIdempotencyToken(generateUUIDv4());
      onSuccessCallback();
      onCancel();
      notification.success({
        message: `Successfully refunded ${selectedItems.length} ${selectedItems.length === 1 ? " item" : " items"} ${
          selectedFees.length === 0
            ? "."
            : ` and ${selectedFees.length} ${selectedFees.length === 1 ? " fee." : " fees."}`
        }`,
      });
      setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems);
    } catch (error) {
      generalErrorAlert(error, "Could not refund items.", true);
    }
    setIsSubmittingRefund(false);
  };

  const refundTax = async () => {
    setIsSubmittingTaxRefund(true);
    try {
      const payload = {
        itemIdsToRefund: selectedItems.map((item) => item.orderitemid),
      };
      const res = await axios.post("/api/ownerRefundTax", payload);
      setIdempotencyToken(generateUUIDv4());
      onSuccessCallback();
      if (res.data.numRefunded === 0) {
        notification.warning({
          message: "The selected items have no tax to refund.",
        });
      } else {
        notification.success({
          message: `Successfully refunded tax on ${selectedItems.length} ${
            selectedItems.length === 1 ? " item" : " items"
          } ${
            selectedFees.length === 0
              ? "."
              : ` and ${selectedFees.length} ${selectedFees.length === 1 ? " fee." : " fees."}`
          }`,
        });
      }
      onCancel();
      setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems);
    } catch (error) {
      generalErrorAlert(error, "Could not refund tax.", true);
    }
    setIsSubmittingTaxRefund(false);
  };

  const toggleRefundItemIndexCheck = (index, checked) => {
    setRefundItemIndexesChecked((refundItemIndexesChecked) =>
      refundItemIndexesChecked.map((isItemChecked, i) => {
        return i === index ? checked : isItemChecked;
      })
    );
  };

  const toggleRefundFeeIndexCheck = (index, checked) => {
    setRefundFeeIndexesChecked((refundFeeIndexesChecked) =>
      refundFeeIndexesChecked.map((isFeeChecked, i) => {
        return i === index ? checked : isFeeChecked;
      })
    );
  };

  const selectRefundTypeAndItemsContent = () => {
    // If any non fully refunded items are unchecked, true, otherwise false
    const selectAllMode =
      selectedItems.filter((item) => item.status !== "refunded").length !==
      itemsInOrder.filter((item) => item.status !== "refunded").length;

    return (
      <div>
        <div>
          <div className={"margin-bottom-2"}>
            <RefundTypeSelector
              buttonStyle={"solid"}
              value={refundMode}
              onChange={(e) => setRefundMode(e.target.value)}
            >
              <Radio.Button value={REFUND_MODE.full}>Full Refund</Radio.Button>
              <Radio.Button value={REFUND_MODE.partialPercent}>Partial Refund</Radio.Button>
            </RefundTypeSelector>
          </div>
          <Row className={"full-width margin-bottom-1"}>
            <Col>
              <Button
                disabled={itemsInOrder.every((item) => item.status === "refunded")}
                className={"padding-0"}
                type={"link"}
                onClick={() => {
                  setRefundItemIndexesChecked((refundItemIndexesChecked) =>
                    refundItemIndexesChecked.map((itemWasChecked, index) =>
                      itemsInOrder[index]?.status === "refunded" ? itemWasChecked : selectAllMode
                    )
                  );
                  refundMode === REFUND_MODE.full &&
                    feesInOrder &&
                    setRefundFeeIndexesChecked((refundFeeIndexesChecked) =>
                      refundFeeIndexesChecked.map((feeWasChecked, index) =>
                        feesInOrder[index]?.status === "refunded" ? feeWasChecked : selectAllMode
                      )
                    );
                }}
              >{`${selectAllMode ? "Select" : "Unselect"} All`}</Button>
            </Col>
          </Row>
          {itemsInOrder.map((item, index) => (
            <div key={`item-index__${index}`}>
              <Row className={"full-width"}>
                <Col span={2}>
                  <Checkbox
                    disabled={item.status === "refunded"}
                    checked={refundItemIndexesChecked[index]}
                    onChange={(e) => toggleRefundItemIndexCheck(index, e.target.checked)}
                  />
                </Col>

                <ItemNameCol span={14}>{item.name_for_customer}</ItemNameCol>

                <ItemPriceCol md={8}>
                  {item.status === "refunded" ? (
                    <span>
                      <Typography.Text delete className={"margin-right-1"}>
                        {formatPrice(
                          item.orig_pretax_cents,
                          currencyData.currency,
                          currencyData.locale,
                          currencyData.centsMultiplier
                        )}
                      </Typography.Text>
                      <span>REFUNDED</span>
                    </span>
                  ) : (
                    <span>
                      {item.pretax_cents < item.orig_pretax_cents && (
                        <Typography.Text delete className={"margin-right-1"}>
                          {formatPrice(
                            item.orig_pretax_cents,
                            currencyData.currency,
                            currencyData.locale,
                            currencyData.centsMultiplier
                          )}
                        </Typography.Text>
                      )}
                      <span>
                        {formatPrice(
                          item.pretax_cents,
                          currencyData.currency,
                          currencyData.locale,
                          currencyData.centsMultiplier
                        )}
                      </span>
                    </span>
                  )}
                </ItemPriceCol>
              </Row>

              {item.mods.length > 0 &&
                groupModsByQuantity(item.mods).map((mod, index) => (
                  <Row key={index}>
                    <Col span={2} />
                    <Col span={20}>{`${mod.quantity > 1 ? mod.quantity + " x " : ""}${mod.name}`}</Col>
                  </Row>
                ))}

              <hr />
            </div>
          ))}
          {refundMode === REFUND_MODE.full &&
            feesInOrder.map((fee, index) => (
              <div key={`fee id: ${fee.id}`}>
                <Row className={"full-width"}>
                  <Col span={2}>
                    <Checkbox
                      disabled={fee.status === "refunded"}
                      checked={refundFeeIndexesChecked[index]}
                      onChange={(e) => toggleRefundFeeIndexCheck(index, e.target.checked)}
                    />
                  </Col>

                  <ItemNameCol span={14}>{fee.name_for_customer}</ItemNameCol>

                  <ItemPriceCol span={8}>
                    {fee.status === "refunded" ? (
                      <span>
                        <Typography.Text delete className={"margin-right-1"}>
                          {formatPrice(
                            fee.pretax_cents,
                            currencyData.currency,
                            currencyData.locale,
                            currencyData.centsMultiplier
                          )}
                        </Typography.Text>
                        <span>REFUNDED</span>
                      </span>
                    ) : (
                      <span>
                        {formatPrice(
                          fee.pretax_cents,
                          currencyData.currency,
                          currencyData.locale,
                          currencyData.centsMultiplier
                        )}
                      </span>
                    )}
                  </ItemPriceCol>
                </Row>

                <hr />
              </div>
            ))}
        </div>
      </div>
    );
  };

  const refundFullContent = () => {
    return (
      <div>
        <div>
          <Form
            form={refundsForm}
            name={"items-percent-form"}
            initialValues={{
              refundReason: null,
              customRefundReason: "",
            }}
            layout={"vertical"}
            onFinish={submitRefund}
            onValuesChange={(_, values) => setFormValues(values)}
          >
            {(promptRefundReason === "optional" || promptRefundReason === "required") && (
              <Row>
                <Col span={24}>
                  <StyledSelectInput
                    allowClear
                    name={"refundReason"}
                    label={"Refund Reason"}
                    placeholder={"Select a Refund Reason"}
                    required={promptRefundReason === "required"}
                    options={refundReasonOptions.concat([{ label: "Other", value: "Other" }])}
                  />
                </Col>
              </Row>
            )}
            {refundsForm.getFieldValue("refundReason") === "Other" && (
              <Row>
                <Col span={24}>
                  <TextInput //todo: fix the weird nested input fields here
                    name={"customRefundReason"}
                    label={"Custom Refund Reason"}
                    placeholder={"Enter a Custom Refund Reason"}
                    required={true}
                  />
                </Col>
              </Row>
            )}
          </Form>
        </div>

        <FeeTaxRow>
          <span>Total Items Pre-Tax Refund</span>
          <span>
            {formatPrice(
              selectedItemsPretaxTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>

        <FeeTaxRow>
          <span>Total Items Tax Refund</span>
          <span>
            {formatPrice(
              selectedItemsTaxTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>

        <hr />

        {selectedFees.length > 0 && (
          <FeeTaxRow>
            <span>Total Fees Pre-Tax Refund</span>
            <span>
              {formatPrice(
                selectedFeesPretaxTotal,
                currencyData?.currency,
                currencyData?.locale,
                currencyData?.centsMultiplier
              )}
            </span>
          </FeeTaxRow>
        )}

        {selectedFees.length > 0 && (
          <FeeTaxRow>
            <span>Total Fees Tax Refund</span>
            <span>
              {formatPrice(
                selectedFeesTaxTotal,
                currencyData?.currency,
                currencyData?.locale,
                currencyData?.centsMultiplier
              )}
            </span>
          </FeeTaxRow>
        )}

        {selectedFees.length > 0 && <hr />}

        <FeeTaxRow>
          <span>Total Tips Refund</span>
          <span>
            {formatPrice(
              selectedItemsTipsTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>

        <hr />

        <FeeTaxRow className={"total-row"}>
          <span>Refund Total</span>
          <span>
            {formatPrice(
              selectedItemsPretaxTotal +
                selectedItemsTaxTotal +
                selectedFeesPretaxTotal +
                selectedItemsTipsTotal +
                selectedFeesTaxTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
      </div>
    );
  };

  const refundPartialContent = () => {
    const refundSalesAdjustedTotal =
      refundMode === REFUND_MODE.partialPercent
        ? selectedItemsPretaxTotal * refundsForm.getFieldValue("percentOfSales") * 0.01
        : refundsForm.getFieldValue("amountOfSales") * 100;
    const refundTipsAdjustedTotal =
      refundMode === REFUND_MODE.partialPercent
        ? selectedItemsTipsTotal * refundsForm.getFieldValue("percentOfTips") * 0.01
        : refundsForm.getFieldValue("amountOfTips") * 100;
    const refundTaxAdjustedTotal =
      refundMode === REFUND_MODE.partialPercent
        ? selectedItemsTaxTotal * refundsForm.getFieldValue("percentOfSales") * 0.01
        : ((refundsForm.getFieldValue("amountOfSales") * 100) / selectedItemsPretaxTotal) * selectedItemsTaxTotal;

    return (
      <div>
        <div className={"margin-bottom-2"}>
          <RefundTypeSelector
            defaultValue={REFUND_MODE.partialPercent}
            buttonStyle={"solid"}
            value={refundMode}
            onChange={(e) => setRefundMode(e.target.value)}
          >
            <Radio.Button value={REFUND_MODE.partialPercent}>Partial Refund By %</Radio.Button>
            <Radio.Button value={REFUND_MODE.partialAmount}>Partial Refund By $</Radio.Button>
          </RefundTypeSelector>
        </div>

        <div>
          <Form
            form={refundsForm}
            name={"items-percent-form"}
            initialValues={{
              percentOfSales: 0,
              percentOfTips: 0,
              amountOfSales: 0,
              amountOfTips: 0,
              refundReason: null,
              customRefundReason: "",
            }}
            layout={"vertical"}
            onFinish={submitRefund}
            onValuesChange={(_, values) => setFormValues(values)}
          >
            {refundMode === REFUND_MODE.partialPercent ? (
              <Row gutter={12}>
                <Col span={12}>
                  <NumberInput
                    name={"percentOfSales"}
                    label={"Percent Of Sales To Refund"}
                    required
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: ALERT_MESSAGE.percentage,
                      },
                      {
                        type: "number",
                        max: 99,
                        message: ALERT_MESSAGE.percentage,
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <NumberInput
                    name={"percentOfTips"}
                    label={"Percent Of Tips To Refund"}
                    required
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: ALERT_MESSAGE.percentage,
                      },
                      {
                        type: "number",
                        max: 99,
                        message: ALERT_MESSAGE.percentage,
                      },
                    ]}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={12}>
                <Col span={12}>
                  <NumberInput
                    name={"amountOfSales"}
                    label={"Amount Of Sales To Refund"}
                    required
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: ALERT_MESSAGE.dollar,
                      },
                      {
                        type: "number",
                        max: selectedItemsPretaxTotal * 0.01 - 0.01,
                        message: ALERT_MESSAGE.dollar,
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <NumberInput
                    name={"amountOfTips"}
                    label={"Amount Of Tips To Refund"}
                    required
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: ALERT_MESSAGE.dollar,
                      },
                      {
                        type: "number",
                        max: order?.tip_cents * 0.01 - 0.01,
                        message: ALERT_MESSAGE.dollar,
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {(promptRefundReason === "optional" || promptRefundReason === "required") && (
              <Row>
                <Col span={24}>
                  <StyledSelectInput
                    name={"refundReason"}
                    label={"Refund Reason"}
                    placeholder={"Select a Refund Reason"}
                    allowClear={true}
                    required={promptRefundReason === "required"}
                    options={refundReasonOptions.concat([{ label: "Other", value: "Other" }])}
                  />
                </Col>
              </Row>
            )}
            {refundsForm.getFieldValue("refundReason") === "Other" && (
              <Row>
                <Col span={24}>
                  <TextInput //todo: fix the weird nested input fields here
                    name={"customRefundReason"}
                    label={"Custom Refund Reason"}
                    placeholder={"Enter a Custom Refund Reason"}
                    required={true}
                  />
                </Col>
              </Row>
            )}
          </Form>
        </div>

        <FeeTaxRow>
          <span>Selected Items Pre-Tax Total</span>
          <span>
            {formatPrice(
              selectedItemsPretaxTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
        <FeeTaxRow>
          <span>Selected Items Tax Total</span>
          <span>
            {formatPrice(
              selectedItemsTaxTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
        <FeeTaxRow>
          <span>Selected Items Tips Total</span>
          <span>
            {formatPrice(
              selectedItemsTipsTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>

        <hr />

        <FeeTaxRow>
          <span>Total Pre-Tax Refund</span>
          <span>
            {formatPrice(
              refundSalesAdjustedTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
        <FeeTaxRow>
          <span>Total Tax Refund</span>
          <span>
            {formatPrice(
              refundTaxAdjustedTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
        <FeeTaxRow>
          <span>Total Tips Refund</span>
          <span>
            {formatPrice(
              refundTipsAdjustedTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>

        <hr />

        <FeeTaxRow className={"total-row"}>
          <span>Refund Total</span>
          <span>
            {formatPrice(
              refundSalesAdjustedTotal + refundTaxAdjustedTotal + refundTipsAdjustedTotal,
              currencyData?.currency,
              currencyData?.locale,
              currencyData?.centsMultiplier
            )}
          </span>
        </FeeTaxRow>
      </div>
    );
  };

  const refundContent = () => {
    switch (refundModalState) {
      case REFUND_MODAL_STATES.refundFull:
        return refundFullContent();
      case REFUND_MODAL_STATES.refundPartial:
        return refundPartialContent();
      default:
        return selectRefundTypeAndItemsContent();
    }
  };

  const footerContent = () => {
    const noItemsOrFeesSelected = selectedItems.length === 0 && selectedFees.length === 0;
    const feesNotSelected =
      selectedFees.filter((fee) => fee.status !== "refunded").length !==
      feesInOrder.filter((fee) => fee.status !== "refunded").length;
    const allItemsSelected =
      selectedItems.filter((item) => item.status !== "refunded").length ===
      itemsInOrder.filter((item) => item.status !== "refunded").length;
    const toolTipMessage =
      "In order to refund all items in the order you must also refund all fees associated with the order.";

    switch (refundModalState) {
      case REFUND_MODAL_STATES.refundFull:
        return [
          <BbotButton onClick={() => setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems)}>
            Back
          </BbotButton>,
          <BbotButton type={"primary"} onClick={() => refundsForm.submit()} loading={isSubmittingRefund}>
            Submit
          </BbotButton>,
        ];
      case REFUND_MODAL_STATES.refundPartial:
        return [
          <BbotButton onClick={() => setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems)}>
            Back
          </BbotButton>,
          <BbotButton type={"primary"} onClick={() => refundTax()} loading={isSubmittingTaxRefund}>
            Mark Tax Exempt
          </BbotButton>,
          <BbotButton type={"primary"} onClick={() => refundsForm.submit()} loading={isSubmittingRefund}>
            Submit
          </BbotButton>,
        ];
      default:
        return [
          <BbotButton
            onClick={() => {
              setRefundModalState(null);
              onCancel();
            }}
            key={"refund-by-item-cancel"}
          >
            Cancel
          </BbotButton>,
          <Tooltip
            placement="top"
            title={toolTipMessage}
            visible={refundMode === REFUND_MODE.full && allItemsSelected && feesNotSelected}
          >
            <BbotButton
              /*If ALL items in an order that contains a fee(s) are refunded the fee(s) will automatically be refunded as well.
            To avoid confusion, disable the next button and show a helper message if ALL items are selected and the fee(s) is not*/
              disabled={
                noItemsOrFeesSelected || (refundMode === REFUND_MODE.full && allItemsSelected && feesNotSelected)
              }
              type={"primary"}
              onClick={() =>
                refundMode === REFUND_MODE.full
                  ? setRefundModalState(REFUND_MODAL_STATES.refundFull)
                  : setRefundModalState(REFUND_MODAL_STATES.refundPartial)
              }
              key={"refund-by-item-next"}
            >
              Next
            </BbotButton>
          </Tooltip>,
        ];
    }
  };

  return (
    <BbotModal
      visible={!!order}
      onCancel={() => {
        onCancel();
        setRefundModalState(REFUND_MODAL_STATES.refundTypeAndSelectItems);
      }}
      title={"Refund Order " + order?.orderNumberWithPrefix}
      footer={<>{footerContent()}</>}
    >
      {refundContent()}
    </BbotModal>
  );
};

export default IssueRefundsModal;

const RefundTypeSelector = styled(Radio.Group)`
  width: 100%;
  text-align: center;
  .ant-radio-button-wrapper {
    width: 50%;
  }
  .ant-radio-button-checked {
    background-color: var(--color-primary__regular);
  }
`;

const FeeTaxRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  &.total-row {
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

const ItemNameCol = styled(Col)`
  font-weight: 700;
  font-size: 14px;
`;

const ItemPriceCol = styled(Col)`
  text-align: right;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
`;
