import React, { useEffect, useState, useRef } from "react";
import { BorderRadius, Colors, Icon, InlineChildren, StackChildren, Text } from "@doordash/design-language";
import { TASK_WIDGET_INFO } from "components/customer-journey/JourneyTask";
import ListCell from "@doordash/component-list-cell";
import Button from "@doordash/component-button";
import { EmailField, PhoneField, TextField, ErrorMessage } from "@doordash/component-fields";
import styled from "styled-components";
import { extractBbotAddressFields } from "util/GooglePlacesUtils";
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from "Constants";

const getInitialState = (contacts, physicalAddress) => ({
  businessName: {
    value: physicalAddress.recipient ?? "",
    error: "",
    isRequired: true,
  },
  aptSuite: {
    value: physicalAddress.line2 ?? "",
    error: "",
    isRequired: false,
  },
  physicalAddress: {
    value: physicalAddress.query ?? "",
    error: "",
    isRequired: true,
  },
  phone: {
    value: contacts.support.phone ?? "",
    error: "",
    isRequired: true,
  },
  email: {
    value: contacts.support.email ?? "",
    error: "",
    isRequired: true,
  },
});

let autoCompletePhysical;

const VenueInfoWidget = ({ contacts, physicalAddress, onSave, isSaving }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [venueInfoFormValues, setVenueInfoFormValues] = useState(getInitialState(contacts, physicalAddress));
  const [selectedAddress, setSelectedAddress] = useState(physicalAddress);

  const autoCompleteRefPhysical = useRef(null);

  // Load Maps API
  useEffect(() => {
    if (isEditing) {
      handleScriptLoad();
    }
  }, [isEditing]);

  // Update initial state with contacts and address passed in by props
  useEffect(() => {
    onFieldChange("email", contacts.support.email);
    onFieldChange("phone", contacts.support.phone);
    onFieldChange("businessName", physicalAddress.recipient);
    onFieldChange("aptSuite", physicalAddress.line2);
    onFieldChange("physicalAddress", physicalAddress.query);

    // If defaults are loaded in, set editing to false.
    setIsEditing(!physicalAddress.query);
  }, [contacts, physicalAddress]);

  const onFieldChange = (fieldName, fieldValue) => {
    setVenueInfoFormValues((formValues) => {
      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: "",
          isRequired: formValues[fieldName].isRequired,
        },
      };
    });
  };

  const handlePhysicalAddressSelect = async () => {
    const addressObject = autoCompletePhysical.getPlace();
    let address = extractBbotAddressFields(addressObject);
    address = {
      line2: venueInfoFormValues.aptSuite.value,
      recipient: venueInfoFormValues.businessName.value,
      ...address,
    };
    address.google_place = addressObject;
    address.query = address.formatted_address;
    setSelectedAddress(address);
    onFieldChange("physicalAddress", address.formatted_address);
  };

  const handleScriptLoad = () => {
    autoCompletePhysical = new window.google.maps.places.Autocomplete(autoCompleteRefPhysical.current, {
      types: ["address"],
    });
    autoCompletePhysical.addListener("place_changed", handlePhysicalAddressSelect);
  };

  const validateVenueInfoForm = () => {
    let formHasErrors = false;

    const formEntries = Object.entries(venueInfoFormValues).map(([fieldName, { value, isRequired }]) => {
      let validationError = "";

      const hasValidationError = isRequired && !value?.length;
      if (hasValidationError) {
        formHasErrors = true;
        validationError = "This field is required";
      } else {
        // valid phone number
        if (fieldName === "phone") {
          if (!!value?.length && (!value.match(PHONE_VALIDATION_REGEX) || value.includes(" "))) {
            formHasErrors = true;
            validationError = "Please enter a valid phone number";
          }
        }

        // valid email
        if (fieldName === "email") {
          if (!!value?.length && (!value.match(EMAIL_VALIDATION_REGEX) || value.includes(" "))) {
            formHasErrors = true;
            validationError = "Please enter a valid email";
          }
        }

        // Valid address selected from google dropdown
        if (fieldName === "physicalAddress" && !selectedAddress) {
          formHasErrors = true;
          validationError = "Please use a suggested address from the dropdown.";
        }
      }

      return [
        fieldName,
        {
          value,
          isRequired,
          error: validationError,
        },
      ];
    });

    setVenueInfoFormValues(Object.fromEntries(formEntries));
    return !formHasErrors;
  };

  const onSaveClicked = () => {
    if (!validateVenueInfoForm()) {
      return;
    }

    setSelectedAddress((address) => {
      const updatedAddress = {
        ...address,
        recipient: venueInfoFormValues.businessName.value,
        line2: venueInfoFormValues.aptSuite.value,
      };

      onSave({
        support_email: venueInfoFormValues.email.value,
        support_phone: venueInfoFormValues.phone.value,
        physical_address: updatedAddress,
      });

      return updatedAddress;
    });

    setIsEditing(false);
  };

  const previewContent = (
    <StackChildren size={0}>
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Business Name</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="venue-business-saved-display">
            {venueInfoFormValues.businessName.value}
          </Text>
        }
        renderAfterContent={() => (
          <EditButtonWrap>
            <Button
              data-test-id="edit-venue-button"
              type={Button.Types.Tertiary}
              isInline
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          </EditButtonWrap>
        )}
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Apt/Suite</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="venue-line2-saved-display">
            {venueInfoFormValues.aptSuite.value || "-"}
          </Text>
        }
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Street Address</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="venue-address-saved-display">
            {venueInfoFormValues.physicalAddress.value}
          </Text>
        }
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Phone Number</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="venue-phone-saved-display">
            {venueInfoFormValues.phone.value}
          </Text>
        }
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Email</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="venue-email-saved-display">
            {venueInfoFormValues.email.value}
          </Text>
        }
      />
    </StackChildren>
  );

  const formContent = (
    <StackChildren size={StackChildren.Sizes.Large}>
      <StackChildren>
        <InlineChildren>
          <TextField
            label={"Business Name"}
            placeholder="My Awesome Restaurant"
            value={venueInfoFormValues.businessName.value}
            error={venueInfoFormValues.businessName.error}
            onChange={(businessName) => onFieldChange("businessName", businessName)}
            data-test-id="venue-business-name-field"
          />
          <TextField
            label={"Apt/Suite"}
            placeholder="Apt/Suite"
            value={venueInfoFormValues.aptSuite.value}
            error={venueInfoFormValues.aptSuite.error}
            onChange={(aptSuite) => onFieldChange("aptSuite", aptSuite)}
            data-test-id="venue-line2-field"
          />
        </InlineChildren>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Label1Emphasis}>Street Address</Text>
          <StyledAddressInput
            className={"full-width"}
            ref={autoCompleteRefPhysical}
            onChange={(e) => {
              onFieldChange("physicalAddress", e.target.value);
              setSelectedAddress(null);
            }}
            placeholder="Address"
            value={venueInfoFormValues.physicalAddress.value}
            data-test-id="venue-address-field"
          />
          {venueInfoFormValues.physicalAddress.error !== "" && (
            <ErrorMessage size={ErrorMessage.Sizes.Small} error={venueInfoFormValues.physicalAddress.error} />
          )}
        </StackChildren>
        <InlineChildren>
          <EmailField
            label={"Email"}
            placeholder="Email"
            value={venueInfoFormValues.email.value}
            error={venueInfoFormValues.email.error}
            onChange={(email) => onFieldChange("email", email)}
            data-test-id="venue-email-field"
          />
          <PhoneField
            label={"Phone Number"}
            placeholder="(000) 000-0000"
            value={venueInfoFormValues.phone.value}
            error={venueInfoFormValues.phone.error}
            onChange={(phone) => onFieldChange("phone", phone)}
            data-test-id="venue-phone-field"
          />
        </InlineChildren>
      </StackChildren>
      <Button
        isInline
        onClick={onSaveClicked}
        state={isSaving ? Button.States.Loading : Button.States.Default}
        data-test-id="save-venue-button"
      >
        Save Address
      </Button>
    </StackChildren>
  );

  return (
    <div>
      <div>
        <Text tag="h1" styles={Text.Styles.Title1} data-test-id="subtask-title-3">
          {TASK_WIDGET_INFO.venue_info.title}
        </Text>
        <div className="margin-bottom-2">
          <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
            {TASK_WIDGET_INFO.venue_info.description}
          </Text>
        </div>
      </div>
      {!isEditing ? previewContent : formContent}
    </div>
  );
};

export default VenueInfoWidget;

const EditButtonWrap = styled.div`
  margin-top: 3px;
`;

export const StyledAddressInput = styled.input`
  padding: 8px 12px;
  font-size: 14px;
  border-radius: ${BorderRadius.Medium}px;
  border: 1px solid ${Colors.BorderSecondary};
`;
