import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { NumberInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import { generalErrorAlert, fractionToPercentage } from "../../../../util/Utils";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";

class SeatedIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rewardsPercentage: 0,
      seatedVenueId: "",
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getSeatedConnectionStatus();
    }
  }

  getSeatedConnectionStatus = async () => {
    const { selectedCustomer } = this.props;

    this.setState({ loadingData: true });
    try {
      const res = await axios.get(
        "/admin/getPartnerConnectionStatus?customer_id=" + selectedCustomer?.customer_id + "&partnerKey=seated"
      );
      if (res.data.integration_details) {
        this.setState({
          rewardsPercentage: res.data.integration_details.rewards_fraction || 0,
          seatedVenueId: res.data.integration_details.seated_venue_id || "",
          hasLoaded: true,
        });
      }
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Seated settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectSeated = async (values) => {
    const { selectedCustomer, onClose, onSave } = this.props;
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      partnerKey: "seated",
      rewards_fraction: values.rewardsPercentage / 100,
      seated_venue_id: values.seatedVenueId,
    };

    try {
      await axios.post("/admin/updateSeatedPartnerSettings", payload);
      toast.success("Successfully connected Seated.");
      this.setState({
        rewardsPercentage: values.rewardsPercentage / 100,
        seatedVenueId: values.seatedVenueId,
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting Seated.");
    }
  };

  disconnectSeated = async () => {
    const { selectedCustomer, onClose, onSave } = this.props;
    const payload = {
      customerId: selectedCustomer?.customer_id,
      partnerKey: "seated",
    };

    try {
      await axios.post("/admin/disconnectPartner", payload);
      toast.success("Successfully disconnected from Seated.");
      this.setState({
        rewardsPercentage: 0,
        seatedVenueId: "",
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble disconnecting from Seated.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { rewardsPercentage, seatedVenueId, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Seated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Seated, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button size={"sm"} variant={"danger"} onClick={this.disconnectSeated}>
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            rewardsPercentage: fractionToPercentage(rewardsPercentage, 1),
            seatedVenueId: seatedVenueId,
          }}
          validationSchema={Yup.object({
            rewardsPercentage: Yup.number().min(0).max(100).required("Rewards percentage is required."),
            seatedVenueId: Yup.string().required("Venue ID is required."),
          })}
          onSubmit={this.connectSeated}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Seated Integration Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Seated Integration data"} />
                ) : (
                  <>
                    <div className={"margin-bottom-2"}>
                      <p>
                        For help with your Seated integration, please contact support@seatedapp.io or fill out this
                        <a
                          target="_blank"
                          href="https://seatedapp.formstack.com/forms/seatedbbotintegrationform"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          form
                        </a>
                        .
                      </p>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <NumberInput
                        label={"Rewards Percentage"}
                        name={"rewardsPercentage"}
                        id={"rewards-percentage"}
                        placeholder={0}
                      />
                      <div className={"supporting-text margin-top-1 margin-left-2"}>
                        <p>*Supports increments of 1%</p>
                      </div>
                    </div>
                    <div>
                      <TextInput
                        label={"Seated Venue ID"}
                        name={"seatedVenueId"}
                        id={"seated-venue-id"}
                        placeholder={"aa12bb22"}
                      />
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default SeatedIntegrationModal;
