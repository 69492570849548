import { track } from "instrumentation/tracking/track";

export const trackClickAddPromo = (fields = {}) => track("bb_track_click_new_promo", fields);

export const trackClickPromoAdvancedSettings = (fields = {}) => track("bb_track_click_promo_advanced_settings", fields);

export const trackClickEditPromo = (fields = {}) => track("bb_track_click_edit_promo", fields);

export const trackClickBulkDeletePromos = (fields = {}) => track("bb_track_click_bulk_delete_promos", fields);

export const trackClickSavePromo = (fields = {}) => track("bb_track_click_save_promo", fields);

export const trackSavePromoSuccess = (fields = {}) => track("bb_track_save_promo_success", fields);

export const trackSavePromoFailure = (fields = {}) => track("bb_track_save_promo_failure", fields);
