import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Slide1 from "assets/imgs/marketing/Slide-1.png";
import styled from "styled-components";
import { BorderRadius, StackChildren, Text } from "@doordash/design-language";
import Loading from "@doordash/component-loading";

const Invite = () => {
  const [isInviteValid, setIsInviteValid] = useState(false);
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const checkInvite = async (invite_id: string, secret: string) => {
      try {
        setIsLoadingInvite(true);
        const res = await axios.post("/api/journey/invite", {
          invite_id: invite_id,
          secret: secret,
        });

        const { invite_valid: inviteValid, customer_id: customerId, journey_id: journeyId } = res.data ?? {};
        setIsInviteValid(inviteValid);
        if (inviteValid) {
          history.push("/login?journey_id=" + journeyId + "&customer_id=" + customerId + "&referrer=invite");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingInvite(false);
      }
    };

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const secret = params.get("s") ?? "";
    const inviteId = params.get("id") ?? "";

    checkInvite(inviteId, secret);
  }, [history]);

  const getContent = () => {
    if (isLoadingInvite || isInviteValid) {
      return (
        <div>
          <div className="margin-bottom-1">
            <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Title1}>
              Verifying Bbot Invitation
            </Text>
          </div>
          <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Body1}>
            After we verify your invite link, you'll be redirected to the signup page.
          </Text>
          <div className="margin-y-4">
            <Loading state={Loading.States.FoodLoop} size={Loading.Sizes.XxLarge} />
          </div>
        </div>
      );
    } else if (!isInviteValid) {
      return (
        <StackChildren>
          <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Title1}>
            Invalid Invitation
          </Text>
          <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Body1}>
            The invite link was invalid or expired. Please ask for an updated invite link.
          </Text>
        </StackChildren>
      );
    }
  };

  return (
    <PageContainer>
      <ContentContainer>{getContent()}</ContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background-image: url(${Slide1});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  position: fixed;
  top: 20%;
  margin-left: 12px;
  margin-right: 12px;
  padding: 20px;
  background-color: white;
  border-radius: ${BorderRadius.Medium}px;
`;

export default Invite;
