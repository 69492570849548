import React from "react";
import { Form } from "react-bootstrap";
import { TextInput } from "components/global/form";
import RadioGroupInput from "components/global/form/RadioGroupInput";

const AgilysysAPICredentialsModalInputs = (props) => {
  let { setFieldValue, values } = props;

  return (
    <>
      <Form.Row>
        <TextInput sm={12} label="Client ID" name="clientId" id="clientId" />
      </Form.Row>
      <Form.Row>
        <TextInput sm={12} label="Authorization Code" name="authCode" id="authCode" />
      </Form.Row>
      <Form.Row>
        <TextInput sm={12} label="API Url" name="apiUrl" id="apiUrl" />
      </Form.Row>
      <Form.Row>
        <RadioGroupInput
          options={[
            {
              label: "Hosted On-Site",
              value: true,
            },
            {
              label: "Agilysys Cloud Hosted",
              value: false,
            },
          ]}
          label={"Where is the Agilysys database hosted?"}
          name={"selfHostedDB"}
          id={"selfHostedDB"}
          selectedValue={values.selfHostedDB}
          value={values.selfHostedDB}
          onChange={(value) => setFieldValue("selfHostedDB", value === "true")}
        />
      </Form.Row>
      {values.selfHostedDB ? (
        <Form.Row>
          <TextInput label={"Master Data File Directory Name"} name={"directoryName"} id={"directoryName"} />
        </Form.Row>
      ) : (
        <>
          <Form.Row>
            <TextInput label={"SFTP Server URL"} name={"sftpServerUrl"} id={"sftpServerUrl"} />
          </Form.Row>
          <Form.Row>
            <TextInput label={"SFTP Server Username"} name={"sftpServerUsername"} id={"sftpServerUsername"} />
          </Form.Row>
          <Form.Row>
            <TextInput label={"SFTP Server Password"} name={"sftpServerPassword"} id={"sftpServerPassword"} />
          </Form.Row>
          <Form.Row>
            <TextInput label={"SFTP server path"} name={"sftpPath"} id={"sftpPath"} />
          </Form.Row>
        </>
      )}
    </>
  );
};

export default AgilysysAPICredentialsModalInputs;
