import React from "react";
import axios from "axios";
import { Button, Col, Modal, Row } from "react-bootstrap";

import "./FulfillmentNoticesModal.scss";
import { toast } from "react-toastify";
import { trackClickSaveNotificationSet } from "instrumentation/tracking/page-tracking-events";

class FulfillmentNoticesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSubject: "",
      emailBody: "",
      text: "",
      editEmail: false,
      editText: false,
      textSet: false,
      emailSet: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fulfillmentMethodNotice !== this.props.fulfillmentMethodNotice) {
      const emailSubject = this.props.fulfillmentMethodNotice?.email?.subject || "";
      const emailBody = this.props.fulfillmentMethodNotice?.email?.body || "";
      const text = this.props.fulfillmentMethodNotice?.text || "";
      const textEnabled = text.length > 0;
      const emailEnabled = emailSubject.length > 0 || emailBody.length > 0;
      this.setState({
        emailSubject: emailSubject,
        emailBody: emailBody,
        text: text,
        emailEnabled: emailEnabled,
        textEnabled: textEnabled,
        textSet: text.length > 0,
        emailSet: emailSubject.length > 0 && emailBody.length > 0,
      });
    }
  }

  onSavePressed = async () => {
    const { emailEnabled, textEnabled, emailSubject, emailBody, text } = this.state;
    const { fulfillmentNoticeId, fulfillmentMethodNotice } = this.props;

    let payload = {
      email: null,
      text: null,
      fulfillmentNoticeId: fulfillmentNoticeId,
      fulfillmentMethod: fulfillmentMethodNotice.fulfillmentMethod,
      status: fulfillmentMethodNotice.status,
    };
    if (emailEnabled) {
      payload.email = {
        subject: emailSubject,
        body: emailBody,
      };
    }
    if (textEnabled) {
      payload.text = text;
    }

    // track event to segment
    trackClickSaveNotificationSet({ notification_payload: payload });

    try {
      await axios.post("/api/saveFulfillmentNotice", payload);
      this.setState({
        editEmail: false,
        editText: false,
        textSet: textEnabled && text.length > 0,
        emailSet: (emailEnabled && emailSubject.length > 0) || emailBody.length > 0,
      });
      this.props.saveCallback(payload);
    } catch (error) {
      console.error(error);
    }
    this.props.onHide();
  };

  handleEmailChange = (e) => {
    if (e.target.name === "emailSubject") {
      this.setState({
        [e.target.name]: e.target.value,
        emailEnabled: this.state.emailBody.trim().length > 0 && e.target.value.trim().length > 0,
      });
    } else if (e.target.name === "emailBody") {
      this.setState({
        [e.target.name]: e.target.value,
        emailEnabled: this.state.emailSubject.trim().length > 0 && e.target.value.trim().length > 0,
      });
    }
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      textEnabled: e.target.value.trim().length > 0,
    });
  };

  clearEmails = () => {
    this.setState(
      {
        emailSubject: "",
        emailBody: "",
        emailEnabled: false,
        emailSet: false,
      },
      this.onSavePressed
    );
    toast.success("Successfully cleared emails.");
  };

  clearTexts = () => {
    this.setState(
      {
        text: "",
        textEnabled: false,
        textSet: false,
      },
      this.onSavePressed
    );
    toast.success("Successfully cleared texts.");
  };

  render() {
    const { editEmail, editText } = this.state;
    const { show, doneStatuses, onHide, fulfillmentMethodNotice, statusNames, fulfillmentMethodPrettyNames } =
      this.props;

    const defaultEmailSubject = this.props.fulfillmentMethodNotice?.email?.subject || "";
    const defaultEmailBody = this.props.fulfillmentMethodNotice?.email?.body || "";
    const defaultText = this.props.fulfillmentMethodNotice?.text || "";
    const status = fulfillmentMethodNotice?.status;

    return (
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          if (editEmail || editText) {
            toast.info(
              <div>
                <div className={"margin-bottom-1"}>
                  Do you want to save before exiting? If you do not click save, changes to these settings will not be
                  saved.
                </div>
                <div className={"toast-actions-row"}>
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      this.setState({ editEmail: false, editText: false });
                      onHide();
                    }}
                    className={"margin-right-2"}
                  >
                    Exit
                  </Button>
                  <Button variant={"secondary"} onClick={this.onSavePressed}>
                    Save
                  </Button>
                </div>
              </div>
            );
          } else {
            this.setState({ editEmail: false, editText: false });
            onHide();
          }
        }}
        dialogClassName={"fulfillment-notices-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              Notification to guest when a{" "}
              <b>
                {fulfillmentMethodNotice && fulfillmentMethodPrettyNames[fulfillmentMethodNotice?.fulfillmentMethod]}
              </b>{" "}
              order reaches status{" "}
              <b>{fulfillmentMethodNotice && (statusNames[status] ? statusNames[status] : status)}</b>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Col xs={12} className={"modal-banner padding-y-3"}>
          {doneStatuses.includes(status) && status !== "refunded" && (
            <div className={"margin-bottom-4"}>
              <p className={"helper-text"}>This status is the final one for this fulfillment method's order cycle.</p>
            </div>
          )}
          <div className={"margin-bottom-1"}>
            <p>
              <i>If you include a keyword below, it will be automatically replaced with the relevant info.</i>
            </p>
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$ORDER</span> - Description of the order. Will be formatted like "Bob's
            Restaurant Order 243 (Crab Burger, House Fries)"
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$ORDER_NUMBER</span> - Order number
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$SUPPORT_PHONE</span> - Restaurant's support phone number
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$RESTAURANT</span> - Restaurant's name
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$DESIRED_TIME</span> - Guest's requested delivery time, for order-ahead
            orders
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$LOCATION</span> - Location's name
          </div>
          <div className={"available-variables"}>
            <span className={"variable-name"}>$NAME</span> - Guest name. Name must be configured to be part of the
            required checkout info in the location editor. If not, $NAME will be replaced with "valued guest".
          </div>
        </Col>
        <Modal.Body className={"padding-y-3"}>
          <Row>
            <Col sm={12} className={"margin-bottom-3"}>
              {editEmail ? (
                <Row>
                  <Col sm={12} className={"margin-bottom-3"}>
                    <div className={"modal-label margin-bottom-2"}>Email</div>
                    <div className={"modal-label"}>Email Subject</div>
                    <input
                      className={"modal-input"}
                      name={"emailSubject"}
                      value={this.state.emailSubject}
                      onChange={this.handleEmailChange}
                    />
                  </Col>
                  <Col sm={12} className={"margin-bottom-2"}>
                    <div className={"modal-label"}>Email Body</div>
                    <input
                      className={"modal-input"}
                      name={"emailBody"}
                      value={this.state.emailBody}
                      onChange={this.handleEmailChange}
                    />
                  </Col>
                  {this.state.emailSet && (
                    <Col sm={12}>
                      <div className={"modal-message-default-container"}>
                        <div className={"margin-bottom-1"}>
                          <b>Currently Set Email</b>
                        </div>
                        <div>
                          <b>Subject:</b>
                        </div>
                        <div className={"margin-bottom-1"}>{defaultEmailSubject}</div>
                        <div>
                          <b>Body:</b>
                        </div>
                        <div>{defaultEmailBody}</div>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row>
                  <Col sm={12} className={"margin-bottom-3"}>
                    <div className={"modal-label margin-bottom-2"}>Email</div>
                    <div className={"margin-bottom-2"}>
                      <Button
                        className={"margin-right-2"}
                        size={"sm"}
                        onClick={() => this.setState({ editEmail: true })}
                      >
                        Edit Email
                      </Button>
                      {(defaultEmailSubject || defaultEmailBody) && (
                        <Button onClick={this.clearEmails} size={"sm"}>
                          Clear/Don't Send Emails
                        </Button>
                      )}
                    </div>
                    {(defaultEmailSubject || defaultEmailBody) && (
                      <div className={"modal-message-default-container"}>
                        <div className={"margin-bottom-1"}>
                          <b>Current Email</b>
                        </div>
                        <div>
                          <b>Subject:</b>
                        </div>
                        <div className={"margin-bottom-1"}>{defaultEmailSubject}</div>
                        <div>
                          <b>Body:</b>
                        </div>
                        <div>{defaultEmailBody}</div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={12} className={"margin-bottom-3"}>
              {editText ? (
                <>
                  {" "}
                  <div className={"modal-label margin-bottom-2"}>Text</div>
                  <input
                    className={"modal-input margin-bottom-2"}
                    name={"text"}
                    value={this.state.text}
                    onChange={this.handleTextChange}
                  />
                  {this.state.textSet > 0 && (
                    <div className={"modal-message-default-container"}>
                      <div>
                        <b>Currently Set Text</b>
                      </div>
                      <div>{defaultText}</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={"modal-label margin-bottom-2"}>Text</div>
                  <div className={"margin-bottom-2"}>
                    <Button className={"margin-right-2"} size={"sm"} onClick={() => this.setState({ editText: true })}>
                      Edit Text
                    </Button>
                    {defaultText && (
                      <Button onClick={this.clearTexts} size={"sm"}>
                        Clear/Don't Send Texts
                      </Button>
                    )}
                  </div>
                  {defaultText && (
                    <div className={"modal-message-default-container"}>
                      <div>
                        <b>Current Text</b>
                      </div>
                      <div>{defaultText}</div>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size={"sm"} className={"save-btn"} disabled={!editEmail && !editText} onClick={this.onSavePressed}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FulfillmentNoticesModal;
