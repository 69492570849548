import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckboxInput, NumberInput, SearchSelectInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class NashIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nashSettings: {},
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getNashConnectionStatus();
    }
  }

  getNashConnectionStatus = async () => {
    const { selectedCustomer } = this.props;
    this.setState({ loadingData: true });
    try {
      const res = await axios.get("/admin/getNashConnectionStatus?customer_id=" + selectedCustomer?.customer_id);
      this.setState({
        nashSettings: res.data,
        hasLoaded: true,
        currentIntegrationOwner: res.data.delivery_group_enabled
          ? res.data.current_delivery_integration_owner.customer_name
          : "",
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Nash settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectDisconnectNash = async (values) => {
    const { selectedCustomer, onClose, onSave, disconnectMode, allowedCustomers } = this.props;
    const { nashSettings } = this.state;

    values.enabled = !disconnectMode;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      ...values,
    };
    try {
      await axios.post("/admin/connectDisconnectNash", payload);
      if (disconnectMode) {
        toast.success("Successfully disconnected Nash.");
        nashSettings.enabled = false;
        nashSettings.api_key = "";
        nashSettings.delivery_group_enabled = false;
        nashSettings.current_delivery_integration_owner = "";
        nashSettings.pickup_instructions = "";
        nashSettings.typical_prep_minutes = 25;
        this.setState({
          nashSettings: nashSettings,
          currentIntegrationOwner: "",
        });
      } else {
        toast.success("Successfully connected Nash.");
        nashSettings.enabled = values.enabled;
        nashSettings.api_key = values.api_key;
        const currentIntegrationOwner = values.delivery_group_enabled
          ? allowedCustomers.find((customer) => customer.customer_id === values.delivery_integration_owner_id)
          : null;
        nashSettings.delivery_group_enabled = values.delivery_group_enabled;
        nashSettings.current_delivery_integration_owner = currentIntegrationOwner;
        nashSettings.pickup_instructions = values.pickup_instructions;
        nashSettings.typical_prep_minutes = values.typical_prep_minutes;
        this.setState({
          nashSettings: nashSettings,
          currentIntegrationOwner: values.delivery_group_enabled ? currentIntegrationOwner.name_for_owner : "",
        });
      }
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to Nash.");
    }
  };

  render() {
    const { show, onClose, disconnectMode, allowedCustomers } = this.props;
    const { nashSettings, currentIntegrationOwner, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Nash</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Nash, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              variant={"danger"}
              onClick={() => {
                this.connectDisconnectNash(nashSettings);
              }}
            >
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            api_key: nashSettings.api_key || "",
            delivery_group_enabled: nashSettings.delivery_group_enabled || false,
            delivery_integration_owner_id: nashSettings.delivery_group_enabled
              ? nashSettings.current_delivery_integration_owner.customer_id
              : "",
            pickup_instructions: nashSettings.pickup_instructions || "",
            typical_prep_minutes: nashSettings.typical_prep_minutes || 25,
          }}
          validationSchema={Yup.object({
            api_key: Yup.string(),
            delivery_group_enabled: Yup.boolean(),
            delivery_integration_owner_id: Yup.string(),
            pickup_instructions: Yup.string(),
            typical_prep_minutes: Yup.number().min(15),
          })}
          onSubmit={this.connectDisconnectNash}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Nash Integration Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Nash Integration data"} />
                ) : (
                  <>
                    <div className={"margin-bottom-2"}>
                      <TextInput
                        label={"Nash API Key"}
                        placeholder={nashSettings.api_key}
                        name={"api_key"}
                        id={"api_key"}
                      />
                    </div>
                    <div className={"margin-bottom-2"}>
                      <CheckboxInput
                        label={"Should orders from this account be delivered together with orders from other accounts?"}
                        name={"delivery_group_enabled"}
                        id={"add-to-delivery-group"}
                      />
                    </div>
                    {values.delivery_group_enabled && (
                      <div className={"margin-bottom-1"}>
                        <SearchSelectInput
                          name={"delivery_integration_owner_id"}
                          id={"integration-owner"}
                          label={"Choose the main account for the food hall"}
                          options={allowedCustomers.map((customer) => {
                            return {
                              value: customer.customer_id,
                              label: customer.name_for_owner,
                            };
                          })}
                        />
                        <div className={"supporting-text margin-top-1 margin-left-2"}>
                          <p>Current Integration Owner: {" " + currentIntegrationOwner}</p>
                        </div>
                      </div>
                    )}
                    <div className={"margin-bottom-2"}>
                      <NumberInput
                        label={"Minutes before Nash driver comes for pickup"}
                        name={"typical_prep_minutes"}
                        id={"typical-prep-minutes"}
                      />
                    </div>
                    <div className={"margin-bottom-2"}>
                      <TextInput
                        label={"Pickup instructions for Nash driver (optional)"}
                        name={"pickup_instructions"}
                        id={"pickup_instructions"}
                      />
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size={"sm"}
                  type={"submit"}
                  disabled={
                    loadingData ||
                    Object.keys(errors).length !== 0 ||
                    (values.delivery_group_enabled && values.delivery_integration_owner_id === "")
                  }
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default NashIntegrationModal;
