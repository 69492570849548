import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const DatePickerInput = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <Form.Group
      as={Col}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      controlId={controlId}
      className={props.className ? props.className : "margin-bottom-1 "}
    >
      <Form.Label className={"text-input-label"}>{label}</Form.Label>
      <Form.Control
        as={DatePicker}
        {...field}
        {...props}
        data-test-id={props.testId}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        placeholderText={props.placeholder}
        isInvalid={!!meta.error}
      />
      <div>
        <p className={"text-x-small color-error__regular"}>{meta.error?.replace(props.name, label)}</p>
      </div>
    </Form.Group>
  );
};

DatePickerInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default DatePickerInput;
