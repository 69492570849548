import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";

class ConnectPOSIntegrationModal extends Component {
  render() {
    const { onHide, show, onSubmit, warningText, submitText, cancelText, title } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"padding-x-4"}>
          <div className={"margin-bottom-1"}>
            <p style={{ color: "red" }}>{warningText}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size={"sm"} variant={"light"} onClick={onHide} className={"margin-right-1"}>
            {cancelText}
          </Button>
          <Button size={"sm"} type={"submit"} onClick={onSubmit}>
            {submitText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConnectPOSIntegrationModal;
