import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Badge, Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { NumberInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import { generalErrorAlert } from "../../../../util/Utils";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";

class OrkestroIntegrationModal extends Component {
  state = {
    apiKey: "",
    enabled: false,
    pickupInstructions: "",
    typicalPrepMinutes: 25,
    statusBoard: [],
    loadingData: false,
    hasLoaded: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getOrkestroInfo();
    }
  }

  getOrkestroInfo = async () => {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) return;

    this.setState({ loadingData: true });

    try {
      const res = await axios.get("/admin/getOrkestroConnectionStatus", {
        params: {
          customer_id: selectedCustomer.customer_id,
        },
      });

      this.setState({
        apiKey: res.data.api_key,
        pickupInstructions: res.data.pickup_instructions,
        enabled: res.data.enabled,
        typicalPrepMinutes: res.data.typical_prep_minutes,
        statusBoard: res.data.status_board,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Error connecting Orkestro.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  onHide = () => {
    const { onHide } = this.props;
    onHide();
  };

  saveOrkestroSettings = async (values) => {
    const { selectedCustomer, onSave } = this.props;

    try {
      const payload = {
        api_key: values.apiKey,
        pickup_instructions: values.pickupInstructions,
        customer_id: selectedCustomer.customer_id,
        enabled: true,
        typical_prep_minutes: values.typicalPrepMinutes,
      };
      await axios.post("/admin/connectOrkestro", payload);
      toast.success("Successfully connected with Orkestro");
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error saving Orkestro settings.");
    }
  };

  disconnect = async () => {
    const { selectedCustomer, onSave } = this.props;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        enabled: false,
        api_key: "",
        pickup_instructions: "",
      };
      await axios.post("/admin/connectOrkestro", payload);
      toast.success("Successfully disconnected from Orkestro");
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error disconnecting Orkestro.");
    }
  };

  disconnectModal = () => {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Orkestro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Are you sure you want to disconnect from Orkestro?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button size={"sm"} variant={"danger"} onClick={this.disconnect}>
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { show, disconnectMode } = this.props;
    const { apiKey, pickupInstructions, typicalPrepMinutes, enabled, statusBoard, loadingData } = this.state;

    return disconnectMode ? (
      this.disconnectModal()
    ) : (
      <Modal show={show} onHide={this.onHide}>
        <Formik
          enableReinitialize
          initialValues={{
            apiKey: apiKey || "",
            pickupInstructions: pickupInstructions || "",
            typicalPrepMinutes: typicalPrepMinutes,
          }}
          validationSchema={Yup.object({
            apiKey: Yup.string().required(),
            pickupInstructions: Yup.string(),
            typicalPrepMinutes: Yup.number().min(15),
          })}
          onSubmit={this.saveOrkestroSettings}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Connect Orkestro</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"padding-x-4"}>
              {loadingData ? (
                <CenteredLoadingSpinner label={"Loading Orkestro Integration data"} />
              ) : (
                <>
                  <div className={"margin-bottom-2"}>
                    <h3 className={"margin-bottom-1"}>Integration prerequisites</h3>
                    <div>
                      {statusBoard.map((prereq, index) => (
                        <div key={index}>
                          <span className={"margin-right-2"}>{prereq.description}</span>
                          <span>
                            {prereq.ok ? (
                              <Badge pill variant={"success"}>
                                Yes
                              </Badge>
                            ) : (
                              <Badge pill variant={"danger"}>
                                No
                              </Badge>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Row className={"margin-bottom-2"}>
                    <TextInput label={"Api Key"} name={"apiKey"} id={"api-key"} sm={12} />
                  </Row>
                  <Row className={"margin-bottom-2"}>
                    <TextInput
                      label={"Driver Pickup Instructions"}
                      name={"pickupInstructions"}
                      id={"pickup-instructions"}
                      sm={12}
                    />
                  </Row>
                  <Row className={"margin-bottom-2"}>
                    <NumberInput
                      label={"Minutes before driver comes for pickup"}
                      name={"typicalPrepMinutes"}
                      id={"typical-prep-minutes"}
                      sm={12}
                    />
                  </Row>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className={enabled ? "space-between" : ""}>
              <>
                <Button size={"sm"} variant={"light"} className={"margin-right-1"} onClick={this.onHide}>
                  Cancel
                </Button>
                <Button
                  size={"sm"}
                  variant={"primary"}
                  type={"submit"}
                  disabled={statusBoard.some((prereq) => !prereq.ok)}
                >
                  Save
                </Button>
              </>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    );
  }
}

OrkestroIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default OrkestroIntegrationModal;
