import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckboxInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class OpenTableIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opentableSettings: {},
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getOpenTableConnectionStatus();
    }
  }

  getOpenTableConnectionStatus = async () => {
    const { selectedCustomer } = this.props;
    this.setState({ loadingData: true });
    try {
      const res = await axios.get("/admin/getOpenTableConnectionStatus?customer_id=" + selectedCustomer?.customer_id);
      this.setState({
        opentableSettings: res.data,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving OpenTable settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectOpenTable = async (values) => {
    const { selectedCustomer, onClose, onSave, disconnectMode } = this.props;
    const { opentableSettings } = this.state;

    values.enabled = !disconnectMode;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      ...values,
    };
    try {
      await axios.post("/admin/connectOpenTable", payload);
      if (disconnectMode) {
        toast.success("Successfully disconnected OpenTable.");
        opentableSettings.enabled = false;
        opentableSettings.restaurant_id = "";
        opentableSettings.in_venue = false;
        opentableSettings.takeout = false;
        opentableSettings.delivery = false;
        this.setState({
          opentableSettings: opentableSettings,
        });
      } else {
        toast.success("Successfully connected OpenTable.");
        opentableSettings.enabled = values.enabled;
        opentableSettings.restaurant_id = values.restaurant_id;
        opentableSettings.in_venue = values.in_venue;
        opentableSettings.takeout = values.takeout;
        opentableSettings.delivery = values.delivery;
        this.setState({
          opentableSettings: opentableSettings,
        });
      }
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to OpenTable.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { opentableSettings, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect OpenTable</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect OpenTable, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              variant={"danger"}
              onClick={() => {
                this.connectOpenTable(opentableSettings);
              }}
            >
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            restaurant_id: opentableSettings.restaurant_id || "",
            in_venue: opentableSettings.in_venue || false,
            takeout: opentableSettings.takeout || false,
            delivery: opentableSettings.delivery || false,
          }}
          validationSchema={Yup.object({
            restaurant_id: Yup.string(),
            in_venue: Yup.boolean(),
            takeout: Yup.boolean(),
            delivery: Yup.boolean(),
          })}
          onSubmit={this.connectOpenTable}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>OpenTable Integration Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading OpenTable Integration data"} />
                ) : (
                  <>
                    <div className={"margin-bottom-2"}>
                      Elevate your restaurant brand and guest experience with OpenTable that helps restaurants sit 1.6
                      billion guests each year. To get started, please share the following information:
                    </div>
                    <div className={"margin-bottom-2"}>
                      <TextInput
                        label={"OpenTable Restaurant ID"}
                        placeholder={opentableSettings.restaurant_id}
                        name={"restaurant_id"}
                        id={"restaurant_id"}
                      />
                    </div>
                    <div className={"margin-bottom-2"}>
                      <CheckboxInput label={"Do you offer on-premise ordering?"} name={"in_venue"} id={"in_venue"} />
                    </div>
                    <div className={"margin-bottom-2"}>
                      <CheckboxInput label={"Do you offer takeout?"} name={"takeout"} id={"takeout"} />
                    </div>
                    <div className={"margin-bottom-2"}>
                      <CheckboxInput label={"Do you offer delivery?"} name={"delivery"} id={"delivery"} />
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default OpenTableIntegrationModal;
