import { track } from "instrumentation/tracking/track";

export const trackClickDashboardItem = (fields) => track("track_click_dashboard_link", fields);

export const trackClickSidenavItem = (fields) => track("track_click_sidenav_item", fields);

export const trackClickRestaurantDropdown = (fields) => track("track_click_restaurant_dropdown", fields);

export const trackClickRestaurantDropdownLoadMore = (fields) =>
  track("track_click_restaurant_dropdown_load_more", fields);

export const trackClickRestaurantDropdownItem = (fields) => track("track_click_restaurant_dropdown_item", fields);

export const trackClickHelpCenter = (fields) => track("track_click_help_center", fields);

export const trackClickLogout = (fields) => track("track_click_logout", fields);
