import { Table } from "../bbot-component-library";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { BbotButton, BbotTag, Col, Space } from "bbot-component-library";
import PropTypes from "prop-types";
/*
A generic/configurable table to display a list of objects in the Bbot Owner Portal
*/
const BbotTable = ({
  id,
  columns,
  data,
  title,
  titleRight = null,
  pagination = false,
  bordered = false,
  interactive = true,
  allowBulkEdit = false,
  allowBulkDeletion = false,
  onBulkEdit,
  onBulkDelete,
  editButtonText = "Edit",
  showAddButton = true,
  onAdd,
  addButtonText = "Add",
  emptyTableAllowed = true,
  manuallyFormattedColumns, // pass in custom columns for custom functionality
  manualRowSelection, // can pass in custom row selection for custom functionality. Location editor using this to disable some rows from being selected.
  manualSelectedRowKeys, // if using manualSelection, pass this in to set the state
  manualOnRow,
  onRowSelectionChange,
  editButtonDisabled = false,
  showCountByTitle = true,
  ...props
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // If the number of rows changes clear the user selections
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [data.length]);

  useEffect(() => {
    if (manualSelectedRowKeys) {
      setSelectedRowKeys(manualSelectedRowKeys);
    }
  }, [manualSelectedRowKeys]);

  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(selectedRowKeys);
    }
  }, [onRowSelectionChange, selectedRowKeys]);

  // Initialize selectable rows if interactive is true, otherwise disable selectable rows
  const rowSelection =
    allowBulkEdit || allowBulkDeletion
      ? {
          selectedRowKeys: selectedRowKeys,
          onChange: (checkedRowKeys) => {
            setSelectedRowKeys(checkedRowKeys);
          },
        }
      : false;

  const formattedColumns = columns?.map((column) => {
    return {
      title: column === "Actions" ? "" : column, // Actions is a column reserved for edit / delete icons and shouldn't have a title
      dataIndex: column.toLowerCase().replace(/ /g, "_"),
      key: column.toLowerCase().replace(/ /g, "_"),
    };
  });

  // Function to handle selecting/deselecting rows
  const handleSelectionChange = (selectedRowKeys_fromAnt) => {
    setSelectedRowKeys(selectedRowKeys_fromAnt);
  };

  // Behavior for clicking on a row
  const onRow =
    allowBulkEdit || allowBulkDeletion
      ? (record) => {
          return {
            onClick: () => {
              const selection = selectedRowKeys.includes(record.key)
                ? selectedRowKeys.filter((entry) => {
                    return entry !== record.key;
                  })
                : selectedRowKeys.concat([record.key]);
              handleSelectionChange(selection);
            },
          };
        }
      : false;

  return (
    <div>
      <StyledBbotTable>
        {/* Show the header if there is a title or if interactive is true */}
        {(title || titleRight || (interactive && (allowBulkEdit || allowBulkDeletion))) && (
          <BbotTableHeader>
            <Col span={"18"}>
              <HeaderTitle>
                {title}{" "}
                {showCountByTitle && (
                  <span>
                    <BbotTag>
                      {selectedRowKeys.length > 0 ? `${selectedRowKeys.length}/` : ""}
                      {data.length}
                    </BbotTag>
                  </span>
                )}
              </HeaderTitle>
            </Col>
            <BbotHeaderSpace span={"6"}>
              {titleRight}
              {interactive && selectedRowKeys.length > 0 && allowBulkDeletion && (
                <BbotButton
                  id={id + "-delete-button"}
                  type={"primary"}
                  danger
                  onClick={() => onBulkDelete(selectedRowKeys)}
                  disabled={!emptyTableAllowed && selectedRowKeys.length >= data.length}
                >
                  Delete
                </BbotButton>
              )}
              {interactive && selectedRowKeys.length > 0 && allowBulkEdit && (
                <BbotButton
                  id={id + "-edit-button"}
                  type={"primary"}
                  onClick={() => onBulkEdit(selectedRowKeys)}
                  disabled={editButtonDisabled}
                >
                  {editButtonText}
                </BbotButton>
              )}
            </BbotHeaderSpace>
          </BbotTableHeader>
        )}
        {/* Configure table and populate with columns/data */}
        <Table
          id={id}
          columns={manuallyFormattedColumns || formattedColumns}
          dataSource={data}
          pagination={pagination}
          bordered={bordered}
          rowSelection={manualRowSelection || rowSelection}
          onRow={manualOnRow || onRow}
          {...props}
        />
        {onAdd && showAddButton && (
          <StyledButton id={id + "-add-button"} onClick={onAdd}>
            <div className={"icon-circle"}>+</div>
            <span>{addButtonText}</span>
          </StyledButton>
        )}
      </StyledBbotTable>
    </div>
  );
};

const StyledBbotTable = styled.div`
  background-color: var(--color-neutral__card-background);
  border: 1px solid var(--color-neutral__line);
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(178, 178, 178, 0.25);
  width: 100%;
  overflow: auto;
  .ant-table-pagination {
    margin-right: 16px;
    .ant-pagination-item,
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-table-cell:first-child {
    padding-left: 26px;
  }

  tr:hover {
    cursor: pointer;
  }
`;

const BbotHeaderSpace = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

const BbotTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 25px;
  justify-content: space-between;
  > div {
    > h4 {
      padding: 2px 0px;
    }
  }
`;

const HeaderTitle = styled.h4`
  font-size: 18px;
`;

const StyledButton = styled.button`
  border: none; // Removes ugly button border
  color: var(--color-neutral__label);
  background-color: var(--color-neutral__accent-background);
  border-top: 1px solid var(--color-neutral__line);

  outline: none;
  display: flex;
  width: 100%;
  padding: 15px 25px;
  align-items: center;
  transition-timing-function: ease-in-out;
  transition-property: background-color;
  transition-duration: 0.2s;

  .icon-circle {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-primary__regular);
    background-color: var(--color-primary__light);
    border-radius: 50%;
    color: var(--color-primary__regular);
  }

  > span {
    margin-left: 20px;
  }

  &:hover {
    color: var(--color-primary__dark);
    background-color: var(--color-primary__light);

    .icon-circle {
      color: var(--color-primary__light);
      background-color: var(--color-primary__regular);
    }
  }

  &:focus {
    outline: none;
  }
`;

export default BbotTable;

BbotTable.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.array, // Array of strings. These are the column headers
  data: PropTypes.array.isRequired, // Array of objects. Each object should have a 'key' (the row's ID) and a key
  // for each column, where the column key is the lowercase name of the column with underscores instead of spaces
  onAdd: PropTypes.func, // Function called when clicking the add button
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pagination: PropTypes.bool, // If set to 'true' rows will be paginated into pages of 10 rows
  // each. If set to {pageSize:x} rows will be paginated into pages of x rows
  bordered: PropTypes.bool, // By default the table has no border
  interactive: PropTypes.bool, // Pass true to allow clicking on and interacting with the table
  allowBulkEdit: PropTypes.bool, // Pass true to allow selecting and editing multiple rows
  allowBulkDeletion: PropTypes.bool, // Pass true to allow selecting and deleting multiple rows
  onBulkEdit: PropTypes.func, // Required if bulk edit is allowed
  onBulkDelete: PropTypes.func, // Required if bulk delete is allowed
  editButtonText: PropTypes.string,
  addButtonText: PropTypes.string,
  emptyTableAllowed: PropTypes.bool, // If false users will not be able to delete the last entry in a table
  showCountByTitle: PropTypes.bool,
};
