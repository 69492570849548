import React from "react";
import axios from "axios";
import ReactFilestack from "filestack-react";
import { FILESTACK_API_KEY } from "Constants";
import "styles/customer-journey/widget.scss";

const FILESTACK_IMG_OPTIONS = {
  fromSources: ["local_file_system", "googledrive", "url"],
  maxFiles: 1,
};

class UploadLogoWidget extends React.Component {
  state = {
    location: null,
  };

  componentDidMount() {
    this.getDefaultLocation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.journey !== this.props.journey) {
      this.getDefaultLocation();
    }
  }

  getDefaultLocation = async () => {
    const { journey } = this.props;

    if (!journey) return;

    try {
      const res = await axios.get("/owner/locations/", {
        params: { customer_id: journey.customer_id },
      });

      // There should only be one location set up for this customer.
      this.setState({
        location: res.data.locations[0],
      });
    } catch (error) {
      console.error(error);
      console.error(error.response.data.message);
    }
  };

  uploadLogoSuccess = async (res) => {
    const { location } = this.state;
    const { updateTaskStatus, widget } = this.props;

    // create the menu
    try {
      await axios.post("/api/journey/uploadLocationLogo", {
        location_id: location.locationId,
        logo_url: res.filesUploaded[0].url,
      });
      await this.getDefaultLocation();
      updateTaskStatus("completed", { widgetId: widget.id, goToNext: false });
    } catch (error) {
      console.error(error);
      console.error(error.response.data.message);
    }
  };

  render() {
    const { location } = this.state;

    return (
      <div className={"upload-logo-widget padding-y-3"}>
        {location && location.custom_menu_properties.menu_logo_url && (
          <div>
            <div className={"margin-bottom-2"}>Currently uploaded logo: </div>
            <img
              alt={"uploaded-logo"}
              src={location.custom_menu_properties.menu_logo_url}
              className={"uploaded-logo-img margin-bottom-2"}
            />
          </div>
        )}
        <ReactFilestack
          apikey={FILESTACK_API_KEY}
          actionOptions={FILESTACK_IMG_OPTIONS}
          componentDisplayMode={{
            customText: "Upload Menu Logo",
            customClass: "btn btn-primary margin-x-auto margin-y-1",
          }}
          onSuccess={this.uploadLogoSuccess}
        />
      </div>
    );
  }
}

export default UploadLogoWidget;
