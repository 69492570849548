import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import SoftwareReleaseUploader from "components/owner-app/pcb-updater/SoftwareReleaseUploader";
import { toast } from "react-toastify";
import axios from "axios";

const AddNewPCBSoftwareModal = ({ show, handleClose, onSubmitCallback, releases }) => {
  const [version, setVersion] = useState("");
  const [signature, setSignature] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [saving, setSaving] = useState(false);

  const clearValues = () => {
    setVersion("");
    setFile("");
    setSignature("");
    setDescription("");
  };

  const handleConfirmation = async (e) => {
    if (!file || !version || !signature) {
      toast.error("You must upload a file and set a version number and signature.");
      return;
    }

    if (
      releases.some((release) => String(release.version) === version) &&
      !window.confirm("This version already exists. Would you like to overwrite it?")
    ) {
      return;
    }

    setSaving(true);
    const payload = {
      version_number: version,
      url: file.url,
      signature: signature,
      description: description,
    };

    try {
      await axios.post("printer-setup/controlbox-software-releases", payload);
      toast.success("Successfully set the new version.");
      onSubmitCallback();
      clearValues();
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error(
        "Unexpected error occurred while trying to upload a new version. I would say to contact support but it's only Justin and Luke so figure it out."
      );
    }
    setSaving(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> New PCB software </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className={"styled-form"}>
            <div className={"margin-bottom-2"}>
              <div>
                <h4>Upload Tarball </h4>
                (Make sure the tarball contains)
                <ul>
                  <li> install.sh</li>
                  <li> printer</li>
                  <li> _version.py</li>
                </ul>
                <SoftwareReleaseUploader onChange={setFile} />
              </div>
              <div>
                <h4>Version</h4>
                <Form.Control
                  type="number"
                  placeholder="i.e 8"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                />
              </div>
              <div className="margin-top-2">
                <h4>Signature</h4>
                <Form.Control
                  type="text"
                  placeholder="i.e cat file.sig | base64"
                  value={signature}
                  onChange={(e) => setSignature(e.target.value)}
                />
              </div>
              <div className="margin-top-2">
                <h4>Description (Optional)</h4>
                <Form.Control
                  type="text"
                  placeholder="i.e python3 upgrade"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirmation} disabled={saving}>
          Set Version
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewPCBSoftwareModal;
