import React, { useState } from "react";
import Button from "@doordash/component-button";
import { TextField } from "@doordash/component-fields";
import { Colors, InlineChildren, StackChildren, Text } from "@doordash/design-language";
import { MenuForm } from "../widgets/UploadMenuAssetsWidget";
import { Divider } from "antd";
import FileUploadProvider from "components/owner-app/file-upload/FileUploadProvider";
import DragDrop from "components/owner-app/file-upload/DragDrop";
import FileUploadManager from "components/owner-app/file-upload/FileUploadManager";
import FileUploadButton from "components/owner-app/file-upload/FileUploadButton";
import { FsFileMetadata } from "components/owner-app/file-upload";

const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

type InitialFormValues = {
  menuName: string;
  menuHours: string;
  menuLink: string;
};

type AddMenusFormProps = {
  onSave: (menuFormValues: MenuForm, uploadedMenuFiles: FsFileMetadata[], onFinally: () => void) => void;
  onCancel: () => void;
  initialState?: InitialFormValues;
  initialFiles?: FsFileMetadata[];
  showCancel?: boolean;
  formLabel?: string;
};

const getInitialState = (initialFormValues?: InitialFormValues) => ({
  menuName: {
    value: initialFormValues?.menuName ?? "",
    error: "",
    isRequired: true,
  },
  menuHours: {
    value: initialFormValues?.menuHours ?? "",
    error: "",
    isRequired: true,
  },
  menuLink: {
    value: initialFormValues?.menuLink ?? "",
    error: "",
    isRequired: false,
  },
});

const AddMenusForm: React.FC<AddMenusFormProps> = ({
  onSave,
  onCancel,
  initialState,
  initialFiles,
  showCancel = true,
  formLabel = "Add New Menu",
}) => {
  const [addMenusFormValues, setAddMenusFormValues] = useState<MenuForm>(getInitialState(initialState));
  const [isSaving, setIsSaving] = useState(false);

  const onFieldChange = (fieldName: keyof MenuForm, fieldValue: string) => {
    setAddMenusFormValues((formValues) => {
      let error = "";
      if (formValues[fieldName].isRequired && !fieldValue.length) {
        error = "This field is required";
      }

      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: error,
          isRequired: formValues[fieldName].isRequired,
        },
      };
    });
  };

  const validateAddMenusForm = (uploadedFiles: FsFileMetadata[]) => {
    let formHasErrors = false;

    const formEntries = Object.entries(addMenusFormValues).map(([fieldName, { value, isRequired }]) => {
      let validationError = "";

      // If there are no uploads and no links, show a validation error
      if (fieldName === "menuLink") {
        if (!value.length && !uploadedFiles.length) {
          formHasErrors = true;
          validationError = "If you do not have a link, please provide a menu file below";
        } else if (!!value.length && (!value.match(URL_REGEX) || value.includes(" "))) {
          formHasErrors = true;
          validationError = "Please enter a valid url";
        }
      }

      const hasValidationError = isRequired && !value.length;
      if (hasValidationError) {
        formHasErrors = true;
        validationError = "This field is required";
      }

      return [
        fieldName,
        {
          value,
          isRequired,
          error: validationError,
        },
      ];
    });

    setAddMenusFormValues(Object.fromEntries(formEntries));
    return !formHasErrors;
  };

  return (
    <StackChildren>
      <Text>{formLabel}</Text>
      <InlineChildren>
        <TextField
          label={"Menu Name"}
          placeholder="Name your menu"
          value={addMenusFormValues.menuName.value}
          error={addMenusFormValues.menuName.error}
          onChange={(menuName: string) => onFieldChange("menuName", menuName)}
        />
        <TextField
          label={"Menu Link"}
          description="Optional"
          placeholder="Menu Link"
          value={addMenusFormValues.menuLink.value}
          error={addMenusFormValues.menuLink.error}
          onChange={(menuLink: string) => onFieldChange("menuLink", menuLink)}
        />
      </InlineChildren>
      <TextField
        label="Available Hours"
        isMultiline
        placeholder="Monday - Friday: 10am - 8pm&#10;Saturday - Sunday: 10am - 6pm"
        value={addMenusFormValues.menuHours.value}
        error={addMenusFormValues.menuHours.error}
        onChange={(menuHours: string) => onFieldChange("menuHours", menuHours)}
      />

      <Divider>
        <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Body2Emphasis} color={Colors.TextSecondary}>
          OR
        </Text>
      </Divider>

      <FileUploadProvider savedFiles={initialFiles} acceptedTypes={["image/jpeg", "image/png", ".pdf"]}>
        <DragDrop>
          <Text textAlign={Text.TextAlignments.Center} styles={Text.Styles.Label2Emphasis} color={Colors.TextTertiary}>
            Upload your menu in PDF, JPG, or PNG formats. <br />
            When submitting JPG or PNG photos, make sure the names of your menu items are clearly visible.
          </Text>
        </DragDrop>
        <FileUploadManager />
        <InlineChildren>
          <FileUploadButton
            isInline
            onClick={({ completedFiles, clearAllCompletedFiles }) => {
              if (!validateAddMenusForm(completedFiles)) {
                return;
              }

              setIsSaving(true);

              onSave(addMenusFormValues, completedFiles, () => setIsSaving(false));
              clearAllCompletedFiles();
            }}
            state={isSaving ? Button.States.Loading : Button.States.Default}
            isDisabled={isSaving}
          >
            Save Menu
          </FileUploadButton>

          {showCancel && (
            <Button isInline type={Button.Types.Tertiary} onClick={onCancel}>
              Cancel
            </Button>
          )}
        </InlineChildren>
      </FileUploadProvider>
    </StackChildren>
  );
};

export default AddMenusForm;
