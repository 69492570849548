import { Select } from "bbot-component-library";
import React from "react";
import styled from "styled-components";

const BbotSelect = ({ options, ...props }) => {
  return (
    <StyledSelect {...props}>
      {options.map((option, i) => (
        <Select.Option key={i} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  &&& {
    .ant-select-selector {
      padding: 4px 8px;
      min-height: 40px;
      border-radius: 8px;
    }
  }
`;

export default BbotSelect;
