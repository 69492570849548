export function getAddressLine(customerAddress, includeLine2 = true) {
  if (!customerAddress.street) return "";

  const address =
    includeLine2 && customerAddress.line2
      ? [customerAddress.street, customerAddress.line2].join(", ")
      : customerAddress.street;
  return [address, customerAddress.city, customerAddress.state, customerAddress.zip].join(", ");
}

const getAddressPart = (place, type, use_short_name = true) => {
  const part = place.address_components.find((part) => part.types.includes(type));
  return part ? (use_short_name ? part.short_name : part.long_name) : "";
};

export const extractBbotAddressFields = (place) => {
  if (!place.address_components || !Array.isArray(place.address_components)) {
    return {};
  }

  const googleAddress = {};

  // Store the number and name separately for validation
  googleAddress.streetNumber = getAddressPart(place, "street_number");
  googleAddress.streetName = getAddressPart(place, "route");

  // Build the fields the endpoint expects to receive
  googleAddress.street = googleAddress.streetNumber + " " + googleAddress.streetName;
  googleAddress.line1 = googleAddress.street;
  googleAddress.city =
    getAddressPart(place, "locality", false) ||
    getAddressPart(place, "sublocality_level_1", false) ||
    getAddressPart(place, "postal_town", false);
  googleAddress.state = getAddressPart(place, "administrative_area_level_1");
  googleAddress.zip = getAddressPart(place, "postal_code");
  googleAddress.country_code = getAddressPart(place, "country");
  // Serializing and deserializing the google place object on saves causes the functions to become properties.
  // On a fresh save they are functions, but on reload save, they are properties.
  if (typeof place.geometry.location.lat === "function") {
    googleAddress.latitude = place.geometry.location.lat();
    googleAddress.longitude = place.geometry.location.lng();
  } else {
    googleAddress.latitude = place.geometry.location.lat;
    googleAddress.longitude = place.geometry.location.lng;
  }
  googleAddress.place_id = place.place_id;
  googleAddress.formatted_address = place.formatted_address;
  return googleAddress;
};
