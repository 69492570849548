import React, { useState } from "react";
import { Button } from "react-bootstrap";

const LocationEditorHelpLinks = () => {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div>
      <Button variant={"link"} onClick={() => setShowLinks((showLinks) => !showLinks)}>
        {`${showLinks ? "Hide Helpful Location Editor Links" : "Helpful Location Editor Links"}`}
      </Button>
      {showLinks && (
        <ul>
          <li>
            <a href="https://central.bbot.menu/article/520-location-codes">Location Code General Information</a>
          </li>
          <li>
            <a href="https://central.bbot.menu/article/512-editing-delivery-locations">Editing Locations Guide</a>
          </li>
          <li>
            <a href="https://central.bbot.menu/article/504-adding-delivery-locations">Adding Locations Guide</a>
          </li>
          <li>
            <a href="https://central.bbot.menu/article/427-menu-not-showing-at-certain-locations">
              Menu Not Showing at Certain Locations Troubleshooting
            </a>
          </li>
          <li>
            <a href="https://central.bbot.menu/article/428-multi-zone-menus-fulfilled-at-a-single-station">
              Multi-Zone Menus Fulfilled at a Single Station
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LocationEditorHelpLinks;
