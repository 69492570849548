import React, { useRef, useState, useEffect } from "react";
import { BorderRadius, Colors, StackChildren, Text } from "@doordash/design-language";
// @ts-ignore
import ImageCropModal from "@doordash/component-image-crop";
import { client } from "filestack-react";

import FileUpload, {
  FileSizes,
  FileState,
  FsFile,
  FsFileMetadata,
  getFileMetadata,
} from "components/owner-app/file-upload";
import { FILESTACK_API_KEY } from "Constants";

import axios from "axios";
import { downloadDataFromBrowser, downloadFileFromUrl, generalErrorAlert } from "util/Utils";
import SavedAssetTile from "components/global/image-uploader/SavedAssetTile";
import Loading from "@doordash/component-loading";
import { Modal } from "@doordash/component-modal";
import styled from "styled-components";

export type UploadAssetsOptions = {
  imageTitle: string;
  minAspectRatio: number;
  maxAspectRatio: number;
  minHeight: number;
  minWidth: number;
  savedFile?: FsFileMetadata;
  aspectRatioDescription?: string;
  onFileSaved: (file: FsFileMetadata, original?: FsFileMetadata) => void;
};

type ImageUploaderAndCropperProps = {
  title: string;
  description?: string;
  acceptedTypes: string[];
  acceptedTypesDescription: string;
  isAdmin: boolean;
  showPreviewOnSide?: boolean;
  isLoading?: boolean;
  directSave?: boolean;

  uploadAssetsOptions: UploadAssetsOptions[];
  savedOriginalFile?: FsFileMetadata;
  onDelete?: () => void;
};

const ImageUploaderAndCropper: React.FC<ImageUploaderAndCropperProps> = ({
  title,
  description,
  acceptedTypes,
  acceptedTypesDescription,
  isAdmin,
  uploadAssetsOptions,
  savedOriginalFile,
  onDelete,
  showPreviewOnSide = false,
  isLoading = false,
  directSave = false,
}) => {
  const [files, setFiles] = useState(uploadAssetsOptions.map((uploadAssetsOption) => uploadAssetsOption.savedFile));
  const [showUploader, setShowUploader] = useState(uploadAssetsOptions.every((option) => !option.savedFile));

  const [originalFile, setOriginalFile] = useState(savedOriginalFile);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const filestackClient = useRef(new client.Client(FILESTACK_API_KEY));

  const [showCropInfoModal, setShowCropInfoModal] = useState(false);

  const [editIndex, setEditIndex] = useState(0);

  useEffect(() => {
    if (uploadAssetsOptions.some((option) => !!option.savedFile)) {
      setFiles(uploadAssetsOptions.map((option) => option.savedFile));
      setShowUploader(false);
    }
  }, [uploadAssetsOptions]);

  useEffect(() => {
    if (savedOriginalFile) {
      setOriginalFile(savedOriginalFile);
    }
  }, [savedOriginalFile]);

  const validateImage = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      const { minWidth, minHeight, minAspectRatio, maxAspectRatio } = uploadAssetsOptions[editIndex] || {};
      image.onload = () => {
        const { height, width } = image;

        if (height < minHeight) {
          resolve("Image height is too short");
        }

        if (width < minWidth) {
          resolve("Image width is too narrow");
        }
        resolve("");
      };
    });

  /**
   * Called when file is initially uploaded, but before cropping happens.
   * @param files
   */
  const onSave = (files: FsFileMetadata[]) => {
    setFiles(uploadAssetsOptions.map((_) => files[0]));
    setOriginalFile(files[0]);
    setShowUploader(false);

    // If called with empty array, don't show edit modal
    if (directSave) {
      uploadAssetsOptions[0].onFileSaved(files[0]);
    } else {
      onEdit();
    }
  };

  const downloadFile = async () => {
    // If only 1 file saved, download it
    if (files.length === 1) {
      const file = files[0];
      if (!file) {
        return;
      }

      try {
        const res = await filestackClient.current.download(file.handle);
        if (!res) {
          return;
        }

        const url = URL.createObjectURL(res.data);
        downloadFileFromUrl(url, file.filename);
        URL.revokeObjectURL(url);
      } catch (error) {
        generalErrorAlert("There was an error downloading the file.");
      }
    } else {
      // download files as a zip
      try {
        const fileHandles: string[] = files.map((savedFile) => savedFile?.handle ?? "");
        const res = await axios.get(`https://cdn.filestackcontent.com/${FILESTACK_API_KEY}/zip/[${fileHandles}]`, {
          withCredentials: false,
          responseType: "arraybuffer",
        });
        downloadDataFromBrowser(res.data, "site-customization-assets");
      } catch (error) {
        generalErrorAlert("There was an error downloading the files.");
      }
    }
  };

  const onRemoveClicked = () => {
    if (onDelete) {
      onDelete();
    }

    setFiles([]);
    setShowUploader(true);
    setIsEditing(false);
  };

  const onEdit = () => {
    if (uploadAssetsOptions.length > 1) {
      setShowCropInfoModal(true);
    } else {
      setIsEditing(true);
    }
  };

  const uploadCallback = async (croppedFile: File) => {
    try {
      setIsUploading(true);
      const result = (await filestackClient.current.upload(croppedFile)) as FsFile;

      if (result.status !== FileState.STORED) {
        throw new Error("Something went wrong");
      }

      const uploaded = getFileMetadata(result, croppedFile);
      const { onFileSaved } = uploadAssetsOptions[editIndex];
      await onFileSaved(uploaded, originalFile);

      // Update state of files with uploaded file
      setFiles((oldFiles) => oldFiles.map((file, index) => (index === editIndex ? uploaded : file)));

      // Update editIndex. If we just uploaded the last image, hide the modal (setIsEditing to false). Otherwise, increment editIndex.
      if (editIndex === uploadAssetsOptions.length - 1) {
        setShowUploader(false);
        setIsEditing(false);
        setEditIndex(0);
      } else {
        // Close the first image cropper
        setIsEditing(false);
        setEditIndex((oldEditIndex) => oldEditIndex + 1);

        // Show the crop info modal again
        setShowCropInfoModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const { minAspectRatio, minWidth, minHeight } = uploadAssetsOptions[editIndex] || {};

  const imageCropModals = files.map((file) => (
    <ImageCropModal
      aspect={minAspectRatio}
      imgSrc={originalFile?.url}
      uploadImageMinWidth={minWidth}
      uploadImageMinHeight={minHeight}
      uploadCallBack={uploadCallback}
      isSaving={isUploading}
      zoomStep={0.1}
      isCircleCrop={false}
      onClose={() => {
        setIsEditing(false);
        setFiles([]);
        setShowUploader(true);
        setEditIndex(0);
      }}
      originalFilename={file?.filename}
    />
  ));

  const uploaderContent = showUploader ? (
    <FileUpload
      onSave={onSave}
      acceptedTypes={acceptedTypes}
      maxFileSize={2 * FileSizes.MB}
      validateFile={validateImage}
    >
      <div className="margin-y-2">
        <Text styles={Text.Styles.Label2Emphasis} color={Colors.TextTertiary}>
          {acceptedTypesDescription}
        </Text>
      </div>
    </FileUpload>
  ) : (
    <SavedAssetTile
      fileOptions={uploadAssetsOptions}
      files={files}
      file={originalFile}
      isAdmin={isAdmin}
      downloadFile={downloadFile}
      onDelete={onRemoveClicked}
      onEdit={onEdit}
      showPreviewOnSide={showPreviewOnSide}
      title={title}
      showEditButton={!directSave}
    />
  );

  return (
    <StackChildren>
      <Text tag="h2" styles={!!description ? Text.Styles.Title1 : Text.Styles.Title2}>
        {title}
      </Text>

      {description && (
        <Text styles={Text.Styles.Body2} color={Text.Colors.Secondary}>
          {description}
        </Text>
      )}

      {isLoading ? (
        <div className="margin-y-6">
          <Loading />
        </div>
      ) : (
        uploaderContent
      )}

      {isEditing && imageCropModals}

      {showCropInfoModal && (
        <Modal
          title={`Crop Landing Page ${uploadAssetsOptions[editIndex].imageTitle}`}
          onClose={() => {
            setShowCropInfoModal(false);
            setEditIndex(0);
          }}
          primaryAction={{
            text: "Continue",
            onClick: () => {
              setShowCropInfoModal(false);
              setIsEditing(true);
            },
          }}
          secondaryAction={{
            text: "Change Photo",
            onClick: () => {
              setShowCropInfoModal(false);
              setShowUploader(true);
            },
          }}
        >
          <ContentBackground>
            <Wireframe isLandscape={uploadAssetsOptions[editIndex].minAspectRatio > 1}>
              <Text textAlign={"center"} styles={Text.Styles.Caption2}>
                {`${uploadAssetsOptions[editIndex].imageTitle} (${uploadAssetsOptions[editIndex].aspectRatioDescription})`}
              </Text>
            </Wireframe>
          </ContentBackground>
        </Modal>
      )}
    </StackChildren>
  );
};

export default ImageUploaderAndCropper;

const ContentBackground = styled.div`
  background-color: ${Colors.BackgroundTertiary};
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: ${BorderRadius.Medium}px;
  display: flex;
  justify-content: center;
`;

interface WireframeProps {
  isLandscape: boolean;
}

const Wireframe = styled.div<WireframeProps>`
  background-color: white;
  border-radius: ${BorderRadius.Medium}px;
  height: ${(props) => (props.isLandscape ? "120px" : "208px")};
  width: ${(props) => (props.isLandscape ? "208px" : "120px")};
  padding: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
