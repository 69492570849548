class SearchSelectInputUtils {
  static getValuesFromMultiSelect = (multiSelectValue) => {
    let values = [];
    multiSelectValue.forEach((st) => {
      values.push(st.value);
    });
    return values;
  };
}

export default SearchSelectInputUtils;
