import React from "react";

import { Row, Col, BbotModal, notification } from "bbot-component-library";
import { DateTime } from "luxon";
import axios from "axios";
import { generalErrorAlert } from "util/Utils";

const DeleteCardRateModal = (props) => {
  const { show, onClose, selectedCardRate } = props;

  const blockFromDeleting =
    DateTime.fromISO(selectedCardRate?.start_date) <
    new DateTime.now().toUTC().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 });

  const deleteCardRate = async () => {
    const { onSave, onClose, selectedCardRate } = props;
    const payload = {
      cardRateId: selectedCardRate?.id,
    };

    try {
      await axios.post("/admin/deleteCardProcessingRate", payload);
      notification.success({
        message: "Successfully deleted card processing rate.",
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble deleting card processing rate. Please refresh and try again.");
    }
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onClose}
      title={"Delete Card Processing Rate"}
      closable
      okText={"Delete"}
      okType={"primary danger"}
      onOk={deleteCardRate}
      okButtonProps={{
        disabled: blockFromDeleting,
        danger: true,
      }}
    >
      <Row>
        <Col>
          <div className={"margin-bottom-2"}>
            You are attempting to delete a Card Processing Rate. Please press "Delete" to confirm this action.
          </div>
          {blockFromDeleting && (
            <div className={"text-danger"}>
              ERROR: You are not permitted to delete Card Processing Rates that are currently occurring or have occurred
              in the past.
            </div>
          )}
        </Col>
      </Row>
    </BbotModal>
  );
};

export default DeleteCardRateModal;
