import React, { useEffect, useState } from "react";
import "styles/customer-journey/widget.scss";
import axios from "axios";
import { getAddressLine } from "util/GooglePlacesUtils";
import PrimaryContactWidget from "components/customer-journey/widgets/PrimaryContactWidget";
import ItContactWidget from "components/customer-journey/widgets/ItContactWidget";
import VenueInfoWidget from "components/customer-journey/widgets/VenueInfoWidget";
import MailingAddressWidget from "components/customer-journey/widgets/MailingAddressWidget";
import { generalErrorAlert } from "util/Utils";
import _ from "lodash";

const CONTACTS_INITIAL_STATE = {
  primary: { first_name: "", last_name: "", email: "", phone: "" },
  it: { first_name: "", last_name: "", email: "", phone: "" },
  support: { email: "", phone: "" },
};
const MAILING_ADDRESS_INITIAL_STATE = {
  recipient: "",
  line2: "",
  query: "",
};
const PHYSICAL_ADDRESS_INITIAL_STATE = {
  recipient: "",
  line2: "",
  query: "",
};

const ContactInfoWidgetV2 = ({ widget, journey, updateTaskStatus }) => {
  const [contacts, setContacts] = useState(CONTACTS_INITIAL_STATE);
  const [mailingAddress, setMailingAddress] = useState(MAILING_ADDRESS_INITIAL_STATE);
  const [physicalAddress, setPhysicalAddress] = useState(PHYSICAL_ADDRESS_INITIAL_STATE);

  const [savedContacts, setSavedContacts] = useState(CONTACTS_INITIAL_STATE);
  const [savedMailingAddress, setSavedMailingAddress] = useState(MAILING_ADDRESS_INITIAL_STATE);
  const [savedPhysicalAddress, setSavedPhysicalAddress] = useState(PHYSICAL_ADDRESS_INITIAL_STATE);

  const [isSavingPrimaryInfo, setIsSavingPrimaryInfo] = useState(false);
  const [isSavingItInfo, setIsSavingItInfo] = useState(false);
  const [isSavingVenueInfo, setIsSavingVenueInfo] = useState(false);
  const [isSavingMailingAddress, setIsSavingMailingAddress] = useState(false);

  const [sameAsPrimaryContact, setSameAsPrimaryContact] = useState(false);
  const [sameAsPhysicalAddress, setSameAsPhysicalAddress] = useState(false);

  useEffect(() => {
    getContactInfo();
  }, [journey.id]);

  useEffect(() => {
    if (_.isEqual(contacts.primary, contacts.it) && Object.values(contacts.primary).every((value) => !!value)) {
      setSameAsPrimaryContact(true);
    }
  }, [contacts]);

  useEffect(() => {
    if (
      mailingAddress.query === physicalAddress.query &&
      mailingAddress.recipient === physicalAddress.recipient &&
      mailingAddress.line2 === physicalAddress.line2 &&
      !!physicalAddress.query
    ) {
      setSameAsPhysicalAddress(true);
    }
  }, [mailingAddress, physicalAddress]);

  useEffect(() => {
    if (sameAsPrimaryContact && Object.values(contacts.primary).every((value) => !!value)) {
      //only want to make api call when there's changes
      !_.isEqual(contacts.primary, contacts.it) &&
        saveItContact({
          it_first_name: contacts.primary.first_name,
          it_last_name: contacts.primary.last_name,
          it_email: contacts.primary.email,
          it_phone: contacts.primary.phone,
        });
    }
  }, [sameAsPrimaryContact]);

  useEffect(() => {
    if (sameAsPhysicalAddress && !!physicalAddress.query) {
      //only want to make api call when there's changes
      !_.isEqual(physicalAddress, mailingAddress) &&
        saveMailingAddress({
          mailing_address: physicalAddress,
        });
    }
  }, [sameAsPhysicalAddress]);

  const getContactInfo = async () => {
    if (!journey) return;

    try {
      const res = await axios.get("api/journey/getContactInfo", {
        params: {
          journey_id: journey.id,
        },
      });

      // set contacts
      setContacts(res.data.contacts);
      setSavedContacts(res.data.contacts);

      // setMailingAddress
      setMailingAddress({
        query: getAddressLine(res.data.mailing_address, false),
        ...res.data.mailing_address,
      });
      setSavedMailingAddress({
        query: getAddressLine(res.data.mailing_address, false),
        ...res.data.mailing_address,
      });

      // set physical address
      setPhysicalAddress({
        query: getAddressLine(res.data.physical_address, false),
        ...res.data.physical_address,
      });
      setSavedPhysicalAddress({
        query: getAddressLine(res.data.physical_address, false),
        ...res.data.physical_address,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble contacting server. Please refresh and try again.", true);
    }
  };

  const updateTaskStatusFromPayload = (contactInfoPayload) => {
    const taskShouldBeFinished = Object.entries(contactInfoPayload).every(([contactField, contactValue]) => {
      if (contactField === "mailing_address" || contactField === "physical_address") {
        return !!contactValue.query;
      } else {
        return !!contactValue;
      }
    });

    if (taskShouldBeFinished) {
      updateTaskStatus("completed", { widgetId: widget.id, goToNext: false });
    }
  };

  const saveContactInfo = async (contactInfoPayloadOverride) => {
    try {
      const payload = {
        journey_id: journey.id,
        // used saved info, only update primary info
        primary_first_name: savedContacts.primary.first_name,
        primary_last_name: savedContacts.primary.last_name,
        primary_email: savedContacts.primary.email,
        primary_phone: savedContacts.primary.phone,
        mailing_address: savedMailingAddress,
        physical_address: savedPhysicalAddress,
        it_first_name: savedContacts.it.first_name,
        it_last_name: savedContacts.it.last_name,
        it_email: savedContacts.it.email,
        it_phone: savedContacts.it.phone,
        support_email: savedContacts.support.email,
        support_phone: savedContacts.support.phone,
        ...contactInfoPayloadOverride,
      };
      await axios.post("/api/journey/contactInfo", payload);
      getContactInfo();
      updateTaskStatusFromPayload(payload);
    } catch (error) {
      setIsSavingPrimaryInfo(false);
      generalErrorAlert(error, "Could not save contact information. Please let us know if this keeps happening.", true);
    }
  };

  const savePrimaryContact = async (values) => {
    const payloadOverrides = {
      it_first_name: sameAsPrimaryContact ? values.primary_first_name : savedContacts.it.first_name,
      it_last_name: sameAsPrimaryContact ? values.primary_last_name : savedContacts.it.last_name,
      it_email: sameAsPrimaryContact ? values.primary_email : savedContacts.it.email,
      it_phone: sameAsPrimaryContact ? values.primary_phone : savedContacts.it.phone,
      ...values,
    };
    setIsSavingPrimaryInfo(true);
    await saveContactInfo(payloadOverrides);
    setIsSavingPrimaryInfo(false);
  };

  const saveItContact = async (values) => {
    setIsSavingItInfo(true);
    await saveContactInfo(values);
    setIsSavingItInfo(false);
  };

  const saveVenueInfo = async (values) => {
    const payloadOverrides = {
      mailing_address: sameAsPhysicalAddress ? values.physical_address : savedMailingAddress,
      ...values,
    };
    setIsSavingVenueInfo(true);
    await saveContactInfo(payloadOverrides);
    setIsSavingVenueInfo(false);
  };

  const saveMailingAddress = async (values) => {
    setIsSavingMailingAddress(true);
    await saveContactInfo(values);
    setIsSavingMailingAddress(false);
  };

  const onSameAsPrimaryContactToggle = (isSame) => {
    setSameAsPrimaryContact(isSame);
  };

  const onSameAsPhysicalAddressToggle = (isSame) => {
    setSameAsPhysicalAddress(isSame);
  };

  return (
    <div>
      <div key={"primary_contact"}>
        <PrimaryContactWidget contacts={contacts} onSave={savePrimaryContact} isSaving={isSavingPrimaryInfo} />
        <hr className={"margin-y-3"} />
      </div>
      <div key={"it_contact"}>
        <ItContactWidget
          contacts={contacts}
          onSave={saveItContact}
          isSaving={isSavingItInfo}
          sameAsPrimaryContact={sameAsPrimaryContact}
          onSameAsPrimaryContactToggle={onSameAsPrimaryContactToggle}
        />
        <hr className={"margin-y-3"} />
      </div>
      <div key={"venue_info"}>
        <VenueInfoWidget
          contacts={contacts}
          physicalAddress={physicalAddress}
          onSave={saveVenueInfo}
          isSaving={isSavingVenueInfo}
        />
        <hr className={"margin-y-3"} />
      </div>
      <div key={"mailing_address"}>
        <MailingAddressWidget
          mailingAddress={mailingAddress}
          physicalAddress={physicalAddress}
          onSave={saveMailingAddress}
          isSaving={isSavingMailingAddress}
          sameAsPhysicalAddress={sameAsPhysicalAddress}
          onSameAsPhysicalAddressToggle={onSameAsPhysicalAddressToggle}
        />
      </div>
    </div>
  );
};

export default ContactInfoWidgetV2;
