import Blank from "assets/svgs/icons/file-icons/file-type-blank-icon.svg";
import Csv from "assets/svgs/icons/file-icons/file-type-csv-icon.svg";
import Doc from "assets/svgs/icons/file-icons/file-type-doc-icon.svg";
import Jpg from "assets/svgs/icons/file-icons/file-type-jpg-icon.svg";
import Pdf from "assets/svgs/icons/file-icons/file-type-pdf-icon.svg";
import Png from "assets/svgs/icons/file-icons/file-type-png-icon.svg";
import Xls from "assets/svgs/icons/file-icons/file-type-xls-icon.svg";

const FileIcons = {
  Blank,
  Csv,
  Doc,
  Jpg,
  Pdf,
  Png,
  Xls,
};

export default FileIcons;
