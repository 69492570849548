import React from "react";
import styled from "styled-components";
import { Form, Radio, Space } from "antd";
import PropTypes from "prop-types";
/*
Stolen from consumer. Generic Radio input
 */
const RadioInput = ({
  button = false,
  buttonStyle,
  direction = "horizontal",
  choices,
  className,
  id,
  label,
  name,
  required = false,
  choiceProps,
  ...props
}) => {
  const Control = button ? Radio.Button : Radio;
  const sortedChoices = choices.slice().sort((a, b) => (a.order < b.order ? -1 : 1));

  return (
    <Form.Item
      className={className}
      label={label}
      name={name}
      required={required}
      validateTrigger={["onBlur", "onChange"]}
    >
      <RadioInput.RadioGroup id={id} className={button ? "button" : ""} buttonStyle={buttonStyle} {...props}>
        <Space direction={direction}>
          {sortedChoices.map((choice, i) => (
            <Control
              style={{ marginBottom: 16 }}
              id={id + "-" + choice.value}
              key={id + "-" + choice.value}
              value={choice.value || choice.label}
              disabled={choice.disabled}
              {...choiceProps}
            >
              {choice.label}
            </Control>
          ))}
        </Space>
      </RadioInput.RadioGroup>
    </Form.Item>
  );
};

export default RadioInput;

RadioInput.RadioGroup = styled(Radio.Group)`
  &:not(.button) {
    width: 100%;

    label {
      padding-bottom: 0.375rem;
      width: 100%;
      &:not(:last-of-type) {
        margin-bottom: 0.375rem;
      }
      .ant-radio {
        top: 0.35rem;
      }
      span.ant-radio + * {
        width: 100%;
      }

      & > span:not(.ant-radio) {
        display: flex;
        font-weight: 500;
        font-size: 0.875rem;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.4;

        span {
          display: inline-block;
        }

        span:first-of-type {
          padding-right: 2rem;
        }
      }
    }
  }
`;

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  button: PropTypes.bool,
  buttonStyle: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};
