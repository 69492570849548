import { FileState, FsFile } from "./types";

const FIXTURES_PATH = "cypress/fixtures/journeys/file-uploads";
export const FILE_NAMES = {
  FAILED_ONCE: "failed.jpeg",
  INVALID_FILE_TYPE: "invalid_file_type.webp",
  SUCCESS1: "success1.png",
  SUCCESS2: "success2.jpeg",
  TOO_LARGE: "too_large.zip",
  TOO_SMALL: "too_small.csv",
};
export const FILE_PATHS = Object.fromEntries(
  Object.entries(FILE_NAMES).map(([name, file]) => [name, `${FIXTURES_PATH}/${file}`])
);

export default class MockClient {
  private metadata = new Map<string, FsFile>();
  private handle = 0;
  private hasFailed = false;

  public download(handle: string, security?: any): Promise<FsResponse> {
    // probably no point in testing this, but if we want to...
    // https://stackoverflow.com/questions/66478056/cypress-how-to-verify-if-a-file-is-downloaded
    return Promise.resolve({ status: 0, statusText: "", headers: {}, data: {}, config: {} });
  }

  public upload(file: File, options?: UploadOptions, storeOptions?: any, token: Token = defaultToken, security?: any) {
    console.log("UPLOADING DO");
    const fileMetadata = this.getFileMetadata(file);
    this.metadata.set(fileMetadata.handle, fileMetadata);
    return this.handleUpload(fileMetadata, options, token);
  }

  private getFileMetadata(file: File): FsFile {
    this.handle += 1;
    const handle = this.handle.toString();
    return {
      status: FileState.INIT,
      size: file.size,
      url: `https://cdn.filstackcontent.com/${handle}`,
      handle,
      _file: {
        name: file.name,
        size: file.size,
        type: file.type,
      },
      container: undefined,
      key: undefined,
      workflows: undefined,
      uploadTags: undefined,
    };
  }

  private handleUpload(file: FsFile, options: UploadOptions = {}, token: Token) {
    return new Promise((resolve, reject) => {
      const progressInterval = options?.progressInterval ?? 250;
      const onProgress = options?.onProgress ?? defaultOnProgress;
      const progress = { totalPercent: 0, totalBytes: 0 };

      onProgress(progress);

      // between 1-5 ticks: 100 bytes = 1 tick, 1kb = 2, 10kb = 3, 100kb = 4, >= 1mb = 5
      let ticksRemain = Math.max(1, Math.min(5, Math.ceil(Math.log10(file.size / 100))));
      let bytesPerTick = file.size / ticksRemain;
      let percentPerTick = 100 / ticksRemain;

      const intervalTimeout = setInterval(() => {
        progress.totalBytes = Math.min(file.size, progress.totalBytes + bytesPerTick);
        progress.totalBytes = Math.min(100, progress.totalPercent + percentPerTick);
        onProgress(progress);
        ticksRemain -= 1;

        if (file._file.name === FILE_NAMES.FAILED_ONCE && !this.hasFailed) {
          this.hasFailed = true;
          file.status = FileState.FAILED;
          clearInterval(intervalTimeout);
          resolve(file);
        }

        if (!ticksRemain) {
          file.status = FileState.STORED;
          clearInterval(intervalTimeout);
          resolve(file);
        }
      }, progressInterval);

      token.cancel = () => {
        clearInterval(intervalTimeout);
        reject("Aborted by user");
      };
    });
  }
}

const defaultToken = {
  pause: () => {},
  cancel: () => {},
  resume: () => {},
};

const defaultOnProgress = () => {};

type UploadOptions = {
  partSize?: number;
  concurrency?: number;
  onProgress?: (evt: FSProgressEvent) => void;
  progressInterval?: number;
  onRetry?: () => void;
  retry?: number; // Retry limit
  retryFactor?: number;
  retryMaxTime?: number;
  timeout?: number;
};

type FSProgressEvent = {
  totalPercent: number;
  totalBytes: number;
};

type Token = {
  pause: () => void;
  resume: () => void;
  cancel: () => void;
};

type FsResponse = {
  status: number;
  statusText: string;
  headers: any;
  data: any;
  config: any;
};
