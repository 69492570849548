import React from "react";

import { Modal, Button } from "react-bootstrap";

class DestructiveActionWarningModal extends React.Component {
  render() {
    const { show, title, handleCancel, handleContinue, warningText, onHide, centered, cancelText, continueText } =
      this.props;

    return (
      <Modal show={show} onHide={onHide} centered={centered}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{warningText}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button variant="danger" onClick={handleContinue}>
            {continueText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DestructiveActionWarningModal;
