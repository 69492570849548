import React from "react";
import { Button } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";

const SubmitCancelButtonsWithLoader = (props) => {
  const { errors, onCancel, loadingData } = props;
  return (
    <>
      <Button size={"sm"} variant={"light"} onClick={onCancel} className={"margin-right-1"} disabled={loadingData}>
        Cancel
      </Button>
      {loadingData ? (
        <Button size={"sm"} type={"submit"} disabled={true}>
          <Loader props />
        </Button>
      ) : (
        <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0}>
          Submit
        </Button>
      )}
    </>
  );
};

export default SubmitCancelButtonsWithLoader;
