import React from "react";

import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { generalErrorAlert } from "../../../../util/Utils";

class SevenRoomsIntegrationModal extends React.Component {
  disconnectSevenRooms = async () => {
    const { selectedCustomer, onClose, onSave } = this.props;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
    };

    try {
      await axios.post("/admin/disconnectSevenRooms", payload);
      toast.success("Successfully disconnected from SevenRooms.");
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble disconnecting from SevenRooms.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect SevenRooms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect SevenRooms, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button size={"sm"} variant={"danger"} onClick={this.disconnectSevenRooms}>
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Bbot Support</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"padding-x-4"}>
          <div className={"margin-bottom-2"}>
            <p>To enable this feature, contact support@bbot.menu and we will add this to your account.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size={"sm"}
            variant={"light"}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SevenRoomsIntegrationModal;
