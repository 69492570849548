import React from "react";

// Components
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Col, Row, Table, Container, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateEditServiceRequestModal from "components/owner-app/modals/service-requests/CreateEditServiceRequestModal";
import CreateDefaultServiceRequestModal from "components/owner-app/modals/service-requests/CreateDefaultServiceRequestModal";

const defaultServiceRequests = [
  {
    type: "Water",
    label_for_patron: "Bring Water",
    description_for_patron: "",
    message_to_staff: "This table has requested more water.",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Cutlery",
    label_for_patron: "Bring Silverware",
    description_for_patron: "",
    message_to_staff: "This table has requested more silverware.",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Napkins",
    label_for_patron: "Bring Napkins",
    description_for_patron: "",
    message_to_staff: "This table has requested more napkins.",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Box",
    label_for_patron: "Get a Take-Out Box",
    description_for_patron: "",
    message_to_staff: "This table has requested a take-out box.",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Service",
    label_for_patron: "General Service",
    description_for_patron: "",
    message_to_staff: "This table has requested a server for general service.",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Guest Message",
    label_for_patron: "Send a custom message to you server",
    description_for_patron: "",
    message_to_staff: "",
    free_text: true,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
  {
    type: "Custom",
    label_for_patron: "",
    description_for_patron: "",
    message_to_staff: "",
    free_text: false,
    show_only_after_checkout: true,
    rate_limit_between_requests: 30,
  },
];

class ServiceRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequests: [],
      locations: [],
      locationsByCustomerAndFulfillmentMethod: {},
      fulfillmentMethodsPrettyNames: {},
      createEditServiceRequestModalMode: null,
      showCreateEditServiceRequestModal: false,
      showCreateDefaultServiceRequestModal: false,
      selectedServiceRequest: null,
      showLocationErrorMessage: false,
    };
  }
  componentDidMount() {
    this.getServiceRequests();
    this.getLocations();
    this.getFMNPrettyNames();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getServiceRequests();
      this.getLocations();
      this.getFMNPrettyNames();
    }
  }

  getServiceRequests = async () => {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) return;
    try {
      const params = { customerId: selectedCustomer.customer_id };
      const res = await axios.get("/owner/serviceRequestsForCustomer", {
        params: params,
      });
      this.setState({ serviceRequests: res.data.service_requests });
    } catch (error) {
      console.error("/owner/serviceRequestsForCustomer failed with error", error);
      toast.error("Trouble retrieving service requests. Please refresh and try again.");
    }
  };

  getLocations = async () => {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) return;
    try {
      const params = { customer_id: selectedCustomer?.customer_id };
      const res = await axios.get("/owner/locations", { params: params });
      this.setState({
        locations: res.data.locations,
        showLocationErrorMessage: !res.data.locations.find((location) => location.allow_service_requests),
      });

      let locationsByCustomerAndFulfillmentMethod = {};

      res.data.locations.forEach((location) => {
        if (!(location.customer_id in locationsByCustomerAndFulfillmentMethod)) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id] = {};
        }

        if (!(location.fulfillment_method in locationsByCustomerAndFulfillmentMethod[location.customer_id])) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method] = [];
        }

        locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method].push(location);
      });
      this.setState({
        locationsByCustomerAndFulfillmentMethod: locationsByCustomerAndFulfillmentMethod,
      });
    } catch (error) {
      console.error("owner/locations failed with error", error);
    }
  };

  getFMNPrettyNames = async () => {
    try {
      const res = await axios.get("api/getFulfillmentMethods");
      this.setState({
        fulfillmentMethodsPrettyNames: res.data.fulfillment_methods_pretty_names,
      });
    } catch (error) {
      console.error("api/getFulfillmentMethods failed with error", error);
    }
  };

  deleteServiceRequest = async (serviceRequestId) => {
    const { selectedCustomer } = this.props;
    const payload = {
      serviceRequestId: serviceRequestId,
      customerId: selectedCustomer?.customer_id,
    };
    try {
      await axios.post("/owner/deleteServiceRequest", payload);
      toast.success("Successfully deleted service request.");
      await this.getServiceRequests();
    } catch (error) {
      console.error("/owner/deleteServiceRequest failed with error ", error);
      toast.error("Trouble deleting service request. Please refresh and try again.");
    }
  };

  changeServiceRequestOrder = async (result) => {
    if (!result.destination) return;

    const payload = {
      serviceRequestId: result.draggableId,
      direction: result.destination.index,
    };
    try {
      await axios.post("/owner/editServiceRequest", payload);
      await this.getServiceRequests();
    } catch (error) {
      console.error("/admin/editCardProcessingRate failed with error ", error);
      toast.error("Could not change order of service requests. Please refresh and try again.");
    }
  };

  loadAllDefaultServiceRequests = async () => {
    const { selectedCustomer } = this.props;
    const { locations } = this.state;

    const payload = {
      customerId: selectedCustomer?.customer_id,
      locations: locations.map((location) => location.locationId),
      serviceRequests: defaultServiceRequests.slice(0, -1), // last one is Custom, which is not a default
    };

    try {
      await axios.post("/owner/createServiceRequest", payload);
      await this.getServiceRequests();
      toast.success("Successfully loaded default service requests.");
    } catch (error) {
      toast.error("Trouble saving service requests. Please refresh and try again.");
      console.error("/owner/createServiceRequest failed with error ", error);
    }
  };

  getStyle = async (style, snapshot) => {
    if (style.transform) {
      const axisLockY = "translate(0px" + style.transform.slice(style.transform.indexOf(","), style.transform.length);
      return {
        ...style,
        transform: axisLockY,
      };
    }
    return style;
  };

  render() {
    const { selectedCustomer, allowedCustomersById } = this.props;
    const {
      serviceRequests,
      selectedServiceRequest,
      locations,
      locationsByCustomerAndFulfillmentMethod,
      fulfillmentMethodsPrettyNames,
      createEditServiceRequestModalMode,
      showCreateEditServiceRequestModal,
      showCreateDefaultServiceRequestModal,
      showLocationErrorMessage,
    } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-row margin-bottom-4">
                <span>
                  <Link to="/" className="breadcrumb-back">
                    &#60; Back
                  </Link>
                </span>
                <span className="breadcrumbs">
                  <span>
                    <Link to="/" className="breadcrumb-link">
                      Owner Portal
                    </Link>
                  </span>
                  <span className="breadcrumb-link">&#62;</span>
                  <span>
                    <Link to="/service-requests" className="breadcrumb-link">
                      Service Requests
                    </Link>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="margin-bottom-4">
                <h2>
                  Manage Service Requests For{" "}
                  <span className="color-primary__dark">{selectedCustomer?.customer_name}</span>
                </h2>
              </div>
              <div className="margin-bottom-4">
                <p>
                  Service requests are options that patrons have for contacting your kitchen staff quickly and easily.
                  Things such as "Bring more water" or "Can we have a box" are especially helpful service requests to
                  add for your patrons. You can configure service requests to appear only after checkout. Messages will
                  be sent directly to your staff for quick and easy responses.
                </p>
                <p>
                  You may add some of our default service requests, or configure your own. There is also a 'Custom
                  Message' option which allows patrons to send custom messages back to your kitchen.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  For service requests to appear to your customers, you must edit any locations you want service
                  requests to be visible to 'Allow Service Requests' via the{" "}
                  <Link to="/edit-locations">Tables / Location Codes page</Link>. For staff to receive service requests,
                  you must be on Terminal v2.8.0 or greater and they must be enabled on the device profile.
                </p>
              </div>
            </Col>
          </Row>
          {!serviceRequests.length && (
            <Row className={"margin-bottom-4"}>
              <Col>
                <Alert variant={"primary"}>
                  Looks like you have no service request options configured. Press the button below to load in all
                  default service request options or use this page to create your own.
                </Alert>
                <Button onClick={this.loadAllDefaultServiceRequests}>Load All Default Service Request Options</Button>
              </Col>
            </Row>
          )}
          {showLocationErrorMessage && (
            <Row className={"margin-bottom-4"}>
              <Col>
                <Alert variant={"danger"}>
                  You currently have no associated locations with 'Allow Service Requests' turned on. You will need to
                  change that <Link to="edit-locations">here</Link> if you want service requests to appear to your
                  patrons.
                </Alert>
              </Col>
            </Row>
          )}
          <Row className={"margin-bottom-4"}>
            <Col>
              <div className={"table-container"}>
                <div className={"header bg-blue-6"}>
                  <h4>Service Requests</h4>
                </div>
                <div className={"table-scroll-container"}>
                  <Table hover size={"sm"}>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th className={"flex"}>Label for Patron</th>
                        <th width={"20%"}>Actions</th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={this.changeServiceRequestOrder}>
                      <Droppable droppableId={"droppable"}>
                        {(provided, snapshot) => (
                          <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {serviceRequests
                              .sort((a, b) => (a.display_order < b.display_order ? -1 : 1))
                              .map((request) => (
                                <Draggable
                                  key={request.id + request.type}
                                  draggableId={request.id.toString()}
                                  index={request.display_order}
                                >
                                  {(provided) => {
                                    return (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td>{request.type}</td>
                                        <td>{request.label_for_patron}</td>
                                        <td>
                                          <i
                                            className={"zmdi zmdi-hc-2x zmdi-edit margin-left-2"}
                                            onClick={() => {
                                              this.setState({
                                                showCreateEditServiceRequestModal: true,
                                                createEditServiceRequestModalMode: "edit",
                                                selectedServiceRequest: request,
                                              });
                                            }}
                                          />
                                          <i
                                            className={"zmdi zmdi-hc-2x zmdi-delete margin-left-4"}
                                            onClick={() => {
                                              this.deleteServiceRequest(request.id);
                                            }}
                                          />
                                          <i className={"zmdi zmdi-hc-2x zmdi-unfold-more margin-left-4"} />
                                        </td>
                                      </tr>
                                    );
                                  }}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
                <button
                  className="add-item"
                  onClick={() => {
                    this.setState({
                      showCreateDefaultServiceRequestModal: true,
                    });
                  }}
                >
                  <div className={"icon-circle"}>+</div>
                  <span>New Service Request</span>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
        <CreateEditServiceRequestModal
          show={showCreateEditServiceRequestModal}
          mode={createEditServiceRequestModalMode}
          selectedCustomer={selectedCustomer}
          selectedServiceRequest={selectedServiceRequest}
          locations={locations}
          locationsByCustomerAndFulfillmentMethod={locationsByCustomerAndFulfillmentMethod}
          fulfillmentMethodsPrettyNames={fulfillmentMethodsPrettyNames}
          allowedCustomersById={allowedCustomersById}
          onClose={() => this.setState({ showCreateEditServiceRequestModal: false })}
          onSave={this.getServiceRequests}
        />
        <CreateDefaultServiceRequestModal
          show={showCreateDefaultServiceRequestModal}
          selectedCustomer={selectedCustomer}
          serviceRequests={serviceRequests}
          locations={locations}
          defaultServiceRequests={defaultServiceRequests}
          onClose={(openCustomModal = false) => {
            this.setState({ showCreateDefaultServiceRequestModal: false });
            if (openCustomModal) {
              this.setState({
                showCreateEditServiceRequestModal: true,
                createEditServiceRequestModalMode: "create",
              });
            }
          }}
          onSave={this.getServiceRequests}
        />
      </div>
    );
  }
}

export default ServiceRequests;
