import React from "react";
import axios from "axios";
import PaymentWidget from "components/customer-journey/widgets/PaymentWidget";
import InviteUsersWidget from "components/customer-journey/widgets/InviteUsersWidget";
import UploadMenuAssetsWidget from "components/customer-journey/widgets/UploadMenuAssetsWidget";
import UploadBOSAssetsWidget from "components/customer-journey/widgets/UploadBOSAssetsWidget";
import ConnectStripeWidget from "components/customer-journey/widgets/ConnectStripeWidget";
import OrderingPageWidget from "components/customer-journey/widgets/OrderingPageWidget";
import ChooseHostnameWidget from "components/customer-journey/widgets/ChooseHostnameWidget";
import TaxRateWidget from "components/customer-journey/widgets/TaxRateWidget";
import TermsAndConditionsWidget from "components/customer-journey/widgets/TermsAndConditionsWidget";
import InitialDepositWidget from "components/customer-journey/widgets/InitialDepositWidget";
import UploadLogoWidget from "components/customer-journey/widgets/UploadLogoWidget";
import ContactInfoWidgetV2 from "components/customer-journey/widgets/ContactInfoWidgetV2";

const Widget = (props) => {
  // Renders the component specified by widget_type
  let { widget, task, journey, updateTaskStatus, userInfo, allowedCustomersById } = props;
  switch (widget.widget_type) {
    case "connect_stripe":
      return (
        <ConnectStripeWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          customerId={journey?.customer_id}
          userInfo={props.userInfo}
          showDashboardLink={false}
        />
      );
    case "brand_assets":
      return (
        <UploadBOSAssetsWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          customerId={journey?.customer_id ?? ""}
          userInfo={userInfo}
        />
      );
    case "choose_hostname":
      return <ChooseHostnameWidget widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    case "ordering_page":
      return (
        <OrderingPageWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          userInfo={props.userInfo}
        />
      );
    case "connect_payment":
      return (
        <PaymentWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          customerId={journey?.customer_id}
          customersById={allowedCustomersById}
        />
      );
    case "invite_employees":
      return <InviteUsersWidget widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    case "contact_info":
      return <ContactInfoWidgetV2 widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    case "upload_menu":
      return (
        <UploadMenuAssetsWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          userInfo={userInfo}
        />
      );
    case "tax_rate":
      return <TaxRateWidget widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    case "terms_and_conditions":
      return (
        <TermsAndConditionsWidget
          widget={widget}
          task={task}
          journey={journey}
          updateTaskStatus={updateTaskStatus}
          userInfo={userInfo}
        />
      );
    case "initial_deposit":
      return <InitialDepositWidget widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    case "upload_logo":
      return <UploadLogoWidget widget={widget} task={task} journey={journey} updateTaskStatus={updateTaskStatus} />;
    default:
      throw Error("You didn't make a component for widget type " + widget.widget_type);
  }
};

export const getWidgetData = (widget_id) => axios.get("/api/journey/getWidgetData", { params: { widget_id } });

export const updateWidgetData = ({ widgetId, widgetData }) =>
  axios.post("/api/journey/updateWidgetData", {
    widget_id: widgetId,
    widget_data: widgetData,
  });

export default Widget;
