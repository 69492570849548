import React, { useState } from "react";
import ReactFilestack from "filestack-react";
import { FILESTACK_API_KEY } from "Constants";
import "styles/customer-journey/widget.scss";

const FILESTACK_IMG_OPTIONS = {
  fromSources: ["local_file_system", "googledrive", "url"],
  maxFiles: 1,
};

const SoftwareReleaseUploader = (props) => {
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState(null);

  const { onChange } = props;

  const uploadSoftwareSuccess = (event) => {
    const { filesUploaded } = event;
    setPreview(true);
    setFile(filesUploaded[0]);
    onChange(filesUploaded[0]);
  };

  return (
    <div>
      <div className={"upload-logo-widget padding-bottom-3"} style={{ display: "inline-block" }}>
        <ReactFilestack
          apikey={FILESTACK_API_KEY}
          actionOptions={FILESTACK_IMG_OPTIONS}
          componentDisplayMode={{
            customText: "Select File",
            customClass: "btn btn-primary margin-x-auto ",
          }}
          onSuccess={uploadSoftwareSuccess}
        />
      </div>
      {preview && <span> {file?.filename} </span>}
    </div>
  );
};

export default SoftwareReleaseUploader;
