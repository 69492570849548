import React from "react";
import axios from "axios";

import { Button, Col, Modal, Form, Spinner } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { NumberInput, DateInput, CheckboxInput, SearchSelectInput } from "components/global/form";
import { customerSortAdmin, generalErrorAlert, isoToReadableDate } from "util/Utils";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import "./AddCutAgreementModal.scss";
import { CUT_AGREEMENT_RETRO_USERS } from "Constants";
import Tag from "@doordash/component-tag";

class AddCutAgreementModal extends React.Component {
  state = {
    orderFeeConfigs: [], // used by outbound
    selectedCustomerOrderFeeConfigs: [], // used by inbound
    showEndDatePicker: false,
    externalVendorsIds: [],
    vendeesIds: [],
    selectedCustomerLocationsById: {},
    locationsById: {},
    isSaving: false,
  };

  componentDidMount() {
    this.getLocationsAndOrderConfigs();
    this.getVendorsVendees();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getLocationsAndOrderConfigs();
      this.getVendorsVendees();
    }

    if (prevProps.inbound !== this.props.inbound) {
      this.getLocationsAndOrderConfigs();
    }
  }

  getLocationsAndOrderConfigs = async () => {
    const { selectedCustomer } = this.props;
    if (selectedCustomer?.customer_id) {
      await this.getLocations(selectedCustomer.customer_id, true);
      await this.getOrderFeeConfigs(selectedCustomer.customer_id, true);
    }
  };

  getVendorsVendees = async () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer) return;

    try {
      const res = await axios.get("/admin/getVendorsVendees", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      this.setState({
        externalVendorsIds: res.data.vendors,
        vendeesIds: res.data.vendees,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getLocations = async (customerId, forSelectedCustomer = false) => {
    const { inbound } = this.props;
    const { externalVendorsIds, vendeesIds, selectedCustomerLocationsById } = this.state;

    try {
      let params = { customer_id: customerId };

      const res = await axios.get("/owner/locations/", { params: params });

      // Group locations by customer id then fulfillment method
      let locationsByCustomerAndFulfillmentMethod = {};
      res.data.locations.forEach((location) => {
        if (!(location.customer_id in locationsByCustomerAndFulfillmentMethod)) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id] = {};
        }
        if (!(location.fulfillment_method in locationsByCustomerAndFulfillmentMethod[location.customer_id])) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method] = [];
        }

        locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method].push(location);
      });

      if (forSelectedCustomer) {
        // if fetching for customer selected in navbar
        if (vendeesIds.includes(customerId)) {
          locationsByCustomerAndFulfillmentMethod = {
            ...locationsByCustomerAndFulfillmentMethod,
            ...selectedCustomerLocationsById,
          };
        }

        this.setState({
          selectedCustomerLocationsById: locationsByCustomerAndFulfillmentMethod,
        });
      } else {
        // if fetching for customer selected in modal
        if (inbound && externalVendorsIds.includes(customerId)) {
          // if inbound and customer selected in modal is a child/vendor, add in the parents locations (navbar selected)
          locationsByCustomerAndFulfillmentMethod = {
            ...locationsByCustomerAndFulfillmentMethod,
            ...selectedCustomerLocationsById,
          };
        }

        this.setState({
          locationsById: locationsByCustomerAndFulfillmentMethod,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getOrderFeeConfigs = async (customerId, forSelectedCustomer = false) => {
    try {
      const res = await axios.get("/api/getAllOrderFeeConfigs", {
        params: {
          customer_id: customerId,
          include_vendor_fee_configs: true,
          include_ended_fee_configs: true,
        },
      });
      if (forSelectedCustomer) {
        this.setState({
          selectedCustomerOrderFeeConfigs: res.data.order_fee_configs,
        });
      } else {
        this.setState({ orderFeeConfigs: res.data.order_fee_configs });
      }
    } catch (error) {
      console.error(error);
    }
  };

  customerSelected = (customerId) => {
    const { inbound, selectedCustomer } = this.props;
    const { vendeesIds } = this.state;

    if (inbound) {
      // get locations for the customer
      this.setState(
        {
          locationsById: {},
          orderFeeConfigs: [],
        },
        () => {
          this.getLocations(customerId);
          this.getOrderFeeConfigs(customerId);
        }
      );
    } else if (vendeesIds.includes(customerId)) {
      // if outbound and vendee (parent) is selected, show my locations + parents locations
      this.getLocations(customerId, true);
    } else {
      // if outbound and not a vendee is selected, just show my locations
      this.getLocations(selectedCustomer.customer_id, true);
    }
  };

  getCustomerName = (customerId) => {
    const { allowedCustomersById } = this.props;
    const customer = allowedCustomersById[customerId];
    return customer?.name_for_admin;
  };

  saveCutAgreement = async (values) => {
    const { onClose, onSave, inbound, selectedCustomer } = this.props;
    if (!values.customer) {
      toast.info("Please select a customer from the dropdown.");
      return;
    }

    this.setState({ isSaving: true });
    try {
      const payload = {
        inbound: inbound,
        source_customer_id: inbound ? values.customer : selectedCustomer?.customer_id,
        destination_customer_id: inbound ? selectedCustomer?.customer_id : values.customer,
        just_specific_locations: !values.appliesToAllLocations,
        just_specific_feeconfigs: values.appliesToSpecificOrderFeeConfigs,
        specific_feeconfigs_ids: values.specific_feeconfigs_ids,
        specific_locations_ids: values.specific_locations_ids,
        fraction_of_tip: values.percentage_of_tip / 100,
        fraction_of_items_pretax: values.percentage_of_items_pretax / 100,
        fraction_of_items_tax: values.percentage_of_items_tax / 100,
        fixed_cents: values.fixed_cents,
        take_fixed_cents_from_free_orders: values.take_fixed_cents_from_free_orders,
        fraction_of_servicecharge_pretax: values.percentage_of_servicecharge_pretax / 100,
        fraction_of_servicecharge_tax: values.percentage_of_servicecharge_tax / 100,
        start_date: DateTime.fromJSDate(values.start_date).toUTC().set({ hour: 0, minute: 0, seconds: 0 }),
        end_date: DateTime.fromJSDate(values.end_date).toUTC().set({ hour: 0, minute: 0, seconds: 0 }),
      };
      if (window.confirm("Are you sure you would like to save these changes?")) {
        await axios.post("/admin/saveCutAgreement", payload);
        onSave();
        onClose();
        this.setState({
          showEndDatePicker: false,
        });
      }
    } catch (err) {
      generalErrorAlert(err, "Could not save this cut agreement.");
    } finally {
      this.setState({ isSaving: false });
    }
  };

  render() {
    const { allowedCustomers, selectedCustomer, show, inbound, onClose, fulfillmentMethodsPrettyNames, userInfo } =
      this.props;
    const {
      locationsById,
      orderFeeConfigs,
      selectedCustomerLocationsById,
      selectedCustomerOrderFeeConfigs,
      showEndDatePicker,
      isSaving,
    } = this.state;

    const customers = allowedCustomers
      .filter((customer) => customer.customer_id !== selectedCustomer?.customer_id)
      .sort(customerSortAdmin)
      .map((customer) => ({
        value: customer.customer_id,
        label: customer.name_for_admin,
      }));

    const now = DateTime.now();

    return (
      <Modal
        show={show}
        onHide={() => {
          this.setState({ showEndDatePicker: false });
          onClose();
        }}
        size={"medium"}
      >
        <Formik
          initialValues={{
            customer: "",
            percentage_of_tip: 0,
            percentage_of_items_pretax: 0,
            percentage_of_items_tax: 0,
            fixed_cents: 0,
            take_fixed_cents_from_free_orders: false,
            percentage_of_servicecharge_pretax: 0,
            percentage_of_servicecharge_tax: 0,
            appliesToAllLocations: true,
            appliesToSpecificOrderFeeConfigs: false,
            start_date: now.set({ hour: 12 }).toJSDate(),
            end_date: DateTime.fromObject({
              year: 2199,
              month: 1,
              day: 1,
              hour: 12,
            }).toJSDate(), // set the end date to 1/1/2199
            specific_fee_configs_ids: [],
            specific_locations_ids: [],
          }}
          validationSchema={Yup.object({
            percentage_of_tip: Yup.number().max(100).min(0).required(),
            percentage_of_items_pretax: Yup.number().max(100).min(0).required(),
            percentage_of_items_tax: Yup.number().max(100).min(0).required(),
            fixed_cents: Yup.number().min(0),
            take_fixed_cents_from_free_orders: Yup.boolean(),
            percentage_of_servicecharge_pretax: Yup.number().max(100).min(0).required(),
            percentage_of_servicecharge_tax: Yup.number().max(100).min(0).required(),
            start_date: CUT_AGREEMENT_RETRO_USERS.includes(userInfo?.email)
              ? Yup.date()
                  .transform((val) => (isNaN(val) ? undefined : val))
                  .required()
              : Yup.date()
                  .transform((val) => (isNaN(val) ? undefined : val))
                  .required()
                  .min(
                    now.toUTC().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }),
                    "Start date must be equal to or later than current date."
                  ),
            end_date: Yup.date()
              .transform((val) => (isNaN(val) ? undefined : val))
              .when(
                "start_date",
                (start_date, schema) => start_date && schema.min(start_date, "End date must be later than start date")
              ),
          })}
          onSubmit={this.saveCutAgreement}
        >
          {({ values, errors, touched, isValidating, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>{`New ${inbound ? "Inbound" : "Outbound"} Cut Agreement`}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row>
                  <SearchSelectInput
                    md={12}
                    name={"customer"}
                    id={"customer-react-select"}
                    label={inbound ? "Who is the cut coming from?" : "Who is the cut going to?"}
                    placeholder={"Select a customer"}
                    options={inbound ? customers : [{ value: "BBOT", label: "Bbot" }].concat(customers)}
                    onChange={(option) => {
                      this.customerSelected(option.value);
                    }}
                  />
                </Form.Row>
                <Form.Row>
                  <DateInput md={12} name={"start_date"} id="start_date" label={"Start Date"} />
                  {showEndDatePicker ? (
                    <DateInput md={12} name={"end_date"} id="end_date" label={"End Date"} />
                  ) : (
                    <Col md={12} className="margin-bottom-2">
                      <Form.Label>End Date</Form.Label>
                      <Button
                        size={"lg"}
                        variant={"outline-primary"}
                        onClick={() => {
                          setFieldValue(
                            "end_date",
                            now.set({ hour: 12, minute: 0, seconds: 0 }).plus({ years: 1 }).toJSDate()
                          );
                          this.setState({ showEndDatePicker: true });
                        }}
                      >
                        Use Custom End Date
                      </Button>
                    </Col>
                  )}
                </Form.Row>
                <Form.Row>
                  <NumberInput name="percentage_of_tip" id="percentage-tip" label={"Percentage Of Tip"} />
                </Form.Row>
                <Form.Row>
                  <NumberInput
                    name="percentage_of_items_pretax"
                    id="percentage-items-pretax"
                    label={"Percentage Of Items Pretax"}
                    sm={12}
                  />
                </Form.Row>
                <Form.Row>
                  <NumberInput
                    name="percentage_of_items_tax"
                    id="percentage-items-tax"
                    label={"Percentage Of Items Tax"}
                    sm={12}
                  />
                </Form.Row>
                {values.customer === "BBOT" && (
                  <Form.Row>
                    <NumberInput name="fixed_cents" id="bbot-fixed-cents" label={"Bbot Fixed Cents"} />
                  </Form.Row>
                )}
                {values.customer === "BBOT" && (
                  <Form.Row>
                    <CheckboxInput
                      name="take_fixed_cents_from_free_orders"
                      id="take-fixed-cents-from-free-orders"
                      label={"Take Fixed Cents From Free Orders"}
                      disabled={values.customer !== "BBOT"}
                    />
                  </Form.Row>
                )}
                <Form.Row>
                  <NumberInput
                    name="percentage_of_servicecharge_pretax"
                    id="percentage-servicecharge-pretax"
                    label={"Percentage Of Service Charge Pretax"}
                  />
                </Form.Row>
                <Form.Row className={"margin-bottom-2"}>
                  <NumberInput
                    name="percentage_of_servicecharge_tax"
                    id="percentage-servicecharge-tax"
                    label={"Percentage Of Service Charge Tax"}
                  />
                </Form.Row>
                <Form.Row>
                  <CheckboxInput
                    md={12}
                    name="appliesToAllLocations"
                    id="applies-to-all-locations"
                    label={"This cut agreement applies to all locations"}
                    disabled={!values.customer}
                    caption={!values.customer ? "Requires a customer to be selected first." : ""}
                  />
                </Form.Row>
                {/*select the relevant locations*/}
                {!values.appliesToAllLocations && (
                  <div className={"padding-x-1 locations-list"}>
                    {(inbound ? Object.keys(locationsById) : Object.keys(selectedCustomerLocationsById)).map(
                      (customerId, index) => (
                        <div key={customerId}>
                          <div className={"customer-name"}>{this.getCustomerName(customerId)}</div>
                          <div className={"customer-locations-group"}>
                            {(inbound
                              ? Object.keys(locationsById[customerId])
                              : Object.keys(selectedCustomerLocationsById[customerId])
                            ).map((fulfillmentMethod) => (
                              <div key={fulfillmentMethod}>
                                <div className={"fulfillment-method"}>
                                  {fulfillmentMethodsPrettyNames[fulfillmentMethod]}
                                </div>
                                <div className={"fulfillment-method-locations-group"}>
                                  <Button
                                    variant={"link"}
                                    onClick={() => {
                                      let locations = values.specific_locations_ids;
                                      const newLocations = inbound
                                        ? locationsById[customerId][fulfillmentMethod]
                                        : selectedCustomerLocationsById[customerId][fulfillmentMethod];

                                      newLocations.forEach((location) => {
                                        if (!locations.includes(location.locationId)) {
                                          locations.push(location.locationId);
                                        }
                                      });

                                      setFieldValue("specific_locations_ids", locations);
                                    }}
                                  >
                                    Select All {fulfillmentMethodsPrettyNames[fulfillmentMethod]}
                                  </Button>
                                  {(inbound
                                    ? locationsById[customerId][fulfillmentMethod]
                                    : selectedCustomerLocationsById[customerId][fulfillmentMethod]
                                  ).map((location) => (
                                    <div className={"field-wrapper"} key={location.locationId}>
                                      <label className={"d-flex align-items-center"}>
                                        <Field
                                          type={"checkbox"}
                                          name={"specific_locations_ids"}
                                          id={"location-" + location.locationId}
                                          value={location.locationId}
                                        />
                                        <span>{location.locationName}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                <hr />
                <div className={"field-wrapper margin-bottom-1"}>
                  <label className={"d-flex align-items-center"}>
                    <Field
                      type={"checkbox"}
                      name={"appliesToSpecificOrderFeeConfigs"}
                      id={"applies-to-all-order-fee-configs"}
                    />
                    <span>This cut only applies to specific service charges</span>
                  </label>
                </div>

                {/*select the relevant order fee configs*/}
                {values.appliesToSpecificOrderFeeConfigs && (
                  <div className={"padding-x-1 margin-bottom-2"}>
                    {(inbound ? orderFeeConfigs : selectedCustomerOrderFeeConfigs).map((orderFeeConfig, index) => (
                      <div className={"field-wrapper"} key={index}>
                        <label className={"d-flex"}>
                          <Field
                            type={"checkbox"}
                            name={"specific_feeconfigs_ids"}
                            id={"order-fee-config-" + orderFeeConfig.id}
                            value={orderFeeConfig.id}
                          />
                          <div>
                            <div>{`${orderFeeConfig.name_for_owner} (${orderFeeConfig.customer_name_for_admin})`}</div>
                            <div className="d-flex align-items-center">
                              <span className="margin-right-1">
                                {`${isoToReadableDate(orderFeeConfig.start_date)} - ${isoToReadableDate(
                                  orderFeeConfig.end_date
                                )}`}
                              </span>

                              {now > DateTime.fromISO(orderFeeConfig.end_date) && <Tag text="Ended" />}
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  disabled={isSaving}
                  onClick={() => {
                    onClose();
                    this.setState({
                      showEndDatePicker: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0 || isSaving}>
                  {isSaving ? <Spinner animation={"border"} size={"sm"} /> : "Save"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default AddCutAgreementModal;
