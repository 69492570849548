import React, { useState } from "react";
import BbotModal from "bbot-component-library/BbotModal";
import axios from "axios";
import { notification, List } from "bbot-component-library";
import { generalErrorAlert } from "util/Utils";

/*
Modal to confirm deleting service charges
  Required props:
    id: ID for testing
    isVisible: Controls whether the modal is visible
    hideModalFunction: Pass a function to be called when cancel is clicked in modal. Should set the isVisible to false
    selectedCustomer: Currently selected customer
    onSubmitCallback: Function called when the modal is submitted
    serviceCharges: All of the customer's service charges (res.data.order_fee_configs from api/getAllOrderFeeConfigs
    selectedServiceChargeKeys: The currently selected service charges (a list of IDs)
*/

const DeleteServiceChargeModal = ({
  id,
  isVisible,
  hideModalFunction,
  selectedCustomer,
  onSubmitCallback,
  serviceCharges,
  selectedServiceChargeKeys,
  ...props
}) => {
  // State to track whether a delete is in progress
  const [isDeleting, setIsDeleting] = useState(false);

  // Filter service charges to those that are selected
  const selectedServiceCharges = serviceCharges?.filter((charge) => {
    return selectedServiceChargeKeys.includes(charge.id);
  });

  // This function is duplicated from ServiceChargesComponent. Is there a better way to access it?
  const formatFee = (feeFraction, feeCents) => {
    if (feeCents && feeFraction) {
      return feeFraction * 100 + "% + $" + (feeCents / 100).toFixed(2);
    } else if (feeCents) {
      return "$" + (feeCents / 100).toFixed(2);
    } else if (feeFraction) {
      return feeFraction * 100 + "%";
    } else {
      return "No fee";
    }
  };

  // When user clicks confirm delete the selected service charges
  const deleteFunction = async () => {
    if (!selectedCustomer) {
      generalErrorAlert(
        new Error("No customer selected"),
        "Please use the dropdown in the header to select a customer.",
        true
      );
      return;
    }
    setIsDeleting(true);
    const payload = {
      order_fee_config_ids_to_delete: selectedServiceChargeKeys,
    };
    try {
      await axios.post("api/deleteOrderFeeConfigs", payload);
      notification.success({
        message:
          selectedServiceChargeKeys.length > 1
            ? "Successfully deleted service charges."
            : "Successfully deleted service charge.",
      });
      hideModalFunction();
      onSubmitCallback();
    } catch (error) {
      generalErrorAlert(error, "Unexpected Server Error. If this keeps happening please contact Bbot support.", true);
      console.error(error);
    }
    setIsDeleting(false);
  };
  // Render component
  return (
    <BbotModal
      id={id}
      visible={isVisible}
      onCancel={hideModalFunction}
      title="Are you sure you want to delete these Service Charges?"
      onOk={deleteFunction}
      okText="Confirm"
      okType="danger"
      okButtonProps={{ id: id + "-confirm", loading: isDeleting }}
      cancelButtonProps={{ id: id + "-cancel" }}
    >
      Service charges to be deleted:
      {/* List selected service charges */}
      <List>
        {selectedServiceCharges.map((charge, i) => {
          return (
            <List.Item key={i}>
              <strong>{charge.name_for_owner}</strong>
              {" (" + formatFee(charge.fraction_of_sale, charge.fixed_cents) + ")"}
            </List.Item>
          );
        })}
      </List>
    </BbotModal>
  );
};

export default DeleteServiceChargeModal;
