import React, { Component } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import Moment from "react-moment";

import "./SalesCommissionCard.scss";

import SalesRepIcon from "assets/svgs/icons/icon-sales-rep.svg";

class SalesCommissionCard extends Component {
  render() {
    const { salesCommission } = this.props;
    return (
      <Card className={"sales-commission-card"}>
        <Card.Header className={"sales-commission-card__header"}>
          <img src={SalesRepIcon} alt={"Icon"} className={"margin-right-2"} />
          <h4>{salesCommission.salesperson_name}</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <table className={"sales-commission-card__table margin-bottom-4"}>
                <thead>
                  <tr>
                    <th className={"commission-type-col"}>Commission Type</th>
                    <th className={"center"}>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={"commission-row-transaction"} className={"table-row"}>
                    <td className={"commission-type-col"}>{"Transaction"}</td>
                    <td className={"center"}>{(salesCommission.fraction_of_transactions * 100).toFixed(1) + "%"}</td>
                  </tr>
                  <tr key={"commission-row-saas"} className={"table-row"}>
                    <td className={"commission-type-col"}>{"SaaS"}</td>
                    <td className={"center"}>{(salesCommission.fraction_of_saas * 100).toFixed(1) + "%"}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className={"sales-commission-date-range"}>
                <div>
                  <label>Date Range</label>
                </div>
                <Moment format="MM/DD/YYYY">{salesCommission.start_date}</Moment>
                <span> - </span>
                <Moment format="MM/DD/YYYY">{salesCommission.end_date}</Moment>
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button onClick={this.props.editSalesPerson}>Edit</Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default SalesCommissionCard;
