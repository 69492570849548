import { Col, Form as Btpform, FormGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumberInput, SearchSelectInput, TextInput, CheckboxInput } from "components/global/form";
import React from "react";
import RadioGroupInput from "components/global/form/RadioGroupInput";
import SearchSelectInputUtils from "components/global/form/SearchSelectInputUtils";
import sampleTicket from "images/order_grouping_rules/sampleBigSmallMessageTopTicket.jpg";

class CreateEditOrderGroupingRulesPage3 extends React.Component {
  getPrinterChoices = () => {
    const { allPrinters, getNecessaryVendors, selectedVendors } = this.props;

    let printerChoices = [];

    if (allPrinters != null) {
      let necessaryVendors = getNecessaryVendors(SearchSelectInputUtils.getValuesFromMultiSelect(selectedVendors));

      Object.keys(allPrinters)
        .filter((cus) => necessaryVendors.includes(cus))
        .forEach((cus) =>
          allPrinters[cus]
            .filter((p) => p.loadbalance_master === null)
            .forEach((p) =>
              printerChoices.push({
                value: p.id,
                label: p.name,
              })
            )
        );
    }

    printerChoices.sort((a, b) => (a.label > b.label ? 1 : -1));
    return printerChoices;
  };

  getNormalPrinterOptionText = () => {
    const { groupByCheckout } = this.props;

    if (groupByCheckout !== "disabled") {
      return [
        "Normal printers for menu items (configure these by editing the menu item)",
        "  ",
        <OverlayTrigger
          key={"placement"}
          placement={"right"}
          overlay={
            <Tooltip id={"tooltip-select-days-and-times"}>
              When grouping orders from multiple prep stations, you must select specific printer(s) to print the
              resulting ticket.
            </Tooltip>
          }
        >
          <div className="zmdi zmdi-info-outline dashboard-link-icon" />
        </OverlayTrigger>,
      ];
    }

    return "Normal printers for menu items (configure these by editing the menu item)";
  };

  render() {
    const {
      setFieldValue,
      specificPrinters,
      selectedPrinters,
      numCopies,
      groupItemsBy,
      groupByCheckout,
      getTagChoices,
      getNecessaryVendors,
      selectedGroupingTags,
      suppress_normal_ticket_printing,
      largeMessage,
      smallMessage,
      selectedVendors,
      selectedPrintersKey,
    } = this.props;

    return (
      <FormGroup>
        <Btpform.Row className="margin-bottom-2">
          <RadioGroupInput
            options={[
              { label: this.getNormalPrinterOptionText(), value: false, disabled: groupByCheckout !== "disabled" },
              { label: "Specific Printers", value: true },
            ]}
            md={12}
            id={"specificPrinters"}
            name={"specificPrinters"}
            value={specificPrinters}
            selectedValue={specificPrinters}
            label={"Print ticket at?"}
            className={"specificPrintersRadioButtons"}
            onChange={(value) => setFieldValue("specificPrinters", value === "true")}
          />
        </Btpform.Row>
        {specificPrinters && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              md={12}
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              key={selectedPrintersKey}
              className={"margin-bottom-0"}
              id={"selectedPrinters"}
              name={"selectedPrinters"}
              label={"Select Which Printers to Print At"}
              options={this.getPrinterChoices()}
              placeholder={"Available Printers"}
              value={selectedPrinters}
              defaultValue={selectedPrinters}
              onChange={(value) => setFieldValue("selectedPrinters", value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row>
          <CheckboxInput
            label={"Disable normal printing of orders when they're part of this Grouping Rule"}
            name={"suppress_normal_ticket_printing"}
            id={"suppress_normal_ticket_printing"}
            value={suppress_normal_ticket_printing}
            checked={suppress_normal_ticket_printing}
            onChange={(value) => setFieldValue("suppress_normal_ticket_printing", value)}
          />
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-2">
          <NumberInput
            md={12}
            label={"Number of Copies to Print"}
            name={"numCopies"}
            value={numCopies}
            id={"numCopies"}
            placeholder={"Enter number of copies to print"}
          />
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-2">
          <RadioGroupInput
            options={[
              { label: "All Together", value: "all_together" },
              { label: "By Tag", value: "by_tag" },
              { label: "By Order", value: "by_order" },
            ]}
            md={12}
            id={"groupItemsBy"}
            name={"groupItemsBy"}
            value={groupItemsBy}
            selectedValue={groupItemsBy}
            label={"Choose How Items Should Be Organized on the Printed Ticket"}
            className={"groupItemsByRadioButtons"}
            onChange={(value) => setFieldValue("groupItemsBy", value)}
          />
        </Btpform.Row>
        {groupItemsBy === "by_tag" && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              md={12}
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              className={"margin-bottom-0"}
              id={"selectedGroupingTags"}
              name={"selectedGroupingTags"}
              label={"Select Tags to Organize Items By"}
              options={getTagChoices(
                getNecessaryVendors(SearchSelectInputUtils.getValuesFromMultiSelect(selectedVendors))
              )}
              placeholder={"Available Tags"}
              value={selectedGroupingTags}
              defaultValue={selectedGroupingTags}
              onChange={(value) => setFieldValue("selectedGroupingTags", value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row>
          <Col md={12} className="field-wrapper">
            <h6>Messages to Print on Ticket (optional)</h6>
            <hr />
          </Col>
        </Btpform.Row>
        <Btpform.Row md={12}>
          <Col md={6} className={"messages-top-ticket-column"}>
            <Btpform.Row className="margin-bottom-2">
              <TextInput
                md={12}
                label={"Large Message"}
                name={"largeMessage"}
                id={"largeMessage"}
                value={largeMessage}
                placeholder={"Large message at top of ticket"}
              />
            </Btpform.Row>
            <Btpform.Row className="margin-bottom-2">
              <TextInput
                md={12}
                label={"Small Message"}
                name={"smallMessage"}
                id={"smallMessage"}
                value={smallMessage}
                placeholder={"Small message at top of ticket"}
              />
            </Btpform.Row>
          </Col>
          <Col md={6}>
            <img src={sampleTicket} className="margin-left-3" width="300" alt={""} height="500" />
          </Col>
        </Btpform.Row>
      </FormGroup>
    );
  }
}

export default CreateEditOrderGroupingRulesPage3;
