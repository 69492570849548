import React, { useState } from "react";
import { InlineChildren, StackChildren, Text } from "@doordash/design-language";
import { EmailField } from "@doordash/component-fields";
import Button from "@doordash/component-button";
import { InputContainer } from "./PaymentWidget";
import { EMAIL_VALIDATION_REGEX } from "Constants";
import styled from "styled-components";

type BillPayerEmailProps = {
  savedValue?: string;
  onSave: (value: string) => void;
};

const BillPayerEmail: React.FC<BillPayerEmailProps> = ({ savedValue = "", onSave }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(savedValue.length === 0);
  const [email, setEmail] = useState(savedValue);
  const [showError, setShowError] = useState(!!savedValue);

  const handleSave = async () => {
    if (!!validate(email)) {
      setShowError(true);
      return;
    }

    if (email === savedValue) {
      setIsEditing(false);
      return;
    }

    setIsSaving(true);
    await onSave(email);
    setIsSaving(false);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
    if (savedValue && e.key === "Escape") {
      setEmail(savedValue);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    if (savedValue && email === savedValue) {
      setIsEditing(false);
    }
  };

  if (!isEditing) {
    return (
      <InputContainer data-test-id={"billpayer-email-container"}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.FieldMediumLabel}>Billing Email</Text>
          <InlineChildren>
            <StaticInputField>
              <Text styles={Text.Styles.Body2} data-test-id="saved-billpayer-email">
                {savedValue}
              </Text>
            </StaticInputField>
            <Button
              type={Button.Types.FlatSecondary}
              isInline
              onClick={() => setIsEditing(true)}
              data-test-id="edit-billing-email-btn"
            >
              Edit Email
            </Button>
          </InlineChildren>
        </StackChildren>
      </InputContainer>
    );
  }

  return (
    <InputContainer data-test-id={"billpayer-email-container"}>
      <EmailField
        data-test-id="billing-email-input"
        label="Billing Email"
        placeholder="Billing Email"
        autoFocus
        value={email}
        onChange={(email: string) => setEmail(email)}
        onKeyDown={handleKeyDown}
        error={showError && validate(email)}
        onBlur={handleBlur}
        renderConnectedAfter={() => (
          <Button
            data-test-id="save-email-btn"
            type={Button.Types.Full}
            onClick={handleSave}
            state={isSaving ? Button.States.Loading : Button.States.Default}
          >
            Save Email
          </Button>
        )}
      />
    </InputContainer>
  );
};

const validate = (email: string) => {
  if (!email.length) {
    return "Billing Email is required";
  }

  if (!email.match(EMAIL_VALIDATION_REGEX)) {
    return "Please use a valid email address";
  }

  return "";
};

export default BillPayerEmail;

const StaticInputField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
