import React, { Component } from "react";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import "./SalesPersonTable.scss";

class SalesPersonTable extends Component {
  render() {
    return (
      <div>
        <div className={"table-container sales-person-table"}>
          <div className={"header bg-blue-6"}>
            <h4>Sales Persons</h4>
            <div className={"actions"}>
              {this.props.selectedReps && this.props.selectedReps.length > 0 && (
                <ButtonGroup>
                  <Button
                    size={"sm"}
                    variant={"outline-primary"}
                    className={"margin-right-2"}
                    onClick={() => this.props.handleEdit()}
                  >
                    Edit
                  </Button>
                  <Button size={"sm"} variant={"outline-primary"} onClick={() => this.props.handleDelete()}>
                    Delete
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </div>
          <Table hover size="sm" className={"margin-bottom-0"}>
            <thead>
              <tr>
                <th key={"sales-person-col-name"}>Name</th>
                <th key={"sales-person-col-transaction"}>Default Transaction Percentage</th>
                <th key={"sales-person-col-saas"}>Default SaaS Percentage</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((salesPerson, index) => (
                <tr
                  className={"sales-person-row " + (this.props.selectedReps.includes(salesPerson) ? "selected" : "")}
                  key={"sales-person-row-" + index}
                  onClick={() => this.props.handleSelectRow(salesPerson)}
                >
                  <td key={"sales-person-col-name-" + index}>{salesPerson.name}</td>
                  <td key={"sales-person-col-transaction-" + index}>
                    {(salesPerson.default_transaction_commission * 100).toFixed(1) + "%"}
                  </td>
                  <td key={"sales-person-col-saas-" + index}>
                    {(salesPerson.default_saas_commission * 100).toFixed(1) + "%"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <button className="add-item" onClick={this.props.addSalesPerson}>
            <div className={"icon-circle"}>+</div>
            <span>Add New Sales Person</span>
          </button>
        </div>
      </div>
    );
  }
}

export default SalesPersonTable;
