import React, { useState } from "react";
import axios from "axios";
import { useImmer } from "use-immer";
import { Colors, Icon, InlineChildren, Inset, Text } from "@doordash/design-language";
import { Select as DDSelect, TextField } from "@doordash/component-fields";
import Button from "@doordash/component-button";
import Banner from "@doordash/component-banner";
import { useStripe } from "@stripe/react-stripe-js";
import { useToast } from "@doordash/component-toast";
import BBOT_SERVER from "Config";

const initialState = {
  accountHolderName: {
    value: "",
    error: "",
  },
  routingNumber: {
    value: "",
    error: "",
  },
  accountNumber: {
    value: "",
    error: "",
  },
  accountType: {
    value: "",
    error: "",
  },
};

type FormStateField = {
  value?: string;
  error: string;
};

type FormState = {
  accountHolderName: FormStateField;
  routingNumber: FormStateField;
  accountNumber: FormStateField;
  accountType: FormStateField;
};

const PlaidManualBankForm = ({
  customerId,
  onSuccessCallback,
  billpayerExists,
}: {
  customerId: string;
  onSuccessCallback: () => void;
  billpayerExists: boolean;
}) => {
  const stripe = useStripe();
  // const [formValues, dispatch] = useImmerReducer<FormState, FormAction>(reducer, initialState);
  const [formValues, updateFormValues] = useImmer<FormState>(initialState);
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const { displayToast } = useToast();

  const validateFields = async () => {
    let errorsDetected = false;

    // verify that all fields are entered
    await updateFormValues((draft) => {
      Object.entries(formValues).forEach(([fieldName, { value }]) => {
        const isFieldMissing = !value?.length;
        if (isFieldMissing) {
          errorsDetected = true;
          draft[fieldName as keyof FormState].error = "This field is required";
        }
      });
    });

    return errorsDetected;
  };

  const setFieldValue = (fieldName: keyof FormState, fieldValue: string) => {
    updateFormValues((draft) => {
      draft[fieldName].value = fieldValue;
      draft[fieldName].error = fieldValue.length ? "" : "This field is required";
    });
  };

  const handleSubmit = async () => {
    // If no billpayer, show toast, return early.
    if (!billpayerExists) {
      displayToast({
        icon: Icon.Types.ErrorLine,
        text: "Please save a valid billing email first.",
        insetFromEdge: Inset.Sizes.Medium,
      });
      return;
    }

    setIsSaving(true);

    const errorsDetected = await validateFields();

    if (errorsDetected) {
      setError("");
      setIsSaving(false);
      return;
    }

    try {
      const result = await stripe?.createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: formValues?.routingNumber?.value ?? "",
        account_number: formValues?.accountNumber?.value ?? "",
        account_holder_name: formValues?.accountHolderName?.value ?? "",
        account_holder_type: formValues?.accountType?.value ?? "",
      });

      if (result?.error) {
        setError("Error: " + result.error.message);
        throw Error();
      }

      await axios.post(BBOT_SERVER + "/api/journey/addBankAccount", {
        customer_id: customerId,
        bank_account_token: result?.token,
      });

      updateFormValues(() => initialState);
      setError("");
      onSuccessCallback();
    } catch (err) {
      const error = err as any;
      console.error(error);
      if (error.errorCode) {
        setError(error?.errorCode);
      } else if (error?.response?.data?.errorCode) {
        setError(error.response.data.errorCode);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {error && !isSaving && <Banner label={error} variant={Banner.Variants.Negative} />}
      <InlineChildren>
        <TextField
          value={formValues.accountHolderName.value}
          label="Account Holder Name"
          placeholder="Account Holder Name"
          isRequired
          error={formValues.accountHolderName.error}
          onChange={(accountHolderName: string) => setFieldValue("accountHolderName", accountHolderName)}
        />
        <TextField
          value={formValues.routingNumber.value}
          label="Routing Number"
          placeholder="123456789"
          description="Transit Number"
          error={formValues.routingNumber.error}
          onChange={(routingNumber: string) => setFieldValue("routingNumber", routingNumber)}
        />
      </InlineChildren>
      <InlineChildren>
        <TextField
          value={formValues.accountNumber.value}
          label="Account Number"
          placeholder="3334444555566-10"
          error={formValues.accountNumber.error}
          onChange={(accountNumber: string) => setFieldValue("accountNumber", accountNumber)}
        />
        <DDSelect
          disabledValue={"Please Select Account Type"}
          value={formValues.accountType.value}
          label="Account Type"
          options={[
            { name: "Business", value: "company" },
            { name: "Personal", value: "individual" },
          ]}
          error={formValues.accountType.error}
          onChange={(accountType: string) => setFieldValue("accountType", accountType)}
        />
      </InlineChildren>
      <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
        Manually connected bank accounts require additional verification via micro-deposits. After you’ve saved bank
        account details here, two small deposits will be sent to your account. These will take 1-2 business days to
        appear on your statement. You will need to return to this task and manually enter the amounts to finalize the
        verification process.
      </Text>
      <Button isInline onClick={handleSubmit} state={isSaving ? Button.States.Loading : Button.States.Default}>
        Save Bank Account
      </Button>
    </>
  );
};

export default PlaidManualBankForm;
