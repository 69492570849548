import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
/*
Generic container for a collapsable advanced settings section. Content passed in the body will be hidden/shown when the
user clicks on "Advanced Settings"
 */
const AdvancedSettingsContainer = ({ id, active = false, callback, ...props }) => {
  const activeKey = active ? "advanced" : "";
  // Collapse seems to retain its state between modals being closed which I don't love but not that big a problem
  return (
    <Collapse defaultActiveKey={[activeKey]} onChange={callback}>
      <Collapse.Panel key="advanced" header="Advanced Settings" id={id}>
        {props.children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default AdvancedSettingsContainer;
AdvancedSettingsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool, // If set to 'true' the advanced settings will be shown by default
  callback: PropTypes.func, // Optional function to be called when the advanced settings are toggled
};
