import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { STATIC_ROOT } from "../../Config.js";
import styled from "styled-components";
import { Breadcrumbs, notification, Col, Row, SearchText, BbotButton } from "../../bbot-component-library";
import AppStoreCard from "../../components/owner-app/cards/app-store/AppStoreCard";
import { capitalizeString } from "../../util/Utils";

import ConnectPartnerModal from "../../components/owner-app/modals/partners/ConnectPartnerModal";
import ToastIntegrationModal from "../../components/owner-app/modals/app-store/ToastIntegrationModal";
import SquareIntegrationModal from "../../components/owner-app/modals/app-store/SquareIntegrationModal";
import UpserveIntegrationModal from "../../components/owner-app/modals/app-store/UpserveIntegrationModal";
import OmnivoreIntegrationModal from "../../components/owner-app/modals/app-store/OmnivoreIntegrationModal";
import SeatedIntegrationModal from "../../components/owner-app/modals/app-store/SeatedIntegrationModal";
import SevenRoomsIntegrationModal from "../../components/owner-app/modals/app-store/SevenRoomsIntegrationModal";
import GoogleIntegrationModal from "../../components/owner-app/modals/app-store/GoogleIntegrationModal";
import YumpingoIntegrationModal from "../../components/owner-app/modals/app-store/YumpingoIntegrationModal";
import StuartIntegrationModal from "../../components/owner-app/modals/app-store/StuartIntegrationModal";
import OrkestroIntegrationModal from "../../components/owner-app/modals/app-store/OrkestroIntegrationModal";
import RelayIntegrationModal from "../../components/owner-app/modals/app-store/RelayIntegrationModal";
import DoordashDriveIntegrationModal from "../../components/owner-app/modals/app-store/DoordashDriveIntegrationModal";
import ZapierIntegrationModal from "../../components/owner-app/modals/app-store/ZapierIntegrationModal";
import RooamIntegrationModal from "../../components/owner-app/modals/app-store/RooamIntegrationModal";
import ChowlyIntegrationModal from "../../components/owner-app/modals/app-store/ChowlyIntegrationModal";
import RevelIntegrationModal from "../../components/owner-app/modals/app-store/RevelIntegrationModal";
import OpenTableIntegrationModal from "../../components/owner-app/modals/app-store/OpenTableIntegrationModal";
import OtterIntegrationModal from "../../components/owner-app/modals/app-store/OtterIntegrationModal";
import SmartBarIntegrationModal from "../../components/owner-app/modals/app-store/SmartbarIntegrationModal";
import NashIntegrationModal from "../../components/owner-app/modals/app-store/NashIntegrationModal";
import AgilysysIntegrationModal from "../../components/owner-app/modals/app-store/AgilysysIntegrationModal";
import DeliverectIntegrationModal from "components/owner-app/modals/app-store/DeliverectIntegrationModal";
import { trackClickAppStoreCategoryDropdownItem } from "instrumentation/tracking/page-tracking-events";
import { Link } from "react-router-dom";
import GiftCardModal from "components/owner-app/modals/app-store/DoorDashMISGiftCardModal";

const AppStore = (props) => {
  const { selectedCustomer, userInfo, allowedCustomers } = props;
  const userIsAdmin = userInfo?.role === "admin";
  const userIsOwner = userInfo?.role === "Owner";

  const [partners, setPartners] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [connectedPartners, setConnectedPartners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [activeCustomerIntegrations, setActiveCustomerIntegrations] = useState([]);
  const [showConnectPartnerModal, setShowConnectPartnerModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [modalShown, setModalShown] = useState("");
  const [disconnectSelectedIntegration, setDisconnectSelectedIntegration] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [posIntegration, setPosIntegration] = useState("");
  // Check the types of these two on the backend
  const [posSupportsLoyaltyDiscounts, setPosSupportsLoyaltyDiscounts] = useState(null);
  const [posRequiresOpenDiscounts, setPosRequiresOpenDiscounts] = useState(null);

  const hideIntegration = () => {
    setModalShown("");
  };

  const getConnectedLoyaltyIntegration = () => {
    let loyaltyPartnerIds = partners.filter((p) => p.category === "Rewards and Loyalty").map((p) => p.id);
    let connectedLoyaltyPartners = connectedPartners.filter((p) => loyaltyPartnerIds.includes(p.partner_id));

    if (connectedLoyaltyPartners.length > 0) {
      return connectedLoyaltyPartners[0];
    }

    return null;
  };

  const warnPosIntegrationIfConnectedAfterLoyaltyIntegration = () => {
    const connectedLoyaltyIntegration = getConnectedLoyaltyIntegration();

    if (posIntegration != null && connectedLoyaltyIntegration != null) {
      if (posSupportsLoyaltyDiscounts && posRequiresOpenDiscounts) {
        notification.warn({
          message: `Please update your loyalty integrations with the discount that will represent loyalty discounts in ${capitalizeString(
            posIntegration
          )}`,
        });
      } else if (!posSupportsLoyaltyDiscounts) {
        notification.warn({
          message: `You connected a POS integration that does not support open discounts.  Please disconnect either ${capitalizeString(
            posIntegration
          )} or ${capitalizeString(connectedLoyaltyIntegration)}`,
        });
      }
    }
  };

  const getIntegrationsAndPartners = async () => {
    if (!selectedCustomer) return;
    try {
      const res = await axios.get("/owner/getAllPartnersAndIntegrations", {
        params: {
          customerId: selectedCustomer.customer_id,
        },
      });
      setPartners(res.data.partners);
      setIntegrations(res.data.integrations);
      setConnectedPartners(res.data.partner_permissions);
      setCategories(
        [{ value: "all", label: "All Apps" }].concat(
          res.data.categories.map((category) => {
            return { value: category, label: category };
          })
        )
      );
      setActiveCustomerIntegrations(res.data.customer_integrations);
      setPosSupportsLoyaltyDiscounts(res.data.pos_integration_supports_loyalty_discounts);
      setPosRequiresOpenDiscounts(res.data.pos_integration_requires_open_discount);
      setPosIntegration(res.data.pos_integration);
    } catch (error) {
      if (error.response?.data && error.response.data.message) {
        notification.error({ message: error.response.data.message });
      } else {
        console.error(error);
        notification.error({
          message: "Trouble retrieving integrations and partners. Please refresh and try again.",
        });
      }
    }
  };

  useEffect(() => {
    getIntegrationsAndPartners();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const disconnectPartner = async (partner) => {
    const payload = {
      customerId: selectedCustomer?.customer_id,
      partnerKey: partner.short_id,
    };

    try {
      await axios.post("/admin/disconnectPartner", payload);
      notification.success({
        message: "Successfully disconnected from " + partner.display_name,
      });
      await getIntegrationsAndPartners();
    } catch (error) {
      if (error.response.data && error.response.data.message) {
        notification.error({ message: error.response.data.message });
      } else {
        console.error(error);
        notification.error({
          message: "Trouble disconnecting from " + partner.display_name + ". Please refresh and try again.",
        });
      }
    }
  };

  const connectIntegration = (integration) => {
    setModalShown(integration.server_key);
    setDisconnectSelectedIntegration(false);
  };

  const disconnectIntegration = async (integration) => {
    setModalShown(integration.server_key);
    setDisconnectSelectedIntegration(true);
  };

  const hidePosIntegration = async () => {
    hideIntegration();
    await getIntegrationsAndPartners();
    warnPosIntegrationIfConnectedAfterLoyaltyIntegration();
  };

  const cardGroup = (integrationsOrPartners, isIntegration, connectFunction, disconnectFunction) => {
    return integrationsOrPartners.map((integrationOrPartner, index) => (
      <CardColumn
        key={"integration-card-" + index}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
        className={"margin-bottom-2"}
      >
        <AppStoreCard
          id={"integration-card-" + index}
          integrationKey={integrationOrPartner.server_key}
          title={isIntegration ? integrationOrPartner.integration_title : integrationOrPartner.card_title}
          description={
            isIntegration ? integrationOrPartner.integration_description : integrationOrPartner.card_description
          }
          logo={isIntegration ? STATIC_ROOT + integrationOrPartner.image_url : integrationOrPartner.card_image_url}
          connected={
            (isIntegration && activeCustomerIntegrations.hasOwnProperty(integrationOrPartner.server_key)) ||
            (!isIntegration && activeCustomerIntegrations[integrationOrPartner.short_id]?.enabled)
          }
          connectButtonText={isIntegration ? integrationOrPartner.button_text : integrationOrPartner.card_button_text}
          disconnectAction={() => disconnectFunction(integrationOrPartner)}
          connectAction={() => connectFunction(integrationOrPartner)}
          bbotAdminOnly={isIntegration && integrationOrPartner.bbot_only}
        />
      </CardColumn>
    ));
  };

  const searchTextMatchesIntegration = (integration) => {
    return integration.integration_title.toLowerCase().includes(searchText.toLowerCase());
  };

  const searchTextMatchesPartner = (partner) => {
    return partner.card_title.toLowerCase().includes(searchText.toLowerCase());
  };

  const connectedIntegrations = integrations.filter(
    (integration) =>
      searchTextMatchesIntegration(integration) &&
      activeCustomerIntegrations.hasOwnProperty(integration.server_key) &&
      (integration.category === selectedCategory || selectedCategory === "all")
  );

  const availableIntegrations = integrations.filter(
    (integration) =>
      searchTextMatchesIntegration(integration) &&
      !activeCustomerIntegrations.hasOwnProperty(integration.server_key) &&
      (integration.category === selectedCategory || selectedCategory === "all")
  );

  const activePartners = partners.filter(
    (partner) =>
      searchTextMatchesPartner(partner) &&
      activeCustomerIntegrations.hasOwnProperty(partner.short_id) &&
      activeCustomerIntegrations[partner.short_id].enabled &&
      (partner.category === selectedCategory || selectedCategory === "all")
  );

  const availablePartners = partners.filter(
    (partner) =>
      searchTextMatchesPartner(partner) &&
      (!activeCustomerIntegrations.hasOwnProperty(partner.short_id) ||
        !activeCustomerIntegrations[partner.short_id].enabled) &&
      (partner.category === selectedCategory || selectedCategory === "all")
  );

  return (
    <div>
      <div className={"margin-x-5"}>
        <Breadcrumbs name={"Bbot App Store"} link={"/bbot-app-store"} />
        <Row>
          <Col span={24}>
            <div className="margin-bottom-4">
              <h2>Bbot App Store</h2>
            </div>
          </Col>
        </Row>

        <Row className={"margin-bottom-6"} gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={12}>
            <div>
              <ReactSelect
                placeholder={"All Apps"}
                options={categories}
                onChange={(category) => {
                  trackClickAppStoreCategoryDropdownItem({ integration_category: category.value });
                  setSelectedCategory(category.value);
                }}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <SearchText id={"app-store-text-filter"} placeholder={"Search"} searchFunction={setSearchText} />
          </Col>
        </Row>
        {(userIsOwner || userIsAdmin) && (
          <Row className={"margin-bottom-2"}>
            <Link
              to={{
                pathname: "/manage-partners",
                state: {
                  customerOwnedPartnerApp: true,
                },
              }}
            >
              <BbotButton type={"primary"} className={"margin-bottom-2"}>
                Developers
              </BbotButton>
            </Link>
          </Row>
        )}

        {(connectedIntegrations.length > 0 || activePartners.length > 0) && (
          <>
            <Row className={"margin-bottom-2"}>
              <Col span={24}>
                <div>
                  <h3>Connected Apps</h3>
                </div>
              </Col>
            </Row>
            <Row className={"margin-bottom-6"}>
              {cardGroup(
                activePartners,
                false,
                (partner) => {
                  setSelectedPartner(partner);
                  setShowConnectPartnerModal(true);
                },
                (partner) => {
                  disconnectPartner(partner);
                }
              )}

              {cardGroup(
                connectedIntegrations,
                true,
                (integration) => {
                  connectIntegration(integration);
                },
                (integration) => {
                  disconnectIntegration(integration);
                }
              )}
            </Row>
          </>
        )}

        <Row className={"margin-bottom-2"}>
          <Col span={24}>
            <h3>Available Apps</h3>
          </Col>
        </Row>

        <Row className={"margin-bottom-2"}>
          {cardGroup(
            availablePartners,
            false,
            (partner) => {
              setSelectedPartner(partner);
              setShowConnectPartnerModal(true);
            },
            (partner) => {
              disconnectPartner(partner);
            }
          )}

          {cardGroup(
            availableIntegrations,
            true,
            (integration) => {
              connectIntegration(integration);
            },
            (integration) => {
              disconnectIntegration(integration);
            }
          )}
        </Row>
      </div>

      {/* ============= MODALS ============== */}
      <ConnectPartnerModal
        show={showConnectPartnerModal}
        onClose={() => setShowConnectPartnerModal(false)}
        onSave={() => getIntegrationsAndPartners()}
        selectedPartner={selectedPartner}
        selectedCustomer={selectedCustomer}
        connectedPartners={connectedPartners}
        preview={false}
      />
      <ToastIntegrationModal
        show={modalShown === "toast"}
        onHide={() => hidePosIntegration()}
        selectedCustomer={selectedCustomer}
        userIsAdmin={userIsAdmin}
        disconnectMode={disconnectSelectedIntegration}
        onSave={() => getIntegrationsAndPartners()}
      />
      <SquareIntegrationModal
        show={modalShown === "square"}
        onHide={() => hidePosIntegration()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        onSave={() => getIntegrationsAndPartners()}
      />
      <UpserveIntegrationModal
        show={modalShown === "upserve"}
        onHide={() => hidePosIntegration()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        onSave={() => getIntegrationsAndPartners()}
      />
      <OmnivoreIntegrationModal
        show={modalShown === "omnivore_3"}
        onHide={() => hidePosIntegration()}
        disconnectMode={disconnectSelectedIntegration}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
      />
      <StuartIntegrationModal
        show={modalShown === "stuart"}
        onHide={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        onSave={() => getIntegrationsAndPartners()}
      />
      <OrkestroIntegrationModal
        show={modalShown === "orkestro"}
        onHide={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        onSave={() => getIntegrationsAndPartners()}
      />
      <SeatedIntegrationModal
        show={modalShown === "seated"}
        onClose={hideIntegration}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <SevenRoomsIntegrationModal
        show={modalShown === "sevenrooms"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <GoogleIntegrationModal
        show={modalShown === "google_tag_manager"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <YumpingoIntegrationModal
        show={modalShown === "yumpingo"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <RelayIntegrationModal
        show={modalShown === "relay_delivery"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <DoordashDriveIntegrationModal
        show={modalShown === "doordash_drive"}
        onClose={hideIntegration}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
        allowedCustomers={allowedCustomers}
      />
      <ZapierIntegrationModal
        show={modalShown === "zapier"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <RooamIntegrationModal
        show={modalShown === "rooam"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <ChowlyIntegrationModal
        show={modalShown === "chowly"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <RevelIntegrationModal
        show={modalShown === "revel"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <OpenTableIntegrationModal
        show={modalShown === "opentable"}
        onClose={() => hideIntegration()}
        selectedCustomer={selectedCustomer}
        onSave={() => getIntegrationsAndPartners()}
        disconnectMode={disconnectSelectedIntegration}
      />
      <OtterIntegrationModal
        show={modalShown === "otter"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
      />
      <AgilysysIntegrationModal
        show={modalShown === "agilysys"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
      />
      <DeliverectIntegrationModal
        show={modalShown === "deliverect"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
      />
      <NashIntegrationModal
        show={modalShown === "nash"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        allowedCustomers={allowedCustomers}
      />
      <SmartBarIntegrationModal
        show={modalShown === "smart_bar"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
      />
      <GiftCardModal
        show={modalShown === "gift_card_integration__fiserv"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        integration={"gift_card_integration__fiserv"}
        integrationPrettyName={"Fiserv"}
      />
      <GiftCardModal
        show={modalShown === "gift_card_integration__valu_tec"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        integration={"gift_card_integration__valu_tec"}
        integrationPrettyName={"Valutec"}
      />
      <GiftCardModal
        show={modalShown === "gift_card_integration__e_card_systems"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        integration={"gift_card_integration__e_card_systems"}
        integrationPrettyName={"eCard Systems"}
      />
      <GiftCardModal
        show={modalShown === "gift_card_integration__paytronix"}
        onClose={() => hideIntegration()}
        onSave={() => getIntegrationsAndPartners()}
        selectedCustomer={selectedCustomer}
        disconnectMode={disconnectSelectedIntegration}
        integration={"gift_card_integration__paytronix"}
        integrationPrettyName={"Paytronix"}
      />
    </div>
  );
};

const CardColumn = styled(Col)`
  display: flex;
`;
export default AppStore;
