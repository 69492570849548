import React from "react";

// Components
import OrderStatusControls from "components/dev-tools/OrderStatusControls";
import BulkOrderDeleter from "components/dev-tools/BulkOrderDeleter";
import { Container, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";

// Config
import { ENV } from "Config";
import FullstoryControls from "components/dev-tools/FullstoryControls";
import OpenTabControls from "components/dev-tools/OpenTabControls";

class DevTools extends React.Component {
  render() {
    const { selectedCustomer, userInfo } = this.props;
    const userIsAdmin = userInfo?.role === "admin";

    if (!userIsAdmin) return <Redirect to="/" />;

    return (
      <Container>
        <Row>
          <Col className="margin-bottom-5">
            <h2>Dev-Tools</h2>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="margin-bottom-5">
            <FullstoryControls />
          </Col>
        </Row>

        {/* New components go above this. */}
        {ENV !== "prod" && (
          <>
            <Row>
              <Col sm={12} className="margin-bottom-5">
                <OpenTabControls />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="margin-bottom-5">
                <OrderStatusControls selectedCustomer={selectedCustomer} />
              </Col>
            </Row>
            <Row className="margin-bottom-5">
              <Col sm={12}>
                <BulkOrderDeleter />
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

export default DevTools;
