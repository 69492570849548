import React, { useState } from "react";
import { EmailField, PasswordField, TextField } from "@doordash/component-fields";
import { Modal } from "@doordash/component-modal";
import { StackChildren } from "@doordash/design-language";
import Button from "@doordash/component-button";
import axios from "axios";
import { EMAIL_VALIDATION_REGEX } from "Constants";
import PasswordStrengthMeter from "components/customer-journey/PasswordStrengthMeter";
import zxcvbn from "zxcvbn";
import { useHistory } from "react-router-dom";
import Banner from "@doordash/component-banner";

type NewUserFormField = {
  value: string;
  error: string;
};

type NewUserForm = {
  firstName: NewUserFormField;
  lastName: NewUserFormField;
  email: NewUserFormField;
  pin: NewUserFormField;
  password: NewUserFormField;
};

const FORM_INITIAL_VALS = {
  firstName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  email: { value: "", error: "" },
  pin: { value: "", error: "" },
  password: { value: "", error: "" },
};

const NewUserModal = ({
  customerId,
  loginSuccessCallback,
  onClose,
}: {
  customerId: string;
  loginSuccessCallback: () => void;
  onClose: () => void;
}) => {
  const [newUserFormValues, setNewUserFormValues] = useState<NewUserForm>(FORM_INITIAL_VALS);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const onFieldChange = (fieldName: keyof NewUserForm, fieldValue: string) => {
    setNewUserFormValues((formValues) => {
      let error = "";
      if (!fieldValue.length) {
        error = "This field is required";
      }

      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: error,
        },
      };
    });
  };

  const validateNewUsersForm = () => {
    let formHasErrors = false;

    const newFormEntries = Object.entries(newUserFormValues).map(([fieldName, { value: fieldValue }]) => {
      let validationError = "";

      // If field is missing, show validation for it
      if (!fieldValue.length) {
        formHasErrors = true;
        return [fieldName, { value: fieldValue, error: "This field is required." }];
      }

      // Valiate specific fields
      if (fieldName === "email" && !fieldValue.match(EMAIL_VALIDATION_REGEX)) {
        formHasErrors = true;
        validationError = "Please enter a valid email.";
      }

      // Validate the PIN is 4 digits
      if (fieldName === "pin" && !fieldValue.match(/^\d{4}$/)) {
        formHasErrors = true;
        validationError = "Please use a 4 digit PIN.";
      }

      // Valiate password is strong enough
      if (fieldName === "password" && zxcvbn(fieldValue).score < 2) {
        formHasErrors = true;
        validationError = "Please use a stronger password.";
      }

      return [
        fieldName,
        {
          value: fieldValue,
          error: validationError,
        },
      ];
    });

    setNewUserFormValues(Object.fromEntries(newFormEntries));
    return !formHasErrors;
  };

  const saveNewUser = async () => {
    // Validate form
    const formValid = validateNewUsersForm();

    // If no errors, send post request
    if (!formValid) {
      return;
    }

    setIsSaving(true);
    try {
      const newUser = {
        email: newUserFormValues.email.value,
        first_name: newUserFormValues.firstName.value,
        last_name: newUserFormValues.lastName.value,
        level: "Owner",
        pin: newUserFormValues.pin.value,
        password: newUserFormValues.password.value,
        customer_id: customerId,
      };
      const res = await axios.post("/api/journey/signup", { newUser: newUser });
      if (res.data.login_success) {
        loginSuccessCallback();
        history.push("/");
      }
    } catch (error: any) {
      if (error.response?.data?.errorCode || error.errorCode) {
        setError(error.response?.data?.errorCode || error.errorCode);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title="Owner Portal Signup"
      primaryAction={{
        text: "Submit",
        onClick: saveNewUser,
        buttonProps: {
          state: isSaving ? Button.States.Loading : Button.States.Default,
        },
      }}
      onClose={onClose}
    >
      <StackChildren>
        <EmailField
          label={"Email"}
          placeholder="Email"
          value={newUserFormValues.email.value}
          error={newUserFormValues.email.error}
          onChange={(email: string) => onFieldChange("email", email)}
        />
        <TextField
          label={"First Name"}
          placeholder="First Name"
          value={newUserFormValues.firstName.value}
          error={newUserFormValues.firstName.error}
          onChange={(firstName: string) => onFieldChange("firstName", firstName)}
        />
        <TextField
          label={"Last Name"}
          placeholder="Last Name"
          value={newUserFormValues.lastName.value}
          error={newUserFormValues.lastName.error}
          onChange={(lastName: string) => onFieldChange("lastName", lastName)}
        />
        <PasswordField
          label="PIN"
          placeholder="4 digit PIN"
          minLength={4}
          maxLength={4}
          value={newUserFormValues.pin.value}
          error={newUserFormValues.pin.error}
          onChange={(pin: string) => onFieldChange("pin", pin)}
        />
        <PasswordField
          label="Password"
          placeholder="password"
          value={newUserFormValues.password.value}
          error={newUserFormValues.password.error}
          onChange={(password: string) => onFieldChange("password", password)}
        />
        <PasswordStrengthMeter password={newUserFormValues.password.value} />

        {error && (
          <div className="padding-top-2">
            <Banner label={error} variant={Banner.Variants.Negative} />
          </div>
        )}
      </StackChildren>
    </Modal>
  );
};

export default NewUserModal;
