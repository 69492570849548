import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { Card, Row } from "antd";
import {
  BbotButton,
  OPContainer,
  PageLoadingSpinner,
  Radio,
  Tooltip,
  Button,
  Col,
  Form,
  notification,
} from "bbot-component-library";
import FormattedCheckboxGroupInput from "bbot-component-library/form-inputs/FormattedCheckboxGroupInput";
import PercentageInput from "bbot-component-library/form-inputs/PercentageInput";
import ToggleInputRich from "bbot-component-library/form-inputs/ToggleInputRich";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { SettingsEditorContainer } from "styles/SettingsContainerStyles";
import { generalErrorAlert } from "util/Utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

const TippingConfiguration = (props) => {
  const { selectedCustomer } = props;
  const [tipSettings, setTipSettings] = useState({});
  const [fulfillmentMethods, setFulfillmentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toggleText, setToggleText] = useState("Select All");
  const [tippingSettingsForm] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  // Explicitly state some page values
  const id = "tip-settings";
  const name = "Tip Settings";
  const url = "/tip-settings";
  const description = (
    <span>
      {" "}
      Customize the presentation and calculation of tips upon checkout.{" "}
      <a href="https://central.bbot.menu/article/869-gratuity-settings" target="_blank">
        More Info.
      </a>
    </span>
  );

  const getTipSettings = async () => {
    try {
      const res = await axios.get("api/getOwnerProperties");
      setTipSettings(res.data.owner_properties.app_properties.tipping);
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to server. Please refresh the page.");
      console.error(error);
    }
  };

  const getFulfillmentMethods = async () => {
    try {
      const res = await axios.get("api/getFulfillmentMethods");
      setFulfillmentMethods(res.data.fulfillment_methods);
    } catch (error) {
      console.error(error);
    }
  };

  const updateTipSettings = async (values) => {
    const choices = [values.tipPercentageChoice1, values.tipPercentageChoice2, values.tipPercentageChoice3];
    const payload = {
      tip_settings: {
        show_no_tip: values.showNoTip,
        choices: choices,
        default_tip: choices[values.tipPercentageChoiceDefault],
        for_fulfillment_methods: allFulfillmentMethodsSelected() ? ["all"] : values.selectedFulfillmentMethods,
        include_discounts: values.includeDiscounts,
        include_fees: values.includeFees,
        include_taxes: values.includeTaxes,
      },
    };
    try {
      await axios.post("/api/setTipSettings", payload);
      notification.success({
        message: "Successfully saved Tip Settings.",
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble saving tip settings.", selectedCustomer?.customer_id);
    }
  };

  const allFulfillmentMethodsSelected = () => {
    return fulfillmentMethods.length === tippingSettingsForm.getFieldValue("selectedFulfillmentMethods").length;
  };

  const toggleAllFulfillmentMethods = () => {
    if (allFulfillmentMethodsSelected()) {
      tippingSettingsForm.setFieldsValue({ selectedFulfillmentMethods: [] });
      setToggleText("Select All");
    } else {
      tippingSettingsForm.setFieldsValue({ selectedFulfillmentMethods: fulfillmentMethods.map((fm) => fm.id) });
      setToggleText("Unselect All");
    }
  };

  const getData = async () => {
    setIsLoading(true);
    await getTipSettings();
    await getFulfillmentMethods();
    tippingSettingsForm.resetFields();
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedCustomer) getData();
  }, [selectedCustomer]);

  return (
    <OPContainer
      id={id}
      title={name}
      url={url}
      description={description}
      selectedCustomer={props.selectedCustomer}
      refreshOnCurrentPageClick={true}
      userInfo={props.userInfo}
    >
      <SettingsEditorContainer>
        {isLoading && _.isEmpty(tipSettings) ? (
          <Row>
            <Col span={2} offset={11}>
              <PageLoadingSpinner />
            </Col>
          </Row>
        ) : (
          <Card>
            <Form
              form={tippingSettingsForm}
              name="tipping-settings-form"
              onFinish={updateTipSettings}
              layout={"vertical"}
              initialValues={{
                showNoTip: tipSettings.show_no_tip,
                tipPercentageChoice1: tipSettings.choices[0],
                tipPercentageChoice2: tipSettings.choices[1],
                tipPercentageChoice3: tipSettings.choices[2],
                tipPercentageChoiceDefault: tipSettings.default_tip
                  ? tipSettings.choices.indexOf(tipSettings.default_tip)
                  : null,
                selectedFulfillmentMethods: tipSettings.for_fulfillment_methods?.includes("all")
                  ? fulfillmentMethods.map((fm) => fm.id)
                  : tipSettings.for_fulfillment_methods,
                includeDiscounts: tipSettings.include_discounts,
                includeFees: tipSettings.include_fees,
                includeTaxes: tipSettings.include_taxes,
              }}
              onValuesChange={(_, values) => {
                setFormValues(values);
              }}
            >
              <div className={"margin-bottom-2"}>
                <h4>Tip Percentages</h4>
                <p>Set the tip options that are selectable on checkout.</p>
                <Card>
                  <ToggleInputRich
                    title={'Show "No Tip" Option'}
                    description={
                      'Shows a "No Tip" option on the checkout screen on both the consumer site and on the Terminal (3.3+)'
                    }
                    id="show_no_tip"
                    name="showNoTip"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    className={"margin-bottom-1"}
                    data-test-id={"default-tip-choices-heading"}
                  >
                    Default Tip Choices
                    <Tooltip title="Default tip percentage cannot be greater than 30%.">
                      <QuestionCircleOutlined style={{ padding: 4 }} />
                    </Tooltip>
                  </div>
                  <Form.Item name={"tipPercentageChoiceDefault"} style={{ marginBottom: 0 }}>
                    <Radio.Group
                      id={"tipPercentageChoiceDefault"}
                      name={"tipPercentageChoiceDefault"}
                      style={{ width: "100%" }}
                    >
                      <Container>
                        <Row className={"margin-bottom-1"}>
                          <Col span={3} className={"center"}>
                            <h5>Default</h5>
                          </Col>
                          <Col>
                            <h5>Percentage</h5>
                          </Col>
                        </Row>
                        <Row className={"margin-bottom-1"}>
                          <Col span={3} className={"center"}>
                            <Radio
                              value={0}
                              disabled={formValues.tipPercentageChoice1 > 0.3}
                              data-test-id={"default-tip-choice-1"}
                            />
                          </Col>
                          <Col className={"center"}>
                            <PercentageInput
                              id={"first_tip_percentage"}
                              name={"tipPercentageChoice1"}
                              formItemStyle={{ width: "80px", marginBottom: 0 }}
                              min={0}
                              max={
                                formValues.tipPercentageChoiceDefault === 0
                                  ? Math.min(formValues.tipPercentageChoice2 - 0.01 || tipSettings.choices[1], 0.3)
                                  : formValues.tipPercentageChoice2 - 0.01 || tipSettings.choices[1]
                              }
                              data-test-id={"tip-input-1"}
                            />
                          </Col>
                        </Row>
                        <Row className={"margin-bottom-1"}>
                          <Col span={3} className={"center"}>
                            <Radio
                              value={1}
                              disabled={formValues.tipPercentageChoice2 > 0.3}
                              data-test-id={"default-tip-choice-2"}
                            />
                          </Col>
                          <Col className={"center"}>
                            <PercentageInput
                              id={"second_tip_percentage"}
                              name={"tipPercentageChoice2"}
                              formItemStyle={{ width: "80px", marginBottom: 0 }}
                              min={formValues.tipPercentageChoice1 || tipSettings.choices[0]}
                              max={
                                formValues.tipPercentageChoiceDefault === 1
                                  ? Math.min(formValues.tipPercentageChoice3 - 0.01 || tipSettings.choices[2], 0.3)
                                  : formValues.tipPercentageChoice3 - 0.01 || tipSettings.choices[2]
                              }
                              data-test-id={"tip-input-2"}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={3} className={"center"}>
                            <Radio
                              value={2}
                              disabled={formValues.tipPercentageChoice3 > 0.3}
                              data-test-id={"default-tip-choice-3"}
                            />
                          </Col>
                          <Col className={"center"}>
                            <PercentageInput
                              id={"third_tip_percentage"}
                              name={"tipPercentageChoice3"}
                              formItemStyle={{ width: "80px", marginBottom: 0 }}
                              min={formValues.tipPercentageChoice2 || tipSettings.choices[1]}
                              max={formValues.tipPercentageChoiceDefault === 2 ? 0.3 : 1}
                              data-test-id={"tip-input-3"}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </div>
              <div className={"margin-bottom-6"}>
                <div className={"margin-bottom-2"}>
                  <h4>Fulfillment Methods</h4>
                  <p>Choose which fulfillment methods allow tipping. </p>
                  <FormattedCheckboxGroupInput
                    id="fulfillment_methods_tipping"
                    options={fulfillmentMethods.map((fm) => ({ label: fm.name, value: fm.id }))}
                    name="selectedFulfillmentMethods"
                    required={false}
                    columns={2}
                  >
                    <ToggleButton onClick={toggleAllFulfillmentMethods}>{toggleText}</ToggleButton>
                  </FormattedCheckboxGroupInput>
                </div>
              </div>
              <div className={"margin-bottom-6"}>
                <div className={"margin-bottom-2"}>
                  <h4>Tip Calculation</h4>
                  <p>Determines how tip percentage is calculated on Terminal.</p>
                  <Card>
                    <ToggleInputRich
                      title={"Include Discounts"}
                      description={
                        "This controls whether the tip is calculated after all discounts and promotions are applied"
                      }
                      id={"include_discounts_toggle"}
                      name={"includeDiscounts"}
                    />
                    <ToggleInputRich
                      title={"Include Fees"}
                      description={"This controls whether the tip is calculated on top of any service fees"}
                      id="include_fees_toggle"
                      name="includeFees"
                    />
                    <ToggleInputRich
                      title={"Include Taxes"}
                      description={"This controls whether the  tip is calculated on a pre or post-tax basis"}
                      id="include_taxes_toggle"
                      name="includeTaxes"
                    />
                  </Card>
                </div>
              </div>
            </Form>
            <BbotButton id={"submit-form"} type={"primary"} onClick={tippingSettingsForm.submit}>
              Save Changes
            </BbotButton>
          </Card>
        )}
      </SettingsEditorContainer>
    </OPContainer>
  );
};

const ToggleButton = styled(Button)`
  margin-bottom: 0.5rem;
`;

export default TippingConfiguration;
