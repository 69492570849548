import React from "react";
import NumberInput from "bbot-component-library/form-inputs/NumberInput";
import { Form } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
/*
An input that displays a percent plus an amount (in dollars)
*/
const PercentPlusAmountInput = ({
  id,
  label,
  required,
  name,
  validationMessage = "Please enter a percent and/or amount",
  form,
  onChange = () => {},
  ...props
}) => {
  // If percent is changed call the passed function with the supplied values
  const handlePercentChange = (value) => {
    onChange({
      percent: value ? value : 0,
      amount: form.getFieldValue(name + "_amount") ? form.getFieldValue(name + "_amount") : 0,
    });
  };
  // If amount is changed call the passed function with the supplied values
  const handleAmountChange = (value) => {
    onChange({
      percent: form.getFieldValue(name + "_percent") ? form.getFieldValue(name + "_percent") : 0,
      amount: value ? value : 0,
    });
  };
  // Validation rules. Throw an error if field is required, percent is 0, and amount is 0
  const rules = [
    {
      message: validationMessage,
      required: required,
      validateTrigger: ["onBlur", "onChange"],
      validator: () =>
        !required || form.getFieldValue(name + "_percent") > 0 || form.getFieldValue(name + "_amount") > 0
          ? Promise.resolve()
          : Promise.reject(),
    },
  ];
  // Render
  return (
    <Form.Item
      label={label}
      required={required}
      id={id}
      name={name}
      rules={rules}
      dependencies={[name + "_percent", name + "_amount"]}
    >
      <InputContainer>
        <NumberInput
          id={id + "-percent"}
          min={0}
          max={100}
          name={name + "_percent"}
          addonAfter="%"
          onChange={handlePercentChange}
          required={false}
        />
        <span className="material-icons-outlined">add</span>
        <NumberInput
          id={id + "-amount"}
          step="0.01"
          min={0}
          name={name + "_amount"}
          addonBefore="$"
          onChange={handleAmountChange}
          required={false}
        />
      </InputContainer>
    </Form.Item>
  );
};

export default PercentPlusAmountInput;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .material-icons-outlined {
    margin-top: 0.75rem;
  }
`;

PercentPlusAmountInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired, // AntD form object
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  validationMessage: PropTypes.string,
  onChange: PropTypes.func,
};
