const availableCommands = [
  {
    id: "pcb_ip",
    name: "IP Address",
    min_pcb_version: 10,
    heading_text: "Request the Current IP Address of the PCB",
    description:
      "The results will show the IP Addresses of all available network interfaces of the PCB.\n" +
      "The default IP Address of the ethernet port on the PCB will be listed as 'eth0'",
  },
  {
    id: "mac_address",
    name: "MAC Address",
    min_pcb_version: 10,
    heading_text: "Request the PCB's Mac Address",
    description:
      "The results will show the MAC Addresses of all available interfaces. The default MAC Address of the ethernet port on the PCB will be listed as 'eth0'",
  },
  {
    id: "nmap",
    name: "Network Scan",
    min_pcb_version: 10,
    heading_text: "Scan a PCB's Network for available printers on port 9100.",
    description:
      "To scan for a specific printer, enter a specific IP Address => x.x.x.x\n" +
      'To scan an entire subnet for available printers, enter a subnet address ending in ".0/24" => x.x.x.0/24',
    command_param: {
      type: "text",
      placeholder: "x.x.x.x or x.x.x.0/24",
    },
  },
  {
    id: "get_log",
    name: "Log Transfer",
    min_pcb_version: 10,
    heading_text: "Request Specific Log Files from a PCB",
    description: "Choose the log files that you would like to request from the PCB",
    command_param: {
      type: "select",
      options: [
        { value: "bbot", label: "Main Process Logs" },
        { value: "pcb_update_service", label: "Update Service" },
        { value: "wifi", label: "Connectivity Logs" },
      ],
    },
  },
];

export default availableCommands;
