import React from "react";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const ZAPIER_CLIENT_ID = "er6KMINre3umBKy9noIkCgjDrR6uSIfV";

class ApproveZapier extends React.Component {
  makeZapierPartner = async () => {
    const { selectedCustomer } = this.props;
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const state = urlParams.get("state");
      const clientID = urlParams.get("client_id");
      // Verify zapier by checking the client_id from the request:
      if (clientID !== ZAPIER_CLIENT_ID) {
        toast.error("Trouble connecting with zapier. Please refresh and try again");
        return;
      }
      const res = await axios.get(
        "/owner/zapierPartner?customer_id=" + selectedCustomer.customer_id + "&state=" + state
      );
      window.open(res.data.url, "_self");
    } catch (error) {
      toast.error("Encountered an unknown error while attempting to connect with Zapier");
      console.error(error);
    }
  };
  render() {
    return (
      <Container>
        <div className={"margin-bottom-4"}>
          <h2>Approve Zapier</h2>
        </div>
        <div className={"margin-bottom-4"}>
          <p>Clicking approve will grant Zapier read-only access to your Bbot account's data.</p>
        </div>
        <div>
          <Button onClick={() => this.makeZapierPartner()}>Approve</Button>
        </div>
      </Container>
    );
  }
}

export default ApproveZapier;
