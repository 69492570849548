import React, { useState } from "react";
import { NumberField, Select, TextField } from "@doordash/component-fields";
import { Modal } from "@doordash/component-modal";
import { StackChildren, InlineChildren, Text } from "@doordash/design-language";
import Button from "@doordash/component-button";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Banner from "@doordash/component-banner";
import { SALESFORCE_ID_REGEX, TIMEZONE_OPTIONS } from "Constants";
import Toggle from "@doordash/component-toggle";
import { ENV, Environments } from "Config";

type NewCustomerTextField = {
  value: string;
  error: string;
  validator: (value: string) => string;
};

type NewCustomerForm = {
  customerNameForGuests: NewCustomerTextField;
  customerNameForAdmins: NewCustomerTextField;
  salesforceId: NewCustomerTextField;
  currency: NewCustomerTextField;
  locale: NewCustomerTextField;
  referrer: NewCustomerTextField;
  timezone: NewCustomerTextField;
  bbotFeePercent: NewCustomerTextField;
  bbotFeeCents: NewCustomerTextField;
};

type Currency = {
  code: string;
  displayName: string;
};

type Locales = Record<string, string[]>;

type FormFieldOptions = {
  defaultValue?: string;
  validator?: NewCustomerTextField["validator"];
};

const defaultValidator = (value: string) => (value.length ? "" : "This field is required.");

const salesforceValidator = (value: string) => {
  const errorRequired = defaultValidator(value);
  const errorInvalidSalesforceId = SALESFORCE_ID_REGEX.test(value)
    ? ""
    : "Salesforce ID must start with 001D, contain 18 characters, and no spaces.";

  return errorRequired || errorInvalidSalesforceId || "";
};

const formField = ({ defaultValue = "", validator = defaultValidator }: FormFieldOptions = {}) => ({
  value: defaultValue,
  error: "",
  validator,
});

const formDefaults = {
  customerNameForGuests: formField(),
  customerNameForAdmins: formField(),
  salesforceId: formField({ validator: salesforceValidator }),
  currency: formField({ defaultValue: "usd" }),
  locale: formField({ defaultValue: "en_us" }),
  referrer: formField(),
  timezone: formField({ defaultValue: "America/New_York" }),
  bbotFeePercent: formField({ defaultValue: "0" }),
  bbotFeeCents: formField({ defaultValue: "29" }), // Default fee cents
};

type NewCustomerModalProps = {
  onClose: () => void;
  currencies: Currency[];
  localesByCurrency: Locales;
  referrers: [];
};

const NewCustomerModal: React.FC<NewCustomerModalProps> = ({
  onClose,
  currencies = [],
  localesByCurrency = {},
  referrers = [],
}) => {
  const [newCustomerFormValues, setNewCustomerFormValues] = useState<NewCustomerForm>(formDefaults);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [includeContract, setIncludeContract] = useState(false);
  const history = useHistory();

  const setFormValue = (fieldName: keyof NewCustomerForm, fieldValue: string) => {
    setNewCustomerFormValues((oldFormValues: NewCustomerForm) => ({
      ...oldFormValues,
      [fieldName]: {
        ...oldFormValues[fieldName],
        value: fieldValue,
        error: "",
      },
    }));
  };

  const getLocaleOptions = (currency: string) =>
    localesByCurrency[currency]?.map((locale: string) => ({
      name: locale,
      value: locale,
    })) || [];

  const validateForm = () => {
    const newFormEntries = Object.entries(newCustomerFormValues).map(
      ([fieldName, field]) =>
        [fieldName, { ...field, error: field.validator(field.value) }] as [keyof NewCustomerForm, NewCustomerTextField]
    );

    setNewCustomerFormValues(Object.fromEntries(newFormEntries) as NewCustomerForm);

    return newFormEntries.every(([_, field]) => field.error.length === 0);
  };

  // Only validate form after user hits submit
  const saveNewCustomer = async () => {
    const formValid = validateForm();
    if (!formValid) return;

    try {
      setIsSaving(true);
      setError("");
      const payload = {
        customer_name: newCustomerFormValues.customerNameForGuests.value,
        name_for_admin: newCustomerFormValues.customerNameForAdmins.value,
        salesforce_id: newCustomerFormValues.salesforceId.value,
        timezone: newCustomerFormValues.timezone.value,
        referrer: newCustomerFormValues.referrer.value === "bbot" ? "" : newCustomerFormValues.referrer.value,
        currency: newCustomerFormValues.currency.value,
        locale: newCustomerFormValues.locale.value,
        bbot_fee_percent: parseFloat(newCustomerFormValues.bbotFeePercent.value),
        bbot_fee_cents: parseFloat(newCustomerFormValues.bbotFeeCents.value),
        deposit_amt: 0,
        tax_inclusive_pricing: false,
        include_contract: includeContract,
      };
      const res = await axios.post("/api/journey/registerCustomer", payload);
      history.push("journey/" + res.data.journey_id);
    } catch (err: any) {
      console.error(err);
      if (err.errorCode) {
        setError(err.errorCode);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title="Set Up A New Customer"
      onClose={onClose}
      primaryAction={{
        text: "Submit",
        onClick: saveNewCustomer,
        buttonProps: {
          state: isSaving ? Button.States.Loading : Button.States.Default,
        },
      }}
    >
      <StackChildren>
        {error && <Banner variant={Banner.Variants.Negative} body={<Text>{error}</Text>} />}

        <TextField
          label="Customer Name (shown to guests)"
          value={newCustomerFormValues.customerNameForGuests.value}
          onChange={(nameForGuests: string) => setFormValue("customerNameForGuests", nameForGuests)}
          error={newCustomerFormValues.customerNameForGuests.error}
          data-test-id={"customer-name-for-guests-input"}
        />
        <TextField
          label="Customer Name For Admins (shown to admins)"
          value={newCustomerFormValues.customerNameForAdmins.value}
          onChange={(nameForAdmins: string) => setFormValue("customerNameForAdmins", nameForAdmins)}
          error={newCustomerFormValues.customerNameForAdmins.error}
          data-test-id={"customer-name-for-admins-input"}
        />
        <TextField
          label="Salesforce Id"
          value={newCustomerFormValues.salesforceId.value}
          onChange={(salesforceId: string) => setFormValue("salesforceId", salesforceId)}
          error={newCustomerFormValues.salesforceId.error}
          data-test-id={"salesforce-id-input"}
        />
        <InlineChildren>
          <Select
            label="Timezone"
            options={TIMEZONE_OPTIONS}
            value={newCustomerFormValues.timezone.value}
            error={newCustomerFormValues.timezone.error}
            onChange={(timezone: string) => setFormValue("timezone", timezone)}
            data-test-id={"timezone-select"}
          />
          <Select
            label="Referrer"
            options={referrers.map((referrer) => ({ name: referrer, value: referrer }))}
            value={newCustomerFormValues.referrer.value}
            error={newCustomerFormValues.referrer.error}
            disabledValue="Please select a referrer"
            onChange={(referrer: string) => setFormValue("referrer", referrer)}
            data-test-id={"referrer-select"}
          />
        </InlineChildren>
        <InlineChildren>
          <Select
            label="Currency"
            options={currencies.map((currency: Currency) => ({
              name: currency.displayName,
              value: currency.code,
            }))}
            value={newCustomerFormValues.currency.value}
            onChange={(currency: string) => {
              setFormValue("currency", currency);
              const localeOptions = getLocaleOptions(currency);
              if (localeOptions.length) {
                setFormValue("locale", localeOptions[0].value);
              }
            }}
            error={newCustomerFormValues.currency.error}
          />
          <Select
            label="Locale"
            options={getLocaleOptions(newCustomerFormValues.currency.value)}
            value={newCustomerFormValues.locale.value}
            onChange={(locale: string) => setFormValue("locale", locale)}
            error={newCustomerFormValues.locale.error}
          />
        </InlineChildren>
        <InlineChildren>
          <NumberField
            allowFloats
            label="Bbot Fee Percent"
            value={newCustomerFormValues.bbotFeePercent.value}
            error={newCustomerFormValues.bbotFeePercent.error}
            onChange={(bbotFeePercent: string) => setFormValue("bbotFeePercent", bbotFeePercent)}
          />
          <NumberField
            allowFloats
            label="Bbot Fee Fixed Cents"
            value={newCustomerFormValues.bbotFeeCents.value}
            error={newCustomerFormValues.bbotFeeCents.error}
            onChange={(bbotFeeCents: string) => setFormValue("bbotFeeCents", bbotFeeCents)}
          />
        </InlineChildren>

        {ENV !== Environments.Prod && (
          <Banner
            variant={Banner.Variants.Highlight}
            body={
              <StackChildren>
                <Text styles={Text.Styles.BannerBody}>
                  Non-production environment detected. This toggle will not be available in production.
                </Text>

                <Toggle
                  label="Inlude PactSafe Contract in Prebot"
                  onChange={(isSelected: boolean) => setIncludeContract(isSelected)}
                  isSelected={includeContract}
                />
              </StackChildren>
            }
          />
        )}
      </StackChildren>
    </Modal>
  );
};

export default NewCustomerModal;
