import styled from "styled-components";

export const SettingsContainer = styled.div`
  margin-bottom: 12px;
`;

export const TitleRow = styled.div`
  margin-bottom: 12px;
  &.tabs-settings {
    span {
      font-weight: bold;
    }
  }
`;

export const ToggleContainer = styled.div`
  float: right;
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 80%;
`;

export const SettingsEditorContainer = styled.div`
  .settings-element {
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral__line);
    }

    input,
    .ant-input-number,
    .ant-input-number-group-wrapper {
      max-width: 400px;
    }
  }
`;
