import React from "react";
import { Spin } from "bbot-component-library";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const PageLoadingSpinner = ({ ...props }) => {
  return <Spin indicator={<StyledLoadingIcon />} {...props} />;
};

const StyledLoadingIcon = styled(LoadingOutlined)`
  color: var(--color-primary__regular);
  font-size: 32px;
`;

export default PageLoadingSpinner;
