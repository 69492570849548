import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Col, Form } from "react-bootstrap";

const CheckboxInput = ({ label, controlId, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  return (
    <Form.Group
      as={Col}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      controlId={controlId}
      className={props.className ? props.className : "margin-bottom-1"}
    >
      <Form.Check
        className={"check-box  d-flex align-items-center " + (meta.touched && !!meta.error ? "error" : "")}
        isInvalid={!!meta.error}
        {...field}
        {...props}
        label={label}
        disabled={props.disabled}
        type={"checkbox"}
        checked={field.value}
        data-test-id={props.testId}
        onChange={(event) => {
          const newVal = event.target.checked;
          setValue(newVal);
          if (props.onChange) {
            props.onChange(newVal);
          }
        }}
      />
      {props.caption && <Form.Text>{props.caption}</Form.Text>}
    </Form.Group>
  );
};

CheckboxInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  caption: PropTypes.string,
  testId: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default CheckboxInput;
