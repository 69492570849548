import { track } from "instrumentation/tracking/track";

export const trackUpdateFilters = (fields) => track("track_update_filters", fields);

export const trackAddFilterDropdownClicked = (fields) => track("track_click_add_filter_dropdown", fields);

export const trackNewFilterDropdownItemClicked = (fields) => track("track_click_new_filter_dropdown_item", fields);

export const trackNewFilterPopoverSubmitted = (fields) => track("track_click_new_filter_popover_submitted", fields);

export const trackClickUpdateFilter = (fields) => track("track_click_update_filter", fields);

export const trackSubmitUpdateFilter = (fields) => track("track_submit_update_filter", fields);
