import React, { useState, useEffect } from "react";
import ConnectPOSIntegrationModal from "components/global/modals/pos-integrations/ConnectPOSIntegrationModal";
import DisconnectPOSIntegrationModal from "components/global/modals/pos-integrations/DisconnectPOSIntegrationModal";
import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "util/pos-integrations/IntegrationConnectModalUtils";
import EditIntegrationSettingsModal from "util/pos-integrations/EditIntegrationSettingsModal";
import AgilysysIntegrationModalInputs from "components/owner-app/modals/app-store/AgilysysIntegrationModalInputs";
import * as Yup from "yup";
import AgilysysAPICredentialsModalInputs from "components/owner-app/modals/app-store/AgilysysAPICredentialsModalInputs";
import { makePOSTRequestWithErrorHandling } from "util/Utils";

const AgilysysIntegrationModal = (props) => {
  const [agilysysSettings, setAgilysysSettings] = useState({});
  const [showConnectWarning, setShowConnectWarning] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const { selectedCustomer, disconnectMode, show, onClose, onSave } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getAgilysysSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAgilysysSettings = async () => {
    const getSettingsUrl = "/admin/getAgilysysConnectionStatus";
    setLoadingData(true);
    await getIntegrationSettings(getSettingsUrl, "Agilysys", selectedCustomer, saveAgilysysSettings);
    setLoadingData(false);
  };

  const saveAgilysysSettings = async (agilysysSettings) => {
    setAgilysysSettings(agilysysSettings);
    setShowConnectWarning(agilysysSettings?.api_url == null);
  };

  const connectAgilysys = async (values, deleteMenuItems) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      api_url: values?.apiUrl,
      inject_orders: values?.injectOrders ?? true,
      employee: values?.employee,
      profit_center: values?.profitCenter,
      tender: values?.tender,
      delete_menu_items: deleteMenuItems,
      display_checkout_info_on_ticket: values?.displayCheckoutInfoOnTicket,
      display_fulfillment_on_ticket: values?.displayFulfillmentOnTicket,
      display_location_on_ticket: values?.displayLocationOnTicket,
    };
    await connectIntegration("/admin/connectAgilysys", payload, "Agilysys", setLoadingData, onConnectSuccess);
  };

  const onConnectSuccess = async () => {
    setShowConnectWarning(false);
    await getAgilysysSettings();
    onSave();
    onClose();
  };

  const disconnectAgilysys = async (deleteMenuItems) => {
    await disconnectIntegration(
      "/admin/disconnectAgilysys",
      "Agilysys",
      selectedCustomer,
      deleteMenuItems,
      setLoadingData,
      onDisconnectSuccess
    );
  };

  const onDisconnectSuccess = async () => {
    await saveAgilysysSettings(true);
    setShowConnectWarning(true);
    onClose();
    onSave();
  };

  const disconnectModal = () => {
    return (
      <DisconnectPOSIntegrationModal
        onHide={onClose}
        show={show}
        disconnectFunc={disconnectAgilysys}
        onClose={onClose}
        integrationSourceName={"Agilysys"}
      />
    );
  };

  const submitEditSettings = async (values) => {
    await connectAgilysys(values, true);
    await getAgilysysSettings();
  };

  const closeAndResetModal = () => {
    setShowConnectWarning(agilysysSettings?.inject_orders == null);
    onClose();
  };

  const editSettingsModal = () => {
    let initialValues = {
      injectOrders: agilysysSettings?.inject_orders ?? true,
      employee: agilysysSettings?.employee,
      profitCenter: agilysysSettings?.profit_center,
      tender: agilysysSettings?.tender,
      apiUrl: agilysysSettings?.api_url,
      displayCheckoutInfoOnTicket: agilysysSettings?.display_checkout_info_on_ticket ?? true,
      displayFulfillmentOnTicket: agilysysSettings?.display_fulfillment_on_ticket ?? true,
      displayLocationOnTicket: agilysysSettings?.display_location_on_ticket ?? true,
    };

    let validationSchema = Yup.object({
      profitCenter: Yup.string().required("Please select a profit center."),
      employee: Yup.string().required("Please select an Employee."),
      tender: Yup.string().required("Please select a Tender."),
    });

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={closeAndResetModal}
        onCancel={closeAndResetModal}
        onSubmit={submitEditSettings}
        modalBody={AgilysysIntegrationModalInputs}
        loadingData={loadingData}
        modalTitle={"Agilysys POS Settings"}
        agilysysSettings={agilysysSettings}
        validationSchema={validationSchema}
      />
    );
  };

  const apiCredentialsModal = () => {
    let initialValues = {
      apiUrl: "",
      clientId: "",
      authCode: "",
      directoryName: agilysysSettings?.directory_name ?? "",
      selfHostedDB: agilysysSettings?.self_hosted_db ?? true,
      sftpServerUrl: agilysysSettings?.sftp_server_url ?? "",
      sftpServerUsername: agilysysSettings?.sftp_server_username ?? "",
      sftpServerPassword: agilysysSettings?.sftp_server_password ?? "",
      sftpPath: agilysysSettings?.sftpPath ?? "",
    };

    let validationSchema = Yup.object({
      apiUrl: Yup.string().required("Please enter an API url."),
      clientId: Yup.string().required("Please enter a Client Id."),
      authCode: Yup.string().required("Please enter an Authorization Code."),
      selfHostedDB: Yup.bool().required("Please select a database hosting method."),
    });

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={closeAndResetModal}
        onCancel={closeAndResetModal}
        onSubmit={submitAPICredentials}
        modalBody={AgilysysAPICredentialsModalInputs}
        loadingData={loadingData}
        modalTitle={"Agilysys POS Settings"}
        agilysysSettings={agilysysSettings}
        validationSchema={validationSchema}
      />
    );
  };

  const submitAPICredentials = async (values) => {
    await testAPICredentials(values);
  };

  const testAPICredentials = async (values) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      api_url: values.apiUrl,
      client_id: values.clientId,
      auth_code: values.authCode,
      directory_name: values?.directoryName,
      self_hosted_db: values?.selfHostedDB,
      sftp_server_url: values?.sftpServerUrl,
      sftp_server_username: values?.sftpServerUsername,
      sftp_server_password: values?.sftpServerPassword,
      sftp_path: values?.sftpPath,
    };

    await makePOSTRequestWithErrorHandling(
      "/admin/testAgilysysAPICredentials",
      payload,
      onTestAPICredentialsSuccess,
      null,
      "Trouble connecting to Agilysys."
    );
  };

  const onTestAPICredentialsSuccess = async () => {
    await getAgilysysSettings();
  };

  const connectModal = () => {
    return (
      <ConnectPOSIntegrationModal
        integrationSourceName={"Agilysys"}
        onHide={onClose}
        onSubmit={() => {
          setShowConnectWarning(false);
        }}
        show={showConnectWarning && show}
        loadingData={loadingData}
      />
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  if (showConnectWarning) {
    return connectModal();
  }

  return agilysysSettings.api_url ? editSettingsModal() : apiCredentialsModal();
};

export default AgilysysIntegrationModal;
