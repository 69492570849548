import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { BbotInput, BbotModal, DatePicker } from "bbot-component-library";
import styled from "styled-components";

const AddEditDateRange = (props) => {
  const [startDate, setStartDate] = useState(moment().toISOString());
  const [endDate, setEndDate] = useState(null);
  const [notes, setNotes] = useState("");

  const { show, onHideCallback, onSubmitCallback, initialDateRange } = props;

  useEffect(() => {
    if (initialDateRange) {
      setStartDate(initialDateRange.dateRange.start_date);
      setEndDate(initialDateRange.dateRange.end_date);
      setNotes(initialDateRange.dateRange.notes);
    }
  }, [initialDateRange]);

  const onHide = () => {
    setStartDate(new Date());
    setEndDate(null);
    setNotes("");
    onHideCallback();
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onHide}
      title={"Add/Edit Date Range"}
      okText={"Save"}
      onOk={() => {
        onSubmitCallback(startDate, endDate, notes);
      }}
    >
      <div className={"margin-bottom-2"}>
        <StyledRangepicker
          value={[moment(startDate), !!endDate ? moment(endDate) : null]}
          showTime={{ format: "hh:mm a" }}
          format="YYYY-MM-DD hh:mm a"
          onChange={(newRange) => {
            if (!newRange) return;

            const [rangeStart, rangeEnd] = newRange;
            setStartDate(rangeStart.toISOString());
            setEndDate(rangeEnd.toISOString());
          }}
        />
      </div>
      <div>
        <div>Notes</div>
        <BbotInput value={notes} onChange={(e) => setNotes(e.target.value)} />
      </div>
    </BbotModal>
  );
};

const StyledRangepicker = styled(DatePicker.RangePicker)`
  width: 100%;
  border-radius: 8px;
  padding: 8px;
`;

export default AddEditDateRange;
