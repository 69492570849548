import React, { useState, useEffect } from "react";
import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "util/pos-integrations/IntegrationConnectModalUtils";
import EditIntegrationSettingsModal from "util/pos-integrations/EditIntegrationSettingsModal";
import SmartBarIntegrationModalInputs from "components/owner-app/modals/app-store/SmartBarIntegrationModalInputs";
import SearchSelectInputUtils from "components/global/form/SearchSelectInputUtils";
import {
  getSelectedOptions,
  getFulfillmentMethodChoices,
} from "components/global/form/form-utils/CommonMultiSelectOptionUtils";
import PromptYesNoPOSIntegrationModal from "components/global/modals/pos-integrations/PromptYesNoPOSIntegrationModal";

const SmartBarIntegrationModal = (props) => {
  const [smartBarSettings, setSmartBarSettings] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [allFulfillmentMethods, setAllFulfillmentMethods] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const { selectedCustomer, disconnectMode, show, onClose, onSave } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getSmartBarSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSmartBarSettings = async () => {
    const getSettingsUrl = "/admin/getSmartBarConnectionStatus";
    await getIntegrationSettings(getSettingsUrl, "SmartBar", selectedCustomer, updateSmartBarSettings);
  };

  const updateSmartBarSettings = async (getSmartBarSettingsResponse) => {
    setSmartBarSettings(getSmartBarSettingsResponse?.smart_bar_settings);
    setAllFulfillmentMethods(getSmartBarSettingsResponse?.all_fulfillment_methods);
    setAllLocations(getSmartBarSettingsResponse?.all_locations);
    setAllTags(getSmartBarSettingsResponse?.all_tags);
  };

  const connectSmartBar = async (values) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      fulfillment_methods: SearchSelectInputUtils.getValuesFromMultiSelect(values.selectedFulfillmentMethods),
      location_ids: SearchSelectInputUtils.getValuesFromMultiSelect(values.selectedLocations),
      tags: SearchSelectInputUtils.getValuesFromMultiSelect(values.selectedTags),
      guest_instructions: values.instructionsForGuests,
    };
    await connectIntegration("/admin/connectSmartBar", payload, "SmartBar", setLoadingData, onConnectSuccess);
  };

  const onConnectSuccess = async () => {
    await getSmartBarSettings();
    onSave();
    onClose();
  };

  const disconnectModal = () => {
    return (
      <PromptYesNoPOSIntegrationModal
        onHide={onClose}
        show={show}
        onSubmit={disconnectSmartBar}
        onClose={onClose}
        integrationSourceName={"Smart Bar"}
        warningText={"Are you sure you would like to disconnect Smart Bar?"}
        submitText={"Disconnect"}
        title={"Disconnect Smart Bar"}
        cancelText={"Cancel"}
      />
    );
  };

  const disconnectSmartBar = async () => {
    await disconnectIntegration(
      "/admin/disconnectSmartBar",
      "Smart Bar",
      selectedCustomer,
      false,
      setLoadingData,
      onDisconnectSuccess
    );
  };

  const onDisconnectSuccess = async () => {
    await updateSmartBarSettings({});
    onClose();
    onSave();
  };

  const getLocationOptions = (selectedFulfillmentMethods, valuesFromMultiSelect) => {
    let formattedSelectedFulfillmentMethods = valuesFromMultiSelect
      ? SearchSelectInputUtils.getValuesFromMultiSelect(selectedFulfillmentMethods)
      : selectedFulfillmentMethods;

    let validLocations =
      selectedFulfillmentMethods?.length === 0
        ? Object.keys(allLocations)
        : Object.keys(allLocations).filter((lid) =>
            formattedSelectedFulfillmentMethods.includes(allLocations[lid].fulfillment_method)
          );

    let locationOptions = validLocations.map((lid) => ({
      value: lid,
      label: allLocations[lid].location_name,
    }));
    locationOptions.sort((a, b) => (a.label === "Auto" ? -2 : a.label > b.label ? 1 : -1));
    return locationOptions;
  };

  const getTagOptions = () => {
    return Object.keys(allTags).map((t) => ({
      value: t,
      label: allTags[t],
    }));
  };

  const getSelectedTagsOptions = (selectedTags) => {
    return getSelectedOptions(getTagOptions, selectedTags);
  };

  const getSelectedLocationsOptions = (selectedLocations, selectedFulfillmentMethods) => {
    return getLocationOptions(selectedFulfillmentMethods, false).filter((l) => selectedLocations.includes(l.value));
  };

  const getSelectedFulfillmentMethodsOptions = (selectedFulfillmentMethods) => {
    return getFulfillmentMethodChoices(allFulfillmentMethods).filter((fm) =>
      selectedFulfillmentMethods.includes(fm.value)
    );
  };

  const submitEditSettings = async (values) => {
    await connectSmartBar(values);
    await getSmartBarSettings();
  };

  const editSettingsModal = () => {
    let initialValues = {
      selectedFulfillmentMethods: getSelectedFulfillmentMethodsOptions(smartBarSettings?.fulfillment_methods ?? []),
      selectedLocations: getSelectedLocationsOptions(
        smartBarSettings?.location_ids ?? [],
        smartBarSettings?.fulfillment_methods ?? []
      ),
      selectedTags: getSelectedTagsOptions(smartBarSettings?.tags ?? []),
      instructionsForGuests: smartBarSettings?.guest_instructions ?? "",
    };

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={onClose}
        onSubmit={submitEditSettings}
        modalBody={SmartBarIntegrationModalInputs}
        loadingData={loadingData}
        modalTitle={"Smart Bar USA"}
        allTags={allTags}
        allFulfillmentMethods={allFulfillmentMethods}
        getTagOptions={getTagOptions}
        getLocationOptions={getLocationOptions}
      />
    );
  };

  return disconnectMode ? disconnectModal() : editSettingsModal();
};

export default SmartBarIntegrationModal;
