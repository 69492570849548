import React from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ActivityFeed from "components/owner-app/activity-feed/ActivityFeed";
import PcbGeneralCommandCard from "components/owner-app/cards/pcb-special-commands-card/PcbGeneralCommandCard";
import availableCommands from "components/owner-app/cards/pcb-special-commands-card/PcbAvailableCommands";

const PcbSpecialCommandsCard = ({ selectedPCB }) => {
  const requestNewCommand = async (command_dict) => {
    try {
      const payload = {
        command_dict,
      };
      await axios.put("printer-setup/controlbox-special-commands/" + selectedPCB.id, payload);

      toast.success(
        "Successfully requested " +
          availableCommands.find((element) => element.id === command_dict.command).name +
          " from PCB"
      );
    } catch (error) {
      toast.error(
        "Unable to Request " +
          availableCommands.find((element) => element.id === command_dict.command).name +
          ". " +
          error.response.data.errorCode
      );
    }
  };

  return (
    <div className={"margin-y-2"}>
      <div className={"margin-x-1"}>
        <h4>PCB Special Commands (Admin Only)</h4>
        <div>
          <p>
            Use the options below to request Special Commands on the current PCB. Detailed results will populate in the
            Feed at the bottom of the page as soon as they are available.
          </p>
        </div>
      </div>

      <div className={"margin-x-2"}>
        <Row>
          {availableCommands
            .filter((command) => command.min_pcb_version <= selectedPCB.current_software_version_id)
            .map((availableCommand, index) => (
              <Col lg={6} sm={6} className={"margin-bottom-2"} key={index}>
                <PcbGeneralCommandCard currentCommand={availableCommand} requestNewCommand={requestNewCommand} />
              </Col>
            ))}
        </Row>
        <ActivityFeed
          endpoint={"printer-setup/controlbox-special-commands/" + selectedPCB.id}
          endpointParameters={{
            pcb_id: selectedPCB.id,
            feed_type: "pcb_commands",
          }}
          pollInterval={5}
        />
      </div>
    </div>
  );
};

export default PcbSpecialCommandsCard;
