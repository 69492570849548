import React, { useState } from "react";
import Moment from "react-moment";

import EditPrinterModal from "components/owner-app/modals/printer-configuration/EditPrinterModal";
import AddPrinterModal from "components/owner-app/modals/printer-configuration/AddPrinterModal";

import PencilIcon from "assets/svgs/icons/icon-pencil.svg";
import { BbotTable } from "bbot-component-library";

const PrintersCard = ({ printers, stations, driverProfiles, selectedCustomer, onSaveCallback, network, userInfo }) => {
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [showEditPrinterModal, setShowEditPrinterModal] = useState(false);
  const [showAddPrinterModal, setShowAddPrinterModal] = useState(false);

  const printerData = printers
    .sort((a, b) => a.last_print_time - b.last_print_time)
    .map((printer) => ({
      ...printer,
      key: printer.id,
      last_print: printer.last_print_time && <Moment fromNow>{printer.last_print_time}</Moment>,
      edit_icon: <img alt="edit icon" className="icon xx-small" src={PencilIcon} />,
    }));

  const getLoadBalanceMasterChoices = (printers, selectedPrinter) => {
    if (selectedPrinter) {
      printers = printers.filter((printer) => printer.id !== selectedPrinter.id);
    }

    return printers
      .filter((printer) => !printer.loadbalance_master)
      .map((printer) => ({
        value: printer.id,
        label: printer.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <>
      <BbotTable
        id={"printer-configuration-printers-table"}
        title={"Printers"}
        manuallyFormattedColumns={[
          { title: "Name", dataIndex: "name" },
          { title: "Last Print", dataIndex: "last_print" },
          { title: "# of Prints", dataIndex: "total_prints" },
          { title: "", dataIndex: "edit_icon", width: "10%" },
        ]}
        data={printerData}
        addButtonText={"Add a New Printer"}
        onAdd={() => setShowAddPrinterModal(true)}
        manualOnRow={(printer) => ({
          onClick: () => {
            setSelectedPrinter(
              printers.filter((p) => {
                return p.id === printer.key;
              })[0]
            );
            setShowEditPrinterModal(true);
          },
        })}
        showCountByTitle={false}
      />
      <AddPrinterModal
        showAddPrinterModal={showAddPrinterModal}
        onHideCallback={() => setShowAddPrinterModal(false)}
        onSaveCallback={() => {
          setShowAddPrinterModal(false);
          onSaveCallback();
        }}
        network={network}
        customer={selectedCustomer}
        stations={stations}
        driverProfiles={driverProfiles}
        printers={printers}
        getLoadBalanceMasterChoices={getLoadBalanceMasterChoices}
      />
      <EditPrinterModal
        showEditPrinterModal={showEditPrinterModal}
        onHideCallback={() => setShowEditPrinterModal(false)}
        selectedPrinter={selectedPrinter}
        onSaveCallback={() => {
          setShowEditPrinterModal(false);
          onSaveCallback();
        }}
        network={network}
        stations={stations}
        driverProfiles={driverProfiles}
        allPrinters={printers}
        selectedCustomer={selectedCustomer}
        userInfo={userInfo}
        getLoadBalanceMasterChoices={getLoadBalanceMasterChoices}
      />
    </>
  );
};

export default PrintersCard;
