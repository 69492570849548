import React from "react";
import PropTypes from "prop-types";
import { NumberInput } from "../index";

const PercentageInput = ({
  min = 0,
  max = 1,
  step = "0.01",
  formatter = (value) => (value * 100).toFixed(0),
  parser = (value) => value / 100,
  ...props
}) => {
  return <NumberInput min={min} max={max} step={step} formatter={formatter} parser={parser} {...props} />;
};

export default PercentageInput;

PercentageInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number, // Minimum accepted value
  max: PropTypes.number, // Maximum accepted value
  formatter: PropTypes.func,
  parser: PropTypes.func,
  step: PropTypes.string, // Fixed decimals. Pass "0.01" to fix 2 decimals
  placeholder: PropTypes.number,
  hidden: PropTypes.bool,
  addonBefore: PropTypes.string, // Rendered in a small box to the left of input
  addonAfter: PropTypes.string, // Rendered in a small box to the right of input
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validationMessage: PropTypes.string,
  rules: PropTypes.array, // Validation rules
};
