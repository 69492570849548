import { track } from "instrumentation/tracking/track";

export * from "instrumentation/tracking/page-tracking-events/EmployeesPageTracking";
export * from "instrumentation/tracking/page-tracking-events/DashboardPageTracking";
export * from "instrumentation/tracking/page-tracking-events/LocationEditorPageTracking";
export * from "instrumentation/tracking/page-tracking-events/PastOrdersPageTracking";
export * from "instrumentation/tracking/page-tracking-events/GuestNotificationSetsPageTracking";
export * from "instrumentation/tracking/page-tracking-events/AppStorePageTracking";
export * from "instrumentation/tracking/page-tracking-events/PromotionsPageTracking";
export * from "instrumentation/tracking/page-tracking-events/ServiceChargesPageTracking";
export * from "instrumentation/tracking/page-tracking-events/PrebotTracking.js";

// This is here because multiple files have legacy links in the header.
export const trackClickLegacyLink = (fields) => track("track_click_legacy_link", fields);
