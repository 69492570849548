import React from "react";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { SearchSelectInput } from "components/global/form";

class CreateDefaultServiceRequestModal extends React.Component {
  createServiceRequest = async (values) => {
    const { onSave, onClose, selectedCustomer, locations, defaultServiceRequests } = this.props;
    if (values.type === "Custom") {
      return onClose(true); // redirects you to CreateEditServiceRequestModal
    }
    const payload = {
      customerId: selectedCustomer?.customer_id,
      serviceRequests: [defaultServiceRequests.find((request) => request.type === values.type)],
      locations: locations.map((location) => location.locationId),
    };

    try {
      await axios.post("/owner/createServiceRequest", payload);
      toast.success("Successfully saved service request.");
      onSave();
      onClose();
    } catch (error) {
      toast.error("Trouble saving service request. Please refresh and try again.");
      console.error("/owner/createServiceRequest failed with error ", error);
    }
  };

  render() {
    const { show, onClose, serviceRequests, defaultServiceRequests } = this.props;
    const initialValues = {
      type: "",
    };
    return (
      <Modal
        size={"md"}
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            type: Yup.string().required("Please choose a service request type."),
          })}
          onSubmit={this.createServiceRequest}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Create New Service Request</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row className={"margin-bottom-2"}>
                  <SearchSelectInput
                    name={"type"}
                    id={"type"}
                    label={"Choose from a Service Request Default or choose 'Custom'"}
                    options={defaultServiceRequests
                      .filter(
                        (requestOption) =>
                          requestOption.type === "Custom" ||
                          !serviceRequests.find((request) => request.type === requestOption.type)
                      )
                      .map((request) => {
                        return { value: request.type, label: request.label_for_patron || "Custom" };
                      })}
                  />
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"tertiary"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateDefaultServiceRequestModal;
