import React, { useEffect, useState } from "react";
import axios from "axios";

// Components
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// Styles
import "./dashboard.scss";

// Config
import BBOT_SERVER from "Config";
import IncompleteJourneyModal from "components/owner-app/modals/incomplete-journey-modal/IncompleteJourneyModal";
import { generalErrorAlert, isFeatureEnabledById } from "util/Utils";
import { BbotAlert } from "bbot-component-library";
import { trackClickDashboardItem } from "instrumentation/tracking/page-tracking-events/DashboardPageTracking";

export const MENU_LINK_CONFIG = [
  {
    testId: "menus-navigation-link",
    name: "Menus",
    link: "/#!/home",
    iconClass: "zmdi zmdi-home dashboard-link-icon",
    googleIcon: "menu_book",
  },
  {
    name: "Available Hours + Menu Settings",
    link: "/#!/edit/schedule",
    iconClass: "zmdi zmdi-calendar-alt dashboard-link-icon",
    googleIcon: "today",
  },
  {
    name: "Presentation to Guests",
    link: "/#!/presentation",
    iconClass: "zmdi zmdi-collection-image dashboard-link-icon",
    googleIcon: "image",
  },

  {
    name: "Text Message Management",
    link: "/#!/messaging",
    iconClass: "zmdi zmdi-comment-outline dashboard-link-icon",
    googleIcon: "textsms",
  },
];

export const SALES_LINK_CONFIG = [
  {
    name: "Payouts and Billing",
    link: "/payouts-and-billing",
    reactLink: true,
    iconClass: "zmdi zmdi-money-off dashboard-link-icon",
    googleIcon: "payments",
  },
  {
    name: "Past Orders",
    link: "/orders",
    reactLink: true,
    iconClass: "zmdi zmdi-email-open dashboard-link-icon",
    googleIcon: "manage_search",
  },
  {
    name: "Reports",
    link: "/#!/account",
    iconClass: "zmdi zmdi-money dashboard-link-icon",
    id: "reports-link",
    googleIcon: "summarize",
  },
];

export const OWNER_LINK_CONFIG = [
  {
    name: "Account Settings",
    link: "/#!/settings",
    iconClass: "zmdi zmdi-settings dashboard-link-icon",
    id: "settings-link",
    googleIcon: "settings",
  },
  {
    name: "Promos + Discounts",
    link: "/promotions",
    reactLink: true,
    iconClass: "zmdi zmdi-money-off dashboard-link-icon",
    id: "promotions-link",
    googleIcon: "local_offer",
  },
  {
    name: "Service Charges",
    link: "/service-charges",
    reactLink: true,
    id: "service-charges-link",
    googleIcon: "request_quote",
  },
  {
    name: "Tip Settings",
    link: "/tip-settings",
    reactLink: true,
    id: "tipping-settings-link",
    googleIcon: "paid",
  },
  {
    name: "Employees",
    link: "/employees",
    reactLink: true,
    iconClass: "zmdi zmdi-face dashboard-link-icon",
    id: "employees-link",
    googleIcon: "people",
  },
  {
    name: "Tables / Location Codes",
    link: "/edit-locations",
    reactLink: true,
    iconClass: "zmdi zmdi-my-location dashboard-link-icon",
    id: "edit-locations-link",
    googleIcon: "qr_code_scanner",
  },
  {
    name: "Kiosk Configuration Settings",
    link: "/edit-kiosk-config",
    reactLink: true,
    shouldCheckLinkWhitelist: true,
    id: "edit-kiosk-config",
    googleIcon: "smartphone",
  },
  {
    name: "Printer Configuration",
    link: "/printer-configuration",
    reactLink: true,
    iconClass: "zmdi zmdi-print dashboard-link-icon",
    googleIcon: "print",
  },
  {
    name: "Support Text Alerts",
    link: "/#!/supportAlerts",
    iconClass: "zmdi zmdi-comment-alert dashboard-link-icon",
    googleIcon: "textsms",
  },
  // {
  //   name: "Support Alerts",
  //   link: "/support-alerts",
  //   reactLink: true,
  //   googleIcon: "textsms",
  // },
  {
    name: "Guest Notification Sets",
    link: "/fulfillment-notices",
    reactLink: true,
    iconClass: "zmdi zmdi-collection-text dashboard-link-icon",
    googleIcon: "notifications",
  },
  {
    name: "Order Grouping",
    link: "/manage-print-rules",
    reactLink: true,
    iconClass: "zmdi zmdi-group dashboard-link-icon",
    googleIcon: "table_view",
  },
  {
    name: "System Logs",
    link: "/system-logs",
    reactLink: true,
    iconClass: "zmdi zmdi-collection-text dashboard-link-icon",
    googleIcon: "list_alt",
  },
  {
    name: "Service Requests",
    link: "/service-requests",
    reactLink: true,
    featureId: "service_requests",
    iconClass: "zmdi zmdi-run dashboard-link-icon",
    googleIcon: "directions_run",
  },
  {
    name: "BOS Settings",
    link: "/#!/bosSettings",
    iconClass: "zmdi zmdi-group dashboard-link-icon",
    id: "bos-settings-link",
    googleIcon: "dashboard_customize",
  },
  // {
  //   name: "Restaurant Details",
  //   link: "/restaurant-details",
  //   id: "restaurant-details-link",
  //   reactLink: true,
  //   googleIcon: "description",
  // },
];

export const INTEGRATION_LINK_CONFIG = [
  {
    name: "Bbot App Store",
    link: "/bbot-app-store",
    reactLink: true,
    iconClass: "zmdi zmdi-store dashboard-link-icon",
    id: "app-store-link",
    googleIcon: "apps",
  },
];

export const ADMIN_LINK_CONFIG = [
  {
    name: "Handheld Devices",
    link: "/#!/edit/devices",
    iconClass: "zmdi zmdi-devices dashboard-link-icon",
    googleIcon: "devices",
  },
  {
    name: "Device Profiles",
    link: "/#!/edit/handheldConfigs",
    iconClass: "zmdi zmdi-smartphone-setting dashboard-link-icon",
    googleIcon: "tablet_mac",
  },
  {
    name: "Stations",
    link: "/stations",
    reactLink: true,
    googleIcon: "computer",
  },
  {
    name: "Customer Properties",
    link: "/#!/customerProperties",
    iconClass: "zmdi zmdi-info dashboard-link-icon",
    id: "customer-properties-link",
    googleIcon: "settings",
  },
  {
    name: "Feature Management",
    link: "/feature-management",
    iconClass: "zmdi zmdi-flag dashboard-link-icon",
    id: "feature-flags-link",
    reactLink: true,
    googleIcon: "flag",
  },
  {
    name: "PreBot",
    link: "/myjourneys",
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    reactLink: true,
    googleIcon: "rocket_launch",
  },
  {
    name: "Admin Portal",
    link: "/admin/portal",
    isAdminPortalLink: true,
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    googleIcon: "admin_panel_settings",
  },
  {
    name: "Manage Partners",
    link: "/manage-partners",
    reactLink: true,
    iconClass: "zmdi zmdi-accounts-list-alt dashboard-link-icon",
    googleIcon: "api",
  },
];

export const SUPER_ADMIN_LINK_CONFIG = [
  {
    name: "Sales Rep Commissions",
    link: "/sales-commission",
    reactLink: true,
    iconClass: "zmdi zmdi-balance-wallet dashboard-link-icon",
    googleIcon: "groups",
  },
  {
    name: "Cut Agreements",
    link: "/cut-agreements",
    reactLink: true,
    iconClass: "zmdi zmdi-cutlery dashboard-link-icon",
    id: "cut-agreements",
    googleIcon: "vertical_split",
  },
  {
    name: "Card Processing Rates",
    link: "/card-processing-rates",
    reactLink: true,
    iconClass: "zmdi zmdi-card dashboard-link-icon",
    googleIcon: "credit_card",
  },
  {
    name: "Owner Panel Announcements",
    link: "/owner-announcements",
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    reactLink: true,
    googleIcon: "textsms",
  },
  {
    name: "PCB Management",
    link: "/pcb-updater",
    reactLink: true,
    iconClass: "zmdi zmdi-accounts-list-alt dashboard-link-icon",
    googleIcon: "device_hub",
  },
];
export const BBOT_ADMIN_ONLY_LINKS = [
  {
    name: "Cut Agreements",
    link: "/cut-agreements",
    reactLink: true,
    iconClass: "zmdi zmdi-cutlery dashboard-link-icon",
    id: "cut-agreements",
    googleIcon: "vertical_split",
  },
  {
    name: "Card Processing Rates",
    link: "/card-processing-rates",
    reactLink: true,
    iconClass: "zmdi zmdi-card dashboard-link-icon",
    googleIcon: "credit_card",
  },
];

const OwnerAppDashboard = (props) => {
  const [journeys, setJourneys] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  const {
    allowedCustomers,
    selectedCustomer,
    selectAllowedCustomer,
    userInfo,
    history,
    linksWhitelist,
    match: {
      params: { selectedCustomerId: customerIdFromUrl },
    },
  } = props;

  const userIsAdmin = userInfo?.role === "admin";
  const userIsBbotSuperAdmin = userInfo?.permissions?.includes("manage_admins") && userInfo?.teams?.includes("bbot");
  const userIsBbotAdmin = userInfo?.teams?.includes("bbot");
  const redirectUrlAppendix = userIsAdmin ? BBOT_SERVER + "/superuser/console" : BBOT_SERVER + "/owner/console";

  /**
   * If there's a customer id in the url, try to select that customer
   */
  useEffect(() => {
    if (customerIdFromUrl && allowedCustomers.length) {
      selectAllowedCustomer(customerIdFromUrl);
    }
  }, [customerIdFromUrl, allowedCustomers]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Get the announcements and journeys when user changes selected customer
   */
  useEffect(() => {
    const getJourneys = async () => {
      try {
        const res = await axios.get("/api/journey/getJourneys");
        setJourneys(res.data.journeys);
      } catch (error) {
        console.error(error);
        generalErrorAlert(error, "Couldn't load account information.");
      }
    };

    const getAnnouncements = async () => {
      try {
        const res = await axios.get("/owner/getAnnouncements?customerId=" + selectedCustomer?.customer_id);
        setAnnouncements(res.data.announcements);
      } catch (error) {
        generalErrorAlert(error, "Error Retrieving Announcements");
      }
    };

    getJourneys();

    if (selectedCustomer?.customer_id) {
      getAnnouncements();
    }
  }, [selectedCustomer]);

  /**
   * Redirect to journey page if user has access to 1 account and that account is not set up yet
   */
  useEffect(() => {
    const incompleteJourneys = journeys.filter((journey) => journey.tasks_completed < journey.total_tasks);

    if (userInfo && userInfo.role !== "admin" && allowedCustomers.length === 1 && incompleteJourneys.length > 0) {
      history.push(`/journey/${incompleteJourneys[0].id}`);
    } else if (
      userInfo &&
      userInfo.role !== "admin" &&
      allowedCustomers.length > 1 && // If the user is multi vendor
      journeys.length && // Make sure journeys have actually been loaded, and it's not just initial state
      incompleteJourneys.length === journeys.length // If the user has NO accounts set up yet, so # of incomplete journeys === # of total journeys
    ) {
      history.push("/myjourneys");
    }
  }, [journeys, allowedCustomers, userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeAnnouncement = async (announcement_id) => {
    const payload = {
      announcement: announcement_id,
    };

    try {
      await axios.post("/owner/closeAnnouncement?userId=" + userInfo.id, payload);
    } catch (error) {
      generalErrorAlert(error, "Error Closing Announcement");
    }
  };

  const linkList = (links) => {
    const redirectUrlAppendix = userIsAdmin ? BBOT_SERVER + "/superuser/console" : BBOT_SERVER + "/owner/console";

    return links
      .filter(
        (link) =>
          !(link.featureId && !isFeatureEnabledById(link.featureId, selectedCustomer)) &&
          (!link.shouldCheckLinkWhitelist || (link.shouldCheckLinkWhitelist && linksWhitelist.includes(link.link)))
      )
      .map((link, index) => (
        <div key={"dashboard-link-" + index} className="dashboard-link">
          {link.reactLink ? (
            <Link to={link.link} onClick={() => trackClickDashboardItem({ destination_link: link.link })}>
              <div className={"dashboard-link-container"}>
                <span className={"material-icons-outlined"}>{link.googleIcon}</span>
                <span>{link.name}</span>
              </div>
            </Link>
          ) : (
            <a
              href={
                link.isAdminPortalLink
                  ? BBOT_SERVER + link.link
                  : redirectUrlAppendix + link.link + "?from=owner_react_app"
              }
              id={link.id}
              onClick={() => trackClickDashboardItem({ destination_link: link.link })}
            >
              <div className={"dashboard-link-container"}>
                <span className={"material-icons-outlined"}>{link.googleIcon}</span>
                <span>{link.name}</span>
              </div>
            </a>
          )}
        </div>
      ));
  };

  const relevantAnnouncements =
    announcements?.sort((announcement_a, announcement_b) =>
      announcement_a.start_date < announcement_b.start_date
        ? 1
        : announcement_a.start_date > announcement_b.start_date
        ? -1
        : 0
    ) || [];

  return (
    <div>
      <Container className="dashboard-container">
        {relevantAnnouncements.map((announcement, index) => (
          <div key={"dashboard-alert-" + index}>
            <BbotAlert
              className="margin-bottom-1"
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: announcement.header,
                  }}
                />
              }
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: announcement.body,
                  }}
                />
              }
              type="info"
              showIcon
              closable
              onClose={() => closeAnnouncement(announcement["id"])}
            />
          </div>
        ))}
        <Row className={"margin-bottom-6"}>
          <Col>
            <h2>
              Welcome
              {selectedCustomer ? (
                <>
                  {" to "}
                  <span className={"color-primary__dark"}>{selectedCustomer.customer_name}</span>
                </>
              ) : (
                <></>
              )}
            </h2>
          </Col>
        </Row>
        <Row className="links-row">
          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined dashboard-link-icon"}>menu_book</span>
              <span className="dashboard-menu-title">Menu Management</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{linkList(MENU_LINK_CONFIG)}</div>
          </Col>
          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined dashboard-link-icon"}>savings</span>
              <span className="dashboard-menu-title">Financials</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{linkList(SALES_LINK_CONFIG)}</div>
          </Col>

          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined dashboard-link-icon"}>restaurant</span>
              <span className="dashboard-menu-title">Restaurant Setup</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>
              {linkList(OWNER_LINK_CONFIG)}
              {!userIsAdmin && journeys.length > 0 && (
                <div key={"dashboard-link-wizard"} className="dashboard-link">
                  <Link to={"/myjourneys"}>
                    <div className={"dashboard-link-container"}>
                      <span className={"material-icons-outlined"}>rocket_launch</span>
                      <span>PreBot</span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </Col>

          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined dashboard-link-icon"}>integration_instructions</span>
              <span className="dashboard-menu-title">Integrations</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{linkList(INTEGRATION_LINK_CONFIG)}</div>
          </Col>

          {userIsAdmin && (
            <Col lg={4} sm={6} className="menu-column">
              <div className={"heading-container"}>
                <span className={"material-icons-outlined dashboard-link-icon"}>admin_panel_settings</span>
                <span className="dashboard-menu-title">Admin</span>
              </div>

              <hr className={"bg-grey-12"} />

              <div className={"margin-left-1"}>
                {linkList(ADMIN_LINK_CONFIG)}
                {userIsBbotAdmin && !userIsBbotSuperAdmin && linkList(BBOT_ADMIN_ONLY_LINKS)}
                {userIsBbotSuperAdmin &&
                  SUPER_ADMIN_LINK_CONFIG.map((link, index) => (
                    <div key={"dashboard-link-" + index} className="dashboard-link">
                      {link.reactLink ? (
                        <Link to={link.link} className={"super-admin-link"}>
                          <div className={"dashboard-link-container"}>
                            <span className={"material-icons-outlined"}>{link.googleIcon}</span>
                            <span>{link.name}</span>
                          </div>
                        </Link>
                      ) : (
                        <a href={redirectUrlAppendix + link.link + "?from=owner_react_app"} id={link.id}>
                          <Row>
                            <Col sm={1} xs={1}>
                              <div className={link.iconClass} />
                            </Col>
                            <Col>{link.name}</Col>
                          </Row>
                        </a>
                      )}
                    </div>
                  ))}
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <IncompleteJourneyModal
        allowedCustomers={allowedCustomers}
        selectedCustomer={selectedCustomer}
        selectAllowedCustomer={selectAllowedCustomer}
        journeys={journeys}
        userInfo={userInfo}
      />
    </div>
  );
};

export default withRouter(OwnerAppDashboard);
