import React from "react";
import styled from "styled-components";
import { Modal } from "bbot-component-library";

const BbotModal = ({ destroyOnClose = true, ...props }) => {
  return <StyledModal destroyOnClose={destroyOnClose} {...props} />;
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-footer {
      padding: 18px 24px;
      display: flex;
      justify-content: center;
      border: none;

      .ant-btn {
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
        color: black;
        font-family: var(--default-font);
        background: white;
        border-color: lightgrey;

        &:active,
        &:hover,
        &:focus {
          color: black;
          border-color: lightgrey;
          background: var(--color-button-default__background);
          opacity: 0.9;
        }

        &-primary {
          color: white;
          background: var(--color-primary__regular);
          border-color: var(--color-primary__regular);

          &:active,
          &:hover,
          &:focus {
            color: white;
            background: var(--color-primary__dark);
            border-color: var(--color-primary__dark);
            opacity: 0.9;
          }
        }
        &-dangerous {
          color: white;
          background: var(--color-error__regular);
          border-color: var(--color-error__regular);

          &:active,
          &:hover,
          &:focus {
            color: white;
            background: var(--color-error__dark);
            border-color: var(--color-error__dark);
            opacity: 0.9;
          }
        }
      }
    }
  }
  .ant-modal-header {
    border-radius: 8px;
    border: none;
  }
`;

export default BbotModal;
