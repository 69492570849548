import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Col, Form } from "react-bootstrap";

const RadioGroupInput = ({ label, controlId, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  return (
    <fieldset>
      <Form.Group
        as={Col}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        controlId={props.controlId}
        className={props.className ? props.className : "margin-bottom-1 "}
      >
        <Form.Label className={"react-radio-group-input-label"}>{label}</Form.Label>
        {props.options &&
          props.options.map((option, index) => (
            <Form.Check
              disabled={option.disabled != null ? option.disabled : false}
              label={option.label}
              name={props.name + "-radios"}
              value={option.value}
              type="radio"
              key={index}
              checked={option.value === field.value}
              onChange={(event) => {
                const newVal = event.target.checked;
                setValue(newVal);
                if (props.onChange) {
                  //returns the value instead of newVal because newVal is just a boolean and doesn't tell us which radio option
                  //is selected
                  props.onChange(event.target.value);
                }
              }}
            />
          ))}
        <Form.Control.Feedback type="invalid">{meta.error?.replace(props.name, label)}</Form.Control.Feedback>
      </Form.Group>
    </fieldset>
  );
};

RadioGroupInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};
export default RadioGroupInput;
