import { Button, Modal, Form } from "react-bootstrap";
import React, { Component } from "react";

class DisconnectPOSIntegrationModal extends Component {
  state = {
    deleteMenuItems: false,
  };

  render() {
    const { onHide, show, disconnectFunc, onClose, integrationSourceName } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect {integrationSourceName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={"margin-bottom-1"}>
              <p>
                Disconnecting will stop orders from being sent to {integrationSourceName}. If you would also like to
                delete menu items that came from {integrationSourceName}, please check the checkbox below.
              </p>
              <p>If you are not an admin, please contact an admin if you wish to disconnect.</p>
            </div>
            <Form.Group className={"margin-bottom-1 "}>
              <Form.Check
                className={"check-box"}
                label={`Delete existing ${integrationSourceName} menu items`}
                type={"checkbox"}
                onChange={(event) => {
                  this.setState({ deleteMenuItems: event.target.checked });
                }}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size={"sm"}
            variant={"light"}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            size={"sm"}
            variant={"danger"}
            onClick={() => {
              disconnectFunc(this.state.deleteMenuItems);
            }}
          >
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DisconnectPOSIntegrationModal;
