import React, { useEffect, useState } from "react";
import { Breadcrumbs, BbotTabs, Tabs, BbotContainer } from "bbot-component-library";
import BBOT_SERVER from "Config"; // used for antd datepicker
import PastOrdersLookupTab from "./PastOrdersLookupTab";

import axios from "axios";
import { generalErrorAlert } from "util/Utils";

const SearchPastOrdersPage = (props) => {
  const { selectedCustomer, userInfo } = props;

  const [stations, setStations] = useState([]);

  const userIsAdmin = userInfo?.role === "admin";

  const legacyPastOrdersPage = userIsAdmin
    ? BBOT_SERVER + "/superuser/console/#!/receipts"
    : BBOT_SERVER + "/owner/console/#!/receipts";

  const getStations = async () => {
    let endpoint = "/owner/stations";
    try {
      const res = await axios.get(endpoint);
      setStations(res.data.stations);
    } catch (error) {
      generalErrorAlert(error, "Trouble getting stations details. Please refresh the page or contact Bbot Support.");
    }
  };

  useEffect(() => {
    if (!selectedCustomer) return;
    getStations();
  }, [selectedCustomer]);

  return (
    <BbotContainer>
      <Breadcrumbs name={"Past Order Lookup"} link={"/orders"} />
      <div className={"margin-bottom-3"}>
        <h2>
          Search Orders To <span className={"color-primary__dark"}>{selectedCustomer?.customer_name}</span>
        </h2>
      </div>

      <div className={"margin-bottom-3"}>
        <p>
          A start and end date filter is pre-applied. To narrow your search, please add filters using the Add Filter
          button. Updating the filters will automatically refresh the table.
          <br />
          The legacy Past Orders Page is available <a href={legacyPastOrdersPage}>here</a>.
        </p>
      </div>

      <BbotTabs
        id={"single-partner-tabs"}
        defaultActiveKey={"past_order_lookup"}
        tabPosition={"top"}
        onChange={getStations} //refresh stations in case changes to station refund reasons made
      >
        <Tabs.TabPane tab={"Past Order Lookup"} key={"past_order_lookup"}>
          <PastOrdersLookupTab userInfo={userInfo} selectedCustomer={selectedCustomer} stations={stations} />
        </Tabs.TabPane>
        {/*<Tabs.TabPane*/}
        {/*  tab={*/}
        {/*    <span>*/}
        {/*      <span className={"margin-right-1"}>Refund Settings</span>*/}
        {/*      <BbotTag>New</BbotTag>*/}
        {/*    </span>*/}
        {/*  }*/}
        {/*  key={"refund_reasons"}*/}
        {/*>*/}
        {/*  <RefundReasonsConfigTab userInfo={userInfo} selectedCustomer={selectedCustomer} stations={stations} />*/}
        {/*</Tabs.TabPane>*/}
      </BbotTabs>
    </BbotContainer>
  );
};

export default SearchPastOrdersPage;
