import React from "react";

import axios from "axios";
import ReactFilestack from "filestack-react";
import { FILESTACK_API_KEY } from "Constants";
import { STATIC_ROOT } from "Config";
import { ENV } from "Config";

// Modals
import CreatePartnerModal from "components/owner-app/modals/partners/CreatePartnerModal";
import ResetWebhookModal from "components/owner-app/modals/partners/ResetWebhookModal";
import CredentialDisplayModal from "components/owner-app/modals/partners/CredentialDisplayModal";
import CreateCredentialsModal from "components/owner-app/modals/partners/CreateCredentialsModal";
import DeleteCredentialModal from "components/owner-app/modals/partners/DeleteCredentialModal";
import CreateEditConnectPartnerModal from "components/owner-app/modals/partners/CreateEditConnectPartnerModal";
import ConnectPartnerModal from "components/owner-app/modals/partners/ConnectPartnerModal";

//Components
import {
  Form,
  Row,
  Col,
  BbotButton,
  BbotTable,
  BbotTabs,
  Tabs,
  BbotTag,
  BbotAlert,
  Breadcrumbs,
  notification,
  EmailInput,
  TextInput,
  URLInput,
  SelectInput,
  ToggleInput,
} from "bbot-component-library";
import AppStoreCard from "components/owner-app/cards/app-store/AppStoreCard";
import { DeleteOutlined, EditOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";

import { useEffect, useState } from "react";
import { generalErrorAlert } from "util/Utils";

const ManagePartners = (props) => {
  const { selectedCustomer, userInfo } = props;

  const [partners, setPartners] = useState([]);
  const [pageView, setPageView] = useState("");
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [showCreatePartnerModal, setShowCreatePartnerModal] = useState(false);
  const [showResetWebhookModal, setShowResetWebhookModal] = useState(false);
  const [showCredentialDisplayModal, setShowCredentialDisplayModal] = useState(false);
  const [credentialDisplayOneTimeViewing, setCredentialDisplayOneTimeViewing] = useState(true);
  const [showCreateCredentialsModal, setShowCreateCredentialsModal] = useState(false);
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState(false);
  const [showCreateEditConnectPartnerModal, setShowCreateEditConnectPartnerModal] = useState(false);
  const [createEditConnectPartnerModalMode, setCreateEditConnectPartnerModalMode] = useState("create");
  const [showConnectModalPreview, setShowConnectModalPreview] = useState(false);
  const [selectedConnectCardInfo, setSelectedConnectCardInfo] = useState(null);
  const [selectedPartnerCredentials, setSelectedPartnerCredentials] = useState([]);
  const [credentialsForDisplay, setCredentialsForDisplay] = useState([]);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [showAppStoreConfig, setShowAppStoreConfig] = useState(false);

  const userIsBbotAdmin = userInfo?.teams?.includes("bbot");
  const userIsSuperAdmin = userInfo?.permissions?.includes("manage_admins"); // If the user is an owner on the page, they already passed the check on the owner panel
  const customerOwnedPartnerApp =
    (props.location.state?.customerOwnedPartnerApp || userInfo?.role === "Owner") ?? false;

  // Controls whether or not users are able to create, reset, or delete webhook credentials
  const advancedCredentialsVisibility = userInfo?.role === "Owner" || userIsSuperAdmin;

  const [webhookForms] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const [appStoreForm] = Form.useForm();

  const [webhooksFormInitialValues, setWebhooksFormInitialValues] = useState(null);
  const [settingsFormInitialValues, setSettingsFormInitialValues] = useState(null);
  const [appStoreFormInitialValues, setAppStoreFormInitialValues] = useState(null);

  useEffect(() => {
    getAllowedPartners();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Updates values in webhooks form
  useEffect(() => {
    let newInitialValuesWebhooks = {
      restaurant_changed_webhook_url: selectedPartner?.restaurant_changed_webhook_url || "",
      menus_changed_webhook_url: selectedPartner?.menus_changed_webhook_url || "",
      order_status_webhook_url: selectedPartner?.order_status_webhook_url || "",
      promo_webhook_url: selectedPartner?.promo_webhook_url || "",
      promo_redemption_finished_webhook_url: selectedPartner?.promo_redemption_finished_webhook_url || "",
      promo_redemption_cancelled_webhook_url: selectedPartner?.promo_redemption_cancelled_webhook_url || "",
      promo_items_refunded_webhook_url: selectedPartner?.promo_items_refunded_webhook_url || "",
      order_injection_webhook_url: selectedPartner?.order_injection_webhook_url || "",
    };
    setWebhooksFormInitialValues(newInitialValuesWebhooks);
    webhookForms.setFieldsValue(newInitialValuesWebhooks);
  }, [webhookForms, selectedPartner]);

  // Updates values in settings form
  useEffect(() => {
    let newInitialValuesSettings = {
      show_salesforce_id: selectedPartner?.show_salesforce_id || false,
      technical_email: selectedPartner?.technical_email || "",
      customer_service_email: selectedPartner?.customer_service_email || "",
    };
    setSettingsFormInitialValues(newInitialValuesSettings);
    settingsForm.setFieldsValue(newInitialValuesSettings);
  }, [settingsForm, selectedPartner]);

  // Updates values in app store form
  useEffect(() => {
    let newInitialValuesAppStore = {
      card_title: selectedPartner?.card_title || "",
      card_button_text: selectedPartner?.card_button_text || "",
      card_description: selectedPartner?.card_description || "",
      connect_modal_description: selectedPartner?.card_button_text || "",
      documentation_link: selectedPartner?.documentation_link || "",
      category: selectedPartner?.category || "",
    };
    setAppStoreFormInitialValues(newInitialValuesAppStore);
    appStoreForm.setFieldsValue(newInitialValuesAppStore);
  }, [appStoreForm, selectedPartner]);

  const getAllowedPartners = async (updatingPartner = false) => {
    if (!selectedCustomer || !userInfo) return;
    try {
      if (customerOwnedPartnerApp) {
        const res = await axios.get("/owner/getPartnersForAdminOrOwner", {
          params: {
            customerId: selectedCustomer?.customer_id,
            customerOwnedPartnerApp: true,
          },
        });
        await setPartners(res.data.partners);
        setPageView("multiple");
        if (updatingPartner) {
          const partnerToSet = selectedPartner
            ? res.data.partners.find((partner) => partner.short_id === selectedPartner.short_id)
            : null;
          if (partnerToSet) {
            setPageView("single");
            setSelectedPartner(partnerToSet);
            await getPartnerCredentials(partnerToSet);
          }
        }
      } else {
        const res = await axios.get("/owner/getPartnersForAdminOrOwner", {
          params: {
            customerId: selectedCustomer?.customer_id,
            teams: userInfo?.teams,
          },
        });
        await setPartners(res.data.partners);
        setAvailableCategories(res.data.categories);
        if (res.data.partners.length > 1 && !updatingPartner) {
          setPageView("multiple");
        } else {
          const partnerToSet = selectedPartner
            ? res.data.partners.find((partner) => partner.short_id === selectedPartner.short_id)
            : res.data.partners[0];
          setPageView("single");
          setSelectedPartner(partnerToSet);
          await getPartnerCredentials(partnerToSet);
        }
      }
    } catch (error) {
      generalErrorAlert(error, `Trouble retrieving ${customerOwnedPartnerApp ? "apps" : "partners"}.`, true);
    }
  };

  const getPartnerCredentials = async (partner) => {
    const payload = {
      partnerId: partner?.id,
    };
    try {
      const res = await axios.get("/owner/getAllCredentialsForPartner", {
        params: payload,
      });
      setSelectedPartnerCredentials(res.data.partner_credentials);
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving credentials.", true);
    }
  };

  const resetWebhookCredentials = async () => {
    setShowResetWebhookModal(false);

    const payload = {
      partnerId: selectedPartner.id,
      customerId: selectedCustomer?.customer_id,
    };

    try {
      const res = await axios.post("/owner/resetPartnerWebhoookCredentials", payload);
      setCredentialsForDisplay(res.data.credentials);
      setShowCredentialDisplayModal(true);
    } catch (error) {
      generalErrorAlert(error, "Trouble resetting webhook credentials.", true);
    }
  };

  const editPartnerSettings = async (values) => {
    const payload = {
      partnerId: selectedPartner.id,
      propertiesToEdit: values,
    };
    try {
      await axios.post("/owner/editPartnerProperties", payload);
      await getAllowedPartners(true);
      notification.success({
        message: `Successfully updated ${customerOwnedPartnerApp ? "app" : "partner"} settings.`,
      });
    } catch (error) {
      generalErrorAlert(error, `Trouble updating ${customerOwnedPartnerApp ? "app" : "partner"} settings.`, true);
    }
  };

  const uploadLogo = async (imageFile) => {
    const payload = {
      partnerId: selectedPartner.id,
      filestackImage: imageFile,
    };

    try {
      await axios.post("/owner/uploadPartnerLogo", payload);
      notification.success({ message: "Successfully updated logo." });
      await getAllowedPartners(true);
    } catch (error) {
      generalErrorAlert(error, "Trouble uploading logo.", true);
    }
  };

  const deletePartnerConnectInfo = async (selectedInfo) => {
    const displayOrderOfDeleted = selectedInfo.display_order;
    const connectPartnerInfo = selectedPartner.connect_partner_info;

    delete connectPartnerInfo[selectedInfo.key];

    // Destructively update all display_orders
    Object.values(connectPartnerInfo).forEach((connectInfo) => {
      if (connectInfo.display_order > displayOrderOfDeleted) {
        connectInfo.display_order -= 1;
      }
    });

    const payload = {
      partnerId: selectedPartner.id,
      propertiesToEdit: { connect_partner_info: connectPartnerInfo },
    };

    try {
      await axios.post("/owner/editPartnerProperties", payload);
      notification.success({
        message: "Successfully deleted sign up requirement.",
      });
      await getAllowedPartners(true);
    } catch (error) {
      generalErrorAlert(error, "Trouble deleting sign up requirement.", true);
    }
  };

  const canShowOnBbotAppStore = (partner) => {
    return (
      partner.card_title &&
      partner.card_description &&
      partner.card_button_text &&
      partner.category &&
      partner.card_image_url
    );
  };

  const multiplePartnersView = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="margin-bottom-4">
              {customerOwnedPartnerApp ? <h2>Manage Customer Owned Apps</h2> : <h2>Manage Partners</h2>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BbotTable
              id={"multiple-partners-table"}
              columns={customerOwnedPartnerApp ? ["Apps"] : ["API Partners"]}
              data={
                customerOwnedPartnerApp
                  ? partners.map((partner, index) => ({
                      key: index,
                      apps: partner.display_name,
                      partner: partner,
                    }))
                  : partners.map((partner, index) => ({
                      key: index,
                      api_partners: partner.display_name,
                      partner: partner,
                    }))
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setPageView("single");
                    setSelectedPartner(record.partner);
                    getPartnerCredentials(record.partner);
                  },
                };
              }}
              interactive={customerOwnedPartnerApp || userIsBbotAdmin}
              addButtonText={customerOwnedPartnerApp ? "Create New App" : "Create New API Partner"}
              onAdd={() => setShowCreatePartnerModal(true)}
              showAddButton={customerOwnedPartnerApp || userIsBbotAdmin}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const singlePartnerView = () => {
    const userCanPublishToAppStore = userIsBbotAdmin || ENV !== "prod";

    if (!selectedPartner) {
      return;
    }
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="margin-bottom-4">
              <h2>
                {(customerOwnedPartnerApp || partners.length > 1) && (
                  <BackArrow className="zmdi zmdi-arrow-left" onClick={() => setPageView("multiple")} />
                )}{" "}
                Manage <span className={"color-primary__dark"}>{selectedPartner.display_name}</span>
              </h2>
            </div>
          </Col>
        </Row>
        <BbotTabs id={"single-partner-tabs"} defaultActiveKey={"credentials"} tabPosition={"top"}>
          <Tabs.TabPane tab={"Credentials"} key={"credentials"}>
            <div>
              <Row>
                <Col span={24}>
                  <div className={"margin-bottom-2"}>
                    {customerOwnedPartnerApp ? (
                      <h4>App and Webhook Credentials</h4>
                    ) : (
                      <h4>Partner and Webhook Credentials</h4>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <BbotTable
                    id={"partner-credentials-table"}
                    title={<>All Credentials for {selectedPartner.display_name}</>}
                    columns={
                      advancedCredentialsVisibility
                        ? ["Credential Name", "User", "Actions"]
                        : ["Credential Name", "User"]
                    }
                    data={selectedPartnerCredentials.map((credential, index) => ({
                      key: credential.id,
                      credential_name: credential.display_name || "No display name given for credential",
                      user: credential.user.username,
                      actions: (
                        <TableActions>
                          <DeleteIcon
                            onClick={() => {
                              setSelectedCredential(credential);
                              setShowDeleteCredentialModal(true);
                            }}
                          />
                        </TableActions>
                      ),
                    }))}
                    interactive={true}
                    onAdd={() => {
                      if (advancedCredentialsVisibility) {
                        setShowCreateCredentialsModal(true);
                      } else {
                        notification.warning({ message: "Only owners and super admins can generate new credentials." });
                      }
                    }}
                    showAddButton={true}
                    addButtonText={
                      customerOwnedPartnerApp ? "Generate Credentials For App" : "Generate Credentials For API Partner"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className={"margin-top-4"}>
                    <BbotButton
                      type={"primary"}
                      onClick={() => {
                        setShowCredentialDisplayModal(true);
                        setCredentialDisplayOneTimeViewing(false);
                        setCredentialsForDisplay([
                          {
                            display_name: "API Client Key",
                            value: selectedPartner.api_client_key,
                          },
                          {
                            display_name: "API Secret Key",
                            value: selectedPartner.api_secret_key,
                          },
                        ]);
                      }}
                    >
                      View Webhook Credentials
                    </BbotButton>
                    <span>
                      <BbotButton
                        type={"primary"}
                        className={"margin-left-2 margin-right-1"}
                        onClick={() => setShowResetWebhookModal(true)}
                        disabled={!advancedCredentialsVisibility}
                      >
                        Reset Webhook Credentials
                      </BbotButton>
                      {!advancedCredentialsVisibility && (
                        <BbotTag color={"warning"}>Only owners and super admins can reset webhook credentials</BbotTag>
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className={"margin-top-4"}>
                <Col span={24}>
                  <Form
                    name={"webhooks"}
                    form={webhookForms}
                    initialValues={webhooksFormInitialValues}
                    onFinish={editPartnerSettings}
                    layout={"vertical"}
                  >
                    <URLInput
                      id={"restaurant-changed-webhook-url"}
                      label={"Restaurant Changed Webhook URL"}
                      name={"restaurant_changed_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"menus-changed-webhook-url"}
                      label={"Menus Changed Webhook URL"}
                      name={"menus_changed_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"order-status-webhook-url"}
                      label={"Order Status Webhook URL"}
                      name={"order_status_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"promo-webhook-url"}
                      label={"Promo Webhook URL"}
                      name={"promo_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"promo-redemption-finished-webhook-url"}
                      label={"Checkout Complete Webhook URL"}
                      name={"promo_redemption_finished_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"promo-redemption-cancelled-webhook-url"}
                      label={"Checkout Cancelled Webhook URL"}
                      name={"promo_redemption_cancelled_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"promo-items-refunded-webhook-url"}
                      label={"Items Refunded Webhook URL"}
                      name={"promo_items_refunded_webhook_url"}
                      required={false}
                    />
                    <URLInput
                      id={"order-injection-webhook-url"}
                      label={"Order Injection Webhook URL"}
                      name={"order_injection_webhook_url"}
                      required={false}
                    />
                    <Form.Item>
                      <BbotButton type={"primary"} htmlType={"submit"}>
                        Save
                      </BbotButton>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Settings"} key={"settings"}>
            <div>
              <Row>
                <Col span={24}>
                  <div className={"margin-bottom-2"}>
                    {customerOwnedPartnerApp ? <h4>Edit App Settings</h4> : <h4>Edit Partner Settings</h4>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form
                    name={"settings"}
                    onFinish={editPartnerSettings}
                    form={settingsForm}
                    initialValues={settingsFormInitialValues}
                    layout={"vertical"}
                  >
                    {userIsBbotAdmin && (
                      <ToggleInput
                        id={"show-salesforce-id"}
                        label={"Show Salesforce ID field when starting Customer Journey."}
                        name={"show_salesforce_id"}
                      />
                    )}
                    <EmailInput
                      id={"technical-email"}
                      label={"Technical Email"}
                      name={"technical_email"}
                      required={false}
                    />
                    <EmailInput
                      id={"customer-service-email"}
                      label={"Customer Service Email"}
                      name={"customer_service_email"}
                      required={false}
                    />
                    <Form.Item>
                      <BbotButton htmlType={"submit"} type={"primary"}>
                        Save
                      </BbotButton>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </Tabs.TabPane>
          {(userIsBbotAdmin || userIsSuperAdmin || customerOwnedPartnerApp) && (
            <Tabs.TabPane tab={"Bbot App Store"} key={"app-store"}>
              {selectedPartner.show_on_app_store || showAppStoreConfig ? (
                <div>
                  {selectedPartner.show_on_app_store ? (
                    <div className={"margin-bottom-2 margin-top-1"}>
                      <BbotAlert
                        showIcon={true}
                        message={"Your App Store Card and Modal are currently live on the Bbot App Store."}
                        type={"success"}
                      />
                    </div>
                  ) : (
                    <div className={"margin-bottom-2 margin-top-1"}>
                      <BbotAlert
                        showIcon={true}
                        message={
                          "Your App Store Card and Modal are currently in draft. They will go live when you click 'Show on Bbot App Store'. This requires you to have a 'Card and Modal Title', 'Card Description', 'Connect Button Text', 'Category', and logo."
                        }
                        type={"warning"}
                      />
                    </div>
                  )}
                  {!userCanPublishToAppStore && (
                    <div className={"margin-bottom-2 margin-top-1"}>
                      <BbotAlert
                        showIcon={true}
                        message={
                          "You are not permitted to publish your Card and Modal on the Bbot App Store. Please contact a Bbot representative to make this request."
                        }
                        type={"warning"}
                      />
                    </div>
                  )}
                  <Row>
                    <Col span={24}>
                      <div className={"margin-bottom-1"}>
                        <h4>Upload Logo</h4>
                      </div>
                      <div className={"margin-bottom-2"}>
                        <p>This logo will appear on your "Connect Card" on Bbot's Integrations Page.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={"margin-bottom-4"}>
                    <Col span={24}>
                      <ReactFilestack
                        apikey={FILESTACK_API_KEY}
                        actionOptions={{
                          accept: ["image/svg+xml", "image/png", "image/jpeg"],
                        }}
                        customRender={({ onPick }) => (
                          <BbotButton onClick={onPick} variant={"outline-primary"}>
                            <CloudUploadOutlined />
                            Upload Card Logo
                          </BbotButton>
                        )}
                        onSuccess={(res) => uploadLogo(res.filesUploaded[0])}
                        onError={(err) => {
                          console.error(err);
                          notification.error({
                            message: "Trouble uploading image. Please refresh and try again.",
                          });
                        }}
                      />
                      {selectedPartner.card_image_url && (
                        <img
                          className={"margin-left-8"}
                          src={selectedPartner.card_image_url}
                          alt={STATIC_ROOT}
                          width={100}
                          height={100}
                          style={{ objectFit: "contain" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row className={"margin-top-4"}>
                    <Col>
                      <div className={"margin-bottom-1"}>
                        <h4>Customize Bbot App Store Card and Modal</h4>
                      </div>
                      <div className={"margin-bottom-2"}>
                        <p>
                          This allows you customize your card in the Bbot App Store. It also allows you to add and
                          customize the inputs you want to appear for customers when they are connecting to your
                          service. This usually includes things like API Connection Keys.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className={"margin-bottom-4"}>
                    <Form
                      name={"app-store-settings"}
                      onFinish={editPartnerSettings}
                      form={appStoreForm}
                      initialValues={appStoreFormInitialValues}
                      layout={"vertical"}
                    >
                      <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                          <TextInput
                            id={"card-title"}
                            label={"Card and Modal Title"}
                            name={"card_title"}
                            required={false}
                            maxLength={50}
                          />
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 11, offset: 2 }}
                          lg={{ span: 11, offset: 2 }}
                        >
                          <TextInput
                            id={"card-button-text"}
                            label={"Card Button Text"}
                            name={"card_button_text"}
                            required={false}
                            maxLength={20}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                          <TextInput
                            id={"card-description"}
                            label={"Card Description"}
                            name={"card_description"}
                            required={false}
                            maxLength={255}
                          />
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 11, offset: 2 }}
                          lg={{ span: 11, offset: 2 }}
                        >
                          <TextInput
                            id={"connect-modal-description"}
                            label={"Modal Description"}
                            name={"connect_modal_description"}
                            required={false}
                            maxLength={255}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <URLInput
                            id={"documentation-link"}
                            label={"Link to Documentation"}
                            name={"documentation_link"}
                            required={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <SelectInput
                            id={"category"}
                            label={"Category"}
                            name={"category"}
                            options={availableCategories.map((category, index) => ({
                              value: category,
                              label: category,
                            }))}
                            required={false}
                          />
                        </Col>
                      </Row>
                      <Row className={"margin-bottom-2"}>
                        <p>
                          <strong>Pay at the table:</strong> White-label application using your front-end to allow
                          guests to order while in-venue.
                        </p>
                        <p>
                          <strong>Delivery:</strong> Bbot orders will be injected into your delivery service for
                          fulfillment to the end-user.
                        </p>
                        <p>
                          <strong>POS Integrations:</strong> Sync a POS menu into Bbot and/or inject Bbot orders into
                          the POS.
                        </p>
                        <p>
                          <strong>Feedback and Intelligence:</strong> Enables guest feedback, analysis of guest
                          behavior, and exporting of consumer data.
                        </p>
                        <p>
                          <strong>Rewards and Loyalty:</strong> Apply discounts and rewards to patron orders.
                        </p>
                      </Row>
                      <Form.Item className={"margin-bottom-4"}>
                        <BbotButton htmlType={"submit"} type={"primary"}>
                          Save
                        </BbotButton>
                      </Form.Item>
                    </Form>
                    <hr />
                    <div className={"margin-bottom-1 margin-top-4"}>
                      <h4>App Store Card and Modal Preview</h4>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <p>
                        If you want to preview your App Store Modal, click the connect button in the lower right hand
                        corner of the card below. This will pop the modal.
                      </p>
                    </div>
                    <Row className={"margin-bottom-4"}>
                      <Col span={8} offset={8}>
                        <AppStoreCard
                          id={"preview-card"}
                          title={appStoreForm.getFieldValue("card_title") || "Card and Modal Title"}
                          description={appStoreForm.getFieldValue("card_description") || "Card Description"}
                          logo={selectedPartner?.card_image_url || STATIC_ROOT + "img/bbot_logo.png"}
                          connected={false}
                          connectButtonText={appStoreForm.getFieldValue("card_button_text") || "Connect"}
                          disconnectAction={null}
                          connectAction={() => setShowConnectModalPreview(true)}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row className={"margin-top-4 margin-bottom-4"}>
                      <Col span={24}>
                        {Object.keys(selectedPartner.connect_partner_info).length === 0 && (
                          <div className={"margin-bottom-2"}>
                            <BbotAlert
                              showIcon={true}
                              message={
                                "Your App Store modal currently has no sign up requirements. This means customers can connect to your service without providing any information."
                              }
                              type={"warning"}
                            />
                          </div>
                        )}
                        <BbotTable
                          id={"app-store-modal-table"}
                          title={"Configure Sign-Up Requirements"}
                          columns={["Label", "Type", "Actions"]}
                          data={Object.values(selectedPartner.connect_partner_info)
                            .sort((a, b) => a.display_order - b.display_order)
                            .map((connectInfo, index) => ({
                              key: index,
                              label: connectInfo.label,
                              type: connectInfo.type[0].toUpperCase() + connectInfo.type.slice(1),
                              actions: (
                                <TableActions>
                                  <DeleteIcon onClick={() => deletePartnerConnectInfo(connectInfo)} />
                                  <EditIcon
                                    onClick={async () => {
                                      await setSelectedConnectCardInfo(connectInfo);
                                      await setCreateEditConnectPartnerModalMode("edit");
                                      setShowCreateEditConnectPartnerModal(true);
                                    }}
                                  />
                                </TableActions>
                              ),
                            }))}
                          addButtonText={"Add an Input Field"}
                          onAdd={() => {
                            setShowCreateEditConnectPartnerModal(true);
                            setCreateEditConnectPartnerModalMode("create");
                          }}
                          interactive={true}
                        />
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    {!selectedPartner.show_on_app_store ? (
                      <BbotButton
                        size={"lg"}
                        block
                        type={"primary"}
                        disabled={!canShowOnBbotAppStore(selectedPartner) || !userCanPublishToAppStore}
                        className={"margin-top-2 margin-left-1 margin-right-1"}
                        onClick={() => editPartnerSettings({ show_on_app_store: true })}
                      >
                        Show on Bbot App Store
                      </BbotButton>
                    ) : (
                      <BbotButton
                        size={"lg"}
                        block
                        type={"primary"}
                        danger
                        className={"margin-top-4 margin-left-1 margin-right-1"}
                        onClick={() => {
                          setShowAppStoreConfig(false);
                          editPartnerSettings({ show_on_app_store: false });
                        }}
                      >
                        Remove from Bbot App Store
                      </BbotButton>
                    )}
                  </Row>
                </div>
              ) : (
                <div>
                  <Row>
                    <Col>
                      <div className={"margin-bottom-1"}>
                        <h4>Configure for Bbot App Store</h4>
                      </div>
                      <div className={"margin-bottom-2"}>
                        <p>
                          Bbot's App Store is a place where customers can connect to your service. If you choose to
                          appear on our App Store, you can customize an "App Card" and an "App Modal" that customers
                          will be able to see and use to connect to {selectedPartner.display_name}.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <BbotButton size={"lg"} block type={"primary"} onClick={() => setShowAppStoreConfig(true)}>
                      Configure for Bbot App Store
                    </BbotButton>
                  </div>
                </div>
              )}
            </Tabs.TabPane>
          )}
        </BbotTabs>
      </div>
    );
  };
  return (
    <div>
      <div className={"margin-x-5"}>
        {customerOwnedPartnerApp ? (
          <Breadcrumbs name={"Bbot App Store"} link={"/bbot-app-store"} />
        ) : (
          <Breadcrumbs name={"Manage Partners"} link={"/manage-partners"} />
        )}
        {pageView === "multiple" ? multiplePartnersView() : singlePartnerView()}
      </div>

      <CreatePartnerModal
        selectedCustomerID={selectedCustomer?.customer_id}
        customerOwnedPartnerApp={customerOwnedPartnerApp}
        show={showCreatePartnerModal}
        onClose={() => setShowCreatePartnerModal(false)}
        onSave={getAllowedPartners}
      />

      <ResetWebhookModal
        show={showResetWebhookModal}
        onCancel={() => setShowResetWebhookModal(false)}
        onConfirm={resetWebhookCredentials}
      />

      <CredentialDisplayModal
        show={showCredentialDisplayModal}
        credentials={credentialsForDisplay}
        credentialDisplayOneTimeViewing={credentialDisplayOneTimeViewing}
        onClose={() => {
          if (credentialDisplayOneTimeViewing) {
            getAllowedPartners(true).then(() => {
              getPartnerCredentials(selectedPartner);
            });
          }
          setShowCredentialDisplayModal(false);
          setCredentialsForDisplay([]);
          setCredentialDisplayOneTimeViewing(true);
        }}
      />
      <CreateCredentialsModal
        show={showCreateCredentialsModal}
        onClose={() => setShowCreateCredentialsModal(false)}
        onSave={(credentials) => {
          setShowCredentialDisplayModal(true);
          setCredentialsForDisplay(credentials);
        }}
        customerOwnedPartnerApp={customerOwnedPartnerApp}
        selectedPartner={selectedPartner}
        selectedCustomer={selectedCustomer}
      />
      <DeleteCredentialModal
        show={showDeleteCredentialModal}
        onClose={() => {
          setShowDeleteCredentialModal(false);
          getPartnerCredentials(selectedPartner);
        }}
        selectedCustomer={selectedCustomer}
        selectedPartner={selectedPartner}
        selectedCredential={selectedCredential}
      />
      <CreateEditConnectPartnerModal
        show={showCreateEditConnectPartnerModal}
        mode={createEditConnectPartnerModalMode}
        onClose={() => setShowCreateEditConnectPartnerModal(false)}
        onSave={() => getAllowedPartners(true)}
        selectedPartner={selectedPartner}
        selectedInfo={selectedConnectCardInfo}
      />
      <ConnectPartnerModal
        show={showConnectModalPreview}
        onClose={() => setShowConnectModalPreview(false)}
        onSave={() => setShowConnectModalPreview(false)}
        selectedPartner={selectedPartner}
        selectedCustomer={selectedCustomer}
        connectedPartners={[]}
        preview={true}
      />
    </div>
  );
};

const BackArrow = styled.i`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const DeleteIcon = styled(DeleteOutlined)`
  font-size: 20px;
`;

const EditIcon = styled(EditOutlined)`
  font-size: 20px;
  margin-left: 25%;
`;

const TableActions = styled.div`
  display: flex;
  justify-content: center;
`;

export default ManagePartners;
