import React, { useState, useEffect } from "react";

import "./Scheduler.scss";
import {
  cleanTextScheduleInput,
  militaryTimeToTimeString,
  parseTimeRangeList,
  validateScheduleInputs,
} from "util/Utils";

const DAYS_IN_WEEK = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const DAYS_IN_WEEK_ABBRV = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const CalendarPicker = (props) => {
  let timeBlockStartTimes = [];
  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(i / 2) % 12;
    hour = hour === 0 ? 12 : hour;
    let minute = i % 2 === 0 ? "00" : "30";
    let meridian = i < 24 ? "AM" : "PM";
    timeBlockStartTimes.push(hour + ":" + minute + " " + meridian);
  }

  // SCHEDULER STATE
  const [schedule, setSchedule] = useState(
    timeBlockStartTimes.map(() => [false, false, false, false, false, false, false])
  );
  const [selecting, setSelecting] = useState(false); // if the mouse is currently pressed down
  const [selectingDayIndex, setSelectingDayIndex] = useState(null);
  const [selectingStart, setSelectingStart] = useState(null);
  const [selectingEnd, setSelectingEnd] = useState(null);
  const [settingTimeBlocks, setSettingTimeBlocks] = useState(true); // if setting or clearing time blocks

  const [showWeekView, setShowWeekView] = useState(false);

  // INPUT SCHEDULE STATE
  const [scheduleIntervals, setScheduleIntervals] = useState([[], [], [], [], [], [], []]);
  const [sundaySchedule, setSundaySchedule] = useState("");
  const [mondaySchedule, setMondaySchedule] = useState("");
  const [tuesdaySchedule, setTuesdaySchedule] = useState("");
  const [wednesdaySchedule, setWednesdaySchedule] = useState("");
  const [thursdaySchedule, setThursdaySchedule] = useState("");
  const [fridaySchedule, setFridaySchedule] = useState("");
  const [saturdaySchedule, setSaturdaySchedule] = useState("");

  const { onChangeCallback } = props;

  const textSchedules = [
    sundaySchedule,
    mondaySchedule,
    tuesdaySchedule,
    wednesdaySchedule,
    thursdaySchedule,
    fridaySchedule,
    saturdaySchedule,
  ];

  const setTextScheduleFuncs = [
    setSundaySchedule,
    setMondaySchedule,
    setTuesdaySchedule,
    setWednesdaySchedule,
    setThursdaySchedule,
    setFridaySchedule,
    setSaturdaySchedule,
  ];

  useEffect(() => {
    if (!props.initialSchedule) return;

    // update schedule from time ranges
    for (let i = 0; i < DAYS_IN_WEEK.length; i++) {
      const dayInWeek = DAYS_IN_WEEK[i];
      updateScheduleFromTimeRanges(props.initialSchedule[dayInWeek].military_times, i);
    }
  }, [props.initialSchedule]); // eslint-disable-line react-hooks/exhaustive-deps

  const timeBlockIndexToMilitaryTime = (timeBlockIndex, minuteBefore = false) => {
    if (!minuteBefore) {
      const hour = Math.floor(timeBlockIndex / 2);
      const minute = timeBlockIndex % 2 === 0 ? 0 : 30;
      return hour * 100 + minute;
    } else {
      const hour = Math.floor(timeBlockIndex / 2) - (timeBlockIndex % 2 === 0 ? 1 : 0);
      const minute = timeBlockIndex % 2 === 0 ? 59 : 29;
      return hour * 100 + minute;
    }
  };

  const militaryTimeToTimeBlockIndex = (militaryTime, considerMinuteBefore = false) => {
    const hour = Math.floor(militaryTime / 100);
    const minute = militaryTime % 100;

    if (considerMinuteBefore) {
      if (minute === 59) return hour * 2 + 2;
      if (minute >= 29) return hour * 2 + 1;
      return hour * 2;
    } else {
      if (minute >= 30) return hour * 2 + 1;
      return hour * 2;
    }
  };

  const saveSelection = () => {
    if (!selecting) return;

    let sched = schedule;
    if (selectingStart <= selectingEnd) {
      for (let r = selectingStart; r <= selectingEnd; r++) {
        sched[r][selectingDayIndex] = settingTimeBlocks;
      }
    } else {
      for (let r = selectingEnd; r <= selectingStart; r++) {
        sched[r][selectingDayIndex] = settingTimeBlocks;
      }
    }

    calculateTextInput(sched);

    setSchedule(sched);
  };

  const shortenTimeString = (timeString) => {
    if (!timeString) return "";
    return timeString.replace(" ", "");
  };

  const calculateTextInput = (newSchedule) => {
    let schedIntervals = scheduleIntervals; // will be updated throughout the function

    const dayTimeBlocks = newSchedule.map((week) => week[selectingDayIndex]);
    let intervals = [];
    let currentInterval = [];
    let inInterval = false;
    for (let i = 0; i < dayTimeBlocks.length; i++) {
      if (!inInterval && dayTimeBlocks[i]) {
        inInterval = true;
        currentInterval.push(timeBlockIndexToMilitaryTime(i));
      } else if (inInterval && !dayTimeBlocks[i]) {
        currentInterval.push(timeBlockIndexToMilitaryTime(i, true));
        intervals.push(currentInterval);
        inInterval = false;
        currentInterval = [];
      }
    }

    // figure out the overlap into the next day
    if (inInterval) {
      currentInterval.push(2400);
      intervals.push(currentInterval);
    }

    // update text schedule
    schedIntervals[selectingDayIndex] = intervals;
    setScheduleIntervals(schedIntervals);
    const intervalsString = intervals
      .map(
        (interval) =>
          shortenTimeString(militaryTimeToTimeString(interval[0])) +
          "-" +
          shortenTimeString(militaryTimeToTimeString(interval[1]))
      )
      .join(", ");
    setTextScheduleFuncs[selectingDayIndex](intervalsString);

    // make new textSchedules object
    const updatedTextSchedules = textSchedules.map((testScheduleDay, i) =>
      i === selectingDayIndex ? intervalsString : testScheduleDay
    );
    onChangeCallback(updatedTextSchedules, schedIntervals);
  };

  const getTableCells = (timeRowIndex) => {
    let tableCells = [];
    for (let i = 0; i < 7; i++) {
      tableCells.push(
        <td
          key={timeRowIndex * 100 + i}
          onMouseDown={() => {
            setSelecting(true);
            setSelectingDayIndex(i);
            setSelectingStart(timeRowIndex);
            setSelectingEnd(timeRowIndex);
            setSettingTimeBlocks(!schedule[timeRowIndex][i]);
          }}
          onMouseUp={() => {
            saveSelection();
            setSelecting(false);
            setSelectingDayIndex(null);
            setSelectingStart(null);
            setSelectingEnd(null);
          }}
          onMouseEnter={() => {
            if (selecting && i === selectingDayIndex) {
              setSelectingEnd(timeRowIndex);
            }
          }}
          className={
            (schedule[timeRowIndex][i] ||
              (selecting &&
                settingTimeBlocks &&
                i === selectingDayIndex &&
                ((selectingStart <= selectingEnd && timeRowIndex >= selectingStart && timeRowIndex <= selectingEnd) ||
                  (selectingStart > selectingEnd &&
                    timeRowIndex <= selectingStart &&
                    timeRowIndex >= selectingEnd)))) &&
            !(
              selecting &&
              !settingTimeBlocks &&
              i === selectingDayIndex &&
              ((selectingStart <= selectingEnd && timeRowIndex >= selectingStart && timeRowIndex <= selectingEnd) ||
                (selectingStart > selectingEnd && timeRowIndex <= selectingStart && timeRowIndex >= selectingEnd))
            )
              ? "selected"
              : ""
          }
        />
      );
    }
    return tableCells;
  };

  const getTextAreaInputs = () => {
    let textAreaInputs = [];
    for (let i = 0; i < 7; i++) {
      textAreaInputs.push(
        <th className={"text-schedule-input"} key={i}>
          <div className={"padding-y-1"}>{DAYS_IN_WEEK_ABBRV[i]}</div>
          <hr className={"margin-y-0"} />
          <div>
            <textarea
              rows={2}
              value={textSchedules[i]}
              onChange={(e) => {
                setTextScheduleFuncs[i](e.target.value);
                // validate that the ranges are correct
                const validationError = validateScheduleInputs(e.target.value, textSchedules[(i + 1) % 7]);
                if (!validationError) {
                  // set the correct time blocks in the schedule state
                  updateScheduleFromText(e.target.value, i);
                } else {
                  // set the error
                  console.error("there has been an error with the input");
                }
              }}
            />
          </div>
        </th>
      );
    }
    return textAreaInputs;
  };

  const generateIntervalsStringFromTimeRanges = (intervals) => {
    const intervalStrings = intervals.map(
      (interval) => militaryTimeToTimeString(interval[0]) + "-" + militaryTimeToTimeString(interval[1])
    );
    return intervalStrings.join(", ");
  };

  /**
   * Takes in military time ranges and updates the schedule data structure.
   * If intervalsString not provided, also generates the intervalsString and saves it.
   * @param militaryTimeRanges An array of arrays(2) that represent the military time ranges for one day (sunday, monday, etc.)
   * @param dayIndex The index of the day with respect to the week. 0 = sunday, 1 = monday, etc...
   * @param intervalsString (Optional) The string user input text that represents the time range
   */
  const updateScheduleFromTimeRanges = (militaryTimeRanges, dayIndex, intervalsString = null) => {
    // update the intervals
    let schedIntervals = scheduleIntervals;
    schedIntervals[dayIndex] = militaryTimeRanges;
    setScheduleIntervals(schedIntervals);

    // update the schedule
    let timeBlockIndexes = [];
    for (let i = 0; i < militaryTimeRanges.length; i++) {
      const militaryTimeRangeStart = militaryTimeToTimeBlockIndex(militaryTimeRanges[i][0]);
      const militaryTimeRangeEnd = militaryTimeToTimeBlockIndex(militaryTimeRanges[i][1], true);
      for (let j = militaryTimeRangeStart; j < militaryTimeRangeEnd; j++) {
        timeBlockIndexes.push(j);
      }
    }

    let sched = schedule;
    for (let timeBlockIndex = 0; timeBlockIndex < timeBlockStartTimes.length; timeBlockIndex++) {
      sched[timeBlockIndex][dayIndex] = timeBlockIndexes.includes(timeBlockIndex);
    }
    setSchedule(sched);

    // If intervalsString is not provided, calling on component mount, which means we should set the text state as well.
    if (!intervalsString) {
      setTextScheduleFuncs[dayIndex](generateIntervalsStringFromTimeRanges(militaryTimeRanges));
    } else {
      const updatedTextSchedules = textSchedules.map((testScheduleDay, i) =>
        i === dayIndex ? intervalsString : testScheduleDay
      );
      onChangeCallback(updatedTextSchedules, schedIntervals);
    }
  };

  const updateScheduleFromText = (intervalsString, dayIndex) => {
    // takes in intervals in text, updates the intervals and the schedule state
    // at this point, the intervals should have already been validated
    const timeBlocks = intervalsString.length
      ? parseTimeRangeList(cleanTextScheduleInput(intervalsString).split(","))
      : [];
    updateScheduleFromTimeRanges(timeBlocks, dayIndex, intervalsString);
  };

  return (
    <div>
      <div className={`scheduler ${showWeekView ? "show-week-view" : ""}`}>
        <table
          className="calendar"
          onMouseLeave={() => {
            saveSelection();
            setSelecting(false);
            setSelectingDayIndex(null);
            setSelectingStart(null);
            setSelectingEnd(null);
          }}
        >
          <thead
            onMouseEnter={() => {
              if (selecting) {
                saveSelection();
                setSelecting(false);
                setSelectingDayIndex(null);
                setSelectingStart(null);
                setSelectingEnd(null);
              }
            }}
          >
            <tr>
              <th className={"no-border"} />
              {getTextAreaInputs()}
            </tr>
          </thead>
          {showWeekView && (
            <tbody>
              {timeBlockStartTimes.map((startTime, i) => (
                <tr key={i}>
                  <td className={"time-label"}>{i % 2 === 0 ? startTime : ""}</td>
                  {getTableCells(i)}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <div className={"show-week-view-btn"} onClick={() => setShowWeekView(!showWeekView)}>
        {showWeekView ? <span>Hide week view</span> : <span>Show week view</span>}
        <i className={`zmdi ${showWeekView ? "zmdi-chevron-up" : "zmdi-chevron-down"} zmdi-hc-2x margin-x-1`} />
      </div>
    </div>
  );
};

export default CalendarPicker;
