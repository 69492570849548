import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckboxInput from "../../../../../src/components/global/form/CheckboxInput";
import { SelectInput } from "../../../../../src/components/global/form";
import DisplayExtraCheckoutInfoOnTicketsFields from "../../../../../src/components/global/form/pos-integrations/DisplayExtraCheckoutInfoOnTicketsFields";
import { TextInput } from "../../../../../src/components/global/form";

const DeliverectIntegrationModalInputs = (props) => {
  let { values } = props;

  return (
    <>
      <Form.Row>
        <SelectInput name={"integrationType"} id={"integration-type"} sm={12} label={"Integration Type"}>
          <option key={"bbotAsPOS"} value={"bbotAsPOS"}>
            Bbot as POS
          </option>
          <option key={"bbotAsOrderingSite"} value={"bbotAsOrderingSite"}>
            Bbot as Ordering Site
          </option>
        </SelectInput>
      </Form.Row>
      <Form.Row>
        <TextInput name={"channelLinkId"} id={"channelLinkId"} label={"Deliverect Channel Link ID"} />
      </Form.Row>
      <Form.Row>
        <TextInput name={"locationId"} id={"locationId"} label={"Deliverect Location ID"} />
      </Form.Row>
      {values.integrationType === "bbotAsOrderingSite" ? (
        <>
          <Form.Row>
            <SelectInput
              name={"taxTypeToUse"}
              id={"tax_type_to_use"}
              label={[
                "Tax Type",
                "  ",
                <OverlayTrigger
                  key={"overlay-tax-type"}
                  placement={"right"}
                  overlay={
                    <Tooltip id={"tooltip-tax-type"}>
                      Deliverect has different tax types for each fulfillment type. Only one tax type will be imported
                      for your menu. Which tax type would you like to import?
                    </Tooltip>
                  }
                >
                  <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                </OverlayTrigger>,
              ]}
            >
              <option key={"deliveryTax"} value={"deliveryTax"}>
                Delivery Tax
              </option>
              <option key={"takeawayTax"} value={"takeawayTax"}>
                Takeaway Tax
              </option>
              <option key={"eatInTax"} value={"eatInTax"}>
                Eat In Tax
              </option>
            </SelectInput>
          </Form.Row>
          <Form.Row>
            <CheckboxInput
              label={[
                "Automatically mark orders Accepted by Staff in Bbot, once they're injected into Deliverect",
                "  ",
                <OverlayTrigger
                  key={"overlay-auto-accept-orders"}
                  placement={"right"}
                  overlay={
                    <Tooltip id={"tooltip-auto-accept-orders"}>
                      Bbot orders injected into Deliverect will be set to "Accepted by Staff" in Bbot for all applicable
                      Fulfillment Methods (which excludes Catering and no-feedback methods)
                    </Tooltip>
                  }
                >
                  <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                </OverlayTrigger>,
              ]}
              name={"autoAcceptOrdersInBbot"}
              id={"auto-accept-orders-in-bbot"}
            />
          </Form.Row>
          <Form.Row>
            <CheckboxInput
              label={[
                "Sync order statuses from Deliverect",
                "  ",
                <OverlayTrigger
                  key={"overlay-sync-fulfillment-status"}
                  placement={"right"}
                  overlay={
                    <Tooltip id={"tooltip-sync-fulfillment-status"}>
                      When order fulfillment statuses are updated in Deliverect, should the fulfillment statuses
                      automatically be updated in Bbot?
                    </Tooltip>
                  }
                >
                  <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                </OverlayTrigger>,
              ]}
              name={"syncFulfillmentStatusFromDeliverect"}
              id={"sync-fulfillment-status-from-deliverect"}
            />
          </Form.Row>
          <Form.Row>
            <CheckboxInput
              label={[
                "Allow ordering to be paused from Deliverect",
                "  ",
                <OverlayTrigger
                  key={"overlay-allow-order-pausing"}
                  placement={"right"}
                  overlay={
                    <Tooltip id={"tooltip-allow-order-pausing"}>
                      Do you want to be able to pause and unpause ordering from Deliverect?
                    </Tooltip>
                  }
                >
                  <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                </OverlayTrigger>,
              ]}
              name={"allowOrderPausing"}
              id={"allow-order-pausing"}
            />
          </Form.Row>
          <Form.Row>
            <CheckboxInput
              label={"Import Menu Availability Schedules from Deliverect"}
              name={"importMenuAvailabilitySchedules"}
              id={"importMenuAvailabilitySchedules"}
            />
          </Form.Row>
          <Form.Row>
            <DisplayExtraCheckoutInfoOnTicketsFields
              injectOrders={values.injectOrders}
              posName={"Deliverect"}
              showFulfillmentMethodOption={false}
            />
          </Form.Row>
        </>
      ) : (
        <></>
      )}
      {/*<Form.Row className={"margin-bottom-1"}>*/}
      {/*  <CheckboxInput sm={12} label="Inject orders into Deliverect" name="injectOrders" id="inject-orders" />*/}
      {/*</Form.Row>*/}
      {/*<DisplayExtraCheckoutInfoOnTicketsFields injectOrders={values.injectOrders} posName={"Deliverect"} />*/}
    </>
  );
};

export default DeliverectIntegrationModalInputs;
