const { hostname } = window.location;

let bbotDjangoServer = "";
if (["localhost", "127.0.0.1"].includes(hostname)) {
  bbotDjangoServer = "http://localhost:8000";
} else {
  bbotDjangoServer = window.location.protocol + "//" + hostname.split("owner.")[1];
}

// Static root varries based on environment.  Additionally,
// CSRF cookie name varries based on the environment.  For why see:
// https://docs.google.com/document/d/1Q-JA-xvQkkzvIBN68rN4HdQomHnVRmuJSK8uM7gTvoQ/edit
export const Environments = {
  Prod: "prod",
  Staging: "staging",
  Dev: "dev",
  Demo: "demo",
};
const parts = hostname.split(".");
const subdomain = parts[0] === "owner" ? parts[1] : parts[0]; // Expected at parts[1] since owner.env.bbot.menu
let env = "prod";
let staticRoot = "https://static.bbot.menu/";
if ([Environments.Staging, Environments.Dev, Environments.Demo].includes(subdomain)) env = subdomain;
else if (subdomain === "localhost" || parseInt(subdomain) === subdomain) env = "localdev"; // handles IPs
if ([Environments.Staging, Environments.Dev, Environments.Demo].includes(subdomain))
  staticRoot = "https://static." + subdomain + ".bbot.menu/";
else if (subdomain === "localhost" || parseInt(subdomain) === subdomain) staticRoot = bbotDjangoServer + "/static/"; // handles IPs
export const ENV = env;
export const COOKIE_HASH = "fghj";
export const STATIC_ROOT = staticRoot;

export const BBOT_DJANGO_SERVER = bbotDjangoServer;
export const LOCALDEVSERVER = true;
export default bbotDjangoServer;
