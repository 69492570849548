import Icon from "@ant-design/icons";
import React from "react";

const EmployeeSVG = () => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 18C2.5 9.16344 9.66344 2 18.5 2C27.3366 2 34.5 9.16344 34.5 18C34.5 26.8366 27.3366 34 18.5 34C9.66344 34 2.5 26.8366 2.5 18Z"
      fill="#D1E9FF"
    />
    <path
      d="M11.1666 24.6533C11.1666 25.0267 11.4666 25.3267 11.84 25.3267H20.5C20.8733 25.3267 21.1733 25.0267 21.1733 24.6533V24H11.1666V24.6533ZM16.1666 15.9933C13.6666 15.9933 11.1666 17.3333 11.1666 20H21.1666C21.1666 17.3333 18.6666 15.9933 16.1666 15.9933ZM12.9133 18.6667C13.6533 17.6333 15.2266 17.3267 16.1666 17.3267C17.1066 17.3267 18.68 17.6333 19.42 18.6667H12.9133ZM11.1666 21.3333H21.1666V22.6667H11.1666V21.3333ZM22.5 13.3333V10.6667H21.1666V13.3333H17.8333L17.9866 14.6667H24.36L23.4266 24H22.5V25.3333H23.6466C24.2066 25.3333 24.6666 24.9 24.7333 24.3533L25.8333 13.3333H22.5Z"
      fill="#1570EF"
    />
    <path
      d="M18.5 32C10.768 32 4.5 25.732 4.5 18H0.5C0.5 27.9411 8.55887 36 18.5 36V32ZM32.5 18C32.5 25.732 26.232 32 18.5 32V36C28.4411 36 36.5 27.9411 36.5 18H32.5ZM18.5 4C26.232 4 32.5 10.268 32.5 18H36.5C36.5 8.05887 28.4411 0 18.5 0V4ZM18.5 0C8.55887 0 0.5 8.05887 0.5 18H4.5C4.5 10.268 10.768 4 18.5 4V0Z"
      fill="#EFF8FF"
    />
  </svg>
);

const Employee = () => <Icon title="Employee" aria-label="Employee" component={() => EmployeeSVG()} />;

export default Employee;
