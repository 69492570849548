import React, { useEffect, useState } from "react";
import { Colors, InlineChildren, StackChildren, Text } from "@doordash/design-language";
import Toggle from "@doordash/component-toggle";
import { TASK_WIDGET_INFO } from "components/customer-journey/JourneyTask";
import ListCell from "@doordash/component-list-cell";
import Button from "@doordash/component-button";
import { EmailField, PhoneField, TextField } from "@doordash/component-fields";
import styled from "styled-components";
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from "Constants";

const getInitialState = (contacts) => ({
  firstName: {
    value: contacts.it.first_name ?? "",
    error: "",
    isRequired: true,
  },
  lastName: {
    value: contacts.it.last_name ?? "",
    error: "",
    isRequired: true,
  },
  phone: {
    value: contacts.it.phone ?? "",
    error: "",
    isRequired: true,
  },
  email: {
    value: contacts.it.email ?? "",
    error: "",
    isRequired: true,
  },
});

const ItContactWidget = ({ contacts, onSave, isSaving, sameAsPrimaryContact, onSameAsPrimaryContactToggle }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [itContactFormValues, setitContactFormValues] = useState(getInitialState(contacts));

  useEffect(() => {
    onFieldChange("firstName", contacts.it.first_name);
    onFieldChange("lastName", contacts.it.last_name);
    onFieldChange("email", contacts.it.email);
    onFieldChange("phone", contacts.it.phone);
    setIsEditing(
      [contacts.it.first_name, contacts.it.last_name, contacts.it.email, contacts.it.phone].every((value) => {
        return value === "";
      })
    );
  }, [contacts]);

  const onFieldChange = (fieldName, fieldValue) => {
    setitContactFormValues((formValues) => {
      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: "",
          isRequired: formValues[fieldName].isRequired,
        },
      };
    });
  };

  const validateItContactForm = () => {
    let formHasErrors = false;

    const formEntries = Object.entries(itContactFormValues).map(([fieldName, { value, isRequired }]) => {
      let validationError = "";

      // valid phone number
      if (fieldName === "phone") {
        if (!!value.length && (!value.match(PHONE_VALIDATION_REGEX) || value.includes(" "))) {
          formHasErrors = true;
          validationError = "Please enter a valid phone number";
        }
      }

      // valid email
      if (fieldName === "email") {
        if (!!value.length && (!value.match(EMAIL_VALIDATION_REGEX) || value.includes(" "))) {
          formHasErrors = true;
          validationError = "Please enter a valid email";
        }
      }

      const hasValidationError = isRequired && !value.length;
      if (hasValidationError) {
        formHasErrors = true;
        validationError = "This field is required";
      }

      return [
        fieldName,
        {
          value,
          isRequired,
          error: validationError,
        },
      ];
    });

    setitContactFormValues(Object.fromEntries(formEntries));
    return !formHasErrors;
  };

  const onSaveClicked = () => {
    if (!validateItContactForm()) {
      return;
    }

    onSave({
      it_first_name: itContactFormValues.firstName.value,
      it_last_name: itContactFormValues.lastName.value,
      it_email: itContactFormValues.email.value,
      it_phone: itContactFormValues.phone.value,
    });

    setIsEditing(false);
  };

  return (
    <div>
      <div>
        <Text tag="h1" styles={Text.Styles.Title1} data-test-id="subtask-title-2">
          {TASK_WIDGET_INFO.it_contact.title}
        </Text>
        <div className="margin-bottom-2">
          <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
            {TASK_WIDGET_INFO.it_contact.description}
          </Text>
        </div>
      </div>
      <StackChildren size={StackChildren.Sizes.Large}>
        {Object.values(contacts.primary).every((value) => !!value) && (
          <Toggle
            label="Same as Primary Contact"
            onChange={(isSelected) => onSameAsPrimaryContactToggle(isSelected)}
            isSelected={sameAsPrimaryContact}
          />
        )}
        {!isEditing ? (
          <StackChildren size={0}>
            <ListCell
              title={<Text styles={Text.Styles.Body2Emphasis}>First Name</Text>}
              insetHorizontalSize={ListCell.InsetSizes.None}
              insetSize={ListCell.InsetSizes.XxSmall}
              isSeparatorVisible={false}
              subtext={
                <Text
                  styles={Text.Styles.Body1}
                  color={Colors.TextSecondary}
                  data-test-id="it-first-name-saved-display"
                >
                  {itContactFormValues.firstName.value}
                </Text>
              }
              renderAfterContent={() => (
                <EditButtonWrap>
                  {!sameAsPrimaryContact && (
                    <Button
                      data-test-id="edit-it-contract-button"
                      type={Button.Types.Tertiary}
                      isInline
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  )}
                </EditButtonWrap>
              )}
            />
            <ListCell
              title={<Text styles={Text.Styles.Body2Emphasis}>Last Name</Text>}
              insetHorizontalSize={ListCell.InsetSizes.None}
              insetSize={ListCell.InsetSizes.XxSmall}
              isSeparatorVisible={false}
              subtext={
                <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="it-last-name-saved-display">
                  {itContactFormValues.lastName.value}
                </Text>
              }
            />
            <ListCell
              title={<Text styles={Text.Styles.Body2Emphasis}>Phone Number</Text>}
              insetHorizontalSize={ListCell.InsetSizes.None}
              insetSize={ListCell.InsetSizes.XxSmall}
              isSeparatorVisible={false}
              subtext={
                <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="it-phone-saved-display">
                  {itContactFormValues.phone.value}
                </Text>
              }
            />
            <ListCell
              title={<Text styles={Text.Styles.Body2Emphasis}>Email</Text>}
              insetHorizontalSize={ListCell.InsetSizes.None}
              insetSize={ListCell.InsetSizes.XxSmall}
              isSeparatorVisible={false}
              subtext={
                <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="it-email-saved-display">
                  {itContactFormValues.email.value}
                </Text>
              }
            />
          </StackChildren>
        ) : (
          <StackChildren size={StackChildren.Sizes.Large}>
            <StackChildren>
              <InlineChildren>
                <TextField
                  label={"First Name"}
                  placeholder="First Name"
                  value={itContactFormValues.firstName.value}
                  error={itContactFormValues.firstName.error}
                  onChange={(firstName) => onFieldChange("firstName", firstName)}
                  data-test-id="it-first-name-field"
                />
                <TextField
                  label={"Last Name"}
                  placeholder="Last Name"
                  value={itContactFormValues.lastName.value}
                  error={itContactFormValues.lastName.error}
                  onChange={(lastName) => onFieldChange("lastName", lastName)}
                  data-test-id="it-last-name-field"
                />
              </InlineChildren>
              <InlineChildren>
                <EmailField
                  label={"Email"}
                  placeholder="Email"
                  value={itContactFormValues.email.value}
                  error={itContactFormValues.email.error}
                  onChange={(email) => onFieldChange("email", email)}
                  data-test-id="it-email-field"
                />
                <PhoneField
                  label={"Phone Number"}
                  placeholder="(000) 000-0000"
                  value={itContactFormValues.phone.value}
                  error={itContactFormValues.phone.error}
                  onChange={(phone) => onFieldChange("phone", phone)}
                  data-test-id="it-phone-field"
                />
              </InlineChildren>
            </StackChildren>
            <Button
              isInline
              onClick={onSaveClicked}
              state={isSaving ? Button.States.Loading : Button.States.Default}
              data-test-id="save-it-contact-button"
            >
              Save Contact
            </Button>
          </StackChildren>
        )}
      </StackChildren>
    </div>
  );
};

export default ItContactWidget;

const EditButtonWrap = styled.div`
  margin-top: 3px;
`;
