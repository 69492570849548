import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BbotSelect, BbotButton, Card, BbotInput, Checkbox } from "bbot-component-library";
import BulkEditableSetting from "components/owner-app/BulkEditableSetting";
import _ from "lodash";

const DEFAULT_FM_TOGGLE_LABELS = [
  {
    fulfillment_method: "driver_delivery",
    button_name: "Delivery",
  },
  {
    fulfillment_method: "patron_pickup",
    button_name: "Carryout",
  },
];

const FulfillmentTab = (props) => {
  const [settingsState, setSettingsState] = useState({});
  const [isBulkEditFMLabelInputsVisible, setIsBulkEditFMLabelInputsVisible] = useState(false);

  const {
    bulkEditMode,
    updateLocationSettings,
    updatedLocationSettings,
    removeChanges,
    defaultLocationSettings,
    fulfillmentMethods,
    selectedLocations,
  } = props;

  useEffect(() => {
    const { bulkEditMode } = props;
    setSettingsState({
      ...settingsState,
      isMessageWhenOffBulkEditVisible: !bulkEditMode,
    });
  }, [props.bulkEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSettingsState((settingsState) => ({
      ...settingsState,
      allowOrderAhead: props.defaultLocationSettings.allow_order_ahead || "auto",
      fulfillmentMethod: props.defaultLocationSettings.fulfillment_method,
      messageWhenOff: props.defaultLocationSettings.message_when_off || "",
      messageWhenOffEnabled: props.defaultLocationSettings.message_when_off?.length > 0 || false,
      isCheckoutEnabled: props.defaultLocationSettings.enable_checkout,
      forceAllowOrderAhead: props.forceAllowOrderAhead ?? false,
      doordashLof: props.doordashLof ?? false,
      possibleFulfillmentMethods: props.defaultLocationSettings.possible_fulfillment_methods || [],
      integrations: props.defaultLocationSettings.integrations || {},
    }));
  }, [props.defaultLocationSettings, props.forceAllowOrderAhead, props.doordashLof]);

  // Not bulk edit, and fmn is patron choice, or is bulk edit and all locations are patron choice
  const showFMToggleLabelSetting =
    (!bulkEditMode && settingsState.fulfillmentMethod === "patron_choice") ||
    (bulkEditMode &&
      (updatedLocationSettings.properties?.fulfillment_method === "patron_choice" ||
        (!updatedLocationSettings.properties?.fulfillment_method &&
          selectedLocations.every((location) => location.fulfillment_method === "patron_choice"))));

  // Not bulk edit, and fmn is driver delivery, or is bulk edit and all locations are driver delivery
  const deliveryFulfillmentMethods = ["patron_choice", "driver_delivery", "driver_delivery_with_expo"];
  const showLOFToggleLabelSetting =
    settingsState.doordashLof &&
    ((!bulkEditMode && deliveryFulfillmentMethods.includes(settingsState.fulfillmentMethod)) ||
      (bulkEditMode &&
        (deliveryFulfillmentMethods.includes(updatedLocationSettings.properties?.fulfillment_method) ||
          (!updatedLocationSettings.properties?.fulfillment_method &&
            selectedLocations.every((location) => deliveryFulfillmentMethods.includes(location.fulfillment_method))))));

  const bulkEditButtonText = "Edit For Selected Locations";

  return (
    <div>
      <div className={"margin-bottom-2"}>
        <h4>Fulfillment Method/Ordering Settings</h4>
        <p>
          Edit ordering settings, including what fulfillment method is used at the selected locations.
          <a href={"https://central.bbot.menu/article/516-editing-locations-fulfillment"}> More info here.</a>
        </p>
      </div>

      <Card>
        <BulkEditableSetting
          title={"Fulfillment Method"}
          description={
            <span>
              This controls the fulfillment method for the selected locations. Learn more about fulfillment methods{" "}
              <a href={"https://central.bbot.menu/article/521-fulfillment-methods"}>here</a>.
            </span>
          }
          inBulkEditMode={bulkEditMode}
          bulkEditButtonText={bulkEditButtonText}
          onBulkEditInitial={() =>
            updateLocationSettings({
              fulfillment_method: defaultLocationSettings.fulfillment_method,
            })
          }
          onBulkEditCancel={() => removeChanges("fulfillment_method")}
        >
          <FulfillmentSelect
            value={settingsState.fulfillmentMethod}
            onChange={(fulfillmentMethod) => {
              if (fulfillmentMethod === "patron_choice") {
                setSettingsState({
                  ...settingsState,
                  fulfillmentMethod: "patron_choice",
                  possibleFulfillmentMethods: DEFAULT_FM_TOGGLE_LABELS,
                });
                updateLocationSettings({
                  fulfillment_method: "patron_choice",
                  possible_fulfillment_methods: DEFAULT_FM_TOGGLE_LABELS,
                });
              } else {
                setSettingsState({
                  ...settingsState,
                  fulfillmentMethod: fulfillmentMethod,
                });
                updateLocationSettings({
                  fulfillment_method: fulfillmentMethod,
                  possible_fulfillment_methods: [],
                });
              }
            }}
            options={Object.entries(fulfillmentMethods).map((fulfillmentMethodEntry, i) => ({
              value: fulfillmentMethodEntry[0],
              label: fulfillmentMethodEntry[1],
            }))}
          />
        </BulkEditableSetting>

        {showFMToggleLabelSetting && (
          <BulkEditableSetting
            title={"Patron Choice Options"}
            description={
              <span>
                For <b>Patron's Choice</b> fulfillment method only. This setting controls the fulfillment method and
                label for the fulfillment method toggle on the checkout screen. Most common setup is Pickup/Delivery.
              </span>
            }
            inBulkEditMode={bulkEditMode}
            bulkEditButtonText={bulkEditButtonText}
            onBulkEditInitial={() => {
              const defaultFMLabels =
                settingsState.possibleFulfillmentMethods.length > 0
                  ? settingsState.possibleFulfillmentMethods
                  : DEFAULT_FM_TOGGLE_LABELS;
              setIsBulkEditFMLabelInputsVisible(true);
              updateLocationSettings({
                possible_fulfillment_methods: defaultFMLabels,
              });
              setSettingsState({
                ...settingsState,
                possibleFulfillmentMethods: defaultFMLabels,
              });
            }}
            onBulkEditCancel={() => {
              setIsBulkEditFMLabelInputsVisible(false);
              removeChanges("possible_fulfillment_methods");
            }}
          >
            {(!bulkEditMode || (bulkEditMode && isBulkEditFMLabelInputsVisible)) && (
              <div className={"padding-x-2 margin-bottom-2"}>
                {settingsState.possibleFulfillmentMethods?.map((possibleFMN, index) => (
                  <div key={`patrons-choice-option-${possibleFMN.fulfillment_method}`}>
                    <PatronsChoiceOption>
                      <span className={"margin-right-2"}>
                        <div className={"text-small-detail"}>Fulfillment Method</div>

                        <BbotSelect
                          defaultValue={possibleFMN.fulfillment_method}
                          onChange={(fulfillmentMethod) => {
                            settingsState.possibleFulfillmentMethods[index].fulfillment_method = fulfillmentMethod;

                            let updatedButtonNames = [...settingsState.possibleFulfillmentMethods];
                            updateLocationSettings({
                              possible_fulfillment_methods: updatedButtonNames,
                            });
                          }}
                          options={Object.entries(fulfillmentMethods)
                            .filter((fulfillmentMethod) => fulfillmentMethod[0] !== "patron_choice")
                            .map((fulfillmentMethodEntry, i) => ({
                              value: fulfillmentMethodEntry[0],
                              label: fulfillmentMethodEntry[1],
                            }))}
                        />
                      </span>
                      <span className={"margin-right-2"}>
                        <div className={"text-small-detail"}>Button Label</div>
                        <div>
                          <BbotInput
                            value={possibleFMN.button_name}
                            name={"locationName"}
                            onChange={(e) => {
                              settingsState.possibleFulfillmentMethods[index].button_name = e.target.value;

                              let updatedButtonNames = [...settingsState.possibleFulfillmentMethods];
                              updateLocationSettings({
                                possible_fulfillment_methods: updatedButtonNames,
                              });
                            }}
                          />
                        </div>
                      </span>
                      {index >= 2 && (
                        <BbotButton
                          danger
                          onClick={() => {
                            setSettingsState((settingsState) => {
                              const otherFulfillmentMethods = settingsState.possibleFulfillmentMethods.filter(
                                (_, possibleFMIndex) => index !== possibleFMIndex
                              );
                              updateLocationSettings({
                                possible_fulfillment_methods: otherFulfillmentMethods,
                              });
                              return {
                                ...settingsState,
                                possibleFulfillmentMethods: otherFulfillmentMethods,
                              };
                            });
                          }}
                        >
                          Delete
                        </BbotButton>
                      )}
                    </PatronsChoiceOption>
                    <hr />
                  </div>
                ))}
              </div>
            )}
          </BulkEditableSetting>
        )}

        {showLOFToggleLabelSetting && (
          <BulkEditableSetting
            title={"Driver Delivery Options"}
            description={
              <span>
                For delivery fulfillment methods only, at restaurants with a Doordash Drive integration that use Large
                Order Fulfillment
              </span>
            }
            inBulkEditMode={bulkEditMode}
            bulkEditButtonText={bulkEditButtonText}
            onBulkEditInitial={() => {}}
            onBulkEditCancel={() => {
              removeChanges("integrations_table_mapping");
            }}
          >
            <div className={"padding-x-2 margin-bottom-2"}>
              <Checkbox
                checked={
                  bulkEditMode
                    ? selectedLocations.every(
                        (location) => location.integrations.doordash_drive?.large_order_fulfillment === true
                      )
                    : settingsState.integrations.doordash_drive?.large_order_fulfillment === true
                }
                onChange={(value) => {
                  let integrations = settingsState.integrations;
                  if (value.target.checked) {
                    _.set(integrations, "doordash_drive.large_order_fulfillment", value.target.checked);
                  } else if (_.includes(Object.keys(integrations), "doordash_drive")) {
                    if (Object.keys(integrations.doordash_drive).length === 1) {
                      _.set(integrations, "doordash_drive", "remove");
                    } else {
                      delete integrations.doordash_drive.large_order_fulfillment;
                    }
                  }
                  setSettingsState({
                    ...settingsState,
                    integrations: integrations,
                  });
                  updateLocationSettings({
                    integrations_table_mapping: integrations,
                  });
                }}
              >
                Location is used for Doordash LOF (Large Order Fulfillment) Orders
              </Checkbox>
            </div>
          </BulkEditableSetting>
        )}

        <BulkEditableSetting
          title={"Guest Checkout/Cart Enabled"}
          description={
            'Toggling this OFF will hide "Add to Cart" buttons on menu item pages, preventing guests from adding ' +
            "items to their cart. The cart icon will not display when toggled off. This feature is ideal for " +
            "view-only menus."
          }
          inBulkEditMode={bulkEditMode}
          bulkEditButtonText={bulkEditButtonText}
          onBulkEditInitial={() =>
            updateLocationSettings({
              enable_checkout: settingsState.isCheckoutEnabled,
            })
          }
          onBulkEditCancel={() => removeChanges("enable_checkout")}
          toggleConfig={{
            defaultEnabled: defaultLocationSettings.enable_checkout,
            onClick: (e) => {
              setSettingsState({
                ...settingsState,
                isCheckoutEnabled: e.SWITCH_STATE.enabled,
              });
              updateLocationSettings({
                enable_checkout: e.SWITCH_STATE.enabled,
              });
            },
          }}
        />

        <BulkEditableSetting
          title={"Allow Order Ahead"}
          description={
            "If not set to \"auto\" this will override the 'Allow Order Ahead' checkbox in the 'Menu Timers' page."
          }
          inBulkEditMode={bulkEditMode}
          bulkEditButtonText={bulkEditButtonText}
          onBulkEditInitial={() =>
            updateLocationSettings({
              allow_order_ahead: settingsState.allowOrderAhead,
            })
          }
          onBulkEditCancel={() => removeChanges("allow_order_ahead")}
          toggleConfig={{
            defaultEnabled: defaultLocationSettings.allow_order_ahead === "auto",
            onClick: (e) => {
              setSettingsState({
                ...settingsState,
                allowOrderAhead: e.SWITCH_STATE.enabled ? "auto" : "off",
              });
              updateLocationSettings({
                allow_order_ahead: e.SWITCH_STATE.enabled ? "auto" : "off",
              });
            },
          }}
        />

        <BulkEditableSetting
          title={"Message When Off"}
          description={
            <span>
              This message will be shown to guests who visit your page when the menu is{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://central.bbot.menu/article/410-menu-is-off-hours"}
              >
                off-hours
              </a>
              .
            </span>
          }
          inBulkEditMode={bulkEditMode}
          bulkEditButtonText={bulkEditButtonText}
          onBulkEditInitial={() =>
            updateLocationSettings({
              message_when_off: settingsState.messageWhenOff,
            })
          }
          onBulkEditCancel={() => removeChanges("message_when_off")}
          toggleConfig={{
            toggleShouldBlockContent: true,
            defaultEnabled: defaultLocationSettings.landing_page_name !== "",
            onClick: (e) => {
              if (!e.SWITCH_STATE.enabled) {
                setSettingsState({
                  ...settingsState,
                  messageWhenOff: "",
                });
                updateLocationSettings({
                  message_when_off: "",
                });
              }
            },
          }}
        >
          <BbotInput
            value={settingsState.messageWhenOff}
            name={"messageWhenOff"}
            onChange={(e) => {
              setSettingsState({
                ...settingsState,
                messageWhenOff: e.target.value,
              });
              updateLocationSettings({
                message_when_off: e.target.value,
              });
            }}
          />
        </BulkEditableSetting>
      </Card>
    </div>
  );
};

export default FulfillmentTab;

const PatronsChoiceOption = styled.div`
  padding: 12px;
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const FulfillmentSelect = styled(BbotSelect)`
  min-width: 250px;
`;
