import React from "react";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { TextInput, CheckboxInput, NumberInput } from "components/global/form";
import SelectLocationsInput from "components/global/form/SelectLocationsInput";
import "./CreateEditServiceRequestModal.scss";

class CreateEditServiceRequestModal extends React.Component {
  saveServiceRequest = async (values) => {
    const { onSave, onClose, selectedServiceRequest, selectedCustomer, mode, locations } = this.props;
    const endpoint = mode === "create" ? "/owner/createServiceRequest" : "/owner/editServiceRequest";
    const payload =
      mode === "create"
        ? {
            customerId: selectedCustomer?.customer_id,
            locations: values.only_selected_locations
              ? values.specific_locations_ids
              : locations.map((location) => location.locationId),
            serviceRequests: [{ type: "Custom", ...values }],
          }
        : {
            serviceRequestId: selectedServiceRequest?.id,
            propertiesToEdit: values,
            locations: values.only_selected_locations
              ? values.specific_locations_ids
              : locations.map((location) => location.locationId),
          };

    try {
      await axios.post(endpoint, payload);
      toast.success("Successfully saved service request.");
      onSave();
      onClose();
    } catch (error) {
      toast.error("Trouble saving service request. Please refresh and try again.");
      console.error(endpoint, " failed with error ", error);
    }
  };

  render() {
    const {
      show,
      onClose,
      mode,
      selectedServiceRequest,
      locations,
      fulfillmentMethodsPrettyNames,
      allowedCustomersById,
      locationsByCustomerAndFulfillmentMethod,
    } = this.props;

    const initialValues =
      mode === "create"
        ? {
            label_for_patron: "",
            description_for_patron: "",
            message_to_staff: "",
            free_text: false,
            show_only_after_checkout: true,
            only_selected_locations: false,
            rate_limit_between_requests: 30,
            specific_locations_ids: [],
          }
        : {
            label_for_patron: selectedServiceRequest?.label_for_patron,
            description_for_patron: selectedServiceRequest?.description_for_patron,
            message_to_staff: selectedServiceRequest?.message_to_staff,
            free_text: selectedServiceRequest?.free_text,
            show_only_after_checkout: selectedServiceRequest?.show_only_after_checkout,
            only_selected_locations: locations.length !== selectedServiceRequest?.locations.length,
            rate_limit_between_requests: selectedServiceRequest?.rate_limit_between_requests,
            specific_locations_ids: selectedServiceRequest?.locations,
          };
    return (
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            label_for_patron: Yup.string().required("Label for Patron is required"),
            description_for_patron: Yup.string(),
            message_to_staff: Yup.string(),
            free_text: Yup.boolean(),
            show_only_after_checkout: Yup.boolean(),
            only_selected_locations: Yup.boolean(),
            rate_limit_between_requests: Yup.number(),
          })}
          onSubmit={this.saveServiceRequest}
        >
          {({ values, errors, touched, isValidating, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>{mode === "create" ? "Create New" : "Edit"} Service Request</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row className={"margin-bottom-4"}>
                  <TextInput name={"label_for_patron"} id={"label-for-patron"} label={"Label for Patron"} />
                </Form.Row>
                <Form.Row className={"margin-bottom-4"}>
                  <TextInput
                    name={"description_for_patron"}
                    id={"description-for-patron"}
                    label={"Description for Patron (optional)"}
                  />
                </Form.Row>
                <div className={"grouped-inputs margin-bottom-4"}>
                  <Form.Row className={"margin-bottom-1"}>
                    <TextInput
                      name={"message_to_staff"}
                      id={"message-to-staff"}
                      label={"Message to Staff"}
                      disabled={values.free_text}
                    />
                  </Form.Row>
                  <Form.Row className={"margin-bottom-1"}>
                    <div className={"divider"}>
                      <span>-------------------OR-------------------</span>
                    </div>
                  </Form.Row>
                  <Form.Row>
                    <CheckboxInput
                      name={"free_text"}
                      id={"free-text"}
                      label={"Allow patron to send custom message."}
                      onChange={(value) => {
                        if (value === true) setFieldValue("message_to_staff", "");
                      }}
                    />
                  </Form.Row>
                </div>
                <Form.Row>
                  <NumberInput
                    label={"Minimum seconds between requests allowed"}
                    name={"rate_limit_between_requests"}
                    id={"rate-limit"}
                  />
                </Form.Row>
                <p style={{ fontStyle: "italic", fontSize: "15px" }} className={"margin-bottom-3"}>
                  Block service requests of this type that are placed in quick succession from the same location
                </p>
                <Form.Row className={"margin-bottom-2"}>
                  <CheckboxInput
                    name={"show_only_after_checkout"}
                    id={"show-only-after-checkout"}
                    label={"Show only after paid order."}
                  />
                </Form.Row>
                <Form.Row className={"margin-bottom-2"}>
                  <CheckboxInput
                    name={"only_selected_locations"}
                    id={"only_selected_locations"}
                    label={"Show only on specified locations."}
                  />
                </Form.Row>
                {values.only_selected_locations && (
                  <SelectLocationsInput
                    locationsById={locationsByCustomerAndFulfillmentMethod}
                    fulfillmentMethodsPrettyNames={fulfillmentMethodsPrettyNames}
                    allowedCustomersById={allowedCustomersById}
                    values={values}
                    setFieldValue={setFieldValue}
                    showServiceRequestFlag={true}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"tertiary"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateEditServiceRequestModal;
