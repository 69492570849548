import { Col, Form as Btpform, FormGroup } from "react-bootstrap";
import { NumberInput, SearchSelectInput } from "components/global/form";
import React from "react";
import RadioGroupInput from "components/global/form/RadioGroupInput";
import SearchSelectInputUtils from "components/global/form/SearchSelectInputUtils";

class CreateEditOrderGroupingRulesPage2 extends React.Component {
  getStationChoices = () => {
    const { allStations, getNecessaryVendors, selectedVendors } = this.props;

    let stationChoices = [];

    if (allStations != null) {
      let stationsDict = allStations;
      let necessaryVendors = getNecessaryVendors(SearchSelectInputUtils.getValuesFromMultiSelect(selectedVendors));

      Object.keys(allStations)
        .filter((cus) => necessaryVendors.includes(cus))
        .forEach((cus) =>
          stationsDict[cus].forEach((st) =>
            stationChoices.push({
              value: st.id,
              label: st.station_name,
            })
          )
        );
    }

    stationChoices.sort((a, b) => (a.label > b.label ? 1 : -1));
    return stationChoices;
  };

  setGroupByCheckout = (value) => {
    const { setFieldValue } = this.props;

    setFieldValue("groupByCheckout", value);

    if (value !== "disabled") {
      //If groupByCheckout is disabled the user needs to manually select printers
      setFieldValue("specificPrinters", true);
    }
  };

  render() {
    const {
      setFieldValue,
      groupByCheckout,
      selectedStations,
      groupAcrossTime,
      minutesWaitItemsInCart,
      minutesWaitNoItemsInCart,
      selectedStationsKey,
    } = this.props;

    return (
      <FormGroup>
        <Btpform.Row className="margin-bottom-1">
          <Col md={12} className="field-wrapper">
            <h6>Grouping by Checkout</h6>
            <hr />
          </Col>
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-2">
          <RadioGroupInput
            md={12}
            options={[
              { label: "No, handle the orders separately", value: "disabled" },
              { label: "Yes, group all orders from the checkout together", value: "all" },
              { label: "Only group orders from specific prep stations", value: "specific_stations" },
            ]}
            id={"groupByCheckout"}
            name={"groupByCheckout"}
            value={groupByCheckout}
            selectedValue={groupByCheckout}
            label={
              "If a checkout produces multiple orders (to different prep stations), should they be grouped and printed as one ticket?"
            }
            className={"groupByCheckoutRadioButtons"}
            onChange={(value) => this.setGroupByCheckout(value)}
          />
        </Btpform.Row>
        {this.props.groupByCheckout === "specific_stations" && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              md={12}
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              className={"margin-bottom-0"}
              id={"selectedStations"}
              name={"selectedStations"}
              label={"Specific Stations"}
              options={this.getStationChoices()}
              key={selectedStationsKey}
              placeholder={"Select Specific Stations (Where orders are grouped)"}
              value={selectedStations}
              defaultValue={selectedStations}
              onChange={(value) => setFieldValue("selectedStations", value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row>
          <Col md={12} className="field-wrapper">
            <h6>Grouping Over Time</h6>
            <hr />
          </Col>
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-2">
          <RadioGroupInput
            options={[
              { label: "No, print as soon as a guest checks out", value: false },
              {
                label:
                  "Yes, wait a few minutes to let other guests check out, and print all guests' orders on one ticket",
                value: true,
              },
            ]}
            md={12}
            id={"groupAcrossTime"}
            name={"groupAcrossTime"}
            value={groupAcrossTime}
            selectedValue={groupAcrossTime}
            label={
              "Should orders at the same location code be held for a designated amount of time before printing so that they all print on one ticket?"
            }
            className={"groupAcrossTimeRadioButtons"}
            onChange={(value) => setFieldValue("groupAcrossTime", value === "true")}
          />
        </Btpform.Row>
        {groupAcrossTime && (
          <FormGroup>
            <Btpform.Row className="margin-bottom-2">
              <NumberInput
                md={12}
                label={"How many minutes to wait when other guests at the same location have items in their carts?"}
                name={"minutesWaitItemsInCart"}
                value={minutesWaitItemsInCart}
                id={"minutesWaitItemsInCart"}
                placeholder={"5 minutes"}
              />
            </Btpform.Row>
            <Btpform.Row className="margin-bottom-2">
              <NumberInput
                md={12}
                label={
                  "How many minutes to wait when other guests at the same location do NOT have items in their carts?"
                }
                name={"minutesWaitNoItemsInCart"}
                value={minutesWaitNoItemsInCart}
                id={"minutesWaitNoItemsInCart"}
                placeholder={"3 minutes"}
              />
            </Btpform.Row>
          </FormGroup>
        )}
      </FormGroup>
    );
  }
}

export default CreateEditOrderGroupingRulesPage2;
