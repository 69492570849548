import React, { useState, useEffect } from "react";
import { BorderRadius, Colors, StackChildren, Text } from "@doordash/design-language";

import { FsFileMetadata } from "components/owner-app/file-upload";
import { Widget } from "./UploadMenuAssetsWidget";
import axios, { AxiosError } from "axios";
import { getWidgetData, updateWidgetData } from "./Widget";
import { generalErrorAlert } from "util/Utils";
import ImageUploaderAndCropper from "components/global/image-uploader/ImageUploaderAndCropper";
import styled from "styled-components";
import { UpdateTaskStatus } from "./OrderingPageWidget";

type UploadBOSAssetsWidgetProps = {
  widget: Widget;
  updateTaskStatus: UpdateTaskStatus;
  userInfo: any;
  customerId: string;
};

type UploadedAsset = {
  original: FsFileMetadata;
  cropped: FsFileMetadata;
};

type UploadedAssets = {
  logo?: UploadedAsset;
  mobile_banner?: UploadedAsset;
  desktop_banner?: UploadedAsset;
  mobile_background?: UploadedAsset;
  desktop_background?: UploadedAsset;
};

const UploadBOSAssetsWidget: React.FC<UploadBOSAssetsWidgetProps> = ({
  widget,
  updateTaskStatus,
  userInfo,
  customerId,
}) => {
  const [uploadedAssets, setUploadedAssets] = useState<UploadedAssets>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (widget) {
      setIsLoading(true);
      getWidgetData(widget.id)
        .then((res: any) => {
          if (res.data.uploaded_assets) {
            setUploadedAssets(res.data.uploaded_assets);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [widget]);

  const isAdmin = userInfo?.role === "admin";

  const updateUploadedAssetData = async (assetType: string, cropped: FsFileMetadata, original?: FsFileMetadata) => {
    const updatedAssets = {
      ...uploadedAssets,
      [assetType]: {
        cropped,
        original: original ?? cropped,
      },
    };

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          uploaded_assets: updatedAssets,
        },
      });
      setUploadedAssets(updatedAssets);
      const allAssetsUploaded =
        !!updatedAssets.logo?.cropped &&
        !!updatedAssets.mobile_background?.cropped &&
        !!updatedAssets.desktop_background?.cropped &&
        !!updatedAssets.mobile_banner?.cropped &&
        !!updatedAssets.desktop_banner?.cropped;

      updateTaskStatus(allAssetsUploaded ? "completed" : "in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not save image. Please let us know if this keeps happening.",
        true
      );
    }
  };

  const removeAssetData = async (assetType: string) => {
    const updatedAssets = {
      ...uploadedAssets,
      [assetType]: null,
    };

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          uploaded_assets: updatedAssets,
        },
      });
      setUploadedAssets(updatedAssets);
      // Removing asset should un-complete the task
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not remove image. Please let us know if this keeps happening.",
        true
      );
    }
  };

  const saveAsset = async (
    assetName: keyof UploadedAssets,
    cropped: FsFileMetadata,
    original?: FsFileMetadata,
    url?: string
  ) => {
    try {
      if (url) {
        await axios.post(url, [{ filesUploaded: [cropped] }]);
      }
      updateUploadedAssetData(assetName, cropped, original);
    } catch (error) {
      generalErrorAlert(error, "Could not save image. Please let us know if this keeps happening.");
    }
  };

  /**
   *
   * @param assetName Name of the asset in this local component
   * @param assetType Name of the asset as stored in the backend
   */
  const removeAsset = async (assetName: keyof UploadedAssets, assetType: string) => {
    try {
      removeAssetData(assetName);
      await axios.post("/api/deleteCustomerAsset", {
        customerId,
        assetType: assetType,
      });
      // If removing asset, we should boot task to incomplete
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error) {
      generalErrorAlert(error, "Sorry, could not remove this asset. Please let us know if this keeps happening.", true);
    }
  };

  const onLogoSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("logo", cropped, original, "/api/storeLogo");

  const onDesktopBannerSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("desktop_banner", cropped, original, "/api/storeDesktopBanner");

  const onMobileBannerSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("mobile_banner", cropped, original, "/api/storeMobileBanner");

  const onDesktopBackgroundSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("desktop_background", cropped, original);

  const onMobileBackgroundSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("mobile_background", cropped, original);

  const previewHero = (
    <PreviewContainer>
      <LandingPageLabel color={Colors.TextTertiary} styles={Text.Styles.Caption2}>
        Landing Page
      </LandingPageLabel>
      <PhoneWireframe>
        <DecorativeCircle1 />
        <DecorativeCircle2 />
        <DecorativeCircle3 />

        <div className="margin-bottom-8">
          <LogoContainer>
            <Text styles={Text.Styles.Caption2}>Logo</Text>
          </LogoContainer>
        </div>

        <div className="margin-bottom-6">
          <InputSearchSkeleton>
            <InputSearchButtonSkeleton />
          </InputSearchSkeleton>
          <ButtonSkeleton />
          <ButtonSkeleton />
        </div>

        <ForegroundLabel styles={Text.Styles.Caption2} textAlign="center">
          Landing Page Background
        </ForegroundLabel>

        <HomebarSkeleton />
      </PhoneWireframe>
      <MenuPageLabel color={Colors.TextTertiary} styles={Text.Styles.Caption2}>
        Menu Page
      </MenuPageLabel>
      <PhoneWireframe>
        <LogoContainer>
          <Text styles={Text.Styles.Caption2}>Logo</Text>
        </LogoContainer>

        <MenuBannerPreviewContainer>
          <DecorativeCircle4 />
          <DecorativeCircle5 />
          <ForegroundLabel textAlign="center" styles={Text.Styles.Caption2}>
            Menu Banner
          </ForegroundLabel>
        </MenuBannerPreviewContainer>

        <div className="margin-bottom-13">
          <LineSkeleton />
          <LineSkeleton />
        </div>
        <ButtonSkeleton />
        <HomebarSkeleton />
      </PhoneWireframe>
    </PreviewContainer>
  );

  return (
    <StackChildren size={StackChildren.Sizes.XLarge}>
      {previewHero}

      <WarningContainer>
        <Text styles={Text.Styles.Body1}>
          Logo and Menu Banner images will automatically populate on ordering sites and corresponding Owner Portal input
          fields. Landing Page Background and Logo will not automatically populate on the landing page; use the "Upload
          Assets" button in the BOS Builder tool to import these images when configuring the BOS.
        </Text>
      </WarningContainer>

      <ImageUploaderAndCropper
        showPreviewOnSide
        title="Logo"
        description="Your logo will display on your landing page as well as all of your menu pages."
        acceptedTypes={[".png", ".svg"]}
        acceptedTypesDescription="PNG or SVG format, minimum height of 200px (aspect ratio 1:1 - 2:1)"
        savedOriginalFile={uploadedAssets.logo?.original}
        isAdmin={isAdmin}
        isLoading={isLoading}
        onDelete={() => removeAsset("logo", "storeLogo")}
        directSave={true}
        uploadAssetsOptions={[
          {
            imageTitle: "Logo",
            minAspectRatio: 1,
            maxAspectRatio: 2,
            minHeight: 200,
            minWidth: 200,
            onFileSaved: onLogoSaved,
            savedFile: uploadedAssets.logo?.cropped,
          },
        ]}
      />

      <hr />

      <StackChildren size={StackChildren.Sizes.XLarge}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Title1}>Landing Page Background</Text>
          <Text styles={Text.Styles.Body2}>This image will display on your landing page only.</Text>
        </StackChildren>

        <ImageUploaderAndCropper
          title="Mobile Background"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 9:16 aspect ratio"
          savedOriginalFile={uploadedAssets.mobile_background?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAssetData("mobile_background")}
          uploadAssetsOptions={[
            {
              imageTitle: "Mobile Background",
              aspectRatioDescription: "9:16",
              minAspectRatio: 9 / 16,
              maxAspectRatio: 9 / 16,
              minHeight: 250,
              minWidth: 250,
              onFileSaved: onMobileBackgroundSaved,
              savedFile: uploadedAssets.mobile_background?.cropped,
            },
          ]}
        />

        <ImageUploaderAndCropper
          title="Desktop Background"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 16:9 aspect ratio"
          savedOriginalFile={uploadedAssets.desktop_background?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAssetData("desktop_background")}
          uploadAssetsOptions={[
            {
              imageTitle: "Desktop Background",
              aspectRatioDescription: "16:9",
              minAspectRatio: 16 / 9,
              maxAspectRatio: 16 / 9,
              minHeight: 250,
              minWidth: 250,
              onFileSaved: onDesktopBackgroundSaved,
              savedFile: uploadedAssets.desktop_background?.cropped,
            },
          ]}
        />
      </StackChildren>

      <hr />
      <StackChildren size={StackChildren.Sizes.XLarge}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Title1}>Menu Banner</Text>
          <Text styles={Text.Styles.Body2}>This image will display on your menu page only.</Text>
        </StackChildren>

        <ImageUploaderAndCropper
          title="Mobile Banner"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 3:2 aspect ratio"
          savedOriginalFile={uploadedAssets.mobile_banner?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAsset("mobile_banner", "mobileBanner")}
          uploadAssetsOptions={[
            {
              imageTitle: "Menu Banner",
              minAspectRatio: 1.5,
              maxAspectRatio: 1.5,
              minHeight: 250,
              minWidth: 250,
              onFileSaved: onMobileBannerSaved,
              savedFile: uploadedAssets.mobile_banner?.cropped,
            },
          ]}
        />

        <ImageUploaderAndCropper
          title="Desktop Banner"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 4:1 aspect ratio"
          savedOriginalFile={uploadedAssets.desktop_banner?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAsset("desktop_banner", "desktopBanner")}
          uploadAssetsOptions={[
            {
              imageTitle: "Menu Banner",
              minAspectRatio: 4,
              maxAspectRatio: 4,
              minHeight: 250,
              minWidth: 250,
              onFileSaved: onDesktopBannerSaved,
              savedFile: uploadedAssets.desktop_banner?.cropped,
            },
          ]}
        />
      </StackChildren>
    </StackChildren>
  );
};

export default UploadBOSAssetsWidget;

const WarningContainer = styled.div`
  border-radius: ${BorderRadius.Medium}px;
  border: 1px solid ${Colors.TextError};
  padding: 20px;
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: ${Colors.BackgroundTertiary};
  padding: 24px;
  border-radius: ${BorderRadius.Small}px;
`;

const PhoneWireframe = styled.div`
  width: 160px;
  height: 360px;
  border-radius: ${BorderRadius.Medium}px;
  background-color: ${Colors.SystemWhite};
  margin-right: 40px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  overflow: hidden;
`;

const LandingPageLabel = styled(Text)`
  transform: rotate(-90deg);
  position: relative;
  bottom: 24px;
  margin-right: -12px;
`;

const MenuPageLabel = styled(Text)`
  transform: rotate(-90deg);
  position: relative;
  bottom: 20px;
  margin-right: -8px;
`;

const LogoContainer = styled.div`
  margin-top: 12px;
  padding: 6px;
  background-color: ${Colors.BackgroundSecondary};
  display: inline-block;
  border-radius: ${BorderRadius.Medium}px;
`;

const InputSearchSkeleton = styled.div`
  border: 1px solid ${Colors.BackgroundTertiary};
  height: 24px;
  border-radius: ${BorderRadius.Small}px;
  background-color: ${Colors.SystemWhite};
  margin-bottom: 6px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`;

const InputSearchButtonSkeleton = styled.div`
  width: 40%;
  height: 100%;
  float: right;
  background-color: ${Colors.BackgroundTertiary};
  border-top-right-radius: ${BorderRadius.Small}px;
  border-bottom-right-radius: ${BorderRadius.Small}px;
`;

const ButtonSkeleton = styled.div`
  background-color: ${Colors.BackgroundTertiary};
  height: 24px;
  border-radius: ${BorderRadius.Small}px;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
`;

const MenuBannerPreviewContainer = styled.div`
  height: 80px;
  background-color: ${Colors.BackgroundSecondary};
  border-radius: ${BorderRadius.Medium}px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const LineSkeleton = styled.div`
  height: 12px;
  border-radius: ${BorderRadius.Medium}px;
  background-color: ${Colors.BackgroundTertiary};
  margin-bottom: 6px;
`;

const HomebarSkeleton = styled.div`
  height: 4px;
  width: 64px;
  background-color: ${Colors.BackgroundTertiary};
  position: absolute;
  bottom: 8px;
  left: 50px;
  border-radius: ${BorderRadius.Medium}px;
`;

const ForegroundLabel = styled(Text)`
  position: relative;
  z-index: 2;
`;

const DecorativeCircle1 = styled.div`
  height: 125px;
  width: 125px;
  background-color: ${Colors.BackgroundSecondary};
  position: absolute;
  top: 50px;
  right: -40px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle2 = styled.div`
  height: 178px;
  width: 178px;
  background-color: ${Colors.SystemBlue10};
  position: absolute;
  bottom: -20px;
  left: -80px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle3 = styled.div`
  height: 125px;
  width: 125px;
  background-color: ${Colors.BackgroundSecondary};
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle4 = styled.div`
  height: 114px;
  width: 114px;
  background-color: ${Colors.SystemBlue10};
  position: absolute;
  bottom: -50px;
  left: -10px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle5 = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${Colors.BackgroundTertiary};
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 100%;
  z-index: 0;
`;
