import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import "./styles/customer-journey/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BBOT_DJANGO_SERVER } from "./Config";
import { ENV, COOKIE_HASH } from "./Config";
import * as Sentry from "@sentry/react";

//these tell Axios where to find the csrf token in the Django cookie
axios.defaults.xsrfCookieName = "csrftoken" + ENV + "_" + COOKIE_HASH;
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.baseURL = BBOT_DJANGO_SERVER;
axios.defaults.withCredentials = true;

const environment = process.env.NODE_ENV;
Sentry.init({
  dsn: "https://5e6fc81b9058401a951f8931f4c7ead0@o17585.ingest.sentry.io/6293626",
  environment: environment,
});

// Initialize Segment Tracking
var analytics = (window.analytics = window.analytics || []);
if (!analytics.initialize)
  if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
  else {
    analytics.invoked = !0;
    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "group",
      "track",
      "ready",
      "alias",
      "page",
      "once",
      "off",
      "on",
    ];
    analytics.factory = function (t) {
      return function () {
        var e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };
    };
    for (var t = 0; t < analytics.methods.length; t++) {
      var e = analytics.methods[t];
      analytics[e] = analytics.factory(e);
    }
    analytics.load = function (t) {
      var e = document.createElement("script");
      e.type = "text/javascript";
      e.async = !0;
      e.src =
        ("https:" === document.location.protocol ? "https://" : "http://") +
        "cdn.segment.com/analytics.js/v1/" +
        t +
        "/analytics.min.js";
      var n = document.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(e, n);
    };
    analytics.SNIPPET_VERSION = "3.0.1";
    analytics.load(process.env.REACT_APP_SEGMENT_KEY);
    analytics.page();
  }

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
