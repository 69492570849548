import { Form as Btpform, FormGroup } from "react-bootstrap";
import { CheckboxInput, NumberInput, SearchSelectInput } from "components/global/form";
import React from "react";
import SearchSelectInputUtils from "components/global/form/SearchSelectInputUtils";

class CreateEditOrderGroupingRulesPage4 extends React.Component {
  render() {
    const {
      setFieldValue,
      delayPrinting,
      printSpecificItems,
      getTagChoices,
      getNecessaryVendors,
      selectedVendors,
      selectedFilters,
      delayPrintASAPOrder,
      delayPrintOrderAhead,
    } = this.props;

    return (
      <FormGroup>
        <Btpform.Row className="margin-bottom-2">
          <CheckboxInput
            md={12}
            label={"Print Only Specific Items"}
            name={"printSpecificItems"}
            id={"printSpecificItems"}
            value={printSpecificItems}
          />
        </Btpform.Row>
        {printSpecificItems && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              md={12}
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              className={"margin-bottom-0"}
              id={"desiredFilters"}
              name={"desiredFilters"}
              label={"Select Desired Filters"}
              options={getTagChoices(
                getNecessaryVendors(SearchSelectInputUtils.getValuesFromMultiSelect(selectedVendors))
              )}
              placeholder={"Available Tags"}
              value={selectedFilters}
              defaultValue={selectedFilters}
              onChange={(value) => setFieldValue("selectedFilters", value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row className="margin-bottom-2">
          <CheckboxInput
            id={"delayPrinting"}
            md={12}
            name={"delayPrinting"}
            value={delayPrinting}
            label={"Add an additional delay before printing"}
            className={"groupAcrossTimeCheckBox"}
            onChange={(value) => setFieldValue("delayPrinting", value)}
          />
        </Btpform.Row>
        {delayPrinting && (
          <FormGroup>
            <Btpform.Row className="margin-bottom-2">
              <NumberInput
                label={"For ASAP orders: How many minutes after the order time do you want to print?"}
                md={12}
                name={"delayPrintASAPOrder"}
                value={delayPrintASAPOrder}
                id={"delayPrintASAPOrder"}
                placeholder={
                  "(i.e. 4 minutes - Delay burgers 0 minutes, delay fries 4 minutes.  Relative to last order in group)"
                }
              />
            </Btpform.Row>
            <Btpform.Row className="margin-bottom-2">
              <NumberInput
                label={"For order-ahead orders: How many minutes before the desired time do you want to print?"}
                name={"delayPrintOrderAhead"}
                value={delayPrintOrderAhead}
                id={"delayPrintOrderAhead"}
                placeholder={"Type number of minutes prior to desired time"}
              />
            </Btpform.Row>
          </FormGroup>
        )}
      </FormGroup>
    );
  }
}

export default CreateEditOrderGroupingRulesPage4;
