import React from "react";
import styled from "styled-components";
import { Tabs } from "bbot-component-library";

const BbotTabs = ({ hideTabHeaders = false, ...props }) => {
  return <StyledTabs {...props} className={hideTabHeaders ? "hideTabHeaders" : ""} />;
};

BbotTabs.TabPane = ({ ...props }) => {
  return <Tabs.TabPane {...props} />;
};

const StyledTabs = styled(Tabs)`
  &&&.ant-tabs {
    overflow: visible;

    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: flex-start;
      border-radius: 5px;
      padding: 1px 1px 1px 1px;
      background-color: white;
    }

    .ant-tabs-nav-operations {
      height: 0;
    }

    .ant-tabs-tab {
      color: grey;
      border-radius: 5px;
      padding: 5px 5px 5px 5px;
      &:hover {
        color: black;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background-color: var(--color-primary__light);
      color: var(--color-primary__regular);
      box-shadow: 1px 1px 1px 1px var(--color-primary__light);
      .ant-tabs-tab-btn {
        color: var(--color-primary__regular);
      }
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }

  &&&.hideTabHeaders > .ant-tabs-nav {
    display: none;
  }
`;

export default BbotTabs;
