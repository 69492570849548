import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckboxInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class RelayIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relaySettings: {},
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getRelayConnectionStatus();
    }
  }

  getRelayConnectionStatus = async () => {
    const { selectedCustomer } = this.props;

    this.setState({ loadingData: true });
    try {
      const res = await axios.get(
        "/admin/getRelayDeliveryConnectionStatus?customer_id=" + selectedCustomer?.customer_id
      );
      this.setState({
        relaySettings: res.data,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Relay settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectRelay = async (values) => {
    const { selectedCustomer, onClose, onSave, disconnectMode } = this.props;

    values.enabled = !disconnectMode;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      ...values,
    };

    try {
      await axios.post("/admin/connectRelayDelivery", payload);
      if (disconnectMode) {
        toast.success("Successfully disconnected Relay Delivery.");
        this.setState({
          relaySettings: {},
        });
      } else {
        toast.success("Successfully connected Relay Delivery.");
        values.api_key = "<hidden>";
        this.setState({
          relaySettings: values,
        });
      }
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to Relay Delivery.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { relaySettings, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Relay</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Relay Delivery, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              variant={"danger"}
              onClick={() => {
                this.connectRelay(relaySettings);
              }}
            >
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            api_key: relaySettings.api_key || "",
            account_string: relaySettings.account_string || "",
            inject_orders_as_ready: relaySettings.inject_orders_as_ready,
          }}
          validationSchema={Yup.object({
            api_key: Yup.string().required("API key is required."),
            account_string: Yup.string().required("Location key is required."),
            inject_orders_as_ready: Yup.boolean(),
          })}
          onSubmit={this.connectRelay}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Relay Delivery Integration Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Relay Integration data"} />
                ) : (
                  <>
                    <Form.Row>
                      <TextInput label={"Producer Location Key"} name={"account_string"} id={"account-string"} />
                    </Form.Row>
                    <Form.Row>
                      <TextInput label={"API Key"} name={"api_key"} id={"api-key"} />
                    </Form.Row>
                    <Form.Row>
                      <CheckboxInput
                        label={"Inject orders as ready for delivery?"}
                        name={"inject_orders_as_ready"}
                        id={"inject-orders-as-ready"}
                      />
                    </Form.Row>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default RelayIntegrationModal;
