import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import ReactSelect from "react-select";
import { capitalizeString, generalErrorAlert, generateRandomPin } from "util/Utils";

import BlueOutlineCheckIcon from "assets/svgs/check-icons/icon-blue-outline-check.svg";

const SUPPORTED_CREATE_ACC_INTS = ["toast"];

const MapIntegrationEmployees = (props) => {
  const [employeeMappingOptions, setEmployeeMappingOptions] = useState([]);
  const [selectedRemainingEmployeeIds, setSelectedRemainingEmployeeIds] = useState([]);
  const [employeeMappings, setEmployeeMappings] = useState({});
  const [showExtraEmployees, setShowExtraEmployees] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { show, onHideCallback, onSaveCallback, posIntegration, selectedCustomer, employees, pins } = props;

  const createAccSupported = SUPPORTED_CREATE_ACC_INTS.includes(posIntegration);
  const readablePosIntegration = posIntegration ? capitalizeString(posIntegration.replaceAll("_", " ")) : "";

  useEffect(() => {
    getEmployeeMappings();
  }, [posIntegration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set up the mappings initial state based on employees
    // username -> {employee_name, employee_id}
    mapEmployees(employees, posIntegration);
  }, [employees, posIntegration]);

  const mapEmployees = (employees, posIntegration) => {
    const newMapping = Object.fromEntries(
      employees
        .map((employee) => {
          const integrationInfo = employee.integrations[posIntegration];
          return [
            employee.user.username,
            integrationInfo
              ? {
                  employee_name: integrationInfo.employee_name,
                  employee_id: integrationInfo.employee_id,
                }
              : null,
          ];
        })
        .filter((employeeMapping) => employeeMapping !== null)
    );
    setEmployeeMappings(newMapping);
  };

  const onHide = (refreshMappings = true) => {
    setShowExtraEmployees(false);
    onHideCallback();
    if (refreshMappings) mapEmployees(employees, posIntegration);
  };

  const getEmployeeMappings = async () => {
    if (!selectedCustomer || !posIntegration) return;

    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        external_source: posIntegration,
      };
      const employeeMappingOptionsRes = await axios.get("api/getIntegrationEmployeeMappingOptions", {
        params: payload,
      });
      setEmployeeMappingOptions(employeeMappingOptionsRes.data.employee_mapping_options);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEmployeeMappings = async () => {
    setIsSaving(true);
    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        mappings: employeeMappings,
        pos_integration: posIntegration,
      };
      await axios.post("api/connectIntegrationEmployees", payload);
      const remainingEmployeeIds = employeeMappingOptions
        .filter(
          (employee) =>
            !Object.values(employeeMappings)
              .filter((emp) => emp !== null)
              .map((emp) => emp.employee_id)
              .includes(employee.id)
        )
        .map((employee) => employee.id);

      await onSaveCallback();

      if (createAccSupported) {
        setSelectedRemainingEmployeeIds(remainingEmployeeIds);
        setShowExtraEmployees(true);
      } else {
        onHide(false);
      }
    } catch (error) {
      generalErrorAlert(error);
    }
    setIsSaving(false);
  };

  const createNewAccounts = async () => {
    setIsSaving(true);
    let calculatedPins = new Set();
    const newEmployees = employeeMappingOptions
      .filter((employee) => selectedRemainingEmployeeIds.includes(employee.id))
      .map((employee) => {
        const employeeNames = employee.name.split(" ");
        let newPin = generateRandomPin();
        while (calculatedPins.has(newPin) || pins.has(newPin)) {
          newPin = generateRandomPin();
        }
        calculatedPins.add(newPin);
        return {
          email: employee.email,
          first_name: employeeNames[0],
          last_name: employeeNames[1],
          pin_number: newPin,
          level: "Bartender",
          integration_info: {
            employee_name: employee.name,
            employee_id: employee.id,
          },
        };
      });
    try {
      const payload = {
        pos_integration: posIntegration,
        customer_id: selectedCustomer?.customer_id,
        new_employees: newEmployees,
      };
      await axios.post("api/createIntegrationEmployees", payload);
      await onSaveCallback();
      onHide(false);
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const autoMatchEmployees = () => {
    console.log("matching employees");
    let mappings = { ...employeeMappings };
    employees.forEach((employee) => {
      const employeeMatch = employeeMappingOptions.find(
        (potentialEmployeeMatch) => potentialEmployeeMatch.email === employee.user.email
      );
      if (employeeMatch) {
        mappings[employee.user.username] = {
          employee_name: employeeMatch.name,
          employee_id: employeeMatch.id,
        };
      }
    });
    setEmployeeMappings(mappings);
  };

  const remainingEmployees = employeeMappingOptions.filter(
    (employee) =>
      !Object.values(employeeMappings)
        .filter((emp) => emp !== null)
        .map((emp) => emp.employee_id)
        .includes(employee.id)
  );

  const extraEmployees = () => {
    if (remainingEmployees.length === 0) {
      return (
        <div className={"margin-y-2 finished-icon-container"}>
          <div>
            <img src={BlueOutlineCheckIcon} alt={"Icon"} className={"margin-bottom-1 finished-icon"} />
          </div>
          <div>All {readablePosIntegration} employees have been connected.</div>
        </div>
      );
    }
    return (
      <div>
        <div className={"margin-bottom-2"}>
          Would you like to create new Bbot accounts for the remaining Toast employees?
        </div>
        <div className={"margin-x-2"}>
          <div>
            <input
              type={"checkbox"}
              className={"margin-right-1"}
              defaultChecked
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRemainingEmployeeIds(remainingEmployees.map((employee) => employee.id));
                } else {
                  setSelectedRemainingEmployeeIds([]);
                }
              }}
            />
            <span>{`${selectedRemainingEmployeeIds.length ? "Deselect" : "Select"} All`}</span>
          </div>
          {remainingEmployees.map((employee, index) => (
            <div key={index}>
              <input
                type={"checkbox"}
                className={"margin-right-1"}
                checked={selectedRemainingEmployeeIds.includes(employee.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRemainingEmployeeIds([...selectedRemainingEmployeeIds, employee.id]);
                  } else {
                    const checkedEmployees = selectedRemainingEmployeeIds.filter(
                      (employeeId) => employeeId !== employee.id
                    );
                    setSelectedRemainingEmployeeIds(checkedEmployees);
                  }
                }}
              />
              <span>{employee.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const employeeMapping = () => {
    return (
      <div className={"padding-x-2"}>
        {employees.map((employee, index) => {
          const username = employee.user.username;
          return (
            <div key={index}>
              <div className={"d-flex justify-content-space-between align-items-center margin-bottom-2"}>
                <span>
                  {employee.user.first_name} {employee.user.last_name}
                </span>
                <span style={{ minWidth: "200px" }}>
                  <ReactSelect
                    isClearable
                    className={"react-select-custom-styling"} // Needed for styling
                    classNamePrefix={"react-select"} // Needed for styling
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: state.selectProps.value
                          ? "var(--color-success__regular)"
                          : "var(--color-neutral__line)",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    placeholder={"Select Employee"}
                    options={employeeMappingOptions.map((employee) => ({
                      label: employee.name,
                      value: employee.id,
                    }))}
                    onChange={(option) => {
                      // adding a new connection
                      setEmployeeMappings({
                        ...employeeMappings,
                        [username]: option
                          ? {
                              employee_id: option.value,
                              employee_name: option.label,
                            }
                          : null,
                      });
                    }}
                    value={
                      employeeMappings[username] &&
                      employeeMappings[username].employee_name &&
                      employeeMappings[username].employee_id && {
                        label: employeeMappings[username].employee_name,
                        value: employeeMappings[username].employee_id,
                      }
                    }
                    defaultValue={
                      employeeMappings[username] &&
                      employeeMappings[username].employee_name &&
                      employeeMappings[username].employee_id && {
                        label: employeeMappings[username].employee_name,
                        value: employeeMappings[username].employee_id,
                      }
                    }
                  />
                </span>
              </div>
              {index < employees.length - 1 && <hr />}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={onHide} className={"connect-employees-modal"}>
      <Modal.Header closeButton>
        <Modal.Title>Connect {posIntegration && readablePosIntegration} Employees</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showExtraEmployees ? extraEmployees() : employeeMapping()}
        {/*{extraEmployees()}*/}
      </Modal.Body>
      <Modal.Footer>
        {showExtraEmployees ? (
          remainingEmployees.length ? (
            <>
              <Button size={"sm"} onClick={onHide} disabled={isSaving} className={"margin-right-2"} variant={"light"}>
                Don't Create Accounts
              </Button>
              <Button size={"sm"} onClick={createNewAccounts} disabled={isSaving}>
                {isSaving ? <Spinner animation={"border"} size={"sm"} /> : "Yes, Create Accounts"}
              </Button>
            </>
          ) : (
            <Button variant={"light"} size={"sm"} onClick={onHide}>
              Finish
            </Button>
          )
        ) : (
          <>
            <Button variant={"light"} onClick={onHide} size={"sm"} className={"margin-right-2"}>
              Cancel
            </Button>
            {createAccSupported && (
              <Button onClick={autoMatchEmployees} size={"sm"} className={"margin-right-2"}>
                Auto-match Employees
              </Button>
            )}
            <Button size={"sm"} onClick={saveEmployeeMappings}>
              {isSaving ? <Spinner animation={"border"} size={"sm"} /> : "Save and Continue"}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MapIntegrationEmployees;
