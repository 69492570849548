import React, { useState } from "react";
import axios from "axios";

// Components
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Modal, Alert, Button } from "react-bootstrap";

const BulkOrderDeleter = () => {
  const [show, setShow] = useState(false);
  const [modalValues, setModalValues] = useState({});

  const handleDelete = (minutes) => {
    const deleteRecentOrders = async (minutes) => {
      try {
        const response = await axios.post(`admin/bulkDeleteRecentOrders`, {
          minutes,
        });
        if (response.data["no_orders_detected"]) return toast.warn(response.data.message);
        toast.success(response.data.message);
      } catch (err) {
        toast.error("Sorry, there was a problem and no orders were deleted.");
      }
    };
    deleteRecentOrders(minutes);
    setShow(false);
    setModalValues({});
  };

  const ConfirmationModal = ({ show, setShow, modalValues, handleDelete }) => {
    return (
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alert className="d-inline-block" variant={"danger"}>
            Are you sure you want to permanently delete all orders in the last {modalValues.minutes} minutes?{" "}
            <strong>There will be no way to undo this action!</strong>
          </Alert>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDelete(modalValues.minutes);
            }}
          >
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="shadow border border-secondary padding-3 bg-light rounded">
      <h3 className="my-3">Bulk Delete Orders:</h3>
      <Alert className="d-inline-block" variant={"danger"}>
        <strong>Warning:</strong> This action deletes <strong>all orders</strong> across <strong>all customers</strong>{" "}
        and is permanently destructive.
      </Alert>
      <Formik
        initialValues={{ minutes: 0 }}
        validate={(values) => {
          const errors = {};
          if (!values.minutes) {
            errors.minutes = "Required";
          } else if (values.minutes > 180) {
            errors.minutes = "Max 180 (3hrs)";
          } else if (values.minutes < 0) {
            errors.minutes = "Must use positive number.";
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          setShow(true);
          setModalValues(values);
          resetForm({});
        }}
      >
        {({ isSubmitting }) => (
          <Form className="col-xs-2 form-inline">
            <label className="margin-right-2" htmlFor="minutes">
              Minutes in past:
            </label>
            <Field className="form-control mr-3" id="minutesInPast" name="minutes" type="number" />
            <ErrorMessage name="minutes" component="div" />
            <button className="btn btn-secondary m-3" type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <ConfirmationModal show={show} setShow={setShow} modalValues={modalValues} handleDelete={handleDelete} />
    </div>
  );
};

export default BulkOrderDeleter;
