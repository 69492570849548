import _ from "lodash";
/*
Functions used for generating multi select options for common fields, such as fulfillment methods, menu tags, etc.
 */

/*
Takes an input of dictionary with key: fulfillmentMethodBackendName value: fulfillmentMethodPrettyName
 Can be generated in the backend by calling dbmodels.FULFILLMENT_METHOD_PRETTY_NAMES
*/
export const getFulfillmentMethodChoices = (fulfillmentMethods) => {
  let fulfillmentMethodChoices = [];
  if (fulfillmentMethods != null) {
    fulfillmentMethodChoices = Object.keys(fulfillmentMethods).map((fulfillmentMethodKey) => ({
      value: fulfillmentMethodKey,
      label: fulfillmentMethods[fulfillmentMethodKey],
    }));
  }

  fulfillmentMethodChoices = _.sortBy(fulfillmentMethodChoices, "label");
  return fulfillmentMethodChoices;
};

export const getSelectedOptions = (getOptionsFunc, selectedOptionsValues) => {
  return getOptionsFunc().filter((o) => selectedOptionsValues.includes(o.value));
};
