import React, { useEffect, useState } from "react";
import axios from "axios";

// Components
import { Accordion, Card, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";

// Styles
import "./OrderStatusControls.scss";

import ChevronIcon from "assets/svgs/icons/icon-chevron.svg";

const OrderStatusControls = ({ selectedCustomer }) => {
  const [foundOrders, setFoundOrders] = useState([]);
  const [fulfillmentMethodStatuses, setFulfillmentMethodStatuses] = useState({});

  useEffect(() => {
    if (selectedCustomer) {
      const fetchPossibleStatuses = async (customer_id) => {
        try {
          const response = await axios.get("/api/getCustomerFulfillmentMethods/", { customer_id });
          setFulfillmentMethodStatuses(response.data);
          setFoundOrders([]);
        } catch (err) {
          toast.error("Had trouble obtaining all fulfillment methods.");
        }
      };
      fetchPossibleStatuses();
    }
  }, [selectedCustomer]);

  const _getLowestStatusInOrder = (order, possibleStatuses) => {
    const { items, fulfillment_method } = order;

    // Creates an array of the index of each order's status in possible statuses, then return the lowest (integer).
    let lowestStatusIdx = Math.min(...items.map((item) => possibleStatuses[fulfillment_method].indexOf(item.status)));

    // Using the index returned above to lookup in the possibleStatus array, sets variable to the lowest status found in entire order(string).
    let lowestStatusInOrder = possibleStatuses[fulfillment_method][lowestStatusIdx];
    return lowestStatusInOrder; //string
  };

  const OrderCard = ({ order, availableStatuses, availablePrettyNames }) => {
    const [currentStatus, setCurrentStatus] = useState(_getLowestStatusInOrder(order, availableStatuses));
    const [isExpanded, setIsExpanded] = useState(false);

    const onStatusChange = (newStatus, orderItems) => {
      let data = {
        customerId: selectedCustomer.customer_id,
        orderItems: orderItems,
        status: newStatus,
      };
      const sendNewStatus = async (data) => {
        try {
          const response = await axios.post("admin/devtoolSetOrderStatus", data);
          const { new_status } = response.data;
          setCurrentStatus(new_status);
        } catch (err) {
          toast.error("Something went wrong");
          console.warn(err);
        }
      };
      sendNewStatus(data);
    };

    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={order.orderId}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <b>{order.orderNumberWithPrefix}</b>
          <span>Current Status: {availablePrettyNames[currentStatus]}</span>
          <img className={"icon small " + (isExpanded && "rotate90")} src={ChevronIcon} alt={"chevron"} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={order.orderId}>
          <Card.Body>
            <p className="font-weight-bold">
              Total: <span className="font-weight-normal">{order.totalPrice / 100}</span>
            </p>
            <p className="font-weight-bold">
              Location: <span className="font-weight-normal">{order.locationShortCode}</span>
            </p>
            <p className="font-weight-bold">
              Order Time: <span className="font-weight-normal">{order.time}</span>
            </p>
            <p className="font-weight-bold">Items: </p>
            <ul>
              {order.items.map((item) => {
                return <li key={item.orderitemid}>{item.name_when_purchased}</li>;
              })}
            </ul>
            <p className="font-weight-bold">Status: </p>
            <ButtonGroup toggle>
              {availableStatuses[order.fulfillment_method].map((status, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="secondary"
                  name="status"
                  value={status}
                  checked={currentStatus === status}
                  onChange={() => {
                    onStatusChange(status, order.items);
                  }}
                  className="statusButton"
                >
                  {availablePrettyNames[status]}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const OrderList = ({ orders, fulfillmentMethodStatuses }) => {
    const availableStatuses = fulfillmentMethodStatuses.fulfillment_methods;
    const availablePrettyNames = fulfillmentMethodStatuses.status_pretty_names;

    return (
      <div>
        <Accordion style={{ maxHeight: 600, overflow: "auto" }} className="border border-secondary rounded">
          {orders &&
            orders.map((o) => (
              <OrderCard
                order={o}
                availableStatuses={availableStatuses}
                availablePrettyNames={availablePrettyNames}
                key={o.orderId}
              />
            ))}
        </Accordion>
      </div>
    );
  };

  return (
    <div className="shadow border border-secondary padding-3 bg-light rounded">
      <h3 className="my-3">Order Status Control:</h3>
      <Formik
        initialValues={{
          days_in_past: null,
          order_number: null,
          last4: "",
          exp_month: null,
          exp_year: null,
        }}
        validate={(values) => {
          const errors = {};
          if (values.days_in_past < 0 || values.days_in_past > 1000) {
            errors.days_in_past = "Must be between 1-1000.";
          }
          if (values.order_number < 0 || values.order_number > 10000) {
            errors.order_number = "Must be between 1-10000.";
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const response = await axios.get("/api/findReceiptsFromOwner/", {
              params: values,
            });
            setFoundOrders(response.data.orders);
            resetForm({});
          } catch (err) {
            toast.error("Sorry, there was an issue finding the orders.");
          }
        }}
      >
        {({ values }) => (
          <Form className="styled-form header-search-bar margin-bottom-2 form-inline">
            <label htmlFor="days_in_past">Days in past:</label>
            <Field
              className="form-control"
              id="daysSearchField"
              name="days_in_past"
              value={values.days_in_past || ""}
              type="number"
              placeholder="1"
            />
            <ErrorMessage name="days_in_past" component="div" />
            <label htmlFor="order_number">Order Number:</label>
            <Field
              className="form-control"
              id="orderNumberField"
              name="order_number"
              value={values.order_number || ""}
              type="number"
              placeholder="105"
            />
            <ErrorMessage name="order_number" component="div" />
            <Button className="margin-right-1" type="submit" variant="primary">
              Search
            </Button>
            <Button
              onClick={() => {
                setFoundOrders([]);
              }}
              variant="secondary"
            >
              Hide Results
            </Button>
          </Form>
        )}
      </Formik>
      {foundOrders.length ? (
        <OrderList orders={foundOrders} fulfillmentMethodStatuses={fulfillmentMethodStatuses} />
      ) : null}
    </div>
  );
};

export default OrderStatusControls;
