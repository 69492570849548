import React, { useState, useEffect } from "react";
import { BbotTable, Input, Breadcrumbs, BbotContainer, Button } from "../../bbot-component-library";
import axios from "axios";
import { generalErrorAlert } from "util/Utils";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NewCustomerModal from "components/owner-app/modals/prebot-new-customer/NewCustomerModal";

const JourneyChooser = (props) => {
  const [journeys, setJourneys] = useState([]);
  const [possibleReferrers, setPossibleReferrers] = useState([]);
  const [partnerShowSalesforceId, setPartnerShowSalesforceId] = useState([]);
  const [isLoadingJourneys, setIsLoadingJourneys] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [localesByCurrency, setLocalesByCurrency] = useState({});

  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);

  // Table
  const [searchInputText, setSearchInputText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [numJourneys, setNumJourneys] = useState(0);

  // HOOKS
  const history = useHistory();

  const { userInfo, selectedCustomer } = props;

  useEffect(() => {
    getJourneys(1, 10);
    getPossibleReferrers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedCustomer?.customer_id) getShouldShowSalesforceId();
  }, [selectedCustomer?.customer_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getJourneys = async (page = 1, pageSize = 10) => {
    setIsLoadingJourneys(true);
    try {
      const res = await axios.get("/api/journey/getJourneys", {
        params: {
          current_page: page,
          page_size: pageSize,
          search_text: searchInputText,
        },
      });
      setJourneys(res.data.journeys);
      setNumJourneys(res.data.num_journeys);
    } catch (error) {
      generalErrorAlert(error);
    }
    setIsLoadingJourneys(false);
  };

  const getPossibleReferrers = async () => {
    try {
      const res = await axios.get("/api/journey/getPossibleReferrersAndCurrencies");
      setPossibleReferrers(res.data.possible_referrers);
      setCurrencies(res.data.supported_currencies);
      setLocalesByCurrency(res.data.currency_locales);
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const getShouldShowSalesforceId = async () => {
    const { selectedCustomer, userInfo } = props;

    const payload = {
      customerId: selectedCustomer?.customer_id,
      teams: userInfo?.teams,
    };

    try {
      const res = await axios.get("/owner/getPartnersForAdminOrOwner", {
        params: payload,
      });
      const partners = res.data.partners;
      const shouldShowSalesforceId = partners.some((partner) => partner.show_salesforce_id);
      setPartnerShowSalesforceId(shouldShowSalesforceId);
    } catch (error) {
      console.error(error);
    }
  };

  const onTableChange = (pagination, filters, sorter) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
      setPageSize(pagination.pageSize);
    }
    getJourneys(pagination.current, pagination.pageSize);
  };

  const journeyTable = () => {
    const journeyData = journeys.map((journey) => {
      return {
        customer_name: journey.customer_name,
        date_started: DateTime.fromISO(journey.time_created).toLocaleString(DateTime.DATE_SHORT),
        tasks_complete: `${journey.tasks_completed}/${journey.total_tasks}`,
        journey_id: journey.id,
      };
    });

    return (
      <BbotTable
        interactive={userInfo?.role === "admin"}
        id={"journeys-table"}
        columns={["Customer Name", "Date Started", "Tasks Complete"]}
        data={journeyData}
        onAdd={userInfo?.role === "admin" ? () => setShowNewCustomerModal(true) : null}
        addButtonText={"Create New Customer Account"}
        pagination={{
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageSize,
          total: numJourneys,
        }}
        loading={isLoadingJourneys}
        onChange={onTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push("/journey/" + record.journey_id);
            },
          };
        }}
      />
    );
  };

  return (
    <BbotContainer>
      <Breadcrumbs name={"PreBot"} link={"/myjourneys"} />

      <div className={"margin-bottom-2"}>
        <h2>PreBot - Bbot's Onboarding Helper</h2>
      </div>
      <div className={"margin-bottom-4"}>
        <p>
          Here, you can find active onboardings. Each onboarding consists of a list of tasks to complete. <br />
          When all the tasks are completed, the onboarding is finished!
        </p>
      </div>

      <SearchInput
        allowClear
        className={"margin-bottom-2"}
        enterButton={
          <Button data-test-id="search-journey-btn" style={{ color: "white" }}>
            Search
          </Button>
        }
        onSearch={() => {
          setCurrentPage(1);
          getJourneys(1, pageSize);
        }}
        onChange={(e) => setSearchInputText(e.target.value)}
        data-test-id="search-journey-input"
      />

      {journeyTable()}
      {showNewCustomerModal && (
        <NewCustomerModal
          currencies={currencies}
          localesByCurrency={localesByCurrency}
          referrers={possibleReferrers}
          onClose={() => setShowNewCustomerModal(false)}
        />
      )}
    </BbotContainer>
  );
};

export default JourneyChooser;

const SearchInput = styled(Input.Search)`
  max-width: 600px;
  .ant-input-search-button {
    background-color: var(--color-primary__regular);
  }
`;
