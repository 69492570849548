import React from "react";
import { Field } from "formik";
import { Button } from "react-bootstrap";

const SelectLocationsInput = ({
  locationsById,
  fulfillmentMethodsPrettyNames,
  allowedCustomersById,
  values,
  setFieldValue,
  showServiceRequestFlag = false,
}) => {
  const getCustomerName = (customerId) => {
    const customer = allowedCustomersById[customerId];
    return customer?.name_for_admin;
  };

  return (
    <div className={"padding-x-1 locations-list"}>
      {Object.keys(locationsById).map((customerId, index) => (
        <div key={customerId}>
          <div className={"customer-name"}>{getCustomerName(customerId)}</div>
          <div className={"customer-locations-group"}>
            {Object.keys(locationsById[customerId]).map((fulfillmentMethod) => (
              <div key={fulfillmentMethod}>
                <div className={"fulfillment-method"}>{fulfillmentMethodsPrettyNames[fulfillmentMethod]}</div>
                <div className={"fulfillment-method-locations-group"}>
                  <Button
                    variant={"link"}
                    onClick={() => {
                      let locations = values.specific_locations_ids;
                      const newLocations = locationsById[customerId][fulfillmentMethod];

                      newLocations.forEach((location) => {
                        if (!locations.includes(location.locationId)) {
                          locations.push(location.locationId);
                        }
                      });

                      setFieldValue("specific_locations_ids", locations);
                    }}
                  >
                    Select All {fulfillmentMethodsPrettyNames[fulfillmentMethod]}
                  </Button>
                  {locationsById[customerId][fulfillmentMethod].map((location) => (
                    <div className={"field-wrapper"} key={location.locationId}>
                      <label className={"d-flex align-items-center"}>
                        <Field
                          type={"checkbox"}
                          name={"specific_locations_ids"}
                          id={"location-" + location.locationId}
                          value={location.locationId}
                        />
                        <span>{location.locationName}</span>
                        {showServiceRequestFlag && !location.allow_service_requests && (
                          <span className={"margin-left-1"} style={{ fontStyle: "italic", fontSize: 12 }}>
                            (Service requests currently off)
                          </span>
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectLocationsInput;
