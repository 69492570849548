import { Form, Input } from "bbot-component-library";
import React from "react";

const URLInput = ({ id, label = "", name = "", required = true, validationMessage }) => {
  return (
    <Form.Item
      id={id}
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: validationMessage || "This is a required field",
          transform: (value) => value?.trim(),
        },
        {
          pattern: new RegExp(/^(ftp|http|https):\/\/[^ "]+$/),
          message: "This must be a valid URL",
        },
      ]}
      validateTrigger={["onBlur", "onChange"]}
    >
      <Input type={"text"} />
    </Form.Item>
  );
};

export default URLInput;
