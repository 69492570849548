import { Form, Select } from "bbot-component-library";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*
Dropdown input; supports multi-select and type-ahead
 */
const SelectInput = ({
  id,
  label = "",
  name = "",
  required = true,
  validationMessage,
  options,
  multiSelect = false,
  typeAhead = false,
  dataTestId = null,
  ...props
}) => {
  return (
    <Form.Item
      id={id}
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: validationMessage || "This is a required field",
        },
      ]}
      data-test-id={dataTestId}
    >
      <StyledSelect
        mode={multiSelect ? "multiple" : null}
        showSearch={typeAhead}
        filterOption={
          typeAhead ? (input, option) => option.children.toLowerCase().includes(input.toLowerCase()) : () => void 0
        }
        {...props}
      >
        {options?.map((option, i) => (
          <Select.Option key={i} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </StyledSelect>
    </Form.Item>
  );
};

const StyledSelect = styled(Select)`
  &&& {
    .ant-select-selector {
      padding: 4px 8px;
      min-height: 40px;
      border-radius: 8px;
    }
  }
`;

export default SelectInput;

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  validationMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  typeAhead: PropTypes.bool,
};
