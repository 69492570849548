import { Form as Btpform, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CheckboxInput, DateInput, SearchSelectInput, TextInput } from "../index";
import React from "react";
import { Form } from "react-bootstrap";
import RadioGroupInput from "../RadioGroupInput";
import SearchSelectInputUtils from "../SearchSelectInputUtils";
import { DateTime } from "luxon";
import { getFulfillmentMethodChoices } from "../form-utils/CommonMultiSelectOptionUtils";

class OrderFilterInputs extends React.Component {
  async componentDidMount() {
    this.setState({
      locationChoices: this.getLocationChoices(),
      previouslySelectedVendors: this.props.selectedVendors,
    });
  }

  getVendorChoices = (allVendors) => {
    let vendorChoices = allVendors.map((ven) => ({
      value: ven.customer_id,
      label: ven.name_for_owner,
    }));

    vendorChoices.sort((a, b) => (a.label > b.label ? 1 : -1));
    return vendorChoices;
  };

  //Location choices have to change based on what external vendors are selected.
  updateSelectedVendors = (setFieldValue, selectedVendors) => {
    const { previouslySelectedVendors } = this.state;

    if (selectedVendors.length < previouslySelectedVendors.length) {
      this.props.disconnectVendorWarning(
        selectedVendors,
        this.confirmSelectedVendorsChange,
        this.cancelSelectedVendorsChange
      );
    } else {
      this.confirmSelectedVendorsChange(setFieldValue, selectedVendors);
    }
  };

  confirmSelectedVendorsChange = (setFieldValue, selectedVendors) => {
    setFieldValue("selectedVendors", selectedVendors).then(() =>
      this.setState({
        locationChoices: this.getLocationChoices(),
        previouslySelectedVendors: selectedVendors,
      })
    );
  };

  cancelSelectedVendorsChange = (setFieldValue) => {
    setFieldValue("selectedVendors", this.state.previouslySelectedVendors);
    setFieldValue("selectedVendorsKey", "selectedVendorsKey" + DateTime.now().toString());
  };

  getLocationChoices = () => {
    let locationChoices = [];

    if (this?.props?.allLocations != null) {
      let locationsDict = this.props.allLocations;
      let necessaryVendors = this.props.getNecessaryVendors(
        SearchSelectInputUtils.getValuesFromMultiSelect(this.props.selectedVendors)
      );

      Object.keys(this.props.allLocations)
        .filter((cus) => necessaryVendors.includes(cus))
        .map((cus) =>
          locationsDict[cus].map((loc) =>
            locationChoices.push({
              value: loc.locationId,
              label: loc.locationName,
            })
          )
        );
    }

    locationChoices.sort((a, b) => (a.label > b.label ? 1 : -1));
    return locationChoices;
  };

  render() {
    const {
      setFieldValue,
      allVendors,
      selectedLocations,
      allFulfillmentMethods,
      selectedFulfillmentMethods,
      selectDaysAndTimes,
      specificLocations,
      selectedVendors,
      selectedLocationsKey,
      justSpecificDates,
      startDate,
      endDate,
      selectedVendorsKey,
      daysOfWeek = [
        {
          id: "mondaySchedule",
          name: "mondaySchedule",
          label: "Monday",
          value: this.props.mondaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "tuesdaySchedule",
          name: "tuesdaySchedule",
          label: "Tuesday",
          value: this.props.tuesdaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "wednesdaySchedule",
          name: "wednesdaySchedule",
          label: "Wednesday",
          value: this.props.wednesdaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "thursdaySchedule",
          name: "thursdaySchedule",
          label: "Thursday",
          value: this.props.thursdaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "fridaySchedule",
          name: "fridaySchedule",
          label: "Friday",
          value: this.props.fridaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "saturdaySchedule",
          name: "saturdaySchedule",
          label: "Saturday",
          value: this.props.saturdaySchedule,
          placeholder: "11AM-2AM",
        },
        {
          id: "sundaySchedule",
          name: "sundaySchedule",
          label: "Sunday",
          value: this.props.sundaySchedule,
          placeholder: "11AM-2AM",
        },
      ],
    } = this.props;

    return (
      <Form.Group>
        {allVendors.length > 0 && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              name={"selectedVendors"}
              id={"selectedVendors"}
              md={12}
              isMulti
              closeMenuOnSelect={false}
              label={"Vendors"}
              options={this.getVendorChoices(allVendors)}
              placeholder={"None"}
              value={selectedVendors}
              key={selectedVendorsKey}
              defaultValue={selectedVendors}
              onChange={(value) => this.updateSelectedVendors(setFieldValue, value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row className="margin-bottom-2">
          <RadioGroupInput
            options={[
              { label: "Just specific location codes", value: true },
              {
                label: "All location codes",
                value: false,
              },
            ]}
            id={"specificLocations"}
            name={"specificLocations"}
            value={specificLocations}
            selectedValue={specificLocations}
            label={"Where should this Order Grouping Rule apply?"}
            className={"enable-locations-radio-buttons"}
            onChange={(value) => setFieldValue("specificLocations", value === "true")}
          />
        </Btpform.Row>
        {specificLocations === true && (
          <Btpform.Row className="margin-bottom-2">
            <SearchSelectInput
              md={12}
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              className={"margin-bottom-0"}
              id={"selectedLocations"}
              name={"selectedLocations"}
              label={"Select Location Codes"}
              options={this?.state?.locationChoices}
              placeholder={"Choose Location Codes"}
              key={selectedLocationsKey}
              value={selectedLocations}
              defaultValue={selectedLocations}
              onChange={(value) => setFieldValue("selectedLocations", value)}
            />
          </Btpform.Row>
        )}
        <Btpform.Row className="margin-bottom-4">
          <SearchSelectInput
            md={12}
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            className={"margin-bottom-0"}
            id={"fulfillment_methods"}
            name={"fulfillment_methods"}
            label={"Select Fulfillment Methods"}
            options={getFulfillmentMethodChoices(allFulfillmentMethods)}
            placeholder={"All Fulfillment Methods"}
            value={selectedFulfillmentMethods}
            defaultValue={selectedFulfillmentMethods}
            onChange={(value) => setFieldValue("selectedFulfillmentMethods", value)}
          />
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-3">
          <SearchSelectInput
            md={12}
            label={[
              "Select Weekly Schedule",
              "  ",
              <OverlayTrigger
                key={"placement"}
                placement={"right"}
                overlay={
                  <Tooltip id={"tooltip-select-days-and-times"}>
                    <strong>Formatting</strong>
                    <br />
                    Type 12AM-12AM if open all day.
                    <br />
                    Type CLOSED if closed all day.
                    <br />
                    VALID EXAMPLES:
                    <br />
                    9AM - 2PM, 4PM - 2AM
                    <br />
                    9AM - 2:30AM
                    <br />
                    8:15AM - 2:15AM
                    <br />
                  </Tooltip>
                }
              >
                <div className="zmdi zmdi-info-outline dashboard-link-icon" />
              </OverlayTrigger>,
            ]}
            id={"selectDaysAndTimes"}
            name={"selectDaysAndTimes"}
            options={[
              { label: "All Days and Times", value: false },
              { label: "Selected Days and Times", value: true },
            ]}
            value={selectDaysAndTimes}
            onChange={(value) => setFieldValue("selectDaysAndTimes", value)}
            placeholder={"Always Active"}
            defaultValue={selectDaysAndTimes}
          />
        </Btpform.Row>
        {selectDaysAndTimes?.value === true &&
          daysOfWeek.map((day) => (
            <Btpform.Row id={"row " + day.id} key={"row " + day.id} className="margin-bottom-2">
              <TextInput
                md={12}
                label={day.label}
                id={day.id}
                key={day.id}
                name={day.name}
                placeholder={day.placeholder}
                value={day.value}
              />
            </Btpform.Row>
          ))}
        <Btpform.Row className="margin-bottom-2">
          <CheckboxInput
            id={"justSpecificDates"}
            md={12}
            name={"justSpecificDates"}
            value={justSpecificDates}
            label={"Just Specific Dates"}
            className={"justSpecificDatesCheckBox"}
            onChange={(value) => setFieldValue("justSpecificDates", value)}
          />
        </Btpform.Row>
        {justSpecificDates && (
          <Btpform.Row className="margin-bottom-3">
            <DateInput
              md={6}
              name={"startDate"}
              id={"startDate"}
              label={"Start Date"}
              placeholder={"Indefinite"}
              value={startDate}
              onChange={(value) => setFieldValue("startDate", value)}
            />
            <DateInput
              md={6}
              name={"endDate"}
              id={"endDate"}
              label={"End Date"}
              placeholder={"No End Date"}
              value={endDate}
              onChange={(value) => setFieldValue("endDate", value)}
            />
          </Btpform.Row>
        )}
      </Form.Group>
    );
  }
}

export default OrderFilterInputs;
