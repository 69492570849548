import axios from "axios";
import React, { useState } from "react";
import PropTypes from "prop-types";
import "components/owner-app/modals/printer-configuration/NetworkConfigModals.scss";
import { BbotButton, Form, BbotModal, TextInput } from "bbot-component-library";
import { notification } from "antd";

const AddEditNetworkModal = ({
  showAddEditNetworkModal,
  selectedCustomer,
  onSaveCallback,
  onHideCallback,
  network,
  userInfo,
}) => {
  const [editNetworkForm] = Form.useForm();
  const [isSavingNetwork, setIsSavingNetwork] = useState(false);
  const [, setFormValues] = useState({});

  const editMode = !!network?.id;

  const addEditNetwork = async (values) => {
    try {
      setIsSavingNetwork(true);
      if (!selectedCustomer) {
        notification.error({ message: "No customer session found, please refresh and try again." });
        setIsSavingNetwork(false);
        return;
      } else if (!values.networkName) {
        notification.error({ message: "Please provide a name for the new network." });
        setIsSavingNetwork(false);
        return;
      }
      const payload = {
        network_id: editMode === true ? network.id : null,
        network_name: values.networkName,
        customer_id: selectedCustomer?.customer_id,
      };

      if (editMode) {
        await axios.put("/printer-setup/network-subnets", payload);
        notification.success({ message: "Successfully edited " + values.networkName });
      } else {
        await axios.post("/printer-setup/network-subnets", payload);
        notification.success({
          message: "Successfully added " + values.networkName + " to " + selectedCustomer.customer_name,
        });
      }

      setIsSavingNetwork(false);
      onSaveCallback();
    } catch (error) {
      setIsSavingNetwork(false);
      notification.error({ message: "Trouble connecting to the server. Please refresh the page and try again." });
      console.error(error);
    }
  };

  const removeNetwork = async () => {
    try {
      setIsSavingNetwork(true);
      if (!selectedCustomer || !network) {
        notification.error({ message: "Error deleting Network, please refresh and try again." });
        return;
      }
      const payload = {
        network_id: network.id,
        customer_id: selectedCustomer.customer_id,
      };
      let res = await axios.post("/printer-setup/remove-network-subnet", payload);
      notification.success({ message: res.data.message });
      setIsSavingNetwork(false);
      onSaveCallback();
    } catch (error) {
      setIsSavingNetwork(false);
      notification.error({ message: "Trouble connecting to the server. Please refresh the page and try again." });
      console.error(error);
    }
  };

  const modalFooter = () => {
    return (
      <>
        {editMode && userInfo.role === "admin" && (
          <BbotButton
            danger
            onClick={() => {
              removeNetwork();
            }}
          >
            {selectedCustomer.customer_id === network.managing_customer_id
              ? "Delete Network"
              : "Remove Customer from this Subnet"}
          </BbotButton>
        )}
        {(!editMode || (editMode && selectedCustomer.customer_id === network.managing_customer_id)) && (
          <BbotButton
            type={"primary"}
            onClick={() => {
              editNetworkForm.submit();
            }}
          >
            Save
          </BbotButton>
        )}
      </>
    );
  };

  return (
    <BbotModal
      title={editMode === true ? "Edit " + network?.name : "Add a New Network"}
      visible={showAddEditNetworkModal}
      cancelText={"Stop it"}
      onCancel={() => onHideCallback()}
      onOk={editNetworkForm.submit}
      okText={"Save"}
      okButtonProps={{
        loading: isSavingNetwork,
      }}
      footer={modalFooter()}
    >
      {!editMode || selectedCustomer?.customer_id === network?.managing_customer_id ? (
        <Form
          form={editNetworkForm}
          name="edit-network-form"
          onFinish={addEditNetwork}
          layout={"vertical"}
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required",
          }}
          initialValues={{
            networkName: network?.name || selectedCustomer?.customer_name + " New Network",
          }}
          onValuesChange={(_, values) => setFormValues(values)}
        >
          <div>
            <TextInput name={"networkName"} label={"Network Name"} required={true} />
          </div>
        </Form>
      ) : (
        <div>
          <p>
            Only the managing Customer of this Network can make edits or delete it. Removing this Customer from the
            Network will delete/remove all of it's printers, Control Boxes, and Devices from this Network. Other edits
            must be made by <b>{network.managing_customer_name}</b>
          </p>
        </div>
      )}
    </BbotModal>
  );
};

AddEditNetworkModal.propTypes = {
  onHideCallback: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  showAddEditNetworkModal: PropTypes.bool.isRequired,
  selectedCustomer: PropTypes.object,
};
export default AddEditNetworkModal;
