import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { capitalizeString, generalErrorAlert } from "util/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { BbotModal, BbotButton } from "bbot-component-library";

const TableMappingModal = (props) => {
  const [locationIntegrations, setLocationIntegrations] = useState({});
  const [mappings, setMappings] = useState({});
  const [tableMappingOptions, setTableMappingOptions] = useState([]);
  const [employeeMappingOptions, setEmployeeMappingOptions] = useState([]);

  // Omnivore 3 only
  const [revenueCenterMappingOptions, setRevenueCenterMappingOptions] = useState([]);
  const [orderTypeMappingOptions, setOrderTypeMappingOptions] = useState([]);
  const [itemOrderModeMappingOptions, setItemOrderModeMappingOptions] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isAutoPopulating, setIsAutoPopulating] = useState(false);

  const { show, onHideCallback, onSaveCallback, locations, selectedCustomer, posIntegration } = props;

  useEffect(() => {
    getMappingOptions(); // depends on customer_id, external_source
    if (posIntegration === "omnivore_3") {
      getOmnivore3MappingOptions();
    }
  }, [posIntegration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocationIntegrations(
      Object.fromEntries(locations.map((location) => [location.locationId, location.integrations]))
    );
  }, [locations]);

  const onHide = (resetMappings = true) => {
    onHideCallback();
    if (resetMappings) {
      setMappings({});
      setLocationIntegrations(
        Object.fromEntries(locations.map((location) => [location.locationId, location.integrations]))
      );
    }
  };

  const getMappingOptions = async () => {
    if (!selectedCustomer || !posIntegration) return;

    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        external_source: posIntegration,
      };
      const tableMappingOptionsRes = await axios.get("api/getIntegrationTableMappingOptions", {
        params: payload,
      });
      setTableMappingOptions(tableMappingOptionsRes.data.table_mapping_options);
      const employeeMappingOptionsRes = await axios.get("api/getIntegrationEmployeeMappingOptions", {
        params: payload,
      });
      setEmployeeMappingOptions(employeeMappingOptionsRes.data.employee_mapping_options);
    } catch (error) {
      console.error(error);
    }
  };

  const getOmnivore3MappingOptions = async () => {
    if (!selectedCustomer || !posIntegration) return;

    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        external_source: posIntegration,
      };
      const revenueCenters = await axios.get("api/getIntegrationRevenueCenters", {
        params: payload,
      });
      setRevenueCenterMappingOptions(revenueCenters.data.revenue_centers);

      const orderTypesOptions = await axios.get("api/getIntegrationOrderTypes", {
        params: payload,
      });
      setOrderTypeMappingOptions(orderTypesOptions.data.order_types);

      const orderModeOptions = await axios.get("api/getIntegrationItemOrderModes", {
        params: payload,
      });
      setItemOrderModeMappingOptions(orderModeOptions.data.item_order_modes);
    } catch (error) {
      console.error(error);
    }
  };

  const removeAllMappings = () => {
    // remove all mappings
    if (locations.length < 1) return;

    // get the integration properties to set
    let integrationProperties = [
      `${posIntegration}_employee_guid`,
      `${posIntegration}_employee_name`,
      `${posIntegration}_table_guid`,
      `${posIntegration}_table_name`,
    ];

    if (posIntegration === "omnivore_3") {
      // extra integration fields for omnivore_3
      integrationProperties.push(
        "omnivore_3_order_type_guid",
        "omnivore_3_order_type_name",
        "omnivore_3_revenue_center_guid",
        "omnivore_3_revenue_center_name",
        "omnivore_3_order_mode_guid",
        "omnivore_3_order_mode_name"
      );
    }

    // set the mappings to "remove"
    setMappings(
      Object.fromEntries(
        locations.map((location) => {
          const locationMapping = Object.fromEntries(integrationProperties.map((property) => [property, "remove"]));
          return [location.locationId, locationMapping];
        })
      )
    );

    // set the state of the location integrations
    setLocationIntegrations(
      Object.fromEntries(
        locations.map((location) => {
          const locationMapping = Object.fromEntries(integrationProperties.map((property) => [property, ""]));
          return [location.locationId, locationMapping];
        })
      )
    );
  };

  const autoPopulateMappings = async () => {
    setIsAutoPopulating(true);
    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        external_source: posIntegration,
      };
      const res = await axios.get("owner/getAutoTableMappings", {
        params: payload,
      });
      const tableMappings = Object.entries(res.data.autoTableMappings);
      let newIntegrations = { ...locationIntegrations };
      let newMappings = { ...mappings };
      for (let i = 0; i < tableMappings.length; i++) {
        const [locationId, tableId] = tableMappings[i];
        const table = tableMappingOptions.find((table) => table.id === tableId);
        newIntegrations[locationId] = {
          ...newIntegrations[locationId],
          [`${posIntegration}_table_guid`]: tableId,
          [`${posIntegration}_table_name`]: table?.name,
        };
        newMappings[locationId] = {
          ...newIntegrations[locationId],
          [`${posIntegration}_table_guid`]: tableId,
          [`${posIntegration}_table_name`]: table?.name,
        };
      }
      setLocationIntegrations(newIntegrations);
      setMappings(newMappings);
    } catch (error) {
      console.error(error);
      toast.error("Could not auto populate mappings.");
    }
    setIsAutoPopulating(false);
  };

  const saveMappings = async () => {
    setIsSaving(true);
    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        ...mappings,
      };
      await axios.post("/owner/updateLocationTableMappings", payload);
      onSaveCallback();
      onHide(false);
    } catch (error) {
      generalErrorAlert(error);
    }
    setIsSaving(false);
  };

  /**
   * Returns ReactSelect component for code reuse.
   * @param location
   * @param fieldName
   * @param options
   * @param placeHolder
   */
  const selectField = (location, fieldName, options, placeHolder = "") => {
    return (
      <ReactSelect
        isClearable
        className={"react-select-custom-styling"} // Needed for styling
        classNamePrefix={"react-select"} // Needed for styling
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.value ? "var(--color-success__regular)" : "var(--color-neutral__line)",
          }),
        }}
        menuPortalTarget={document.body}
        placeholder={placeHolder}
        options={options.map((tmo) => ({
          label: tmo.name,
          value: tmo.id,
        }))}
        onChange={(option) => {
          // change the state
          setLocationIntegrations({
            ...locationIntegrations,
            [location.locationId]: {
              ...locationIntegrations[location.locationId],
              [`${fieldName}_guid`]: option ? option.value : "",
              [`${fieldName}_name`]: option ? option.label : "",
            },
          });
          // if this location's settings have not been changed yet, set the original values for it, then add to the changes object.
          const originalSettings = Object.keys(mappings).includes(location.locationId) ? {} : location.integrations;
          setMappings({
            ...mappings,
            [location.locationId]: {
              ...originalSettings,
              ...mappings[location.locationId],
              [`${fieldName}_guid`]: option ? option.value : "remove",
              [`${fieldName}_name`]: option ? option.label : "remove",
            },
          });
        }}
        value={
          locationIntegrations[location.locationId] &&
          locationIntegrations[location.locationId][`${fieldName}_guid`] &&
          locationIntegrations[location.locationId][`${fieldName}_name`] && {
            label: locationIntegrations[location.locationId][`${fieldName}_name`],
            value: locationIntegrations[location.locationId][`${fieldName}_guid`],
          }
        }
        defaultValue={
          locationIntegrations[location.locationId] &&
          locationIntegrations[location.locationId][`${fieldName}_guid`] &&
          locationIntegrations[location.locationId][`${fieldName}_name`] && {
            label: locationIntegrations[location.locationId][`${fieldName}_name`],
            value: locationIntegrations[location.locationId][`${fieldName}_guid`],
          }
        }
      />
    );
  };

  const omnivore3Mapping = () => {
    return (
      <div>
        {locations.map((location, index) => (
          <div key={index}>
            <div className={"margin-bottom-1"}>
              <b>{location.locationName}</b>
            </div>
            <div className={"d-flex margin-bottom-1"}>
              <div className={"d-flex"}>
                <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                  <div>Table</div>
                  {selectField(location, "omnivore_3_table", tableMappingOptions, "Select Table")}
                </span>
                <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                  <div>Employee</div>
                  {selectField(location, "omnivore_3_employee", employeeMappingOptions, "Select Employee")}
                </span>
                <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                  <div>Revenue Center</div>
                  {selectField(location, "omnivore_3_revenue_center", revenueCenterMappingOptions, "Select Rev Center")}
                </span>
              </div>
            </div>
            <div className={"d-flex margin-bottom-2"}>
              <div className={"d-flex"}>
                <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                  <div>Order Type</div>
                  {selectField(location, "omnivore_3_order_type", orderTypeMappingOptions, "Select Order Type")}
                </span>
                <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                  <div>Item Order Mode</div>
                  {selectField(
                    location,
                    "omnivore_3_item_order_mode",
                    itemOrderModeMappingOptions,
                    "Select Order Mode"
                  )}
                </span>
              </div>
            </div>
            {index < locations.length - 1 && <hr className={"margin-y-4"} />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onHide}
      title={"Configure Table Mappings"}
      width={800}
      footer={
        <>
          <BbotButton danger className={"margin-right-2"} onClick={removeAllMappings}>
            Remove All
          </BbotButton>
          <BbotButton className={"margin-right-2"} onClick={onHide}>
            Cancel
          </BbotButton>
          <BbotButton type={"primary"} onClick={saveMappings} loading={isSaving}>
            Save
          </BbotButton>
        </>
      }
    >
      <div className={"margin-bottom-2 margin-x-2"}>
        <BbotButton type={"primary"} onClick={autoPopulateMappings} loading={isAutoPopulating}>
          Auto Populate Mappings
        </BbotButton>
      </div>
      <div className={"margin-y-4 padding-x-2"}>
        {posIntegration === "omnivore_3" ? (
          omnivore3Mapping()
        ) : (
          <>
            <div>
              <div className={"d-flex justify-content-space-between margin-bottom-2"}>
                <div>
                  <b>Bbot Location</b>
                </div>
                <div className={"d-flex"}>
                  <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                    <b>{`${capitalizeString(posIntegration)} Table`}</b>
                  </span>
                  <span style={{ minWidth: "200px" }}>
                    <b>{`${capitalizeString(posIntegration)} Employee (optional)`}</b>
                  </span>
                </div>
              </div>
              <hr />
            </div>

            {locations.map((location, index) => (
              <div key={index}>
                <div className={"d-flex justify-content-space-between margin-bottom-2"}>
                  <div> {location.locationName}</div>
                  <div className={"d-flex"}>
                    <span className={"margin-right-4"} style={{ minWidth: "200px" }}>
                      {selectField(location, `${posIntegration}_table`, tableMappingOptions, "Select Table")}
                    </span>
                    <span style={{ minWidth: "200px" }}>
                      {selectField(location, `${posIntegration}_employee`, employeeMappingOptions, "Select Employee")}
                    </span>
                  </div>
                </div>
                {index < locations.length - 1 && <hr />}
              </div>
            ))}
          </>
        )}
      </div>
    </BbotModal>
  );
};

export default TableMappingModal;
