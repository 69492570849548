import React, { useState, useEffect } from "react";
import DisconnectPOSIntegrationModal from "../../../global/modals/pos-integrations/DisconnectPOSIntegrationModal";
import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "../../../../util/pos-integrations/IntegrationConnectModalUtils";
import { makePOSTRequestWithErrorHandling } from "../../../../util/Utils";
import EditIntegrationSettingsModal from "../../../../util/pos-integrations/EditIntegrationSettingsModal";
import OtterIntegrationModalInputs from "../app-store/OtterIntegrationModalInputs";
import OtterSelectLocationModalInputs from "../app-store/OtterSelectLocationModalInputs";

const OtterIntegrationModal = (props) => {
  const [otterSettings, setOtterSettings] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { selectedCustomer, disconnectMode, show, onClose, onSave } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getOtterSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOtterSettings = async () => {
    const getSettingsUrl = "/admin/getOtterConnectionStatus";
    await getIntegrationSettings(getSettingsUrl, "Otter", selectedCustomer, saveOtterSettings);
  };

  const saveOtterSettings = async (otterSettings) => {
    setOtterSettings(otterSettings);
  };

  const connectOtter = async (values, deleteMenuItems) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      delete_menu_items: deleteMenuItems,
      location_id: values.locationId,
      inject_orders: values.injectOrders,
      display_fulfillment_on_ticket: values.displayFulfillmentOnTicket,
      display_location_on_ticket: values.displayLocationOnTicket,
      display_checkout_info_on_ticket: values.displayCheckoutInfoOnTicket,
      sync_menu_availability_schedules: values.syncMenuAvailabilitySchedules,
      sync_fulfillment_status_from_otter: values.syncFulfillmentStatusFromOtter,
      forward_webhook_to_url: values.forwardWebhookToUrl,
    };
    await connectIntegration("/admin/connectOtter", payload, "Otter", setLoadingData, onConnectSuccess);
  };

  const onConnectSuccess = async () => {
    await getOtterSettings();
    onSave();
    onClose();
  };

  const closeModal = async () => {
    await getOtterSettings();
    onClose();
  };

  const disconnectOtter = async (deleteMenuItems) => {
    await disconnectIntegration(
      "/admin/disconnectOtter",
      "Otter",
      selectedCustomer,
      deleteMenuItems,
      setLoadingData,
      onDisconnectSuccess
    );
  };

  const onDisconnectSuccess = async () => {
    saveOtterSettings({});
    onClose();
    onSave();
    await closeModal();
  };

  const disconnectModal = () => {
    return (
      <DisconnectPOSIntegrationModal
        onHide={onClose}
        show={show}
        disconnectFunc={disconnectOtter}
        onClose={onClose}
        integrationSourceName={"Otter"}
      />
    );
  };

  const verifyLocationId = async (values) => {
    const payload = {
      location_id: values.locationId,
      customer_id: selectedCustomer?.customer_id,
    };

    await makePOSTRequestWithErrorHandling(
      "/admin/verifyOtterLocationId",
      payload,
      onVerifyLocationIdSuccess,
      null,
      "Trouble connecting to Otter."
    );
  };

  const onVerifyLocationIdSuccess = (res) => {
    saveOtterSettings({ location_id: res.location_id });
  };

  const selectLocationModal = () => {
    const initialValues = {
      locationId: "",
    };

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={closeModal}
        onSubmit={verifyLocationId}
        modalBody={OtterSelectLocationModalInputs}
        loadingData={loadingData}
        modalTitle={"Select Location"}
      />
    );
  };

  const submitEditSettings = async (values) => {
    await connectOtter(values, false);
    await getOtterSettings();
  };

  const editSettingsModal = () => {
    let initialValues = {
      injectOrders: otterSettings?.inject_orders ?? true,
      locationId: otterSettings?.location_id ?? "",
      displayFulfillmentOnTicket: otterSettings?.display_fulfillment_on_ticket ?? true,
      displayLocationOnTicket: otterSettings?.display_location_on_ticket ?? true,
      displayCheckoutInfoOnTicket: otterSettings?.display_checkout_info_on_ticket ?? true,
      syncMenuAvailabilitySchedules: otterSettings?.sync_menu_availability_schedules ?? true,
      syncFulfillmentStatusFromOtter: otterSettings?.sync_fulfillment_status_from_otter ?? true,
      forwardWebhookToUrl: otterSettings?.forward_webhook_to_url ?? "",
    };

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={closeModal}
        onSubmit={submitEditSettings}
        modalBody={OtterIntegrationModalInputs}
        loadingData={loadingData}
        modalTitle={"Otter POS Settings"}
        otterSettings={otterSettings}
      />
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  return otterSettings.location_id ? editSettingsModal() : selectLocationModal();
};

export default OtterIntegrationModal;
