import React from "react";
import { CheckboxInput } from "../../../../../src/components/global/form/index";

const DisplayExtraCheckoutInfoOnTicketsFields = ({
  injectOrders,
  posName,
  showFulfillmentMethodOption = true,
  showLocationOption = true,
  showExtraCheckoutOption = true,
  ...props
}) => {
  return (
    <>
      {showLocationOption && (
        <CheckboxInput
          sm={12}
          label={`Display Bbot location code on ${posName} ticket`}
          name="displayLocationOnTicket"
          id="display-location-on-ticket"
          disabled={!injectOrders}
        />
      )}
      {showFulfillmentMethodOption && (
        <CheckboxInput
          sm={12}
          label={`Display Bbot fulfillment method on ${posName} ticket`}
          name="displayFulfillmentOnTicket"
          id="display-fulfillment-on-ticket"
          disabled={!injectOrders}
        />
      )}
      {showExtraCheckoutOption && (
        <CheckboxInput
          sm={12}
          label={`Display Bbot checkout info on ${posName} ticket`}
          name="displayCheckoutInfoOnTicket"
          id="display-checkout-info-on-ticket"
          disabled={!injectOrders}
        />
      )}
    </>
  );
};

export default DisplayExtraCheckoutInfoOnTicketsFields;
