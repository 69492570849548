let selectedCustomer = null;
let userInfo = null;

export const getSelectedCustomer = () => selectedCustomer;

export const getUserInfo = () => userInfo;

export const setSelectedCustomer = (customer) => {
  selectedCustomer = customer;
};

export const setUserInfo = (userData) => {
  userInfo = userData;
};
