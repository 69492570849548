import React from "react";
import zxcvbn from "zxcvbn";
import { ProgressBar } from "react-bootstrap";

import "styles/customer-journey/PasswordStrengthMeter.scss";

class PasswordStrengthMeter extends React.Component {
  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  getProgresBar = (result) => {
    switch (result.score) {
      case 0:
        return <ProgressBar className="password-progress" now={0} variant="danger" />;
      case 1:
        return <ProgressBar className="password-progress" now={20} variant="danger" />;
      case 2:
        return <ProgressBar className="password-progress" now={40} variant="warning" />;
      case 3:
        return <ProgressBar className="password-progress" now={70} variant="info" />;
      case 4:
        return <ProgressBar className="password-progress" now={100} variant="success" />;
      default:
        return <ProgressBar className="password-progress" now={0} variant="danger" />;
    }
  };

  render() {
    const { password } = this.props;
    if (!password) return <> </>;

    const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        {this.getProgresBar(testedResult)}
        <label className="password-strength-meter-label">
          {password && (
            <>
              <strong>Password strength:</strong> {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;
