import React from "react";
import axios from "axios";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import AddCutAgreementModal from "components/owner-app/modals/cut-agreements/AddCutAgreementModal";
import { fractionToPercentage } from "util/Utils";
import EditCutAgreementModal from "components/owner-app/modals/cut-agreements/EditCutAgreementModal";

class CutAgreements extends React.Component {
  state = {
    showAddCutAgreementModal: false,
    showEditCutAgreementModal: false,
    inbound: false,
    inboundCutAgreements: [],
    outboundCutAgreements: [],
    selectedCutAgreement: null,
    now: DateTime.now(),
    fulfillmentMethodsPrettyNames: {},
  };

  componentDidMount() {
    const { selectedCustomer } = this.props;
    this.getFMNPrettyNames();
    if (!selectedCustomer) return;

    // get existing cut agreements
    this.getCutAgreements();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getCutAgreements();
    }
  }

  getCutAgreements = async () => {
    const { selectedCustomer } = this.props;
    try {
      const res = await axios.get("/admin/getCutAgreements", {
        params: { customer_id: selectedCustomer?.customer_id },
      });
      this.setState({
        inboundCutAgreements: res.data.inbound_cut_agreements,
        outboundCutAgreements: res.data.outbound_cut_agreements,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getFMNPrettyNames = async () => {
    try {
      const res = await axios.get("api/getFulfillmentMethods");
      this.setState({
        fulfillmentMethodsPrettyNames: res.data.fulfillment_methods_pretty_names,
      });
    } catch (error) {
      console.error(error);
    }
  };

  sortCutAgreementsByDate = (cutAgreement1, cutAgreement2) => {
    const cutAgreement1Date = DateTime.fromISO(cutAgreement1.start_date);
    const cutAgreement2Date = DateTime.fromISO(cutAgreement2.start_date);
    return cutAgreement1Date > cutAgreement2Date ? -1 : 1;
  };

  render() {
    const { allowedCustomers, allowedCustomersById, selectedCustomer, userInfo } = this.props;

    const {
      showAddCutAgreementModal,
      showEditCutAgreementModal,
      inbound,
      inboundCutAgreements,
      outboundCutAgreements,
      selectedCutAgreement,
      now,
      fulfillmentMethodsPrettyNames,
    } = this.state;

    const userCanEdit = userInfo?.permissions?.includes("manage_admins");

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-row margin-bottom-4">
                <span>
                  <Link to="/" className="breadcrumb-back">
                    &#60; Back
                  </Link>
                </span>
                <span className="breadcrumbs">
                  <span>
                    <Link to="/" className="breadcrumb-link">
                      Owner Portal
                    </Link>
                  </span>
                  <span className="breadcrumb-link">&#62;</span>
                  <span>
                    <Link to="/cut-agreements" className="breadcrumb-link">
                      Cut Agreements
                    </Link>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="margin-bottom-4">
                <h2>
                  Manage Cut Agreements For{" "}
                  <span className="color-primary__dark">{selectedCustomer?.customer_name}</span>
                </h2>
              </div>
              <div className="margin-bottom-4">
                <p>
                  Cut Agreements control how service charges behave. Inbound cut agreements are cuts that this customer
                  takes from other customers, and outbound cut agreements are cuts that come out of this customer.
                </p>
                <p>
                  ONLY edit this if you have received training from your manager, and your manager has signed off that
                  you're qualified to edit cuts.
                </p>
              </div>
            </Col>
          </Row>
          <Row className={"margin-bottom-4"}>
            <Col>
              {/*INBOUND CUT AGREEMENTS TABLE*/}

              <div className={"table-container"}>
                <div className={"header bg-blue-6"}>
                  <h4>Inbound Cut Agreements</h4>
                </div>
                <div className={"table-scroll-container"}>
                  <Table hover size={"sm"}>
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th className={"right-align"}>Tip</th>
                        <th className={"right-align"}>Items</th>
                        <th className={"right-align"}>Service Charge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inboundCutAgreements.sort(this.sortCutAgreementsByDate).map((cutAgreement, index) => {
                        const cutAgreementEndDate = DateTime.fromISO(cutAgreement.end_date);
                        const cutAgreementEnded = now > cutAgreementEndDate;
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              this.setState({
                                selectedCutAgreement: cutAgreement,
                                showEditCutAgreementModal: true,
                                inbound: true,
                              })
                            }
                          >
                            <td>
                              <div>
                                <span className={"margin-right-1"}>{cutAgreement.from_customer?.name_for_admin}</span>
                                {cutAgreementEnded && (
                                  <Badge pill variant={"secondary"}>
                                    Ended
                                  </Badge>
                                )}
                              </div>
                              <div className={"text-small-detail"}>
                                {DateTime.fromISO(cutAgreement.start_date).toUTC().set({ hour: 12 }).toLocaleString() +
                                  " - " +
                                  DateTime.fromISO(cutAgreement.end_date).toUTC().set({ hour: 12 }).toLocaleString()}
                              </div>
                            </td>
                            <td className={"right-align"}>{fractionToPercentage(cutAgreement.fraction_of_tip, 4)}%</td>
                            <td className={"right-align"}>
                              <div>{fractionToPercentage(cutAgreement.fraction_of_items_pretax, 4)}% pretax</div>
                              <div className={"text-small-detail"}>
                                {fractionToPercentage(cutAgreement.fraction_of_items_tax, 4)}% tax
                              </div>
                            </td>
                            <td className={"right-align"}>
                              <div>
                                {fractionToPercentage(cutAgreement.fraction_of_servicecharge_pretax, 4)}% pretax
                              </div>
                              <div className={"text-small-detail"}>
                                {fractionToPercentage(cutAgreement.fraction_of_servicecharge_tax, 4)}% tax
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {userCanEdit && (
                  <button
                    className="add-item"
                    onClick={() =>
                      this.setState({
                        showAddCutAgreementModal: true,
                        inbound: true,
                      })
                    }
                  >
                    <div className={"icon-circle"}>+</div>
                    <span>New Inbound Cut Agreement</span>
                  </button>
                )}
              </div>
            </Col>
          </Row>

          <Row className={"margin-bottom-4"}>
            <Col>
              {/*OUTBOUND CUT AGREEMENTS TABLE*/}

              <div className={"table-container"}>
                <div className={"header"}>
                  <h4>Outbound Cut Agreements</h4>
                </div>
                <div className={"table-scroll-container"}>
                  <Table hover size={"sm"}>
                    <thead>
                      <tr>
                        <th>Destination</th>
                        <th className={"right-align"}>Tip</th>
                        <th className={"right-align"}>Items</th>
                        <th className={"right-align"}>Fixed Cents</th>
                        <th className={"right-align"}>Service Charge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outboundCutAgreements.sort(this.sortCutAgreementsByDate).map((cutAgreement, index) => {
                        const cutAgreementEndDate = DateTime.fromISO(cutAgreement.end_date);
                        const cutAgreementEnded = now > cutAgreementEndDate;
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              this.setState({
                                selectedCutAgreement: cutAgreement,
                                showEditCutAgreementModal: true,
                                inbound: false,
                              })
                            }
                          >
                            <td>
                              <div>
                                <span className={"margin-right-1"}>
                                  {cutAgreement.recipient_is_bbot
                                    ? "Bbot"
                                    : cutAgreement.recipient_if_customer?.name_for_admin}
                                </span>
                                {cutAgreementEnded && (
                                  <Badge pill variant={"secondary"}>
                                    Ended
                                  </Badge>
                                )}
                              </div>
                              <div className={"text-small-detail"}>
                                {DateTime.fromISO(cutAgreement.start_date).toUTC().set({ hour: 12 }).toLocaleString() +
                                  " - " +
                                  DateTime.fromISO(cutAgreement.end_date).toUTC().set({ hour: 12 }).toLocaleString()}
                              </div>
                            </td>
                            <td className={"right-align"}>{fractionToPercentage(cutAgreement.fraction_of_tip, 4)}%</td>
                            <td className={"right-align"}>
                              <div>{fractionToPercentage(cutAgreement.fraction_of_items_pretax, 4)}% pretax</div>
                              <div className={"text-small-detail"}>
                                {fractionToPercentage(cutAgreement.fraction_of_items_tax, 4)}% tax
                              </div>
                            </td>
                            <td className={"right-align"}>
                              {cutAgreement.recipient_is_bbot ? cutAgreement.fixed_cents : "NA"}
                            </td>
                            <td className={"right-align"}>
                              <div>
                                {fractionToPercentage(cutAgreement.fraction_of_servicecharge_pretax, 4)}% pretax
                              </div>
                              <div className={"text-small-detail"}>
                                {fractionToPercentage(cutAgreement.fraction_of_servicecharge_tax, 4)}% tax
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {userCanEdit && (
                  <button
                    className="add-item"
                    onClick={() =>
                      this.setState({
                        showAddCutAgreementModal: true,
                        inbound: false,
                      })
                    }
                  >
                    <div className={"icon-circle"}>+</div>
                    <span>New Outbound Cut Agreement</span>
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <AddCutAgreementModal
          show={showAddCutAgreementModal}
          inbound={inbound}
          onClose={() => this.setState({ showAddCutAgreementModal: false })}
          onSave={this.getCutAgreements}
          selectedCustomer={selectedCustomer}
          allowedCustomers={allowedCustomers}
          allowedCustomersById={allowedCustomersById}
          fulfillmentMethodsPrettyNames={fulfillmentMethodsPrettyNames}
          userInfo={userInfo}
        />
        <EditCutAgreementModal
          show={showEditCutAgreementModal}
          inbound={inbound}
          onClose={() => this.setState({ showEditCutAgreementModal: false })}
          onSave={this.getCutAgreements}
          cutAgreement={selectedCutAgreement}
          selectedCustomer={selectedCustomer}
          allowedCustomers={allowedCustomers}
          allowedCustomersById={allowedCustomersById}
          fulfillmentMethodsPrettyNames={fulfillmentMethodsPrettyNames}
          userInfo={userInfo}
        />
      </div>
    );
  }
}

export default CutAgreements;
