import React, { useState } from "react";
import { BbotTable } from "../../../../bbot-component-library";
import UpdatePCBModal from "../../modals/pcb-updater/UpdatePCBModal";
import { statusBadge, ControlBoxTableColumns } from "./PrinterControlBoxTableUtils";

const PrinterControlBoxTable = ({ printerControlBoxes, onUpdateCallback, softwareVersions }) => {
  const [selectedPcbIds, setSelectedPcbIds] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const controlBoxes = printerControlBoxes?.map((box) => {
    return {
      ...box,
      key: box.id,
      last_poll: statusBadge(box.time_since_heartbeat),
    };
  });

  const getPcbInfoForUpdate = () => {
    return controlBoxes.filter((box) => {
      return selectedPcbIds.includes(box.id);
    });
  };

  return (
    <div>
      <BbotTable
        id={"pcb-software-release-table"}
        title={"Control Boxes"}
        manuallyFormattedColumns={ControlBoxTableColumns({ controlBoxes })}
        data={controlBoxes}
        editButtonDisabled={!selectedPcbIds}
        editButtonText={"Update"}
        onRowSelectionChange={(selectedRowKeys) => {
          setSelectedPcbIds(selectedRowKeys);
        }}
        onBulkEdit={() => setShowUpdateModal(true)}
        pagination
        bordered
        allowBulkEdit
      />
      <UpdatePCBModal
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onUpdateCallback={onUpdateCallback}
        softwareVersions={softwareVersions}
        selectedPcbs={getPcbInfoForUpdate()}
      />
    </div>
  );
};

export default PrinterControlBoxTable;
