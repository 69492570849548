import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import CenteredLoadingSpinner from "../../../../components/owner-app/loading-spinners/CenteredLoadingSpinner";

class ConnectPOSIntegrationModal extends Component {
  render() {
    const { onHide, show, onSubmit, integrationSourceName, loadingData } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Connect {integrationSourceName}</Modal.Title>
        </Modal.Header>
        {loadingData ? (
          <CenteredLoadingSpinner label={`Loading ${integrationSourceName} Integration data`} />
        ) : (
          <>
            <Modal.Body className={"padding-x-4"}>
              <div className={"margin-bottom-1"}>
                <p style={{ color: "red" }}>
                  Warning: Connecting {integrationSourceName} will delete all existing menu items.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button size={"sm"} variant={"light"} onClick={onHide} className={"margin-right-1"}>
                Cancel
              </Button>
              <Button size={"sm"} type={"submit"} onClick={onSubmit}>
                Connect
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  }
}

export default ConnectPOSIntegrationModal;
