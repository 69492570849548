import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class YumpingoIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yumpingoSettings: {},
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getYumpingoConnectionStatus();
    }
  }

  getYumpingoConnectionStatus = async () => {
    const { selectedCustomer } = this.props;

    this.setState({ loadingData: true });
    try {
      const res = await axios.get("/admin/getYumpingoConnectionStatus?customer_id=" + selectedCustomer?.customer_id);
      this.setState({
        yumpingoSettings: res.data,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Yumpingo settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectYumpingo = async (values) => {
    const { selectedCustomer, onClose, onSave, disconnectMode } = this.props;

    values.enabled = !disconnectMode;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      ...values,
    };

    try {
      await axios.post("/admin/connectYumpingo", payload);
      if (disconnectMode) {
        toast.success("Successfully disconnected Yumpingo.");
        this.setState({
          yumpingoSettings: {},
        });
      } else {
        toast.success("Successfully connected Yumpingo.");
        this.setState({
          yumpingoSettings: values,
        });
      }
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to Yumpingo.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { yumpingoSettings, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Yumpingo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Yumpingo, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              variant={"danger"}
              onClick={() => {
                this.connectYumpingo(yumpingoSettings);
              }}
            >
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            yumpingo_venue_id: yumpingoSettings.yumpingo_venue_id || "",
            message_delay: yumpingoSettings.message_delay || "",
            message_content: yumpingoSettings.message_content || "",
          }}
          validationSchema={Yup.object({
            yumpingo_venue_id: Yup.string().required("Venue ID is required."),
            message_delay: Yup.string(),
            message_content: Yup.string(),
          })}
          onSubmit={this.connectYumpingo}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Yumpingo Integration Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Yumpingo Integration data"} />
                ) : (
                  <>
                    <div className={"margin-bottom-2"}>
                      <p>Yumpingo provides a platform for your guests to review their food experience.</p>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <Button
                        className={"float-right"}
                        size={"lg"}
                        onClick={() => {
                          window.open("https://static.yumpingo.com/bbot-activation-form", "_blank");
                        }}
                      >
                        Contact Yumpingo
                      </Button>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <TextInput label={"Yumpingo Venue Id"} name={"yumpingo_venue_id"} id={"venue-id"} />
                      <div className={"supporting-text margin-top-1 margin-left-2"}>
                        <p>If you don't have a Yumpingo venue id, please contact Yumpingo to get set up.</p>
                      </div>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <TextInput label={"Message Delay (minutes)"} name={"message_delay"} id={"message-delay"} />
                      <div className={"supporting-text margin-top-1 margin-left-2"}>
                        <p>This time delay starts when all items in the order reach a "closed" state.</p>
                      </div>
                    </div>
                    <div className={"margin-bottom-4"}>
                      <TextInput label={"Custom Message"} name={"message_content"} id={"custom-message"} />
                      <div className={"supporting-text margin-top-1 margin-left-2"}>
                        <p>
                          {
                            "Use {{customerName}} and {{url}} as placeholders for the customer name and review url as part of your message"
                          }
                        </p>
                      </div>
                    </div>
                    <div className={"margin-bottom-2"}>
                      <h3>Other Requirements for Yumpingo</h3>
                      <div className={"supporting-text margin-top-1"}>
                        <p>
                          Please ensure all locations you wish this integration to work for are set to require the
                          guest's mobile phone number.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default YumpingoIntegrationModal;
