import React from "react";
import styled from "styled-components";

const SearchText = ({ id, placeholder = "", searchFunction }) => {
  return (
    <StyledTextInputContainer id={id}>
      <i className={"zmdi zmdi-search margin-right-1"} />
      <StyledInput
        placeholder={placeholder}
        onChange={(e) => {
          searchFunction(e.target.value);
        }}
      />
    </StyledTextInputContainer>
  );
};

const StyledTextInputContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
`;

const StyledInput = styled.input`
  border: none;
  width: 80%;
`;

export default SearchText;
