import Icon from "@ant-design/icons";
import React from "react";

const PromotionSVG = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 28C4.5 14.7452 15.2452 4 28.5 4V4C41.7548 4 52.5 14.7452 52.5 28V28C52.5 41.2548 41.7548 52 28.5 52V52C15.2452 52 4.5 41.2548 4.5 28V28Z"
      fill="#D1E9FF"
    />
    <path
      d="M19.5 22L22.5 18H34.5L37.5 22M19.5 22V36C19.5 36.5304 19.7107 37.0391 20.0858 37.4142C20.4609 37.7893 20.9696 38 21.5 38H35.5C36.0304 38 36.5391 37.7893 36.9142 37.4142C37.2893 37.0391 37.5 36.5304 37.5 36V22M19.5 22H37.5M32.5 26C32.5 27.0609 32.0786 28.0783 31.3284 28.8284C30.5783 29.5786 29.5609 30 28.5 30C27.4391 30 26.4217 29.5786 25.6716 28.8284C24.9214 28.0783 24.5 27.0609 24.5 26"
      stroke="#1570EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 48C17.4543 48 8.5 39.0457 8.5 28H0.5C0.5 43.464 13.036 56 28.5 56V48ZM48.5 28C48.5 39.0457 39.5457 48 28.5 48V56C43.964 56 56.5 43.464 56.5 28H48.5ZM28.5 8C39.5457 8 48.5 16.9543 48.5 28H56.5C56.5 12.536 43.964 0 28.5 0V8ZM28.5 0C13.036 0 0.5 12.536 0.5 28H8.5C8.5 16.9543 17.4543 8 28.5 8V0Z"
      fill="#EFF8FF"
    />
  </svg>
);

const Promotion = () => <Icon title="Employee" aria-label="Employee" component={() => PromotionSVG()} />;

export default Promotion;
