import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Col, Form } from "react-bootstrap";
import ReactSelect from "react-select";

const SearchSelectInput = ({ label, ...props }) => {
  const [, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  return (
    <Form.Group
      as={Col}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      className={props.className ? props.className : "margin-bottom-1 "}
    >
      <Form.Label className={"react-select-input-label"}>{label}</Form.Label>
      <ReactSelect
        id={props.id}
        data-test-id={props.testId}
        className={"react-select-custom-styling"} // Needed for styling
        classNamePrefix={"react-select"} // Needed for styling
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        closeMenuOnSelect={props.closeMenuOnSelect}
        options={props.options}
        defaultValue={props.defaultValue}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        isClearable={props.isClearable}
        menuPlacement={"bottom"}
        onChange={(option) => {
          if (option != null) {
            setValue(option.value);
          } else {
            setValue(null);
          }
          if (props.onChange) {
            props.onChange(option); // Call the callback function with the selected option
          }
        }}
      />
      <Form.Control.Feedback type="invalid">{meta.error?.replace(props.name, label)}</Form.Control.Feedback>
    </Form.Group>
  );
};

SearchSelectInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  testId: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default SearchSelectInput;
