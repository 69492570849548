import React, { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

function InitialDepositWidget({ task, widget, journey, updateTaskStatus }) {
  const [submitting, setSubmitting] = useState(false);
  const initialDepositAmountCents = journey.initial_deposit_amount_cents;

  const authorizePayment = async () => {
    setSubmitting(true);
    try {
      const payload = { journey_id: journey.id };
      await axios.post("/api/journey/authorizePayment", payload);
      updateTaskStatus("in_progress", { widgetId: widget.id });
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <div>
      <p>The initial deposit amount is: ${(initialDepositAmountCents / 100.0).toFixed(2)}</p>
      {task.status === "in_progress" || task.status === "completed" ? (
        <div>
          <p>
            The initial deposit has already been authorized. This task will become completed when the bill executes.
          </p>
        </div>
      ) : (
        <Button onClick={authorizePayment} disabled={submitting}>
          Authorize Payment Now
        </Button>
      )}
    </div>
  );
}

export default InitialDepositWidget;
