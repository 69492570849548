import React, { useState, useEffect } from "react";
import BulkEditableSetting from "components/owner-app/BulkEditableSetting";
import { Card } from "bbot-component-library";

const SharingTab = (props) => {
  const { bulkEditMode, updateLocationSettings, removeChanges, defaultLocationSettings } = props;
  const bulkEditButtonText = "Edit For Selected Locations";

  const [settingsState, setSettingsState] = useState({
    groupOrderingAllowed: false,
    discoverabilityOfGroupOrderingAllowed: false,
  });

  useEffect(() => {
    setSettingsState((settingsState) => ({
      ...settingsState,
      groupOrderingAllowed: defaultLocationSettings.shared_carts_allowed,
      discoverabilityOfGroupOrderingAllowed: defaultLocationSettings.shared_carts_are_public,
    }));
  }, [defaultLocationSettings]);

  return (
    <div>
      <div className={"margin-bottom-2"}>
        <h4>Group Ordering</h4>
      </div>

      <Card className={"margin-bottom-4"}>
        <BulkEditableSetting
          title={"Group Ordering Allowed"}
          description={"This controls whether collaborative group ordering is enabled for the selected locations."}
          inBulkEditMode={bulkEditMode}
          onBulkEditInitial={() =>
            updateLocationSettings({ shared_carts_allowed: defaultLocationSettings.shared_carts_allowed })
          }
          onBulkEditCancel={() => removeChanges("shared_carts_allowed")}
          bulkEditButtonText={bulkEditButtonText}
          toggleConfig={{
            defaultEnabled: settingsState.groupOrderingAllowed,
            onClick: (e) => {
              setSettingsState({
                ...settingsState,
                groupOrderingAllowed: e.SWITCH_STATE.enabled,
              });
              updateLocationSettings({
                shared_carts_allowed: e.SWITCH_STATE.enabled,
              });
            },
          }}
        />
      </Card>
    </div>
  );
};

export default SharingTab;
