import React, { useState, useEffect } from "react";
import {
  Button,
  BbotButton,
  Breadcrumbs,
  Form,
  Row,
  Col,
  Card,
  notification,
  BbotContainer,
  Input,
  Spin,
} from "bbot-component-library";
import TextInput from "bbot-component-library/form-inputs/TextInput";
import styled from "styled-components";
import axios from "axios";
import { generalErrorAlert } from "util/Utils";
import ReactFilestack from "filestack-react";
import { FILESTACK_API_KEY } from "Constants";
import { useHistory } from "react-router-dom";

import KioskThanksIcon from "assets/svgs/icons/icon-kiosk-thanks.svg";

const FILESTACK_IMG_OPTIONS = {
  accept: ["image/*"],
  fromSources: ["local_file_system", "googledrive", "url"],
  maxFiles: 1,
};

const KioskEditor = (props) => {
  const [isSavingScreensaver, setIsSavingScreensaver] = useState(false);
  const [isSavingPostCheckout, setIsSavingPostCheckout] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [kioskConfig, setKioskConfig] = useState({});
  const [preCheckoutFormValues, setPreCheckoutFormValues] = useState({});
  const [postCheckoutFormValues, setPostCheckoutFormValues] = useState({});

  const [backgroundImages, setBackgroundImages] = useState([]);

  const history = useHistory();

  const [preCheckoutForm] = Form.useForm();
  const [postCheckoutForm] = Form.useForm();

  const { selectedCustomer } = props;

  useEffect(() => {
    const getKioskConfig = async () => {
      if (!selectedCustomer) return;

      setIsLoadingConfig(true);
      try {
        const res = await axios.get("api/kioskConfigurations", {
          params: { customer_id: selectedCustomer.customer_id },
        });

        // This account has no kiosk configs yet. Redirect to the locations page.
        if (res.data.kiosk_configurations.length === 0) {
          history.push("/edit-locations");
          return;
        }

        setKioskConfig(res.data.kiosk_configurations[0]);
        setBackgroundImages(res.data.kiosk_configurations[0].screensaver_images || []);
      } catch (error) {
        generalErrorAlert(error, "Could not fetch Kiosk Configuration.", true);
      }
      setIsLoadingConfig(false);
    };

    getKioskConfig();
  }, [selectedCustomer, history]);

  useEffect(() => {
    if (!kioskConfig) return;

    const preCheckoutFormInitialValues = {
      screensaverImageDelay: kioskConfig.screensaver_image_delay,
      screensaverTitleText: kioskConfig.screensaver_title_text,
      screensaverDescriptionText: kioskConfig.screensaver_description_text,
      screensaverButtonText: kioskConfig.screensaver_button_text,
    };
    const postCheckoutFormInitialValues = {
      postCheckoutTitle: kioskConfig.thank_you_title,
      postCheckoutDescription: kioskConfig.thank_you_description,
    };

    preCheckoutForm.setFieldsValue(preCheckoutFormInitialValues);
    setPreCheckoutFormValues(preCheckoutFormInitialValues);
    postCheckoutForm.setFieldsValue(postCheckoutFormInitialValues);
    setPostCheckoutFormValues(postCheckoutFormInitialValues);
  }, [preCheckoutForm, postCheckoutForm, kioskConfig]);

  const saveKioskConfig = async (values, editingScreensaver) => {
    if (editingScreensaver) {
      setIsSavingScreensaver(true);
    } else {
      setIsSavingPostCheckout(true);
    }
    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        config_id: kioskConfig.id,
        config_properties: editingScreensaver
          ? {
              screensaver_images: backgroundImages,
              screensaver_image_delay: values.screensaverImageDelay,
              screensaver_title_text: values.screensaverTitleText,
              screensaver_description_text: values.screensaverDescriptionText,
              screensaver_button_text: values.screensaverButtonText,
            }
          : {
              thank_you_title: values.postCheckoutTitle,
              thank_you_description: values.postCheckoutDescription,
            },
      };
      await axios.post("/api/kioskConfigurations", payload);
      notification.success({ message: "Saved Kiosk Configuration Settings" });
    } catch (error) {
      generalErrorAlert(error, "Could not save Kiosk Configuration Settngs.");
    }
    if (editingScreensaver) {
      setIsSavingScreensaver(false);
    } else {
      setIsSavingPostCheckout(false);
    }
  };

  const editPreCheckoutScreen = () => {
    return (
      <div>
        <div className={"margin-y-4"}>
          <Row gutter={40}>
            <Col sm={24} md={12}>
              <Form
                form={preCheckoutForm}
                name={"pre-checkout-form"}
                layout={"vertical"}
                initialValues={{
                  screensaverImageDelay: kioskConfig?.screensaver_image_delay,
                  screensaverTitleText: kioskConfig?.screensaver_title_text,
                  screensaverDescriptionText: kioskConfig?.screensaver_description_text,
                  screensaverButtonText: kioskConfig?.screensaver_button_text,
                }}
                onFinish={(values) => saveKioskConfig(values, true)}
                onValuesChange={(_, values) => setPreCheckoutFormValues(values)}
              >
                {/*BACKGROUND PICTURES*/}
                <div>
                  <div className={"margin-bottom-1"}>Background Picture</div>
                  <Row gutter={20} className={"margin-bottom-2"}>
                    {backgroundImages.map((backgroundImageUrl) => (
                      <Col span={6} className={"margin-bottom-1"}>
                        <BackgroundImagePreview>
                          <img src={backgroundImageUrl} alt={"background-img"} />
                        </BackgroundImagePreview>
                      </Col>
                    ))}
                    <Col span={6} className={"margin-bottom-1"}>
                      <BackgroundImagePreview>
                        <ReactFilestack
                          apikey={FILESTACK_API_KEY}
                          actionOptions={FILESTACK_IMG_OPTIONS}
                          componentDisplayMode={{
                            customText: (
                              <div>
                                <span className={"material-icons-outlined"}>file_upload</span>
                                Upload
                              </div>
                            ),
                            customClass: "upload-button",
                          }}
                          onSuccess={(res) => {
                            setBackgroundImages([res.filesUploaded[0].url]);
                          }}
                        />
                      </BackgroundImagePreview>
                    </Col>
                  </Row>
                </div>

                {/*For V2*/}
                {/*<NumberInput*/}
                {/*  name={"screensaverImageDelay"}*/}
                {/*  id={"screensaver-image-delay"}*/}
                {/*  label={"Screensaver Image Delay"}*/}
                {/*/>*/}

                <TextInput
                  name={"screensaverTitleText"}
                  id={"screensaver-title-text"}
                  label={"Screensaver Title"}
                  placeholder={"Order Here"}
                  required={false}
                />
                <TextInput
                  name={"screensaverDescriptionText"}
                  id={"screensaver-description-text"}
                  label={"Screensaver Description Text"}
                  required={false}
                />
                <TextInput
                  name={"screensaverButtonText"}
                  id={"screensaver-button-text"}
                  label={"Screensaver Button Text"}
                  required={false}
                  placeholder={"Start"}
                />
                <div>
                  <BbotButton
                    htmlType={"submit"}
                    type={"primary"}
                    loading={isSavingScreensaver}
                    className={"margin-bottom-2"}
                    required={false}
                  >
                    Save
                  </BbotButton>
                </div>
              </Form>
            </Col>
            <Col sm={24} md={12}>
              <PreviewCard title={"Screensaver Preview"}>
                {isLoadingConfig ? (
                  <Spin />
                ) : (
                  <ScreensaverPreviewContainer backgroundImage={backgroundImages[0]}>
                    <LogoContainer className={"margin-bottom-8 margin-top-8"}>LOGO</LogoContainer>
                    <PreCheckoutTitle>{preCheckoutFormValues.screensaverTitleText || "Order Here"}</PreCheckoutTitle>
                    <PreCheckoutDescription>{preCheckoutFormValues.screensaverDescriptionText}</PreCheckoutDescription>
                    <CheckoutButton>{preCheckoutForm.getFieldValue("screensaverButtonText") || "Start"}</CheckoutButton>
                  </ScreensaverPreviewContainer>
                )}
              </PreviewCard>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const editPostCheckoutScreen = () => {
    return (
      <div>
        <div className={"margin-y-4"}>
          <Row gutter={40}>
            <Col sm={24} md={12}>
              <Form
                form={postCheckoutForm}
                name={"post-checkout-form"}
                layout={"vertical"}
                initialValues={{
                  postCheckoutTitle: kioskConfig?.thank_you_title,
                  postCheckoutDescription: kioskConfig?.thank_you_description,
                }}
                onFinish={(values) => saveKioskConfig(values, false)}
                onValuesChange={(_, values) => setPostCheckoutFormValues(values)}
              >
                <TextInput
                  name={"postCheckoutTitle"}
                  id={"post-checkout-title"}
                  label={"Post Checkout Title"}
                  required={false}
                  placeholder={"Thank You"}
                />
                <TextInput
                  name={"postCheckoutDescription"}
                  id={"post-checkout-description"}
                  label={"Post Checkout Description"}
                  required={false}
                />
                <div>
                  <BbotButton
                    htmlType={"submit"}
                    type={"primary"}
                    loading={isSavingPostCheckout}
                    className={"margin-bottom-2"}
                  >
                    Save
                  </BbotButton>
                </div>
              </Form>
            </Col>
            <Col sm={24} md={12}>
              <PreviewCard title={"Post Checkout Screen Preview"}>
                <ThanksIconContainer className={"margin-y-6"}>
                  <img alt="settings icon" className="icon" src={KioskThanksIcon} />
                </ThanksIconContainer>
                <PostCheckoutOrderNumber>ORDER #432</PostCheckoutOrderNumber>
                <PostCheckoutTitle>{postCheckoutFormValues.postCheckoutTitle || "Thank You"}</PostCheckoutTitle>
                <PostCheckoutDescription>{postCheckoutFormValues.postCheckoutDescription}</PostCheckoutDescription>
                <InputsContainer>
                  <div className={"margin-bottom-2"}>
                    <InputLabel>EMAIL</InputLabel>
                    <InputStyled enterButton={"EMAIL RECEIPT"} />
                  </div>
                  <div className={"margin-bottom-4"}>
                    <InputLabel>PHONE NUMBER</InputLabel>
                    <InputStyled enterButton={"TEXT RECEIPT"} />
                  </div>
                  <AnotherOrderButton>PLACE ANOTHER ORDER</AnotherOrderButton>
                </InputsContainer>
              </PreviewCard>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <BbotContainer>
      <Breadcrumbs name={"Edit Kiosk Configuration"} link={"/edit-kiosk-config"} />
      <div className={"margin-bottom-4"}>
        <h2>Manage Kiosk Settings</h2>
      </div>

      {editPreCheckoutScreen()}

      <hr className={"margin-y-4"} />

      {editPostCheckoutScreen()}

      {/*{pageContent()}*/}
    </BbotContainer>
  );
};

const BackgroundImagePreview = styled.div`
  min-height: 120px;
  height: 100%;
  border: 1px solid var(--color-neutral__line);

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .upload-button {
    background-color: white;
    border: None;
    height: 100%;
    width: 100%;
    color: var(--color-neutral__body);

    &:hover {
      color: var(--color-primary__regular);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 30%;
  height: 20%;
  padding: 20px;
  width: 40%;
  border: 1px dashed white;
  font-size: 20px;
`;

const PreCheckoutDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  text-transform: uppercase;
  max-width: 50%;
  text-align: center;
`;

const PreCheckoutTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  max-width: 50%;
  text-align: center;
`;

const CheckoutButton = styled(Button)`
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  width: 60%;
  border-radius: 20px;
  background-color: #e86100;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background-color: #e86100;
    color: white;
  }
`;

const ThanksIconContainer = styled.div`
  width: 100%;
  text-align: center;
  img {
    max-width: 30%;
  }
`;

const InputLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
`;

const PostCheckoutOrderNumber = styled.div`
  font-size: 12px;
  margin-bottom: 28px;
  color: #6e8165;
`;

const PostCheckoutTitle = styled.div`
  text-align: center;
  max-width: 300px;
  margin-bottom: 28px;
  font-size: 24px;
  font-weight: 600;
`;

const PostCheckoutDescription = styled.div`
  text-align: center;
  max-width: 300px;
  margin-bottom: 28px;
  font-size: 14px;
`;

const InputsContainer = styled.div`
  width: 70%;
`;

const PreviewCard = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    min-height: 800px;
  }
`;

const ScreensaverPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  min-height: 800px;
  background-image: url("${(props) => props.backgroundImage}");
  background-size: cover;
  background-position: center;
  background-color: var(--color-neutral__line);
  color: white;
`;

const InputStyled = styled(Input.Search)`
  button {
    font-size: 10px;
    background-color: #6e8165;
    border-color: #6e8165;
    &:hover,
    &:focus {
      background-color: #6e8165;
      border-color: #6e8165;
    }
  }
`;

const AnotherOrderButton = styled(Button)`
  font-size: 12px;
  border-radius: 8px;
  background-color: black;
  color: white;
  width: 100%;

  &:hover,
  &:focus {
    background-color: black;
    color: white;
  }
`;

export default KioskEditor;
