import { track } from "instrumentation/tracking/track";

export const trackClickAddServiceCharge = (fields) => track("track_click_new_service_charge", fields);

export const trackClickSaveNewServiceCharge = (fields) => track("track_click_save_new_service_charge", fields);

export const trackClickEditServiceCharge = (fields) => track("track_click_edit_service_charge", fields);

export const trackClickSaveEditServiceCharge = (fields) => track("track_click_save_edit_service_charge", fields);

export const trackClickBulkDeleteServiceCharge = (fields) => track("track_click_bulk_delete_service_charge", fields);
