import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import SubmitCancelButtonsWithLoader from "../../../src/components/global/form/SubmitCancelButtonsWithLoader";

const EditIntegrationSettingsModal = (props) => {
  const { show, onHide, onCancel, onSubmit, initialValues, modalBody, loadingData, modalTitle, validationSchema } =
    props;

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className={"styled-form"}>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"padding-x-4"}>
              {React.createElement(modalBody, { values: values, setFieldValue: setFieldValue, ...props })}
            </Modal.Body>
            <Modal.Footer>
              <SubmitCancelButtonsWithLoader loadingData={loadingData} errors={errors} onCancel={onCancel} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditIntegrationSettingsModal;
