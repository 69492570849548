import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Col, Form } from "react-bootstrap";

const TextInput = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group
      as={Col}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      controlId={controlId}
      className={props.className ? props.className : "margin-bottom-1 "}
    >
      {!props.hidelabel && <Form.Label className={"text-input-label"}>{label}</Form.Label>}
      <Form.Control
        type={"text"}
        data-test-id={props.testId}
        className={"text-input " + (meta.touched && !!meta.error ? "error" : "")}
        isInvalid={meta.touched && !!meta.error}
        {...field}
        {...props}
      />
      <Form.Control.Feedback type="invalid">{meta.error?.replace(props.name, label)}</Form.Control.Feedback>
    </Form.Group>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  validate: PropTypes.any,
  testId: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

TextInput.defaultProps = {
  placeholder: "",
};

export default TextInput;
