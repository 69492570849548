import { makeGETRequestWithErrorHandling, makePOSTRequestWithErrorHandling } from "../Utils";

export const getIntegrationSettings = async (url, integrationName, selectedCustomer, setSettings) => {
  let fullRequestURL = `${url}?customer_id=${selectedCustomer?.customer_id}`;
  await makeGETRequestWithErrorHandling(fullRequestURL, setSettings, `Trouble retrieving ${integrationName} settings.`);
};

export const connectIntegration = async (url, payload, integrationName, setLoadingData, onSuccess) => {
  setLoadingData(true);
  await makePOSTRequestWithErrorHandling(
    url,
    payload,
    onSuccess,
    `Successfully connected ${integrationName}.`,
    `Trouble connecting to ${integrationName}.`
  );
  setLoadingData(false);
};

export const disconnectIntegration = async (
  url,
  integrationName,
  selectedCustomer,
  deleteMenuItems,
  setLoadingData,
  onDisconnectSuccess,
  integration = null
) => {
  setLoadingData(true);
  let payload = {
    delete_menu_items: deleteMenuItems,
    customer_id: selectedCustomer?.customer_id,
    integration,
  };
  await makePOSTRequestWithErrorHandling(
    url,
    payload,
    onDisconnectSuccess,
    `Successfully disconnected ${integrationName}.`,
    `Trouble disconnecting ${integrationName}.`
  );
  setLoadingData(false);
};
