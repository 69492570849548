import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Text } from "@doordash/design-language";
import Button from "@doordash/component-button";

const ConfirmationModal = ({ show, onClose, onConfirm, title, description, buttonText }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"margin-bottom-2"}>
          <Text styles={Text.Styles.Body1}>{description}</Text>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className="margin-right-1">
          <Button isInline type={Button.Types.Tertiary} onClick={onClose} size={Button.Sizes.Small}>
            Cancel
          </Button>
        </span>
        <Button isInline onClick={onConfirm} size={Button.Sizes.Small}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  buttonText: "Confirm",
};

export default ConfirmationModal;
