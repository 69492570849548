import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class RooamIntegrationModal extends React.Component {
  state = {
    rooamSettings: {},
    hasLoaded: false,
    loadingData: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getRooamSettings();
    }
  }

  getRooamSettings = async () => {
    const { selectedCustomer } = this.props;

    this.setState({ loadingData: true });
    try {
      const res = await axios.get("/admin/getRooamConnectionStatus?customerId=" + selectedCustomer?.customer_id);
      this.setState({
        rooamSettings: res.data,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Rooam settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectRooam = async (values) => {
    const { selectedCustomer, onClose, onSave, disconnectMode } = this.props;

    const payload = {
      customerId: selectedCustomer?.customer_id,
      ...values,
    };

    const endpoint = disconnectMode ? "/admin/disconnectRooam" : "/admin/connectRooam";

    try {
      await axios.post(endpoint, payload);
      if (disconnectMode) {
        toast.success("Successfully disconnected Rooam Pay at Table.");
        this.setState({
          rooamSettings: {},
        });
      } else {
        toast.success("Successfully connected Rooam Pay at Table.");
        this.setState({
          rooamSettings: values,
        });
      }
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to Rooam Pay at Table.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { rooamSettings, loadingData } = this.state;

    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Rooam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Rooam Pay at Table, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              variant={"danger"}
              onClick={() => {
                this.connectRooam(rooamSettings);
              }}
            >
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            buttonName: rooamSettings.buttonName || "",
            externalUrl: rooamSettings.externalUrl || "",
          }}
          validationSchema={Yup.object({
            buttonName: Yup.string().required("Button name is required."),
            externalUrl: Yup.string()
              .matches(/^(ftp|http|https):\/\/[^ "]+$/, "This must be a valid URL.")
              .required("External URL is required."),
          })}
          onSubmit={this.connectRooam}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Rooam Pay at Table Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Rooam Integration data"} />
                ) : (
                  <>
                    <Form.Row>
                      <TextInput label={"Button Name"} name={"buttonName"} id={"button-name"} />
                    </Form.Row>
                    <Form.Row>
                      <TextInput label={"External URL"} name={"externalUrl"} id={"external_url"} />
                    </Form.Row>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default RooamIntegrationModal;
