import React, { useEffect, useState } from "react";
import { BbotModal, BbotTable, notification } from "bbot-component-library";
import TableEditDeleteIcons from "bbot-component-library/TableEditDeleteIcons";
import { Form } from "bbot-component-library";
import TextInput from "bbot-component-library/form-inputs/TextInput";
import PhoneInput from "bbot-component-library/form-inputs/PhoneInput";
import { editFraudContact, saveFraudContact } from "util/ApiCalls";
import styled from "styled-components";

const FraudContacts = ({ fraudContacts, isLoading, selectedCustomer, refreshData, ...props }) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [fraudContactForm] = Form.useForm();

  useEffect(() => {
    const fraudInitialValues = {
      firstName: contactToEdit?.first_name || "",
      lastName: contactToEdit?.last_name || "",
      phoneNumber: contactToEdit?.phone || "",
    };
    fraudContactForm.setFieldsValue(fraudInitialValues);
  }, [contactToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = async () => {
    const properties = contactToEdit
      ? {
          first_name: fraudContactForm.getFieldValue("firstName"),
          last_name: fraudContactForm.getFieldValue("lastName"),
          phone: fraudContactForm.getFieldValue("phoneNumber"),
        }
      : fraudContactForm.getFieldsValue();
    if (!contactToEdit) {
      await saveFraudContact(selectedCustomer, properties);
    } else {
      await editFraudContact(contactToEdit.id, false, properties);
    }
    setContactToEdit(null);
    setShowAddEditModal(false);
    refreshData();
    notification.success({ message: "Fraud contact saved successfully." });
  };

  const onDelete = async () => {
    await editFraudContact(contactToDelete.id, true);
    setContactToDelete(null);
    refreshData();
    notification.success({ message: "Fraud contact deleted successfully." });
  };

  const formattedRows = fraudContacts.map((contact) => {
    return {
      key: contact.id,
      name: contact.first_name + " " + contact.last_name,
      phone: contact.phone,
      actions: (
        <TableEditDeleteIcons
          onEdit={() => {
            setShowAddEditModal(true);
            setContactToEdit(contact);
          }}
          onDelete={() => setContactToDelete(contact)}
        />
      ),
    };
  });

  const modalContent = (
    <Form form={fraudContactForm} layout="vertical" onFinish={onSave}>
      <TextInput id="first-name" name="firstName" label="First Name" placeholder="First name" required={true} />
      <TextInput id="last-name" name="lastName" label="Last Name" placeholder="Last name" required={true} />
      <PhoneInput
        id="phone-number"
        name="phoneNumber"
        label="Phone Number"
        placeholder="(555)555-5555"
        required={true}
      />
    </Form>
  );

  return (
    <>
      <StyledInfoBox>
        Fraud Alerts are special text alerts that will be sent when we detect that a transaction may be fraudulent.
        <br />
        Here, you can set who gets Fraud Alert notifications.
      </StyledInfoBox>
      <BbotTable
        id={"fraud-contacts"}
        data={formattedRows}
        columns={["Name", "Phone", "Actions"]}
        loading={isLoading}
        onAdd={() => setShowAddEditModal(true)}
        interactive={false}
        pagination={fraudContacts.length > 10}
      />
      <BbotModal
        title={contactToEdit ? "Edit fraud contact" : "Add fraud contact"}
        visible={showAddEditModal}
        onCancel={() => {
          setShowAddEditModal(false);
          setContactToEdit(null);
        }}
        onOk={fraudContactForm.submit}
      >
        {modalContent}
      </BbotModal>
      <BbotModal
        title="Are you sure you'd like to delete this fraud contact?"
        visible={contactToDelete}
        onCancel={() => setContactToDelete(null)}
        okText="Delete"
        okType="danger"
        onOk={onDelete}
      >
        You are about to delete the fraud contact:{" "}
        <strong>{contactToDelete?.first_name + " " + contactToDelete?.last_name}</strong>
      </BbotModal>
    </>
  );
};

const StyledInfoBox = styled.div`
  font-size: 14px;
  color: var(--color-neutral__placeholder);
  margin-bottom: 24px;
`;

export default FraudContacts;
