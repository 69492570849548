import React from "react";
import { Card } from "antd";
import styled from "styled-components";

const BbotCard = (props) => {
  return <StyledCard {...props}></StyledCard>;
};

const StyledCard = styled(Card)`
  background-color: var(--color-neutral__card-background);
  border: 1px solid var(--color-neutral__line);
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(178, 178, 178, 0.25);
  width: 100%;
  overflow: hidden;
`;

export default BbotCard;
