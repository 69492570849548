import React, { useState } from "react";
import Button from "@doordash/component-button";
import { TextField } from "@doordash/component-fields";
import { Icon, InlineChildren } from "@doordash/design-language";
import styled from "styled-components";

type CopyToClipboardProps = {
  id?: string;
  value?: string;
  buttonLabel?: string;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  id = "copy-to-clipboard",
  value = "",
  buttonLabel = "Copy to clipboard",
}) => {
  const [buttonState, setButtonState] = useState(Button.States.Default as string);

  const selectText = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);

    setButtonState(Button.States.Success);
  };

  return (
    <InlineChildren>
      <OverflowEllipsis>
        <TextField id={id} readOnly={true} value={value} label={value} isLabelHidden={true} onFocus={selectText} />
      </OverflowEllipsis>
      <Button
        state={buttonState}
        isInline
        onClick={copyToClipboard}
        leadingIcon={Icon.Types.CopyLine}
        accessibilityLabel={buttonLabel}
        onAnimationEnd={() => {
          setTimeout(() => setButtonState(Button.States.Default), 200);
        }}
      >
        Copy
      </Button>
    </InlineChildren>
  );
};

export default CopyToClipboard;

// cannot pass a style or set this through a prop... use a wrapper then
const OverflowEllipsis = styled.div`
  width: 100%;
  input {
    text-overflow: ellipsis;

    &:focus {
      text-overflow: unset;
    }
  }
`;
