import React, { useState, useEffect } from "react";
import { PSClickWrap } from "@pactsafe/pactsafe-react-sdk";
import { getWidgetData, updateWidgetData } from "./Widget";
import { InlineChildren, Text, Icon, BorderRadius, Colors, Spacing, StackChildren } from "@doordash/design-language";
import Tag from "@doordash/component-tag";
import styled from "styled-components";
import Banner from "@doordash/component-banner";
import Button from "@doordash/component-button";
import { ENV, Environments } from "Config";
import { generalErrorAlert } from "util/Utils";
import Loading from "@doordash/component-loading";
import { TEST_PSCONTRACT_TOKENS } from "Constants";
import axios from "axios";

const PACTSAFE_ACCESS_ID = "3953381d-4fb6-4789-950f-a6d2694995f4";
const BBOT_GROUP_KEY = "group-bbot-ssmo-en";

const ContractStatus = {
  Ready: "ready",
  Completed: "completed",
  Recalled: "recalled",
};

const TermsAndConditionsWidget = ({ journey, widget, updateTaskStatus, userInfo }) => {
  const [contractTokens, setContractTokens] = useState({});
  const [contractStatus, setContractStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDevModal, setShowDevModal] = useState(true);
  const [accepting, setAccepting] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  useEffect(() => {
    if (widget && !Object.values(contractTokens).length) {
      setIsLoading(true);
      getWidgetData(widget.id)
        .then((res) => {
          if (res.data.contract_tokens && res.data.contract_status) {
            setContractTokens(res.data.contract_tokens);
            setContractStatus(res.data.contract_status);
          } else {
            setContractStatus(ContractStatus.Recalled);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [widget, contractTokens]);

  const setTestContractTokens = async () => {
    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          contract_status: ContractStatus.Ready,
          contract_tokens: TEST_PSCONTRACT_TOKENS,
        },
      });
      setContractStatus(ContractStatus.Ready);
      setContractTokens(TEST_PSCONTRACT_TOKENS);
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
      setHasAccepted(false);
      setAccepting(false);
    } catch (error) {
      generalErrorAlert(error, "Could not set the test contract tokens.", true);
    }
  };

  const handleAcceptContract = () => {
    if (!hasAccepted) {
      return;
    }

    setAccepting(true);
    window._ps(`${BBOT_GROUP_KEY}:send`, "agreed", {
      disable_sending: false,
      event_callback: async (err, eventType, group, request) => {
        if (err) {
          generalErrorAlert(err, "Could not update the status of the contract.", true);
          setAccepting(false);
          return;
        }

        try {
          await updateWidgetData({
            widgetId: widget.id,
            widgetData: {
              contract_status: ContractStatus.Completed,
            },
          });

          const { signer_id, session } = group.toJSON();

          setContractStatus(ContractStatus.Completed);
          saveCompletedContract(signer_id, session);
        } catch (error) {
          generalErrorAlert(error, "Could not update the status of the contract.", true);
        }

        setAccepting(false);
      },
    });
  };

  const saveCompletedContract = async (signer_id, session) => {
    try {
      await axios.post("/api/journey/saveCustomerContract", {
        journey_id: journey.id,
        session_id: session,
        signer_id,
      });

      updateTaskStatus("completed", { widgetId: widget.id, goToNext: false });
    } catch (error) {
      generalErrorAlert(error, "Unable to save the completed contract. Please try again.", true);
      resetTaskAndContract(ContractStatus.Ready);
    }
  };

  const resetTaskAndContract = async (newContractStatus) => {
    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          contract_status: newContractStatus,
        },
      });
      setContractStatus(newContractStatus);
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error) {
      generalErrorAlert(error, "Could not update the status of the contract.", true);
    }
  };

  const completedContent = (
    <CompletedContainer>
      <StackChildren size={StackChildren.Sizes.Small}>
        <InlineChildren>
          <Text styles={Text.Styles.Body1Emphasis}>Your Contract</Text>
          <Tag
            text="Completed"
            size={Tag.Sizes.Medium}
            type={Tag.Types.PositiveDefault}
            hasLeadingIcon
            leadingIconType={Icon.Types.CheckCircleIcon}
          />
        </InlineChildren>

        <ClickwrapWrapper hideCheckbox>
          <PSClickWrap
            dynamic
            allowDisagreed
            disableSending
            accessId={PACTSAFE_ACCESS_ID}
            groupKey={BBOT_GROUP_KEY}
            signerId={userInfo?.email}
            renderData={contractTokens}
            customData={contractTokens}
          />
        </ClickwrapWrapper>

        <Text styles={Text.Styles.Body2}>
          You can’t change this contract. Please reach out to{" "}
          <b>{contractTokens.ae_email || "your account executive"}</b> directly with any questions.
        </Text>
      </StackChildren>
    </CompletedContainer>
  );

  const recalledContent = (
    <CompletedContainer>
      <StackChildren size={StackChildren.Sizes.Small}>
        <InlineChildren>
          <Text styles={Text.Styles.Body1Emphasis}>Your contract is currently being updated.</Text>
          <Tag text="In Progress" size={Tag.Sizes.Medium} hasLeadingIcon leadingIconType={Icon.Types.InfoCircleLine} />
        </InlineChildren>
        <Text styles={Text.Styles.Body2}>
          Your Bbot representative will be in touch once complete. Please reach out to{" "}
          <b>{contractTokens.ae_email || "your account executive"}</b> directly with any questions.
        </Text>
      </StackChildren>
    </CompletedContainer>
  );

  const readyContent = (
    <StackChildren size={StackChildren.Sizes.Small}>
      <ClickwrapWrapper>
        <PSClickWrap
          allowDisagreed
          dynamic
          disableSending
          accessId={PACTSAFE_ACCESS_ID}
          groupKey={BBOT_GROUP_KEY}
          signerId={userInfo?.email}
          renderData={contractTokens}
          onValid={() => setHasAccepted(true)}
          onInvalid={() => setHasAccepted(false)}
        />
      </ClickwrapWrapper>
      <Button
        isInline
        isDisabled={!hasAccepted}
        onClick={handleAcceptContract}
        state={accepting ? Button.States.Loading : Button.States.Default}
      >
        Agree to Terms of Service
      </Button>
    </StackChildren>
  );

  const contractContent = () => {
    switch (contractStatus) {
      case ContractStatus.Ready:
        return readyContent;
      case ContractStatus.Completed:
        return completedContent;
      case ContractStatus.Recalled:
        return recalledContent;
      default:
        return <></>;
    }
  };

  return (
    <div>
      {ENV !== Environments.Prod && showDevModal && (
        <div className="margin-bottom-2">
          <Banner
            variant={Banner.Variants.Highlight}
            label="Test Mode Detected"
            onClose={() => setShowDevModal(false)}
            body={
              <StackChildren>
                <Text styles={Text.Styles.BannerBody}>
                  Non-production environment detected. Use the buttons below to set the contract tokens and status.
                </Text>

                <InlineChildren>
                  <Button isInline type={Button.Types.Tertiary} onClick={setTestContractTokens}>
                    Reset Contract Tokens
                  </Button>
                  <Button
                    isInline
                    type={Button.Types.Tertiary}
                    onClick={() => resetTaskAndContract(ContractStatus.Recalled)}
                  >
                    Recall Contract
                  </Button>
                </InlineChildren>
              </StackChildren>
            }
          />
        </div>
      )}

      {isLoading ? (
        <div className="margin-y-6">
          <Loading />
        </div>
      ) : (
        contractContent()
      )}
    </div>
  );
};

export default TermsAndConditionsWidget;

const CompletedContainer = styled.div`
  border-radius: ${BorderRadius.Medium}px;
  border: 1px solid ${Colors.BorderPrimary};
  padding: ${Spacing.Medium}px;
`;

const ClickwrapWrapper = styled.div`
  #ps-group-group-bbot-ssmo-en {
    .ps-contract {
      padding: 0;
    }

    input.ps-checkbox[type="checkbox"] {
      display: ${(props) => (props.hideCheckbox ? "none" : "")};
      margin-right: 12px;
    }

    .ps-checkbox-container {
      padding-left: ${(props) => (props.hideCheckbox ? "0px" : "")};
    }

    .ps-contract-label {
      margin-left: ${(props) => (props.hideCheckbox ? "0px" : "")};
      font-size: 14px;
      font-weight: 400;
      font-family: DD-TTNorms;
      color: ${Colors.TextPrimary};

      a {
        color: ${Colors.TextPrimary};
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
`;
