import { FILESTACK_API_KEY } from "Constants";
import { client } from "filestack-react";
import MockClient from "./MockFilestackClient";
import React, { useCallback, useContext, useRef, useState } from "react";
import { FileSizes } from "./helpers";
import { FilestackClient, FsFileMetadata } from "./types";

const DEFAULT_ACCEPTED_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png", "application/*", ".pdf", ".xlsx", ".csv"];
// @ts-ignore
export const FsClient = window.Cypress ? MockClient : client.Client;

const getFileId = (() => {
  let id = 0;
  return () => {
    id += 1;
    return id;
  };
})();

export type FileUploadContextValue = {
  acceptedTypes: string[];
  maxFileSize: number;
  minFileSize: number;
  filestackClient: FilestackClient;
  inProgressFiles: [number, File][];
  completedFiles: FsFileMetadata[];
  validateFile: (file: File) => string | Promise<string>;
  addInProgressFiles: (files: File[]) => void;
  removeInProgressFile: (fileId: number) => void;
  removeCompletedFile: (fileHandle: string) => void;
  saveCompletedFile: (file: FsFileMetadata) => void;
  saveAllCompletedFiles: () => void;
  clearAllCompletedFiles: () => void;
  clearAllInProgressFiles: () => void;
};

export const FileUploadContext = React.createContext<FileUploadContextValue>({
  acceptedTypes: DEFAULT_ACCEPTED_FILE_TYPES,
  maxFileSize: 2 * FileSizes.GB,
  minFileSize: 0,
  filestackClient: new client.Client(FILESTACK_API_KEY),
  inProgressFiles: [],
  completedFiles: [],
  validateFile: () => "",
  addInProgressFiles: () => {},
  removeInProgressFile: () => {},
  removeCompletedFile: () => {},
  saveCompletedFile: () => {},
  saveAllCompletedFiles: () => {},
  clearAllCompletedFiles: () => {},
  clearAllInProgressFiles: () => {},
});

export const useFileUploadContext = () => useContext(FileUploadContext);

export type FileUploadProviderProps = {
  acceptedTypes?: string[];
  maxFileSize?: number;
  minFileSize?: number;
  validateFile?: (file: File) => string | Promise<string>;
  onSave?: (files: FsFileMetadata[]) => void;
  savedFiles?: FsFileMetadata[];
};

const FileUploadProvider: React.FC<FileUploadProviderProps> = ({
  acceptedTypes = DEFAULT_ACCEPTED_FILE_TYPES,
  maxFileSize = 2 * FileSizes.GB,
  minFileSize = 0,
  validateFile = () => "",
  savedFiles = [],
  onSave,
  children,
}) => {
  const [inProgressFiles, setInProgressFiles] = useState<[number, File][]>([]);
  const [completedFiles, setCompletedFiles] = useState<FsFileMetadata[]>(savedFiles);
  const fileStackClient = useRef<FilestackClient>(new FsClient(FILESTACK_API_KEY));

  const addInProgressFiles = useCallback(
    (files: File[]) =>
      setInProgressFiles((prev) => [...prev, ...files.map((file) => [getFileId(), file] as [number, File])]),
    []
  );

  const removeInProgressFile = useCallback(
    (fileId: number) => setInProgressFiles((prev) => prev.filter(([id]) => id !== fileId)),
    []
  );

  const removeCompletedFile = useCallback(
    (fileHandle: string) => setCompletedFiles((prev) => prev.filter(({ handle }) => handle !== fileHandle)),
    []
  );

  const saveCompletedFile = useCallback(
    (file: FsFileMetadata) => (onSave ? onSave([file]) : setCompletedFiles((prev) => [...prev, file])),
    [onSave]
  );

  const clearAllCompletedFiles = useCallback(() => setCompletedFiles(() => []), []);

  const clearAllInProgressFiles = useCallback(() => setInProgressFiles(() => []), []);

  const saveAllCompletedFiles = useCallback(() => onSave && onSave(completedFiles), [onSave, completedFiles]);

  return (
    <FileUploadContext.Provider
      value={{
        acceptedTypes,
        maxFileSize,
        minFileSize,
        validateFile,
        filestackClient: fileStackClient.current,
        inProgressFiles,
        completedFiles,
        addInProgressFiles,
        removeInProgressFile,
        removeCompletedFile,
        saveCompletedFile,
        saveAllCompletedFiles,
        clearAllCompletedFiles,
        clearAllInProgressFiles,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadProvider;
