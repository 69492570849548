import React from "react";
import { Form } from "react-bootstrap";
import DisplayExtraCheckoutInfoOnTicketsFields from "../../../global/form/pos-integrations/DisplayExtraCheckoutInfoOnTicketsFields";
import CheckboxInput from "../../../../../src/components/global/form/CheckboxInput";
import TextInput from "../../../../../src/components/global/form/TextInput";

const OtterIntegrationModalInputs = (props) => {
  const { values, otterSettings } = props;

  return (
    <>
      <Form.Row>
        <CheckboxInput sm={12} label="Inject orders into Otter" name="injectOrders" id="inject-orders" />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          sm={12}
          label={"Sync Menu Availability Schedules from Otter"}
          name={"syncMenuAvailabilitySchedules"}
          id={"syncMenuAvailabilitySchedules"}
        />
      </Form.Row>
      <Form.Row>
        {values.injectOrders && (
          <>
            <DisplayExtraCheckoutInfoOnTicketsFields injectOrders={values.injectOrders} posName={"Otter"} />
            <CheckboxInput
              label={"Sync order fulfillment status in Bbot from Otter"}
              name={"syncFulfillmentStatusFromOtter"}
              id={"syncFulfillmentStatusFromOtter"}
            />
          </>
        )}
      </Form.Row>
      <Form.Row>
        {!otterSettings?.this_is_prod && (
          <TextInput name={"forwardWebhookToUrl"} id={"forwardWebhookToUrl"} label={"Forward Webhook to Url"} />
        )}
      </Form.Row>
    </>
  );
};

export default OtterIntegrationModalInputs;
