import React from "react";
import { Form } from "react-bootstrap";
import { SelectInput } from "components/global/form";

const OmnivoreCustomTenderTypesModalInputs = (props) => {
  const { tenderTypes } = props;

  return (
    <>
      <Form.Row>
        <SelectInput
          name={"visaTenderType"}
          id={"visa-tender-type"}
          sm={12}
          label={"Visa Tender Type"}
          isClearable={true}
        >
          {tenderTypes.map((tenderType, i) => (
            <option key={i} value={tenderType.id}>
              {tenderType.name}
            </option>
          ))}
          <option key={"dont-accept"} value={null}>
            Don't accept this card type.
          </option>
        </SelectInput>
      </Form.Row>
      <Form.Row>
        <SelectInput
          name={"amexTenderType"}
          id={"amex-tender-type"}
          sm={12}
          label={"Amex Tender Type"}
          isClearable={true}
        >
          {tenderTypes.map((tenderType, i) => (
            <option key={i} value={tenderType.id}>
              {tenderType.name}
            </option>
          ))}
          <option key={"dont-accept"} value={null}>
            Don't accept this card type.
          </option>
        </SelectInput>
      </Form.Row>
      <Form.Row>
        <SelectInput
          name={"discoverTenderType"}
          id={"discover-tender-type"}
          sm={12}
          label={"Discover/Diner's Club Tender Type"}
          isClearable={true}
        >
          {tenderTypes.map((tenderType, i) => (
            <option key={i} value={tenderType.id}>
              {tenderType.name}
            </option>
          ))}
          <option key={"dont-accept"} value={null}>
            Don't accept this card type.
          </option>
        </SelectInput>
      </Form.Row>
      <Form.Row>
        <SelectInput
          name={"mcTenderType"}
          id={"mc-tender-type"}
          sm={12}
          label={"Master Card Tender Type"}
          isClearable={true}
        >
          {tenderTypes.map((tenderType, i) => (
            <option key={i} value={tenderType.id}>
              {tenderType.name}
            </option>
          ))}
          <option key={"dont-accept"} value={null}>
            Don't accept this card type.
          </option>
        </SelectInput>
      </Form.Row>
    </>
  );
};

export default OmnivoreCustomTenderTypesModalInputs;
