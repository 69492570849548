import React from "react";
import axios from "axios";

// Components
import { Container, Row, Col, DropdownButton, Dropdown, ButtonGroup, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import FulfillmentNoticesModal from "components/owner-app/modals/fulfillment-notice-modal/FulfillmentNoticesModal";
import FulfillmentNoticeCard from "components/owner-app/cards/fulfillment-method-type-card/FulfillmentMethodTypeCard";

// Styles
import "./FulfillmentNotice.scss";
import { toast } from "react-toastify";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import BbotToggle from "bbot-component-library/BbotToggle";
import {
  trackClickNotificationAfterHoursToggle,
  trackClickPostCheckoutInstructions,
  trackClickSaveNotificationsAfterHours,
  trackClickSavePostCheckoutInstructions,
} from "instrumentation/tracking/page-tracking-events";

class FulfillmentNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fulfillmentNotices: [],
      stations: [],
      locations: [],
      fulfillmentMethods: {},
      statusNames: {},
      fulfillmentMethodPrettyNames: {},
      customer_id: null,
      is_admin: false,
      showFulfillmentNoticesModal: false,
      fulfillmentMethodNotices: [],
      selectedFMN: null,
      selectedCustomer: null,
      userInfo: null,
      doneStatuses: [],
      showPostCheckoutInstructionsModal: false,
      selectedPCIFulfillmentMethod: "",
      hoursAfterTextLimit: 0,
    };
  }

  componentDidMount = async () => {
    const { selectedCustomer } = this.props;
    const fNoticeId = this.props.match.params.fulfillmentNoticeId;

    // this.props.location.state is the object that is passed from the state prop of the Link component
    // in OwnerAppDashboard.js. Currently, I provide the is_admin and customer_id fields to react owner app
    // pages to help with API calls and conditional rendering.
    this.mounted = true;
    this.setState({ ...this.props.location.state });

    if (!selectedCustomer) {
      return;
    }

    await this.getNotices();
    await this.getFulfillmentMethods();
    await this.getFulfillmentNotice(fNoticeId);
    this.setState({ fulfillmentNoticeId: fNoticeId });
  };

  componentWillUnmount = () => {
    // this boolean is meant to detect when the component is unmounted to prevent trying to do state updates
    // in an unmounted component (which causes React to complain). TODO: I think I need to wrap all the GET calls
    // to not update state if this boolean is false as well as the POST calls
    this.mounted = false;
  };

  // used for import dropdown
  getNotices = async () => {
    const { selectedCustomer } = this.props;

    let endpoint = "/owner/fulfillmentNotices";
    try {
      const res = await axios.get(endpoint, {
        params: { customer_id: selectedCustomer?.customer_id },
      });
      this.setState({
        fulfillmentNotices: res.data.fulfillmentNotices,
      });
    } catch (error) {
      toast.error("There was an error fetching the notification settings. Please refresh the page.");
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.selectedCustomer && prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.props.history.push("/fulfillment-notices");
    } else if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      const fNoticeId = this.props.match.params.fulfillmentNoticeId;
      await this.getNotices();
      await this.getFulfillmentMethods();
      await this.getFulfillmentNotice(fNoticeId);
      this.setState({ fulfillmentNoticeId: fNoticeId });
    }
  };

  getFulfillmentNotice = async (noticeId) => {
    try {
      const res = await axios.get("/api/getFulfillmentNoticeById", {
        params: { notice_id: noticeId },
      });

      const fulfillmentMethodNotices = this.serializeFulfillmentNoticeData(res.data.fulfillment_notice).sort((a, b) =>
        a.fulfillment_method > b.fulfillment_method ? 1 : -1
      );
      this.setState({
        fulfillmentNotice: res.data.fulfillment_notice,
        fulfillmentMethodNotices: fulfillmentMethodNotices,
        hoursAfterTextLimit: res.data.fulfillment_notice.suppress_texts_after_minutes / 60,
      });
    } catch (error) {
      toast.error("There was an error fetching the notification settings. Please refresh the page.");
      console.error(error.response.data.message);
    }
  };

  // returns a copy the given fulfillment notice object
  copyFulfillmentNoticeSource = (fulfillmentMethodNoticesSource) => {
    let fulfillmentNoticesCopy = [];
    fulfillmentMethodNoticesSource.forEach((fmn) => {
      let newNotices = [];
      fmn.notices.forEach((notice) => {
        newNotices.push({
          status: notice.status,
          email: notice.email,
          text: notice.text,
        });
      });
      fulfillmentNoticesCopy.push({
        fulfillment_method: fmn.fulfillment_method,
        notices: newNotices,
      });
    });
    return fulfillmentNoticesCopy;
  };

  // Loop through texts array, add each notice to the data structure.
  serializeTexts = (texts) => {
    let fulfillmentMethodNotices = [];
    for (let i = 0; i < texts.length; i++) {
      let text = texts[i];
      const fulfillmentMethodNotice = fulfillmentMethodNotices.find((notice) => notice.fulfillment_method === text[0]);

      if (fulfillmentMethodNotice) {
        const notice = fulfillmentMethodNotice.notices.find((notice) => notice.status === text[1]);
        if (notice) {
          notice.text = text[2];
        } else {
          fulfillmentMethodNotice.notices.push({
            status: text[1],
            email: null,
            text: text[2],
          });
        }
      } else {
        fulfillmentMethodNotices.push({
          fulfillment_method: text[0],
          notices: [
            {
              status: text[1],
              email: null,
              text: text[2],
            },
          ],
        });
      }
    }
    return fulfillmentMethodNotices;
  };

  // Loop through emails array, add each notice to the data structure.
  serializeEmails = (fulfillmentMethodNoticesSource, emails) => {
    // Make a copy of fulfillmentMethodNotices
    let fulfillmentMethodNotices = this.copyFulfillmentNoticeSource(fulfillmentMethodNoticesSource);

    for (let i = 0; i < emails.length; i++) {
      let email = emails[i];
      const emailObj = email[2];
      const fulfillmentMethodNotice = fulfillmentMethodNotices.find((notice) => notice.fulfillment_method === email[0]);
      if (fulfillmentMethodNotice) {
        const notice = fulfillmentMethodNotice.notices.find((notice) => notice.status === email[1]);
        if (notice) {
          notice.email = emailObj;
        } else {
          fulfillmentMethodNotice.notices.push({
            status: email[1],
            email: emailObj,
            text: null,
          });
        }
      } else {
        fulfillmentMethodNotices.push({
          fulfillment_method: email[0],
          notices: [
            {
              status: email[1],
              email: emailObj,
              text: null,
            },
          ],
        });
      }
    }
    return fulfillmentMethodNotices;
  };

  addDefaultStatusesToFulfillmentMethods = (fulfillmentMethodsForNoticeSource) => {
    let fulfillmentMethodsForNotice = this.copyFulfillmentNoticeSource(fulfillmentMethodsForNoticeSource);

    // show all the fulfillmentNotices this customer station owns
    this.state.customerStationsFulfillmentMethods.forEach((customerStationFulfillmentMethod) => {
      if (
        !fulfillmentMethodsForNotice.some(
          (fulfillmentMethod) => fulfillmentMethod.fulfillment_method === customerStationFulfillmentMethod
        )
      ) {
        fulfillmentMethodsForNotice.push({
          fulfillment_method: customerStationFulfillmentMethod,
          notices: [],
        });
      }
    });

    const allFulfillmentMethods = this.state.fulfillmentMethods;
    // add in all statuses for each fulfillment
    fulfillmentMethodsForNotice.forEach((fulfillmentMethod) => {
      // get the statuses for the fmn
      const statuses = allFulfillmentMethods[fulfillmentMethod.fulfillment_method];
      var updatedStatusList = [];

      // add notice if status doesn't exist in notices list yet
      if (statuses) {
        statuses.forEach((status) => {
          const fmnToUpdate = fulfillmentMethod.notices.find((n) => n.status === status);

          if (fmnToUpdate) {
            updatedStatusList.push(fmnToUpdate);
          } else {
            updatedStatusList.push({
              status: status,
              email: null,
              text: null,
            });
          }
        });
      }

      fulfillmentMethod.notices = updatedStatusList;

      //update list of status for status that have values
    });
    return fulfillmentMethodsForNotice;
  };

  serializeFulfillmentNoticeData = (fulfillmentNotice) => {
    const texts = fulfillmentNotice.texts_to_patron;
    const emails = fulfillmentNotice.emails_to_patron;

    const fulfillmentMethodNoticesText = this.serializeTexts(texts);
    const fulfillmentMethodNoticesEmails = this.serializeEmails(fulfillmentMethodNoticesText, emails);
    const fulfillmentMethodNotices = this.addDefaultStatusesToFulfillmentMethods(fulfillmentMethodNoticesEmails);
    return fulfillmentMethodNotices;
  };

  // gets all available fulfillment methods for this customer.
  getFulfillmentMethods = async () => {
    const { selectedCustomer } = this.props;
    try {
      let res = await axios.get("/api/getCustomerFulfillmentMethods", {
        params: { customer_id: selectedCustomer?.customer_id },
      });

      // add in checkout complete, refunded for all fulfillment methods
      Object.keys(res.data.fulfillment_methods).forEach((method) => {
        res.data.fulfillment_methods[method].unshift("checkout_complete");
        res.data.fulfillment_methods[method].push("refunded");
      });
      // add prettyName status for checkout complete
      res.data.status_pretty_names.checkout_complete = "Checkout Complete";
      // add in an 'All' fulfillment method
      res.data.fulfillment_methods.all = ["checkout_complete", "refunded"];
      res.data.fulfillment_method_pretty_names.all = "Default";
      res.data.customer_fulfillment_methods.push("all");

      this.setState({
        customerStationsFulfillmentMethods: res.data.customer_fulfillment_methods,
        fulfillmentMethods: res.data.fulfillment_methods,
        statusNames: res.data.status_pretty_names,
        fulfillmentMethodPrettyNames: res.data.fulfillment_method_pretty_names,
        doneStatuses: res.data.done_statuses,
      });
    } catch (error) {
      toast.error("There was an error fetching the notification settings. Please refresh the page.");
      console.error(error.response.data.message);
      this.props.history.push("/login");
    }
  };

  // When save is pressed from modal, this updates the state accordingly
  updateStateFromSave = (payload) => {
    let { fulfillmentMethodNotices } = this.state;
    let fulfillmentMethodNotice = fulfillmentMethodNotices.find(
      (fmn) => fmn.fulfillment_method === payload.fulfillmentMethod
    );
    let notice = fulfillmentMethodNotice.notices.find((notice) => notice.status === payload.status);
    notice.email = payload.email;
    notice.text = payload.text;
    this.setState({ fulfillmentMethodNotices: fulfillmentMethodNotices });
  };

  hideShowFulfillmentNoticesModal = () => {
    this.setState({ showFulfillmentNoticesModal: false });
  };

  rowClicked = (notice, fulfillmentMethod) => {
    notice.fulfillmentMethod = fulfillmentMethod;
    this.setState({ showFulfillmentNoticesModal: true, selectedFMN: notice });
  };

  importConfig = async (fulfillmentNotice) => {
    const fulfillmentNoticeId = fulfillmentNotice.id;
    const endpoint = "/owner/importFulfillmentNotice";
    try {
      const payload = {
        targetFulfillmentId: this.state.fulfillmentNotice.id,
        sourceFulfillmentId: fulfillmentNoticeId,
      };
      await axios.post(endpoint, payload);
      this.getFulfillmentNotice(this.state.fulfillmentNotice.id);
      toast.success("Successfully copied messages from " + fulfillmentNotice.name + ".");
    } catch (error) {
      console.error(error.response.data.message);
      toast.error("Error attempting to copy messages. Please refresh.");
    }
  };

  savePostCheckoutInstructions = async (values, { resetForm }) => {
    try {
      trackClickSavePostCheckoutInstructions();
      const { fulfillmentNotice, selectedPCIFulfillmentMethod } = this.state;
      const payload = {
        fulfillmentNoticeId: fulfillmentNotice.id,
        fulfillmentMethod: selectedPCIFulfillmentMethod,
        postCheckoutInstructions: values.postCheckoutInstructions,
      };
      await axios.post("/api/savePostCheckoutInstructions", payload);
      this.setState({ showPostCheckoutInstructionsModal: false });
      await this.getFulfillmentNotice(fulfillmentNotice.id);
      resetForm();
    } catch (error) {
      console.error(error);
      toast.error("Trouble saving Post Checkout Instructions. Please refresh and try again.");
    }
  };

  postCheckoutInstructionsModal = () => {
    const { showPostCheckoutInstructionsModal, fulfillmentNotice, selectedPCIFulfillmentMethod } = this.state;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          postCheckoutInstructions: fulfillmentNotice?.post_checkout_instructions[selectedPCIFulfillmentMethod] || "",
        }}
        validationSchema={Yup.object({
          postCheckoutInstructions: Yup.string(),
        })}
        onSubmit={this.savePostCheckoutInstructions}
      >
        {({ errors, handleSubmit }) => (
          <Modal
            show={showPostCheckoutInstructionsModal}
            onHide={() => this.setState({ showPostCheckoutInstructionsModal: false })}
          >
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Post Checkout Instructions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={"field-wrapper form-group"}>
                  <div className={"field-label"}>
                    What instructions should be shown on the website after a guest checks out?
                  </div>
                  <Field
                    id="postCheckoutInstructions"
                    name="postCheckoutInstructions"
                    type="text"
                    className={"form-control text-input"}
                  />
                  {errors.postCheckoutInstructions && <div className="error">{errors.postCheckoutInstructions}</div>}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type={"submit"}>Save</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    );
  };

  saveHoursAfterTextLimit = async () => {
    const { hoursAfterTextLimit, fulfillmentNotice } = this.state;
    const { selectedCustomer } = this.props;
    trackClickSaveNotificationsAfterHours();

    try {
      await axios.post("owner/saveSuppressTextTimeLimit", {
        minutes_limit: isNaN(hoursAfterTextLimit) ? 0 : hoursAfterTextLimit * 60,
        fulfillment_notice_id: fulfillmentNotice.id,
        customer_id: selectedCustomer.customer_id,
      });
      toast.success("Successfully updated notification settings.");
    } catch (error) {
      console.error(error);
      toast.error("We could not update your notification settings. Please refresh and try again.");
    }
  };

  render() {
    const {
      showFulfillmentNoticesModal,
      customerStationsFulfillmentMethods,
      fulfillmentNotices,
      fulfillmentNotice,
      hoursAfterTextLimit,
    } = this.state;
    const { fulfillmentMethodNotices } = this.state;
    const { selectedCustomer } = this.state;
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className={"breadcrumbs-row margin-bottom-4"}>
                <span>
                  <Link to={"/fulfillment-notices"} className={"breadcrumb-back"}>
                    &#60; Back
                  </Link>
                </span>
                <span className={"breadcrumbs"}>
                  <span>
                    <Link to={"/"} className={"breadcrumb-link"}>
                      Owner Portal
                    </Link>
                  </span>
                  <span className={"breadcrumb-link"}>&#62;</span>
                  <span>
                    <Link to={"/fulfillment-notices"} className={"breadcrumb-link"}>
                      Guest Notification Sets
                    </Link>
                  </span>
                  <span className={"breadcrumb-link"}>&#62;</span>
                  <span>
                    <span className={"breadcrumb-link"}>{this.state.fulfillmentNotice?.name}</span>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"margin-bottom-4"}>
                <h2>
                  <span className={"color-primary__dark"}>
                    {selectedCustomer ? selectedCustomer.customer_name + ": " : ""}
                  </span>
                  {this.state.fulfillmentNotice?.name}
                </h2>
              </div>
              <div className={"margin-bottom-4"}>
                <p>These texts or emails will be sent to the guest when an order reaches the specified status.</p>
                <p>
                  <i>For a good guest experience, we recommend no more than 2 messages per fulfillment method.</i>
                </p>
              </div>
            </Col>
          </Row>
          <hr className={"margin-bottom-3"} />
          <Row>
            <Col>
              <div className={"d-flex justify-content-start margin-bottom-3"}>
                <span className={"margin-right-2"}>
                  <BbotToggle
                    defaultEnabled={hoursAfterTextLimit > 0}
                    onClick={(e) => {
                      trackClickNotificationAfterHoursToggle({ toggle_on: e.SWITCH_STATE.enabled });
                      this.setState(
                        {
                          hoursAfterTextLimit: e.SWITCH_STATE.enabled ? 4 : 0,
                        },
                        () => {
                          if (!e.SWITCH_STATE.enabled) {
                            this.saveHoursAfterTextLimit();
                          }
                        }
                      );
                    }}
                  />
                </span>
                <span>
                  <p>Prevent messages from being sent after guest's desired time?</p>
                </span>
              </div>
              {(isNaN(hoursAfterTextLimit) || hoursAfterTextLimit > 0) && (
                <Row className={"d-flex align-items-end"}>
                  <Form.Group className={"form-group"} as={Col} sm={8}>
                    <Form.Label>
                      For up to how many hours after the guest's desired time should staff be able to send messages?
                    </Form.Label>
                    <Form.Control
                      type={"number"}
                      className={"text-input"}
                      value={hoursAfterTextLimit}
                      onChange={(e) => {
                        const newLimit = e.target.value;
                        this.setState(
                          {
                            hoursAfterTextLimit: parseInt(newLimit),
                          },
                          () => {
                            if (newLimit === "0") {
                              this.saveHoursAfterTextLimit();
                            }
                          }
                        );
                      }}
                    />
                  </Form.Group>
                  <Col>
                    <Form.Group>
                      <Button variant={"outline-primary"} onClick={this.saveHoursAfterTextLimit}>
                        Save
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"margin-bottom-4"}>
                {fulfillmentNotices?.length > 1 && (
                  <DropdownButton
                    as={ButtonGroup}
                    id={"import"}
                    title={"Copy messages from"}
                    variant={"outline-primary"}
                    size={"lg"}
                  >
                    {fulfillmentNotices?.map(
                      (fn) =>
                        fn.id !== fulfillmentNotice?.id && (
                          <Dropdown.Item onClick={() => this.importConfig(fn)} key={fn.id}>
                            {fn.name}
                          </Dropdown.Item>
                        )
                    )}
                  </DropdownButton>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {fulfillmentMethodNotices
              .filter((fulfillmentNotice) =>
                customerStationsFulfillmentMethods.includes(fulfillmentNotice.fulfillment_method)
              )
              .map((d, index) => (
                <Col key={"fulfillment-notice-card-" + index} lg={4} sm={6} className={"margin-bottom-2"}>
                  <FulfillmentNoticeCard
                    openModal={() => this.setState({ showFulfillmentNoticesModal: true })}
                    updateSelectedFMN={(data) => {
                      d.notices.push(data);

                      this.setState({
                        selectedFMN: data,
                        showFulfillmentNoticesModal: true,
                      });
                    }}
                    fulfillmentMethod={d}
                    rowClicked={this.rowClicked}
                    postCheckoutInstructionsClicked={(fulfillmentMethod) => {
                      trackClickPostCheckoutInstructions();
                      this.setState({
                        showPostCheckoutInstructionsModal: true,
                        selectedPCIFulfillmentMethod: fulfillmentMethod,
                      });
                    }}
                    statusNames={this.state.statusNames}
                    fulfillmentMethodPrettyNames={this.state.fulfillmentMethodPrettyNames}
                  />
                </Col>
              ))}
          </Row>
        </Container>
        <FulfillmentNoticesModal
          show={showFulfillmentNoticesModal}
          onHide={this.hideShowFulfillmentNoticesModal}
          fulfillmentMethodNotice={this.state.selectedFMN}
          statusNames={this.state.statusNames}
          fulfillmentMethodPrettyNames={this.state.fulfillmentMethodPrettyNames}
          fulfillmentNoticeId={this.state.fulfillmentNoticeId}
          doneStatuses={this.state.doneStatuses}
          saveCallback={(payload) => {
            this.updateStateFromSave(payload);
          }}
        />
        {this.postCheckoutInstructionsModal()}
      </div>
    );
  }
}

export default FulfillmentNotice;
