import React, { useState, useEffect } from "react";

import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "util/pos-integrations/IntegrationConnectModalUtils";

import * as Yup from "yup";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import SubmitCancelButtonsWithLoader from "components/global/form/SubmitCancelButtonsWithLoader";
import { TextInput } from "../../../global/form";
import PromptYesNoPOSIntegrationModal from "components/global/modals/pos-integrations/PromptYesNoPOSIntegrationModal";

const GiftCardModal = (props) => {
  const [giftCardSettings, setGiftCardSettings] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { selectedCustomer, disconnectMode, show, onClose, onSave, integration, integrationPrettyName } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getGiftCardSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getGiftCardSettings = async () => {
    const getSettingsUrl = "/admin/getGiftCardConnectionStatus";
    setLoadingData(true);
    await getIntegrationSettings(getSettingsUrl, integrationPrettyName, selectedCustomer, saveGiftCardSettings);
    setLoadingData(false);
  };

  const saveGiftCardSettings = async (giftCardSettings) => {
    setGiftCardSettings(giftCardSettings);
  };

  const connectGiftCardIntegration = async (values) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      integration: integration,
      external_reference_id: values?.externalReferenceId,
      external_store_id: values?.externalStoreId,
    };
    await connectIntegration(
      "/admin/connectGiftCardIntegration",
      payload,
      integrationPrettyName,
      setLoadingData,
      onConnectSuccess
    );
  };

  const onConnectSuccess = async () => {
    await getGiftCardSettings();
    onSave();
    onClose();
  };

  const disconnectGiftCardIntegration = async () => {
    await disconnectIntegration(
      "/admin/disconnectGiftCardIntegration",
      integrationPrettyName,
      selectedCustomer,
      false,
      setLoadingData,
      onDisconnectSuccess,
      integration
    );
  };

  const onDisconnectSuccess = async () => {
    await getGiftCardSettings();
    onClose();
    onSave();
  };

  const disconnectModal = () => {
    return (
      <PromptYesNoPOSIntegrationModal
        onHide={onClose}
        show={show}
        onSubmit={disconnectGiftCardIntegration}
        onClose={onClose}
        integrationSourceName={integrationPrettyName}
        warningText={`Are you sure you would like to disconnect ${integrationPrettyName}?`}
        submitText={"Disconnect"}
        title={`Disconnect ${integrationPrettyName}`}
        cancelText={"Cancel"}
      />
    );
  };

  const submitEditSettings = async (values) => {
    await connectGiftCardIntegration(values);
    await getGiftCardSettings();
  };

  const editSettingsModal = () => {
    let initialValues = {
      externalReferenceId: giftCardSettings.external_reference_id ?? "",
      externalStoreId: giftCardSettings.external_store_id ?? "",
    };

    let validationSchema = Yup.object({});

    return (
      <Modal show={show} onHide={onClose}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            submitEditSettings(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Connect {integrationPrettyName}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row>
                  <TextInput label={"Merchant ID"} name={"externalReferenceId"} id={"externalReferenceId"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Location ID"} name={"externalStoreId"} id={"externalStoreId"} />
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <SubmitCancelButtonsWithLoader loadingData={loadingData} errors={errors} onCancel={onClose} />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  return editSettingsModal();
};

export default GiftCardModal;
