import React from "react";
import { Form } from "react-bootstrap";
import { SearchSelectInput } from "../../../global/form";

const RevelIntegrationEstablishmentInputs = (props) => {
  const { values } = props;

  return (
    <>
      <div className={"margin-bottom-2"}>
        <p>
          Select the Revel establishment you wish to link to Bbot. After saving, you will need to disconnect and
          reconnect the integration to change establishments
        </p>
      </div>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          isSearchable
          id={"selectedRevelEstablishment"}
          md={12}
          name={"selectedRevelEstablishment"}
          label={"Revel Establishment"}
          placeholder={"Select a Revel establishment to connect"}
          defaultValue={values.selectedRevelEstablishment}
          value={values.selectedRevelEstablishment}
          options={values.allRevelEstablishments.map((establishment) => ({
            label: establishment.name,
            value: establishment.id,
          }))}
        />
      </Form.Row>
    </>
  );
};

export default RevelIntegrationEstablishmentInputs;
