import React from "react";

// Components
import ActivityFeed from "components/owner-app/activity-feed/ActivityFeed";
import { Container, Col, Row } from "react-bootstrap";

class IntegrationsActivityFeedPage extends React.Component {
  render() {
    const { selectedCustomer } = this.props;
    return (
      <div>
        {selectedCustomer ? (
          <Container>
            <Row>
              <Col md={12}>
                <h2>Integrations Activity Feed</h2>
                <p>View possible issues with your integrations.</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ActivityFeed customerId={selectedCustomer.customer_id} feedType="pos" />
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="margin-x-8 margin-y-2">
            <p>Please select a customer from the dropdown above.</p>
          </div>
        )}
      </div>
    );
  }
}

export default IntegrationsActivityFeedPage;
