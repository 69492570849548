import React, { useState, useEffect } from "react";
import { Alert, Button, Modal, Row, Spinner, Tab, Col, Tabs, Card, CloseButton } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { SelectInput, TextInput } from "components/global/form";
import { generalErrorAlert } from "util/Utils";
import { toast } from "react-toastify";
import { trackClickResetEmployeePassword, trackSaveEditEmployee } from "instrumentation/tracking/page-tracking-events";

const EditEmployeeModal = (props) => {
  const { show, onHideCallback, onSaveCallback, employee, employees, selectedCustomer, allowedCustomersById } = props;

  // TAB STATE
  const [tabActiveKey, setTabActiveKey] = useState("editEmployee");

  const [isSaving, setIsSaving] = useState(false);
  const [isResettingPwd, setIsResettingPwd] = useState(false);
  const [availableReports, setAvailableReports] = useState([]);
  const [availablePeriodicities, setAvailablePeriodicities] = useState([]);
  const [availableGroupTimesBy, setAvailableGroupTimesBy] = useState([]);
  const [subscribedReports, setSubscribedReports] = useState([]);
  const [showAddReport, setShowAddReport] = useState(false);

  // NEW REPORT STATE HELPERS
  const [newReportType, setNewReportType] = useState("");
  const [newReportPeriod, setNewReportPeriod] = useState("");
  const [newReportGroupBy, setNewReportGroupBy] = useState("");

  useEffect(() => {
    getAvailableReports();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSubscribedReports();
  }, [selectedCustomer, employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSubscribedReports = async () => {
    if (!selectedCustomer || !employee) return;

    try {
      const res = await axios.get("api/reportSubscriptions", {
        params: {
          customer_id: selectedCustomer?.customer_id,
          bartender_id: employee?.user?.id,
        },
      });
      setSubscribedReports(res.data.report_subscriptions);
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const getAvailableReports = async () => {
    try {
      const res = await axios.get("api/availablereports", {
        params: {
          customer_id: selectedCustomer?.customer_id,
        },
      });
      setAvailableReports(res.data.available_reports);
      setAvailablePeriodicities(res.data.available_periodicities);
      setAvailableGroupTimesBy(res.data.available_group_times_by);

      // default dropdowns to first selections
      setNewReportType(res.data.available_reports[0].report_id);
      setNewReportPeriod(res.data.available_periodicities[0]);
      setNewReportGroupBy(res.data.available_group_times_by[0]);
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const subscribeToNewReport = async () => {
    try {
      // if already subscribed to this exact report, tell user, don't subscribe again.
      if (
        subscribedReports.filter(
          (report) =>
            report.report_id === newReportType &&
            report.periodicity === newReportPeriod &&
            report.group_times_by === newReportGroupBy
        ).length > 0
      ) {
        toast.info("Already subscribed to this report.");
        return;
      }

      const payload = {
        customer_id: selectedCustomer?.customer_id,
        bartender_id: employee?.user?.id,
        report_id: newReportType,
        report_period: newReportPeriod,
        report_group_by: newReportGroupBy,
      };
      await axios.put("api/reportSubscriptions", payload);
      await getSubscribedReports();
      setShowAddReport(false);
      // reset states after adding
      setNewReportType(availableReports[0].report_id);
      setNewReportPeriod(availablePeriodicities[0]);
      setNewReportGroupBy(availableGroupTimesBy[0]);
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const deleteSubscription = async (subscriptionIndex) => {
    try {
      const subscriptions = [...subscribedReports];
      subscriptions.splice(subscriptionIndex, 1);

      const payload = {
        customer_id: selectedCustomer?.customer_id,
        bartender_id: employee?.user?.id,
        subscriptions: subscriptions,
      };
      await axios.post("api/reportSubscriptions", payload);
      await getSubscribedReports();
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const pins = employees.filter((emp) => emp?.user?.id !== employee?.user?.id).map((emp) => emp.pin_number);

  const employeeBelongsToAnother = employee && employee?.customer !== selectedCustomer?.customer_id;

  const showGroupByOptions = availableReports.find(
    (report) => report.report_id === newReportType
  )?.accepts_group_times_by;

  const resetPassword = async () => {
    setIsResettingPwd(true);
    trackClickResetEmployeePassword();
    try {
      const payload = {
        username: employee.user.username,
        email_address: employee.user.email,
        redirect_to_owner: true,
      };
      await axios.post("/user/password_reset/", payload);
      toast.success("Sent this user a password reset email. Please follow the given instructions.");
    } catch (error) {
      console.error(error);
      generalErrorAlert(error);
    }
    setIsResettingPwd(false);
  };

  const saveEmployee = async (values) => {
    setIsSaving(true);
    trackSaveEditEmployee();
    try {
      const payload = {
        update_owners_user_info: true,
        pin_number: values.pinNumber,
        customer_id: selectedCustomer?.customer_id,
        level: values.level,
        admin_note: "",
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          username: employee.user.username,
        },
      };
      await axios.put("api/allbartenders", payload);
      onHideCallback();
    } catch (error) {
      // if 405 it is ok, we saved PIN like user expected us to.
      if (error.response.status === 405) {
        onHideCallback();
      } else {
        console.error(error);
        generalErrorAlert(error);
      }
    }
    onSaveCallback();
    setIsSaving(false);
  };

  const subscriptionReports = () => {
    return (
      <>
        <div className={"margin-bottom-2"}>
          <div className={"d-flex justify-content-space-between margin-bottom-4"}>
            <h3 className={"color-neutral__placeholder"}>Employee Report Subscriptions</h3>

            {!showAddReport && (
              <Button size={"sm"} variant={"light"} onClick={() => setShowAddReport(true)}>
                + Add Subscription
              </Button>
            )}
          </div>

          {subscribedReports.map((report, index) => {
            const reportName = availableReports.find(
              (availableReport) => availableReport.report_id === report.report_id
            )?.display_name;
            return (
              <div className={"margin-bottom-2"} key={index}>
                <div className={"d-flex justify-content-space-between"}>
                  <div>
                    <div>{reportName}</div>
                    <div
                      className={"text-small-detail"}
                    >{`Ran ${report.periodicity}, grouped by ${report.group_times_by}`}</div>
                  </div>
                  <div>
                    <Button size={"sm"} variant={"outline-primary"} onClick={() => deleteSubscription(index)}>
                      <i className={"zmdi zmdi-check inline-icon"} />
                      Subscribed
                    </Button>
                  </div>
                </div>
                {index < subscribedReports.length - 1 && <hr />}
              </div>
            );
          })}

          {showAddReport && (
            <Card className={"margin-bottom-2"}>
              <Card.Header className={"d-flex justify-content-space-between"}>
                <span>Subscribe To New Report</span>
                <CloseButton onClick={() => setShowAddReport(false)} />
              </Card.Header>
              <Card.Body>
                <Row className={"margin-bottom-2"}>
                  <Col sm={12}>
                    <div className={"text-small-detail"}>Report</div>
                    <select className={"form-control"} onChange={(e) => setNewReportType(e.target.value)}>
                      {availableReports.map((report, i) => (
                        <option key={i} value={report.report_id}>
                          {report.display_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className={"margin-bottom-2"}>
                  <Col sm={showGroupByOptions ? 6 : 12}>
                    <div className={"text-small-detail"}>Report Period</div>
                    <select className={"form-control"} onChange={(e) => setNewReportPeriod(e.target.value)}>
                      {availablePeriodicities.map((period) => (
                        <option key={period} value={period}>
                          {period}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {showGroupByOptions && (
                    <Col sm={6}>
                      <div className={"text-small-detail"}>Group By</div>
                      <select className={"form-control"} onChange={(e) => setNewReportGroupBy(e.target.value)}>
                        {availableGroupTimesBy.map((grouping) => (
                          <option key={grouping} value={grouping}>
                            {grouping || "None"}
                          </option>
                        ))}
                      </select>
                    </Col>
                  )}
                </Row>
                <Row className={"margin-bottom-1"}>
                  <Col sm={12}>
                    <Button style={{ width: "100%" }} onClick={subscribeToNewReport}>
                      Subscribe To Report
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onHideCallback} size={"lg"} className={"edit-employee-modal"}>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: employee?.user.first_name || "",
          lastName: employee?.user.last_name || "",
          emailAddress: employee?.user.email || "",
          pinNumber: employee?.pin_number || "",
          level: employee?.level || "bartender",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required("This field is required"),
          lastName: Yup.string().required("This field is required"),
          emailAddress: Yup.string().email("Please enter a valid email").required("This field is required"),
          pinNumber: Yup.string()
            .matches(/^[0-9]{4}$/, "Pin must be 4 digits")
            .test("unique-pin", "Pin not available", (value, context) => !pins.includes(value))
            .required("This field is required"),
        })}
        onSubmit={saveEmployee}
      >
        {({ values, errors, touched, isValidating, handleSubmit }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Edit Employee Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {employeeBelongsToAnother && (
                <Alert variant={"warning"}>
                  {allowedCustomersById.hasOwnProperty(employee?.customer)
                    ? `This employee belongs to ${allowedCustomersById[employee.customer].name_for_owner}. `
                    : "This employee belongs to another customer. "}
                  Here, you may only edit the PIN and subscriptions to reports.
                </Alert>
              )}
              <Tabs id="edit-employee-tabs" activeKey={tabActiveKey} onSelect={(key) => setTabActiveKey(key)}>
                <Tab eventKey={"editEmployee"} title={"Employee Information"}>
                  <div className={"padding-4"}>
                    <Row className={"margin-bottom-1"}>
                      <TextInput
                        testId={"edit-first-name"}
                        sm={6}
                        name={"firstName"}
                        id={"first-name"}
                        label={"First Name"}
                        disabled={employeeBelongsToAnother}
                      />
                      <TextInput
                        testId={"edit-last-name"}
                        sm={6}
                        name={"lastName"}
                        id={"last-name"}
                        label={"Last Name"}
                        disabled={employeeBelongsToAnother}
                      />
                    </Row>
                    <Row className={"margin-bottom-1"}>
                      <TextInput
                        testId={"edit-email"}
                        disabled
                        name={"emailAddress"}
                        id={"email-address"}
                        label={"Email"}
                      />
                    </Row>
                    <Row className={"margin-bottom-1"}>
                      <TextInput testId={"edit-pin"} name={"pinNumber"} id={"pin-number"} label={"Pin"} />
                    </Row>
                    <Row className={"margin-bottom-2"}>
                      <SelectInput
                        testId={"edit-level"}
                        name={"level"}
                        id={"level"}
                        className={"margin-bottom-0"}
                        label={"Level"}
                        disabled={employeeBelongsToAnother}
                      >
                        <option value={"Manager"}>Manager</option>
                        <option value={"Owner"}>Owner</option>
                        <option value={"Server"}>Server</option>
                        <option value={"Bartender"}>Bartender</option>
                      </SelectInput>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey={"editReportSubscriptions"} title={"Report Subscriptions"}>
                  <div className={"padding-4"}>{subscriptionReports()}</div>
                </Tab>
              </Tabs>
            </Modal.Body>
            {tabActiveKey === "editEmployee" && (
              <Modal.Footer>
                <div className={"d-flex justify-content-space-between full-width"}>
                  <Button
                    data-test-id={"send-reset-pwd-email"}
                    size={"sm"}
                    variant={"light"}
                    onClick={resetPassword}
                    disabled={isResettingPwd}
                  >
                    {isResettingPwd ? (
                      <Spinner as="span" animation={"border"} size={"sm"} aria-hidden="true" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                  <span>
                    <Button
                      data-test-id={"cancel-edit-employee"}
                      size={"sm"}
                      className={"margin-right-2"}
                      variant={"light"}
                      onClick={onHideCallback}
                    >
                      Cancel
                    </Button>
                    <Button data-test-id={"submit-edit-employee"} size={"sm"} type={"submit"} disabled={isSaving}>
                      {isSaving ? <Spinner as="span" animation={"border"} size={"sm"} aria-hidden="true" /> : <>Save</>}
                    </Button>
                  </span>
                </div>
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditEmployeeModal;
