import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, BbotButton, BbotTabs, BbotAlert, Tabs } from "bbot-component-library";

import PropTypes from "prop-types";

/*
Container for editing options, with multiple tabs, a save button that appears when there are changes to save, and an
(optional) alert that specifies which objects are being edited
*/

const EditSettingsContainer = ({
  id,
  selectedNames,
  tabs,
  objectToEditName,
  hideEditFunction,
  showSave = false,
  showAlert = true,
  onSave = () => {},
  forceRender = false,
  ...props
}) => {
  // Throw a confirmation alert when user tries to close window with unsaved changes
  useEffect(() => {
    const alertUser = (e) => {
      if (showSave) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [showSave]); // eslint-disable-line react-hooks/exhaustive-deps

  // Change the alert message based on how many items are selected
  let alertMessage = <div></div>;
  if (!selectedNames || selectedNames.length === 0) {
    alertMessage = (
      <div>
        <span>Nothing selected.</span>
        <Button type={"link"} onClick={hideEditFunction}>
          Click here to make a selection
        </Button>
      </div>
    );
  } else if (selectedNames.length === 1) {
    alertMessage = (
      <div>
        <span>
          Editing the following {objectToEditName}: <strong>{selectedNames}</strong>.
        </span>
        <Button type={"link"} onClick={hideEditFunction}>
          Click here to change
        </Button>
      </div>
    );
  } else {
    alertMessage = (
      <div>
        <span>
          Editing the following {objectToEditName}s: <strong>{selectedNames.join(", ")}</strong>.
        </span>
        <Button type={"link"} onClick={hideEditFunction}>
          Click here to change
        </Button>
        <br />
        Saving changes will affect ALL {objectToEditName}s selected.
      </div>
    );
  }
  // Render component
  return (
    <>
      {showAlert && <BbotAlert message={alertMessage} className="margin-bottom-2" />}
      <BbotTabs
        tabBarExtraContent={
          showSave && (
            <BbotButton type={"primary"} onClick={onSave} id={id + "-save-button"}>
              Save
            </BbotButton>
          )
        }
        id={id}
      >
        {/* Populate tabs */}
        {tabs.map((tab) => {
          return (
            <StyledTabPane
              tab={tab.name}
              key={tab.key ? tab.key : tab.name.toLowerCase()}
              id={tab.key ? tab.key : tab.name.toLowerCase()}
              forceRender={forceRender}
            >
              {tab.content}
            </StyledTabPane>
          );
        })}
      </BbotTabs>
    </>
  );
};

export default EditSettingsContainer;

const StyledTabPane = styled(Tabs.TabPane)`
  > div {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  > form {
    > div {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

EditSettingsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired, // Array of objects where each object has a 'name' (the tab title) and 'content'
  // (the JSX object to be displayed within that tab)
  showSave: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  showAlert: PropTypes.bool, // If true an alert at the top of the page will show information about the selected
  // object(s). Below values populate this alert
  selectedNames: PropTypes.array, // The names of the selected objects
  objectToEditName: PropTypes.string, // The generic name of the object type, for example: "location"
  hideEditFunction: PropTypes.func,
};
