import React, { useState } from "react";
import axios from "axios";

// Components
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";

// Styles

// Config

const FullstoryControls = () => {
  const [fullstoryCustomers, setFullstoryCustomers] = useState([]);
  const [isDisabling, setIsDisabling] = useState(false);

  const CustomerCard = ({ customer }) => {
    const handleDelete = async (customer) => {
      setIsDisabling(true);
      try {
        const response = await axios.post("admin/disableFS", {
          customerPk: customer.pk,
        });
        if (response.status !== 204) {
          throw new Error();
        }
        setFullstoryCustomers(fullstoryCustomers.filter((fsCustomer) => fsCustomer.pk !== customer.pk));
        toast.success(`Successfully disconnected ${customer.name_for_admin} from Fullstory.`, {
          autoClose: 2000,
        });
      } catch (err) {
        console.error(err);
        toast.error("Could not Disable Fullstory");
      }
      setIsDisabling(false);
    };

    return (
      <Card body>
        <p className="d-inline" style={{ lineHeight: "3" }}>
          {customer.name_for_admin}
        </p>
        <Button
          className="float-right"
          style={{ marginTop: "5px" }} // annoying way to vertically align the button
          variant="danger"
          onClick={() => handleDelete(customer)}
          disabled={isDisabling}
        >
          Disable
        </Button>
      </Card>
    );
  };

  const FullstoryCustomerList = ({ customers }) => {
    return (
      <div style={{ maxHeight: 500, overflow: "auto" }}>
        {customers.map((customer) => (
          <CustomerCard customer={customer} key={customer.pk} />
        ))}
      </div>
    );
  };

  const fetchFullstoryCustomers = async () => {
    try {
      const response = await axios.get("admin/getAllEnabledFullstory");

      const fsCustomers = response.data.customers_with_fs_enabled;

      if (fsCustomers.length === 0) {
        toast.info("No customers currently have Fullstory enabled.");
        return;
      }

      setFullstoryCustomers(fsCustomers);

      const pluralOrNot = fsCustomers.length > 1 ? "s" : "";

      toast.success(`Found ${fsCustomers.length} customer${pluralOrNot} with Fullstory Enabled.`, {
        autoClose: 3000,
      });
    } catch (err) {
      console.error(err);
      toast.error("Sorry, something went wrong and the search for customers with Fullstory enabled was not completed.");
    }
  };

  return (
    <div className="shadow border border-secondary padding-3 bg-light rounded">
      <h3 className="margin-y-4 justify-content-center">
        Fullstory Controls:
        <span className="float-right">
          <Button className="margin-right-3" variant="primary" onClick={fetchFullstoryCustomers}>
            Search
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setFullstoryCustomers([]);
            }}
          >
            Hide Results
          </Button>
        </span>
      </h3>
      <p>Searches for all customers with Fullstory enabled and allows you to disable them.</p>
      {fullstoryCustomers && fullstoryCustomers.length > 0 ? (
        <FullstoryCustomerList customers={fullstoryCustomers} />
      ) : null}
    </div>
  );
};

export default FullstoryControls;
