import React from "react";
import { Form } from "react-bootstrap";
import { TextInput } from "../../../global/form";

const RevelIntegrationApiConfigInputs = (props) => {
  return (
    <>
      <div className={"margin-bottom-2"}>
        <p>Enter the Revel API key, secret, and url provided by Revel</p>
        <p>
          To use this integration, you will also need to configure a custom payment type that includes "bbot" in the
          name. For more info, check out{" "}
          <a href={"https://support.revelsystems.com/s/article/Customizable-Payment-Methods"}>this article</a>
        </p>
      </div>
      <Form.Row>
        <TextInput label={"Revel Domain Name"} name={"revelApiUrl"} id={"revelApiUrl"} />
      </Form.Row>
      <Form.Row>
        <TextInput label={"API Key for Revel"} name={"revelApiKey"} id={"revelApiKey"} />
      </Form.Row>
      <Form.Row>
        <TextInput label={"API Secret for Revel"} name={"revelApiSecret"} id={"revelApiSecret"} />
      </Form.Row>
    </>
  );
};

export default RevelIntegrationApiConfigInputs;
