class UserScheduleValidation {
  /*
  This class has several methods to validate and format a user inputted schedule
  (ie the one at ownerapp/manage-print-rules or Available hours + menu settings.
   */

  static nextDay = ["tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "monday"];

  static _toMilitaryTime = (time_string, scheduleAndIsValid) => {
    const regex = /^(\d\d?):?(\d\d)?(AM|PM)$/g;

    try {
      let match = regex.exec(time_string);
      // eslint-disable-next-line no-unused-vars
      let [v1, hour_string, minute_string, meridian_string] = match;

      if (!minute_string) {
        minute_string = "00";
      }

      let hour_int = parseInt(hour_string);

      if (hour_int === 12) {
        hour_int = 0;
      }

      return 1200 * (meridian_string === "PM" ? 1 : 0) + hour_int * 100 + parseInt(minute_string);
    } catch (err) {
      scheduleAndIsValid.userScheduleIsValid = false;
    }
  };

  static _parseTimeRange = (time_range_string, allow_single_times, scheduleAndIsValid) => {
    let [t0str, t1str] = time_range_string.split("-");

    if (allow_single_times && t1str == null) {
      t1str = t0str;
    }

    let t0 = UserScheduleValidation._toMilitaryTime(t0str, scheduleAndIsValid);
    let t1 = UserScheduleValidation._toMilitaryTime(t1str, scheduleAndIsValid);

    if (t1 === 0) {
      t1 = 2400;
    }

    return [t0, t1];
  };

  static userInputtedSingleDayScheduleIsValid = (daySchedule) => {
    /*
    Validates a single day of user inputted schedule (eg 12AM-12AM)
     */

    //No user input is fine
    if (daySchedule == null || daySchedule === "") {
      return true;
    }

    //Don't accept a bunch of spaces
    if (daySchedule.replaceAll(" ", "") === "") {
      return false;
    }

    let scheduleAndIsValid = {};
    scheduleAndIsValid.userScheduleIsValid = true;

    let cleanCustomerInput = daySchedule
      .replace(/[\s.]/g, "")
      .toUpperCase()
      .replace("CLOSED", "")
      .replace("MIDNIGHT", "12AM")
      .replace("OPEN", "12AM-12AM");
    let timeRanges = cleanCustomerInput.replaceAll(" ", "").split(",");

    if (timeRanges != null) {
      timeRanges.forEach((time_range) => {
        if (time_range) {
          UserScheduleValidation._parseTimeRange(time_range, false, scheduleAndIsValid);
        }
      });
    }

    return scheduleAndIsValid.userScheduleIsValid;
  };

  static parseUserInputtedSchedule = (schedule, allow_single_times = false) => {
    /*
    * Takes user inputted schedule and tries to make it how our server expects it
    * server expects -> {Monday:{customer_input:'9AM - 2AM', times:[(0000-0200),(0900,2359)]},
                         Tuesday:{customer_input:'9AM - 2AM', times:[(0000-0200),(0900,2359)]}
                        }
    * If cannot parse userScheduleIsValid flag is marked as false in the return dictionary.  
    * buildAndValidateSchedule() can be called to create the schedule in the format that this function expects:
    * {
    *   monday: {
          customer_input: mondaySchedule
        },
        tuesday: {
          customer_input: tuesdaySchedule
        },
        etc
      }
    */

    let scheduleAndIsValid = {};
    scheduleAndIsValid.userScheduleIsValid = true;

    if (schedule == null) {
      return scheduleAndIsValid;
    }

    Object.values(schedule).forEach((dayDict) => {
      dayDict.military_times = [];
    });

    let nextDayIndex = 0;

    Object.keys(schedule).forEach((dayKey) => {
      let dayDict = schedule[dayKey];
      let cleanCustomerInput = dayDict.customer_input
        .replace(/[\s.]/g, "")
        .replaceAll(" ", "")
        .toUpperCase()
        .replace("CLOSED", "")
        .replace("MIDNIGHT", "12AM")
        .replace("OPEN", "12AM-12AM");
      let timeRanges = cleanCustomerInput.split(",");

      if (timeRanges != null) {
        timeRanges.forEach((time_range) => {
          if (time_range) {
            var [t0, t1] = UserScheduleValidation._parseTimeRange(time_range, allow_single_times, scheduleAndIsValid);
            if (t0 <= t1) {
              dayDict["military_times"].push([t0, t1]);
            } else {
              dayDict["military_times"].push([t0, 2400]);
              var tomorrow = UserScheduleValidation.nextDay[nextDayIndex];
              schedule[tomorrow]["military_times"].push([0, t1]);
            }
          }
        });
      }

      nextDayIndex++;
    });

    scheduleAndIsValid.scheduleAsMilitaryTime = schedule;

    return scheduleAndIsValid;
  };

  static keepScheduleOrGetDefault = (schedule) => {
    if (schedule.length === 0 || !schedule.trim()) {
      return "12AM-12AM";
    }

    return schedule;
  };

  static buildAndValidateSchedule = (schedule) => {
    let formattedSchedule = {
      monday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.mondaySchedule),
      },
      tuesday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.tuesdaySchedule),
      },
      wednesday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.wednesdaySchedule),
      },
      thursday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.thursdaySchedule),
      },
      friday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.fridaySchedule),
      },
      saturday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.saturdaySchedule),
      },
      sunday: {
        customer_input: this.keepScheduleOrGetDefault(schedule.sundaySchedule),
      },
    };

    return this.parseUserInputtedSchedule(formattedSchedule);
  };
}

export default UserScheduleValidation;
