import { track } from "instrumentation/tracking/track";

export const trackClickResetAllNotifications = (fields) => track("track_click_reset_all_notification_sets", fields);

export const trackClickConfirmResetAllNotifications = (fields) =>
  track("track_click_confirm_reset_all_notification_sets", fields);

export const trackClickPreviewNotifications = (fields) => track("track_click_preview_notifications", fields);

export const trackClickAddGuestNotificationSet = (fields) => track("track_click_add_guest_notification_set", fields);

export const trackClickNewNotificationsAdvancedOptions = (fields) =>
  track("track_click_new_notifications_advanced_options", fields);

export const trackClickSaveNotificationsAfterHours = (fields) =>
  track("track_click_save_notifications_after_hours", fields);

export const trackClickNotificationAfterHoursToggle = (fields) =>
  track("track_click_notifications_after_hours_toggle", fields);

export const trackClickPostCheckoutInstructions = (fields) => track("track_click_post_checkout_instructions", fields);

export const trackClickSavePostCheckoutInstructions = (fields) =>
  track("track_click_save_post_checkout_instructions", fields);

export const trackClickEditNotificationSet = (fields) => track("track_click_edit_notification_set", fields);

export const trackClickSaveNotificationSet = (fields) => track("track_click_save_notification_set", fields);

export const trackClickEditGNSStationsLocations = (fields) => track("track_click_edit_gns_stations_locations", fields);
