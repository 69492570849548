import React, { useEffect, useState, useRef } from "react";
import { Colors, Text, StackChildren, InlineChildren, Icon } from "@doordash/design-language";
import Toggle from "@doordash/component-toggle";
import { TASK_WIDGET_INFO } from "components/customer-journey/JourneyTask";
import ListCell from "@doordash/component-list-cell";
import Button from "@doordash/component-button";
import { ErrorMessage, TextField } from "@doordash/component-fields";
import styled from "styled-components";
import { extractBbotAddressFields } from "util/GooglePlacesUtils";
import { StyledAddressInput } from "components/customer-journey/widgets/VenueInfoWidget";

const getInitialState = (mailingAddress) => ({
  businessName: {
    value: mailingAddress.recipient ?? "",
    error: "",
    isRequired: true,
  },
  aptSuite: {
    value: mailingAddress.line2 ?? "",
    error: "",
    isRequired: false,
  },
  mailingAddress: {
    value: mailingAddress.query ?? "",
    error: "",
    isRequired: true,
  },
});

let autoCompleteMailing;

const MailingAddressWidget = ({
  mailingAddress,
  physicalAddress,
  onSave,
  isSaving,
  sameAsPhysicalAddress,
  onSameAsPhysicalAddressToggle,
}) => {
  const [isEditing, setIsEditing] = useState(true);
  const [mailingInfoFormValues, setMailingInfoFormValues] = useState(getInitialState(mailingAddress));
  const [selectedAddress, setSelectedAddress] = useState(mailingAddress);

  const autoCompleteRefMailing = useRef(null);

  const handleScriptLoad = () => {
    autoCompleteMailing = new window.google.maps.places.Autocomplete(autoCompleteRefMailing.current, {
      types: ["address"],
    });
    autoCompleteMailing.addListener("place_changed", handleMailingAddressSelect);
  };

  useEffect(() => {
    if (isEditing) {
      handleScriptLoad();
    }
  }, [isEditing]);

  useEffect(() => {
    onFieldChange("businessName", mailingAddress.recipient);
    onFieldChange("aptSuite", mailingAddress.line2);
    onFieldChange("mailingAddress", mailingAddress.query);
    setIsEditing(!mailingAddress.query);
  }, [mailingAddress]);

  const onFieldChange = (fieldName, fieldValue) => {
    setMailingInfoFormValues((formValues) => {
      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: "",
          isRequired: formValues[fieldName].isRequired,
        },
      };
    });
  };

  const handleMailingAddressSelect = async () => {
    const addressObject = autoCompleteMailing.getPlace();
    let address = extractBbotAddressFields(addressObject);
    address = {
      line2: mailingInfoFormValues.aptSuite.value,
      recipient: mailingInfoFormValues.businessName.value,
      ...address,
    };
    address.google_place = addressObject;
    address.query = address.formatted_address;
    setSelectedAddress(address);
    onFieldChange("mailingAddress", address.formatted_address);
  };

  const validateMailingAddressForm = () => {
    let formHasErrors = false;

    const formEntries = Object.entries(mailingInfoFormValues).map(([fieldName, { value, isRequired }]) => {
      let validationError = "";

      if (fieldName === "mailingAddress" && !selectedAddress) {
        formHasErrors = true;
        validationError = "Please use a suggested address from the dropdown.";
      }

      const hasValidationError = isRequired && !value.length;
      if (hasValidationError) {
        formHasErrors = true;
        validationError = "This field is required";
      }

      return [
        fieldName,
        {
          value,
          isRequired,
          error: validationError,
        },
      ];
    });

    setMailingInfoFormValues(Object.fromEntries(formEntries));
    return !formHasErrors;
  };

  const onSaveClicked = () => {
    if (!validateMailingAddressForm()) {
      return;
    }

    setSelectedAddress((address) => {
      const updatedAddress = {
        ...address,
        recipient: mailingInfoFormValues.businessName.value,
        line2: mailingInfoFormValues.aptSuite.value,
      };

      onSave({
        mailing_address: updatedAddress,
      });

      return updatedAddress;
    });

    setIsEditing(false);
  };

  const previewContent = (
    <StackChildren size={0}>
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Business Name</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="mailing-business-saved-display">
            {mailingInfoFormValues.businessName.value}
          </Text>
        }
        renderAfterContent={() => (
          <EditButtonWrap>
            {!sameAsPhysicalAddress && (
              <Button
                data-test-id="edit-mailing-button"
                type={Button.Types.Tertiary}
                isInline
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            )}
          </EditButtonWrap>
        )}
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Apt/Suite</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="mailing-line2-saved-display">
            {mailingInfoFormValues.aptSuite.value || "-"}
          </Text>
        }
      />
      <ListCell
        title={<Text styles={Text.Styles.Body2Emphasis}>Mailing Address</Text>}
        insetHorizontalSize={ListCell.InsetSizes.None}
        insetSize={ListCell.InsetSizes.XxSmall}
        isSeparatorVisible={false}
        subtext={
          <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="mailing-address-saved-display">
            {mailingInfoFormValues.mailingAddress.value}
          </Text>
        }
      />
    </StackChildren>
  );

  const formContent = (
    <StackChildren size={StackChildren.Sizes.Large}>
      <StackChildren>
        <InlineChildren>
          <TextField
            label={"Business Name"}
            placeholder="My Awesome Restaurant"
            value={mailingInfoFormValues.businessName.value}
            error={mailingInfoFormValues.businessName.error}
            onChange={(businessName) => onFieldChange("businessName", businessName)}
            data-test-id="mailing-business-name-field"
          />
          <TextField
            label={"Apt/Suite"}
            placeholder="Apt/Suite"
            value={mailingInfoFormValues.aptSuite.value}
            error={mailingInfoFormValues.aptSuite.error}
            onChange={(aptSuite) => onFieldChange("aptSuite", aptSuite)}
            data-test-id="mailing-line2-field"
          />
        </InlineChildren>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Label1Emphasis}>Street Address</Text>
          <div className="search-location-input">
            <StyledAddressInput
              className={"full-width"}
              ref={autoCompleteRefMailing}
              onChange={(e) => {
                onFieldChange("mailingAddress", e.target.value);
                setSelectedAddress(null);
              }}
              placeholder="Address"
              value={mailingInfoFormValues.mailingAddress.value}
              data-test-id="mailing-address-field"
            />
          </div>
          {mailingInfoFormValues.mailingAddress.error !== "" && (
            <ErrorMessage size={ErrorMessage.Sizes.Small} error={mailingInfoFormValues.mailingAddress.error} />
          )}
        </StackChildren>
      </StackChildren>
      <Button
        isInline
        onClick={onSaveClicked}
        state={isSaving ? Button.States.Loading : Button.States.Default}
        data-test-id="save-mailing-button"
      >
        Save Address
      </Button>
    </StackChildren>
  );

  return (
    <div>
      <div>
        <Text tag="h1" styles={Text.Styles.Title1} data-test-id="subtask-title-4">
          {TASK_WIDGET_INFO.mailing_address.title}
        </Text>
        <div className="margin-bottom-2">
          <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
            {TASK_WIDGET_INFO.mailing_address.description}
          </Text>
        </div>
      </div>
      <StackChildren size={StackChildren.Sizes.Large}>
        {!!physicalAddress.query && (
          <Toggle
            label="Same as Venue Information"
            onChange={(isSelected) => onSameAsPhysicalAddressToggle(isSelected)}
            isSelected={sameAsPhysicalAddress}
          />
        )}
        {!isEditing ? previewContent : formContent}
      </StackChildren>
    </div>
  );
};

export default MailingAddressWidget;

const EditButtonWrap = styled.div`
  margin-top: 3px;
`;
