import React from "react";

import { Modal, Button, Form as Btpform } from "react-bootstrap";
import { DateTime } from "luxon";
import { Formik } from "formik";
import * as Yup from "yup";
import CreateEditOrderGroupingRulesModalPage1 from "./CreateEditOrderGroupingRulesModalPage1";
import CreateEditOrderGroupingRulesPage2 from "./CreateEditOrderGroupingRulesModalPage2";
import CreateEditOrderGroupingRulesModalPage3 from "./CreateEditOrderGroupingRulesModalPage3";
import CreateEditOrderGroupingRulesModalPage4 from "./CreateEditOrderGroupingRulesModalPage4";
import UserScheduleValidation from "components/global/form/form-utils/UserScheduleValidation";
import "./CreateEditOrderGroupingrulesModal.scss";
import DestructiveActionWarningModal from "components/owner-app/modals/destructive-action-warning/DestructiveActionWarningModal";

function isValidSchedule(message) {
  return this.test("isValidSchedule", message, function (value) {
    const { path, createError } = this;
    let scheduleValidation = UserScheduleValidation.userInputtedSingleDayScheduleIsValid(value);

    if (!scheduleValidation) {
      return createError({ path, message: "Schedule is not valid.  Please make sure it is formatted correctly" });
    }
    return true;
  });
}

Yup.addMethod(Yup.string, "isValidSchedule", isValidSchedule);

class CreateEditOrderGroupingRulesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      showDisconnectVendorWarning: false,
    };
  }

  hideComponent = async () => {
    const { onHide } = this.props;

    onHide();

    this.setState({
      pageNum: 1,
    });
  };

  save = async (values) => {
    const { saveCallback } = this.props;

    saveCallback(values);

    this.setState({
      pageNum: 1,
    });
  };

  getTagLabels = () => {
    const { allTags } = this.props;

    let tagLabels = {};

    if (allTags != null) {
      Object.keys(allTags).map((cus) =>
        Object.keys(allTags[cus]).map((tagKey) => (tagLabels[tagKey] = allTags[cus][tagKey].display_name))
      );

      tagLabels["food"] = "Food";
      tagLabels["drink"] = "Drink";
    }

    return tagLabels;
  };

  getSelectDaysAndTimes = (selectedOrderGroupingRule) => {
    let options = {
      true: { value: true, label: "Selected Days and Times" },
      false: { value: false, label: "All Days and Times" },
    };

    if (selectedOrderGroupingRule?.order_filter?.always_active != null) {
      //always_active means do not select specific days and times
      return options[!selectedOrderGroupingRule.order_filter.always_active];
    }

    return options[false];
  };

  getSelectedVendors = (selectedOrderGroupingRule) => {
    let vendors = [];

    if (selectedOrderGroupingRule?.order_filter?.vendors != null) {
      vendors = selectedOrderGroupingRule.order_filter.vendors
        .filter((ven) => selectedOrderGroupingRule?.order_filter?.managing_customer?.customer_id !== ven.customer_id)
        .map((ven) => ({ value: ven.customer_id, label: ven.name_for_owner }));
    }

    vendors.sort((a, b) => (a.label > b.label ? 1 : -1));
    return vendors;
  };

  getSelectedStations = (selectedOrderGroupingRule) => {
    let stations = [];

    if (selectedOrderGroupingRule?.stations != null) {
      stations = selectedOrderGroupingRule.stations.map((st) => ({ value: st.id, label: st.station_name }));
    }

    return stations;
  };

  getSelectedTags = (tags) => {
    let tagLabels = this.getTagLabels();
    let selectedTags = [];

    if (tags != null) {
      selectedTags = tags.map((tag) => ({ value: tag, label: tagLabels[tag] }));
    }

    selectedTags.sort((a, b) => (a.label > b.label ? 1 : -1));
    return selectedTags;
  };

  getSelectedPrinters = (selectedOrderGroupingRule) => {
    let printers = [];

    if (selectedOrderGroupingRule?.aggregator_printers != null) {
      printers = selectedOrderGroupingRule.aggregator_printers.map((p) => ({ value: p.id, label: p.name }));
    }

    printers.sort((a, b) => (a.label > b.label ? 1 : -1));
    return printers;
  };

  getSelectedLocations = (selectedOrderGroupingRule) => {
    let locations = [];

    if (selectedOrderGroupingRule?.order_filter?.locations != null) {
      locations = selectedOrderGroupingRule.order_filter.locations.map((loc) => ({
        value: loc.locationId,
        label: loc.locationName,
      }));
    }

    return locations;
  };

  getSelectedFulfillmentMethods = (selectedOrderGroupingRule) => {
    const { allFulfillmentMethods } = this.props;

    let fulfillmentMethods = [];

    if (selectedOrderGroupingRule?.order_filter?.fulfillment_methods != null) {
      fulfillmentMethods = selectedOrderGroupingRule.order_filter.fulfillment_methods.map((fm) => ({
        value: fm,
        label: allFulfillmentMethods[fm],
      }));
    }

    if (fulfillmentMethods.length === 1 && fulfillmentMethods[0].value === "all") {
      return []; //For all, just leave it blank - only select specific ones.
    }

    return fulfillmentMethods;
  };

  getTagChoices = (necessaryVendors) => {
    const { allTags } = this.props;

    let filterChoices = [];
    let filtersAdded = new Set();

    if (allTags != null) {
      Object.keys(allTags)
        .filter((cus) => necessaryVendors.includes(cus))
        .map((cus) =>
          Object.keys(allTags[cus])
            .filter((tagKey) => !filtersAdded.has(tagKey))
            .forEach((tagKey) => {
              filterChoices.push({
                value: tagKey,
                label: allTags[cus][tagKey].display_name,
              });
              filtersAdded.add(tagKey);
            })
        );
    }

    if (!filtersAdded.has("food")) {
      filterChoices.push({
        value: "food",
        label: "Food",
      });
    }

    if (!filtersAdded.has("drink")) {
      filterChoices.push({
        value: "drink",
        label: "Drink",
      });
    }

    filterChoices.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    return filterChoices;
  };

  getJustSpecificDates = (selectedOrderGroupingRule) => {
    let startDate = DateTime.fromISO(selectedOrderGroupingRule?.order_filter?.start_date);
    let endDate = DateTime.fromISO(selectedOrderGroupingRule?.order_filter?.end_date);
    return startDate.diffNow("days").days > 0 || endDate.diffNow("years").years < 10;
  };

  nextPage = async (validateForm) => {
    const { pageNum } = this.state;

    const errors = await validateForm();

    if ("minutesWaitItemsInCart" in errors) {
      delete errors["minutesWaitItemsInCart"];
    }

    if ("minutesWaitNoItemsInCart" in errors) {
      delete errors["minutesWaitNoItemsInCart"];
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        pageNum: pageNum + 1,
      });
    }
  };

  disconnectVendorWarning = (selectedVendors, confirmSelectedVendorsChange, cancelSelectedVendorsChange) => {
    this.setState({
      showDisconnectVendorWarning: true,
      updatedSelectedVendors: selectedVendors,
      confirmSelectedVendorsChange: confirmSelectedVendorsChange,
      cancelSelectedVendorsChange: cancelSelectedVendorsChange,
    });
  };

  previousPage = () => {
    const { pageNum } = this.state;

    this.setState({
      pageNum: pageNum - 1,
    });
  };

  getNecessaryVendors = (selectedVendors) => {
    const { managingCustomer } = this.props;

    let necessaryVendors = [];

    if (selectedVendors != null) {
      necessaryVendors = selectedVendors;
    }

    necessaryVendors.push(managingCustomer);

    return necessaryVendors;
  };

  deselectStationsLocationsPrinters = (setFieldValue, selectedLocations, selectedStations, selectedPrinters) => {
    const { allLocations, allStations, allPrinters } = this.props;

    let necessaryVendors = this.getNecessaryVendors(this.state.updatedSelectedVendors.map((v) => v.value));

    let possibleLocations = [];
    Object.keys(allLocations)
      .filter((cus) => necessaryVendors.includes(cus))
      .map((cus) => allLocations[cus].map((loc) => possibleLocations.push(loc.locationId)));

    let updatedSelectedLocations = selectedLocations.filter((l) => possibleLocations.includes(l.value));
    setFieldValue("selectedLocations", updatedSelectedLocations);

    let possibleStations = [];
    Object.keys(allStations)
      .filter((cus) => necessaryVendors.includes(cus))
      .map((cus) => allStations[cus].map((loc) => possibleStations.push(loc.id)));

    let updatedSelectedStations = selectedStations.filter((l) => possibleStations.includes(l.value));
    setFieldValue("selectedStations", updatedSelectedStations);

    let possiblePrinters = [];
    Object.keys(allPrinters)
      .filter((cus) => necessaryVendors.includes(cus))
      .map((cus) => allPrinters[cus].map((loc) => possiblePrinters.push(loc.id)));

    let updatedSelectedPrinters = selectedPrinters.filter((l) => possiblePrinters.includes(l.value));
    setFieldValue("selectedPrinters", updatedSelectedPrinters);

    this.setState({
      showDisconnectVendorWarning: false,
    });

    //changing these to something arbitrary forces the component to refresh the selection
    setFieldValue("selectedLocationsKey", "selectedLocations" + DateTime.now().toString());
    setFieldValue("selectedStationsKey", "selectedStations" + DateTime.now().toString());
    setFieldValue("selectedPrintersKey", "selectedPrinters" + DateTime.now().toString());
  };

  render() {
    const {
      show,
      onHide,
      selectedOrderGroupingRule,
      allFulfillmentMethods,
      allLocations,
      allVendors,
      allStations,
      allPrinters,
      allTags,
    } = this.props;

    const { pageNum, showDisconnectVendorWarning } = this.state;

    return (
      <Modal size="lg" show={show} onHide={this.hideComponent} dialogClassName={"create-order-grouping-rule-modal"}>
        <Formik
          initialValues={{
            //Page 1 props
            nameForOwner: selectedOrderGroupingRule?.name_for_owner || "",
            description: selectedOrderGroupingRule?.description || "",
            selectedVendors: this.getSelectedVendors(selectedOrderGroupingRule),
            specificLocations: selectedOrderGroupingRule?.order_filter?.just_specific_locations || false,
            selectedLocations: this.getSelectedLocations(selectedOrderGroupingRule),
            selectedFulfillmentMethods: this.getSelectedFulfillmentMethods(selectedOrderGroupingRule),
            justSpecificDates: selectedOrderGroupingRule?.order_filter?.start_date
              ? this.getJustSpecificDates(selectedOrderGroupingRule)
              : false,
            startDate: selectedOrderGroupingRule?.order_filter?.start_date
              ? DateTime.fromISO(selectedOrderGroupingRule?.order_filter?.start_date)
              : DateTime.now().toJSDate(),
            endDate: selectedOrderGroupingRule?.order_filter?.end_date
              ? DateTime.fromISO(selectedOrderGroupingRule?.order_filter?.end_date)
              : DateTime.now().set({ year: 2099 }).toJSDate(),
            selectDaysAndTimes: this.getSelectDaysAndTimes(selectedOrderGroupingRule),
            mondaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.monday?.customer_input || "",
            tuesdaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.tuesday?.customer_input || "",
            wednesdaySchedule:
              selectedOrderGroupingRule?.order_filter?.active_schedule?.wednesday?.customer_input || "",
            thursdaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.thursday?.customer_input || "",
            fridaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.friday?.customer_input || "",
            saturdaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.saturday?.customer_input || "",
            sundaySchedule: selectedOrderGroupingRule?.order_filter?.active_schedule?.sunday?.customer_input || "",
            selectedLocationsKey: "selectedLocations", //Changing this just forces the component to refresh the selection
            selectedVendorsKey: "selectedVendors",

            //Page 2 props
            selectedStations: this.getSelectedStations(selectedOrderGroupingRule),
            groupByCheckout: selectedOrderGroupingRule?.grouping_by_stations || "all",
            groupAcrossTime: selectedOrderGroupingRule?.group_across_time || false,
            minutesWaitItemsInCart:
              selectedOrderGroupingRule?.minutes_to_wait_if_items_in_carts === undefined
                ? 3
                : selectedOrderGroupingRule?.minutes_to_wait_if_items_in_carts,
            minutesWaitNoItemsInCart:
              selectedOrderGroupingRule?.minutes_to_wait_no_items_in_carts === undefined
                ? 5
                : selectedOrderGroupingRule?.minutes_to_wait_no_items_in_carts,
            selectedStationsKey: "selectedStations",

            //Page 3 props
            specificPrinters:
              selectedOrderGroupingRule?.grouping_by_stations !== "disabled"
                ? true
                : selectedOrderGroupingRule?.normal_print_routing != null
                ? !selectedOrderGroupingRule?.normal_print_routing
                : false,
            selectedPrinters: this.getSelectedPrinters(selectedOrderGroupingRule),
            suppress_normal_ticket_printing: selectedOrderGroupingRule?.suppress_normal_ticket_printing ?? false,
            numCopies: selectedOrderGroupingRule?.num_copies === undefined ? 1 : selectedOrderGroupingRule?.num_copies,
            groupItemsBy: selectedOrderGroupingRule?.group_items_on_ticket || "all_together",
            selectedGroupingTags: this.getSelectedTags(selectedOrderGroupingRule?.tags_to_group_items_by),
            largeMessage: selectedOrderGroupingRule?.message_on_ticket_top_big || "",
            smallMessage: selectedOrderGroupingRule?.message_on_ticket_top_small || "",
            selectedPrintersKey: "selectedPrinters",

            //Page 4 props
            printSpecificItems: selectedOrderGroupingRule?.filter_items_by_tag || false,
            selectedFilters: this.getSelectedTags(selectedOrderGroupingRule?.tags_to_filter_items_by),
            delayPrinting: selectedOrderGroupingRule?.delay_printing || false,
            delayPrintASAPOrder: selectedOrderGroupingRule?.print_minutes_after_asap_order_time || 0,
            delayPrintOrderAhead: selectedOrderGroupingRule?.print_minutes_after_desired_time * -1 || 0,
          }}
          onSubmit={(values) => {
            this.save(values);
          }}
          validationSchema={Yup.object({
            nameForOwner: Yup.string()
              .test("len", "Please enter a name for this rule.", (val) => val != null && val.length >= 1)
              .test("len", "Must be 100 characters or less", (val) => val == null || val.length <= 100),
            description: Yup.string().test(
              "len",
              "Must be 200 characters or less",
              (val) => val == null || val.length <= 200
            ),
            mondaySchedule: Yup.string().isValidSchedule(),
            delayPrintASAPOrder: Yup.number().min(0),
            numCopies: Yup.number().min(0),
            minutesWaitItemsInCart: Yup.number()
              .min(0)
              .max(10, "We strongly recommend not delaying tickets by more than 10 minutes."),
            minutesWaitNoItemsInCart: Yup.number()
              .min(0)
              .max(10, "We strongly recommend not delaying tickets by more than 10 minutes."),
            tuesdaySchedule: Yup.string().isValidSchedule(),
            wednesdaySchedule: Yup.string().isValidSchedule(),
            thursdaySchedule: Yup.string().isValidSchedule(),
            fridaySchedule: Yup.string().isValidSchedule(),
            saturdaySchedule: Yup.string().isValidSchedule(),
            sundaySchedule: Yup.string().isValidSchedule(),
            startDate: Yup.date().max(Yup.ref("endDate"), "End date can't be before start date"),
            endDate: Yup.date().min(Yup.ref("startDate"), "End date can't be before start date"),
          })}
        >
          {({ values, handleSubmit, setFieldValue, validateForm }) => (
            <Btpform onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header className={"padding-x-3"} closeButton>
                <Modal.Title>Edit Order Grouping Rule</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-3"}>
                {pageNum === 1 && (
                  <CreateEditOrderGroupingRulesModalPage1
                    allFulfillmentMethods={allFulfillmentMethods}
                    allLocations={allLocations}
                    allVendors={allVendors}
                    getNecessaryVendors={this.getNecessaryVendors}
                    setFieldValue={setFieldValue}
                    selectedVendors={values.selectedVendors}
                    specificLocations={values.specificLocations}
                    selectedLocations={values.selectedLocations}
                    selectedFulfillmentMethods={values.selectedFulfillmentMethods}
                    justSpecificDates={values.justSpecificDates}
                    startDate={values.startDate}
                    endDate={values.endDate}
                    nameForOwner={values.nameForOwner}
                    description={values.description}
                    selectDaysAndTimes={values.selectDaysAndTimes}
                    mondaySchedule={values.mondaySchedule}
                    tuesdaySchedule={values.tuesdaySchedule}
                    wednesdaySchedule={values.wednesdaySchedule}
                    thursdaySchedule={values.thursdaySchedule}
                    fridaySchedule={values.fridaySchedule}
                    saturdaySchedule={values.saturdaySchedule}
                    sundaySchedule={values.sundaySchedule}
                    selectedLocationsKey={values.selectedLocationsKey}
                    selectedVendorsKey={values.selectedVendorsKey}
                    disconnectVendorWarning={this.disconnectVendorWarning}
                  />
                )}
                {pageNum === 2 && (
                  <CreateEditOrderGroupingRulesPage2
                    setFieldValue={setFieldValue}
                    getNecessaryVendors={this.getNecessaryVendors}
                    groupByCheckout={values.groupByCheckout}
                    groupAcrossTime={values.groupAcrossTime}
                    minutesWaitItemsInCart={values.minutesWaitItemsInCart}
                    minutesWaitNoItemsInCart={values.minutesWaitNoItemsInCart}
                    selectedVendors={values.selectedVendors}
                    selectedStations={values.selectedStations}
                    selectedStationsKey={values.selectedStationsKey}
                    allStations={allStations}
                    specificPrinters={values.specificPrinters}
                  />
                )}
                {pageNum === 3 && (
                  <CreateEditOrderGroupingRulesModalPage3
                    setFieldValue={setFieldValue}
                    getNecessaryVendors={this.getNecessaryVendors}
                    specificPrinters={values.specificPrinters}
                    selectedPrinters={values.selectedPrinters}
                    suppress_normal_ticket_printing={values.suppress_normal_ticket_printing}
                    selectedPrintersKey={values.selectedPrintersKey}
                    groupByCheckout={values.groupByCheckout}
                    numCopies={values.numCopies}
                    groupItemsBy={values.groupItemsBy}
                    selectedGroupingTags={values.selectedGroupingTags}
                    largeMessage={values.largeMessage}
                    smallMessage={values.smallMessage}
                    selectedVendors={values.selectedVendors}
                    allPrinters={allPrinters}
                    getTagChoices={this.getTagChoices}
                  />
                )}
                {pageNum === 4 && (
                  <CreateEditOrderGroupingRulesModalPage4
                    setFieldValue={setFieldValue}
                    getNecessaryVendors={this.getNecessaryVendors}
                    printSpecificItems={values.printSpecificItems}
                    selectedFilters={values.selectedFilters}
                    delayPrinting={values.delayPrinting}
                    delayPrintASAPOrder={values.delayPrintASAPOrder}
                    delayPrintOrderAhead={values.delayPrintOrderAhead}
                    selectedVendors={values.selectedVendors}
                    allTags={allTags}
                    getTagChoices={this.getTagChoices}
                  />
                )}
              </Modal.Body>
              <Modal.Footer className={"padding-x-3 justify-content-between"}>
                <Button variant="primary" onClick={onHide} target="_blank" size={"sm"} href={""}>
                  Need Help?
                </Button>
                <div>
                  {pageNum > 1 && (
                    <Button variant="light" onClick={this.previousPage} type="button" size={"sm"}>
                      Back
                    </Button>
                  )}
                  {pageNum < 4 && (
                    <Button
                      className="margin-left-2 margin-right-0"
                      variant="primary"
                      type="button"
                      size={"sm"}
                      onClick={() => this.nextPage(validateForm)}
                    >
                      Next
                    </Button>
                  )}
                  {pageNum === 4 && (
                    <Button className="margin-left-2 margin-right-0" variant="primary" type="submit" size={"sm"}>
                      Save
                    </Button>
                  )}
                </div>
              </Modal.Footer>
              <DestructiveActionWarningModal
                show={showDisconnectVendorWarning}
                title={"Remove Vendor from Order Grouping Rule"}
                warningText={
                  "Removing a vendor from the order grouping rule will also remove all selected locations, stations, and printers that belong to that vendor."
                }
                handleContinue={() => {
                  this.state.confirmSelectedVendorsChange(setFieldValue, this.state.updatedSelectedVendors);
                  this.deselectStationsLocationsPrinters(
                    setFieldValue,
                    values.selectedLocations,
                    values.selectedStations,
                    values.selectedPrinters
                  );
                }}
                handleCancel={() => {
                  this.setState({ showDisconnectVendorWarning: false });
                  this.state.cancelSelectedVendorsChange(setFieldValue);
                }}
                centered={true}
                onHide={() => {
                  this.setState({ showDisconnectVendorWarning: false });
                  this.state.cancelSelectedVendorsChange(setFieldValue);
                }}
                cancelText={"Cancel"}
                continueText={"Continue"}
              />
            </Btpform>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateEditOrderGroupingRulesModal;
