import Icon from "@ant-design/icons";
import React from "react";

const PromoCodeSVG = () => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 18C2.5 9.16344 9.66344 2 18.5 2C27.3366 2 34.5 9.16344 34.5 18C34.5 26.8366 27.3366 34 18.5 34C9.66344 34 2.5 26.8366 2.5 18Z"
      fill="#D1E9FF"
    />
    <path
      d="M25.1667 16.6667V14C25.1667 13.2667 24.5667 12.6667 23.8333 12.6667H13.1667C12.4333 12.6667 11.84 13.2667 11.84 14V16.6667C12.5733 16.6667 13.1667 17.2667 13.1667 18C13.1667 18.7333 12.5733 19.3333 11.8333 19.3333V22C11.8333 22.7333 12.4333 23.3333 13.1667 23.3333H23.8333C24.5667 23.3333 25.1667 22.7333 25.1667 22V19.3333C24.4333 19.3333 23.8333 18.7333 23.8333 18C23.8333 17.2667 24.4333 16.6667 25.1667 16.6667ZM23.8333 15.6933C23.04 16.1533 22.5 17.02 22.5 18C22.5 18.98 23.04 19.8467 23.8333 20.3067V22H13.1667V20.3067C13.96 19.8467 14.5 18.98 14.5 18C14.5 17.0133 13.9667 16.1533 13.1733 15.6933L13.1667 14H23.8333V15.6933ZM16.5467 20.6667L18.5 19.4133L20.4533 20.6667L19.86 18.4267L21.6533 16.96L19.34 16.82L18.5 14.6667L17.6533 16.8133L15.34 16.9533L17.1333 18.42L16.5467 20.6667Z"
      fill="#1570EF"
    />
    <path
      d="M18.5 32C10.768 32 4.5 25.732 4.5 18H0.5C0.5 27.9411 8.55887 36 18.5 36V32ZM32.5 18C32.5 25.732 26.232 32 18.5 32V36C28.4411 36 36.5 27.9411 36.5 18H32.5ZM18.5 4C26.232 4 32.5 10.268 32.5 18H36.5C36.5 8.05887 28.4411 0 18.5 0V4ZM18.5 0C8.55887 0 0.5 8.05887 0.5 18H4.5C4.5 10.268 10.768 4 18.5 4V0Z"
      fill="#EFF8FF"
    />
  </svg>
);

const PromoCode = () => <Icon title="PromoCode" aria-label="PromoCode" component={() => PromoCodeSVG()} />;

export default PromoCode;
