import React from "react";
import axios from "axios";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrderGroupingRulesTable from "components/owner-app/tables/order-grouping-rules-table/OrderGroupingRulesTable";
import CreateEditOrderGroupingRulesModal from "components/owner-app/modals/create-edit-order-grouping-rules-modal/CreateEditOrderGroupingRulesModal";
import SearchSelectInputUtils from "components/global/form/SearchSelectInputUtils";
import UserScheduleValidation from "components/global/form/form-utils/UserScheduleValidation";
import DestructiveActionWarningModal from "components/owner-app/modals/destructive-action-warning/DestructiveActionWarningModal";

class OrderGroupingRules extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderGroupingRules: [],
      showOrderFilterModal: false,
      showDeleteOrderGroupingRuleWarning: false,
      selectedOrderGroupingRule: null,
      ruleToDelete: null,
      allFulfillmentMethods: [],
      allVendors: [],
      allStations: [],
      allTags: [],
      allPrinters: [],
      managingCustomer: "",
    };
  }

  componentDidMount = async () => {
    const { selectedCustomer } = this.props;
    this.mounted = true;

    if (!selectedCustomer) {
      return;
    }

    await this.getOrderGroupingRules();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = async (prevProps) => {
    const { selectedCustomer } = this.props;

    if (prevProps.selectedCustomer !== selectedCustomer) {
      await this.getOrderGroupingRules();
    }
  };

  deleteOrderGroupingRuleWarning = (orderGroupingRule) => {
    this.setState({
      showDeleteOrderGroupingRuleWarning: true,
      ruleToDelete: orderGroupingRule,
    });
  };

  deleteOrderGroupingRule = async () => {
    const { ruleToDelete } = this.state;

    try {
      await axios.delete("/api/deleteOrderGroupingRule", {
        params: {
          order_grouping_rule_id: ruleToDelete.id,
          order_filter_id: ruleToDelete.order_filter.id,
        },
      });
    } catch (error) {
      console.error(error.response.data.message);
    }

    this.setState({
      ruleToDelete: null,
      showDeleteOrderGroupingRuleWarning: false,
    });

    await this.getOrderGroupingRules();
  };

  cancelDeleteOrderGroupingRule = () => {
    this.setState({
      ruleToDelete: null,
      showDeleteOrderGroupingRuleWarning: false,
    });
  };

  getOrderGroupingRules = async () => {
    try {
      const res = await axios.get("/api/getOrderGroupingRules", {
        params: {},
      });
      this.setState({
        orderGroupingRules: res.data.order_grouping_rules,
        allFulfillmentMethods: res.data.all_fulfillment_methods,
        allLocations: res.data.all_locations,
        allStations: res.data.all_stations,
        allTags: res.data.tags,
        allPrinters: res.data.all_printers,
        allVendors: res.data.external_vendors,
        managingCustomer: res.data.managing_customer,
      });
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  createUpdateOrderGroupingRule = async (payload) => {
    const { selectedOrderGroupingRule } = this.state;

    let dailySchedules = {
      mondaySchedule: payload.mondaySchedule,
      tuesdaySchedule: payload.tuesdaySchedule,
      wednesdaySchedule: payload.wednesdaySchedule,
      thursdaySchedule: payload.thursdaySchedule,
      fridaySchedule: payload.fridaySchedule,
      saturdaySchedule: payload.saturdaySchedule,
      sundaySchedule: payload.sundaySchedule,
    };

    let formattedSchedule = UserScheduleValidation.buildAndValidateSchedule(dailySchedules);

    try {
      await axios.post("/api/createUpdateOrderGroupingRule", {
        params: {
          order_grouping_rule_id: selectedOrderGroupingRule?.id || "",
          //Page 1 props
          name_for_owner: payload.nameForOwner,
          description: payload.description,
          selected_vendors: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedVendors),
          specific_locations: payload.specificLocations,
          selected_locations: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedLocations),
          fulfillment_methods: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedFulfillmentMethods),
          start_date: payload.startDate,
          end_date: payload.endDate,
          select_days_and_times: payload.selectDaysAndTimes.value,
          formatted_schedule: formattedSchedule.scheduleAsMilitaryTime,

          //Page 2 props
          selected_stations: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedStations),
          group_by_checkout: payload.groupByCheckout,
          group_across_time: payload.groupAcrossTime,
          minutes_wait_items_in_cart: payload.minutesWaitItemsInCart,
          minutes_wait_no_items_in_cart: payload.minutesWaitNoItemsInCart,

          //Page 3 props
          specific_printers: payload.specificPrinters,
          selected_printers: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedPrinters),
          suppress_normal_ticket_printing: payload.suppress_normal_ticket_printing,
          num_copies: payload.numCopies,
          group_items_by: payload.groupItemsBy,
          selected_grouping_tags: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedGroupingTags),
          large_message: payload.largeMessage,
          small_message: payload.smallMessage,

          //Page 4 props
          print_specific_items: payload.printSpecificItems,
          selected_filters: SearchSelectInputUtils.getValuesFromMultiSelect(payload.selectedFilters),
          delay_printing: payload.delayPrinting,
          delay_printing_asap_order: payload.delayPrintASAPOrder,
          delay_printing_order_ahead: payload.delayPrintOrderAhead * -1,
        },
      });
    } catch (error) {
      if (error?.response?.data?.message != null) {
        console.error(error.response.data.message);
        return false;
      }
    }

    this.setState({
      showOrderFilterModal: false,
    });

    await this.getOrderGroupingRules();
  };

  render() {
    const {
      showOrderFilterModal,
      selectedOrderGroupingRule,
      allLocations,
      allVendors,
      allStations,
      allPrinters,
      allTags,
      allFulfillmentMethods,
      managingCustomer,
      orderGroupingRules,
      showDeleteOrderGroupingRuleWarning,
    } = this.state;

    let content;

    if (orderGroupingRules.length === 0) {
      content = (
        <div className={"margin-bottom-4"}>
          <Link to={"/manage-print-rules"} className={"breadcrumb-link"}>
            <Button
              onClick={() =>
                this.setState({
                  showOrderFilterModal: true,
                  selectedOrderGroupingRule: null,
                })
              }
            >
              Create Order Grouping Rule
            </Button>
          </Link>
        </div>
      );
    } else {
      content = (
        <OrderGroupingRulesTable
          orderGroupingRules={orderGroupingRules}
          deleteOrderGroupingRule={this.deleteOrderGroupingRuleWarning}
          editOrderGroupingRule={(data) => {
            this.setState({
              selectedOrderGroupingRule: data,
              showOrderFilterModal: true,
            });
          }}
          addOrderGroupingRule={() => {
            this.setState({
              selectedOrderGroupingRule: null,
              showOrderFilterModal: true,
            });
          }}
        />
      );
    }

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className={"breadcrumbs-row margin-bottom-4"}>
                <span>
                  <Link to={"/"} className={"breadcrumb-back"}>
                    &#60; Back
                  </Link>
                </span>
                <span className={"breadcrumbs"}>
                  <span>
                    <Link to={"/"} className={"breadcrumb-link"}>
                      Owner Portal
                    </Link>
                  </span>
                  <span className={"breadcrumb-link"}>&#62;</span>
                  <span>
                    <Link to={"/manage-print-rules"} className={"breadcrumb-link"}>
                      Order Grouping
                    </Link>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"margin-bottom-4"}>
                <h2>
                  Order Grouping Rules
                  <span className={"color-primary__dark"}></span>
                </h2>
              </div>
              <div className={"margin-bottom-4"}>
                <p>
                  Each rule will produce a custom printed ticket to support your unique workflows. For example, you can
                  produce:
                </p>
                <ul>
                  <li>a ticket that combines all orders placed within 5 minutes of each other at 1 table</li>
                  <li>a ticket that only shows appetizers</li>
                  <li>
                    <i>for food halls:</i> a ticket that combines orders from 1 guest at multiple food hall vendors
                  </li>
                </ul>
                <p>
                  Note: adding a rule here does <b>not</b> disable the normal printing of orders; it simply creates an
                  additional printed ticket. You can still control normal ticket printing via the Printers page and the
                  Menu Item editor.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>{content}</Col>
          </Row>
        </Container>
        {/* ============= Modals ======== */}
        <CreateEditOrderGroupingRulesModal
          show={showOrderFilterModal}
          onHide={() => this.setState({ showOrderFilterModal: false })}
          selectedOrderGroupingRule={selectedOrderGroupingRule}
          allLocations={allLocations}
          allPrinters={allPrinters}
          allStations={allStations}
          allVendors={allVendors}
          allTags={allTags}
          managingCustomer={managingCustomer}
          allFulfillmentMethods={allFulfillmentMethods}
          saveCallback={(payload) => {
            this.createUpdateOrderGroupingRule(payload);
          }}
        />
        <DestructiveActionWarningModal
          show={showDeleteOrderGroupingRuleWarning}
          title={"Confirm Delete Order Grouping Rule"}
          warningText={
            "Are you sure you want to delete this order grouping rule?  This is a destructive action and cannot be undone."
          }
          handleContinue={this.deleteOrderGroupingRule}
          handleCancel={this.cancelDeleteOrderGroupingRule}
          onHide={() => this.setState({ showDeleteOrderGroupingRuleWarning: false })}
          continueText={"Continue"}
          cancelText={"Cancel"}
        />
      </div>
    );
  }
}

export default OrderGroupingRules;
