import trackSegment from "./trackSegment";

import { ENV } from "Config";
import { getSelectedCustomer, getUserInfo } from "./trackingCommonData";

export const isSeoVisitFunctional = (referrer, queryParams, seoDomains) => {
  for (const seoDomain of seoDomains) {
    const { domain, utmAllowed } = seoDomain;

    if (referrer.includes(domain)) {
      if (utmAllowed) {
        return true;
      }

      return queryParams.utm_source === undefined;
    }
  }

  return false;
};

// Track custom events using NewRelic as a destination instead of regular Segment.
export const trackNewrelic = (eventName, body) => {
  // uncomment to log warnings for all tracking events
  // console.warn('::: Newrelict Event Fired :::', eventName, body)
  const newrelic = window?.newrelic;
  if (newrelic) {
    newrelic.addPageAction(eventName, body);
  }
};

// augment bundle_context when the events are emitted from a specific page.
// this could potentially helpful to emit any additional parameters specific to a page
export const getAdditionalFieldsForPage = () => {
  if (window?.location?.pathname?.match(/^\/doubledash\//)) {
    return { bundle_context: "post_checkout" };
  }
  return {};
};

const trackHelper = (eventName, body, context) => {
  if (typeof window === "undefined") {
    return;
  }

  const payload = {
    ...body,
    ...getAdditionalFieldsForPage(),
  };

  const envInjectedContext = context ?? {};
  if (envInjectedContext.app) {
    envInjectedContext.app.environment = ENV;
  } else {
    envInjectedContext.app = { environment: ENV };
  }

  envInjectedContext.customer_id = getSelectedCustomer()?.customer_id;
  envInjectedContext.user_id = getUserInfo()?.id;
  envInjectedContext.username = getUserInfo()?.username;
  envInjectedContext.role = getUserInfo()?.role;
  envInjectedContext.permissions = getUserInfo()?.permissions;

  const injectedEventName = `bb_${eventName}`;

  trackSegment(injectedEventName, payload, envInjectedContext);
};

export const track = (eventName, body, context) => {
  trackHelper(eventName, body, context);
};

// Access tracking via a hook instead of direct import
export const useTrack = () => {
  // no tracking on server side for now
  if (typeof window === "undefined") {
    return;
  }

  return {
    track(eventName, body, context) {
      trackHelper(eventName, body, context);
    },
  };
};

export const trackErrorAppear = (payload, error) => {
  track("error_appear", {
    ...payload,
    internalErrorDetails: payload.internalErrorDetails ?? payload.errorMessage,
    isGenericError: payload.isGenericError ?? true,
    isErrorExpected: payload.isErrorExpected ?? false,
  });
};

export default track;
