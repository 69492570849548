import React from "react";
import { Form } from "react-bootstrap";
import SearchSelectInput from "../../../../../src/components/global/form/SearchSelectInput";
import TextAreaInput from "../../../../../src/components/global/form/TextAreaInput";
import { getFulfillmentMethodChoices } from "../../../global/form/form-utils/CommonMultiSelectOptionUtils";

const SmartBarIntegrationModalInputs = (props) => {
  const { values, setFieldValue, allTags, allFulfillmentMethods, getLocationOptions, getTagOptions } = props;

  return (
    <>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          md={12}
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          className={"margin-bottom-0"}
          id={"tags"}
          name={"tags"}
          label={"Menu Tags to Reference"}
          options={getTagOptions(allTags)}
          placeholder={"None"}
          value={values.selectedTags}
          defaultValue={values.selectedTags}
          onChange={(value) => setFieldValue("selectedTags", value)}
        />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          md={12}
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          className={"margin-bottom-0"}
          id={"selectedFulfillmentMethods"}
          name={"selectedFulfillmentMethods"}
          label={"Fulfillment Methods Applied"}
          options={getFulfillmentMethodChoices(allFulfillmentMethods, true)}
          placeholder={"All Fulfillment Methods"}
          value={values.selectedFulfillmentMethods}
          defaultValue={values.selectedFulfillmentMethods}
          onChange={(value) => setFieldValue("selectedFulfillmentMethods", value)}
        />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          md={12}
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          className={"margin-bottom-0"}
          id={"selectedLocations"}
          name={"selectedLocations"}
          label={"Location Codes Applied"}
          options={getLocationOptions(values.selectedFulfillmentMethods, true)}
          placeholder={"All Locations"}
          key={`desired-locations${values.selectedFulfillmentMethods}`}
          value={values.selectedLocations}
          defaultValue={values.selectedLocations}
          onChange={(value) => setFieldValue("selectedLocations", value)}
        />
      </Form.Row>
      <Form.Row>
        <TextAreaInput
          sm={12}
          label={"Instructions For Guests (optional)"}
          id={"instructionsForGuests"}
          name={"instructionsForGuests"}
          placeholder={""}
        />
      </Form.Row>
    </>
  );
};

export default SmartBarIntegrationModalInputs;
