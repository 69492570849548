import React from "react";
import axios from "axios";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput, NumberInput, CheckboxInput, SearchSelectInput } from "components/global/form";
import CenteredLoadingSpinner from "components/owner-app/loading-spinners/CenteredLoadingSpinner";

const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;

function isValidPromosSelected(message) {
  return this.test("isValidPromosSelected", message, function (value) {
    const { path, createError } = this;
    // have to use Object.entries() here or else it won't work
    for (const [, promo] of Object.entries(value)) {
      //eslint-disable-line no-unused-vars
      if (promo?.value == null) {
        return createError({ path, message: message });
      }
    }
    return true;
  });
}
Yup.addMethod(Yup.object, "isValidPromosSelected", isValidPromosSelected);

class ConnectPartnerModal extends React.Component {
  state = {
    initialValues: {},
    validationSchema: {},
    promoSettings: {},
    loadingData: true,
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedPartner === this.props.selectedPartner) {
      return;
    }
    await this.setInitialValues();
  }

  async componentDidMount() {
    await this.setInitialValues();
  }

  setInitialValues = async () => {
    this.setState({
      loadingData: true,
    });

    const { selectedCustomer, selectedPartner, connectedPartners } = this.props;

    let posPromoSettings;
    if (selectedPartner?.requires_connected_promotion) {
      // Not all partners require a linked POS promotion
      let res = await axios.get("/owner/getPartnerLoyaltyPromo", {
        params: {
          customerId: selectedCustomer.customer_id,
          partnerId: selectedPartner.id,
        },
      });
      posPromoSettings = {
        posByVendorPk: res.data.pos_by_vendor_pk,
        connectablePromosForVendors: res.data.connectable_promos_by_vendor,
        vendorNames: res.data.vendor_names_by_pk,
        connectedPromosForVendors: res.data.connected_promos_by_vendor_pk,
      };
    }

    // Fill in initial values for partner-specific connection fields
    const initialValues = {
      selectedPosPromoByVendor: {},
    };
    const validationSchema = {};
    if (posPromoSettings?.connectedPromosForVendors != null) {
      validationSchema.selectedPosPromoByVendor = Yup.object().isValidPromosSelected("Please select a POS promo");
      for (const [vendorId, promo] of Object.entries(posPromoSettings.connectedPromosForVendors)) {
        initialValues.selectedPosPromoByVendor[vendorId] = {
          value: promo ?? null,
          label: posPromoSettings.connectablePromosForVendors[vendorId].find((p) => p.value === promo)?.name ?? null,
        };
      }
    }

    if (selectedPartner != null) {
      const activePartner = connectedPartners.find(
        // `connectedPartners` should never change, hence we don't add it as a hook dependency
        (partner) => partner.partner_id === selectedPartner?.id
      );
      Object.values(selectedPartner?.connect_partner_info).forEach((field) => {
        if (field.type === "text") {
          initialValues[field.key] = activePartner?.integration_settings[field.key] ?? "";
          validationSchema[field.key] = field.required
            ? Yup.string().required(field.label + " is required.")
            : Yup.string();
        } else if (field.type === "url") {
          initialValues[field.key] = activePartner?.integration_settings[field.key] ?? "";
          validationSchema[field.key] = field.required
            ? Yup.string()
                .matches(URL_REGEX, "URL is not valid")
                .required(field.label + " is required.")
            : Yup.string().matches(URL_REGEX, "URL is not valid");
        } else if (field.type === "number") {
          initialValues[field.key] = activePartner?.integration_settings[field.key] ?? 0;
          validationSchema[field.key] = field.required
            ? Yup.number().required(field.label + " is required.")
            : Yup.number();
        } else if (field.type === "checkbox") {
          initialValues[field.key] = activePartner?.integration_settings[field.key] ?? false;
          validationSchema[field.key] = field.required
            ? Yup.boolean().required(field.label + " is required.")
            : Yup.boolean();
        } else if (field.type === "password") {
          initialValues[field.key] = activePartner ? "<hidden/>" : "";
          validationSchema[field.key] = field.required
            ? Yup.string().required(field.label + " is required.")
            : Yup.string();
        }
      });
    }

    this.setState({
      initialValues: initialValues,
      validationSchema: validationSchema,
      posPromoSettings: posPromoSettings,
      loadingData: false,
    });
  };

  connectPartner = async (integrationSettings) => {
    const { onClose, onSave, selectedPartner, selectedCustomer } = this.props;
    const payload = {
      customerId: selectedCustomer?.customer_id,
      partnerKey: selectedPartner.short_id,
      integrationSettings: integrationSettings,
    };

    try {
      await axios.post("/admin/connectPartner", payload);
      toast.success(
        "Successfully connected " + selectedPartner?.display_name + " to " + selectedCustomer?.name_for_owner
      );
      onSave();
      onClose();
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error(`Trouble connecting to ${selectedPartner?.displayName}. Please refresh and try again.`);
      }
    }
  };

  render() {
    const { onClose, selectedPartner, show, preview } = this.props;
    const { initialValues, validationSchema, posPromoSettings, loadingData } = this.state;

    return (
      <Modal show={show} onHide={onClose}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={this.connectPartner}
        >
          {({ setFieldValue, errors, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>{selectedPartner.card_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner />
                ) : (
                  <>
                    <div className={"margin-bottom-2"}>
                      <p>{selectedPartner.connect_modal_description}</p>
                      {selectedPartner.documentation_link && (
                        <p className={"text-x-small"}>
                          For more information about connecting to {selectedPartner.display_name}, please visit this{" "}
                          <a href={selectedPartner.documentation_link} rel={"noopener noreferrer"} target={"_blank"}>
                            link.
                          </a>
                        </p>
                      )}
                    </div>
                    {Object.values(selectedPartner.connect_partner_info)
                      .sort((a, b) => a.display_order - b.display_order)
                      .map((field, index) => (
                        <div className={"margin-bottom-2"} key={index}>
                          {(field.type === "text" || field.type === "password" || field.type === "url") && (
                            <div>
                              <TextInput
                                label={field.label + (field.required ? " *" : "")}
                                name={field.key}
                                id={field.key}
                              />
                              <div className={"supporting-text margin-top-1 margin-left-2 margin-bottom-2"}>
                                {field.description}
                              </div>
                            </div>
                          )}
                          {field.type === "number" && (
                            <div>
                              <NumberInput
                                label={field.label + (field.required ? " *" : "")}
                                name={field.key}
                                id={field.key}
                              />
                              <div className={"supporting-text margin-top-1 margin-left-2 margin-bottom-2"}>
                                {field.description}
                              </div>
                            </div>
                          )}
                          {field.type === "checkbox" && (
                            <div>
                              <CheckboxInput
                                label={field.label + (field.required ? " *" : "")}
                                name={field.key}
                                id={field.key}
                              />
                              <div className={"supporting-text margin-top-1 margin-left-2 margin-bottom-2"}>
                                {field.description}
                              </div>
                            </div>
                          )}
                          {field.type === "button" && (
                            <Button
                              size={"lg"}
                              className={"margin-bottom-2 margin-left-1"}
                              onClick={() => {
                                window.open(field.url, "_blank");
                              }}
                            >
                              {field.label}
                            </Button>
                          )}
                        </div>
                      ))}
                    {selectedPartner?.requires_connected_promotion &&
                    posPromoSettings?.connectablePromosForVendors != null ? (
                      Object.keys(posPromoSettings?.connectablePromosForVendors).map((vendorId) => (
                        <SearchSelectInput
                          key={vendorId}
                          isSearchable
                          id={`select-pos-promo-${vendorId}`}
                          name={`selectedPosPromoByVendor.${vendorId}`}
                          label={[
                            `${posPromoSettings.vendorNames[vendorId]} ${posPromoSettings.posByVendorPk[vendorId]} Promotion`,
                            "  ",
                            <OverlayTrigger
                              key={`overlay-${vendorId}-loyalty-discount-selection`}
                              placement={"right"}
                              overlay={
                                <Tooltip id={`tooltip-${vendorId}-loyalty-discount-selection`}>
                                  At {posPromoSettings.vendorNames[vendorId]}, which{" "}
                                  {posPromoSettings.posByVendorPk[vendorId]} Promotion should be used to represent
                                  loyalty discounts? (Must be an "open" discount in the POS, not a fixed amount.)
                                </Tooltip>
                              }
                            >
                              <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                            </OverlayTrigger>,
                          ]}
                          defaultValue={posPromoSettings.connectablePromosForVendors[vendorId]
                            .map((p) => {
                              return { label: p.name, value: p.external_id };
                            })
                            .find((p) => p.value === posPromoSettings.connectedPromosForVendors[vendorId])}
                          options={posPromoSettings.connectablePromosForVendors[vendorId].map((p) => {
                            return { label: p.name, value: p.external_id };
                          })}
                          onChange={(value) => {
                            setFieldValue(`selectedPosPromoByVendor.${vendorId}`, value);
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                    {Object.values(selectedPartner.connect_partner_info).find((field) => field.required) && (
                      <div className={"margin-top-4"}>
                        <small style={{ fontStyle: "italic" }}>* = required field</small>
                      </div>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button size={"sm"} variant={"light"} onClick={onClose}>
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0 || preview}>
                  Enter
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default ConnectPartnerModal;
