import React, { useEffect, useState } from "react";
import OPContainer from "bbot-component-library/OPContainer";
import BbotTabs from "bbot-component-library/BbotTabs";
import StaffAlerts from "pages/support-alerts/StaffAlerts";
import TerminalAlerts from "pages/support-alerts/TerminalAlerts";
import FraudContacts from "pages/support-alerts/FraudContacts";
import { BbotAlert } from "bbot-component-library";
import { BBOT_DJANGO_SERVER } from "Config";
import { getSupportAlerts } from "util/ApiCalls";
import styled from "styled-components";

const SupportAlerts = (props) => {
  const legacyLink =
    BBOT_DJANGO_SERVER +
    (props.userInfo?.role === "admin" ? "/superuser/console/#!/supportAlerts" : "/owner/console/#!/supportAlerts");
  const [staffAlerts, setStaffAlerts] = useState([]);
  const [terminalAlerts, setTerminalAlerts] = useState([]);
  const [fraudContacts, setFraudContacts] = useState([]);
  const [possibleStations, setPossibleStations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hideTabHeaders, setHideTabHeaders] = useState(false);

  const setAlertData = async () => {
    setIsLoading(true);
    const data = await getSupportAlerts(props.selectedCustomer);
    if (!data) {
      setIsLoading(false);
      return;
    }
    setStaffAlerts(data.supportStaffWithAlerts.filter((alert) => alert.is_customer && !alert.handheld_config));
    setTerminalAlerts(data.supportStaffWithAlerts.filter((alert) => alert.is_customer && alert.handheld_config));
    setFraudContacts(data.fraud_contacts);
    setPossibleStations(data.possibleStations);
    setIsLoading(false);
  };

  useEffect(() => {
    setAlertData();
  }, [props.selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OPContainer
      selectedCustomer={props.selectedCustomer}
      title="Support Alerts"
      refreshOnCurrentPageClick={true}
      // description={description}
    >
      {!hideTabHeaders && (
        <div className={"margin-bottom-4"}>
          <StyledAlert
            message={
              <span>
                We've updated our support alerts page! We'll retire the old page soon but you can view it{" "}
                <a href={legacyLink}>here.</a>
              </span>
            }
          />
        </div>
      )}
      <BbotTabs id="support-alerts" hideTabHeaders={hideTabHeaders}>
        <BbotTabs.TabPane tab="Staff Alerts" key="staff-alerts" id="staff-alerts">
          <div className={"padding-x-2"}>
            <StaffAlerts
              staffAlerts={staffAlerts}
              isLoading={isLoading}
              selectedCustomer={props.selectedCustomer}
              setTabHeaderVisibility={(isEditing) => setHideTabHeaders(isEditing)}
              possibleStations={possibleStations}
              refreshData={setAlertData}
            />
          </div>
        </BbotTabs.TabPane>
        <BbotTabs.TabPane tab="Terminal Alerts" key="terminal-alerts" id="terminal-alerts">
          <div className={"padding-x-2"}>
            <TerminalAlerts
              terminalAlerts={terminalAlerts}
              possibleStations={possibleStations}
              isLoading={isLoading}
              selectedCustomer={props.selectedCustomer}
              refreshData={setAlertData}
            />
          </div>
        </BbotTabs.TabPane>
        <BbotTabs.TabPane tab="Fraud Contacts" key="fraud-contacts" id="fraud-contacts">
          <div className={"padding-x-2"}>
            <FraudContacts
              fraudContacts={fraudContacts}
              isLoading={isLoading}
              selectedCustomer={props.selectedCustomer}
              refreshData={setAlertData}
            />
          </div>
        </BbotTabs.TabPane>
      </BbotTabs>
    </OPContainer>
  );
};

export default SupportAlerts;

const StyledAlert = styled(BbotAlert)`
  margin-bottom: 15px;
`;
