import React, { useEffect, useState } from "react";
import { Colors, InlineChildren, StackChildren, Text } from "@doordash/design-language";
import { TASK_WIDGET_INFO } from "components/customer-journey/JourneyTask";
import styled from "styled-components";
import { EmailField, PhoneField, TextField } from "@doordash/component-fields";
import Button from "@doordash/component-button";
import ListCell from "@doordash/component-list-cell";
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from "Constants";

const getInitialState = (contacts) => ({
  firstName: {
    value: contacts.primary.first_name ?? "",
    error: "",
    isRequired: true,
  },
  lastName: {
    value: contacts.primary.last_name ?? "",
    error: "",
    isRequired: true,
  },
  phone: {
    value: contacts.primary.phone ?? "",
    error: "",
    isRequired: true,
  },
  email: {
    value: contacts.primary.email ?? "",
    error: "",
    isRequired: true,
  },
});

const PrimaryContactWidget = ({ contacts, onSave, isSaving }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [primaryContactFormValues, setPrimaryContactFormValues] = useState(getInitialState(contacts));

  useEffect(() => {
    onFieldChange("firstName", contacts.primary.first_name);
    onFieldChange("lastName", contacts.primary.last_name);
    onFieldChange("email", contacts.primary.email);
    onFieldChange("phone", contacts.primary.phone);
    setIsEditing(
      [contacts.primary.first_name, contacts.primary.last_name, contacts.primary.email, contacts.primary.phone].every(
        (value) => {
          return value === "";
        }
      )
    );
  }, [contacts]);

  const onFieldChange = (fieldName, fieldValue) => {
    setPrimaryContactFormValues((formValues) => {
      return {
        ...formValues,
        [fieldName]: {
          value: fieldValue,
          error: "",
          isRequired: formValues[fieldName].isRequired,
        },
      };
    });
  };

  const validatePrimaryContactForm = () => {
    let formHasErrors = false;

    const formEntries = Object.entries(primaryContactFormValues).map(([fieldName, { value, isRequired }]) => {
      let validationError = "";

      // valid phone number
      if (fieldName === "phone") {
        if (!!value.length && (!value.match(PHONE_VALIDATION_REGEX) || value.includes(" "))) {
          formHasErrors = true;
          validationError = "Please enter a valid phone number";
        }
      }

      // valid email
      if (fieldName === "email") {
        if (!!value.length && (!value.match(EMAIL_VALIDATION_REGEX) || value.includes(" "))) {
          formHasErrors = true;
          validationError = "Please enter a valid email";
        }
      }

      const hasValidationError = isRequired && !value.length;
      if (hasValidationError) {
        formHasErrors = true;
        validationError = "This field is required";
      }

      return [
        fieldName,
        {
          value,
          isRequired,
          error: validationError,
        },
      ];
    });

    setPrimaryContactFormValues(Object.fromEntries(formEntries));
    return !formHasErrors;
  };

  const onSaveClicked = () => {
    if (!validatePrimaryContactForm()) {
      return;
    }

    onSave({
      primary_first_name: primaryContactFormValues.firstName.value,
      primary_last_name: primaryContactFormValues.lastName.value,
      primary_email: primaryContactFormValues.email.value,
      primary_phone: primaryContactFormValues.phone.value,
    });

    setIsEditing(false);
  };

  return (
    <div>
      <div>
        <Text tag="h1" styles={Text.Styles.Title1} data-test-id="subtask-title-1">
          {TASK_WIDGET_INFO.primary_contact.title}
        </Text>
        <div className="margin-bottom-2">
          <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
            {TASK_WIDGET_INFO.primary_contact.description}
          </Text>
        </div>
      </div>
      {!isEditing ? (
        <StackChildren size={0}>
          <ListCell
            title={<Text styles={Text.Styles.Body2Emphasis}>First Name</Text>}
            insetHorizontalSize={ListCell.InsetSizes.None}
            insetSize={ListCell.InsetSizes.XxSmall}
            isSeparatorVisible={false}
            subtext={
              <Text
                styles={Text.Styles.Body1}
                color={Colors.TextSecondary}
                data-test-id="primary-first-name-saved-display"
              >
                {primaryContactFormValues.firstName.value}
              </Text>
            }
            renderAfterContent={() => (
              <EditButtonWrap>
                <Button
                  data-test-id="edit-primary-contract-button"
                  type={Button.Types.Tertiary}
                  isInline
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </EditButtonWrap>
            )}
          />
          <ListCell
            title={<Text styles={Text.Styles.Body2Emphasis}>Last Name</Text>}
            insetHorizontalSize={ListCell.InsetSizes.None}
            insetSize={ListCell.InsetSizes.XxSmall}
            isSeparatorVisible={false}
            subtext={
              <Text
                styles={Text.Styles.Body1}
                color={Colors.TextSecondary}
                data-test-id="primary-last-name-saved-display"
              >
                {primaryContactFormValues.lastName.value}
              </Text>
            }
          />
          <ListCell
            title={<Text styles={Text.Styles.Body2Emphasis}>Phone Number</Text>}
            insetHorizontalSize={ListCell.InsetSizes.None}
            insetSize={ListCell.InsetSizes.XxSmall}
            isSeparatorVisible={false}
            subtext={
              <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="primary-phone-saved-display">
                {primaryContactFormValues.phone.value}
              </Text>
            }
          />
          <ListCell
            title={<Text styles={Text.Styles.Body2Emphasis}>Email</Text>}
            insetHorizontalSize={ListCell.InsetSizes.None}
            insetSize={ListCell.InsetSizes.XxSmall}
            isSeparatorVisible={false}
            subtext={
              <Text styles={Text.Styles.Body1} color={Colors.TextSecondary} data-test-id="primary-email-saved-display">
                {primaryContactFormValues.email.value}
              </Text>
            }
          />
        </StackChildren>
      ) : (
        <StackChildren size={StackChildren.Sizes.Large}>
          <StackChildren>
            <InlineChildren>
              <TextField
                label={"First Name"}
                placeholder="First Name"
                value={primaryContactFormValues.firstName.value}
                error={primaryContactFormValues.firstName.error}
                onChange={(firstName) => onFieldChange("firstName", firstName)}
                data-test-id="primary-first-name-field"
              />
              <TextField
                label={"Last Name"}
                placeholder="Last Name"
                value={primaryContactFormValues.lastName.value}
                error={primaryContactFormValues.lastName.error}
                onChange={(lastName) => onFieldChange("lastName", lastName)}
                data-test-id="primary-last-name-field"
              />
            </InlineChildren>
            <InlineChildren>
              <EmailField
                label={"Email"}
                placeholder="Email"
                value={primaryContactFormValues.email.value}
                error={primaryContactFormValues.email.error}
                onChange={(email) => onFieldChange("email", email)}
                data-test-id="primary-email-field"
              />
              <PhoneField
                label={"Phone Number"}
                placeholder="(000) 000-0000"
                value={primaryContactFormValues.phone.value}
                error={primaryContactFormValues.phone.error}
                onChange={(phone) => onFieldChange("phone", phone)}
                data-test-id="primary-phone-field"
              />
            </InlineChildren>
          </StackChildren>
          <Button
            isInline
            onClick={onSaveClicked}
            state={isSaving ? Button.States.Loading : Button.States.Default}
            data-test-id="save-primary-contact-button"
          >
            Save Contact
          </Button>
        </StackChildren>
      )}
    </div>
  );
};

export default PrimaryContactWidget;

const EditButtonWrap = styled.div`
  margin-top: 3px;
`;
