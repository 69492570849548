import React, { useState } from "react";
import Button from "@doordash/component-button";
import { BorderRadius, Colors, Icon, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
import { MenuData, MenuForm } from "./widgets/UploadMenuAssetsWidget";
import styled from "styled-components";
import { bytesToReadableSize, downloadDataFromBrowser, generalErrorAlert, openLinkInNewTab } from "util/Utils";
import AddMenusForm from "./forms/AddMenusForm";
import axios from "axios";
import { FILESTACK_API_KEY } from "Constants";
import { FileThumbnail, FsFileMetadata } from "components/owner-app/file-upload";
import { Confirm } from "@doordash/component-modal";

type MenuPreviewProps = {
  menu: MenuData;
  menuIndex: number;
  onRemoveMenu: (menuIndex: number, onFinallyCallback: () => void) => void;
  onSaveMenu: (menuFormValues: MenuForm, uploadedMenuFiles: FsFileMetadata[], onFinally: () => void) => void;
  userInfo: any;
};

const AddedMenuPreview: React.FC<MenuPreviewProps> = ({ menu, menuIndex, onRemoveMenu, onSaveMenu, userInfo }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [showEditContent, setShowEditContent] = useState(false);
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);

  const downloadFiles = async () => {
    try {
      const fileHandles: string[] = menu.menu_uploads.map((menuUpload) => menuUpload.handle);
      const res = await axios.get(`https://cdn.filestackcontent.com/${FILESTACK_API_KEY}/zip/[${fileHandles}]`, {
        withCredentials: false,
        responseType: "arraybuffer",
      });
      downloadDataFromBrowser(res.data, "menu-assets");
    } catch (error) {
      generalErrorAlert("There was an error downloading the files.");
    }
  };

  const removeMenu = () => {
    setIsRemoving(true);
    onRemoveMenu(menuIndex, () => {
      setIsRemoving(false);
    });
  };

  const onRemoveClicked = () => {
    if (userInfo?.role === "admin") {
      setShowRemovalConfirmation(true);
      return;
    }

    removeMenu();
  };

  return (
    <AddedMenuContainer>
      {showEditContent ? (
        <AddMenusForm
          onSave={onSaveMenu}
          onCancel={() => setShowEditContent(false)}
          initialState={{
            menuName: menu.menu_name,
            menuHours: menu.hours,
            menuLink: menu.menu_url,
          }}
          initialFiles={menu.menu_uploads}
          formLabel={"Edit Menu"}
        />
      ) : (
        <>
          <div className="margin-bottom-2">
            <StackChildren size={StackChildren.Sizes.XxxSmall}>
              <Text styles={Text.Styles.Body1Emphasis}>{menu.menu_name}</Text>

              {!!menu.menu_url.length && (
                <ButtonLinkWrapper>
                  <Button
                    isInline
                    type={Button.Types.Link}
                    onClick={() => openLinkInNewTab(menu.menu_url)}
                    accessibilityRole="link"
                  >
                    {menu.menu_url}
                  </Button>
                </ButtonLinkWrapper>
              )}

              {menu.hours.split("\n").map((line, i) => (
                <Text key={i} styles={Text.Styles.Body2} color={Colors.TextSecondary}>
                  {line}
                </Text>
              ))}
            </StackChildren>
          </div>

          {!!menu.menu_uploads?.length && (
            <div className="margin-bottom-2">
              <StackChildren>
                {menu.menu_uploads.map((menuAsset) => (
                  <InlineChildren key={menuAsset.handle}>
                    <FileThumbnail type={menuAsset.mimetype} />
                    <StackChildren size={StackChildren.Sizes.XxxSmall}>
                      <Text styles={Text.Styles.Label1Emphasis}>{menuAsset.filename}</Text>
                      <Text styles={Text.Styles.Label1Emphasis} color={Colors.TextTertiary}>
                        {bytesToReadableSize(menuAsset.size)}
                      </Text>
                    </StackChildren>
                  </InlineChildren>
                ))}
              </StackChildren>
            </div>
          )}

          <InlineChildren>
            {userInfo?.role === "admin" && !!menu.menu_uploads?.length && (
              <ButtonDangerTertiary>
                <Button isInline type={Button.Types.Tertiary} onClick={downloadFiles}>
                  {`Download Attachment${menu.menu_uploads.length > 1 ? "s" : ""}`}
                </Button>
              </ButtonDangerTertiary>
            )}

            <Button
              isInline
              type={Button.Types.Tertiary}
              onClick={() => {
                setShowEditContent(true);
              }}
              isDisabled={isRemoving}
            >
              Edit
            </Button>

            <Button
              isInline
              type={Button.Types.Tertiary}
              onClick={onRemoveClicked}
              state={isRemoving ? Button.States.Loading : Button.States.Default}
              isDisabled={isRemoving}
            >
              Remove
            </Button>
          </InlineChildren>
        </>
      )}

      {showRemovalConfirmation && (
        <Confirm
          title="Are you sure you want to remove this menu?"
          dismissText="Cancel"
          onDismiss={() => setShowRemovalConfirmation(false)}
          onConfirm={() => {
            removeMenu();
            setShowRemovalConfirmation(false);
          }}
        />
      )}
    </AddedMenuContainer>
  );
};

export default AddedMenuPreview;

const AddedMenuContainer = styled.div`
  border-radius: ${BorderRadius.Medium}px;
  border: 1px solid ${Colors.BorderPrimary};
  padding: ${Spacing.Medium}px;
  margin-bottom: ${Spacing.Medium}px;
`;

const ButtonDangerTertiary = styled.div`
  button,
  button:hover {
    box-shadow: 0 0 0 1px ${Colors.GlobalPrimary} inset;
  }
`;

const ButtonLinkWrapper = styled.div`
  button,
  button:hover {
    span {
      font-weight: 400;
    }
  }
`;
