import React from "react";
import { StackChildren } from "@doordash/design-language";

import DragDrop, { DragDropProps } from "./DragDrop";
import FileUploadProvider, { FileUploadProviderProps } from "./FileUploadProvider";
import FileUploadManager from "./FileUploadManager";

type FileUploadProps = FileUploadProviderProps & DragDropProps;

const FileUpload: React.FC<FileUploadProps> = ({
  onSave,
  savedFiles = [],
  acceptedTypes,
  maxFileSize,
  minFileSize,
  validateFile,
  layout,
  isMobile,
  children,
}) => {
  return (
    <FileUploadProvider
      onSave={onSave}
      savedFiles={savedFiles}
      acceptedTypes={acceptedTypes}
      maxFileSize={maxFileSize}
      minFileSize={minFileSize}
      validateFile={validateFile}
    >
      <StackChildren>
        <DragDrop layout={layout} isMobile={isMobile}>
          {children}
        </DragDrop>
        <FileUploadManager />
      </StackChildren>
    </FileUploadProvider>
  );
};

export default FileUpload;
