import React, { useEffect, useState } from "react";
import axios from "axios";
import { BbotModal, Select, notification } from "bbot-component-library";
import styled from "styled-components";

const DeleteLocationsModal = (props) => {
  const [locationIdToMoveOrdersTo, setLocationIdToMoveOrdersTo] = useState(null);

  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (props.locations.length > 0) {
      setLocationIdToMoveOrdersTo(props.locations[0].locationId);
    }
  }, [props.locations]);

  const { show, onHideCallback, onSubmitCallback, locations, selectedLocationIds } = props;

  const deleteLocations = async () => {
    setDeleting(true);
    try {
      const payload = {
        location_id_to_move_orders_to: locationIdToMoveOrdersTo,
        location_ids_to_delete: selectedLocationIds,
      };
      await axios.post("api/deleteDeliveryLocations", payload);
      notification.success({ message: "Successfully deleted locations." });
      onSubmitCallback();
      onHideCallback();
    } catch (error) {
      notification.error({ message: "Could not delete locations." });
    }
    setDeleting(false);
  };

  const locationsLeft = locations.filter((location) => !selectedLocationIds.includes(location.locationId));

  return (
    <BbotModal
      visible={show}
      onCancel={onHideCallback}
      title={"Delete Locations"}
      okText={"Delete"}
      onOk={deleteLocations}
      okButtonProps={{
        loading: deleting,
      }}
    >
      <div className={"margin-bottom-2"}>
        <div className="supporting-text margin-bottom-1">Move location past orders to</div>

        <StyledLocationDropdown
          onChange={(value) => {
            setLocationIdToMoveOrdersTo(value);
          }}
          defaultValue={locationsLeft[0]?.locationId}
        >
          {locations
            .filter((location) => !selectedLocationIds.includes(location.locationId))
            .map((location) => (
              <Select.Option key={location.locationId}>{location.locationName}</Select.Option>
            ))}
        </StyledLocationDropdown>
      </div>
      <div className={"margin-bottom-1"}>
        <h5>Deleting the following locations:</h5>
      </div>
      {selectedLocationIds.map((selectedLocationId) => {
        const location = locations.find((location) => location.locationId === selectedLocationId);
        return (
          <div className={"margin-bottom-1"} key={selectedLocationId}>
            <div>{location?.locationName}</div>
            <div className={"supporting-text"}>{location?.locationShortId}</div>
          </div>
        );
      })}
    </BbotModal>
  );
};

export default DeleteLocationsModal;

const StyledLocationDropdown = styled(Select)`
  width: 100%;
`;
