import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Badge, Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { NumberInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import { generalErrorAlert } from "../../../../util/Utils";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";

class StuartIntegrationModal extends Component {
  state = {
    clientId: "",
    clientSecret: "",
    enabled: false,
    maxDeliveryCostCents: 0,
    typicalPrepMinutes: 25,
    statusBoard: [],
    loadingData: false,
    savingData: false,
    hasLoaded: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getStuartInfo();
    }
  }

  getStuartInfo = async () => {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) return;

    this.setState({ loadingData: true });
    try {
      const res = await axios.get("/admin/getStuartConnectionStatus", {
        params: {
          customer_id: selectedCustomer.customer_id,
        },
      });

      this.setState({
        clientId: res.data.client_id,
        clientSecret: res.data.client_secret,
        enabled: res.data.enabled,
        maxDeliveryCostCents: res.data.max_delivery_cost_cents / 100,
        typicalPrepMinutes: res.data.typical_prep_minutes,
        statusBoard: res.data.status_board,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Error connecting Stuart.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  onHide = () => {
    const { onHide } = this.props;
    onHide();
  };

  saveStuartSettings = async (values) => {
    const { selectedCustomer, onSave } = this.props;

    this.setState({ savingData: true });
    try {
      const payload = {
        client_id: values.clientId,
        client_secret: values.clientSecret,
        customer_id: selectedCustomer.customer_id,
        enabled: true,
        max_delivery_cost_cents: values.maxDeliveryCostCents * 100,
        typical_prep_minutes: values.typicalPrepMinutes,
      };
      await axios.post("/admin/connectStuart", payload);
      toast.success("Successfully connected to Stuart");
      this.onHide();
      this.getStuartInfo();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error saving settings to Stuart.");
    } finally {
      this.setState({ savingData: false });
    }
  };

  disconnect = async () => {
    // To disconnect, we're really just setting the integration's enabled setting to false.
    const { selectedCustomer, onSave } = this.props;
    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        enabled: false,
      };
      await axios.post("/admin/connectStuart", payload);
      toast.success("Successfully disconnected from Stuart");
      this.onHide();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error disconnecting Stuart.");
    }
  };

  disconnectModal = () => {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Stuart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Are you sure you want to disconnect from Stuart?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button size={"sm"} variant={"danger"} onClick={this.disconnect}>
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { show, disconnectMode } = this.props;
    const { clientId, clientSecret, maxDeliveryCostCents, typicalPrepMinutes, statusBoard, loadingData, savingData } =
      this.state;

    return disconnectMode ? (
      this.disconnectModal()
    ) : (
      <Modal show={show} onHide={this.onHide}>
        <Formik
          enableReinitialize
          initialValues={{
            clientId: clientId,
            clientSecret: clientSecret,
            maxDeliveryCostCents: maxDeliveryCostCents,
            typicalPrepMinutes: typicalPrepMinutes,
          }}
          validationSchema={Yup.object({
            clientId: Yup.string().required(),
            clientSecret: Yup.string().required(),
            maxDeliveryCostCents: Yup.number().min(0).required(),
            typicalPrepMinutes: Yup.number().min(15).required(),
          })}
          onSubmit={this.saveStuartSettings}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Connect Stuart</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"padding-x-4"}>
              {loadingData ? (
                <CenteredLoadingSpinner label={"Loading Stuart Integration data"} />
              ) : (
                <>
                  <div className={"margin-bottom-2"}>
                    <h3 className={"margin-bottom-1"}>Integration prerequisites</h3>
                    <div>
                      {statusBoard.map((prereq, index) => (
                        <div key={index}>
                          <span className={"margin-right-2"}>{prereq.description}</span>
                          <span>
                            {prereq.ok ? (
                              <Badge pill variant={"success"}>
                                Yes
                              </Badge>
                            ) : (
                              <Badge pill variant={"danger"}>
                                No
                              </Badge>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={"margin-bottom-2"}>
                    You can sign up for Stuart <a href={"https://www.stuart.com"}>here</a>. <br />
                    To get Stuart API credentials, click <a href={"https://admin.stuart.com/client/api"}>here</a>.
                  </div>
                  <Row className={"margin-bottom-2"}>
                    <TextInput label={"Api Client Key"} name={"clientId"} id={"client-id"} sm={12} />
                  </Row>
                  <Row className={"margin-bottom-2"}>
                    <TextInput label={"Api Secret"} name={"clientSecret"} id={"client-secret"} sm={12} />
                  </Row>
                  <Row className={"margin-bottom-2"}>
                    <NumberInput
                      label={"Minutes before driver comes for pickup"}
                      name={"typicalPrepMinutes"}
                      id={"typical-prep-minutes"}
                      sm={12}
                    />
                  </Row>
                  <Row className={"margin-bottom-2"}>
                    <NumberInput
                      label={"Maximum delivery cost allowed per order (leave 0 for unlimited)"}
                      name={"maxDeliveryCostCents"}
                      id={"max-delivery-cost-cents"}
                      sm={12}
                    />
                  </Row>
                  <div>
                    <p>
                      To enable driver status updates in the terminal, navigate to{" "}
                      <a href={"https://admin.stuart.com/client/api "}>the stuart site</a> and set the following fields:
                    </p>
                    <ol>
                      <li>
                        <p>Webhook V2 URL: https://staging.bbot.menu/api/webhook/stuart</p>
                      </li>
                      <li>
                        <p>Webhook authentication header: X-Bbot-Webhook-Key</p>
                      </li>
                      <li>
                        <p>Webhook authentication key: 8726323iuerhiasdfuasd238r672jfgsafjksdgfds8762389r689asdfk2</p>
                      </li>
                    </ol>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <>
                <Button size={"sm"} variant={"light"} className={"margin-right-1"} onClick={this.onHide}>
                  Cancel
                </Button>
                <Button
                  size={"sm"}
                  variant={"primary"}
                  type={"submit"}
                  disabled={loadingData || savingData || statusBoard.some((prereq) => !prereq.ok)}
                >
                  Save
                </Button>
              </>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    );
  }
}

StuartIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default StuartIntegrationModal;
