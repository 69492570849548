import React, { useState } from "react";
import axios from "axios";
import moment from "moment";

// Components
import { Accordion, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { DatePicker } from "bbot-component-library";
import ChevronIcon from "assets/svgs/icons/icon-chevron.svg";

const TabCard = ({ tab }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dateValue, setDateValue] = useState(moment(tab.end_date));
  const handleSetTime = async (tab) => {
    try {
      const response = await axios.post("admin/edit-tab-from-dev-tools", {
        tab_id: tab.id,
        end_date: dateValue,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      toast.success(`Successfully edited time of tab ${tab.id}`, {
        autoClose: 2000,
      });
    } catch (err) {
      console.error(err);
      toast.error("Could not edit time of tab");
    }
  };

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={tab.id}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <b>{tab.id}</b>
        <span>End Date: {moment(tab.end_date).format(`MMM DD YYYY h:mm a`)}</span>
        <img className={"icon small " + (isExpanded && "rotate90")} src={ChevronIcon} alt={"chevron"} />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={tab.id}>
        <Card.Body>
          <p className="font-weight-bold">
            Start Date:{" "}
            <span className="font-weight-normal">{moment(tab.start_date).format(`MMM DD YYYY h:mm a`)}</span>
          </p>
          <p className="font-weight-bold">
            End Date: <span className="font-weight-normal">{moment(tab.end_date).format(`MMM DD YYYY h:mm a`)}</span>
            {isEditing && (
              <DatePicker
                showTime
                defaultValue={moment(tab.end_date)}
                onChange={(value) => setDateValue(value)}
                name="merp"
                id={"tab-date"}
                placeholder={tab.end_date}
              />
            )}
            {isEditing ? (
              <Button
                className="float-right"
                onClick={() => {
                  setIsEditing(false);
                  handleSetTime(tab);
                }}
              >
                Change End Date
              </Button>
            ) : (
              <Button className="float-right" variant="light" onClick={() => setIsEditing(true)} disabled={isEditing}>
                Edit End Time
              </Button>
            )}
          </p>
          <p className="font-weight-bold">
            Available Cents: <span className="font-weight-normal">{tab.available_cents / 100}</span>
          </p>
          <p className="font-weight-bold">
            Used Cents: <span className="font-weight-normal">{tab.used_cents}</span>
          </p>
          <p className="font-weight-bold">
            Limit Cents: <span className="font-weight-normal">{tab.limit_cents}</span>
          </p>
          <p className="font-weight-bold">
            Managing Customer Name: <span className="font-weight-normal">{tab.managing_customer.customer_name}</span>
          </p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const OpenTabList = ({ tabs }) => {
  return (
    <Accordion style={{ maxHeight: 600, overflow: "auto" }} className="border border-secondary rounded">
      {tabs.map((tab) => (
        <TabCard tab={tab} key={`${tab.id}${tab.end_date}`} />
      ))}
    </Accordion>
  );
};

const OpenTabControls = () => {
  const [openTabs, setOpenTabs] = useState([]);

  const fetchOpenTabs = async () => {
    try {
      const response = await axios.get("admin/get-open-tabs-in-dev-tools");

      const tabs = response.data.open_tabs;

      if (tabs.length === 0) {
        toast.info("No tabs currently open.");
        return;
      }

      setOpenTabs(tabs);

      const pluralOrNot = tabs.length > 1 ? "s" : "";

      toast.success(`Found ${tabs.length} open tab${pluralOrNot}.`, {
        autoClose: 3000,
      });
    } catch (err) {
      console.error(err);
      toast.error("Sorry, something went wrong and the search for tabs failed");
    }
  };

  return (
    <div className="shadow border border-secondary padding-3 bg-light rounded">
      <h3 className="margin-y-4 justify-content-center">
        Open tabs:
        <span className="float-right">
          <Button className="margin-right-3" variant="primary" onClick={fetchOpenTabs}>
            Search
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenTabs([]);
            }}
          >
            Hide Results
          </Button>
        </span>
      </h3>
      <p>Searches for all open tabs with an end date in the future</p>
      {openTabs && openTabs.length > 0 ? <OpenTabList tabs={openTabs} /> : null}
    </div>
  );
};

export default OpenTabControls;
