import React from "react";
import { Form } from "react-bootstrap";
import CheckboxInput from "../../../../../src/components/global/form/CheckboxInput";
import { SearchSelectInput } from "../../../../../src/components/global/form";
import DisplayExtraCheckoutInfoOnTicketsFields from "../../../../../src/components/global/form/pos-integrations/DisplayExtraCheckoutInfoOnTicketsFields";

const AgilysysIntegrationModalInputs = (props) => {
  let { agilysysSettings, setFieldValue, values } = props;

  const getDefaultEmployeeValue = () => {
    let matchingEmployee = agilysysSettings?.all_employees?.filter((e) => e.id === values?.employee);

    if (matchingEmployee != null && matchingEmployee.length > 0) {
      return {
        value: values.employee,
        label: matchingEmployee[0].name,
      };
    }
    return null;
  };

  const getDefaultProfitCenter = () => {
    let profitCenter = agilysysSettings?.all_profit_centers?.filter((e) => e.id === values?.profitCenter);

    if (profitCenter != null && profitCenter.length > 0) {
      return {
        value: values.profitCenter,
        label: profitCenter[0].name,
      };
    }
    return null;
  };

  const getDefaultTender = () => {
    let matchingTender = agilysysSettings?.all_tenders?.filter((e) => e.id === values?.tender);

    if (matchingTender != null && matchingTender.length > 0) {
      return {
        value: values.tender,
        label: matchingTender[0].name,
      };
    }
    return null;
  };

  return (
    <>
      <Form.Row className={"margin-bottom-1"}>
        <CheckboxInput sm={12} label="Inject orders into Agilysys" name="injectOrders" id="inject-orders" />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          isSearchable
          id={"employee"}
          md={12}
          name={"employee"}
          label={"Employee"}
          placeholder={"Select an Employee"}
          defaultValue={getDefaultEmployeeValue()}
          value={values.employee}
          options={agilysysSettings?.all_employees?.map((emp) => ({
            label: emp.name,
            value: emp.id,
          }))}
          onChange={(value) => setFieldValue("employee", value.value)}
        />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          isSearchable
          id={"profitCenter"}
          md={12}
          name={"profitCenter"}
          label={"Profit Center"}
          placeholder={"Select a Profit Center"}
          defaultValue={getDefaultProfitCenter()}
          value={values?.profitCenter}
          options={agilysysSettings?.all_profit_centers?.map((pc) => ({
            label: pc.name,
            value: pc.id,
          }))}
          onChange={(value) => setFieldValue("profitCenter", value.value)}
        />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <SearchSelectInput
          isSearchable
          id={"tender"}
          md={12}
          name={"tender"}
          label={"Default Tender"}
          placeholder={"Select a Tender"}
          defaultValue={getDefaultTender()}
          value={values?.tender}
          options={agilysysSettings?.all_tenders?.map((t) => ({
            label: t.name,
            value: t.id,
          }))}
          onChange={(value) => setFieldValue("tender", value.value)}
        />
      </Form.Row>
      <DisplayExtraCheckoutInfoOnTicketsFields injectOrders={values.injectOrders} posName={"Agilysys"} />
    </>
  );
};

export default AgilysysIntegrationModalInputs;
