import styled from "styled-components";
import React from "react";
import { Card } from "../bbot-component-library";

const SettingsCard = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};

export default SettingsCard;

const StyledCard = styled(Card)`
    padding: 30px;
    background-color: var(--color-neutral__card-background);
    border: 1px solid var(--color-neutral__line);
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgb(178 178 178 / 25%);
    width: 100%;
    overflow: hidden;
    
    .ant-card-body > div {
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral__line);
    }
    
    input {
      max-width: 400px;
    }
`;
