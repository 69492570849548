import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import withCustomerInfo from "components/global/higher-order-components/WithCustomerInfo";
// Pages
import Invite from "components/customer-journey/Invite";
import Journey from "components/customer-journey/Journey";
import NewCustomerLogin from "components/customer-journey/NewCustomerLogin";
import ScrollToTop from "components/global/higher-order-components/ScrollToTop";
import { LoggedInRoutes, ProtectedRoute } from "pages/loggedInRoutes";

const Routes = (props) => {
  const { getCustomerInfo } = props;

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {/* UNPROTECTED ROUTES */}
        <Route path="/login">
          <NewCustomerLogin
            {...props}
            loginSuccessCallback={() => {
              getCustomerInfo();
            }}
          />
        </Route>
        <Route path="/invite">
          <Invite {...props} />
        </Route>
        <ProtectedRoute exact path="/journey/:journeyId" component={Journey} {...props} />
        {/*OWNER APP ROUTES*/}
        <Route path="/">
          <LoggedInRoutes {...props} />
        </Route>
      </Switch>
    </Router>
  );
};

export default withCustomerInfo(Routes);
