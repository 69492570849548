import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import { NumberInput, TextInput } from "components/global/form";

class CreateEditSalesPersonModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validateText = (value) => {
    var error;
    if (value.length === 0 || (value.length > 0 && value.trim().length === 0)) {
      error = "Input a valid name.";
    }
    return error;
  };

  validatePercent = (value) => {
    var error;
    if (value < 0 || value > 99) {
      error = "Input a percentage between 0 and 99. ";
    }
    return error;
  };

  render() {
    const { show, mode, selectedRep, onHide } = this.props;

    const initialValues =
      mode === "create"
        ? {
            name: "",
            default_transaction_commission: 40,
            default_saas_commission: 40,
          }
        : {
            name: selectedRep?.name,
            default_transaction_commission: selectedRep?.default_transaction_commission * 100,
            default_saas_commission: selectedRep?.default_saas_commission * 100,
          };

    return (
      <Modal show={show} onHide={onHide} dialogClassName={"create-new-sales-rep-modal"} size={"med"}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            values.default_transaction_commission /= 100;
            values.default_saas_commission /= 100;
            this.props.saveCallback(values);
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            default_transaction_commission: Yup.number().min(0).max(100).required(),
            default_saas_commission: Yup.number().min(0).max(100).required(),
          })}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header className={"padding-top-3"} closeButton>
                <Modal.Title>{mode === "create" ? "Create New " : "Edit "}Sales Rep</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-y-2"}>
                <Form.Row className={"margin-bottom-2"}>
                  <TextInput label={"Name"} name={"name"} id={"name"} placeholder={"Salesperson Name"} sm={12} />
                </Form.Row>
                <Form.Row className={"margin-bottom-2"}>
                  <NumberInput
                    label={"Default Transaction Percentage"}
                    name={"default_transaction_commission"}
                    id={"default_transaction_commission"}
                    sm={12}
                  />
                </Form.Row>
                <Form.Row className={"margin-bottom-2"}>
                  <NumberInput
                    label={"Default SaaS Percentage"}
                    name={"default_saas_commission"}
                    id={"default_saas_commission"}
                  />
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <div className="actions">
                  <Button variant="tertiary" onClick={onHide} size={"sm"}>
                    Cancel
                  </Button>
                  <Button
                    className="margin-left-2"
                    variant="primary"
                    type="submit"
                    size={"sm"}
                    disabled={values.name.length === 0 || Object.keys(errors).length}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateEditSalesPersonModal;
