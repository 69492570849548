import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import React, { useEffect, useState } from "react";

const IncompleteJourneyModal = ({ allowedCustomers, selectedCustomer, selectAllowedCustomer, journeys, userInfo }) => {
  const [dropdownSelectedCustomer, setDropdownSelectedCustomer] = useState(null);

  const options = allowedCustomers
    .filter((customer) => customer.customer_id !== selectedCustomer?.customer_id)
    .map((customer) => ({
      value: customer.customer_id,
      label: customer.customer_name,
    }));

  const showModal =
    userInfo?.role !== "admin" &&
    journeys
      .filter((journey) => journey.customer_id === selectedCustomer?.customer_id)
      .some((journey) => journey.tasks_completed !== journey.total_tasks);

  useEffect(() => {
    if (!showModal) {
      setDropdownSelectedCustomer(null);
    }
  }, [showModal]);

  // onHide is a void function because we want the modal to not be hidden by clicking outside of it.
  return (
    <Modal show={showModal} onHide={() => void 0}>
      <Modal.Header>
        <Modal.Title>This account is not fully set up yet!</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"no-overflow"}>
        <div>
          <p>
            {selectedCustomer?.name_for_owner} has an incomplete account setup. You can continue setting up the account{" "}
            <Link to={"/myjourneys"}>here</Link>.
          </p>
        </div>

        {options.length > 0 && (
          <>
            <div>
              <p>Alternatively, you can switch to a different account using the dropdown below.</p>
            </div>
            <div className="customer-chooser">
              <ReactSelect options={options} onChange={(option) => setDropdownSelectedCustomer(option.value)} />
            </div>
          </>
        )}
      </Modal.Body>

      {options.length > 0 && (
        <Modal.Footer>
          <Button onClick={() => selectAllowedCustomer(dropdownSelectedCustomer)} disabled={!dropdownSelectedCustomer}>
            Continue
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default IncompleteJourneyModal;
