import React, { useEffect, useState } from "react";
import axios from "axios";
import Toggle from "@doordash/component-toggle";
import Button from "@doordash/component-button";
import { TextField } from "@doordash/component-fields";
import { Colors, StackChildren, Stack, Inline, Text, InlineChildren } from "@doordash/design-language";

import "styles/customer-journey/widget.scss";
import styled from "styled-components";
import { generalErrorAlert } from "util/Utils";
import ListCell from "@doordash/component-list-cell";

const NUMBER_OR_DECIMAL_REGEX = /^\d*\.?\d*$/;
const FLOAT_ROUNDING_10_DIGITS = 10000000000;
const FLOAT_ROUNDING_8_DIGITS = 100000000;

async function uploadTaxRate(taxRate, taxInclusivePricing, journeyId) {
  const endpoint = "/api/journey/saveTaxRate";
  try {
    await axios.post(endpoint, {
      tax_rate: taxRate,
      journey_id: journeyId,
      tax_inclusive_pricing: taxInclusivePricing,
    });
  } catch (error) {
    generalErrorAlert(error, "Error saving Tax Rate, please try again or contact support.", true);
  }
}

const TaxRateWidget = ({ widget, journey, updateTaskStatus }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(widget.status !== "completed");

  const [savedTaxRate, setSavedTaxRate] = useState("");
  const [savedTaxInclusivePricing, setSavedTaxInclusivePricing] = useState(false);

  const [taxRate, setTaxRate] = useState("");
  const [taxInclusivePricing, setTaxInclusivePricing] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function getTaxRate() {
      try {
        const res = await axios.get("/api/journey/getTaxRate", {
          params: { journey_id: journey.id },
        });
        const taxRate = res.data.tax_rate;

        const taxPercent = Math.round(taxRate * FLOAT_ROUNDING_10_DIGITS) / FLOAT_ROUNDING_8_DIGITS;

        if (taxRate !== 1 || widget.status === "completed") {
          setTaxRate(taxPercent);
          setSavedTaxRate(taxPercent);
        }

        setTaxInclusivePricing(res.data.tax_inclusive_pricing);
        setSavedTaxInclusivePricing(res.data.tax_inclusive_pricing);
      } catch (error) {
        generalErrorAlert(error, "Error getting Tax Rate, refresh the page to try again or contact support.", true);
      }
    }

    getTaxRate();
  }, [journey.id]);

  const saveTaxRate = (taxRate, taxInclusivePricing) => {
    if (taxRate <= 100 && taxRate >= 0) {
      uploadTaxRate(taxRate / 100, taxInclusivePricing, journey.id);
      updateTaskStatus("completed", { widgetId: widget.id });
    } else {
      setShowError(true);
    }
  };

  const handleSave = async () => {
    if (!!validate(taxRate)) {
      setShowError(true);
      return;
    }

    if (taxRate === savedTaxRate && taxInclusivePricing === savedTaxInclusivePricing) {
      setIsEditing(false);
      return;
    }

    setIsSaving(true);
    await saveTaxRate(taxRate, taxInclusivePricing);
    setIsSaving(false);
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <SaveStateWrap>
        <ListCell
          insetSize={ListCell.InsetSizes.XxSmall}
          insetHorizontalSize={ListCell.InsetSizes.None}
          insetVerticalSize={ListCell.InsetSizes.None}
        />
        <ListCell
          title={<Text styles={Text.Styles.Body1}>Tax Rate</Text>}
          insetHorizontalSize={ListCell.InsetSizes.None}
          insetSize={ListCell.InsetSizes.XSmall}
          subtextMaxLines={20}
          subtext={
            <StackChildren size={StackChildren.Sizes.XxSmall}>
              <InlineChildren>
                <StaticInputField>
                  <Text data-test-id="saved-tax-rate-text" styles={Text.Styles.Body2} color={Colors.TextSecondary}>
                    {taxRate} %{taxInclusivePricing ? ", Tax inclusive pricing" : ""}
                  </Text>
                </StaticInputField>
              </InlineChildren>
            </StackChildren>
          }
          renderAfterContent={() => (
            <EditButtonWrap>
              <Button
                data-test-id="edit-tax-button"
                type={Button.Types.Tertiary}
                isInline
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            </EditButtonWrap>
          )}
        />
      </SaveStateWrap>
    );
  }

  return (
    <div className={"margin-bottom-2"}>
      <InputContainer>
        <Stack size={Stack.Sizes.Large}>
          <TextField
            label="Tax Rate"
            data-test-id="tax-rate-input"
            value={String(taxRate)}
            placeholder={"8.5"}
            onChange={(tax) => {
              setTaxRate(tax);
            }}
            error={showError && validate(taxRate)}
            isRequired
            renderAfterContent={() => <Inline size={Inline.Sizes.XxSmall}>%</Inline>}
          />
        </Stack>
      </InputContainer>

      <StackChildren size={StackChildren.Sizes.Medium}>
        <Toggle
          id={"tax-inclusive-pricing"}
          data-test-id="use-tax-inclusive-checkbox"
          label="Use tax inclusive pricing"
          isSelected={taxInclusivePricing}
          onChange={() => setTaxInclusivePricing(!taxInclusivePricing)}
          renderLabel={({ htmlFor, label }) => (
            <StyledLabel htmlFor={htmlFor}>
              <Stack size={Stack.Sizes.XxxSmall}>
                <Text styles={Text.Styles.Body1Emphasis}>{label}</Text>
              </Stack>
              <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
                Item prices shown to guests will already have tax included.
              </Text>
            </StyledLabel>
          )}
        />

        <Button
          isInline
          id={"save-tax-rate-btn"}
          onClick={handleSave}
          data-test-id="save-tax-button"
          state={isSaving ? Button.States.Loading : Button.States.Default}
        >
          Save Tax Rate
        </Button>
      </StackChildren>
    </div>
  );
};

const validate = (tax) => {
  if (!tax && tax !== 0) {
    return "Tax rate is required";
  }

  if (!tax.toString().match(NUMBER_OR_DECIMAL_REGEX)) {
    return "Please enter a tax rate containing only numbers.";
  }

  tax = parseFloat(tax);

  if (isNaN(tax)) {
    return "Please enter tax rate as an integer or decimal.";
  }

  if (tax > 100 || tax < 0) {
    return "Please enter a tax rate between 0 and 100.";
  }

  return "";
};

export default TaxRateWidget;

const StyledLabel = styled.label`
  margin-top: 2px;
`;

export const InputContainer = styled.div`
  width: 50%;
`;

const StaticInputField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const EditButtonWrap = styled.div`
  margin-top: 3px;
`;

const SaveStateWrap = styled.div`
  margin-top: 30px;
`;
