import React from "react";

import { BbotButton, Row, Col, BbotModal } from "bbot-component-library";
import styled from "styled-components";

const CredentialDisplayModal = (props) => {
  const { show, onClose, credentials, credentialDisplayOneTimeViewing } = props;
  return (
    <BbotModal
      title={"Credentials"}
      visible={show}
      onCancel={onClose}
      closable
      footer={
        credentialDisplayOneTimeViewing ? (
          <BbotButton
            type={"primary"}
            danger
            onClick={() => {
              onClose();
            }}
          >
            OK
          </BbotButton>
        ) : (
          <BbotButton
            type={"primary"}
            onClick={() => {
              onClose();
            }}
          >
            OK
          </BbotButton>
        )
      }
    >
      <Row className={"margin-bottom-2"}>
        <Col span={24}>
          <div className={"margin-bottom-2"}>Here are your credentials:</div>
          {credentials.map((credential, index) => (
            <div key={index}>
              <span>
                <strong>{credential.display_name}</strong>
                {": " + credential.value}
              </span>
            </div>
          ))}
          {credentialDisplayOneTimeViewing && (
            <CredentialsWarning className={"margin-top-2"}>
              After clicking "OK", you will NOT be shown these credentials again. Please copy and paste them into
              LastPass or another secure password management tool.
            </CredentialsWarning>
          )}
        </Col>
      </Row>
    </BbotModal>
  );
};

const CredentialsWarning = styled.div`
  font-weight: bold;
  color: red;
`;

export default CredentialDisplayModal;
