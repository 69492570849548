import React, { useState } from "react";
import { BorderRadius, Colors, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
// @ts-ignore

import { bytesToReadable, FsFileMetadata } from "components/owner-app/file-upload";
import Button from "@doordash/component-button";
import styled from "styled-components";
import { UploadAssetsOptions } from "components/global/image-uploader/ImageUploaderAndCropper";
import { Modal } from "@doordash/component-modal";
import { ButtonDangerTertiary } from "bbot-component-library/DangerButton";

type SavedAssetTileProps = {
  files?: (FsFileMetadata | undefined)[];
  file?: FsFileMetadata;
  isAdmin: boolean;
  showPreviewOnSide: boolean;
  fileOptions: UploadAssetsOptions[];
  title: string;
  showEditButton?: boolean;
  downloadFile: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

const SavedAssetTile: React.FC<SavedAssetTileProps> = ({
  file,
  files,
  isAdmin,
  showPreviewOnSide,
  fileOptions,
  title,
  downloadFile,
  onEdit,
  onDelete,
  showEditButton = true,
}) => {
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);

  const onRemoveClicked = () => {
    if (isAdmin) {
      setShowRemoveConfirmModal(true);
    } else {
      onDelete();
    }
  };

  return (
    <TileContainer>
      <StackChildren>
        {!showPreviewOnSide && (
          <>
            {/* case 1: files prop is undefined or empty */}
            {!files?.length && <UploadedFullTopPreview src={file?.url} alt={file?.name} />}

            {/* case 2: there is 1 file */}
            {files?.length === 1 && (
              <UploadedFullTopPreview
                src={files[0]?.url}
                alt={files[0]?.name}
                restrictMaxHeight={fileOptions[0].minAspectRatio < 1}
              />
            )}

            {/* case 3: there are more than 1 file */}
            {files?.length && files?.length > 1 && (
              <div className={"padding-bottom-4"}>
                <InlineChildren>
                  {files?.map((file, index) => (
                    <UploadedBackgroundImgPreview
                      flexVal={fileOptions[index].minAspectRatio}
                      size={StackChildren.Sizes.Small}
                    >
                      <img src={file?.url} alt={file?.name} />
                      {files?.length > 1 && (
                        <Text styles={Text.Styles.Label1Emphasis}>{fileOptions[index].imageTitle}</Text>
                      )}
                    </UploadedBackgroundImgPreview>
                  ))}
                </InlineChildren>
              </div>
            )}
          </>
        )}

        <InlineChildren alignItems="center">
          {showPreviewOnSide && <UploadedSidePreview src={file?.url} alt={file?.name} />}
          <StackChildren size={StackChildren.Sizes.XxxxSmall}>
            <Text styles={Text.Styles.Label1Emphasis}>{file?.filename}</Text>
            <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextTertiary}>
              {bytesToReadable(file?.size ?? 0)}
            </Text>
          </StackChildren>
        </InlineChildren>

        <InlineChildren>
          {isAdmin && (
            <ButtonDangerTertiary>
              <Button
                type={Button.Types.Tertiary}
                isInline
                onClick={downloadFile}
                accessibilityLabel={`Download asset ${file?.filename}`}
              >
                Download Attachment
              </Button>
            </ButtonDangerTertiary>
          )}
          <Button
            type={Button.Types.Tertiary}
            isInline
            onClick={onRemoveClicked}
            accessibilityLabel={`Remove asset ${file?.filename}`}
          >
            Remove
          </Button>

          {showEditButton && (
            <Button
              type={Button.Types.Tertiary}
              isInline
              onClick={onEdit}
              accessibilityLabel={`Edit asset ${file?.filename}`}
            >
              Edit
            </Button>
          )}
        </InlineChildren>
      </StackChildren>

      {showRemoveConfirmModal && (
        <Modal
          title={`Remove ${title}`}
          onClose={() => setShowRemoveConfirmModal(false)}
          primaryAction={{ text: "Confirm", onClick: onDelete }}
          secondaryAction={{ text: "Cancel", onClick: () => setShowRemoveConfirmModal(false) }}
        >
          <Text color={Colors.TextSecondary}>{`Are you sure you want to remove ${title.toLowerCase()}?`}</Text>
        </Modal>
      )}
    </TileContainer>
  );
};

export default SavedAssetTile;

const TileContainer = styled.div`
  border: 1px solid ${Colors.BorderSecondary};
  border-radius: ${BorderRadius.Medium}px;
  padding: ${Spacing.Large}px;
  width: 100%;
`;

interface UploadedFullTopPreviewProps {
  restrictMaxHeight?: boolean;
}

const UploadedFullTopPreview = styled.img<UploadedFullTopPreviewProps>`
  width: ${(props) => (props.restrictMaxHeight ? "auto" : "100%")};
  height: ${(props) => (props.restrictMaxHeight ? "300px" : "auto")};
  max-height: 600px;
  border-radius: ${BorderRadius.Small}px;
`;

interface BackgroundImagePreviewProps {
  flexVal: number;
}

const UploadedBackgroundImgPreview = styled(StackChildren)<BackgroundImagePreviewProps>`
  height: auto;
  max-height: 300px;
  flex: ${(props) => props.flexVal} 0 0;

  img {
    height: 100%;
    width: 100%;
  }
`;

const UploadedSidePreview = styled.img`
  height: 100px;
  width: auto;
  padding: 12px;
  border-radius: 8px;
  border: 2px dashed ${Colors.BorderSecondary};
`;
