import { Input } from "bbot-component-library";
import React from "react";
import styled from "styled-components";

const BbotInput = ({ ...props }) => {
  return <InputStyled {...props} />;
};

const InputStyled = styled(Input)`
  width: 100%;
  padding: 8px 14px;
  border-radius: 8px;
`;

export default BbotInput;
