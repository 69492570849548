import React, { useState, useEffect } from "react";
import { Switch } from "bbot-component-library";

const BbotToggle = ({ defaultEnabled, onClick, onStateChanged, ...props }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(defaultEnabled);
  }, [defaultEnabled]);

  return (
    <Switch
      defaultChecked={defaultEnabled}
      onClick={(checked, e) => {
        // Augment the event object with SWITCH_STATE
        const switchEvent = Object.assign(e, {
          SWITCH_STATE: { enabled: checked },
        });

        // Execute the callback functions
        onClick && onClick(switchEvent);
      }}
      checked={checked}
      onChange={(checked) => {
        setChecked(checked);
        if (onStateChanged) {
          onStateChanged(checked);
        }
      }}
      {...props}
    />
  );
};

export default BbotToggle;
