import { Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import React, { useState } from "react";

const PcbGeneralCommandCard = ({ currentCommand, requestNewCommand }) => {
  const [commandParameter, setCommandParameter] = useState(() => {
    if (currentCommand.command_param?.type === "select") {
      return currentCommand.command_param?.options[0].value;
    }
    return "";
  });

  return (
    <Card className={"margin-bottom-2"}>
      <Card.Header className={"d-flex justify-content-space-between"}>
        <h3>{currentCommand.name}</h3>
      </Card.Header>
      <Card.Body>
        <div className={"styled-form"}>
          <div className={"margin-bottom-2"}>
            <div>
              <h5>{currentCommand.heading_text}</h5>
              <p style={{ marginTop: 10 }}>{currentCommand.description}</p>
            </div>
            <div>
              {currentCommand.command_param?.type === "text" && (
                <Form.Control
                  type="text"
                  placeholder={currentCommand.command_param.placeholder}
                  value={commandParameter}
                  onChange={(e) => setCommandParameter(e.target.value)}
                />
              )}
              {currentCommand.command_param?.type === "select" && (
                <Select
                  options={currentCommand.command_param.options}
                  defaultValue={currentCommand.command_param.options[0]}
                  onChange={(e) => setCommandParameter(e.value)}
                />
              )}
            </div>
            <Button
              className={"margin-y-1"}
              onClick={() => {
                requestNewCommand({
                  command: currentCommand.id,
                  command_name: currentCommand.name,
                  command_param: commandParameter,
                });
              }}
            >
              Request {currentCommand.name}
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PcbGeneralCommandCard;
