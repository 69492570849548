import React, { Component } from "react";
import { Table } from "react-bootstrap";

class OrderGroupingRulesTable extends Component {
  getLocationsString = (locations) => {
    let locationNames = [];

    locationNames = locations.map((loc) => loc.locationName);
    locationNames = locationNames.sort();

    return locationNames.join(", ");
  };

  getPrintersString = (printers, normal_print_routing) => {
    if (normal_print_routing) {
      return "Default Printers";
    }

    let printerNames = printers.map((printer) => printer.name);

    return printerNames.join(", ");
  };

  render() {
    const { orderGroupingRules, editOrderGroupingRule, addOrderGroupingRule, deleteOrderGroupingRule } = this.props;

    return (
      <div>
        <div className={"table-container sales-person-table"}>
          <div className={"header bg-blue-6"}>
            <h4>Order Grouping Rules</h4>
          </div>
          <Table hover size="sm" className={"margin-bottom-0"}>
            <thead>
              <tr>
                <th key={"order-grouping-rule-col-name"}>Title of Rule</th>
                <th key={"order-grouping-rule-col-rule-effect"}>Rule Effect</th>
                <th key={"order-grouping-rule-col-locations"}>Locations</th>
                <th key={"order-grouping-rule-col-print-to"}>Print To</th>
                <th key={"order-grouping-rule-col-edit-icon"}></th>
                <th key={"order-grouping-rule-col-del-icon"}></th>
              </tr>
            </thead>
            <tbody>
              {orderGroupingRules &&
                orderGroupingRules.map((orderGroupingRule, index) => (
                  <tr className={"order-grouping-rule-row"} key={"order-grouping-rule-" + index}>
                    <td key={"order-grouping-rule-col-name-" + index}>{orderGroupingRule.name_for_owner}</td>
                    <td key={"order-grouping-rule-col-rule-effect-" + index}>{orderGroupingRule.description}</td>
                    <td key={"order-grouping-rule-col-locations-" + index}>
                      {this.getLocationsString(orderGroupingRule.order_filter.locations)}
                    </td>
                    <td key={"order-grouping-rules-col-print-to-" + index}>
                      {this.getPrintersString(
                        orderGroupingRule.aggregator_printers,
                        orderGroupingRule.normal_print_routing
                      )}
                    </td>
                    <td
                      key={"order-grouping-rule-col-edit-icon-" + index}
                      onClick={() => editOrderGroupingRule(orderGroupingRule)}
                    >
                      <div className="zmdi zmdi-edit dashboard-link-icon" />
                    </td>
                    <td key={"order-grouping-rule-col-delete-icon-" + index}>
                      <div
                        className="zmdi zmdi-delete dashboard-link-icon"
                        onClick={() => deleteOrderGroupingRule(orderGroupingRule)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <button className="add-item" onClick={addOrderGroupingRule}>
            <div className={"icon-circle"}>+</div>
          </button>
        </div>
      </div>
    );
  }
}

export default OrderGroupingRulesTable;
