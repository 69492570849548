import React, { useState, useEffect } from "react";
import axios from "axios";
import BBOT_SERVER from "Config";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "components/global/modals/ConfirmationModal";

import Button from "@doordash/component-button";
import Banner from "@doordash/component-banner";
import { BorderRadius, Colors, Icon, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
import Tag from "@doordash/component-tag";
import Loading from "@doordash/component-loading";
import { openLinkInNewTab } from "util/Utils";

const STRIPE_BASE_URL = "https://connect.stripe.com/express/oauth/authorize";

const ConnectStripeWidget = ({
  task,
  widget,
  updateTaskStatus,
  journey,
  customerId,
  userInfo,
  setNavigationTab,
  showDashboardLink = true,
}) => {
  // HOOKS
  const location = useLocation();

  // STATE
  const [stripeConnectedAccountToken, setStripeConnectedAccountToken] = useState("");
  const [ownerHasConnectStripeAccount, setOwnerHasConnectedStripeAccount] = useState(false);
  const [ownerStripeId, setOwnerStripeId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  const userIsAdmin = userInfo?.role === "admin";
  const stripeConsoleUrl = BBOT_SERVER + "/owner/stripeconsole?customer_id=" + customerId;

  useEffect(() => {
    // const params = urlParams;
    const urlParams = new URLSearchParams(location.search);
    getDataForConnectStripe(urlParams, customerId);
  }, [customerId, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataForConnectStripe = async (params, customerId) => {
    if (!customerId) return;

    setIsLoading(true);
    // get the data from url params, send api request, and set state variables from respoonse.
    try {
      const res = await axios.get("/api/journey/getDataForConnectStripe", {
        params: {
          customer_id: customerId,
          err: params ? params.get("err") : "",
          dup_id: params ? params.get("dup_id") : "",
          provided: params ? params.get("provided") : "",
          expected: params ? params.get("expected") : "",
        },
      });
      const msg = res.data;
      setStripeConnectedAccountToken(msg.stripe_connected_account_token);
      setOwnerHasConnectedStripeAccount(msg.owner_has_connect_stripe_account);
      setOwnerStripeId(msg.owner_stripe_id);
      setErrorMessage(msg.error_message);

      const taskIsIncomplete = journey?.id && task.status !== "completed";
      if (msg.owner_has_connect_stripe_account && taskIsIncomplete) {
        updateTaskStatus("completed", { widgetId: widget?.id, goToNext: false });
      }

      // change to stripe tab if not in journey and is being redirected from stripe
      if ((msg.error_message || params?.get("stripe-redirect-success") === "true") && setNavigationTab) {
        setNavigationTab("payout-settings");
      }
    } catch (error) {
      const res = error.response;
      console.error("Error from getDataForConnectStripe: ", error, res && res.data);
      if (res && res.data && res.data.errorCode) {
        toast.error(res.data.errorCode);
        setErrorMessage(res.data.errorCode);
      }
    }
    setIsLoading(false);
  };

  const disconnectFromStripe = async () => {
    try {
      await axios.post(BBOT_SERVER + "/api/deleteStripeAccount", {
        customer_id: customerId,
      });
      await getDataForConnectStripe(null, customerId);
      if (journey) updateTaskStatus("in_progress", { widgetId: widget?.id });
      setOwnerHasConnectedStripeAccount(false);
    } catch (err) {
      toast.error("There was a problem disconnecting from stripe.");
      console.error(err);
    }
  };

  const journeyId = journey?.id;

  const stripeConnectUrl =
    STRIPE_BASE_URL +
    "?redirect_uri=" +
    BBOT_SERVER +
    "/api/journey/stripeoauth" +
    "&client_id=" +
    stripeConnectedAccountToken +
    "&state=" +
    (journeyId || customerId) +
    "&suggested_capabilities[]=transfers" +
    "&suggested_capabilities[]=tax_reporting_us_1099_k";

  if (isLoading)
    return (
      <div className={"padding-y-4 connect-stripe-container"}>
        <div className={"margin-bottom-1"}>
          <Loading />
        </div>
        <div>
          <Text>Loading Stripe Information</Text>
        </div>
      </div>
    );

  return ownerHasConnectStripeAccount ? (
    <StripeContainer>
      <StackChildren>
        {errorMessage && <Banner label={errorMessage} />}
        <InlineChildren>
          <Text styles={Text.Styles.Body1Emphasis}>Stripe Account</Text>
          <Tag
            text="Connected"
            hasLeadingIcon
            leadingIconType={Tag.IconTypes.CheckCircleFill}
            size={Tag.Sizes.Medium}
            type={Tag.Types.PositiveEmphasis}
          />
        </InlineChildren>

        {userIsAdmin && <Text styles={Text.Styles.Body2}>You are using the account {ownerStripeId}</Text>}

        {(showDashboardLink || userIsAdmin) && (
          <InlineChildren>
            {showDashboardLink && (
              <Button
                isInline
                trailingIcon={Icon.Types.LinkExternalLine}
                onClick={() => openLinkInNewTab(stripeConsoleUrl)}
              >
                Continue To Stripe Dashboard
              </Button>
            )}

            {userIsAdmin && (
              <Button isInline type={Button.Types.Tertiary} onClick={() => setShowDisconnectModal(true)}>
                Remove
              </Button>
            )}
          </InlineChildren>
        )}

        <ConfirmationModal
          show={showDisconnectModal}
          onClose={() => {
            setShowDisconnectModal(false);
          }}
          onConfirm={() => {
            disconnectFromStripe();
            setShowDisconnectModal(false);
          }}
          title={"Disconnect from Stripe Account"}
          description={"Are you sure you want to disconnect from your Stripe account?"}
          buttonText={"Disconnect"}
        />
      </StackChildren>
    </StripeContainer>
  ) : (
    <StackChildren>
      {errorMessage && <Banner label={errorMessage} />}

      <Banner
        label="Be sure to choose the right 'business entity' in Stripe"
        body={
          <StackChildren>
            <Text styles={Text.Styles.BannerBody}>
              The ‘business entity’ defines the type of business you own. Choosing the incorrect business entity while
              setting up your Stripe account can have potential implications to your income, taxes, and other aspects of
              your business.
              <br />
              <br />
              If you don’t know what your business entity is, please consult with your tax avisor, accountant, or legal
              representative.
            </Text>
            <Button
              isInline
              type={Button.Types.Tertiary}
              onClick={() =>
                window.open("https://central.bbot.menu/article/297-how-to-connect-with-stripe", "_blank").focus()
              }
            >
              Learn More
            </Button>
          </StackChildren>
        }
      />

      <Button isInline trailingIcon={Icon.Types.LinkExternalLine} onClick={() => (window.location = stripeConnectUrl)}>
        Continue To Stripe
      </Button>
    </StackChildren>
  );
};

export default ConnectStripeWidget;

const StripeContainer = styled.div`
  border: 1px solid ${Colors.BorderPrimary};
  border-radius: ${BorderRadius.Medium}px;
  padding: ${Spacing.Large}px;
`;
