import Icon from "@ant-design/icons";
import React from "react";

const DiscountSVG = () => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 18C2.5 9.16344 9.66344 2 18.5 2C27.3366 2 34.5 9.16344 34.5 18C34.5 26.8366 27.3366 34 18.5 34C9.66344 34 2.5 26.8366 2.5 18Z"
      fill="#D1E9FF"
    />
    <path
      d="M24.5 13.3333H25.8333V22.6667H24.5V13.3333ZM21.8333 13.3333H23.1667V22.6667H21.8333V13.3333ZM19.8333 13.3333H11.8333C11.4667 13.3333 11.1667 13.6333 11.1667 14V22C11.1667 22.3667 11.4667 22.6667 11.8333 22.6667H19.8333C20.2 22.6667 20.5 22.3667 20.5 22V14C20.5 13.6333 20.2 13.3333 19.8333 13.3333ZM19.1667 21.3333H12.5V14.6667H19.1667V21.3333Z"
      fill="#1570EF"
    />
    <path
      d="M15.8333 17.9267C16.5513 17.9267 17.1333 17.3446 17.1333 16.6267C17.1333 15.9087 16.5513 15.3267 15.8333 15.3267C15.1154 15.3267 14.5333 15.9087 14.5333 16.6267C14.5333 17.3446 15.1154 17.9267 15.8333 17.9267Z"
      fill="#1570EF"
    />
    <path
      d="M18.4267 20.2333C18.4267 19.3667 16.7 18.9333 15.8333 18.9333C14.9667 18.9333 13.24 19.3667 13.24 20.2333V20.6667H18.4267V20.2333Z"
      fill="#1570EF"
    />
    <path
      d="M18.5 32C10.768 32 4.5 25.732 4.5 18H0.5C0.5 27.9411 8.55887 36 18.5 36V32ZM32.5 18C32.5 25.732 26.232 32 18.5 32V36C28.4411 36 36.5 27.9411 36.5 18H32.5ZM18.5 4C26.232 4 32.5 10.268 32.5 18H36.5C36.5 8.05887 28.4411 0 18.5 0V4ZM18.5 0C8.55887 0 0.5 8.05887 0.5 18H4.5C4.5 10.268 10.768 4 18.5 4V0Z"
      fill="#EFF8FF"
    />
  </svg>
);

const Discount = () => <Icon title="Discount" aria-label="Discount" component={() => DiscountSVG()} />;

export default Discount;
