import React from "react";
import UserActionLog from "components/owner-app/user-action-log/UserActionLog";
import { Container, Col, Row } from "react-bootstrap";

class UserActionLogPage extends React.Component {
  render() {
    const { selectedCustomer, userInfo } = this.props;
    return (
      <div>
        {selectedCustomer ? (
          <Container>
            <Row>
              <Col md={12}>
                <h2>Audit Trail</h2>
                <p>View a log of actions performed by a user.</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <UserActionLog customerId={selectedCustomer.customer_id} userInfo={userInfo} />
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="margin-x-8 margin-y-2">
            <p>Please select a customer from the dropdown above.</p>
          </div>
        )}
      </div>
    );
  }
}

export default UserActionLogPage;
