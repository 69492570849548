import React, { useEffect, useState } from "react";
import FormattedCheckboxGroupInput from "bbot-component-library/form-inputs/FormattedCheckboxGroupInput";
import { Button } from "antd";
import styled from "styled-components";
import {
  fulfillmentMethodsToIds,
  removePatronChoice,
  locationsToIds,
  fulfillmentMethodsToArray,
  filterLocations,
  locationsToArray,
} from "util/Utils";
import PropTypes from "prop-types";

/*
Input with two checkbox groups, where locations shown are controlled by the fulfillment methods selected
problems
*/
const FulfillmentAndLocationsInput = ({
  locations,
  fulfillmentMethods,
  id,
  initialFulfillments,
  onChange = () => {},
  form,
  ...props
}) => {
  // State to hold the current values of the inputs
  const [fulfillmentsValue, setFulfillmentsValue] = useState(
    initialFulfillments ? initialFulfillments : fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods))
  );

  // Effects to set initial values
  useEffect(() => {
    if (initialFulfillments?.length > 0) setFulfillmentsValue(initialFulfillments);
  }, [initialFulfillments]);

  // State to hold the text for the toggle buttons
  const [fulfillmentToggleText, setFulfillmentToggleText] = useState(
    form.getFieldValue("fulfillment_methods")?.length ===
      fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods))?.length
      ? "Unselect all"
      : "Select all"
  );
  const [locationsToggleText, setLocationsToggleText] = useState(
    form.getFieldValue("locations")?.length === filterLocations(locations, fulfillmentsValue)?.length
      ? "Unselect all"
      : "Select all"
  );

  // Handler function for fulfillment method selection change
  const handleFulfillmentChange = (value) => {
    // Set the new value
    setFulfillmentsValue(value);
    // Callback function
    onChange({
      fulfillment_methods: value,
      location_ids: form.getFieldValue("locations"),
    });
    // If all fulfillment methods are selected set the toggle text to "Unselect all" otherwise set it to "Select all"
    if (value?.length === fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods))?.length) {
      setFulfillmentToggleText("Unselect all");
    } else {
      setFulfillmentToggleText("Select all");
      fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods));
    }
    // If all the currently visible (filtered) location codes are selected set toggle text "Unselect all" otherwise set
    // it to "Select all"
    if (form.getFieldValue("locations")?.length === filterLocations(locations, value)?.length) {
      setLocationsToggleText("Unselect all");
    } else {
      setLocationsToggleText("Select all");
    }
  };

  // Handler function for location code selection changes
  const handleLocationsChange = (value) => {
    // Callback function
    onChange({ fulfillment_methods: fulfillmentsValue, location_ids: value });
    // If all visible locations are selected set toggle text to "Unselect all" otherwise set it to "Select all"
    if (form.getFieldValue("locations")?.length === filterLocations(locations, fulfillmentsValue)?.length) {
      setLocationsToggleText("Unselect all");
    } else {
      setLocationsToggleText("Select all");
    }
  };
  // Validation rules. If all location codes are hidden block submission
  const rules = [
    {
      message: "Your current fulfillment method selection is hiding all location codes",
      required: true,
      type: "array",
      validateTrigger: ["onBlur", "onChange"],
      validator: () =>
        filterLocations(locations, fulfillmentsValue)?.length > 0 ? Promise.resolve() : Promise.reject(),
    },
  ];

  // Handler for toggle fulfillment methods button. If all fulfillment methods are selected unselect them all and handle
  // the change, otherwise select them all and handle the change
  const handleToggleFulfillments = () => {
    if (
      form.getFieldValue("fulfillment_methods")?.length ===
      fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods))?.length
    ) {
      form.setFieldsValue({ fulfillment_methods: [] });
      handleFulfillmentChange([]);
    } else {
      form.setFieldsValue({
        fulfillment_methods: fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods)),
      });
      handleFulfillmentChange(fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods)));
    }
  };

  // Handler for locations toggle button. If all visible locations are selected unselect them all and handle the change,
  // otherwise select all visible locations and handle the change
  const handleToggleLocations = () => {
    if (form.getFieldValue("locations")?.length === filterLocations(locations, fulfillmentsValue)?.length) {
      form.setFieldsValue({ locations: [] });
      handleLocationsChange([]);
    } else {
      form.setFieldsValue({
        locations: locationsToIds(filterLocations(locations, fulfillmentsValue)),
      });
      handleLocationsChange(locationsToIds(filterLocations(locations, fulfillmentsValue)));
    }
  };

  // Render
  return (
    <div>
      {/* Checkboxes to select fulfillment methods */}
      <FulfillmentCard
        label="Fulfillment methods for which this fee is applied:"
        name="fulfillment_methods"
        id={id + "-fulfillment-method-input"}
        options={fulfillmentMethodsToArray(removePatronChoice(fulfillmentMethods))}
        required={true}
        onChange={handleFulfillmentChange}
        rules={rules}
      >
        {/* Button to toggle all fulfillment methods */}
        <ToggleButton onClick={handleToggleFulfillments}>{fulfillmentToggleText}</ToggleButton>
      </FulfillmentCard>
      {/* Checkboxes to select location codes */}
      <FormattedCheckboxGroupInput
        label="Locations at which this fee is applied:"
        name="locations"
        id={id + "-locations-input"}
        options={locationsToArray(filterLocations(locations, fulfillmentsValue))}
        required={true}
        onChange={handleLocationsChange}
      >
        {/* Button to toggle all locations */}
        <ToggleButton
          onClick={handleToggleLocations}
          hidden={filterLocations(locations, fulfillmentsValue)?.length < 2}
        >
          {locationsToggleText}
        </ToggleButton>
      </FormattedCheckboxGroupInput>
    </div>
  );
};

export default FulfillmentAndLocationsInput;

const ToggleButton = styled(Button)`
  margin-bottom: 0.5rem;
`;

const FulfillmentCard = styled(FormattedCheckboxGroupInput)`
  margin-bottom: 0.5rem;
`;

FulfillmentAndLocationsInput.propTypes = {
  id: PropTypes.string.isRequired,
  fulfillmentMethods: PropTypes.object.isRequired, // res.data.fulfillment_methods from api/getOwnerLocations
  locations: PropTypes.array.isRequired, // res.data.locations from api/getOwnerLocations
  form: PropTypes.object.isRequired, // Antd form object
  initialFulfillments: PropTypes.array, // Filter to an initial fulfillment methods selection. Array of strings
  onChange: PropTypes.func,
};
