import axios from "axios";
import { generalErrorAlert } from "./Utils";

/***
 *
 * @param selectedCustomer
 * @returns {Promise<void>}
 */
export const getSupportAlerts = async (selectedCustomer) => {
  if (!selectedCustomer) return;
  try {
    const res = await axios.get("api/getSupportStaffAlerts", {
      params: { customer_id: selectedCustomer.customer_id },
    });
    return res.data;
  } catch (error) {
    generalErrorAlert(error, "There was an error getting support alerts.", true);
  }
};

/**
 *
 * @param selectedCustomer
 * @returns {Promise<void>}
 */
export const getOwnerConfigs = async (selectedCustomer) => {
  if (!selectedCustomer) return;
  try {
    const res = await axios.get("api/ownerconfigs", {
      params: { customer_id: selectedCustomer.customer_id },
    });
    return res.data.configs;
  } catch (error) {
    generalErrorAlert(error, "There was an error getting support alerts.", true);
  }
};

/***
 *
 * @param selectedCustomer
 * @returns {Promise<void>}
 */
export const getEmployees = async (selectedCustomer) => {
  if (!selectedCustomer) return;
  try {
    const res = await axios.get("api/allbartenders", {
      params: { format: "json", owner_id: selectedCustomer.customer_id },
    });
    return res.data;
  } catch (error) {
    generalErrorAlert(error, "There was an error getting support alerts.", true);
  }
};

export const saveSupportAlerts = async (selectedCustomer, properties) => {
  if (!selectedCustomer) return;
  try {
    await axios.post("api/manageSupportStaffAlerts", { ...properties });
  } catch (error) {
    generalErrorAlert(error, "There was an error saving this support alert.", true);
  }
};

export const deleteSupportAlert = async (staffId) => {
  try {
    await axios.post("api/deleteSupportStaff", { staffId: staffId });
  } catch (error) {
    generalErrorAlert(error, "There was an error deleting this support alert.", true);
  }
};

export const saveFraudContact = async (selectedCustomer, properties) => {
  if (!selectedCustomer) return;
  try {
    await axios.post("owner/addFraudContact", {
      customerId: selectedCustomer.customer_id,
      newFraudContact: { ...properties },
    });
  } catch (error) {
    generalErrorAlert(error, "There was an error adding this fraud contact.", true);
  }
};

export const editFraudContact = async (contactId, deleteContact, contactChanges) => {
  try {
    await axios.post("owner/editFraudContacts", {
      contactsToEdit: [contactId],
      deleteContacts: deleteContact,
      fraudContactEdits: deleteContact ? {} : contactChanges,
    });
  } catch (error) {
    generalErrorAlert(error, "There was an error editing this fraud contact.", true);
  }
};
