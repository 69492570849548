import { Form as Btpform, FormGroup } from "react-bootstrap";
import { TextInput } from "components/global/form";
import OrderFilterInputs from "components/global/form/django-model-form-components/OrderFilterInputs";
import React from "react";

class CreateEditOrderGroupingRulesPage1 extends React.Component {
  render() {
    const {
      description,
      getNecessaryVendors,
      selectedVendors,
      setFieldValue,
      allFulfillmentMethods,
      allLocations,
      allVendors,
      nameForOwner,
      selectedLocations,
      specificLocations,
      selectedFulfillmentMethods,
      justSpecificDates,
      startDate,
      endDate,
      selectDaysAndTimes,
      mondaySchedule,
      tuesdaySchedule,
      wednesdaySchedule,
      thursdaySchedule,
      fridaySchedule,
      saturdaySchedule,
      sundaySchedule,
      disconnectVendorWarning,
      selectedLocationsKey,
      selectedVendorsKey,
    } = this.props;

    return (
      <FormGroup>
        <Btpform.Row className="margin-bottom-2">
          <TextInput
            md={12}
            label={"Title of Rule"}
            value={nameForOwner}
            id="nameForOwner"
            name="nameForOwner"
            type="text"
            placeholder="Name of Printer Rule"
          />
        </Btpform.Row>
        <Btpform.Row className="margin-bottom-2">
          <TextInput
            md={12}
            label={"Rule Description"}
            value={description}
            id="description"
            name="description"
            type="text"
            placeholder="Description of what this rule does"
          />
        </Btpform.Row>
        <OrderFilterInputs
          allFulfillmentMethods={allFulfillmentMethods}
          allVendors={allVendors}
          allLocations={allLocations}
          setFieldValue={setFieldValue}
          getNecessaryVendors={getNecessaryVendors}
          selectedVendors={selectedVendors}
          selectedLocations={selectedLocations}
          specificLocations={specificLocations}
          selectedFulfillmentMethods={selectedFulfillmentMethods}
          justSpecificDates={justSpecificDates}
          startDate={startDate}
          endDate={endDate}
          selectDaysAndTimes={selectDaysAndTimes}
          mondaySchedule={mondaySchedule}
          tuesdaySchedule={tuesdaySchedule}
          wednesdaySchedule={wednesdaySchedule}
          thursdaySchedule={thursdaySchedule}
          fridaySchedule={fridaySchedule}
          saturdaySchedule={saturdaySchedule}
          sundaySchedule={sundaySchedule}
          disconnectVendorWarning={disconnectVendorWarning}
          selectedVendorsKey={selectedVendorsKey}
          selectedLocationsKey={selectedLocationsKey}
        />
      </FormGroup>
    );
  }
}

export default CreateEditOrderGroupingRulesPage1;
