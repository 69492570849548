import React, { useState } from "react";

const DismissibleButton = (props) => {
  const { buttonText, onClickCallback, onCancelCallback, dismissedInitially, id } = props;
  const [dismissed, setDismissed] = useState(false);

  const onClick = () => {
    setDismissed(true);
    if (onClickCallback) onClickCallback();
  };

  const onCancel = () => {
    setDismissed(false);
    if (onCancelCallback) onCancelCallback();
  };

  return dismissed || dismissedInitially ? (
    <span>
      {props.children}
      {!dismissedInitially && !!onCancelCallback && (
        <span className={"btn-link cursor-pointer margin-x-2"} onClick={onCancel} id={id + "-cancel"}>
          Cancel Changes
        </span>
      )}
    </span>
  ) : (
    <span className={"btn-link cursor-pointer"} onClick={onClick} id={id}>
      {buttonText}
    </span>
  );
};

export default DismissibleButton;
