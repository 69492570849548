import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TextInput } from "../../../../../src/components/global/form/index";

const TemplateStringInputField = ({ label, name, id, overlayId, subText, ...props }) => {
  return (
    <>
      <TextInput
        name={name}
        id={id}
        label={[
          label,
          "  ",
          <OverlayTrigger
            key={"placement"}
            placement={"right"}
            overlay={
              <Tooltip id={overlayId}>
                <strong>
                  If you include a keyword below, it will be automatically replaced with the relevant info.
                </strong>
                <br />
                $ORDER_NUMBER - Order number
                <br />
                $LOCATION - Location's name
                <br />
                $PATRON_NAME - Patron's name
                <br />
                $ROOM - Room number
                <br />
                $PATRON_PHONE - Patron's phone number
                <br />
                $PATRON_EMAIL - Patron's email address
                <br />
              </Tooltip>
            }
          >
            <div className="zmdi zmdi-info-outline dashboard-link-icon" />
          </OverlayTrigger>,
        ]}
      />
      <div className={"margin-bottom-2 margin-left-2 margin-right-2"}>
        <small>{subText}</small>
      </div>
    </>
  );
};

export default TemplateStringInputField;
