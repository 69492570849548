import React, { useEffect, useState } from "react";
import Button from "@doordash/component-button";
import { Icon, StackChildren, Text } from "@doordash/design-language";
import { getWidgetData, updateWidgetData } from "components/customer-journey/widgets/Widget";
import { generalErrorAlert, generateUUIDv4 } from "util/Utils";
import Loading from "@doordash/component-loading";
import AddedMenuPreview from "../AddedMenuPreview";
import AddMenusForm from "../forms/AddMenusForm";
import { FsFileMetadata } from "components/owner-app/file-upload";
import { UpdateTaskStatus } from "./OrderingPageWidget";
import { AxiosError } from "axios";

export type MenuData = {
  menu_name: string;
  hours: string;
  menu_url: string;
  menu_uploads: FsFileMetadata[];
};

export type MenuFormField = {
  value: string;
  error: string;
  isRequired: boolean;
};

export type MenuForm = {
  menuName: MenuFormField;
  menuHours: MenuFormField;
  menuLink: MenuFormField;
};

export type Widget = {
  description: string;
  display_position: number;
  id: string;
  status: string;
  task_id: string;
  title: string;
  widget_type: string;
};

type UploadMenuAssetWidgetProps = {
  widget: Widget;
  updateTaskStatus: UpdateTaskStatus;
  userInfo: any;
};

const UploadMenuAssetWidget: React.FC<UploadMenuAssetWidgetProps> = ({ widget, updateTaskStatus, userInfo }) => {
  const [addedMenus, setAddedMenus] = useState<MenuData[]>([]);
  const [showAddMenuContent, setShowAddMenuContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // When no menus are loaded in, show the add menu form/content
    setShowAddMenuContent(!addedMenus.length);
  }, [addedMenus]);

  useEffect(() => {
    if (widget) {
      setIsLoading(true);
      getWidgetData(widget.id)
        .then((res: any) => {
          if (res.data.menus) {
            setAddedMenus(res.data.menus);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [widget]);

  const updateWidgetMenuData = async (updatedMenus: MenuData[], onFinally: () => void) => {
    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          menus: updatedMenus,
        },
      });
      setAddedMenus(updatedMenus);
      updateTaskStatus("completed", { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not save menu data. Please let us know if this keeps happening.",
        true
      );
    } finally {
      onFinally();
    }
  };

  const onSaveEditMenu = async (
    menuIndex: number,
    menuFormValues: MenuForm,
    uploadedMenuFiles: FsFileMetadata[],
    onFinally: () => void
  ) => {
    const updatedMenus = addedMenus.map((menu, index) =>
      index === menuIndex
        ? {
            menu_name: menuFormValues.menuName.value,
            menu_url: menuFormValues.menuLink.value,
            hours: menuFormValues.menuHours.value,
            menu_uploads: uploadedMenuFiles,
          }
        : menu
    );

    updateWidgetMenuData(updatedMenus, onFinally);
  };

  const onSaveNewMenu = async (
    menuFormValues: MenuForm,
    uploadedMenuFiles: FsFileMetadata[],
    onFinally: () => void
  ) => {
    const updatedMenus = [
      ...addedMenus,
      {
        menu_name: menuFormValues.menuName.value,
        menu_url: menuFormValues.menuLink.value,
        hours: menuFormValues.menuHours.value,
        menu_uploads: uploadedMenuFiles,
      },
    ];

    updateWidgetMenuData(updatedMenus, onFinally);
  };

  const onRemoveMenu = async (menuIndex: number, onFinallyCallback: () => void) => {
    const updatedMenus = addedMenus.filter((menu, index) => menuIndex !== index);

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          menus: updatedMenus,
        },
      });
      setAddedMenus(updatedMenus);
      if (!updatedMenus.length) {
        updateTaskStatus("in_progress", { widgetId: widget.id });
      }
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not save menu data. Please let us know if this keeps happening.",
        true
      );
    } finally {
      onFinallyCallback();
    }
  };

  const addMenuContent = showAddMenuContent ? (
    <AddMenusForm
      onSave={onSaveNewMenu}
      onCancel={() => setShowAddMenuContent(false)}
      showCancel={!!addedMenus.length}
    />
  ) : (
    <Button isInline onClick={() => setShowAddMenuContent(true)} leadingIcon={Icon.Types.Add}>
      Add Menu
    </Button>
  );

  const addedMenusContent = (
    <StackChildren>
      <Text>Added Menus</Text>
      {addedMenus.map((menu, index) => (
        <AddedMenuPreview
          key={generateUUIDv4()}
          menu={menu}
          menuIndex={index}
          onRemoveMenu={onRemoveMenu}
          onSaveMenu={(menuFormValues, uploadedFiles, onFinally) =>
            onSaveEditMenu(index, menuFormValues, uploadedFiles, onFinally)
          }
          userInfo={userInfo}
        />
      ))}
    </StackChildren>
  );

  if (isLoading)
    return (
      <div className="margin-y-6">
        <Loading />
      </div>
    );

  return (
    <div>
      {!!addedMenus.length && addedMenusContent}

      {addMenuContent}
    </div>
  );
};

export default UploadMenuAssetWidget;
