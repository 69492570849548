import React from "react";
import styled from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const TableEditDeleteIcons = ({ onEdit, onDelete }) => {
  return (
    <TableActions>
      {onEdit && <EditIcon onClick={() => onEdit()} />}
      {onDelete && <DeleteIcon onClick={() => onDelete()} />}
    </TableActions>
  );
};

const DeleteIcon = styled(DeleteOutlined)`
  font-size: 20px;
  margin-left: 25%;
`;

const EditIcon = styled(EditOutlined)`
  font-size: 20px;
`;

const TableActions = styled.div`
  display: flex;
  justify-content: center;
`;

export default TableEditDeleteIcons;
