import React from "react";
import { Col, Modal, Row, Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./FulfillmentNoticeCheckerModal.scss";

class FulfillmentNoticeCheckerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedOptions: false,
      selectedLocation: null,
      selectedStation: null,
      selectedFulfillmentMethod: null,
      selectedStatus: null,
      foundText: null,
      foundEmail: null,
      foundFulfillmentNotice: null,
    };
  }

  // componentDidUpdate = (prevState, prevProps) => [
  //   if(this.props.)
  // ]

  searchForFN = () => {
    const { selectedLocation, selectedStation, selectedFulfillmentMethod, selectedStatus } = this.state;
    const { fulfillmentNotices } = this.props;

    if ((!selectedStation && !selectedLocation) || !selectedFulfillmentMethod || !selectedStatus) {
      this.setState({ foundText: null, foundEmail: null });
      return;
    }

    const fulfillmentNotice = fulfillmentNotices.find((fn) => {
      if (selectedLocation) {
        return fn.locations.some((location) => location.locationId === selectedLocation.locationId);
      }

      if (selectedStation) {
        return fn.stations.some((station) => station.id === selectedStation.id);
      }

      return null;
    });

    const text = fulfillmentNotice?.texts_to_patron.find(
      (text) => text[0] === selectedFulfillmentMethod && text[1] === selectedStatus
    );

    const email = fulfillmentNotice?.emails_to_patron.find(
      (email) => email[0] === selectedFulfillmentMethod && email[1] === selectedStatus
    );

    this.setState({
      foundText: text ? text[2] : "There is currently no text set.",
      foundEmail: email
        ? email[2]
        : {
            subject: "There is currently no email set.",
            body: "There is currently no email set",
          },
      foundFulfillmentNotice: fulfillmentNotice,
    });
  };

  render() {
    const {
      selectedLocation,
      selectedStation,
      selectedFulfillmentMethod,
      selectedStatus,
      foundText,
      foundEmail,
      foundFulfillmentNotice,
    } = this.state;
    const { show, onHide, stations, fulfillmentMethods, locations, customerStationsFulfillmentMethods } = this.props;

    return (
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          this.setState({
            selectedLocation: null,
            selectedStation: null,
            selectedFulfillmentMethod: null,
            selectedStatus: null,
          });
          onHide();
        }}
        dialogClassName={"create-new-fulfillment-notice-modal"}
      >
        <Modal.Header className={"padding-top-3"} closeButton>
          <Col sm={12}>
            <h3 className="color-grey-2">Check notification settings for a specific configuration</h3>
          </Col>
        </Modal.Header>
        <Modal.Body className={"padding-y-3"}>
          <Col sm={12}>
            <Row>
              <Col sm={12} className="margin-bottom-3">
                <div className="field-wrapper">
                  <p>Look up texts/emails based on station/location, fulfillment method, and status.</p>
                </div>
              </Col>
              <Col sm={12} className={"margin-bottom-3"}>
                <div className={"margin-bottom-2"}>
                  <div>
                    <p>1. Choose which location or station you want to check</p>
                  </div>
                  <DropdownButton
                    as={ButtonGroup}
                    id={"locations"}
                    title={selectedLocation ? selectedLocation.locationName : "Location"}
                    variant={"outline-primary"}
                  >
                    {locations.map((location, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedLocation: location,
                            selectedStation: null,
                          })
                        }
                      >
                        {location.locationName}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <span className={"margin-x-2"}>or</span>
                  <DropdownButton
                    as={ButtonGroup}
                    id={"stations"}
                    title={selectedStation ? selectedStation.station_name : "Station"}
                    variant={"outline-primary"}
                  >
                    {stations.map((station, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedLocation: null,
                            selectedStation: station,
                          })
                        }
                      >
                        {station.station_name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div className={"margin-bottom-2"}>
                  <div>
                    <p>2. Choose the fulfillment method you want to check</p>
                  </div>
                  <DropdownButton
                    as={ButtonGroup}
                    id={"fulfillmentMethod"}
                    title={selectedFulfillmentMethod ? selectedFulfillmentMethod : "Fulfillment method"}
                    variant={"outline-primary"}
                  >
                    {customerStationsFulfillmentMethods.map((fulfillmentMethod, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedFulfillmentMethod: fulfillmentMethod,
                            selectedStatus: null,
                          })
                        }
                      >
                        {fulfillmentMethod}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div className={"margin-bottom-2"}>
                  <div>
                    <p>3. Choose the status you want to check</p>
                  </div>
                  <DropdownButton
                    as={ButtonGroup}
                    id={"status"}
                    title={selectedStatus ? selectedStatus : "Status"}
                    variant={"outline-primary"}
                  >
                    {fulfillmentMethods[this.state.selectedFulfillmentMethod]?.map((status, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedStatus: status,
                          })
                        }
                      >
                        {status}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div>
                  <div>
                    <p>4. Hit Search to see what texts/emails are getting sent!</p>
                  </div>
                  <Button as={ButtonGroup} onClick={this.searchForFN}>
                    Search
                  </Button>
                </div>
              </Col>

              <Col sm={12}>
                <div className={"notification-container margin-bottom-2"}>
                  <div>Current Text:</div>
                  <div>{foundText}</div>
                </div>
                <div className={"notification-container"}>
                  Current Email
                  <div>Subject: {foundEmail?.subject}</div>
                  <div>Body: {foundEmail?.body}</div>
                </div>
                <div>
                  {foundFulfillmentNotice && (
                    <div>
                      <p>
                        Notifications set by:{" "}
                        <Link to={"/fulfillment-notices/" + foundFulfillmentNotice.id}>
                          {foundFulfillmentNotice.name}
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-content">
            <div></div>
            <div className="actions">
              <Button variant="outline-primary" onClick={onHide}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FulfillmentNoticeCheckerModal;
