import { track } from "instrumentation/tracking/track";

export const trackAddEmployee = (fields) => track("track_click_add_employee", fields);

export const trackRemoveSingleEmployee = (fields) => track("track_click_remove_single_employee", fields);

export const trackBulkRemoveEmployees = (fields) => track("track_click_bulk_remove_employees", fields);

export const trackEditEmployee = (fields) => track("track_click_edit_employee", fields);

export const trackSaveEditEmployee = (fields) => track("track_click_save_edit_employee", fields);

export const trackClickResetEmployeePassword = (fields) => track("track_click_reset_employee_password", fields);
