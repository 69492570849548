import React from "react";
import { Spinner } from "react-bootstrap";

const CenteredLoadingSpinner = (props) => {
  const { label } = props;

  return (
    <div className={"center margin-y-2"}>
      <Spinner animation="border" />
      <div className={"supporting-text"}>{label}</div>
    </div>
  );
};
export default CenteredLoadingSpinner;
