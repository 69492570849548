import React, { useState } from "react";
import styled from "styled-components";
import Tag from "@doordash/component-tag";
import { Colors, StackChildren, InlineChildren, Text, BorderRadius, Spacing, Icon } from "@doordash/design-language";
import Button from "@doordash/component-button";
import { capitalizeString, getCreditCardIconType } from "util/Utils";

type Card = {
  brand: string;
  last4: string;
  checks: {
    cvc_check: string;
    address_line1_check: null;
    address_postal_code_check: null;
  };
  wallet: null;
  country: string;
  funding: string;
  exp_year: number;
  networks: {
    available: string[];
    preferred: null;
  };
  exp_month: number;
  fingerprint: string;
  generated_from: null;
  three_d_secure_usage: {
    supported: true;
  };
};

type BillingMethodProps = {
  isPrimary: boolean;
  paymentMethodName: string;
  isVerified: boolean;
  card?: Card;
  removePaymentMethod: () => void;
  selectPaymentMethodAsPrimary: () => void;
  onClickVerify: () => void;
};

const BillingMethod: React.FC<BillingMethodProps> = ({
  isPrimary,
  paymentMethodName,
  isVerified,
  card,
  removePaymentMethod,
  selectPaymentMethodAsPrimary,
  onClickVerify,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [isSettingPaymentMethodPrimary, setIsSettingPaymentMethodPrimary] = useState(false);

  const removePayment = async () => {
    setIsRemoving(true);
    await removePaymentMethod();
    setIsRemoving(false);
  };

  const selectPaymentMethod = async () => {
    setIsSettingPaymentMethodPrimary(true);
    await selectPaymentMethodAsPrimary();
    setIsSettingPaymentMethodPrimary(false);
  };

  return (
    <BillingMethodContainer>
      <StackChildren size={StackChildren.Sizes.Large}>
        {isPrimary && <Tag text="Default" size={Tag.Sizes.Medium} />}

        {!!card ? (
          <StackChildren size={StackChildren.Sizes.XxxSmall}>
            <InlineChildren size={InlineChildren.Sizes.XxSmall}>
              <Icon type={getCreditCardIconType(card.brand)} useDefaultColors />
              <Text styles={Text.Styles.Body1Emphasis}>{`${capitalizeString(card.brand)} ****${card.last4}`}</Text>
            </InlineChildren>
            <Text
              styles={Text.Styles.Body2}
              color={Colors.TextSecondary}
            >{`Expires ${card.exp_month}/${card.exp_year}`}</Text>
          </StackChildren>
        ) : (
          <>
            <InlineChildren>
              <Text styles={Text.Styles.Body1Emphasis}>{paymentMethodName}</Text>
              {!isVerified && <Tag text="Unverified" size={Tag.Sizes.Medium} type={Tag.Types.WarningEmphasis} />}
            </InlineChildren>

            {!isVerified && (
              <Text styles={Text.Styles.Body2}>
                Manually connected bank accounts require additional verification via micro-deposits. After you’ve saved
                bank account details here, two small deposits will be sent to your account. These will take 1-2 business
                days to appear on your statement. You will need to return to this task and manually enter the amounts to
                finalize the verification process.
              </Text>
            )}
          </>
        )}

        {!isPrimary && (
          <InlineChildren>
            {isVerified && (
              <Button
                isInline
                type={Button.Types.Tertiary}
                onClick={selectPaymentMethod}
                state={isSettingPaymentMethodPrimary ? Button.States.Loading : Button.States.Default}
              >
                Make Default
              </Button>
            )}

            {!isVerified && (
              <Button isInline type={Button.Types.Tertiary} onClick={onClickVerify}>
                Verify
              </Button>
            )}

            <Button
              isInline
              type={Button.Types.Tertiary}
              onClick={removePayment}
              state={isRemoving ? Button.States.Loading : Button.States.Default}
            >
              Remove
            </Button>
          </InlineChildren>
        )}
      </StackChildren>
    </BillingMethodContainer>
  );
};

export default BillingMethod;

const BillingMethodContainer = styled.div`
  width: 100%;
  border: 1px solid ${Colors.BorderPrimary};
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Medium}px;
`;
