import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Colors, Icon, InlineChildren, StackChildren, Text } from "@doordash/design-language";
import { IconButton } from "@doordash/component-button";
import styled from "styled-components";

import FileIcons from "./FileIcons";
import { bytesToReadable, getFileMetadata } from "./helpers";
import { FileState, FsFile, FilestackClient, FsFileMetadata } from "./types";

const DEFAULT_PROGRESS_INTERVAL = 500;

const DEFAULT_PROGRESS_CONTROLLER = {
  pause: () => {},
  resume: () => {},
  cancel: () => {},
};

type InProgressFileProps = {
  id: number;
  file: File;
  client: FilestackClient;
  validateFile: (file: File) => string | Promise<string>;
  saveCompletedFile: (file: FsFileMetadata) => void;
  removeInProgressFile: (id: number) => void;
};

const InProgressFile: React.FC<InProgressFileProps> = ({
  id,
  file,
  client,
  validateFile,
  saveCompletedFile,
  removeInProgressFile,
}) => {
  const [uploadStatus, setUploadStatus] = useState<FileState>(FileState.INIT);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const progressController = useRef({ ...DEFAULT_PROGRESS_CONTROLLER });
  const hasCompletedInitialUpload = useRef(false);

  const handleUpload = useCallback(async () => {
    if (uploadStatus === FileState.STORED) {
      return;
    }

    const error = await validateFile(file);

    if (!!error) {
      setErrorMessage(error);
      setProgress(100);
      setUploadStatus(FileState.INVALID);
      return;
    }

    progressController.current.cancel();
    setUploadStatus(FileState.INIT);

    try {
      const result = (await client.upload(
        file,
        {
          onProgress: (evt) => {
            setUploadStatus(FileState.PROGRESS);
            setProgress(evt.totalPercent);
          },
          progressInterval: DEFAULT_PROGRESS_INTERVAL,
        },
        undefined,
        progressController.current
      )) as FsFile;

      if (result.status !== FileState.STORED) {
        setUploadStatus(result.status);
        return;
      }

      const uploaded = getFileMetadata(result, file);

      setUploadStatus(FileState.STORED);
      setTimeout(() => {
        removeInProgressFile(id);
        saveCompletedFile(uploaded);
      }, 500);
    } catch (error) {
      setUploadStatus(FileState.FAILED);
    }
  }, [id, uploadStatus, file, client, validateFile, removeInProgressFile, saveCompletedFile]);

  useLayoutEffect(() => {
    if (!hasCompletedInitialUpload.current) {
      handleUpload();
      hasCompletedInitialUpload.current = true;
    }

    return () => {
      progressController.current.cancel(); // eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = useCallback(() => {
    removeInProgressFile(id);
    progressController.current.cancel();
  }, [id, removeInProgressFile]);

  return (
    <FileUploadTile
      progress={progress}
      uploadStatus={uploadStatus}
      error={errorMessage}
      file={file}
      handleRetry={handleUpload}
      handleRemove={handleCancel}
    />
  );
};

type FileUploadTileProps = {
  progress: number;
  uploadStatus: FileState;
  error?: string;
  file: File | FsFileMetadata;
  handleRetry: () => void;
  handleRemove: () => void;
};

export const FileUploadTile: React.FC<FileUploadTileProps> = ({
  progress,
  uploadStatus,
  error = "",
  file,
  handleRetry,
  handleRemove,
}) => {
  // support the old version of the picker that stored name and type in an "originalFile" object
  const [name, type] = file instanceof File ? [file.name, file.type] : [file.filename, file.mimetype];
  return (
    <InlineChildren
      alignItems={InlineChildren.Alignment.Center}
      justifyContent={InlineChildren.Justification.SpaceAround}
      size={InlineChildren.Sizes.XxxSmall}
    >
      <FileThumbnail type={type} />
      <WideStackChildren size={StackChildren.Sizes.XxxSmall}>
        <Text styles={Text.Styles.Label1Emphasis}>{name}</Text>
        <InlineChildren
          alignItems={InlineChildren.Alignment.Center}
          justifyContent={InlineChildren.Justification.SpaceBetween}
        >
          <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextTertiary}>
            {bytesToReadable(file.size)}
          </Text>
          <UploadStatus status={uploadStatus} progress={progress} error={error} />
        </InlineChildren>
        <ProgressBarOuter>
          <ProgressBarInner size={progress} status={uploadStatus} />
        </ProgressBarOuter>
      </WideStackChildren>
      {uploadStatus === FileState.FAILED ? (
        <IconButton
          accessibilityLabel={`Retry uploading ${file.name}`}
          isInline
          iconType={IconButton.IconTypes.Rotate}
          onClick={handleRetry}
        />
      ) : (
        <IconButton
          accessibilityLabel={
            uploadStatus === FileState.STORED ? `Clear status for ${file.name}` : `Cancel uploading ${file.name}`
          }
          isInline
          iconType={IconButton.IconTypes.CloseCircleLine}
          onClick={handleRemove}
        />
      )}
    </InlineChildren>
  );
};

const WideStackChildren = styled(StackChildren)`
  flex-grow: 1;
`;

const FileTypeSrcMap = {
  csv: FileIcons.Csv,
  doc: FileIcons.Doc,
  docx: FileIcons.Doc,
  jpg: FileIcons.Jpg,
  jpeg: FileIcons.Jpg,
  pdf: FileIcons.Pdf,
  png: FileIcons.Png,
  xls: FileIcons.Xls,
  xlsx: FileIcons.Xls,
  default: FileIcons.Blank,
};

export const FileThumbnail: React.FC<{ type: string }> = ({ type }) => {
  const cleaned = cleanFileType(type);
  const src =
    cleaned in FileTypeSrcMap ? FileTypeSrcMap[cleaned as keyof typeof FileTypeSrcMap] : FileTypeSrcMap.default;

  return <ThumbnailImage src={src} width={40} height={40} alt={`${cleaned} thumbnail`} />;
};

type ThumbnailImageProps = {
  width: number;
  height: number;
};

const ThumbnailImage = styled.img`
  width: ${({ width }: ThumbnailImageProps) => width}px;
  height: ${({ height }) => height}px;
`;

const cleanFileType = (type: string) => {
  if (!type) return "";

  const [part1, part2] = type.split("/");
  return (part2 ?? part1 ?? "").toLowerCase().replace(/[^a-z]/g, "");
};

type UploadStatusProps = {
  status: FileState;
  progress: number;
  error?: string;
};

const UploadStatus: React.FC<UploadStatusProps> = ({ status, progress, error }) => {
  switch (status) {
    case FileState.PROGRESS:
      return (
        <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextTertiary}>
          {progress}%
        </Text>
      );
    case FileState.STORED:
      return (
        <InlineChildren alignItems={InlineChildren.Alignment.Center} size={InlineChildren.Sizes.XxxSmall}>
          <Icon
            type={Icon.Types.CheckCircleLine}
            size={Icon.Sizes.Small}
            color={Text.Colors.TextTertiary}
            shouldAdjustSmallSizesWhitespace
          />
          <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextTertiary}>
            Uploaded
          </Text>
        </InlineChildren>
      );
    case FileState.INVALID:
    case FileState.FAILED:
      return (
        <InlineChildren alignItems={InlineChildren.Alignment.Center} size={InlineChildren.Sizes.XxxSmall}>
          <Icon
            type={Icon.Types.ErrorFill}
            size={Icon.Sizes.Small}
            color={Text.Colors.TextError}
            shouldAdjustSmallSizesWhitespace
          />
          <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextError}>
            {error || "Upload Failed"}
          </Text>
        </InlineChildren>
      );
    case FileState.INIT:
    default:
      return null;
  }
};

const FileStateColorMap = {
  [FileState.PROGRESS]: Colors.SystemBlue60,
  [FileState.STORED]: Colors.SystemGreen50,
  [FileState.INVALID]: Colors.TextError,
  [FileState.FAILED]: Colors.TextError,
  [FileState.INIT]: Colors.SystemBlack,
  [FileState.INTRANSIT]: Colors.SystemBlue60,
};

type ProgressBarInnerProps = {
  size: number;
  status: FileState;
};

const ProgressBarInner = styled.div`
  width: ${({ size }: ProgressBarInnerProps) => size}%;
  height: 100%;
  background-color: ${({ status }) => FileStateColorMap[status]};
  transition: width 0.2s linear;
`;

const ProgressBarOuter = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
`;

export default InProgressFile;
