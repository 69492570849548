import React from "react";
import axios from "axios";

import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";

class GoogleIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleTagManagerSettings: {},
      loadingData: false,
      hasLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getGoogleTagManagerConnectionStatus();
    }
  }

  getGoogleTagManagerConnectionStatus = async () => {
    const { selectedCustomer } = this.props;
    this.setState({ loadingData: true });
    try {
      const res = await axios.get(
        "/admin/getGoogleTagManagerConnectionStatus?customer_id=" + selectedCustomer?.customer_id
      );
      this.setState({
        googleTagManagerSettings: res.data,
        hasLoaded: true,
      });
    } catch (error) {
      generalErrorAlert(error, "Trouble retrieving Google Tag Manager settings.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  connectGoogleTagManager = async (values) => {
    const { selectedCustomer, onClose, onSave } = this.props;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      ...values,
    };

    try {
      await axios.post("/admin/connectGoogleTagManager", payload);
      toast.success("Successfully connected Google Tag Manager.");
      this.setState({
        googleTagManagerSettings: values,
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to Google Tag Manager.");
    }
  };

  disconnectGoogleTagManager = async () => {
    const { selectedCustomer, onClose, onSave } = this.props;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
    };

    try {
      await axios.post("/admin/disconnectGoogleTagManager", payload);
      toast.success("Successfully disconnected from Google Tag Manager.");
      this.setState({
        googleTagManagerSettings: {},
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble disconnecting from Google Tag Manager.");
    }
  };

  render() {
    const { show, onClose, disconnectMode } = this.props;
    const { googleTagManagerSettings, loadingData } = this.state;
    if (disconnectMode) {
      return (
        <Modal
          show={show}
          onHide={() => {
            onClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect Google Tag Manager</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"margin-bottom-2"}>
              <p>To disconnect Google Tag Manager, please press the "Disconnect" button below.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"light"}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button size={"sm"} variant={"danger"} onClick={this.disconnectGoogleTagManager}>
              Disconnect
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: googleTagManagerSettings.id || "",
          }}
          validationSchema={Yup.object().shape({
            id: Yup.string()
              .required("Container ID is required.")
              .matches(/^(GTM-)[a-zA-Z0-9]{7}$/, 'Container ID must begin with "GTM-" followed by 7 characters.'),
          })}
          onSubmit={this.connectGoogleTagManager}
        >
          {({ values, errors, touched, isValidating, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Add Google Tag Manager Tag</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {loadingData ? (
                  <CenteredLoadingSpinner label={"Loading Google Tag Manager Integration data"} />
                ) : (
                  <div className={"margin-bottom-2"}>
                    <TextInput label={"Google Tag Manager"} name={"id"} id={"tag-id"} placeholder={"GTM-#######"} />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size={"sm"} type={"submit"} disabled={loadingData || Object.keys(errors).length !== 0}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default GoogleIntegrationModal;
