import React, { useEffect, useState } from "react";
import "styles/customer-journey/widget.scss";
import axios from "axios";
import { BBOT_DJANGO_SERVER } from "Config";
import { BorderRadius, Colors, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
import { generalErrorAlert } from "util/Utils";
import { CopyToClipboard } from "components/global/form";
import styled from "styled-components";

const InviteUsersWidget = (props) => {
  const [magicLink, setMagicLink] = useState();

  const { journey } = props;

  useEffect(() => {
    const getInvitationSecret = async () => {
      if (!journey?.id) return;

      try {
        const res = await axios.get(BBOT_DJANGO_SERVER + "/api/journey/invite", {
          params: {
            journey_id: journey.id,
          },
        });
        const secret = res.data.secret;
        const invitationId = res.data.invite_id;
        const magicLink = window.location.origin + "/invite?id=" + invitationId + "&s=" + secret;
        setMagicLink(magicLink);
      } catch (error) {
        generalErrorAlert(
          error,
          "Oops, having trouble getting the invite information. If this keeps happening, please let us know."
        );
      }
    };

    getInvitationSecret();
  }, [journey]);

  return (
    <WidgetWrapper>
      <StackChildren size={StackChildren.Sizes.Large}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <InlineChildren alignItems="center">
            <Text tag="h2" styles={Text.Styles.Title1}>
              Invite Users
            </Text>
            <Text styles={Text.Styles.Body1} color={Text.Colors.TextSecondary}>
              Optional
            </Text>
          </InlineChildren>
          <Text styles={Text.Styles.Body2} color={Text.Colors.TextSecondary}>
            Share the link to add members of your staff as account admins. They’ll be able to access all PreBot
            onboarding tasks. They’ll also get admin access to your Bbot account after setup is complete.
          </Text>
        </StackChildren>

        <CopyToClipboard value={magicLink} buttonLabel="Copy invite link to clipboard" />
      </StackChildren>
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  width: 100%;
  padding: ${Spacing.Large}px;
  background-color: ${Colors.BackgroundSecondary};
  border-radius: ${BorderRadius.Medium}px;
`;

export default InviteUsersWidget;
