import React, { useState, useEffect } from "react";
import ConnectPOSIntegrationModal from "components/global/modals/pos-integrations/ConnectPOSIntegrationModal";
import DisconnectPOSIntegrationModal from "components/global/modals/pos-integrations/DisconnectPOSIntegrationModal";
import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "util/pos-integrations/IntegrationConnectModalUtils";
import EditIntegrationSettingsModal from "util/pos-integrations/EditIntegrationSettingsModal";
import DeliverectIntegrationModalInputs from "components/owner-app/modals/app-store/DeliverectIntegrationModalInputs";
import * as Yup from "yup";

const DeliverectIntegrationModal = (props) => {
  const [deliverectSettings, setDeliverectSettings] = useState({});
  const [showConnectWarning, setShowConnectWarning] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [formValues, setFormValues] = useState({});

  const { selectedCustomer, disconnectMode, show, onClose, onSave } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getDeliverectSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDeliverectSettings = async () => {
    const getSettingsUrl = "/admin/getDeliverectConnectionStatus";
    setLoadingData(true);
    await getIntegrationSettings(getSettingsUrl, "Deliverect", selectedCustomer, saveDeliverectSettings);
    setLoadingData(false);
  };

  const saveDeliverectSettings = async (deliverectSettings) => {
    setDeliverectSettings(deliverectSettings);
  };

  const connectDeliverectWithWarning = async (values) => {
    if (deliverectSettings?.integration_type == null && values?.integrationType === "bbotAsOrderingSite") {
      setFormValues(values);
      setShowConnectWarning(true);
    } else {
      await connectDeliverect(values);
    }
  };

  const connectDeliverect = async (values) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      inject_orders: values?.injectOrders ?? true,
      integration_type: values?.integrationType,
      tax_type_to_use: values?.taxTypeToUse,
      import_menu_availability_schedules: values?.importMenuAvailabilitySchedules,
      channel_link_id: values?.channelLinkId,
      auto_accept_orders_in_bbot: values?.autoAcceptOrdersInBbot,
      display_checkout_info_on_ticket: values?.displayCheckoutInfoOnTicket,
      display_location_on_ticket: values?.displayLocationOnTicket,
      allow_order_pausing: values?.allowOrderPausing,
      location_id: values?.locationId,
      sync_fulfillment_status_from_deliverect: values?.syncFulfillmentStatusFromDeliverect,
    };
    await connectIntegration("/admin/connectDeliverect", payload, "Deliverect", setLoadingData, onConnectSuccess);
  };

  const onConnectSuccess = async () => {
    setShowConnectWarning(false);
    await getDeliverectSettings();
    onSave();
    onClose();
  };

  const disconnectDeliverect = async (deleteMenuItems) => {
    await disconnectIntegration(
      "/admin/disconnectDeliverect",
      "Deliverect",
      selectedCustomer,
      deleteMenuItems,
      setLoadingData,
      onDisconnectSuccess
    );
  };

  const onDisconnectSuccess = async () => {
    await saveDeliverectSettings(true);
    setShowConnectWarning(false);
    onClose();
    onSave();
  };

  const disconnectModal = () => {
    return (
      <DisconnectPOSIntegrationModal
        onHide={onClose}
        show={show}
        disconnectFunc={disconnectDeliverect}
        onClose={onClose}
        integrationSourceName={"Deliverect"}
      />
    );
  };

  const submitEditSettings = async (values) => {
    await connectDeliverectWithWarning(values);
    await getDeliverectSettings();
  };

  const closeAndResetModal = () => {
    setShowConnectWarning(false);
    onClose();
  };

  const editSettingsModal = () => {
    let initialValues = {
      integrationType: deliverectSettings?.integration_type ?? "bbotAsPOS",
      injectOrders: deliverectSettings?.inject_orders ?? true,
      taxTypeToUse: deliverectSettings.tax_type_to_use ?? "eatInTax",
      importMenuAvailabilitySchedules: deliverectSettings.import_menu_availability_schedules ?? false,
      channelLinkId: deliverectSettings.channel_link_id ?? "",
      autoAcceptOrdersInBbot: deliverectSettings.auto_accept_orders_in_bbot ?? true,
      displayCheckoutInfoOnTicket: deliverectSettings.display_checkout_info_on_ticket ?? true,
      displayLocationOnTicket: deliverectSettings.display_location_on_ticket ?? true,
      allowOrderPausing: deliverectSettings.allow_order_pausing ?? true,
      locationId: deliverectSettings.location_id ?? "",
      syncFulfillmentStatusFromDeliverect: deliverectSettings.sync_fulfillment_status_from_deliverect ?? true,
    };

    let validationSchema = Yup.object({});

    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={closeAndResetModal}
        onCancel={closeAndResetModal}
        onSubmit={submitEditSettings}
        modalBody={DeliverectIntegrationModalInputs}
        loadingData={loadingData}
        modalTitle={"Deliverect POS Settings"}
        deliverectSettings={deliverectSettings}
        validationSchema={validationSchema}
      />
    );
  };

  const connectModal = () => {
    return (
      <ConnectPOSIntegrationModal
        integrationSourceName={"Deliverect"}
        onHide={() => {
          setShowConnectWarning(false);
          onClose();
        }}
        onSubmit={() => {
          setShowConnectWarning(false);
          connectDeliverect(formValues);
        }}
        show={showConnectWarning && show}
      />
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  if (showConnectWarning) {
    return connectModal();
  }

  return editSettingsModal();
};

export default DeliverectIntegrationModal;
