import React, { useEffect, useState } from "react";
import axios from "axios";
import EditSettingsContainer from "bbot-component-library/EditSettingsContainer";
import {
  BbotButton,
  BbotModal,
  BbotTag,
  Form,
  List,
  Col,
  Row,
  Spin,
  notification,
  TextInput,
  CheckboxInput,
  EmailInput,
  NumberInput,
  SelectInput,
  PhoneInput,
  RadioInput,
  RestaurantAddressInput,
  OPContainer,
  SettingsCard,
} from "bbot-component-library";
import { camelToTitleCase, fixPhoneNumber, generalErrorAlert, isUnique, removeKeys } from "util/Utils";
import styled from "styled-components";

const NAME = "Restaurant Details";
const DESCRIPTION = "Edit your basic venue information like contact, default tax, and timezone";
const URL = "/restaurant-details";
const ID = "restaurant-details";

const RestaurantDetails = (props) => {
  const { selectedCustomer, userInfo } = props;
  const isAdmin = userInfo?.role === "admin";

  // States
  const [, setArbitrary] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [ownerProperties, setOwnerProperties] = useState({});
  const [timezoneChoices, setTimezoneChoices] = useState([]);
  const [currencyChoices, setCurrencyChoices] = useState([]);
  const [localeChoices, setLocaleChoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settingsState, setSettingsState] = useState({});
  const [mailingAddressState, setMailingAddressState] = useState(null);

  // Modal states
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [invalidChanges, setInvalidChanges] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [reactivating, setReactivating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [restaurantDetailsForm] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const [deactivateForm] = Form.useForm();

  // Reset states and get owner properties when customer is changed
  useEffect(() => {
    setInitialValues({});
    setTimezoneChoices([]);
    setCurrencyChoices([]);
    setCurrencyChoices([]);
    getOwnerProperties();
    setSettingsState({});
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set default values when customer settings are fetched
  useEffect(() => {
    const restaurantDetailsInitialValues = {
      adminName: ownerProperties.name_for_admin,
      accountName: ownerProperties.name_for_owner,
      displayName: ownerProperties.customer_name,
      physicalAddress: ownerProperties.physical_address,
      mailingAddress: ownerProperties.mailing_address,
      mailingAddressInitial: ownerProperties.mailing_address
        ? ownerProperties.mailing_address
        : ownerProperties.physical_address,
      phoneNumber: ownerProperties.phone_for_patrons ? fixPhoneNumber(ownerProperties.phone_for_patrons) : "",
      email: ownerProperties.email_for_patrons ? ownerProperties.email_for_patrons : "",
      tax: (ownerProperties.default_tax_fraction * 100).toFixed(3),
      taxInclusivePricing: ownerProperties.tax_inclusive_pricing,
      timezone: ownerProperties.timezone,
      currency: ownerProperties.currency,
      adjustPrices: "keep",
      locale: ownerProperties.locale,
    };
    restaurantDetailsInitialValues.accountNameIsDisplayName =
      restaurantDetailsInitialValues.accountName === restaurantDetailsInitialValues.displayName;
    restaurantDetailsInitialValues.physicalAddressIsMailingAddress = !restaurantDetailsInitialValues.mailingAddress;
    setInitialValues(restaurantDetailsInitialValues);
    restaurantDetailsForm.setFieldsValue(restaurantDetailsInitialValues);
    setTimezoneChoices(
      ownerProperties.tz_choices?.map((tz) => {
        return { label: tz.replace(/_/g, " "), value: tz };
      })
    );
    setCurrencyChoices(
      ownerProperties.currencies?.map((cur) => {
        return { label: cur.displayName, value: cur.code };
      })
    );
    setLocaleChoices(ownerProperties.locales);
    setMailingAddressState(restaurantDetailsInitialValues.mailingAddressInitial);
  }, [ownerProperties]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get customer properties from the server - copied from service charges. I'm sure there's a better way to reference this
  const getOwnerProperties = async () => {
    if (!selectedCustomer) return;
    setIsLoading(true);
    try {
      const res = await axios.get("api/getOwnerProperties", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      setOwnerProperties(res.data.owner_properties);
    } catch (error) {
      generalErrorAlert(error, "There was an error getting the customer settings.", true);
    }
    setIsLoading(false);
  };

  // Small helper to add a new unsaved change
  const handleChanges = (changes) => {
    let fieldsToRemove = [];
    Object.keys(changes).forEach((field) => {
      if (
        (field === "physicalAddress" || field === "mailingAddress") &&
        changes[field]?.google_place?.formatted_address === initialValues[field]?.google_place?.formatted_address &&
        changes[field]?.recipient === initialValues[field]?.recipient &&
        changes[field]?.line2 === initialValues[field]?.line2
      )
        fieldsToRemove.push(field);
      else if (!isUnique(changes[field], initialValues[field])) fieldsToRemove.push(field);
    });
    setSettingsState(
      removeKeys(
        {
          ...settingsState,
          ...changes,
        },
        fieldsToRemove
      )
    );
  };

  // Format changes to display in the confirmation modal
  const formatChanges = (input) => {
    const changes = { ...input };
    if ("physicalAddress" in changes)
      changes.physicalAddress = [
        changes.physicalAddress.recipient,
        changes.physicalAddress.google_place?.formatted_address,
        changes.physicalAddress.line2,
      ]
        .filter(Boolean)
        .join(", ");
    else if ("mailingAddress" in changes)
      changes.mailingAddress = changes.mailingAddress
        ? [
            changes.mailingAddress.recipient,
            changes.mailingAddress.google_place?.formatted_address,
            changes.mailingAddress.line2,
          ]
            .filter(Boolean)
            .join(", ")
        : "Same as physical address";
    if ("tax" in changes) changes.tax = changes.tax + "%";
    if ("taxInclusivePricing" in changes)
      changes.taxInclusivePricing = changes.taxInclusivePricing ? "Display post-tax prices" : "Display pre-tax prices";
    if ("currency" in changes)
      changes.currency = ownerProperties.currencies?.find((cur) => cur.code === changes.currency)?.displayName;
    return Object.entries(changes).map(([key, value], i) => {
      return (
        <List.Item key={i}>
          <Row>
            <Col span={24}>
              <strong>{camelToTitleCase(key)}</strong>
            </Col>
            <Col span={24}>{value}</Col>
          </Row>
        </List.Item>
      );
    });
  };

  // Validate form
  const validateForm = async () => {
    try {
      await restaurantDetailsForm.validateFields();
      setShowSaveChangesModal(true);
    } catch (error) {
      setInvalidChanges(error.errorFields);
    }
  };

  // Save changes
  const saveChanges = async () => {
    const fields = restaurantDetailsForm.getFieldsValue();
    const properties = {
      name_for_admin: fields.adminName,
      name_for_owner: fields.accountName,
      customer_name: fields.accountNameIsDisplayName ? fields.accountName : fields.displayName,
      physical_address: fields.physicalAddress,
      mailing_address: fields.physicalAddressIsMailingAddress ? null : fields.mailingAddress,
      phone_for_patrons: fields.phoneNumber,
      email_for_patrons: fields.email,
      default_tax_fraction: parseFloat((fields.tax / 100).toFixed(5)),
      tax_inclusive_pricing: fields.taxInclusivePricing,
      adjust_prices:
        fields.taxInclusivePricing !== initialValues.taxInclusivePricing && fields.adjustPrices === "adjust",
      timezone: fields.timezone,
      currency: fields.currency,
      locale: fields.locale,
    };
    // Save changes, hide the confirmation modal, reset form fields, reset unsaved changes
    try {
      await axios.post("api/customerProperties", {
        customer_id: selectedCustomer.customer_id,
        properties: properties,
      });
      notification.success({ message: "Successfully saved settings." });
      setShowSaveChangesModal(false);
      restaurantDetailsForm.resetFields();
      setSettingsState({});
      getOwnerProperties();
    } catch (error) {
      generalErrorAlert(error, "Unexpected Server Error. If this keeps happening please contact Bbot support.", true);
    }
  };

  // Delete a customer
  const onDelete = async () => {
    if (deleteForm.getFieldValue("confirmDeleteText")?.toLowerCase() === ownerProperties.name_for_admin.toLowerCase()) {
      setDeleting(true);
      try {
        await axios.post("admin/deleteCustomer", {
          customer_id: selectedCustomer.customer_id,
        });
        notification.success({
          message: "Successfully deleted customer. You will be redirected to choose a customer.",
        });
        setTimeout(() => {
          window.location.reload();
          setDeleting(false);
        }, 2000);
      } catch (error) {
        generalErrorAlert(error, "Unexpected Server Error. If this keeps happening please contact Bbot support.", true);
        setDeleting(false);
      }
    } else notification.error({ message: "Entered text does not match admin name" });
  };

  // Deactivate a customer
  const onDeactivate = async () => {
    if (
      deactivateForm.getFieldValue("confirmDeactivateText")?.toLowerCase() ===
      ownerProperties.name_for_admin.toLowerCase()
    ) {
      setDeactivating(true);
      try {
        await axios.post("admin/deactivateCustomer", {
          customer_id: selectedCustomer.customer_id,
          customerForVerification: ownerProperties.customer_name,
        });
        notification.success({
          message: "Successfully deactivated customer. Please wait for the page to refresh.",
        });
        setTimeout(() => {
          window.location.reload();
          setDeactivating(false);
        }, 2000);
      } catch (error) {
        generalErrorAlert(error, "Unexpected Server Error. If this keeps happening please contact Bbot support.", true);
        setDeactivating(false);
      }
    } else
      generalErrorAlert(
        new Error("Entered text does not match admin name"),
        "Entered text does not match admin name",
        true
      );
  };

  // Reactivate a deactivated customer
  const onReactivate = async () => {
    setReactivating(true);
    try {
      await axios.post("admin/reactivateCustomer", {
        customer_id: selectedCustomer.customer_id,
      });
      notification.success({
        message: "Successfully reactivated customer. Please wait for the page to refresh.",
      });
      setTimeout(() => {
        window.location.reload();
        setReactivating(false);
      }, 2000);
    } catch (error) {
      generalErrorAlert(error, "Unexpected Server Error. If this keeps happening please contact Bbot support.", true);
      setReactivating(false);
    }
  };

  // Display and Contact tab content
  const displayAndContact = (
    <>
      <SettingsCard title="Account Name">
        {isAdmin && (
          <div>
            <TextInput
              id="admin-name-input"
              label={
                <span>
                  Admin Name <BbotTag>Admin Only</BbotTag>
                </span>
              }
              name="adminName"
              onChange={(e) => handleChanges({ adminName: e.target.value })}
            />
            <p>
              <i>
                The venue name as it appears in the Admin Portal and admin searches. Names containing quotes (") are
                invalid and will be rejected.
              </i>
            </p>
          </div>
        )}
        <div>
          <TextInput
            id="account-name-input"
            label="Account Name"
            name="accountName"
            onChange={(e) =>
              handleChanges(
                // If using the same display name update them both
                restaurantDetailsForm.getFieldValue("accountNameIsDisplayName")
                  ? {
                      accountName: e.target.value,
                      displayName: e.target.value,
                    }
                  : { accountName: e.target.value }
              )
            }
          />
          <p>
            <i>
              The venue name as it appears in the Owner Portal. Names containing quotes (") are invalid and will be
              rejected.
            </i>
          </p>
        </div>
        <div>
          <CheckboxInput
            id="account-name-is-display-name"
            name="accountNameIsDisplayName"
            label="Display name is the same as account name"
            onChange={(e) => {
              setArbitrary((arbitrary) => !arbitrary);
              // If enabled, set the display name to account name
              if (e.target.checked)
                handleChanges({
                  displayName: restaurantDetailsForm.getFieldValue("accountName"),
                });
              // If disabled and previously enabled set the display name to the account name
              else if (initialValues.accountNameIsDisplayName) {
                handleChanges({
                  displayName: restaurantDetailsForm.getFieldValue("accountName"),
                });
                restaurantDetailsForm.setFieldsValue({
                  displayName: restaurantDetailsForm.getFieldValue("accountName"),
                });
              }
              // If disabled and previously disabled set the display name to the previous display name
              else {
                handleChanges({ displayName: initialValues.displayName });
                restaurantDetailsForm.setFieldsValue({
                  displayName: initialValues.displayName,
                });
              }
            }}
          />
          <div
            hidden={restaurantDetailsForm.getFieldValue("accountNameIsDisplayName")}
            // This needs to be hidden, not non-existent. Making it non-existent causes issues with getting the value
          >
            <TextInput
              id="display-name-input"
              name="displayName"
              label="Display Name"
              onChange={(e) => handleChanges({ displayName: e.target.value })}
            />
          </div>
          <p>
            <i>
              Deselect this option to specify a different venue name to be displayed to guests on menu pages and
              receipts. Names containing quotes (") are invalid and will be rejected.
            </i>
          </p>
        </div>
      </SettingsCard>
      <SettingsCard title="Address">
        <div>
          <RestaurantAddressInput
            label="Physical Address"
            name="physicalAddress"
            initialValue={initialValues?.physicalAddress}
            id="physical-address"
            form={restaurantDetailsForm}
            onChange={(e) => handleChanges({ physicalAddress: e })}
            required={initialValues.physicalAddress && Object.keys(initialValues.physicalAddress).length > 0}
          />
          <p>
            <i>The address used for delivery drivers, location-based searches, etc.</i>
          </p>
        </div>
        <div>
          <CheckboxInput
            id="physical-address-is-mailing-address"
            name="physicalAddressIsMailingAddress"
            label="Mailing address is the same as physical address"
            onChange={(e) => {
              setArbitrary((arbitrary) => !arbitrary);
              // If enabled, set the mailing address to null
              handleChanges({
                mailingAddress: e.target.checked ? null : mailingAddressState,
              });
            }}
          />
          <div
            hidden={restaurantDetailsForm.getFieldValue("physicalAddressIsMailingAddress")}
            // This needs to be hidden, not non-existent
          >
            <RestaurantAddressInput
              form={restaurantDetailsForm}
              initialValue={initialValues.mailingAddressInitial}
              id="mailing-address"
              name="mailingAddress"
              label="Mailing Address"
              onChange={(e) => {
                handleChanges({ mailingAddress: e });
                setMailingAddressState(e);
              }}
              required={!restaurantDetailsForm.getFieldValue("physicalAddressIsMailingAddress")}
            />
          </div>
          <p>
            <i>
              Deselect to specify a different mailing address. Bbot will use this address when sending hardware or
              physical communications.
            </i>
          </p>
        </div>
      </SettingsCard>
      <SettingsCard title="Contact">
        <div>
          <PhoneInput
            title="Phone Number"
            id="phone-input"
            name="phoneNumber"
            required={initialValues.phoneNumber}
            onChange={(val) => handleChanges({ phoneNumber: val })}
          />
          <EmailInput
            label="Email"
            id="email-input"
            name="email"
            required={initialValues.email}
            onChange={(e) => handleChanges({ email: e.target.value })}
          />
          <p>
            <i>
              This is how your guests can reach your venue. In some cases (such as delivery integrations) this
              information will also be shared with your integrations.
            </i>
          </p>
        </div>
      </SettingsCard>
    </>
  );

  // Tax and Timezone tab content
  const taxAndTimezone = (
    <>
      <SettingsCard title="Tax">
        <div>
          <NumberInput
            required={true}
            label="Tax Rate"
            id="tax-rate"
            name="tax"
            addonAfter="%"
            min={0}
            onChange={(e) => handleChanges({ tax: parseFloat(e).toFixed(3) })}
          />
          <p>
            <i>
              Default tax rate is used for all service charges with default tax settings and all menu items/modifiers{" "}
              <strong>moving forward</strong>
            </i>
          </p>
        </div>
        {isAdmin && (
          <div>
            <CheckboxInput
              label={
                <>
                  Tax inclusive pricing: display post-tax price on menu? <BbotTag>Admin Only</BbotTag>
                </>
              }
              id="tax-inclusive"
              name="taxInclusivePricing"
              onChange={(e) => {
                handleChanges({ taxInclusivePricing: e.target.checked });
              }}
            />
            <div
              hidden={restaurantDetailsForm.getFieldValue("taxInclusivePricing") === initialValues.taxInclusivePricing}
            >
              <RadioInput
                id="pretax-behavior"
                name="adjustPrices"
                label="Bulk-adjust item prices?"
                choices={[
                  {
                    label: "Keep pretax prices the same (price shown on menu will change)",
                    value: "keep",
                  },
                  {
                    label: "Adjust pretax prices, such that price shown on menu will stay the same",
                    value: "adjust",
                  },
                ]}
              />
            </div>
            <p>
              <i>
                Enable this option to display the <strong>post-tax</strong> price on menus.
              </i>
            </p>
          </div>
        )}
      </SettingsCard>
      <SettingsCard title="Timezone">
        <div>
          <SelectInput
            name="timezone"
            id="timezone"
            label="Timezone"
            required={true}
            options={timezoneChoices}
            onChange={(value) => handleChanges({ timezone: value })}
          />
          <p>
            <i>
              Your timezone controls your business hours and reporting hours. If your timezone isn't listed please
              contact support@bbot.menu for help.
            </i>
          </p>
        </div>
      </SettingsCard>
    </>
  );

  // Danger Zone tab content
  const dangerZone = (
    <>
      <SettingsCard title="Currency">
        <div>
          <SelectInput
            name="currency"
            id="currency"
            label="Currency"
            required={true}
            options={currencyChoices}
            disabled={!ownerProperties.can_delete}
            onChange={(value) => {
              const firstLocale = localeChoices[value][0];
              restaurantDetailsForm.setFieldsValue({ locale: firstLocale });
              handleChanges({ currency: value, locale: firstLocale });
              setArbitrary((arbitrary) => !arbitrary);
            }}
          />
          <SelectInput
            name="locale"
            id="locale"
            label="Locale"
            required={true}
            options={
              restaurantDetailsForm.getFieldValue("currency") && localeChoices?.length > 0
                ? localeChoices[restaurantDetailsForm.getFieldValue("currency")].map((locale) => {
                    return { value: locale, label: locale };
                  })
                : []
            }
            disabled={!ownerProperties.can_delete}
            onChange={(value) => handleChanges({ locale: value })}
          />
          <p>
            <i>Currency and locale can only be changed if this customer has not yet received any orders.</i>
          </p>
        </div>
      </SettingsCard>
      <DangerCard title={<DangerText>Deactivate/Delete Customer</DangerText>}>
        <div className="d-flex justify-content-space-between">
          <p>
            <i>Delete a customer that has never transacted. This is permanent and cannot be undone.</i>
          </p>
          <BbotButton
            danger
            id="delete-button"
            type="primary"
            disabled={!ownerProperties.can_delete}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Customer
          </BbotButton>
        </div>
        <div className="d-flex justify-content-space-between">
          {ownerProperties.is_archived ? (
            <>
              <p>
                <i>Reactive deactivated customer.</i>
              </p>
              <BbotButton type="primary" id="reactivate-button" onClick={() => setShowReactivateModal(true)}>
                Reactivate Customer
              </BbotButton>
            </>
          ) : (
            <>
              <p>
                <i>Deactivate this customer. Deactivated customers CAN be reactivated.</i>
              </p>
              <BbotButton
                danger
                id="deactivate-button"
                type="primary"
                disabled={ownerProperties.is_archived}
                onClick={() => setShowDeactivateModal(true)}
              >
                Deactivate Customer
              </BbotButton>
            </>
          )}
        </div>
      </DangerCard>
    </>
  );

  // Initialize tabs - include Danger Zone if the user is an admin
  let tabs = [
    { name: "Display and Contact", content: displayAndContact },
    { name: "Tax and Timezone", content: taxAndTimezone },
  ];
  if (isAdmin)
    tabs.push({
      name: (
        <DangerText>
          Danger Zone <BbotTag color="red">Admin Only</BbotTag>
        </DangerText>
      ),
      key: "danger zone",
      content: dangerZone,
    });

  // Render
  return (
    <OPContainer
      title={NAME}
      description={DESCRIPTION}
      selectedCustomer={selectedCustomer}
      url={URL}
      userInfo={props.userInfo}
    >
      <Spin spinning={isLoading}>
        <Form
          id="restaurant-details-form"
          form={restaurantDetailsForm}
          layout="vertical"
          onChange={() => {
            setArbitrary((arbitrary) => !arbitrary); // Hacky function to force the form to rerender
          }}
        >
          <EditSettingsContainer
            id="restaurant-details-bulk-edit-container"
            tabs={tabs}
            showAlert={false}
            forceRender={true}
            showSave={Object.keys(settingsState).length > 0}
            onSave={validateForm}
          />
        </Form>
      </Spin>

      {/*==== Modals ====*/}
      {/* Confirm changes modal */}
      <BbotModal
        id={ID + "-confirmationModal"}
        title="Are you sure you want to make these changes?"
        visible={showSaveChangesModal}
        onCancel={() => setShowSaveChangesModal(false)}
        okText="Confirm"
        onOk={saveChanges}
        okButtonProps={{ id: ID + "-confirmationModal-confirm" }}
        cancelButtonProps={{ id: ID + "-confirmationModal-cancel" }}
      >
        <List>{formatChanges(settingsState)}</List>
      </BbotModal>
      {/* Invalid changes modal */}
      <BbotModal
        id={ID + "-invalidModal"}
        title="Invalid changes"
        visible={invalidChanges.length > 0}
        onCancel={() => setInvalidChanges([])}
        footer={
          <BbotButton danger id={ID + "-invalidModal-cancel"} onClick={() => setInvalidChanges([])}>
            Cancel
          </BbotButton>
        }
      >
        Please fix the following issues:
        <List>
          <strong>
            {invalidChanges.map((error, i) => {
              return (
                <List.Item key={i}>
                  <Row>
                    <Col span={24}>
                      <strong>{camelToTitleCase(error.name[0])}</strong>
                    </Col>
                    <Col span={24}>{error.errors.join(", ")}</Col>
                  </Row>
                </List.Item>
              );
            })}
          </strong>
        </List>
      </BbotModal>
      {/* Delete modal */}
      <BbotModal
        title="Are you sure you want to delete this customer?"
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        confirmLoading={deleting}
        okText="Delete"
        okType="danger"
        onOk={onDelete}
        okButtonProps={{ id: "delete-confirm" }}
        cancelButtonProps={{ id: "delete-cancel" }}
      >
        <Form form={deleteForm} layout="vertical">
          You are about to delete this customer. <strong>This action cannot be undone.</strong> Please type the Admin
          Name for this customer to confirm
          <Spin spinning={deleting}>
            <TextInput id="confirm-delete-text" name="confirmDeleteText" placeholder="Admin name" />
          </Spin>
        </Form>
      </BbotModal>
      {/* Deactivate modal */}
      <BbotModal
        title="Are you sure you want to deactivate this customer?"
        visible={showDeactivateModal}
        onCancel={() => setShowDeactivateModal(false)}
        okText="Deactivate"
        okType="danger"
        onOk={onDeactivate}
        confirmLoading={deactivating}
        okButtonProps={{ id: "deactivate-confirm" }}
        cancelButtonProps={{ id: "deactivate-cancel" }}
      >
        <Form form={deactivateForm} layout="vertical">
          Please type the Admin Name for this customer to confirm
          <Spin spinning={deactivating}>
            <TextInput id="confirm-deactivate-text" name="confirmDeactivateText" placeholder="Admin name" />
          </Spin>
        </Form>
      </BbotModal>
      {/* Reactivate modal */}
      <BbotModal
        title="Are you sure you want to reactivate this customer?"
        visible={showReactivateModal}
        confirmLoading={reactivating}
        onCancel={() => setShowReactivateModal(false)}
        onOk={onReactivate}
        okText="Reactivate"
        okButtonProps={{ id: "reactivate-confirm" }}
        cancelButtonProps={{ id: "reactivate-cancel" }}
      >
        Please confirm that you would like to reactivate this customer
      </BbotModal>
    </OPContainer>
  );
};

export default RestaurantDetails;

const DangerText = styled.span`
  color: #b42318;
`;

const DangerCard = styled(SettingsCard)`
  .ant-card-body {
    border: 1px solid #b42318;
    border-radius: 8px;
    margin-top: 24px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
`;
