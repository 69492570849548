import { ToggleInput } from "../index";
import React from "react";
import PropTypes from "prop-types";
import {
  DescriptionContainer,
  SettingsContainer,
  TitleRow,
  ToggleContainer,
} from "../../styles/SettingsContainerStyles";

const ToggleInputRich = ({ title, description, ...props }) => (
  <SettingsContainer className={"settings-element"}>
    <TitleRow>
      <span>{title}</span>
      <ToggleContainer>
        <ToggleInput {...props} />
      </ToggleContainer>
    </TitleRow>
    <DescriptionContainer>
      <i>{description}</i>
    </DescriptionContainer>
  </SettingsContainer>
);

export default ToggleInputRich;

ToggleInputRich.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
  toggleConfig: PropTypes.object,
};
