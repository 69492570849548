import React, { useEffect } from "react";
import axios from "axios";
import { DateTime } from "luxon";

// Components
import Detail from "./Detail";
import { BbotButton, List, Row, Col, Card, Input } from "bbot-component-library";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";

const UserActionLog = (props) => {
  const [entries, setEntries] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [viewOptions, setViewOptions] = React.useState({
    search: "",
  });
  const { userInfo, customerId } = props;

  const getUserActionLogs = async () => {
    if (!customerId) return;

    try {
      setIsLoading(true);
      const response = await axios.get("/owner/getUserActionLogs", {
        params: { customer_id: customerId },
      });
      await setEntries(response.data.entries);
    } catch (err) {
      console.error(err);
      alert("Failed to get User Action Log: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // if the filter box is empty, don't filter.  If entry.affected_objects is null, the filter command will err out, and those should be filtered out anyway if any filtering is happening
  const getSortedAndFilteredEntries = () => {
    const sortedEntries = entries.sort((entryA, entryB) =>
      DateTime.fromISO(entryA.time) < DateTime.fromISO(entryB.time) ? 1 : -1
    );
    if (viewOptions.search === "") return sortedEntries;
    return sortedEntries.filter((entry) => {
      return Object.entries(entry).some(([key, value]) => {
        if (key === "[[Prototype]]") return false;
        if (typeof value === "string") return value.toLowerCase().includes(viewOptions.search.toLowerCase());
        return Object.entries(value)
          .flat()
          .some((val) => val.id?.includes(viewOptions.search) || val.name?.includes(viewOptions.name));
      });
    });
  };

  const sortedAndFilteredEntries = getSortedAndFilteredEntries();

  const userIsAdmin = userInfo?.role === "admin";

  // Get feed whenever selected customer changes
  useEffect(() => {
    if (props.customerId) {
      getUserActionLogs();
    }
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="margin-bottom-4">
      <Detail
        entry={sortedAndFilteredEntries[selectedRow]}
        userIsAdmin={userIsAdmin}
        dismiss={() => setSelectedRow(null)}
      />

      <div className="margin-y-2">
        <SearchBar prefix={<SearchOutlined />} onChange={(e) => setViewOptions({ search: e.target.value })} />
        <BbotButton type={"primary"} onClick={getUserActionLogs} loading={isLoading}>
          Refresh
        </BbotButton>
      </div>

      <Card>
        <List
          loading={isLoading}
          dataSource={sortedAndFilteredEntries}
          renderItem={(event, index) => (
            <StyledEvent onClick={() => setSelectedRow(index)}>
              <List.Item.Meta
                description={
                  <Row gutter={8}>
                    <Col xs={24} md={6}>
                      {event.username}
                    </Col>
                    <Col xs={24} md={12}>
                      <div>
                        <b>{event.action}</b>
                      </div>
                      <div>{event.details}</div>
                    </Col>
                    <Col xs={24} md={6}>
                      {DateTime.fromISO(event.time).toLocaleString(DateTime.DATETIME_SHORT)}
                    </Col>
                  </Row>
                }
              />
            </StyledEvent>
          )}
        />
      </Card>
    </div>
  );
};

const SearchBar = styled(Input)`
  max-width: 400px;
  margin-right: 20px;
`;

const StyledEvent = styled(List.Item)`
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--color-neutral__page-background);
  }
`;

export default UserActionLog;
