import React from "react";

import "./SaveConfirmationModal.scss";
import { centsToDollar, splitCamelCase, splitSnakeCase } from "util/Utils";
import { BbotModal } from "bbot-component-library";
import { MAX_32_BIT_INT } from "Constants";

/**
 * Formats the property name so users can read it. If it's a menu id, find menu name and return it. Same with stations.
 * @param propertyName
 */
const getPropertyNameString = (propertyName, category, menus, stations) => {
  if (category === "menus") return menus[propertyName];
  if (category === "stations") return stations[propertyName];
  // only for display purposes: shared carts are now known as group ordering, display as such w/o extensive backend refactor
  if (propertyName === "shared_carts_allowed") propertyName = "group_ordering_allowed";
  return splitCamelCase(splitSnakeCase(propertyName.replaceAll("cents", ""))); // removing 'cents' because we want to show dollar values instead.
};

const SaveConfirmationModal = (props) => {
  const { show, onSaveCallback, onHideCallback, isSaving, changes, menus, stations, fulfillmentMethodPrettyNames } =
    props;

  /**
   * Takes a variable, returns user readable result based on type.
   * @param updatedValue
   * @param propertyName (optional) for special cases (fulfilment_method and cents fields)
   */
  const getChangeString = (updatedValue, propertyName) => {
    // if fulfillment method changed, show pretty name
    if (propertyName === "fulfillment_method") {
      return fulfillmentMethodPrettyNames[updatedValue];
    }

    if (updatedValue === "" || (propertyName === "maximum_paid_order_cents" && updatedValue >= MAX_32_BIT_INT))
      return "off";

    // pretty rudimentary solution but if property name contains cents show dollars instead
    if (propertyName.includes("cents")) {
      return "$" + centsToDollar(updatedValue);
    }

    if (updatedValue === "") return "off";
    if (typeof updatedValue === "string" || typeof updatedValue === "number") return updatedValue;
    if (typeof updatedValue === "boolean") return updatedValue ? "on" : "off";
    if (typeof updatedValue === "object") {
      if (updatedValue === null) return "delete";
      return "preview unavailable";
    }
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onHideCallback}
      title={"Are you sure you want to make these changes?"}
      onOk={onSaveCallback}
      okText={"Save"}
      okButtonProps={{
        loading: isSaving,
      }}
    >
      <div className={"changes-list"}>
        {Object.entries(changes).map(([categoryName, changeObj]) =>
          Object.entries(changeObj).map(([propertyName, updatedVal], index) => (
            <div className={"change-entry"} key={propertyName}>
              <span className={"padding-y-1 padding-x-1 d-flex justify-content-space-between"}>
                <span>{getPropertyNameString(propertyName, categoryName, menus, stations)}</span>
                <span>{getChangeString(updatedVal, propertyName)}</span>
              </span>
            </div>
          ))
        )}
      </div>
    </BbotModal>
  );
};

export default SaveConfirmationModal;
