import React, { useCallback, useRef, useState } from "react";
import { Colors, Spacing, StackChildren, Text } from "@doordash/design-language";
import styled from "styled-components";

import FolderIcon from "assets/svgs/icons/icon-folders.svg";
import Button from "@doordash/component-button";
import { useFileUploadContext } from "./FileUploadProvider";

export type Layout = "full" | "short";

export type DragDropProps = {
  layout?: Layout;
  isMobile?: boolean;
  validateFile?: (file: File) => string | Promise<string>;
};

const DragDrop: React.FC<DragDropProps> = ({ layout = "full", isMobile = false, children }) => {
  const { addInProgressFiles, acceptedTypes } = useFileUploadContext();
  const [dragging, setDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current += 1;

      if (dragCounter.current === 1) {
        setDragging(true);
      }
    },
    [setDragging]
  );

  const handleDragOut = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current -= 1;

      if (dragCounter.current === 0) {
        setDragging(false);
      }
    },
    [setDragging]
  );

  const handleUpload = useCallback((files: File[]) => addInProgressFiles(files), [addInProgressFiles]);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current = 0;
      setDragging(false);
      const files = [...e.dataTransfer.files];
      handleUpload(files);
    },
    [handleUpload]
  );

  const className = [!!dragging && "dragging", isMobile && "mobile"].filter(Boolean).join(" ");

  return (
    <FileUploadArea
      className={className}
      onDragEnter={handleDragOver}
      onDragOver={preventDefault}
      onDragLeave={handleDragOut}
      onDragEnd={preventDefault}
      onDrop={handleDrop}
    >
      <FileUploadContentContainer size={StackChildren.Sizes.Medium} layout={layout}>
        {layout === "full" && <Folder src={FolderIcon} alt="Folder Icon" />}
        <DragDropText layout={layout} isMobile={isMobile} />
        <ChooseFiles handleUpload={handleUpload} acceptedTypes={acceptedTypes} />
        {children}
      </FileUploadContentContainer>
    </FileUploadArea>
  );
};

const ChooseFiles: React.FC<{ handleUpload: (files: File[]) => void; acceptedTypes: string[] }> = ({
  handleUpload,
  acceptedTypes,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = useCallback(() => inputRef.current?.click(), []);

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const files = [...((e.target as HTMLInputElement).files ?? [])];
      handleUpload(files);
    },
    [handleUpload]
  );

  return (
    <>
      <Button isInline type={Button.Types.Tertiary} onClick={handleButtonClick}>
        Choose Files
      </Button>
      <input type="file" ref={inputRef} onInput={handleInput} multiple hidden accept={acceptedTypes.join(",")} />
    </>
  );
};

const DragDropText: React.FC<{ layout: Layout; isMobile: boolean }> = ({ layout, isMobile }) => {
  if (isMobile) {
    return null;
  }

  return layout === "full" ? (
    <>
      <Text styles={Text.Styles.Body1Emphasis} color={Text.Colors.TextSecondary}>
        Drag and drop here
      </Text>
      <Text styles={Text.Styles.Label2Emphasis} color={Text.Colors.TextTertiary}>
        or
      </Text>
    </>
  ) : (
    <Text styles={Text.Styles.Body1Emphasis} color={Text.Colors.TextSecondary}>
      Drag and drop here or
    </Text>
  );
};

const Folder = styled.img`
  max-width: 120px;
  max-height: 120px;
  mix-blend-mode: multiply;
`;

const FileUploadArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${Colors.BorderSecondary};
  border-radius: 12px;
  width: 100%;
  padding: ${Spacing.Large}px;
  transition: border 0.25s ease-in-out, background 0.25s ease-in-out;

  &.mobile {
    border: none;
    border-bottom: 1px solid ${Colors.SystemGrey5};
    border-radius: 0px;
  }

  &.dragging {
    border-color: ${Colors.SystemBlue60};
    background-color: ${Colors.SystemBlue10};
  }
`;

type FileUploadContentContainerProps = {
  layout: Layout;
  size: number;
};

const FileUploadContentContainer = styled.div`
  display: flex;
  flex-direction: ${({ layout }: FileUploadContentContainerProps) => (layout === "full" ? "column" : "row")};
  align-items: center;

  & > * + * {
    margin-top: ${({ size, layout }) => (layout === "full" ? size : 0)}px;
    margin-left: ${({ size, layout }) => (layout === "full" ? 0 : size)}px;
  }
`;

const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export default DragDrop;
