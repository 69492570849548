import React, { ComponentProps, useCallback } from "react";
import Button from "@doordash/component-button";
import { FileUploadContextValue, useFileUploadContext } from "./FileUploadProvider";

export type SelectedContext = Omit<FileUploadContextValue, "addInProgressFiles" | "removeInProgressFile" | "">;

type FileUploadButtonProps = Omit<ComponentProps<typeof Button>, "onClick"> & {
  onClick: (context: SelectedContext) => void;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onClick, children, ...props }) => {
  const { addInProgressFiles, removeInProgressFile, ...selectedContext } = useFileUploadContext();
  const handleClick = useCallback(() => onClick(selectedContext), [onClick, selectedContext]);

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default FileUploadButton;
