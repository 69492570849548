import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Button, Modal, Row, Col, Form as BtpForm, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form, Formik } from "formik";
import { CheckboxInput, SelectInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import RadioGroupInput from "../../../global/form/RadioGroupInput";
import "./OmnivoreIntegrationModal.scss";
import CenteredLoadingSpinner from "../../loading-spinners/CenteredLoadingSpinner";
import { generalErrorAlert } from "../../../../util/Utils";
import OmnivoreCustomTenderTypesModalInputs from "../app-store/OmnivoreCustomTenderTypesModalInputs";

class OmnivoreIntegrationModal extends Component {
  state = {
    omnivoreLocationId: null,
    employeeId: null,
    revenueCenter: null,
    orderType: null,
    tenderType: null,
    syncInStock: false,
    syncPriceLevels: false,
    injectOrders: false,
    autoRefund: false,
    ticketNameTemplateString: null,
    logSuccessfulInjection: false,
    employees: [],
    orderTypes: [],
    revenueCenters: [],
    tenderTypes: [],
    actionOnSuccess: null,
    actionOnFailure: null,
    deleteMenuItems: false,
    showDisconnectOptions: false,
    loadingData: false,
    savingData: false,
    hasLoaded: false,
    injectUserInfo: true,
    reEnableAutoDisabledItems: true,
    useCustomTenderTypeMappings: false,
    mcTenderType: null,
    discoverTenderType: null,
    amexTenderType: null,
    visaTenderType: null,
    injectCardLast4: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getOmnivoreConnectionStatus();
    }
  }

  getOmnivoreConnectionStatus = async () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer) return;

    try {
      this.setState({ loadingData: true });

      const res = await axios.get("admin/getOmnivore3ConnectionStatus", {
        params: {
          customer_id: selectedCustomer.customer_id,
        },
      });

      this.setState(
        {
          omnivoreLocationId: res.data.location_id,
          employeeId: res.data.employee_id,
          revenueCenter: res.data.revenue_center_id,
          orderType: res.data.order_type_id,
          tenderType: res.data.tender_type_id,
          syncInStock: res.data.sync_in_stock,
          syncPriceLevels: res.data.sync_price_levels,
          injectOrders: res.data.inject_orders,
          autoRefund: res.data.webhook_auto_refund,
          logSuccessfulInjection: res.data.log_successful_injection,
          actionOnSuccess: res.data.action_on_success,
          actionOnFailure: res.data.action_on_failure,
          hasLoaded: true,
          ticketNameTemplateString: res.data.ticket_name_template_string,
          defaultServiceFeeName: res.data.default_service_fee_name,
          injectUserInfo: res.data.inject_user_info,
          reEnableAutoDisabledItems: res.data.re_enable_auto_disabled_items,
          useCustomTenderTypeMappings: res.data.use_custom_tender_type_mappings,
          visaTenderType: res.data.visa_tender_type,
          mcTenderType: res.data.mc_tender_type,
          discoverTenderType: res.data.discover_tender_type,
          amexTenderType: res.data.amex_tender_type,
          injectCardLast4: res.data.inject_card_last_4,
        },
        async () => {
          if (res.data.location_id) {
            await this.getLocationOptions(res.data.location_id);
          }
          this.setState({ loadingData: false });
        }
      );
    } catch (error) {
      generalErrorAlert(error, "Error getting Omnivore connection status.");
      this.setState({ loadingData: false });
    }
  };

  getLocationOptions = async (omnivoreLocationId) => {
    try {
      const res = await axios.get("/admin/getOmnivoreDirectLocationOptions", {
        params: {
          location_id: omnivoreLocationId,
        },
      });

      this.setState({
        employees: res.data.employees,
        orderTypes: res.data.order_types,
        revenueCenters: res.data.revenue_centers,
        tenderTypes: res.data.tender_types,
        omnivoreLocationId: omnivoreLocationId,
      });
    } catch (error) {
      generalErrorAlert(error, "Error getting Omnivore location options.");
    }
  };

  onHide = () => {
    const { onHide } = this.props;
    this.setState(
      {
        showDisconnectOptions: false,
        deleteMenuItems: false,
      },
      onHide
    );
  };

  onSubmit = async (values) => {
    const { omnivoreLocationId } = this.state;
    const { selectedCustomer, onSave } = this.props;

    this.setState({ savingData: true });

    if (omnivoreLocationId) {
      try {
        const payload = {
          customer_id: selectedCustomer.customer_id,
          employee_id: values.employeeId,
          location_id: values.omnivoreLocationId,
          revenue_center_id: values.revenueCenter,
          order_type_id: values.orderType,
          tender_type_id:
            values.tenderType === "None - Reject orders with unmapped tender types" ? null : values.tenderType,
          inject_orders: values.injectOrders,
          sync_in_stock: values.syncInStock,
          sync_price_levels: values.syncPriceLevels,
          webhook_auto_refund: values.autoRefund,
          log_successful_injection: values.logSuccessfulInjection,
          action_on_success: values.actionOnSuccess,
          action_on_failure: values.actionOnFailure,
          available_order_types: this.state.orderTypes,
          ticket_name_template_string: values.ticketNameTemplateString,
          default_service_fee_name: values.defaultServiceFeeName,
          inject_user_info: values.injectUserInfo,
          re_enable_auto_disabled_items: values.reEnableAutoDisabledItems,
          use_custom_tender_type_mappings: values.useCustomTenderTypeMappings,
          visa_tender_type: values.visaTenderType === "Don't accept this card type." ? null : values.visaTenderType,
          mc_tender_type: values.mcTenderType === "Don't accept this card type." ? null : values.mcTenderType,
          discover_tender_type:
            values.discoverTenderType === "Don't accept this card type." ? null : values.discoverTenderType,
          amex_tender_type: values.amexTenderType === "Don't accept this card type." ? null : values.amexTenderType,
          inject_card_last_4: values.injectCardLast4,
        };

        await axios.post("/admin/connectOmnivore3", payload);
        toast.success("Successfully connected to Omnivore");
        this.onHide();
        this.getOmnivoreConnectionStatus();
        onSave();
      } catch (error) {
        generalErrorAlert(error, "Error connecting Onmivore.");
      }
    } else {
      await this.getLocationOptions(values.omnivoreLocationId);
    }
    this.setState({ savingData: false });
  };

  disconnect = async () => {
    const { selectedCustomer, onSave } = this.props;
    const { deleteMenuItems } = this.state;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        delete_menu_items: deleteMenuItems,
      };
      await axios.post("/admin/disconnectOmnivore3", payload);
      toast.success("Successfully disconnected from Omnivore");
      this.onHide();
      this.getOmnivoreConnectionStatus();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error disconnecting Omnivore.");
    }
  };

  disconnectOptions = () => (
    <div>
      <div className={"margin-bottom-1"}>
        <p>
          Disconnecting will stop orders from being sent to Omnivore Direct. If would also like to delete menu items
          that came from Omnivore, please check the checkbox below.
        </p>
      </div>
      <BtpForm.Group className={"margin-bottom-1 "}>
        <BtpForm.Check
          className={"check-box"}
          label={"Delete existing Omnivore menu items"}
          type={"checkbox"}
          onChange={(event) => {
            this.setState({ deleteMenuItems: event.target.checked });
          }}
        />
      </BtpForm.Group>
    </div>
  );

  disconnectModal = () => {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Omnivore</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.disconnectOptions()}</Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button size={"sm"} variant={"danger"} onClick={this.disconnect}>
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { show, disconnectMode } = this.props;
    const {
      omnivoreLocationId,
      employeeId,
      revenueCenter,
      orderType,
      tenderType,
      employees,
      orderTypes,
      revenueCenters,
      tenderTypes,
      syncInStock,
      syncPriceLevels,
      injectOrders,
      autoRefund,
      actionOnSuccess,
      actionOnFailure,
      savingData,
      loadingData,
      logSuccessfulInjection,
      ticketNameTemplateString,
      defaultServiceFeeName,
      injectUserInfo,
      useCustomTenderTypeMappings,
      visaTenderType,
      amexTenderType,
      discoverTenderType,
      mcTenderType,
      injectCardLast4,
      reEnableAutoDisabledItems,
    } = this.state;

    return disconnectMode ? (
      this.disconnectModal()
    ) : (
      <Modal show={show} onHide={this.onHide}>
        <Formik
          enableReinitialize
          initialValues={{
            omnivoreLocationId: omnivoreLocationId || "",
            employeeId: employeeId || employees[0]?.id,
            revenueCenter: revenueCenter || revenueCenters[0]?.id,
            orderType: orderType || orderTypes[0]?.id,
            tenderType: tenderType || tenderTypes[0]?.id,
            syncInStock: syncInStock,
            syncPriceLevels: syncPriceLevels,
            injectOrders: injectOrders,
            autoRefund: autoRefund,
            logSuccessfulInjection: logSuccessfulInjection,
            actionOnSuccess: actionOnSuccess || "open",
            actionOnFailure: actionOnFailure || "accept",
            ticketNameTemplateString: ticketNameTemplateString ?? "",
            defaultServiceFeeName: defaultServiceFeeName ?? "",
            injectUserInfo: injectUserInfo ?? true,
            reEnableAutoDisabledItems: reEnableAutoDisabledItems ?? true,
            useCustomTenderTypeMappings: useCustomTenderTypeMappings ?? false,
            visaTenderType: visaTenderType,
            mcTenderType: mcTenderType,
            amexTenderType: amexTenderType,
            discoverTenderType: discoverTenderType,
            injectCardLast4: injectCardLast4 ?? false,
          }}
          onSubmit={this.onSubmit}
        >
          {({ values, errors, touched, isValidating, setFieldValue }) => (
            <Form className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Integrate With Omnivore Direct</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {loadingData || savingData ? (
                  <CenteredLoadingSpinner
                    label={loadingData ? "Loading Omnivore Integration data" : "Integrating With Omnivore"}
                  />
                ) : omnivoreLocationId ? (
                  <>
                    <Row className={"margin-bottom-1"}>
                      <Col>
                        <div>Omnivore Location ID: {omnivoreLocationId}</div>
                      </Col>
                    </Row>
                    <Row className="margin-bottom-1">
                      <SelectInput name={"employeeId"} id={"employee"} sm={12} label={"Employee"}>
                        {employees.map((employee, i) => (
                          <option key={i} value={employee.id}>
                            {employee.first_name + " " + employee.last_name}
                          </option>
                        ))}
                      </SelectInput>
                    </Row>
                    <Row className="margin-bottom-1">
                      <SelectInput name={"revenueCenter"} id={"revenue-center"} sm={12} label={"Revenue Center"}>
                        {revenueCenters.map((revenueCenter, i) => (
                          <option key={i} value={revenueCenter.id}>
                            {revenueCenter.name}
                          </option>
                        ))}
                      </SelectInput>
                    </Row>
                    <Row className="margin-bottom-1">
                      <SelectInput name={"orderType"} id={"order-type"} sm={12} label={"Order Type"}>
                        {orderTypes.map((orderType, i) => (
                          <option key={i} value={orderType.id}>
                            {orderType.name} ({orderType.id})
                          </option>
                        ))}
                      </SelectInput>
                    </Row>
                    <Row className="margin-bottom-1">
                      <CheckboxInput
                        label={"Use Custom Tender Type Mappings"}
                        name={"useCustomTenderTypeMappings"}
                        id={"use-custom-tender-type-mappings"}
                      />
                    </Row>
                    <Row className="margin-bottom-1">
                      <SelectInput name={"tenderType"} id={"tender-type"} sm={12} label={"Default Tender Type"}>
                        {tenderTypes.map((tenderType, i) => (
                          <option key={i} value={tenderType.id}>
                            {tenderType.name}
                          </option>
                        ))}
                        {values.useCustomTenderTypeMappings && (
                          <option key={null} value={null}>
                            None - Reject orders with unmapped tender types
                          </option>
                        )}
                      </SelectInput>
                    </Row>
                    {values.useCustomTenderTypeMappings && (
                      <OmnivoreCustomTenderTypesModalInputs tenderTypes={tenderTypes} />
                    )}
                    <Row className="margin-bottom-1">
                      <CheckboxInput
                        label={"Sync 'In Stock' from POS (if compatible)"}
                        name={"syncInStock"}
                        id={"sync-in-stock"}
                      />
                    </Row>
                    <Row className="margin-bottom-1">
                      <CheckboxInput
                        label={"Sync 'Price Levels' from POS"}
                        name={"syncPriceLevels"}
                        id={"sync-price-levels"}
                      />
                    </Row>
                    <Row className="margin-bottom-1">
                      <CheckboxInput label={"Inject orders into POS"} name={"injectOrders"} id={"inject-orders"} />
                    </Row>
                    {values.injectOrders && (
                      <>
                        <Row className="margin-bottom-1 padding-x-3">
                          <CheckboxInput
                            label={"Inject last four numbers of credit card"}
                            name={"injectCardLast4"}
                            id={"injectCardLast4"}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          <RadioGroupInput
                            options={[
                              {
                                label: "Accept checkout, fulfill solely with Bbot",
                                value: "accept",
                              },
                              {
                                label: "Abort checkout and refund guests",
                                value: "abort",
                              },
                            ]}
                            label={"What should happen in an Omnivore POS outage or injection failure?"}
                            name={"actionOnFailure"}
                            id={"action-on-failure"}
                            selectedValue={actionOnFailure}
                            onChange={(value) => setFieldValue("actionOnFailure", value)}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          <RadioGroupInput
                            options={[
                              {
                                label:
                                  "Keep the ticket open in Bbot, staff will update status in Bbot to notify guests.",
                                value: "open",
                              },
                              {
                                label: "Close the ticket in Bbot. Guests won't receive feedback about their orders.",
                                value: "close",
                              },
                            ]}
                            label={"What should happen on successful injection in Omnivore Direct?"}
                            name={"actionOnSuccess"}
                            id={"action-on-success"}
                            selectedValue={actionOnSuccess}
                            onChange={(value) => setFieldValue("actionOnSuccess", value)}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          {/*TODO: Use global component - make change in non-hotfix PR*/}
                          <TextInput
                            name={"ticketNameTemplateString"}
                            id={"ticket-name-template-string"}
                            label={[
                              "Ticket Name Field Format",
                              "  ",
                              <OverlayTrigger
                                key={"placement"}
                                placement={"right"}
                                overlay={
                                  <Tooltip id={"tooltip-select-days-and-times"}>
                                    <strong>
                                      If you include a keyword below, it will be automatically replaced with the
                                      relevant info.
                                    </strong>
                                    <br />
                                    $ORDER_NUMBER - Order number
                                    <br />
                                    $LOCATION - Location's name
                                    <br />
                                    $PATRON_NAME - Patron's name
                                    <br />
                                    $ROOM - Room number
                                    <br />
                                  </Tooltip>
                                }
                              >
                                <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                              </OverlayTrigger>,
                            ]}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          <CheckboxInput
                            label={"Inject User Info into POS"}
                            name={"injectUserInfo"}
                            id={"inject-user-info"}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          <TextInput
                            name={"defaultServiceFeeName"}
                            id={"default-service-fee-name"}
                            label={"Default Service Fee Name"}
                          />
                        </Row>
                        <Row className={"margin-bottom-1 padding-x-3"}>
                          <small className={"padding-x-1"}>
                            If your POS applies automatic service charges to an order, what should they show up as on
                            the checkout page?
                          </small>
                        </Row>
                        <Row className="margin-bottom-1 padding-x-3">
                          <CheckboxInput
                            label={"Automatically refund items that were refunded in POS"}
                            name={"autoRefund"}
                            id={"auto-refund"}
                          />
                        </Row>
                        <Row className="margin-bottom-1">
                          <CheckboxInput
                            label={[
                              "Re-enable disabled menu items",
                              "  ",
                              <OverlayTrigger
                                key={"placement"}
                                placement={"right"}
                                overlay={
                                  <Tooltip id={"tooltip-re-enable-disabled-items"}>
                                    If items are disabled during menu sync because they cannot be found through
                                    Omnivore, should we re-enable them if we find them again during a later menu sync?
                                  </Tooltip>
                                }
                              >
                                <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                              </OverlayTrigger>,
                            ]}
                            name={"reEnableAutoDisabledItems"}
                            id={"re-enable-auto-disabled-items"}
                          />
                        </Row>
                        <Row className="margin-bottom-1 padding-x-3">
                          <CheckboxInput
                            label={"Log successful order injections"}
                            name={"logSuccessfulInjection"}
                            id={"log-successful-injection"}
                          />
                        </Row>
                        {values.logSuccessfulInjection && (
                          <Row className={"margin-bottom-1 padding-x-5"}>
                            <small style={{ fontStyle: "italic" }}>
                              Log the order data to the
                              <a
                                style={{ fontStyle: "italic" }}
                                href="https://central.bbot.menu/article/821-integrations-activity-feed"
                              >
                                {" "}
                                Integrations Activity Feed
                              </a>
                              . This is helpful for cross-referencing Bbot orders and Omnivore orders during initial
                              configuration.
                            </small>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    <TextInput label={"Omnivore Location Id"} name={"omnivoreLocationId"} id={"omnivore-location-id"} />
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {omnivoreLocationId ? (
                  <>
                    <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
                      Cancel
                    </Button>
                    <Button size={"sm"} type={"submit"} disabled={savingData}>
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button size={"sm"} type={"submit"} disabled={savingData}>
                      Next
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

OmnivoreIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default OmnivoreIntegrationModal;
