import React from "react";
import { Modal } from "react-bootstrap";
import { dateFormat } from "util/Utils";

const Detail = ({ entry, userIsAdmin, dismiss }) => {
  return (
    <Modal show={entry} onHide={dismiss}>
      <Modal.Header closeButton>
        <Modal.Title>{entry?.action}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"text log__time"}>
          <b>Time:</b> {entry ? dateFormat.format(new Date(entry.time)) : null}
        </div>
        <div>
          <b>Username:</b> {entry?.username}
        </div>
        <div>
          <b>Details:</b>
          <pre>{entry?.details}</pre>
        </div>
        {userIsAdmin && (
          <>
            <div>
              <b>Admin Details:</b>
              <pre>{entry?.admin_details}</pre>
            </div>
            <div>
              <b>
                <u>Affected Objects:</u>
              </b>
            </div>
            {entry?.affected_objects
              ? Object.entries(entry.affected_objects).map(([model, objects]) => {
                  return objects.map((affected_object, index) => (
                    <div key={index}>
                      <b>{model}</b> {affected_object.id}: {affected_object.name}
                    </div>
                  ));
                })
              : "None"}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default Detail;
