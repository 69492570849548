import React, { useEffect, useState } from "react";
import { BbotModal, BbotTable, notification } from "bbot-component-library";
import { capitalizeString, splitSnakeCase } from "util/Utils";
import TableEditDeleteIcons from "bbot-component-library/TableEditDeleteIcons";
import { Form } from "bbot-component-library";
import { deleteSupportAlert, getOwnerConfigs, saveSupportAlerts } from "util/ApiCalls";
import SelectInput from "bbot-component-library/form-inputs/SelectInput";

const TerminalAlerts = ({ terminalAlerts, possibleStations, isLoading, selectedCustomer, refreshData, ...props }) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [alertToEdit, setAlertToEdit] = useState(null);
  const [alertToDelete, setAlertToDelete] = useState(null);
  const [ownerConfigs, setOwnerConfigs] = useState([]);
  const [terminalAlertForm] = Form.useForm();

  const setOwnerConfigData = async () => {
    const res = await getOwnerConfigs(selectedCustomer);
    setOwnerConfigs(res);
  };

  useEffect(() => {
    setOwnerConfigData();
    setShowAddEditModal(false);
    setAlertToEdit(null);
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const terminalModalInitialValues = {
      deviceProfile: alertToEdit?.handheld_config || null,
      timeBeforeDue:
        alertToEdit?.support_alerts.map((supportAlert) => supportAlert.alert_class) ||
        "terminal_catering_order_in_30_minutes",
    };
    terminalAlertForm.setFieldsValue(terminalModalInitialValues);
  }, [alertToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = async () => {
    const config = ownerConfigs.find((config) => config.id === terminalAlertForm.getFieldValue("deviceProfile"));
    const alertClass =
      terminalAlertForm.getFieldValue("timeBeforeDue") === "terminal_catering_order_in_30_minutes"
        ? {
            alert_class: "terminal_catering_order_in",
            stations: possibleStations,
            timepicker: "minutes",
            minutes: "30",
          }
        : {
            alert_class: "terminal_catering_order_in",
            stations: possibleStations,
            timepicker: "hours",
            hours: "4",
          };
    const properties = {
      alertSettings: "customAlerts",
      createNew: !alertToEdit,
      isCustomer: true,
      handheld_config: config.id,
      name: config.config_name,
      newAlerts: [alertClass],
    };
    if (alertToEdit) properties.staffId = alertToEdit.id;
    await saveSupportAlerts(selectedCustomer, properties);
    refreshData();
    setAlertToEdit(null);
    setShowAddEditModal(false);
    notification.success({ message: "Terminal alert saved successfully." });
  };

  const onDelete = async () => {
    await deleteSupportAlert(alertToDelete.id);
    setAlertToDelete(null);
    notification.success({ message: "Terminal alert deleted successfully." });
    refreshData();
  };

  const formatAlerts = (alerts) => {
    return alerts.map((alert) => <p>{capitalizeString(splitSnakeCase(alert.alert_class))}</p>);
  };

  const formatConfigs = (configs) => {
    return configs?.map((config) => {
      return { label: config.config_name, value: config.id };
    });
  };

  const formattedAlertRows = terminalAlerts.map((terminalAlert) => {
    return {
      key: terminalAlert.id,
      device_profile: terminalAlert.name,
      alert_description: formatAlerts(terminalAlert.support_alerts),
      alert_actions: (
        <TableEditDeleteIcons
          onEdit={() => {
            setAlertToEdit(terminalAlert);
            setShowAddEditModal(true);
          }}
          onDelete={() => setAlertToDelete(terminalAlert)}
        />
      ),
    };
  });

  const modalContent = (
    <Form form={terminalAlertForm} layout="vertical" onFinish={onSave}>
      <SelectInput
        label="Device profile"
        options={formatConfigs(ownerConfigs)}
        name="deviceProfile"
        id="device-profile"
        required={true}
        placeholder="Choose a device profile"
      />
      <SelectInput
        label="How long before the order is due?"
        options={[
          {
            label: "30 Minutes",
            value: "terminal_catering_order_in_30_minutes",
          },
          { label: "4 Hours", value: "terminal_catering_order_in_4_hours" },
        ]}
        name="timeBeforeDue"
        id="time-before-due"
      />
    </Form>
  );

  return (
    <>
      <BbotTable
        id={"terminal-alerts"}
        data={formattedAlertRows}
        columns={["Device Profile", "Alert Description", "Alert Actions"]}
        loading={isLoading}
        onAdd={() => setShowAddEditModal(true)}
        interactive={false}
        pagination={terminalAlerts.length > 10}
      />
      <BbotModal
        title={alertToEdit ? "Edit terminal alert" : "Add terminal alert"}
        visible={showAddEditModal}
        onCancel={() => {
          setShowAddEditModal(false);
          setAlertToEdit(null);
        }}
        onOk={terminalAlertForm.submit}
      >
        {modalContent}
      </BbotModal>
      <BbotModal
        title="Are you sure you'd like to delete this alert?"
        visible={alertToDelete}
        onCancel={() => setAlertToDelete(null)}
        okText="Delete"
        okType="danger"
        onOk={onDelete}
      >
        You are about to delete the terminal alert from the device profile: <strong>{alertToDelete?.name}</strong>
      </BbotModal>
    </>
  );
};

export default TerminalAlerts;
