import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { PRINTER_DRIVER_PRETTY_NAMES } from "Constants";
import { generalErrorAlert } from "util/Utils";
import {
  BbotModal,
  TextInput,
  FormattedCheckboxGroupInput,
  Form,
  Row,
  Col,
  SelectInput,
  CheckboxInput,
  NumberInput,
} from "bbot-component-library";
import { notification } from "antd";

const AddPrinterModal = ({
  showAddPrinterModal,
  onHideCallback,
  onSaveCallback,
  customer,
  network,
  stations,
  printers,
  driverProfiles,
  getLoadBalanceMasterChoices,
}) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
  const [addPrinterForm] = Form.useForm();
  const [, setFormValues] = useState({});
  const loadBalancingOptions = getLoadBalanceMasterChoices(printers);

  const saveNewPrinter = async (values) => {
    // disable button to prevent multiple printers from being added:
    setSaveButtonDisabled(true);

    const payload = {
      network_id: network.id,
      customer_id: customer.customer_id,
      target_printer: {
        name: values.name,
        driver_profile: values.profile,
        local_ip: values.ipAddress,
        local_port: values.port,
        printer_type: values.printerType,
        stations: values.isLoadBalanceWorker
          ? []
          : values.stationsChecked.map((stationId) => ({
              id: stationId,
            })),
        loadbalance_master: values.isLoadBalanceWorker
          ? values.loadBalanceMaster != null
            ? values.loadBalanceMaster
            : null
          : null,
      },
    };
    try {
      await axios.post("/printer-setup/printer-management/", payload);
      notification.success({ message: "Successfully created " + values.name });
      onSaveCallback();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
    setSaveButtonDisabled(false);
  };

  return (
    <BbotModal
      title={"Add a New Printer"}
      visible={showAddPrinterModal}
      onCancel={() => onHideCallback()}
      onOk={addPrinterForm.submit}
      okText={"Submit"}
      okButtonProps={{
        loading: saveButtonDisabled,
      }}
    >
      <Form
        form={addPrinterForm}
        name="add-printer-form"
        onFinish={saveNewPrinter}
        layout={"vertical"}
        initialValues={{
          name: "",
          ipAddress: "",
          port: "9100",
          profile: "TM-U220",
          printerType: "pi_ethernet",
          stationsChecked: [],
          isLoadBalanceWorker: false,
          loadBalanceMaster: null,
        }}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <div>
          <Row gutter={12}>
            <Col span={24}>
              <TextInput id={"name"} name={"name"} label={"Printer Name"} placeholder={""} sm={12} />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <SelectInput
                id="printer-type"
                name="printerType"
                label="Printer Type"
                options={[
                  { value: "pi_ethernet", label: "Network Printer" },
                  { value: "pi_usb", label: "USB Printer" },
                ]}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                id={"driver-profile"}
                name={"profile"}
                label={"Driver Profile"}
                required={true}
                options={driverProfiles?.map((profile) => ({
                  label: PRINTER_DRIVER_PRETTY_NAMES.hasOwnProperty(profile)
                    ? PRINTER_DRIVER_PRETTY_NAMES[profile]
                    : profile,
                  value: profile,
                }))}
              />
            </Col>
          </Row>
          {addPrinterForm.getFieldValue("printerType") !== "pi_usb" && (
            <Row gutter={12}>
              <Col span={12}>
                <TextInput
                  id="ip_address"
                  name="ipAddress"
                  label="Printer IP Address"
                  required={true}
                  regex={/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}
                  regexValidationMessage={"Enter a valid IP Address"}
                />
              </Col>
              <Col span={12}>
                <NumberInput id="port" name="port" label="Printer Port (Default: 9100)" min={0} />
              </Col>
            </Row>
          )}
          <Row gutter={12}>
            <CheckboxInput
              id="isLoadBalanceWorker"
              name="isLoadBalanceWorker"
              label="This printer only prints tickets for another printer (to balance the workload)"
            />
          </Row>
          {addPrinterForm.getFieldValue("isLoadBalanceWorker") && (
            <Row gutter={12}>
              <Col span={24}>
                <SelectInput
                  id={"loadBalanceMaster"}
                  name={"loadBalanceMaster"}
                  typeAhead={true}
                  options={loadBalancingOptions}
                />
                <p>
                  This printer will not be shown on the Menu Item editor. (And any menu items currently set to print at
                  it will no longer print at it.) Instead, some tickets sent to{" "}
                  {addPrinterForm.getFieldValue("loadBalanceMaster") != null
                    ? loadBalancingOptions?.find(
                        (option) => option.value === addPrinterForm.getFieldValue("loadBalanceMaster")
                      )?.label
                    : "the selected parent printer"}{" "}
                  will print at {addPrinterForm.getFieldValue("name") || "this printer"} , on a rotating basis, to
                  balance the workload.
                </p>
              </Col>
            </Row>
          )}
          {!addPrinterForm.getFieldValue("isLoadBalanceWorker") && (
            <Row gutter={12}>
              <Col span={24}>
                <FormattedCheckboxGroupInput
                  id={"stations-checked"}
                  name={"stationsChecked"}
                  label={"Stations this Printer Serves"}
                  required={false}
                  options={
                    stations[customer.customer_id]?.stations
                      .sort((stationx, stationy) => (stationx.station_name > stationy.station_name ? 1 : -1))
                      .map((station) => ({
                        label: station.station_name,
                        value: station.id,
                      })) || []
                  }
                />
              </Col>
            </Row>
          )}
        </div>
      </Form>
    </BbotModal>
  );
};

AddPrinterModal.propTypes = {
  onHideCallback: PropTypes.func,
  onSaveCallback: PropTypes.func,
  showAddPrinterModal: PropTypes.bool,
  network: PropTypes.object,
  customer: PropTypes.object,
  driverProfiles: PropTypes.array,
};
export default AddPrinterModal;
