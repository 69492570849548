import React, { useEffect, useState } from "react";
import { getEmployees, saveSupportAlerts } from "util/ApiCalls";
import { PageLoadingSpinner, Form, TableEditDeleteIcons, notification, BbotWizard, Card } from "bbot-component-library";
import SelectInput from "bbot-component-library/form-inputs/SelectInput";
import RadioInput from "bbot-component-library/form-inputs/RadioInput";
import EmailInput from "bbot-component-library/form-inputs/EmailInput";
import PhoneInput from "bbot-component-library/form-inputs/PhoneInput";
import TextInput from "bbot-component-library/form-inputs/TextInput";
import { List } from "antd";
import styled from "styled-components";
import EditSettingsContainer from "bbot-component-library/EditSettingsContainer";

const AddEditStaffAlert = ({
  selectedCustomer,
  onCancel,
  onSaveOrEditCallback,
  possibleStations,
  alertToEdit = null,
  ...props
}) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setArbitrary] = useState(false);
  const [recipientToAdd, setRecipientToAdd] = useState({
    employeeOrContact: "employee",
  });
  const [alertsToAdd, setAlertsToAdd] = useState([]);
  const [contactForm] = Form.useForm();

  const setEmployeeData = async () => {
    setIsLoading(true);
    const res = await getEmployees(selectedCustomer);
    setEmployees(res);
    setIsLoading(false);
  };

  useEffect(() => {
    setEmployeeData();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (alertToEdit) {
      const recipientInitialValues = alertToEdit.user
        ? {
            employeeOrContact: "employee",
            employee: alertToEdit.user,
            staffEmail: alertToEdit.email || "",
            staffPhone: alertToEdit.phone || "",
          }
        : {
            employeeOrContact: "new",
            newName: alertToEdit.name,
            newEmail: alertToEdit.email || "",
            newPhone: alertToEdit.phone || "",
          };
      contactForm.setFieldsValue(recipientInitialValues);
      setRecipientToAdd(recipientInitialValues);
      alertToEdit.support_alerts.forEach((existingAlert) => handleAddAlertType("", existingAlert));
    }
  }, [alertToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const reRenderForm = () => setArbitrary((arbitrary) => !arbitrary);

  const employeeArray = employees.map((employee) => {
    return {
      label: employee.user.first_name + " " + employee.user.last_name,
      value: employee.user.id,
    };
  });

  const contactStep = (
    <ContactFormContainer>
      <div>
        <p>
          Step 1: Choose somebody to receive the alerts. This can be an existing employee in our system, or a new
          contact.
        </p>
      </div>
      <Form form={contactForm} layout="vertical" onValuesChange={reRenderForm}>
        <RadioInput
          id="employee-or-contact"
          name="employeeOrContact"
          choices={[
            { label: "Choose an employee", value: "employee" },
            { label: "Add a new contact", value: "new" },
          ]}
          button
          onChange={(e) => {
            setRecipientToAdd({
              ...recipientToAdd,
              employeeOrContact: e.target.value,
            });
          }}
        />
        {contactForm.getFieldValue("employeeOrContact") === "employee" && (
          <>
            <SelectInput
              id="add-employee"
              options={employeeArray}
              typeAhead
              label="Select an employee"
              name="employee"
              onChange={(employeeId) => {
                const email = employees.find((employee) => employee.user.id === employeeId).user.email;
                contactForm.setFieldsValue({
                  staffEmail: email,
                });
                setRecipientToAdd({
                  ...recipientToAdd,
                  employee: employeeId,
                  staffEmail: email,
                });
              }}
              placeholder="Employee"
            />
            <EmailInput
              disabled
              label="Email"
              name="staffEmail"
              id="staff-email"
              required={false}
              placeholder="email@domain.com"
            />
            <PhoneInput
              name="staffPhone"
              label="Phone"
              id="staff-phone"
              required={false}
              placeholder="(555) 555-5555"
              onChange={(val) => setRecipientToAdd({ ...recipientToAdd, staffPhone: val })}
            />
          </>
        )}
        {contactForm.getFieldValue("employeeOrContact") === "new" && (
          <>
            <TextInput
              id="new-name"
              name="newName"
              label="Contact Name"
              required
              placeholder="Contact name"
              onChange={(e) => setRecipientToAdd({ ...recipientToAdd, newName: e.target.value })}
            />
            <EmailInput
              label="Email"
              name="newEmail"
              id="new-email"
              required={!contactForm.getFieldValue("newPhone")}
              placeholder="email@domain.com"
              onChange={(e) => setRecipientToAdd({ ...recipientToAdd, newEmail: e.target.value })}
            />
            <PhoneInput
              name="newPhone"
              label="Phone"
              id="new-phone"
              required={!contactForm.getFieldValue("newEmail")}
              placeholder="(555) 555-5555"
              onChange={(val) => setRecipientToAdd({ ...recipientToAdd, newPhone: val })}
            />
          </>
        )}
      </Form>
    </ContactFormContainer>
  );

  const validContactMethods =
    recipientToAdd.employeeOrContact === "employee" && recipientToAdd.staffPhone
      ? "both"
      : recipientToAdd.employeeOrContact === "employee"
      ? "email"
      : recipientToAdd.newEmail && recipientToAdd.newPhone
      ? "both"
      : recipientToAdd.newEmail
      ? "email"
      : "phone";

  const phoneAlertMap = {
    order_unopened: "SMS: Order Left Unopened",
    printer_not_polling: "SMS: Printer Not Polling",
    tablet_not_polling: "SMS: Tablet Not Polling",
    station_disallowed_ordering: "SMS: Tablet Disallowed Ordering",
    robot_not_polling: "SMS: Robot Not Polling",
    robot_problem: "SMS: Robot Problem",
  };

  const emailAlertMap = {
    order_received_email: "Email: Order Received",
    catering_order_unopened: "Email: Order Left Unopened",
    email_catering_order_in: "Email: Order Due",
  };

  const alertMap = { ...phoneAlertMap, ...emailAlertMap };

  const availableAlerts = Object.entries(
    validContactMethods === "both" ? alertMap : validContactMethods === "email" ? emailAlertMap : phoneAlertMap
  ).map(([key, val]) => {
    return { label: val, value: key };
  });

  const changeAlertOption = (alert, index, newValues) => {
    setAlertsToAdd((alertsToAdd) =>
      alertsToAdd.slice(0, index).concat([{ ...alert, ...newValues }].concat(alertsToAdd.slice(index + 1)))
    );
  };

  const removeAlert = (index) => {
    setAlertsToAdd((alertsToAdd) => alertsToAdd.filter((_, i) => i !== index));
  };

  const formatAlerts = (alerts) => {
    return alerts.map((alert, i) => {
      const alertType = alert.alert_class;
      let res = alertMap[alertType];
      return (
        <List.Item key={i}>
          <AlertCard title={res} extra={<TableEditDeleteIcons onDelete={() => removeAlert(i)} />}>
            {alertType === "catering_order_unopened" && (
              <div>
                <div>Alert Trigger Time</div>
                <SelectInput
                  id={"alert-" + i + "-option"}
                  options={[1, 2, 3, 4, 5].map((num) => {
                    return { label: num + " Hours", value: num.toString() };
                  })}
                  defaultValue={alert.hours}
                  onChange={(val) => changeAlertOption(alert, i, { hours: val })}
                />
              </div>
            )}

            {alertType === "email_catering_order_in" && (
              <div>
                <div>Alert Trigger Time</div>
                <SelectInput
                  id={"alert-" + i + "-option"}
                  options={[
                    { label: "4 Hours", value: "4hours" },
                    { label: "30 Minutes", value: "30minutes" },
                  ]}
                  defaultValue={
                    alert.timePicker === "hours" ? alert.hours + alert.timePicker : alert.minutes + alert.timePicker
                  }
                  onChange={(val) =>
                    changeAlertOption(
                      alert,
                      i,
                      val === "4hours"
                        ? { hours: "4", minutes: null, timePicker: "hours" }
                        : { hours: null, minutes: "30", timePicker: "minutes" }
                    )
                  }
                />
              </div>
            )}
            {alertType === "order_unopened" && (
              <div>
                <div>Alert Trigger Time</div>
                <SelectInput
                  id={"alert-" + i + "-option"}
                  options={[2, 3, 5, 10, 15, 20, 30].map((num) => {
                    return { label: num + " Minutes", value: num.toString() };
                  })}
                  defaultValue={alert.minutes}
                  onChange={(val) => changeAlertOption(alert, i, { minutes: val })}
                />
              </div>
            )}

            <div>Stations Affected</div>
            <StationInput
              id={"alert-" + i + "-stations"}
              options={possibleStations.map((station) => {
                return { label: station.station_name, value: station.id };
              })}
              defaultValue={
                alert.stationSetting === "all" ? ["All Stations"] : alert.stations.map((station) => station.id)
              }
              onChange={(val) => {
                changeAlertOption(
                  alert,
                  i,
                  val.includes("All Stations")
                    ? {
                        stations: [],
                        stationSetting: "all",
                      }
                    : {
                        stations: val.map((stationId) => possibleStations.find((station) => stationId === station.id)),
                      }
                );
              }}
              multiSelect
            />
          </AlertCard>
        </List.Item>
      );
    });
  };

  const handleAddAlertType = (alertClass, existingAlert = null) => {
    const alertType = existingAlert?.alert_class || alertClass;
    const res = {
      alert_class: alertType,
      stationSetting: !existingAlert || existingAlert.stations.length === 0 ? "all" : "specific",
      stations: existingAlert ? existingAlert.stations : [],
    };
    if (alertType.startsWith("catering_order_unopened")) {
      res.alert_class = "catering_order_unopened";
      res.hours = existingAlert ? existingAlert.alert_class.charAt(28) : "1"; // I know this is dumb but so is the way this data is stored
    } else if (alertType.startsWith("email_catering_order_in")) {
      res.alert_class = "email_catering_order_in";
      if (existingAlert?.alert_class.charAt(24) === "3") {
        res.minutes = "30";
        res.timePicker = "minutes";
      } else {
        res.hours = existingAlert?.hours || "4";
        res.timePicker = "hours";
      }
    } else if (alertType.startsWith("order_unopened")) {
      res.alert_class = "order_unopened";
      res.minutes = existingAlert ? existingAlert.alert_class.charAt(19) : "5";
      if (!isNaN(parseInt(existingAlert?.alert_class.charAt(20), 10))) {
        // Check if the next digit is a number, and append it if it is
        res.minutes = res.minutes + existingAlert.alert_class.charAt(20);
      }
    }
    setAlertsToAdd((alertsToAdd) => {
      return alertsToAdd.concat([res]);
    });
  };

  const alertsStep = (
    <AlertStepContainer>
      <div>
        <p>
          Step 2: Set up the Alerts you want to send. To set up a new Alert, click the dropdown and select an Alert
          type. <br />
          By default, each new Alert will apply to all stations. To set the alert to a specific station, click the x
          next to 'All Stations' to clear the box. <br />
          When you click into that box again, you'll be able to select individual stations.
        </p>
      </div>
      Add an alert
      <SelectInput
        id="addAlerts"
        options={availableAlerts}
        onChange={(alertType) => {
          handleAddAlertType(alertType);
        }}
      />
      <List>{formatAlerts(alertsToAdd)}</List>
    </AlertStepContainer>
  );

  const confirmationStep = () => {
    if (!Object.keys(recipientToAdd).length > 0 || !alertsToAdd.length > 0) return;
    const isEmployee = recipientToAdd.employeeOrContact === "employee";
    const employee = isEmployee ? employees.find((employee) => employee.user.id === recipientToAdd.employee) : null;
    const name = isEmployee ? employee.user.first_name + " " + employee.user.last_name : recipientToAdd.newName;
    const email = isEmployee ? employee.user.email : recipientToAdd.newEmail;
    const phone =
      isEmployee && recipientToAdd.staffPhone
        ? recipientToAdd.staffPhone
        : !isEmployee && recipientToAdd.newPhone
        ? recipientToAdd.newPhone
        : null;
    return (
      <AlertStepContainer>
        <div className={"margin-bottom-2"}>
          <h3>Please Confirm New Alert Details</h3>
        </div>
        <h4>{name}</h4>
        {email && <h5>{email}</h5>}
        {phone && <h5>{phone}</h5>}
        <List>
          {alertsToAdd.map((alert, i) => (
            <List.Item key={i}>{alertMap[alert.alert_class]}</List.Item>
          ))}
        </List>
      </AlertStepContainer>
    );
  };

  const stepContents = {
    contacts: {
      title: "Recipient",
      content: contactStep,
      form: contactForm,
    },
    alerts: {
      title: "Alerts",
      content: alertsStep,
      disabled: alertsToAdd.length === 0,
    },
    confirm: alertToEdit
      ? null
      : {
          title: "Confirm",
          content: confirmationStep(),
        },
  };

  const userFlowSteps = { addAStaffAlert: ["contacts", "alerts", "confirm"] };

  const onSave = async (editMode) => {
    const isEmployee = recipientToAdd.employeeOrContact === "employee";
    const employee = isEmployee ? employees.find((employee) => employee.user.id === recipientToAdd.employee) : null;
    const properties = {
      alertSettings: "customAlerts",
      createNew: !editMode,
      isCustomer: true,
      name: isEmployee ? employee.user.first_name + " " + employee.user.last_name : recipientToAdd.newName,
      newAlerts: alertsToAdd,
    };
    if (editMode) properties.staffId = alertToEdit.id;
    if (isEmployee) {
      properties.employeeId = employee.user.id;
      properties.email = recipientToAdd.staffEmail;
      if (recipientToAdd.staffPhone) properties.phone = recipientToAdd.staffPhone;
    } else {
      if (recipientToAdd.newEmail) properties.email = recipientToAdd.newEmail;
      if (recipientToAdd.newPhone) properties.phone = recipientToAdd.newPhone;
    }
    await saveSupportAlerts(selectedCustomer, properties);
    onSaveOrEditCallback();
    notification.success({ message: "Staff alert successfully saved." });
  };

  return (
    <PageLoadingSpinner spinning={isLoading}>
      {alertToEdit ? (
        <EditSettingsContainer
          numChanges={1} // This can be deleted once the changes from restaurant details get merged in
          showSave={true}
          tabs={[
            { name: "Recipient", content: contactStep },
            { name: "Alerts", content: alertsStep },
          ]}
          id="edit-staff-alert"
          onSave={() => onSave(true)}
          hideEditFunction={onCancel}
          objectToEditName="Staff Alert"
          selectedNames={[alertToEdit.name]}
        />
      ) : (
        <BbotWizard
          stepContents={stepContents}
          userFlowSteps={userFlowSteps}
          confirmationDescription="Are you sure you'd like to add this staff alert?"
          confirmationTitle="Add this alert?"
          resetFunction={onCancel}
          confirmationFunction={() => onSave(false)}
          stepsForbiddenToReturnTo={["contacts"]}
        />
      )}
    </PageLoadingSpinner>
  );
};

export default AddEditStaffAlert;

const AlertCard = styled(Card)`
  width: 100%;
`;

const AlertStepContainer = styled.div`
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;

const ContactFormContainer = styled.div`
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
`;

const StationInput = styled(SelectInput)`
  width: 100%;
`;
