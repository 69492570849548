import { ENV } from "Config";
import { getAnalytics } from "instrumentation/tracking/analytics";

export const getBrowserData = () => {
  const hasTouchEvents = window ? "ontouchstart" in window : false;
  const innerWidth = window?.innerWidth || 0;
  const innerHeight = window?.innerHeight || 0;
  const userAgent = window?.navigator?.userAgent || 0;

  return {
    href: window?.location.href || "",
    referrer: window?.document.referrer || "",
    platform: hasTouchEvents && innerWidth < 900 ? "mobile" : "desktop",
    browser_width: innerWidth,
    browser_height: innerHeight,
    touch: hasTouchEvents,
    user_agent: userAgent,
  };
};

export const getConsumerIdentified = () => {
  return false;
};

// Track and store custom event using Segment as destination.
// Check Segment configuration for more details:
// https://app.segment.com/doordash/sources/consumer_web/overview
const trackSegment = (eventName, body, context) => {
  if (typeof window === "undefined") {
    return;
  }

  context = { ...context, app: { ...context?.app } };

  const payload = {
    ...body,
    ...getBrowserData(),
    app_version: "",
    is_ssr: window?.is_ssr || false,
  };

  // console.warn events locally
  if (ENV === "localdev") {
    console.warn("::: Tracking Event Fired :::", eventName, { payload, context });
  }

  if (getAnalytics()) {
    getAnalytics().track(eventName, payload, context);
  }
};

export default trackSegment;
