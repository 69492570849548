import React, { useCallback, useEffect, useState, useRef } from "react";
import { InlineChildren, StackChildren, Text } from "@doordash/design-language";
import Separator from "@doordash/component-separator";
import Toggle from "@doordash/component-toggle";
import { getWidgetData, updateWidgetData } from "components/customer-journey/widgets/Widget";
import { generalErrorAlert } from "util/Utils";
import FloorplanManager from "./FloorplanManager";
import { FsFileMetadata } from "components/owner-app/file-upload";
import { AxiosError } from "axios";

const INITIAL_FULFILLMENT_ANSWERS = {
  offers_table_service: false,
  offers_counter_service: false,
  offers_takeout: false,
  offers_delivery: false,
};

export type UpdateTaskStatus = (status: string, options: UpdateTaskStatusOptions) => void;

type UpdateTaskStatusOptions = {
  widgetId?: string;
  goToNext?: boolean;
  forceCompleted?: boolean;
};

type OrderingPageWidgetProps = {
  widget: any;
  updateTaskStatus: UpdateTaskStatus;
  userInfo: any;
};

const OrderingPageWidget: React.FC<OrderingPageWidgetProps> = ({ widget, updateTaskStatus, userInfo }) => {
  const [fulfillmentAnswers, setFulfillmentAnswers] = useState(INITIAL_FULFILLMENT_ANSWERS);
  const [floorplans, setFloorplans] = useState<FsFileMetadata[]>([]);
  const [hasSavedFloorplans, setHasSavedFloorplans] = useState<boolean | undefined>();
  const hasLoadedWidget = useRef(false);

  useEffect(() => {
    if (!widget || hasLoadedWidget.current) {
      return;
    }
    getWidgetData(widget.id).then((res: any) => {
      setFulfillmentAnswers((prev) => ({
        ...prev,
        ...res?.data?.fulfillment_method_answers,
      }));

      const retrievedFloorplans = res?.data?.uploaded_urls ?? [];
      setFloorplans(retrievedFloorplans);
      setHasSavedFloorplans(!!retrievedFloorplans.length);
      hasLoadedWidget.current = true;
    });
  }, [widget]);

  const updateWidgetFloorplans = useCallback(
    async (newFloorplans: FsFileMetadata[], files: FsFileMetadata[], fileAdded: boolean) => {
      try {
        await updateWidgetData({
          widgetId: widget.id,
          widgetData: {
            uploaded_urls: newFloorplans,
          },
        });

        const removedLastFloorplans = !fileAdded && newFloorplans.length === 0;
        const addedFirstFloorplans = fileAdded && newFloorplans.length - files.length === 0;
        if (addedFirstFloorplans || removedLastFloorplans) {
          const newTaskStatus = getTaskStatus(fulfillmentAnswers, newFloorplans);
          updateTaskStatus(newTaskStatus, { widgetId: widget.id, goToNext: false });
        }
      } catch (error: unknown) {
        generalErrorAlert(
          error as AxiosError | Error,
          "Could not save service model info. Please let us know if this keeps happening.",
          true
        );
        setFloorplans((prev) => {
          if (fileAdded) {
            const fileHandleLookup = new Set(files.map((file) => file.handle));
            return prev.filter(({ handle }) => !fileHandleLookup.has(handle));
          }
          return [...prev, ...files];
        });
      }
    },
    [widget, fulfillmentAnswers, updateTaskStatus]
  );

  const addFiles = useCallback(
    (files: FsFileMetadata[]) => {
      setFloorplans((prev) => {
        const newFloorplans = [...prev, ...files];
        updateWidgetFloorplans(newFloorplans, files, true);
        return newFloorplans;
      });
    },
    [updateWidgetFloorplans]
  );

  const removeFile = useCallback(
    (file: FsFileMetadata) => {
      setFloorplans((prev) => {
        const newFloorplans = prev.filter(({ handle }) => handle !== file.handle);
        updateWidgetFloorplans(newFloorplans, [file], false);
        return newFloorplans;
      });
    },
    [updateWidgetFloorplans]
  );

  const isAdmin = userInfo?.role === "admin";

  const updateAnswer = async (fulfillmentType: keyof typeof fulfillmentAnswers, answer: boolean) => {
    const newFulfillmentAnswers = {
      ...fulfillmentAnswers,
      [fulfillmentType]: answer,
    };

    setFulfillmentAnswers(() => newFulfillmentAnswers);

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          fulfillment_method_answers: newFulfillmentAnswers,
        },
      });
      const newTaskStatus = getTaskStatus(newFulfillmentAnswers, floorplans);
      updateTaskStatus(newTaskStatus, { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as AxiosError | Error,
        "Could not save service model info. Please let us know if this keeps happening.",
        true
      );
      setFulfillmentAnswers((prev) => ({
        ...prev,
        [fulfillmentType]: !answer,
      }));
    }
  };

  return (
    <div className="margin-bottom-2">
      <StackChildren size={StackChildren.Sizes.Large}>
        <FulfillmentAnswer
          title="Do you offer delivery?"
          subtitle="Guests have their food delivered by your drivers or a third party integration like DoorDash Drive or Relay."
          isOn={fulfillmentAnswers.offers_delivery}
          onChange={(answer) => updateAnswer("offers_delivery", answer)}
        />
        <Separator type={Separator.Types.FullBorder} />
        <FulfillmentAnswer
          title="Do you offer takeout?"
          subtitle="Guests take their food to go."
          isOn={fulfillmentAnswers.offers_takeout}
          onChange={(answer) => updateAnswer("offers_takeout", answer)}
        />
        <Separator type={Separator.Types.FullBorder} />
        <FulfillmentAnswer
          title="Do you have waitstaff?"
          subtitle="Staff members deliver orders to a seat, table, room, etc."
          isOn={fulfillmentAnswers.offers_table_service}
          onChange={(answer) => updateAnswer("offers_table_service", answer)}
        >
          <FloorplanManager
            key={String(hasSavedFloorplans)}
            files={floorplans}
            onSave={addFiles}
            removeFile={removeFile}
            isAdmin={isAdmin}
          />
        </FulfillmentAnswer>
        <Separator type={Separator.Types.FullBorder} />
        <FulfillmentAnswer
          title="Do you offer counter service?"
          subtitle="Guests are seated at a table and pick up their orders at a station when ready."
          isOn={fulfillmentAnswers.offers_counter_service}
          onChange={(answer) => updateAnswer("offers_counter_service", answer)}
        />
      </StackChildren>
    </div>
  );
};

const getTaskStatus = (fulfillmentAnswers: typeof INITIAL_FULFILLMENT_ANSWERS, floorplans: FsFileMetadata[]) => {
  const hasAnswered = Object.values(fulfillmentAnswers).some(Boolean);
  const hasTableSeviceWithoutFloorplan = fulfillmentAnswers.offers_table_service && !floorplans.length;

  if (!hasAnswered || hasTableSeviceWithoutFloorplan) {
    return "in_progress";
  }

  return "completed";
};

type FulfillmentAnswerProps = {
  title: string;
  subtitle: string;
  onChange: (isSelected: boolean) => void;
  isOn: boolean;
};

const FulfillmentAnswer: React.FC<FulfillmentAnswerProps> = ({ title, subtitle, onChange, isOn = false, children }) => {
  return (
    <StackChildren size={StackChildren.Sizes.Large}>
      <StackChildren size={StackChildren.Sizes.XxxSmall}>
        <InlineChildren alignItems={InlineChildren.Alignment.Center}>
          <Text tag="h2" styles={Text.Styles.Title1}>
            {title}
          </Text>
          <Toggle
            type={Toggle.Types.Toggle}
            onChange={(isSelected: boolean) => onChange(isSelected)}
            isSelected={isOn}
            label={title}
            isLabelHidden
            justifyToggle={Toggle.Justification.End}
          />
        </InlineChildren>
        <Text styles={Text.Styles.Body2}>{subtitle}</Text>
      </StackChildren>
      {isOn && children}
    </StackChildren>
  );
};

export default OrderingPageWidget;
