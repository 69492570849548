import React, { useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import { toast } from "react-toastify";
import { ENV } from "Config";
import Button from "@doordash/component-button";
import { useToast } from "@doordash/component-toast";
import { Icon, Inset, StackChildren } from "@doordash/design-language";
import Banner from "@doordash/component-banner";
import { generalErrorAlert } from "util/Utils";
import { PLAID_SUPPORTED_COUNTRIES } from "Constants";

const PlaidLink = ({ customerId, customerCountryCode, resetAddPaymentForm, onSuccessCallback, billpayerExists }) => {
  const [isSavingBankAccount, setIsSavingBankAccount] = useState(false);
  const [token, setToken] = useState(null);
  const { displayToast } = useToast();
  const [linkError, setLinkError] = useState("");

  const createLinkToken = useCallback(async () => {
    try {
      const res = await axios.post("/api/plaidCreateLinkToken", {
        config: {
          client_name: "Bbot ACH",
          country_codes: Object.keys(PLAID_SUPPORTED_COUNTRIES).includes(customerCountryCode)
            ? [customerCountryCode]
            : Object.keys(PLAID_SUPPORTED_COUNTRIES),
          env: ENV === "prod" ? "production" : "sandbox", // switch to production when env equals "prod"
          language: "en",
          products: ["auth"],
          select_account: true,
          user: customerId,
        },
      });
      setToken(res.data.link_token);
    } catch (err) {
      generalErrorAlert(err, "Error initiating plaid link", true, customerId);
      setLinkError("Error initiating Plaid. Please refresh the page. ");
    }
  }, [customerId, customerCountryCode]);

  useEffect(() => {
    createLinkToken();
  }, [createLinkToken]);

  const onSuccess = async (publicToken, metadata) => {
    setIsSavingBankAccount(true);
    try {
      await axios.post("/api/linkBankWithPlaid", {
        public_token: publicToken,
        account_id: metadata.account_id,
        customer_id: customerId,
      });
      toast.success("Successfully linked bank account with Plaid.");
      if (onSuccessCallback) onSuccessCallback(); // call the callback func if exists
      resetAddPaymentForm();
    } catch (err) {
      const res = err.response;

      generalErrorAlert(err, "Error linking with Plaid.", true, customerId);
      if (res?.data?.errorCode) {
        setLinkError("Error linking with Plaid: " + res.data.errorCode);
      }
    } finally {
      setIsSavingBankAccount(false);
    }
  };

  const config = {
    token,
    onSuccess: onSuccess,
    onExit: (err, metadata) => {
      setIsSavingBankAccount(false);
      if (!!err) {
        setLinkError("Error linking with Plaid: " + err.error_message);
        if (err.error_code === "INVALID_LINK_TOKEN") {
          createLinkToken();
        }
      }
    },
  };

  const { open } = usePlaidLink(config);

  return (
    <StackChildren>
      {linkError && !isSavingBankAccount && <Banner label={linkError} variant={Banner.Variants.Negative} />}

      <Button
        isInline
        data-test-id="connect-with-plaid-button"
        trailingIcon={Icon.Types.LinkExternalLine}
        state={isSavingBankAccount ? Button.States.Loading : Button.States.Default}
        onClick={() => {
          // If no billpayer, show toast, return early.
          if (!billpayerExists) {
            displayToast({
              icon: Icon.Types.ErrorLine,
              text: "Please save a valid billing email first.",
              insetFromEdge: Inset.Sizes.Medium,
            });
            return;
          }

          setLinkError("");
          open();
        }}
      >
        Continue to Plaid
      </Button>
    </StackChildren>
  );
};

export default PlaidLink;
