import { Form, Input } from "bbot-component-library";
import React from "react";
import styled from "styled-components";

const TextInput = ({
  id,
  label = "",
  name = "",
  required = true,
  generalValidationMessage, // used for required validation
  regexValidationMessage, // used for regex validation
  maxLength = null,
  minLength = null,
  regex = null,
  placeholder,
  dataTestId = null,
  ...props
}) => {
  let lengthValidations = maxLength
    ? [
        {
          max: maxLength,
          message: `Must be less than ${maxLength} characters`,
        },
      ]
    : [];
  lengthValidations = lengthValidations.concat(
    minLength
      ? [
          {
            min: minLength,
            message: `Must be greater than ${minLength} characters`,
          },
        ]
      : []
  );

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: generalValidationMessage || "This is a required field",
          transform: (value) => value?.trim(),
        },
        {
          message: regexValidationMessage || "This is not a valid value",
          pattern: regex,
        },
      ].concat(lengthValidations)}
      validateTrigger={["onBlur", "onChange"]}
      data-test-id={dataTestId}
    >
      <InputStyled id={id} placeholder={placeholder} type={"text"} {...props} />
    </Form.Item>
  );
};

const InputStyled = styled(Input)`
  padding: 8px 14px;
  border-radius: 8px;

  &&&.ant-input-group-wrapper {
    padding: 0 0;
  }

  .ant-input-group-addon:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-input-group-addon:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export default TextInput;
