// Components

import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SalesCommissionCard from "components/owner-app/cards/sales-commission-card/SalesCommissionCard";
import SalesPersonTable from "components/owner-app/tables/sales-person-table/SalesPersonTable";
import CreateEditSalesPersonModal from "components/owner-app/modals/create-edit-sales-person-modal/CreateEditSalesPersonModal";
import CreateEditSalesCommissionModal from "components/owner-app/modals/create-edit-sales-commission-modal/CreateEditSalesCommissionModal";

// Styles
import "./SalesCommission.scss";

class SalesCommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesPersons: [],
      salesCommissions: [],
      selectedCustomer: null,
      selectedReps: [],
      selectedCommission: null,
      showSalesCommissionCreateEditModal: false,
      salesCommissionModalMode: null,
      showSalesRepCreateEditModal: false,
      salesRepModalMode: null,
      firstSaaSBillDate: null,
      hasSaaSBilling: false,
    };
  }

  componentDidMount = async () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer) {
      return;
    }

    await this.getSalesPersons();
    await this.getSalesCommissions();
    await this.getFirstSaaSBillDate();
  };

  componentWillUnmount = () => {};

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      await this.getSalesPersons();
      await this.getSalesCommissions();
      await this.getFirstSaaSBillDate();
    }
  };

  getSalesCommissions = async () => {
    const { selectedCustomer } = this.props;
    try {
      const res = await axios.get("/admin/getAllSalesCommissionsForCustomer", {
        params: {
          customer_id: selectedCustomer?.customer_id,
        },
      });
      this.setState({
        salesCommissions: res.data,
      });
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(
        "There was an error fetching all sales commissions. Please refresh the page.\n" + error.response.data.message
      );
    }
  };

  getSalesPersons = async () => {
    try {
      const res = await axios.get("/admin/getAllSalesPersons", {
        params: {},
      });
      this.setState({ salesPersons: res.data });
    } catch (error) {
      toast.error(
        "There was an error fetching all sales people. Please refresh the page.\n" + error.response.data.message
      );
      console.error(error.response.data.message);
    }
  };

  getFirstSaaSBillDate = async () => {
    const { selectedCustomer } = this.props;
    try {
      const res = await axios.get("/admin/getFirstSaasBillDueDate", {
        params: {
          customer_id: selectedCustomer?.customer_id,
        },
      });
      this.setState({
        firstSaaSBillDate: res.data.due_date,
        hasSaaSBilling: res.data.has_saas_billing,
      });
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  createEditSalesPerson = async (payload) => {
    let endpoint = this.state.salesRepModalMode === "create" ? "/admin/createSalesPerson" : "/admin/editSalesPerson";
    if (this.state.salesRepModalMode === "edit") {
      payload["id"] = this.state.selectedReps[0].id;
    }
    try {
      await axios.post(endpoint, payload);
      await this.getSalesPersons();
      toast.success(
        "Successfully " + (this.state.salesRepModalMode === "create" ? "created" : "edited") + " sales rep."
      );
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(
        "There was an error " +
          (this.state.salesRepModalMode === "create" ? "creating" : "editing") +
          " the sales rep. Please refresh the page.\n" +
          error.response.data.message
      );
    }

    this.setState({
      showSalesRepCreateEditModal: false,
      salesRepModalMode: null,
      selectedReps: [],
    });
  };

  createEditSalesCommission = async (payload) => {
    let endpoint =
      this.state.salesCommissionModalMode === "create" ? "/admin/createSalesCommission" : "/admin/editSalesCommission";
    if (this.state.salesCommissionModalMode === "edit") {
      payload["sales_commission_id"] = this.state.selectedCommission.id;
    }
    try {
      await axios.post(endpoint, payload);

      await this.getSalesCommissions();
      toast.success(
        "Successfully " +
          (this.state.salesCommissionModalMode === "create" ? "created" : "edited") +
          " sales commission."
      );
      this.setState({
        showSalesCommissionCreateEditModal: false,
        salesCommissionModalMode: null,
      });
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(
        "There was an error " +
          (this.state.salesCommissionModalMode === "create" ? "creating" : "editing") +
          " the sales commission. Please refresh the page."
      );
      this.setState({
        showSalesCommissionCreateEditModal: false,
        salesCommissionModalMode: null,
      });
    }
  };

  deleteSalesRep = async () => {
    try {
      await axios.post("/admin/deleteSalesPerson", {
        id: this.state.selectedReps[0].id,
      });
      await this.getSalesPersons();
      this.setState({ selectedReps: [] });
      toast.success("Successfully deleted sales rep.");
    } catch (error) {
      toast.error("There was an error deleting sales rep. Please refresh the page. \n" + error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  render() {
    const { salesPersons, salesCommissions } = this.state;
    const { selectedCustomer } = this.props;
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-row margin-bottom-4">
                <span>
                  <Link to="/" className="breadcrumb-back">
                    &#60; Back
                  </Link>
                </span>
                <span className="breadcrumbs">
                  <span>
                    <Link to="/" className="breadcrumb-link">
                      Owner Portal
                    </Link>
                  </span>
                  <span className="breadcrumb-link">&#62;</span>
                  <span>
                    <Link to="/sales-commission" className="breadcrumb-link">
                      Sales Rep Commissions
                    </Link>
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="margin-bottom-4">
                <h2>
                  Manage Commission For <span className="color-primary__dark">{selectedCustomer?.customer_name}</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            {salesCommissions.map((commission, index) => (
              <Col key={"sale-commission-card-" + index} lg={4} sm={6} className={"margin-bottom-2"}>
                <SalesCommissionCard
                  salesCommission={commission}
                  editSalesPerson={() => {
                    this.setState({
                      showSalesCommissionCreateEditModal: true,
                      salesCommissionModalMode: "edit",
                      selectedCommission: commission,
                    });
                  }}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className={"margin-top-2"}>
              <Button
                size={"lg"}
                className={"add-sales-commission-btn margin-right-2"}
                onClick={() =>
                  this.setState({
                    showSalesCommissionCreateEditModal: true,
                    salesCommissionModalMode: "create",
                  })
                }
              >
                Add New Sales Commission
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="margin-bottom-4 margin-top-4">
                <h2>Manage All Sales Persons</h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <SalesPersonTable
                data={salesPersons}
                selectedReps={this.state.selectedReps}
                handleDelete={() => {
                  this.deleteSalesRep();
                }}
                handleEdit={() => {
                  this.setState({
                    showSalesRepCreateEditModal: true,
                    salesRepModalMode: "edit",
                  });
                }}
                handleSelectRow={(data) => {
                  this.setState({ selectedReps: [data] });
                }}
                addSalesPerson={() => {
                  this.setState({
                    showSalesRepCreateEditModal: true,
                    salesRepModalMode: "create",
                  });
                }}
              />
            </Col>
          </Row>
        </Container>
        <CreateEditSalesPersonModal
          show={this.state.showSalesRepCreateEditModal}
          mode={this.state.salesRepModalMode}
          selectedRep={this.state.selectedReps[0]}
          onHide={() => this.setState({ showSalesRepCreateEditModal: false })}
          saveCallback={(payload) => {
            this.createEditSalesPerson(payload);
          }}
        />
        <CreateEditSalesCommissionModal
          show={this.state.showSalesCommissionCreateEditModal}
          mode={this.state.salesCommissionModalMode}
          onHide={() => this.setState({ showSalesCommissionCreateEditModal: false })}
          selectedCustomerId={selectedCustomer?.customer_id}
          salesReps={salesPersons}
          selectedCommission={this.state.selectedCommission}
          firstSaaSBillDate={this.state.firstSaaSBillDate}
          hasSaaSBilling={this.state.hasSaaSBilling}
          saveCallback={(payload) => {
            this.createEditSalesCommission(payload);
          }}
        />
      </div>
    );
  }
}

export default SalesCommission;
