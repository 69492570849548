import React, { useState } from "react";
import styled from "styled-components";
import Button from "@doordash/component-button";
import { Confirm } from "@doordash/component-modal";
import { Colors } from "@doordash/design-language";

const MarkTaskCompletedButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onConfirm = async () => {
    setIsLoading(true);
    await onClick();
    setShowConfirmation(false);
    setIsLoading(false);
  };

  return (
    <>
      <ButtonDangerTertiary>
        <Button
          data-test-id="force-complete-btn"
          state={isLoading ? Button.States.Loading : Button.States.Default}
          isDisabled={isLoading}
          isInline
          type={Button.Types.Tertiary}
          onClick={() => setShowConfirmation(true)}
        >
          Mark as Completed
        </Button>
      </ButtonDangerTertiary>
      {showConfirmation && (
        <Confirm
          title="Are you sure you want to complete this task?"
          subtitle="Marking this task as completed will mark all parts of it as completed."
          dismissText="Cancel"
          onDismiss={() => setShowConfirmation(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

const ButtonDangerTertiary = styled.div`
  button,
  button:hover {
    box-shadow: 0 0 0 1px ${Colors.GlobalPrimary} inset;
  }
`;

export default MarkTaskCompletedButton;
