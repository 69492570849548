// @ts-ignore
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Hooks
import { useGoogleMapsApi } from "hooks/useGoogleMapsApi";

// Components
import { Input, Spin, Form } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Constants

const AddressInput = ({
  allowClear,
  id,
  name,
  onAddressObjectChange,
  onFocusOut,
  onQueryChange,
  placeholder,
  testId,
  initialValue = "",
  label,
  required,
}) => {
  const [debounceHandler, setDebounceHandler] = useState(null);
  const [query, setQuery] = useState("");
  const [addressObject, setAddressObject] = useState({});
  const [focused, setFocused] = useState(false);
  const [loadingPredictions, setLoadingPredictions] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const wrapperRef = useRef(null);
  const GoogleMapsAPI = useGoogleMapsApi();

  useEffect(() => {
    setQuery(initialValue?.google_place?.formatted_address ? initialValue.google_place.formatted_address : "");
    setAddressObject(initialValue?.google_place ? initialValue.google_place : {});
  }, [initialValue]);

  const handleSearchPredictions = async (value) => {
    if (value.length > 0) {
      const predictionList = await GoogleMapsAPI.getAutoCompletePredictions(value);
      setPredictions(predictionList);
    }
    setLoadingPredictions(false);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const value = event.target.value ? event.target.value : null;

    if (onQueryChange) onQueryChange(event.target.value);
    if (value) {
      if (debounceHandler) clearTimeout(debounceHandler);
      setLoadingPredictions(true);
      const handler = setTimeout(() => {
        handleSearchPredictions(value);
      }, 1000);
      setDebounceHandler(handler);
    }
  };

  const handlePredictionsDetails = async (prediction) => {
    try {
      setFocused(false);
      const placeDetails = await GoogleMapsAPI.getPlaceDetails(prediction.place_id);
      setQuery(placeDetails.formatted_address);
      if (onQueryChange) onQueryChange(placeDetails.formatted_address);
      setPredictions([prediction]);
      setAddressObject(placeDetails);
      onAddressObjectChange(placeDetails);
    } catch (error) {
      console.error(error);
    }
  };

  // Detect clicking outside of the input or the list of predictions
  useEffect(() => {
    function handleFocusOutside(event) {
      // If the user clicks outside of the input or prediction window and the input is invalid clear it
      if (
        !(event.target.id === id) &&
        !event.target.id.startsWith(id + "-prediction") &&
        query !== addressObject?.formatted_address &&
        (query || Object.keys(addressObject).length > 0)
      ) {
        setQuery("");
        setAddressObject({});
        onAddressObjectChange(null);
      }
    }
    // Detect if the user clicks or tabs out of the component
    document.addEventListener("mousedown", handleFocusOutside);
    document.addEventListener("keyup", handleFocusOutside);

    return () => {
      document.removeEventListener("mousedown", handleFocusOutside);
      document.removeEventListener("keyup", handleFocusOutside);
    };
  }, [wrapperRef, onFocusOut, query, addressObject, onAddressObjectChange, id]);

  const showPredictionsContainer = loadingPredictions || (focused && query.length > 0);

  return (
    <AddressInput.Container ref={wrapperRef}>
      <Form.Item label={label} name={name} required={required}>
        <AddressInput.Input
          id={id || name}
          testid={testId}
          allowClear={allowClear}
          autoComplete={"none"}
          showpredictions={showPredictionsContainer ? 1 : 0}
          onChange={handleQueryChange}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          value={query}
        />
        {showPredictionsContainer && (
          <AddressInput.PredictionsList id={id + "-prediction"}>
            {!loadingPredictions &&
              predictions?.length &&
              predictions.map((prediction, index) => (
                <AddressInput.PredictionsButton
                  key={"predictions-index-" + index}
                  onClick={() => handlePredictionsDetails(prediction)}
                  id={id + "-prediction-" + index}
                >
                  {prediction.description}
                </AddressInput.PredictionsButton>
              ))}
            {loadingPredictions && (
              <AddressInput.Loader>
                <Spin tip={"Searching Addresses..."} indicator={<LoadingOutlined spin />} />
              </AddressInput.Loader>
            )}
          </AddressInput.PredictionsList>
        )}
      </Form.Item>
    </AddressInput.Container>
  );
};

export default AddressInput;

AddressInput.Container = styled.div`
  position: relative;
`;

AddressInput.Input = styled(Input)`
  padding: 8px 14px;
`;

AddressInput.PredictionsList = styled.ul`
  -webkit-padding-start: 0;
  color: var(--color-neutral__body);
  background-color: var(--color-neutral__page-background);
  border-radius: 0 0 8px 8px;
  border: solid 1px var(--color-neutral__line);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-inline-start: 0;
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  z-index: 100;
  max-width: 400px;
`;

AddressInput.PredictionsButton = styled.button`
  font-size: 0.75rem;
  outline: none;
  padding: 0.75rem 11px;
  text-align: left;
  background-color: var(--color-neutral__card-background);
  border-width: 0;
  &:nth-child(even) {
    background-color: var(--color-neutral__accent-background);
  }

  &:hover {
    background-color: var(--color-neutral__accent-background);
  }

  &:focus {
    border: solid 1px var(--color-primary__regular);
  }
`;

AddressInput.Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  .ant-spin {
    color: var(--color-primary__regular);
  }

  .ant-spin-text {
    color: var(--color-neutral__title-active);
    margin-top: 0.75rem;
  }
`;

AddressInput.propTypes = {
  id: PropTypes.string.isRequired,
  allowClear: PropTypes.bool,
  name: PropTypes.string,
  onAddressObjectChange: PropTypes.func,
  onFocusOut: PropTypes.func,
  onQueryChange: PropTypes.func,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  initialValue: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
};
