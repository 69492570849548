import React from "react";
import { Col, Modal, Button, Form as Btpform } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "react-moment";

import { Formik, Form } from "formik";
import * as yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import { NumberInput, SelectInput } from "components/global/form";

class CreateEditSalesCommissionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateError: false,
    };
  }

  validatePercent = (value) => {
    var error;
    if (value < 0 || value > 99) {
      error = "Input a percentage between 0 and 99. ";
    }
    return error;
  };

  validateDates = (values) => {
    let isError = values.start_date >= values.end_date;
    this.setState({ dateError: isError });
  };

  render() {
    const { show, mode, onHide, selectedCustomerId, salesReps, selectedCommission, firstSaaSBillDate, hasSaaSBilling } =
      this.props;
    const startDate = new Date(firstSaaSBillDate);
    const endDate = new Date(firstSaaSBillDate);
    endDate.setMonth(endDate.getFullYear() + 1);
    const initialValues =
      mode === "create"
        ? {
            salesperson_id: salesReps[0]?.id,
            customer_id: selectedCustomerId,
            start_date: startDate,
            end_date: endDate,
            fraction_of_transactions: salesReps[0]?.default_transaction_commission * 100,
            fraction_of_saas: salesReps[0]?.default_saas_commission * 100,
          }
        : {
            salesperson_id: selectedCommission?.salesperson, //  change to selectedCommission.id once serializer is updated.
            customer_id: selectedCustomerId,
            start_date: new Date(selectedCommission?.start_date),
            end_date: new Date(selectedCommission?.end_date),
            fraction_of_transactions: selectedCommission?.fraction_of_transactions * 100,
            fraction_of_saas: selectedCommission?.fraction_of_saas * 100,
          };
    const validationSchema = yup.object({
      salesperson_id: yup.string(),
      start_date: yup.date(),
      end_date: yup.date(),
      fraction_of_transactions: yup.number().min(0).max(99).required(),
      fraction_of_saas: yup.number().min(0).max(99).required(),
    });
    return (
      <Modal
        // size="lg"
        show={show}
        onHide={onHide}
        dialogClassName={"create-new-sales-commission-modal"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            // Set all dates to 4 AM EST
            values.start_date.setHours(4, 0, 0, 0);
            values.end_date.setHours(4, 0, 0, 0);
            values.fraction_of_transactions /= 100;
            values.fraction_of_saas /= 100;

            this.props.saveCallback(values);
          }}
        >
          {({ values, errors, touched, isValidating }) => (
            <Form className={"styled-form"}>
              <Modal.Header className={"padding-top-3"} closeButton>
                <Modal.Title>{mode === "create" ? "Create New " : "Edit "}Sales Commission</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-y-2"}>
                <Btpform.Row className={"margin-bottom-2"}>
                  <SelectInput name="salesperson_id" id={"salesperson_id"} label={"Sales Rep"} sm={12}>
                    {salesReps.map((rep, index) => (
                      <option key={rep.id} value={rep.id}>
                        {rep.name}
                      </option>
                    ))}
                  </SelectInput>
                </Btpform.Row>

                <Btpform.Row className={"margin-bottom-2"}>
                  <NumberInput
                    id="default-trans-fraction"
                    name="fraction_of_transactions"
                    label={"Transaction Percentage"}
                    sm={12}
                  />
                </Btpform.Row>
                <Btpform.Row className={"margin-bottom-2"}>
                  <NumberInput
                    name={"fraction_of_saas"}
                    id={"default-saas-fraction"}
                    label={"Saas Percentage"}
                    sm={12}
                  />
                </Btpform.Row>
                <Btpform.Row className={"margin-bottom-2"}>
                  <Col sm={6} className="">
                    <div className="field-wrapper">
                      <div className={"text-input-label margin-bottom-1"}>Start Date</div>
                      <DatePicker
                        id="start-date"
                        name="start_date"
                        selected={values.start_date}
                        onChange={(val) => {
                          this.setState({ start_date: val });
                          values.start_date = val;
                          this.validateDates(values);
                        }}
                      />
                      {hasSaaSBilling ? (
                        <div className={"supporting-text margin-top-1 margin-left-1"}>
                          SaaS billing at this customer began <Moment format="MM/DD/YYYY">{startDate}</Moment>
                        </div>
                      ) : (
                        <div className={"supporting-text margin-top-1 margin-left-1"}>
                          No SaaS bills exist yet for this customer
                        </div>
                      )}
                      <div className={"supporting-text margin-top-1 margin-left-1"}>
                        {!values.start_date ||
                          (values.start_date !== initialValues.start_date &&
                            values.start_date < new Date() &&
                            values.start_date.getMonth() < new Date().getMonth() &&
                            "WARNING: Editing this commission will change the financial report for previous months. If you don’t want your change to be retroactive, create a new commission instead.")}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} className="">
                    <div className="field-wrapper">
                      <div className={"text-input-label margin-bottom-1"}>End Date</div>
                      <DatePicker
                        id="end-date"
                        name="end_date"
                        selected={values.end_date}
                        onChange={(val) => {
                          this.setState({ end_date: val });
                          values.end_date = val;
                          this.validateDates(values);
                        }}
                      />
                      {this.state.dateError && <div className="error">End date must be after start date.</div>}
                    </div>
                  </Col>
                </Btpform.Row>
              </Modal.Body>
              <Modal.Footer>
                <div className="actions">
                  <Button variant="tertiary" size={"sm"} onClick={onHide}>
                    Cancel
                  </Button>
                  <Button
                    className="margin-left-2"
                    variant="primary"
                    type="submit"
                    size={"sm"}
                    disabled={Object.keys(errors).length || this.state.dateError}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateEditSalesCommissionModal;
