import React, { useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { CheckboxInput, TextInput } from "components/global/form";
import { NumberInput, SelectInput } from "components/global/form";
import { generalErrorAlert } from "util/Utils";
import RadioGroupInput from "components/global/form/RadioGroupInput";
import { BbotTag } from "bbot-component-library";

const EditStationModal = (props) => {
  const {
    userInfo,
    selectedCustomer,
    showEditStationModal,
    selectedStation,
    onHideCallback,
    onSaveCallback,
    fulfillmentMethodsPretty,
  } = props;

  const [isSaving, setIsSaving] = useState(false);

  const isAdmin = userInfo?.role === "admin";

  const saveStation = async (values) => {
    setIsSaving(true);
    try {
      const payload = {
        customer_id: selectedCustomer?.customer_id,
        station_id: selectedStation?.id,
        ...values,
      };
      await axios.post("/owner/saveStation/", payload);
      toast.success(selectedStation.station_name + " has been successfully updated.");
      onSaveCallback();
      onHideCallback();
    } catch (error) {
      generalErrorAlert(error, "There was an error saving the station info. Please refresh and try again.");
    }
    setIsSaving(false);
  };

  const overridePollSettings = async (pollSettings) => {
    const payload = {
      station_id: selectedStation?.id,
      pollSettings: pollSettings,
    };

    try {
      await axios.post("/admin/overridePollSettings", payload);
      onSaveCallback();
      toast.success("Tablet poll settings changed successfully.");
    } catch (error) {
      generalErrorAlert(error, "Error changing poll settings, please try again");
    }
  };

  const forceTabletStatus = async (newStatus) => {
    const payload = {
      stationId: selectedStation?.id,
      newStatus: newStatus,
    };

    try {
      await axios.post("/admin/forceTabletStatus", payload);
      onSaveCallback();
      toast.success("Tablet Status changed successfully.");
    } catch (error) {
      generalErrorAlert(error, "Error changing tablet status, please try again");
    }
  };

  return (
    <Modal className={"modal modal-l"} show={showEditStationModal} onHide={onHideCallback}>
      <Formik
        enableReinitialize
        initialValues={{
          print_on_checkout: selectedStation?.print_on_checkout,
          refund_requires_pin: selectedStation?.refund_requires_pin,
          may_change_fulfillment_method: selectedStation?.may_change_fulfillment_method,
          selected_fulfillment_methods: selectedStation?.selected_fulfillment_methods,
          possible_fulfillment_methods: selectedStation?.possible_fulfillment_methods,
          default_menuitem_class: selectedStation?.default_menuitem_class,
          spillover_priority: selectedStation?.spillover_priority,
          auto_snooze: selectedStation?.auto_snooze,
          max_queue_before_spillover: selectedStation?.max_queue_before_spillover,
          message_when_ordering_paused: selectedStation?.message_when_ordering_paused,
          order_allowed: selectedStation?.order_allowed,
          polling_behavior: selectedStation?.accept_orders_offline
            ? "alwaysAccept"
            : !selectedStation?.accept_orders_offline && selectedStation?.seconds_since_poll < 0
            ? "alwaysAcceptUntilPoll"
            : "default",
        }}
        validationSchema={Yup.object({
          spillover_priority: Yup.number().required(),
          auto_snooze: Yup.number().required(),
          max_queue_before_spillover: Yup.number().required(),
          message_when_ordering_paused: Yup.string().required(),
        })}
        onSubmit={saveStation}
      >
        {({ values, errors, touched, isValidating, setFieldValue }) => (
          <Form className={"styled-form"}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedStation?.station_name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <div className={"styled-form"}>
                  <div className={"field-wrapper margin-y-2"}>
                    <div className={"field-label"}>{`Version ${selectedStation?.apk.lastAppVersionInstalled}`}</div>
                    <div className={"text-small-detail"}>{`Goal Version ${selectedStation?.apk.goalAppVersion}`}</div>
                  </div>
                  <div className={"field-wrapper margin-y-2"}>
                    <div className={"field-label"}>Attached Printers </div>
                    <div className={"text-small-detail"}>
                      {selectedStation?.printers.length
                        ? selectedStation.printers.map((printer) => printer.name).join(", ")
                        : "None"}
                    </div>
                  </div>

                  <hr />

                  <div className={"field-label"}>Misc. Settings</div>
                  <div className={"margin-y-2"}>
                    <CheckboxInput
                      label={"Print When User Reaches Checkout"}
                      name={"print_on_checkout"}
                      id={"prints-receipts"}
                    />
                  </div>
                  <div className={"margin-y-2"}>
                    <CheckboxInput label={"Require PIN on Refunds"} name={"refund_requires_pin"} id={"refund-pin"} />
                  </div>
                  {isAdmin && (
                    <div className={"margin-y-2"}>
                      <CheckboxInput
                        label={"May Change Fulfillment Method"}
                        name={"may_change_fulfillment_method"}
                        id={"may_change_fulfillment_method"}
                      />
                    </div>
                  )}

                  {isAdmin && (
                    <div className={"field-wrapper margin-y-2"}>
                      <div className={"field-label margin-bottom-1"}>
                        <span>Selected Fulfilment Methods</span>
                        <BbotTag className={"margin-x-1"}>Admin</BbotTag>
                      </div>
                      <div className={"form-group col"}>
                        {Object.entries(fulfillmentMethodsPretty).map(([key, value]) => (
                          <div key={key}>
                            <label>
                              <Field type="checkbox" name="selected_fulfillment_methods" value={key} />
                              {value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className={"field-wrapper margin-y-2"}>
                    <div className={"field-label margin-bottom-1"}>Possible Fulfilment Methods</div>
                    <div className={"form-group col"}>
                      {Object.entries(fulfillmentMethodsPretty).map(([key, value]) => (
                        <div key={key}>
                          <label>
                            <Field type="checkbox" name="possible_fulfillment_methods" value={key} />
                            {value}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={"margin-y-2"}>
                    <SelectInput
                      className={"padding-x-0"}
                      name={"default_menuitem_class"}
                      id={"default-item-class"}
                      label={"Default Item Class"}
                    >
                      <option key={"food"} value={"food"}>
                        Food
                      </option>
                      <option key={"drink"} value={"drink"}>
                        Drink
                      </option>
                      <option key={"all"} value={"all"}>
                        All
                      </option>
                    </SelectInput>
                  </div>
                  <Row className="margin-bottom-1">
                    <NumberInput
                      name={"spillover_priority"}
                      id={"spillover-priority"}
                      label={"Spillover Priority"}
                      md={6}
                    />
                    <NumberInput name={"auto_snooze"} id={"auto-snooze"} label={"Auto Snooze"} md={6} />
                  </Row>
                  <Row className="margin-bottom-1">
                    <NumberInput
                      name={"max_queue_before_spillover"}
                      id={"max-spillover-queue"}
                      label={"Max Queue Before Spillover"}
                      md={12}
                    />
                  </Row>
                  <Row className="margin-bottom-1">
                    <TextInput
                      name={"message_when_ordering_paused"}
                      id={"ordering-paused-msg"}
                      label={"Message when Ordering is Paused"}
                      md={12}
                    />
                  </Row>

                  <div className={"field-wrapper margin-bottom-2"}>
                    <div className={"text-small-detail"}>
                      This message will be shown when ordering is paused. You can use the placeholders $TIME (time of
                      day ordering will resume) and $MINUTES (number of minutes before ordering will resume) in your
                      message.
                    </div>
                  </div>

                  <hr />

                  <div className={"field-wrapper margin-y-2"}>
                    <div className={"field-label margin-y-1"}>Tablet Accepting Orders</div>
                    <RadioGroupInput
                      label={""}
                      className={"padding-0"}
                      options={[
                        {
                          label: "On",
                          value: "on",
                        },
                        {
                          label: "By Schedule",
                          value: "auto",
                        },
                        {
                          label: "Off",
                          value: "off",
                        },
                      ]}
                      name={"order_allowed"}
                      id={"order-allowed"}
                      onChange={(value) => {
                        forceTabletStatus(value);
                        setFieldValue("order_allowed", value);
                      }}
                    />
                  </div>

                  <div className={"field-wrapper margin-y-2"}>
                    <div className={"field-label margin-y-1"}>Change Tablet Polling Behavior</div>
                    <div className={"text-small-detail"}>
                      This is useful during customer set up, when using demo tablets, and for special customer requests.
                      In most cases, set the tablet to the recommended setting to keep the default behaviour.
                    </div>
                    <RadioGroupInput
                      label={""}
                      className={"padding-0"}
                      options={[
                        {
                          label:
                            "Must be online to accept orders (Caution: Intermittent internet connectivity issues can " +
                            "block guest ordering in this mode. Switch to this only if actual problems with bad guest " +
                            "experience because the customer does not reliably keep the tablet on.)",
                          value: "default",
                        },
                        {
                          label: "Always accept orders, even while offline (Use this in the majority of cases.)",
                          value: "alwaysAccept",
                        },
                        {
                          label: "Accept orders offline until tablet is set up for the first time",
                          value: "alwaysAcceptUntilPoll",
                        },
                      ]}
                      name={"polling_behavior"}
                      id={"polling-behavior"}
                      onChange={(value) => {
                        overridePollSettings(value);
                        setFieldValue("polling_behavior", value);
                      }}
                    />
                  </div>
                </div>
              </Container>
            </Modal.Body>

            <Modal.Footer>
              <Button size={"sm"} type={"submit"} disabled={isSaving || Object.keys(errors).length !== 0}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditStationModal;
