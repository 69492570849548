import React from "react";
import { Modal } from "react-bootstrap";

const Detail = ({ entry, onHide }) => {
  return (
    <Modal show={Boolean(entry)} onHide={onHide} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>{entry?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{entry?.description}</div>
        <pre>{entry?.technical_details}</pre>
      </Modal.Body>
    </Modal>
  );
};
export default Detail;
