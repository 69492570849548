import Icon from "@ant-design/icons";
import React from "react";

const FirstTimeGuestSVG = () => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 18C2.5 9.16344 9.66344 2 18.5 2C27.3366 2 34.5 9.16344 34.5 18C34.5 26.8366 27.3366 34 18.5 34C9.66344 34 2.5 26.8366 2.5 18Z"
      fill="#D1E9FF"
    />
    <path
      d="M16.5 18C17.7867 18 18.8334 16.9533 18.8334 15.6667C18.8334 14.38 17.7867 13.3333 16.5 13.3333C15.2134 13.3333 14.1667 14.38 14.1667 15.6667C14.1667 16.9533 15.2134 18 16.5 18ZM16.5 14.6667C17.0534 14.6667 17.5 15.1133 17.5 15.6667C17.5 16.22 17.0534 16.6667 16.5 16.6667C15.9467 16.6667 15.5 16.22 15.5 15.6667C15.5 15.1133 15.9467 14.6667 16.5 14.6667ZM16.5334 21.3333H13.68C14.34 21 15.48 20.6667 16.5 20.6667C16.5734 20.6667 16.6534 20.6733 16.7267 20.6733C16.9534 20.1867 17.3467 19.7867 17.82 19.4667C17.3334 19.38 16.8734 19.3333 16.5 19.3333C14.94 19.3333 11.8334 20.1133 11.8334 21.6667V22.6667H16.5V21.6667C16.5 21.5533 16.5134 21.44 16.5334 21.3333ZM21.5 19.6667C20.2734 19.6667 17.8334 20.34 17.8334 21.6667V22.6667H25.1667V21.6667C25.1667 20.34 22.7267 19.6667 21.5 19.6667ZM22.3067 18.4533C22.8134 18.1667 23.1667 17.6267 23.1667 17C23.1667 16.08 22.42 15.3333 21.5 15.3333C20.58 15.3333 19.8334 16.08 19.8334 17C19.8334 17.6267 20.1867 18.1667 20.6934 18.4533C20.9334 18.5867 21.2067 18.6667 21.5 18.6667C21.7934 18.6667 22.0667 18.5867 22.3067 18.4533Z"
      fill="#1570EF"
    />
    <path
      d="M18.5 32C10.768 32 4.5 25.732 4.5 18H0.5C0.5 27.9411 8.55887 36 18.5 36V32ZM32.5 18C32.5 25.732 26.232 32 18.5 32V36C28.4411 36 36.5 27.9411 36.5 18H32.5ZM18.5 4C26.232 4 32.5 10.268 32.5 18H36.5C36.5 8.05887 28.4411 0 18.5 0V4ZM18.5 0C8.55887 0 0.5 8.05887 0.5 18H4.5C4.5 10.268 10.768 4 18.5 4V0Z"
      fill="#EFF8FF"
    />
  </svg>
);

const Employee = () => (
  <Icon title="FirstTimeGuest" aria-label="FirstTimeGuest" component={() => FirstTimeGuestSVG()} />
);

export default Employee;
