export const FILESTACK_API_KEY = "AarJ7ThEqQZaVx02GWP5Gz";
export const PRINTER_DRIVER_PRETTY_NAMES = { chinese: "Chinese Epson Clone" };
export const PLAID_SUPPORTED_COUNTRIES = {
  US: "United States",
  CA: "Canada",
  FR: "France",
  IE: "Ireland",
  NL: "Netherlands",
  ES: "Spain",
  GB: "Great Britain",
};

export const PAYMENT_TYPE = {
  credit_card: "credit_card",
  bank_account: "bank_account",
};

// list of users we let make retroactive changes to cut agreements
export const CUT_AGREEMENT_RETRO_USERS = ["nikki+admin@bbot.menu", "nikki+sadmin@bbot.menu", "luke+admin@bbot.menu"];

// These users can make the card processing rates whatever they want
export const CARD_PROCESSING_RATE_SUPERUSERS = [
  "joshua.klein@bbot.menu",
  "pope@bbot.menu",
  "erica.grimwood@bbot.menu",
  "nikki+admin@bbot.menu",
];

export const SALESFORCE_ID_REGEX = /^(001D)+[a-zA-Z0-9]{14}$/;

// Taken from : https://emailregex.com/
export const EMAIL_VALIDATION_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const PHONE_VALIDATION_REGEX =
  // eslint-disable-next-line no-control-regex
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

// Integrations that support 'dynamic delivery fees' (passing the delivery fee from the delivery integration directly to
// the customer
export const DYNAMIC_DELIVERY_INTEGRATIONS = ["nash"];

export const MAX_32_BIT_INT = 2147483647;

export const HISTORY_SOURCE_BBOT_OWNER_PORTAL = "bbot_owner_portal";

export const TIMEZONE_OPTIONS = [
  { value: "Australia/Adelaide", name: "Australia/Adelaide" },
  { value: "Australia/Brisbane", name: "Australia/Brisbane" },
  { value: "Australia/Darwin", name: "Australia/Darwin" },
  { value: "Australia/Eucla", name: "Australia/Eucla" },
  { value: "Australia/Melbourne", name: "Australia/Melbourne" },
  { value: "Australia/Sydney", name: "Australia/Sydney" },
  { value: "America/New_York", name: "America/New York" },
  { value: "America/Chicago", name: "America/Chicago" },
  { value: "America/Denver", name: "America/Denver" },
  { value: "America/Phoenix", name: "America/Phoenix" },
  { value: "America/Puerto_Rico", name: "America/Puerto Rico" },
  { value: "America/Cancun", name: "America/Cancun" },
  { value: "America/Los_Angeles", name: "America/Los Angeles" },
  { value: "America/Juneau", name: "America/Juneau" },
  { value: "America/Toronto", name: "America/Toronto" },
  { value: "America/Winnipeg", name: "America/Winnipeg" },
  { value: "America/Edmonton", name: "America/Edmonton" },
  { value: "America/Vancouver", name: "America/Vancouver" },
  { value: "America/Halifax", name: "America/Halifax" },
  { value: "Pacific/Honolulu", name: "Pacific/Honolulu" },
  { value: "Europe/London", name: "Europe/London" },
  { value: "Europe/Paris", name: "Europe/Paris" },
  { value: "Asia/Hong_Kong", name: "Asia/Hong Kong" },
  { value: "Asia/Singapore", name: "Asia/Singapore" },
];

export const TEST_PSCONTRACT_TOKENS = {
  company_name: "Joe's Take Away [Parent-175770]",
  legal_business_name: "Joe's Take Away [Parent-175770]",
  restaurant_street: "11 Washington Avenue",
  restaurant_city: "Miami Beach",
  restaurant_postal_code: "33139",
  restaurant_country: "US",
  restaurant_state: "FL",
  restaurant_state_province: "FL",
  contact_email: "juchuya@joesstonecrab.com",
  contact_phone: "(305) 342-8842",
  state: "FL",
  first_name: "Jose",
  last_name: "Uchuya",
  length_of_trial: null,
  bb_subscriptionStart: "2022-12-06",
  bb_subscriptionEnd: "2023-12-05",
  bb_subscriptionTerm: "1 Year",
  bb_activation: "2023-01-03",
  bb_addTerms: "Implementation Refund Promo;Full Logo Permission",
  ae_email: "autumn.clemons@doordash.com",
  user_name: "Jose Uchuya",
  userName: "Jose Uchuya 2",
  bb_processing_fees: [
    {
      quantity: 1,
      trial_value: "",
      billing_cycle: "",
      product_name: "Card Not-Present Payment Processing Rate (%)",
      pricing_value: 2.9,
      trial_length: "",
    },
    {
      quantity: 1,
      trial_value: "",
      billing_cycle: "",
      product_name: "Card Not-Present Payment Processing Fee ($)",
      pricing_value: 0.3,
      trial_length: "",
    },
  ],
  bb_software_products: [
    {
      quantity: 1,
      unit_value: 0,
      pricing_value: 0,
      trial_value: "",
      billing_cycle: "",
      product_name: "SaaS Fee - Bbot Premium Edition (In-Venue & Off-Premise Ordering)",
      trial_length: "",
    },
  ],
  bb_hardware_products: [
    {
      quantity: 1,
      trial_value: "",
      billing_cycle: "",
      product_name: "Epson Impact Printer TM-U220B",
      unit_value: 0,
      pricing_value: 0,
      trial_length: "",
    },
    {
      quantity: 1,
      trial_value: "",
      billing_cycle: "",
      product_name: 'Elo 10" Tablet',
      unit_value: 0,
      pricing_value: 0,
      trial_length: "",
    },
  ],
  bb_pro_products: [
    {
      quantity: 1,
      unit_value: 0,
      pricing_value: 0,
      trial_value: "",
      billing_cycle: "",
      product_name: "Set Up Fee - Quick Start Implementation Package",
      trial_length: "",
    },
  ],
};
