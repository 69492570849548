import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { generalErrorAlert, generateRandomPin } from "util/Utils";
import { getDuplicatePinEmployees } from "pages/manage-employees/ManageEmployees";

const PinHelpModal = (props) => {
  const [showRandomize, setShowRandomize] = useState(false);
  const [employeesToChange, setEmployeesToChange] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [willSendEmail, setWillSendEmail] = useState(true);
  const [isRandomizing, setIsRandomizing] = useState(false);

  const { show, onHideCallback, employees, onSubmitCallback, pins, selectedCustomer } = props;

  const onHide = () => {
    onHideCallback();
    setShowRandomize(false);
  };

  useEffect(() => {
    // group employees by pin number
    const duplicatePinEmployees = getDuplicatePinEmployees(employees);
    setEmployeesToChange(duplicatePinEmployees);
    setSelectedEmployees(duplicatePinEmployees.map((employee) => employee.user.id));
  }, [employees]);

  const calculatePins = () => {
    // for each employee
    let calculatedPins = new Set();
    const updatedEmployees = employeesToChange.map((employee) => {
      let newPin = generateRandomPin();
      while (calculatedPins.has(newPin) || pins.has(newPin)) {
        newPin = generateRandomPin();
      }
      calculatedPins.add(newPin);
      return {
        ...employee,
        pin_number: newPin,
      };
    });
    setEmployeesToChange(updatedEmployees);
  };

  const randomizePins = async () => {
    setIsRandomizing(true);
    try {
      const employeesWithPins = employeesToChange
        .filter((employee) => selectedEmployees.includes(employee.user.id))
        .map((employee) => ({
          email: employee.user.email,
          pin: employee.pin_number,
        }));
      const payload = {
        updated_employees: employeesWithPins,
        send_emails: willSendEmail,
        customer_id: selectedCustomer?.customer_id,
      };
      await axios.post("api/updatePins", payload);
      onHide();
      onSubmitCallback();
      toast.success("Successfully set pins for employees.");
    } catch (error) {
      generalErrorAlert(error);
    }
    setIsRandomizing(false);
  };

  const randomizeModalBody = () => {
    return (
      <div>
        <div className={"margin-y-2"}>
          <p>
            You are about to change the PINs of the following {employeesToChange.length} employees. Do you want to
            proceed?
          </p>
        </div>
        <div>
          {employeesToChange.map((employee, index) => (
            <div key={index} className={"margin-bottom-1"}>
              <div className={"d-flex"}>
                <input
                  type={"checkbox"}
                  className={"margin-right-2"}
                  checked={selectedEmployees.includes(employee.user.id)}
                  style={{ marginTop: "0.4rem" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedEmployees([...selectedEmployees, employee.user.id]);
                    } else {
                      setSelectedEmployees(selectedEmployees.filter((employeeId) => employeeId !== employee.user.id));
                    }
                  }}
                />
                <span>
                  <div>
                    {employee.user.first_name} {employee.user.last_name}
                  </div>
                  <div className={"color-neutral__placeholder"}>
                    <span className={"margin-right-1"}>{employee.user.email}</span>|
                    <span className={"margin-x-1"}>{employee.pin_number}</span>
                  </div>
                </span>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <input
          data-test-id={"randomize-pins-send-email"}
          type={"checkbox"}
          className={"margin-right-1"}
          checked={willSendEmail}
          onChange={(e) => setWillSendEmail(e.target.checked)}
        />
        <span>Send each employee an email with new PIN</span>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{showRandomize ? "Randomize PINS?" : "Creating Non-Unique Pins"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"padding-2"}>
        {showRandomize ? (
          randomizeModalBody()
        ) : (
          <>
            <div>
              <p>Some employee PINs are not unique.</p>
            </div>
            <div>
              <p>
                {employeesToChange.length} of your employees PINs need to be changed. Either go into each employee row
                to manually change or randomize the PIN or click below to randomize all {employeesToChange.length} PINs.
              </p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant={"light"} size={"sm"} className={"margin-right-2"}>
          Cancel
        </Button>
        {showRandomize ? (
          <Button
            data-test-id={"randomize-pins-confirm"}
            size={"sm"}
            disabled={isRandomizing || !selectedEmployees.length}
            onClick={randomizePins}
          >
            {isRandomizing ? <Spinner animation={"border"} size={"sm"} /> : "Yes, Randomize PINs"}
          </Button>
        ) : (
          <Button
            data-test-id={"randomize-pins-for-employees"}
            onClick={() => {
              calculatePins();
              setShowRandomize(true);
            }}
            size={"sm"}
          >
            Randomize Pins For {employeesToChange.length} Employees
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PinHelpModal;
