import React, { useEffect, useState, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  OWNER_LINK_CONFIG,
  BBOT_ADMIN_ONLY_LINKS,
  ADMIN_LINK_CONFIG,
  SALES_LINK_CONFIG,
  INTEGRATION_LINK_CONFIG,
  MENU_LINK_CONFIG,
  SUPER_ADMIN_LINK_CONFIG,
} from "pages/owner-app-dashboard/OwnerAppDashboard";
import axios from "axios";

// Components
import Footer from "components/global/footer/Footer";
import {
  Avatar,
  Input,
  Layout,
  Menu,
  Tooltip,
  List,
  BbotButton,
  Popover,
  notification
} from "bbot-component-library"; // prettier-ignore

import { Button, Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import BBOT_SERVER, { ENV } from "Config";
import { customerSortAdmin, customerSortOwner, generalErrorAlert, isFeatureEnabledById } from "util/Utils";
import styled, { css } from "styled-components";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { HISTORY_SOURCE_BBOT_OWNER_PORTAL } from "Constants";
import {
  trackClickLogout,
  trackClickRestaurantDropdown,
  trackClickRestaurantDropdownItem,
  trackClickRestaurantDropdownLoadMore,
  trackClickSidenavItem,
} from "instrumentation/tracking/page-tracking-events/DashboardPageTracking";

import BbotLogoCircleWhite from "assets/svgs/bbot-logo-circle-white.svg";
import BbotLogoWhite from "assets/svgs/bbot-logo-white.svg";

const Page = (props) => {
  // HOOKS
  const history = useHistory();
  const location = useLocation();

  const _handleResponseError = async (error) => {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 302: // redirect
          break;
        case 400: // Bad request
          break;
        case 401: // Unauthorized
          break;
        case 403: // Forbidden
          notification.error({
            message:
              "Whoops! Your session is invalid for some reason. You will be redirected to the login page to login again.",
            onClose: async () => {
              await axios.get("/api/clear-session");
              history.push("/login", {
                from_bbot_page: HISTORY_SOURCE_BBOT_OWNER_PORTAL,
                from: props.location,
              });
            },
          });
          break;
        case 404: // Not Found
          break;
        case 500: // Server Error
          break;
        case 504: // Gateway Timeout
          break;
        default:
        // do something
      }

      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  };

  axios.interceptors.response.use((response) => response, _handleResponseError);

  const [dropdownSelectedCustomer, setDropdownSelectedCustomer] = useState(null);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const [searchBarText, setSearchBarText] = useState("");
  const [numCustomersShown, setNumCustomersShown] = useState(30);
  const [hostnames, setHostnames] = useState([]);
  const [numHostnamesDisplayed, setNumHostnamesDisplayed] = useState(5);

  // for when menu is collapsed vs expanded
  const [isCustomerSearchVisibleExpanded, setIsCustomerSearchVisibleExpanded] = useState(false);

  // PROPS
  const { userInfo, selectedCustomer } = props;

  const userIsAdmin = userInfo?.role === "admin";
  const userIsBbotAdmin = userInfo?.teams?.includes("bbot");
  const userIsBbotSuperAdmin = userInfo?.permissions?.includes("manage_admins") && userInfo?.teams?.includes("bbot");

  const terminalLink =
    ENV === "localdev" ? "" : window.location.protocol + "//terminal." + window.location.hostname.split("owner.")[1];

  const getOpenMenuKey = useCallback(() => {
    const currentPath = location.pathname;

    if (OWNER_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)) {
      return ["restaurant_setup"];
    } else if (SALES_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)) {
      return ["financials"];
    } else if (INTEGRATION_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)) {
      return ["integrations"];
    } else if (MENU_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)) {
      return ["menus"];
    } else if (ADMIN_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)) {
      return ["admin"];
    } else if (
      userIsBbotAdmin &&
      !userIsBbotSuperAdmin &&
      BBOT_ADMIN_ONLY_LINKS.map((linkConfig) => linkConfig.link).includes(currentPath)
    ) {
      return ["admin", "bbot_admin_only"];
    } else if (
      userIsBbotSuperAdmin &&
      SUPER_ADMIN_LINK_CONFIG.map((linkConfig) => linkConfig.link).includes(currentPath)
    ) {
      return ["admin", "bbot_super_admin_only"];
    }
  }, [location.pathname, userIsBbotAdmin, userIsBbotSuperAdmin]);

  useEffect(() => {
    setMenuOpenKeys(getOpenMenuKey());
  }, [getOpenMenuKey]);

  //on selectedCustomer change, get and set hostname for selectedCustomer
  useEffect(() => {
    if (!selectedCustomer) return;
    const getHostnames = async () => {
      try {
        const res = await axios.get("/owner/getHostnames?customerId=" + selectedCustomer?.customer_id);
        setHostnames(res.data.hostnames);
      } catch (error) {
        generalErrorAlert(error, "Error Retrieving Hostnames.");
      }
    };

    if (userIsAdmin) {
      getHostnames();
    }
  }, [selectedCustomer, userIsAdmin]);

  const logout = async () => {
    trackClickLogout();
    const res = await axios.post("/api/journey/logout");
    if (res.data.logout_success) {
      props.clearCustomerInfo();
      history.push("/login");
    }
  };

  const chooseCustomerModal = () => {
    const { allowedCustomers, selectedCustomer, selectAllowedCustomer, loadingData, userInfo } = props;

    const userIsSuperAdmin = userInfo?.permissions?.includes("manage_admins");

    const options = allowedCustomers.map((customer) => ({
      value: customer["customer_id"],
      label: customer["customer_name"],
    }));

    return (
      <Modal
        show={
          !selectedCustomer && !loadingData && ((userIsSuperAdmin && allowedCustomers.length > 0) || !userIsSuperAdmin)
        }
        onHide={() => void 0 /*do nothing on hide, force user to select customer*/}
      >
        <Modal.Header>
          <Modal.Title>Please select an account.</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"no-overflow"}>
          <div>
            Select an account from the dropdown below. You can always switch to a different account using the dropdown
            in the navigation bar.
          </div>
          <div className="customer-chooser">
            <ReactSelect
              classNamePrefix={"select-customer"}
              options={options}
              onChange={(option) => setDropdownSelectedCustomer(option.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-test-id="customer select button"
            onClick={() => selectAllowedCustomer(dropdownSelectedCustomer)}
            disabled={!dropdownSelectedCustomer}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const submenu = (links, key, title, returnLinksAsArray = false, materialIcon = null) => {
    const { history, userInfo, selectedCustomer } = props;

    const userIsAdmin = userInfo?.role === "admin";
    const userIsBbotAdmin = userInfo?.teams?.includes("bbot");
    const redirectUrlAppendix = userIsAdmin ? BBOT_SERVER + "/superuser/console" : BBOT_SERVER + "/owner/console";

    const linksArray = links
      .filter((link) => !(link.featureId && !isFeatureEnabledById(link.featureId, selectedCustomer)))
      .map((link) =>
        link.reactLink ? (
          <MenuLink
            menuexpanded={(!isMenuCollapsed).toString()}
            key={link.link}
            onClick={() => {
              trackClickSidenavItem({ destination_link: link.link });
              history.push(link.link);
            }}
          >
            {link.name || (userIsBbotAdmin ? link.nameForBbot : link.nameForOthers)}
          </MenuLink>
        ) : (
          <MenuLink
            menuexpanded={(!isMenuCollapsed).toString()}
            key={link.link}
            onClick={() => {
              trackClickSidenavItem({ destination_link: link.link });
              window.location.href = link.isAdminPortalLink
                ? BBOT_SERVER + link.link
                : redirectUrlAppendix + link.link + "?from=owner_react_app";
            }}
          >
            {link.name.length ? link.name : userIsBbotAdmin ? link.nameForBbot : link.nameForOthers}
          </MenuLink>
        )
      );

    if (returnLinksAsArray) return linksArray;

    return (
      <SubMenu
        key={key}
        title={title}
        icon={materialIcon && <span className={"material-icons-outlined"}>{materialIcon}</span>}
      >
        {linksArray}
      </SubMenu>
    );
  };

  const hostnamesSubmenu = (hostnames) => {
    const linksArray = hostnames.slice(0, numHostnamesDisplayed).map((link) => (
      <MenuLink
        key={link}
        onClick={() => {
          window.open("https://" + link, "_blank");
        }}
      >
        {link}
      </MenuLink>
    ));

    return (
      <SubMenu
        key={"hostnames"}
        title={"Hostnames"}
        icon={<span className={"material-icons-outlined"}>collections_bookmark</span>}
      >
        {linksArray}
        {numHostnamesDisplayed < hostnames.length && (
          <MenuLink
            key={"showmore"}
            onClick={() => {
              setNumHostnamesDisplayed(numHostnamesDisplayed + 5);
            }}
          >
            Show More
          </MenuLink>
        )}
      </SubMenu>
    );
  };

  const customerSearchDropdown = () => {
    const { allowedCustomers, selectAllowedCustomer, userInfo, selectedCustomer } = props;

    const isAdmin = userInfo?.role === "admin";
    const customers = allowedCustomers
      ?.filter((customer) => {
        if (selectedCustomer?.customer_id === customer.customer_id) return false;
        const adminNameIncluded = customer.name_for_admin.toLowerCase().includes(searchBarText.toLowerCase());
        const ownerNameIncluded = customer.name_for_owner.toLowerCase().includes(searchBarText.toLowerCase());

        if (isAdmin) {
          return adminNameIncluded || ownerNameIncluded;
        } else {
          return ownerNameIncluded;
        }
      })
      .sort(isAdmin ? customerSortAdmin : customerSortOwner);

    return (
      <CustomerSelectMenu>
        <div className={"search-input-container"}>
          <Input
            placeholder="Search Restaurant"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchBarText(e.target.value)}
          />
        </div>
        <div className={"customers-container bbot-scroll-container"}>
          <List
            itemLayout="horizontal"
            dataSource={customers.slice(0, numCustomersShown)}
            loadMore={
              numCustomersShown < customers.length ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 12,
                    marginBottom: 12,
                    height: 32,
                    lineHeight: "32px",
                  }}
                >
                  <BbotButton
                    onClick={() => {
                      trackClickRestaurantDropdownLoadMore();
                      setNumCustomersShown((numCustomersShown) => numCustomersShown + 10);
                    }}
                  >
                    Load More
                  </BbotButton>
                </div>
              ) : null
            }
            renderItem={(customer) => (
              <CustomerSelectEntry
                key={customer.customer_id}
                onClick={() => {
                  trackClickRestaurantDropdownItem({ customer });
                  selectAllowedCustomer(customer.customer_id);
                  setIsCustomerSearchVisibleExpanded(false);
                }}
              >
                {isAdmin ? (
                  <div className={"padding-y-1"}>
                    <div>{customer.name_for_admin}</div>
                    <div className={"supporting-text color-neutral__placeholder"}>{customer.name_for_owner}</div>
                  </div>
                ) : (
                  <div className={"padding-y-1"}>{customer.name_for_owner}</div>
                )}
              </CustomerSelectEntry>
            )}
          />
        </div>
      </CustomerSelectMenu>
    );
  };

  const redirectUrlAppendix = userIsAdmin ? BBOT_SERVER + "/superuser/console" : BBOT_SERVER + "/owner/console";

  return (
    <StyledPage>
      <Layout.Sider
        collapsible
        collapsed={isMenuCollapsed}
        onCollapse={(collapsed) => {
          // ignore first collapse call
          setIsMenuCollapsed(collapsed);
        }}
        breakpoint="lg"
        width={300}
        trigger={null}
      >
        <div className={"bbot-scroll-container__dark-background"}>
          <SidebarUpperMenu
            theme={"dark"}
            mode="inline"
            className={"bbot-scroll-container__dark-background padding-top-1"}
            selectedKeys={[location.pathname]}
            openKeys={menuOpenKeys}
            id={"top-level-menu"}
            onOpenChange={(keys) => {
              const latestOpenKey = keys.find((key) => menuOpenKeys?.indexOf(key) === -1);
              if (
                ["/", "restaurant_setup", "integrations", "financials", "admin", "menus"].indexOf(latestOpenKey) === -1
              ) {
                setMenuOpenKeys(keys);
              } else {
                setMenuOpenKeys(latestOpenKey ? [latestOpenKey] : []);
              }
            }}
          >
            {isMenuCollapsed ? (
              <Menu.Item
                key={"expand"}
                className={"d-flex justify-content-center align-items-center"}
                onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
              >
                <div className={"padding-bottom-1 margin-top-1"}>
                  <img src={BbotLogoCircleWhite} alt={"Icon"} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={"collapse"} onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}>
                <div className={"padding-bottom-1 margin-top-1 d-flex align-items-center"}>
                  <img src={BbotLogoWhite} alt={"Icon"} />
                </div>
              </Menu.Item>
            )}

            <SidebarLowerMenuDivider />

            <MenuLink
              istoplevellink={"true"}
              menuexpanded={(!isMenuCollapsed).toString()}
              key={"/"}
              icon={<span className={"material-icons-outlined"}>home</span>}
              onClick={() => history.push("/")}
            >
              Home
            </MenuLink>
            <SubMenu
              title={"Restaurant Setup"}
              key={"restaurant_setup"}
              icon={<span className={"material-icons-outlined"}>restaurant</span>}
            >
              {submenu(OWNER_LINK_CONFIG, "owner_settings", "General", true)}
            </SubMenu>
            {submenu(MENU_LINK_CONFIG, "menus", "Menu Management", false, "menu_book")}
            {submenu(INTEGRATION_LINK_CONFIG, "integrations", "Integrations", false, "integration_instructions")}
            {submenu(SALES_LINK_CONFIG, "financials", "Financials", false, "savings")}
            {userIsAdmin && (
              <SubMenu
                key={"admin"}
                title={"Admin"}
                icon={<span className={"material-icons-outlined"}>admin_panel_settings</span>}
              >
                {submenu(ADMIN_LINK_CONFIG, "admins", "Admin", true)}

                {userIsBbotAdmin &&
                  !userIsBbotSuperAdmin &&
                  submenu(BBOT_ADMIN_ONLY_LINKS, "bbot_admin_only", "Bbot Admin Only")}
                {userIsBbotSuperAdmin && submenu(SUPER_ADMIN_LINK_CONFIG, "bbot_super_admin_only", "Super Admin Only")}
              </SubMenu>
            )}
            {userIsAdmin && hostnamesSubmenu(hostnames)}
          </SidebarUpperMenu>
        </div>

        <SidebarLowerMenuContainer>
          <SidebarLowerMenu theme={"dark"} mode="inline" id={"sidebar-bottom-menu"}>
            <SidebarLowerMenuDivider />
            <MenuLink
              istoplevellink={"true"}
              menuexpanded={(!isMenuCollapsed).toString()}
              icon={<span className={"material-icons-outlined"}>tablet_mac</span>}
              onClick={() => (window.location.href = terminalLink)}
            >
              <span className={"margin-right-2"}>Terminal</span>
            </MenuLink>
            <MenuLink
              istoplevellink={"true"}
              menuexpanded={(!isMenuCollapsed).toString()}
              icon={<span className={"material-icons-outlined"}>help_outline</span>}
              onClick={() => (window.location.href = "https://central.bbot.menu/")}
            >
              Support
            </MenuLink>
            <MenuLink
              istoplevellink={"true"}
              menuexpanded={(!isMenuCollapsed).toString()}
              key={"/settings"}
              icon={<span className={"material-icons-outlined"}>settings</span>}
              onClick={() => (window.location.href = redirectUrlAppendix + "/#!/settings")}
            >
              Settings
            </MenuLink>
          </SidebarLowerMenu>
          <UserinfoContainer>
            {isMenuCollapsed ? (
              <span className={"margin-right-1"}>
                <UserinfoAvatar>{`${userInfo?.first_name?.charAt(0)}${userInfo?.last_name?.charAt(0)}`}</UserinfoAvatar>
              </span>
            ) : (
              <>
                <span className={"d-flex"}>
                  <span className={"margin-right-1"}>
                    <UserinfoAvatar>{`${userInfo?.first_name?.charAt(0)}${userInfo?.last_name?.charAt(
                      0
                    )}`}</UserinfoAvatar>
                  </span>
                  <span>
                    <ClipTextContainer maxWidth={180}>
                      {(userInfo?.first_name || "") + " " + (userInfo?.last_name || "")}
                    </ClipTextContainer>
                    <ClipTextContainer maxWidth={180}>{userInfo?.email}</ClipTextContainer>
                  </span>
                </span>
                <Tooltip title={"Log Out"}>
                  <span
                    className={"material-icons-outlined color-neutral__icon margin-left-1 clickable-icon"}
                    onClick={logout}
                    style={{ float: "right" }}
                  >
                    logout
                  </span>
                </Tooltip>
              </>
            )}
          </UserinfoContainer>
        </SidebarLowerMenuContainer>
      </Layout.Sider>

      <Layout>
        <Header>
          <Popover
            placement={"bottomLeft"}
            content={customerSearchDropdown}
            visible={isCustomerSearchVisibleExpanded}
            onVisibleChange={setIsCustomerSearchVisibleExpanded}
            trigger={"click"}
          >
            <CustomerNameContainer onClick={() => trackClickRestaurantDropdown(null)}>
              <ClipTextContainer>
                {userIsAdmin
                  ? selectedCustomer?.name_for_admin || "Loading..."
                  : selectedCustomer?.name_for_owner || "Loading..."}
              </ClipTextContainer>
              <DownOutlined className={"margin-x-1"} />
            </CustomerNameContainer>
          </Popover>
        </Header>
        <ContentContainer className={"bbot-scroll-container"}>
          <div className={"content-container"}>{props.children}</div>
          <Layout.Footer>
            <Footer {...props} />
          </Layout.Footer>
        </ContentContainer>
      </Layout>

      {chooseCustomerModal()}
    </StyledPage>
  );
};

Page.propTypes = {
  setSelectedCustomer: PropTypes.func,
  setUserInfo: PropTypes.func,
};

export default withRouter(Page);

const StyledPage = styled(Layout)`
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 100vh; // To account for when content is smaller than full screen height

  .ant-layout-content {
    height: calc(100vh - 70px);
    width: 100%;
    overflow-y: auto;
    background-color: white;
    .content-container {
      padding-top: 40px;
    }
  }

  .ant-layout-footer {
    background-color: white;
  }
`;

const CustomerNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
`;

const Header = styled.div`
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 32px;
  background-color: white;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  z-index: 10;
`;

const SubMenu = styled(Menu.SubMenu)`
  &&& {
    .ant-menu-item-icon {
      font-size: 20px;
      color: #f2f4f7;
    }

    .ant-menu-title-content {
      font-size: 16px;
      color: #f2f4f7;
    }
  }
`;

const CustomerSelectEntry = styled.div`
  cursor: pointer;
  padding: 0 20px;
  &:hover {
    background-color: var(--color-primary__light);
  }
`;

const CustomerSelectMenu = styled.div`
  &&& {
    min-width: 300px;
    .search-input-container {
      padding: 20px;
      border-bottom: 1px solid var(--color-neutral__accent-background);
    }
    .customers-container {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
`;

const MenuLink = styled(Menu.Item)`
  &&& {
    width: auto;

    ${(props) =>
      props.menuexpanded === "true" &&
      css`
        padding-left: ${props.istoplevellink === "true" ? "12px !important" : "42px !important"};
        margin-right: 16px;
        margin-left: 12px;
        border-radius: 8px;
      `}

    &.ant-menu-item-selected {
      background-color: #344054;
    }

    .ant-menu-item-icon {
      font-size: 20px;
    }

    .ant-menu-title-content {
      font-size: 16px;
    }
  }
`;

const SidebarUpperMenu = styled(Menu)`
  height: calc(100vh - 257px);
  overflow-y: auto;
  &&& {
    background-color: #101828;
  }
`;

const SidebarLowerMenuContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
`;

const SidebarLowerMenu = styled(Menu)`
  &&& {
    background-color: #101828;
  }
`;

const SidebarLowerMenuDivider = styled(Menu.Divider)`
  &&& {
    border-color: #475467;
    margin: 12px 24px 24px 24px;
  }
`;

const UserinfoContainer = styled.div`
  background-color: #101828;
  color: white;
  line-height: 20px;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
`;

const UserinfoAvatar = styled(Avatar)`
  color: var(--color-primary__regular);
`;

const ClipTextContainer = styled.div`
  max-width: ${(props) => props.maxWidth}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ContentContainer = styled(Layout.Content)`
  &&& {
    background-color: var(--color-neutral__antd-page-background);
    overflow-y: scroll;
  }
`;
