import React, { useCallback } from "react";
import { useFileUploadContext } from "./FileUploadProvider";
import InProgressFile, { FileUploadTile } from "./InProgressFile";
import { FileState } from "./types";

const FileUploadManager: React.FC = () => {
  const {
    acceptedTypes,
    maxFileSize,
    minFileSize,
    validateFile,
    filestackClient,
    inProgressFiles,
    completedFiles,
    saveCompletedFile,
    removeInProgressFile,
    removeCompletedFile,
  } = useFileUploadContext();

  const handleValidation = useCallback(
    (file: File) => {
      const acceptedRegExps = acceptedTypes.map((type) => new RegExp(type.replaceAll(".", "")));
      const isSupported = acceptedRegExps.some((regexp) => regexp.test(file.type));

      if (!isSupported) {
        return "Unsupported file type";
      }

      if (file.size > maxFileSize) {
        return "File size is too large";
      }

      if (file.size < minFileSize) {
        return "File size is too small";
      }

      return validateFile(file);
    },
    [acceptedTypes, maxFileSize, minFileSize, validateFile]
  );

  return (
    <>
      {!!inProgressFiles.length &&
        inProgressFiles.map(([id, file]) => (
          <InProgressFile
            key={id}
            id={id}
            file={file}
            client={filestackClient}
            validateFile={handleValidation}
            saveCompletedFile={saveCompletedFile}
            removeInProgressFile={removeInProgressFile}
          />
        ))}
      {!!completedFiles.length &&
        completedFiles.map((file) => (
          <FileUploadTile
            key={file.handle}
            progress={0}
            uploadStatus={FileState.INIT}
            file={file}
            handleRemove={() => removeCompletedFile(file.handle)}
            handleRetry={() => {}}
          />
        ))}
    </>
  );
};

export default FileUploadManager;
