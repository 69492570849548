import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";

import axios from "axios";
import BBOT_SERVER from "Config";

import { Form, TextInput, SelectInput } from "bbot-component-library";
import { generalErrorAlert, isNumber } from "util/Utils";

import { Modal } from "@doordash/component-modal";
import { InlineChildren, StackChildren, Text } from "@doordash/design-language";
import Button from "@doordash/component-button";
import { TextField } from "@doordash/component-fields";
import Banner from "@doordash/component-banner";

export function VerifyMicroDepositModal({
  customerId,
  payment_method_id,
  getDataForPaymentTask,
  onCloseCallback,
  onSuccessCallback,
}) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [amount1, setAmount1] = useState("");
  const [amount2, setAmount2] = useState("");
  const [amount1Error, setAmount1Error] = useState("");
  const [amount2Error, setAmount2Error] = useState("");

  const handleSubmit = async () => {
    if (!amount1 || !amount2 || amount1Error || amount2Error) {
      return;
    }

    setIsVerifying(true);
    try {
      await axios.post(BBOT_SERVER + "/api/journey/verifyBankAccount", {
        customer_id: customerId,
        payment_method_id: payment_method_id,
        microdeposits: [amount1 * 100, amount2 * 100],
      });

      getDataForPaymentTask();
      if (onSuccessCallback) onSuccessCallback();
    } catch (error) {
      if (error?.response?.data?.errorCode) {
        setErrorMessage(error.response.data.errorCode);
      } else if (error?.errorCode) {
        setErrorMessage(error.errorCode);
      } else {
        generalErrorAlert(error, "Could not verify this bank account.");
      }
    }
    setIsVerifying(false);
  };

  return (
    <Modal title="Verify Bank Account" closeButtonSide={Modal.CloseButtonSides.Trailing} onClose={onCloseCallback}>
      <StackChildren size={StackChildren.Sizes.Large}>
        <Text styles={Text.Styles.Body2}>
          The statent for the bank accout you’re trying to verify has a description that includes AMTS followedby the
          two micro deposits amounts. Please enter them below to verfy your account.
        </Text>

        {errorMessage && !isVerifying && <Banner label={errorMessage} variant={Banner.Variants.Negative} />}

        <TextField
          label="First Microdeposit Amount"
          value={amount1}
          placeholder="0.00"
          onChange={(amount) => {
            setAmount1(amount);
            if (!amount) {
              setAmount1Error("This field is required");
            } else if (!isNumber(amount)) {
              setAmount1Error("Please enter a dollar amount");
            } else {
              setAmount1Error("");
            }
          }}
          error={amount1Error}
          renderBeforeContent={() => "$"}
        />
        <TextField
          label="Second Microdeposit Amount"
          value={amount2}
          placeholder="0.00"
          onChange={(amount) => {
            setAmount2(amount);
            if (!amount) {
              setAmount2Error("This field is required");
            } else if (!isNumber(amount)) {
              setAmount2Error("Please enter a dollar amount");
            } else {
              setAmount2Error("");
            }
          }}
          error={amount2Error}
          renderBeforeContent={() => "$"}
        />

        <InlineChildren justifyContent="end">
          <Button isInline state={isVerifying ? Button.States.Loading : Button.States.Default} onClick={handleSubmit}>
            Verify
          </Button>
        </InlineChildren>
      </StackChildren>
    </Modal>
  );
}

const MicroDepositBankForm = (props) => {
  let { customerId, setErrorMessage, setSubmitting, resetAddPaymentForm } = props;

  const [manualBankForm] = Form.useForm();

  const stripe = useStripe();

  const handleSubmit = (values) => {
    setSubmitting(true);

    stripe
      .createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: values.routingNumber,
        account_number: values.accountNumber,
        account_holder_name: values.fullName,
        account_holder_type: values.accountType,
      })
      .then(function (result) {
        // Handle result.error or result.token
        if (result.error) {
          setSubmitting(false);
          setErrorMessage("Error: " + result.error.message);
        } else {
          axios
            .post(BBOT_SERVER + "/api/journey/addBankAccount", {
              customer_id: customerId,
              bank_account_token: result.token,
            })
            .then((res) => {
              setErrorMessage("");
              resetAddPaymentForm();
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.errorCode) {
                setErrorMessage(error.response.data.errorCode);
              } else {
                setErrorMessage("Unexpected error authorizing bank account");
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      });
  };

  return (
    <div>
      <Form layout={"vertical"} form={manualBankForm} name={"manual-bank-form"} onFinish={handleSubmit}>
        <TextInput required id={"account-name"} label={"Account Holder Full Name"} name={"fullName"} />
        <TextInput
          required
          id={"routing-number"}
          label={"Bank Routing Number"}
          name={"routingNumber"}
          regex={/\d+/}
          regexValidationMessage={"Routing numbers must be 9 digits"}
        />
        <TextInput required id={"account-number"} label={"Account Number"} name={"accountNumber"} />
        <SelectInput
          required
          id={"account-type"}
          label={"Account Type"}
          name={"accountType"}
          options={[
            { label: "Business", value: "company" },
            { label: "Personal", value: "individual" },
          ]}
        />
      </Form>
      <div>
        <p>
          Manually collected bank accounts require additional verification. Upon saving your bank account details, two
          small deposits will be sent to your account for verification purposes that will take 1-2 days to appear on
          your statement.
        </p>
      </div>
    </div>
  );
};

export default MicroDepositBankForm;
