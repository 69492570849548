import React from "react";
import axios from "axios";

import { BbotModal, Form, Row, Col, notification, TextInput } from "bbot-component-library";
import { generalErrorAlert } from "util/Utils";

const CreatePartnerModal = (props) => {
  const { show, onClose, onSave, customerOwnedPartnerApp, selectedCustomerID } = props;
  const [createPartnerForm] = Form.useForm();

  const createNewPartner = async (values) => {
    // values is just a dict of with keys displayName and shortId
    try {
      values["customerId"] = selectedCustomerID;
      values["customerOwnedApp"] = customerOwnedPartnerApp;
      await axios.post("/owner/createNewPartner", values);
      notification.success({ message: `Successfully created new ${customerOwnedPartnerApp ? "app" : "partner"}` });
      onSave();
      onClose();
      createPartnerForm.resetFields();
    } catch (error) {
      generalErrorAlert(error, `Trouble adding new ${customerOwnedPartnerApp ? "app" : "partner"}.`, true);
    }
  };

  return (
    <BbotModal
      visible={show}
      title={customerOwnedPartnerApp ? "Create New App" : "Create New Partner"}
      onCancel={onClose}
      closable
      okText={"Submit"}
      okButtonProps={{
        htmlType: "submit",
        form: "createPartner",
      }}
    >
      <Form name={"createPartner"} onFinish={createNewPartner} form={createPartnerForm} layout={"vertical"}>
        <Row>
          <Col span={24}>
            <TextInput
              id={"display-name"}
              label={"Display Name"}
              name={"displayName"}
              required={true}
              maxLength={200}
            />
            {!customerOwnedPartnerApp && (
              <TextInput
                id={"short-id"}
                label={"Short ID"}
                name={"shortId"}
                required={true}
                maxLength={50}
                regex={new RegExp(/^[a-zA-Z0-9_-]+$/)}
                validationMessage={
                  "This is a required field and must only contain letters, numbers, underscores, and hyphens."
                }
              />
            )}
          </Col>
        </Row>
      </Form>
    </BbotModal>
  );
};

export default CreatePartnerModal;
