import { trackClickEditNotificationSet } from "instrumentation/tracking/page-tracking-events";
import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";

import "./FulfillmentMethodTypeCard.scss";

import ServerDeliveryIcon from "assets/svgs/icons/icon-server-delivery.svg";
import DeliveredCarIcon from "assets/svgs/icons/icon-delivered-car.svg";
import EnrouteIcon from "assets/svgs/icons/icon-enroute.svg";
import OnRobotIcon from "assets/svgs/icons/icon-on-robot.svg";
import ReadyForDriverIcon from "assets/svgs/icons/icon-ready-for-driver.svg";
import AtBarIcon from "assets/svgs/icons/icon-at-bar.svg";
import WaitingIcon from "assets/svgs/icons/icon-waiting.svg";

import PencilIcon from "assets/svgs/icons/icon-pencil.svg";
class FulfillmentNoticeCard extends Component {
  getHeaderIcon = () => {
    const { fulfillmentMethod } = this.props;
    let icon = "";
    switch (fulfillmentMethod.fulfillment_method) {
      case "server_delivery":
        icon = ServerDeliveryIcon;
        break;
      case "patron_pickup":
        icon = DeliveredCarIcon;
        break;
      case "patron_choice":
        icon = DeliveredCarIcon;
        break;
      case "runner_with_cc":
        icon = EnrouteIcon;
        break;
      case "patron_pickup_with_expo":
        icon = EnrouteIcon;
        break;
      case "robot_delivery":
        icon = OnRobotIcon;
        break;
      case "driver_delivery":
        icon = ReadyForDriverIcon;
        break;
      case "driver_delivery_with_expo":
        icon = ReadyForDriverIcon;
        break;
      case "catering":
        icon = AtBarIcon;
        break;
      case "runner_nofeedback":
        icon = WaitingIcon;
        break;
      case "pickup_nofeedback":
        icon = DeliveredCarIcon;
        break;
      default:
        icon = WaitingIcon;
        break;
    }
    return <img src={icon} alt={"Icon"} className={"margin-right-2"} />;
  };

  render() {
    const { fulfillmentMethodPrettyNames, fulfillmentMethod, statusNames, postCheckoutInstructionsClicked } =
      this.props;
    return (
      <Card className={"fulfillment-notice-card"}>
        <Card.Header className={"fulfillment-notice-card__header"}>
          {this.getHeaderIcon()}
          <h4>{fulfillmentMethodPrettyNames[fulfillmentMethod.fulfillment_method]}</h4>
        </Card.Header>
        <Card.Body>
          <div className={"margin-x-2 margin-bottom-2"}>
            <span>
              <Button
                variant={"light"}
                onClick={() => postCheckoutInstructionsClicked(fulfillmentMethod.fulfillment_method)}
              >
                Post Checkout Instructions
              </Button>
            </span>
          </div>

          <table className={"fulfillment-notice-card__table"}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Email</th>
                <th>Text</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fulfillmentMethod.notices.map(
                (notice, index) =>
                  notice.status !== "waiting" && ( // We are not showing the "waiting" status.
                    <tr
                      key={"fulfillment-row-" + index}
                      onClick={() => {
                        trackClickEditNotificationSet();
                        this.props.rowClicked(notice, fulfillmentMethod.fulfillment_method);
                      }}
                    >
                      <td>{statusNames[notice.status]}</td>
                      <td>
                        {notice.email ? (
                          <span className={"green-circle"}></span>
                        ) : (
                          <span className={"minus-sign"}>-</span>
                        )}
                      </td>
                      <td>
                        {notice.text ? (
                          <span className={"green-circle"}></span>
                        ) : (
                          <span className={"minus-sign"}>-</span>
                        )}
                      </td>
                      <td>
                        <img alt="edit icon" className="icon xx-small" src={PencilIcon} />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    );
  }
}

export default FulfillmentNoticeCard;
