import React from "react";
import styled from "styled-components";
import { Button } from "bbot-component-library";

const BbotButton = ({ ...props }) => {
  return <StyledButton {...props} />;
};

const StyledButton = styled(Button)`
  &&&.ant-btn {
    // For dealing with icons in the button
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    .material-icons-outlined {
      font-size: 18px;
      margin-right: 4px;
    }

    border-radius: 5px;
    font-size: 14px;
    color: black;
    font-family: var(--default-font);
    background: white;
    border-color: lightgrey;

    &:active:enabled,
    &:hover:enabled {
      color: black;
      border-color: lightgrey;
      background: var(--color-button-default__background);
      opacity: 0.9;
    }

    &-primary {
      color: white;
      background: var(--color-primary__regular);
      border-color: var(--color-primary__regular);

      &:disabled {
        background-color: var(--color-neutral__placeholder);
        border-color: var(--color-neutral__placeholder);
      }

      &:active:enabled,
      &:hover:enabled {
        color: white;
        background: var(--color-primary__dark);
        border-color: var(--color-primary__dark);
        opacity: 0.9;
      }
    }
  }

  &&&.ant-btn-dangerous {
    color: var(--color-error__dark);
    border-color: var(--color-error__regular);

    &:active:enabled,
    &:hover:enabled,
    &:focus:enabled {
      color: var(--color-error__dark);
      background: var(--color-error__light);
      border-color: var(--color-error__regular);
      opacity: 0.9;
    }

    &:disabled {
      color: var(--color-neutral__placeholder);
      background: var(--color-error__light);
      border-color: var(--color-neutral__placeholder);
      opacity: 0.9;
    }
  }

  &&&.ant-btn-dangerous.ant-btn-primary {
    color: white;
    background: var(--color-error__regular);
    border-color: var(--color-error__regular);
    &:disabled {
      background: #fecdca;
      border: 1px solid #fecdca;
    }

    &:active:enabled,
    &:hover:enabled,
    &:focus:enabled {
      color: white;
      background: var(--color-error__dark);
      border-color: var(--color-error__dark);
      opacity: 0.9;
    }
  }
`;

export default BbotButton;
