import React from "react";
import { InputNumber, Form } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
/*
Generic number input
 */
const NumberInput = ({
  id,
  label = "",
  name = "",
  step = 1,
  hidden,
  required = true,
  validationMessage,
  rules = [],
  dependencies,
  formItemStyle,
  ...props
}) => {
  return (
    <Form.Item
      id={id}
      label={label}
      name={name}
      hidden={hidden}
      required={required}
      dependencies={dependencies}
      rules={[
        {
          required: required,
          message: validationMessage || "This is a required field",
        },
      ].concat(rules)}
      style={formItemStyle}
      validateTrigger={["onBlur", "onChange"]}
    >
      <InputNumberStyled step={step} {...props} />
    </Form.Item>
  );
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
  min-width: 100px
  padding: 4px 0;
  border-radius: 8px;

  &&&.ant-input-number-group-wrapper {
    padding: 0 0;
  }

  .ant-input-number-group-addon:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-input-number-group-addon:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export default NumberInput;

NumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number, // Minimum accepted value
  max: PropTypes.number, // Maximum accepted value
  step: PropTypes.string, // Fixed decimals. Pass "0.01" to fix 2 decimals
  formatter: PropTypes.func,
  parser: PropTypes.func,
  placeholder: PropTypes.number,
  hidden: PropTypes.bool,
  addonBefore: PropTypes.string, // Rendered in a small box to the left of input
  addonAfter: PropTypes.string, // Rendered in a small box to the right of input
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validationMessage: PropTypes.string,
  rules: PropTypes.array, // Validation rules
};
