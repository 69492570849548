import React from "react";
import { Form } from "react-bootstrap";
import { TextInput } from "../../../../../src/components/global/form";

const OtterSelectLocationModalInputs = () => {
  return (
    <Form.Row>
      <TextInput sm={12} label="Store ID" name="locationId" id="location-id" />
    </Form.Row>
  );
};

export default OtterSelectLocationModalInputs;
