import React, { useEffect, useState, useCallback } from "react";

// Components
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import { capitalizeString, generalErrorAlert, splitSnakeCase } from "util/Utils";
import EditStationModal from "./EditStationModal";

const Stations = (props) => {
  const { selectedCustomer, userInfo } = props;

  const [stations, setStations] = useState([]);
  const [showEditStationModal, setShowEditStationModal] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [fulfillmentMethodsPretty, setFulfillmentMethodsPretty] = useState(null);

  const getStations = useCallback(async () => {
    if (!selectedCustomer) {
      return;
    }
    try {
      const res = await axios.get("/owner/getStations?customer_id=" + selectedCustomer?.customer_id);

      setStations(res.data.stations.sort((a, b) => (a.station_name > b.station_name ? 1 : -1)));
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to server. Please refresh the page.");
    }
  }, [selectedCustomer]);

  const fetchPossibleStatuses = async (customer_id) => {
    try {
      const response = await axios.get("/api/getCustomerFulfillmentMethods/", {
        params: { customer_id: customer_id },
      });
      setFulfillmentMethodsPretty(response.data.fulfillment_method_pretty_names);
    } catch (error) {
      generalErrorAlert(error, "Had trouble obtaining all fulfillment methods.");
    }
  };

  useEffect(() => {
    getStations();

    if (selectedCustomer) {
      fetchPossibleStatuses(selectedCustomer.customer_id);
    }

    //Interval Polling
    const intervalId = setInterval(() => {
      getStations();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [getStations, selectedCustomer]);

  const stationCard = (station) => {
    let pillVariant = "danger";
    if (station.accepting_orders) {
      pillVariant = "success";
    } else if (!station.accepting_orders) {
      pillVariant = "warning";
    }

    return (
      <Card className={"printer-configuration-card"}>
        <Card.Header className={"printer-configuration-card__header"}>
          <div className={"d-flex justify-content-space-between align-items-center full-width"}>
            <span>
              <h4>{station.station_name}</h4>
            </span>
            <span>
              <Badge pill variant={pillVariant}>
                {station.accepting_orders ? <>Accepting Orders</> : <>Offline</>}
              </Badge>
            </span>
          </div>
          <div>
            {station.fulfillment_method_pretty_names.map((method, index) => (
              <Badge pill variant={"info"} className={"margin-right-1"} key={method + index}>
                {method}
              </Badge>
            ))}
          </div>
        </Card.Header>
        <Card.Body>
          <table className={"printer-configuration-card__table"}>
            <thead>
              <tr>
                <th className={"status-col"}>Orders in the Past 9 hrs:</th>
              </tr>
            </thead>
            <tbody>
              <tr className={"table-row"}>
                <td className={"status-col"}>{station.orders_received}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
        <Card.Footer>
          <div className={"d-flex justify-content-space-between align-items-center full-width"}>
            <span className={"text-small-detail"}>
              Poll Time:&nbsp;
              {station.poll_time_unix ? (
                <Moment unix fromNow>
                  {station.poll_time_unix}
                </Moment>
              ) : (
                <>Never</>
              )}
            </span>
            <Button
              size={"sm"}
              onClick={() => {
                setSelectedStation(station);
                setShowEditStationModal(true);
              }}
            >
              Edit
            </Button>
          </div>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className={"breadcrumbs-row margin-bottom-4"}>
              <span>
                <Link to={"/"} className={"breadcrumb-back"}>
                  &#60; Back
                </Link>
              </span>
              <span className={"breadcrumbs"}>
                <span>
                  <Link to={"/"} className={"breadcrumb-link"}>
                    Owner Portal
                  </Link>
                </span>
                <span className={"breadcrumb-link"}>&#62;</span>
                <span>
                  <Link to={"/stations"} className={"breadcrumb-link"}>
                    Stations
                  </Link>
                </span>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={"margin-bottom-4"}>
              <h2>
                Manage Stations For <span className={"color-primary__dark"}>{selectedCustomer?.customer_name}</span>
              </h2>
            </div>
            <div className={"margin-bottom-4"}>
              <p>
                Use this page to monitor stations for your system. Stations are organized by their Names. Ask Bbot
                support if you wish to edit station properties.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          {Object.values(stations).map((station, index) => (
            <Col lg={6} sm={6} className={"margin-bottom-2"} key={index}>
              {stationCard(station)}
            </Col>
          ))}
        </Row>
      </Container>
      {/*============= Modals ========*/}
      <EditStationModal
        userInfo={userInfo}
        selectedCustomer={selectedCustomer}
        showEditStationModal={showEditStationModal}
        selectedStation={selectedStation}
        onHideCallback={() => setShowEditStationModal(false)}
        onSaveCallback={getStations}
        fulfillmentMethodsPretty={fulfillmentMethodsPretty}
      />
    </div>
  );
};

export default Stations;
