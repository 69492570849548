import React from "react";
import { Form } from "react-bootstrap";
import DisplayExtraCheckoutInfoOnTicketsFields from "../../../global/form/pos-integrations/DisplayExtraCheckoutInfoOnTicketsFields";
import CheckboxInput from "../../../../../src/components/global/form/CheckboxInput";
import TemplateStringInputField from "../../../../../src/components/global/form/pos-integrations/TemplateStringInputField";

const RevelIntegrationModalInputs = (props) => {
  const { values } = props;

  return (
    <>
      <div className={"margin-bottom-1"}>
        <p>Enable order injection from Bbot to Revel. These settings can be changed at any time.</p>
      </div>
      <Form.Row className={"margin-bottom-1"}>
        <CheckboxInput sm={12} label="Inject orders into Revel" name="injectOrders" id="inject-orders" />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <TemplateStringInputField
          label={"Ticket Call Name Template String"}
          id={"call-name-template-string"}
          name={"callNameTemplateString"}
          subText={"Use custom formatting for the ticket call name that is injected into Revel."}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          sm={12}
          label={"Inject Bbot order data as customer data"}
          name={"injectOrderDataAsCustomerData"}
          id={"injectOrderDataAsCustomerData"}
        />
      </Form.Row>
      <Form.Row>
        {values.injectOrders && (
          <DisplayExtraCheckoutInfoOnTicketsFields injectOrders={values.injectOrders} posName={"Revel"} />
        )}
      </Form.Row>
      <Form.Row>
        <CheckboxInput label={"Sync refunds from Revel"} name={"syncRefundsFromRevel"} id={"syncRefundsFromRevel"} />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={"Keep Bbot quantity limits instead of Revel quantity limits"}
          name={"keepQuantityLimits"}
          id={"keepQuantityLimits"}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={"Ignore Revel inventory for item availability"}
          name={"ignoreRevelInventory"}
          id={"ignoreRevelInventory"}
        />
      </Form.Row>
    </>
  );
};

export default RevelIntegrationModalInputs;
