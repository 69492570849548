// Utils
import { loadScript } from "util/Utils";
/*
Stolen from consumer
 */
export default class GoogleMapsAPI {
  AutocompleteService = null;
  PlacesService = null;
  DirectionsService = null;
  maps = null;

  constructor() {
    if (window.google && window.google.maps) {
      this.initGoogleMaps();
      this.initAutoCompleteService();
      this.initDirectionService();
    } else {
      this.loadGoogleScript();
    }
  }

  /**
   * Loads the google maps script
   */
  loadGoogleScript() {
    // grab the language and region the user is in so Google maps can be in the right language
    // const defaultLanguage = window.navigator.language.slice(0, 2);
    // const defaultRegion = window.navigator.language.slice(3, 5);

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => {
        this.initGoogleMaps();
        this.initAutoCompleteService();
        this.initDirectionService();
        this.initPlacesService();
      }
    );
  }

  initGoogleMaps() {
    this.maps = window.google.maps;
  }

  initAutoCompleteService() {
    this.AutocompleteService = new this.maps.places.AutocompleteService();
  }

  initPlacesService() {
    this.mapElement = document.createElement("div");
    this.PlacesService = new this.maps.places.PlacesService(this.mapElement);
  }

  initDirectionService() {
    this.DirectionsService = new this.maps.DirectionsService();
  }

  /**
   * NOTE: This should be debounced by at least 1 second to prevent Google API rate limiting
   * @param text from an address search input
   * @returns {Promise<unknown>}
   */
  getAutoCompletePredictions = (text) => {
    return new Promise((resolve, reject) => {
      if (!text) {
        return reject("Need valid text input");
      }

      try {
        this.AutocompleteService.getPlacePredictions({ input: text, types: ["geocode"] }, resolve);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * @param placeId is a place_id returned from the selected autocomplete service prediction
   * @returns {Promise<unknown>}
   */
  getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      if (!placeId) {
        return reject("Need valid google place id");
      }

      try {
        this.PlacesService.getDetails(
          {
            placeId,
          },
          resolve
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Set the address that the user searched for so it can be used by other helper functions.
   * @param addrObj
   */
  setSearchLocation = (addrObj) => {
    this.searchLocation = addrObj;
  };
}
