import React from "react";
import axios from "axios";

import { Button, Modal, Form, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckboxInput, SearchSelectInput, SelectInput, TextInput } from "../../../global/form";
import { toast } from "react-toastify";
import { Loader } from "react-bootstrap-typeahead";
import ConnectPOSIntegrationModal from "../../../global/modals/pos-integrations/ConnectPOSIntegrationModal";
import DisconnectPOSIntegrationModal from "../../../global/modals/pos-integrations/DisconnectPOSIntegrationModal";

class ChowlyIntegrationModal extends React.Component {
  state = {
    chowlySettings: {},
    showConnectWarning: false,
  };

  componentDidMount() {
    const { selectedCustomer } = this.props;
    if (selectedCustomer) {
      this.getChowlySettings();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getChowlySettings();
    }
  }

  getChowlySettings = async () => {
    const { selectedCustomer } = this.props;
    try {
      const res = await axios.get("/admin/getChowlyConnectionStatus?customer_id=" + selectedCustomer?.customer_id);
      this.setState({
        chowlySettings: res.data,
        allLocations: res.data.locations,
      });
    } catch (error) {
      if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Trouble retrieving Chowly settings, please try again or contact Bbot.");
        console.error("getChowlyConnectionStatus failed with error", error);
      }
    }
  };

  connectChowly = async (values, deleteMenuItems) => {
    const { selectedCustomer, onClose, onSave } = this.props;

    const payload = {
      customer_id: selectedCustomer?.customer_id,
      delete_menu_items: deleteMenuItems,
      manage_menu_in_bbot: values.integrationType === "virtual-restaurants",
      inject_orders: values.injectOrders,
      api_key: values.api_key,
      sdk_key: values.sdk_key,
      partner_id: values.partner_id,
      import_menu_availability_schedules: values.importMenuAvailabilitySchedules,
      integration_type: values.integrationType,
      display_checkout_info_on_ticket: values.displayCheckoutInfoOnTicket,
      desired_fulfillment_method: values.desiredFulfillmentMethod?.value ?? "",
      desired_location: values.desiredLocation?.value ?? "",
      display_platform_kds: values.displayPlatformKDS,
      display_platform_printed: values.displayPlatformPrinted,
      display_email_kds: values.displayEmailKDS,
      display_email_printed: values.displayEmailPrinted,
      display_name_kds: values.displayNameKDS,
      display_name_printed: values.displayNamePrinted,
      display_address_kds: values.displayAddressKDS,
      display_address_printed: values.displayAddressPrinted,
      display_phone_kds: values.displayPhoneKDS,
      display_phone_printed: values.displayPhonePrinted,
    };

    const endpoint = "/admin/connectChowly";

    this.setState({
      loadingData: true,
    });

    try {
      let res = await axios.post(endpoint, payload);

      toast.success("Successfully connected Chowly.");
      this.setState({
        chowlySettings: res.data,
        showConnectWarning: false,
      });

      onSave();
      onClose();
    } catch (error) {
      if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Trouble connecting to Chowly, please try again or contact Bbot.");
        console.error(endpoint, " failed with error", error);
      }
    }

    this.setState({
      loadingData: false,
      showConnectWarning: false,
    });
  };

  disconnectChowly = async (deleteMenuItems) => {
    const { onClose, onSave, selectedCustomer } = this.props;
    const endpoint = "/admin/disconnectChowly";

    this.setState({
      loadingData: true,
    });

    let payload = {
      delete_menu_items: deleteMenuItems,
      customer_id: selectedCustomer?.customer_id,
    };

    try {
      await axios.post(endpoint, payload);
      toast.success("Successfully disconnected Chowly.");
      this.setState({
        chowlySettings: {},
        showConnectWarning: false,
      });
    } catch (error) {
      if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Trouble disconnecting Chowly, please try again or contact Bbot.");
        console.error(endpoint, " failed with error", error);
      }
    }
    onSave();
    onClose();

    this.setState({
      loadingData: false,
    });
  };

  disconnectModal = () => {
    const { onClose, show } = this.props;

    return (
      <DisconnectPOSIntegrationModal
        onHide={onClose}
        show={show}
        disconnectFunc={this.disconnectChowly}
        onClose={onClose}
        integrationSourceName={"Chowly"}
      />
    );
  };

  locationValidForFulfillmentMethod = (locationId, fulfillmentMethod) => {
    const { allLocations } = this.state;

    if (!locationId || locationId === "auto") {
      return true;
    }

    let deliveryFulfillmentMethods = ["driver_delivery", "patron_choice"];
    let pickupFulfillmentMethods = ["pickup_nofeedback", "patron_pickup", "patron_choice"];

    if (fulfillmentMethod === "driver_delivery") {
      return deliveryFulfillmentMethods.includes(allLocations[locationId].fulfillment_method);
    } else if (fulfillmentMethod === "patron_pickup") {
      return pickupFulfillmentMethods.includes(allLocations[locationId].fulfillment_method);
    }
    // Default case of Auto fulfillment method will inject as pickup
    return pickupFulfillmentMethods.includes(allLocations[locationId].fulfillment_method);
  };

  getLocationOptions = (desiredFulfillmentMethod) => {
    const { allLocations } = this.state;

    let locationOptions = Object.keys(allLocations)
      ?.filter((l) =>
        this.locationValidForFulfillmentMethod(allLocations[l].locationId, desiredFulfillmentMethod.value)
      )
      .map((l) => ({
        value: allLocations[l].locationId,
        label: allLocations[l].locationName,
      }));
    locationOptions.push({
      value: "auto",
      label: "Auto",
    });

    locationOptions.sort((a, b) => (a.label === "Auto" ? -2 : a.label > b.label ? 1 : -1));

    return locationOptions;
  };

  getSelectedLocation = (selectedLocationId) => {
    return this.getLocationOptions({ value: "auto" }).filter((o) => o.value === selectedLocationId)[0];
  };

  getSelectedFulfillmentMethod = (fulfillmentMethod) => {
    if (fulfillmentMethod === "patron_pickup") {
      return { value: "patron_pickup", label: "Pickup" };
    } else if (fulfillmentMethod === "driver_delivery") {
      return { value: "driver_delivery", label: "Delivery" };
    }
    return { value: "auto", label: "Auto" };
  };

  editSettingsModal = () => {
    const { chowlySettings } = this.state;

    const { show, onClose } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik
          initialValues={{
            api_key: chowlySettings?.api_key ?? "",
            sdk_key: chowlySettings?.sdk_key ?? "",
            partner_id: chowlySettings?.partner_id ?? "",
            manage_menu_in_bbot: chowlySettings.manage_menu_in_bbot ?? false,
            importMenuAvailabilitySchedules: chowlySettings.import_menu_availability_schedules ?? true,
            injectOrders: chowlySettings.inject_orders ?? false,
            integrationType: chowlySettings.integration_type ?? "virtual-restaurants",
            desiredFulfillmentMethod: this.getSelectedFulfillmentMethod(chowlySettings.desired_fulfillment_method),
            desiredLocation: chowlySettings.desired_location
              ? this.getSelectedLocation(chowlySettings.desired_location)
              : null,
            displayCheckoutInfoOnTicket: chowlySettings?.display_checkout_info_on_ticket ?? true,
            displayPlatformKDS: chowlySettings?.display_platform_kds ?? true,
            displayPlatformPrinted: chowlySettings?.display_platform_printed ?? true,
            displayEmailKDS: chowlySettings?.display_email_kds ?? false,
            displayEmailPrinted: chowlySettings?.display_email_printed ?? false,
            displayNameKDS: chowlySettings?.display_name_kds ?? true,
            displayNamePrinted: chowlySettings?.display_name_printed ?? true,
            displayAddressKDS: chowlySettings?.display_address_kds ?? false, //Address is off by default before allowing config
            displayAddressPrinted: chowlySettings?.display_address_printed ?? false,
            displayPhoneKDS: chowlySettings?.display_phone_kds ?? true,
            displayPhonePrinted: chowlySettings?.display_phone_printed ?? true,
          }}
          validationSchema={Yup.object({
            partner_id: Yup.string().required("Partner ID is required."),
          })}
          onSubmit={(values) => {
            if (values.integrationType === "online-ordering") {
              if (chowlySettings.integration_type != null) {
                this.connectChowly(values, false);
              } else {
                this.setState({
                  showConnectWarning: true,
                  onlineOrderingValues: values,
                });
              }
            } else {
              this.connectChowly(values, false);
            }
          }}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Chowly POS Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row>
                  <SelectInput name={"integrationType"} id={"integration-type"} label={"Integration Type"}>
                    <option value={"virtual-restaurants"} key={"virtual-restaurants"}>
                      {"Virtual Restaurants"}
                    </option>
                    <option value={"online-ordering"} key={"online-ordering"}>
                      {"Online Ordering"}
                    </option>
                  </SelectInput>
                </Form.Row>
                {values.integrationType === "online-ordering" && (
                  <Form.Row>
                    <TextInput label={"API Key for injecting orders to Chowly"} name={"api_key"} id={"api-key"} />
                  </Form.Row>
                )}
                {values.integrationType === "virtual-restaurants" && (
                  <Form.Row>
                    <TextInput label={"SDK Key for pushing menus to Chowly"} name={"sdk_key"} id={"sdk-key"} />
                  </Form.Row>
                )}
                <Form.Row>
                  <TextInput label={"Chowly Partner ID"} name={"partner_id"} id={"partner-id"} />
                </Form.Row>
                {values.integrationType === "virtual-restaurants" && (
                  <Form.Row>
                    <SearchSelectInput
                      label={"Desired Fulfillment Method for Third Party Orders"}
                      className={"desired-fulfillment-input"}
                      name="desiredFulfillmentMethod"
                      id={"desiredFulfillmentMethod"}
                      onChange={(option) => {
                        setFieldValue("desiredFulfillmentMethod", option);
                        if (!this.locationValidForFulfillmentMethod(values.desiredLocation?.value, option.value)) {
                          setFieldValue("desiredLocation", null);
                        }
                      }}
                      value={values.desiredFulfillmentMethod}
                      defaultValue={values.desiredFulfillmentMethod}
                      options={[
                        { value: "auto", label: "Auto" },
                        { value: "patron_pickup", label: "Pickup" },
                        { value: "driver_delivery", label: "Delivery" },
                      ]}
                    ></SearchSelectInput>
                  </Form.Row>
                )}
                {values.integrationType === "virtual-restaurants" && (
                  <Form.Row>
                    <SearchSelectInput
                      label={"Desired Location Third Party Orders"}
                      className={"desired-location"}
                      name="desiredLocation"
                      id={"desiredLocation"}
                      key={`desired-location${values.desiredLocation}`} // Sort of a hack here to force this component to re-render when the value is changed from the outside
                      md={12}
                      isSearchable
                      options={this.getLocationOptions(values.desiredFulfillmentMethod)}
                      placeholder={"Auto Select Location"}
                      value={values.desiredLocation}
                      defaultValue={values.desiredLocation}
                      onChange={(value) => setFieldValue("desiredLocation", value)}
                    ></SearchSelectInput>
                  </Form.Row>
                )}
                {values.integrationType === "virtual-restaurants" && (
                  <>
                    <Form.Row>
                      <Col>
                        <div className={"margin-bottom-1"}>Display Fields on Tickets</div>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={4}>
                        <div>Platform Name</div>
                      </Col>
                      <Col md={4}>
                        <CheckboxInput label={"KDS"} name="displayPlatformKDS" id="display-platform-on-kds-checkbox" />
                      </Col>
                      <Col>
                        <CheckboxInput
                          label={"Printed"}
                          name="displayPlatformPrinted"
                          id="display-platform-on-printed-checkbox"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={4}>
                        <div>Patron Name</div>
                      </Col>
                      <Col md={4}>
                        <CheckboxInput label={"KDS"} name="displayNameKDS" id="display-name-on-kds-checkbox" />
                      </Col>
                      <Col>
                        <CheckboxInput
                          label={"Printed"}
                          name="displayNamePrinted"
                          id="display-name-on-printed-checkbox"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={4}>
                        <div>Patron Email</div>
                      </Col>
                      <Col md={4}>
                        <CheckboxInput label={"KDS"} name="displayEmailKDS" id="display-email-on-kds-checkbox" />
                      </Col>
                      <Col>
                        <CheckboxInput
                          label={"Printed"}
                          name="displayEmailPrinted"
                          id="display-email-on-printed-checkbox"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={4}>
                        <div>Patron Phone</div>
                      </Col>
                      <Col md={4}>
                        <CheckboxInput label={"KDS"} name="displayPhoneKDS" id="display-phone-on-kds-checkbox" />
                      </Col>
                      <Col>
                        <CheckboxInput
                          label={"Printed"}
                          name="displayPhonePrinted"
                          id="display-phone-on-printed-checkbox"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col md={4}>
                        <div>Patron Address</div>
                      </Col>
                      <Col md={4}>
                        <CheckboxInput label={"KDS"} name="displayAddressKDS" id="display-address-on-kds-checkbox" />
                      </Col>
                      <Col>
                        <CheckboxInput
                          label={"Printed"}
                          name="displayAddressPrinted"
                          id="display-address-on-printed-checkbox"
                        />
                      </Col>
                    </Form.Row>
                  </>
                )}
                {values.integrationType === "online-ordering" && (
                  <Form.Row>
                    <CheckboxInput sm={12} label="Inject orders into Chowly" name="injectOrders" id="inject-orders" />
                  </Form.Row>
                )}
                {values.integrationType === "online-ordering" && (
                  <Form.Row>
                    <CheckboxInput
                      label={"Import Chowly Menu Availability Schedules"}
                      type={"checkbox"}
                      name={"importMenuAvailabilitySchedules"}
                      id={"importMenuAvailabilitySchedules"}
                    />
                  </Form.Row>
                )}
                {values.integrationType === "online-ordering" && (
                  <Form.Row>
                    <CheckboxInput
                      label={"Display Extra Checkout Info on Ticket"}
                      type={"checkbox"}
                      name={"displayCheckoutInfoOnTicket"}
                      id={"displayCheckoutInfoOnTicket"}
                    />
                  </Form.Row>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    this.setState({
                      showConnectWarning: false,
                    });
                    onClose();
                  }}
                  className={"margin-right-1"}
                  disabled={this.state.loadingData}
                >
                  Cancel
                </Button>
                {this.state.loadingData ? (
                  <Button size={"sm"} type={"submit"} disabled={true}>
                    <Loader props />
                  </Button>
                ) : (
                  <Button size={"sm"} type={"submit"} disabled={Object.keys(errors).length !== 0}>
                    Submit
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  connectModal = () => {
    const { onClose, show } = this.props;

    return (
      <ConnectPOSIntegrationModal
        integrationSourceName={"Chowly"}
        onHide={onClose}
        onSubmit={() => {
          this.connectChowly(this.state.onlineOrderingValues, true);
        }}
        show={this.state.showConnectWarning && show}
      />
    );
  };

  render() {
    const { disconnectMode } = this.props;
    const { showConnectWarning } = this.state;

    if (disconnectMode) {
      return this.disconnectModal();
    }

    return showConnectWarning ? this.connectModal() : this.editSettingsModal();
  }
}

export default ChowlyIntegrationModal;
