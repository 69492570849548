import React from "react";
import DismissibleButton from "components/owner-app/dismissable-button/DismissibleButton";
import { Card } from "bbot-component-library";
import styled from "styled-components";
import BulkEditableSetting from "components/owner-app/BulkEditableSetting";

import DropdownIcon from "assets/svgs/menu-display-configurations/dropdown.svg";
import SliderIcon from "assets/svgs/menu-display-configurations/slider.svg";

const MenusTab = (props) => {
  const {
    bulkEditMode,
    menus,
    updateLocationSettings,
    updateCustomMenuProperties,
    removeChanges,
    removeCustomMenuChanges,
    defaultLocationSettings,
    locations,
  } = props;

  const { menu_display_configuration } = defaultLocationSettings.custom_menu_properties;

  const getNumSyncedLocations = (menuId) => {
    return locations.filter((location) => location.menus.includes(menuId)).length;
  };

  const bulkEditButtonText = "Edit For Selected Locations";

  return (
    <div>
      <div className={"margin-bottom-6"}>
        <div className={"margin-bottom-6"}>
          <div className={"margin-bottom-2"}>
            <h4>Menu Display Configuration</h4>
            <p>
              How will menus at this location be displayed on mobile devices?{" "}
              <a href={"https://central.bbot.menu/article/1102-new-editing-location-codes-menus"}>More info here.</a>
            </p>
          </div>

          <Card className={"menu-display-configuration-container margin-bottom-4"}>
            <DismissibleButton
              buttonText={"Edit for Selected Locations"}
              dismissedInitially={!bulkEditMode}
              onClickCallback={() => {
                updateCustomMenuProperties({
                  menu_display_configuration: menu_display_configuration,
                });
              }}
              onCancelCallback={() => {
                removeCustomMenuChanges(["menu_display_configuration"]);
              }}
            >
              <MenuDisplayConfigurationContainer>
                <div className={"margin-right-2"}>
                  <input
                    type="radio"
                    className={"margin-right-1"}
                    id="dropdown"
                    name="menu_display_configuration"
                    value="dropdown"
                    defaultChecked={!menu_display_configuration || menu_display_configuration === "dropdown"}
                    onChange={() =>
                      updateCustomMenuProperties({
                        menu_display_configuration: "dropdown",
                      })
                    }
                  />
                  <label htmlFor="dropdown">Display Menus in Drop-Down Format</label>
                  <img alt="" src={DropdownIcon} />
                </div>
                <div>
                  <input
                    type="radio"
                    className={"margin-right-1"}
                    id="slider"
                    name="menu_display_configuration"
                    value="slider"
                    defaultChecked={menu_display_configuration === "slider"}
                    onChange={() =>
                      updateCustomMenuProperties({
                        menu_display_configuration: "slider",
                      })
                    }
                  />
                  <label htmlFor="slider">Display Menus Side-by-Side in Slider Format</label>
                  <img alt="" src={SliderIcon} />
                </div>
              </MenuDisplayConfigurationContainer>
            </DismissibleButton>
          </Card>

          <div className={"margin-bottom-2"}>
            <h4>Linked Menus</h4>
            <p>
              Which menus are offered here?{" "}
              <a href={"https://central.bbot.menu/article/514-editing-locations-menus"}>More info here.</a>
            </p>
          </div>

          <Card>
            <div className={"margin-bottom-2"}>
              {Object.entries(menus).map((menuEntry, index) => (
                <BulkEditableSetting
                  key={`bulk-editable-setting-menus-${menuEntry[0]}`}
                  title={menuEntry[1]}
                  description={
                    <span> {getNumSyncedLocations(menuEntry[0])} locations currently have this menu linked.</span>
                  }
                  inBulkEditMode={bulkEditMode}
                  bulkEditButtonText={bulkEditButtonText}
                  onBulkEditInitial={(toggleChecked) => {
                    updateLocationSettings({
                      [menuEntry[0]]: toggleChecked ? "add" : "remove",
                    });
                  }}
                  onBulkEditCancel={() => {
                    removeChanges([menuEntry[0]], "menus");
                  }}
                  toggleConfig={{
                    defaultEnabled: defaultLocationSettings?.menus?.includes(menuEntry[0]),
                    onClick: (e) => {
                      updateLocationSettings({
                        [menuEntry[0]]: e.SWITCH_STATE.enabled ? "add" : "remove",
                      });
                    },
                  }}
                />
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const MenuDisplayConfigurationContainer = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }

  > div {
    &:last-of-type {
      margin-top: 40px;
    }

    @media (min-width: 992px) {
      max-width: 400px;
      width: 50%;

      &:last-of-type {
        margin-top: 0;
      }
    }
  }

  img {
    height: 40px;
    margin: 10px 0 0 24px;
    object-fit: contain;
    object-position: left;
    padding-right: 20px;
    width: 100%;
  }

  input {
    cursor: pointer;
    display: inline-block;
    margin-right: -20px;
    margin-top: 5px;
    vertical-align: top;
  }

  label {
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;
    margin-right: -20px;
  }
`;

export default MenusTab;
