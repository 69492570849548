import React, { useState } from "react";
import Moment from "react-moment";
import { Tag } from "antd";

import AddPCBModal from "components/owner-app/modals/printer-configuration/AddPCBModal";
import EditPCBModal from "components/owner-app/modals/printer-configuration/EditPCBModal";

import PencilIcon from "assets/svgs/icons/icon-pencil.svg";
import { BbotTable } from "bbot-component-library";

const PrinterControlBoxesCard = ({ printerControlBoxes, selectedCustomer, network, onSaveCallback, userInfo }) => {
  const [selectedPCB, setSelectedPCB] = useState(null);
  const [showAddPCBModal, setShowAddPCBModal] = useState(false);
  const [showEditPCBModal, setShowEditPCBModal] = useState(false);

  const getPillVariant = (secondsSincePoll) => {
    if (secondsSincePoll && secondsSincePoll <= 120) {
      return "success";
    } else if (secondsSincePoll && secondsSincePoll <= 240) {
      return "warning";
    }
    return "error";
  };

  const getStatusTag = (secondsSincePoll) => {
    return (
      <Tag color={getPillVariant(secondsSincePoll)}>
        {secondsSincePoll ? (
          <>
            <Moment fromNow>{Date.now() - secondsSincePoll * 1000}</Moment>
          </>
        ) : (
          <>Offline</>
        )}
      </Tag>
    );
  };

  return (
    <>
      <BbotTable
        id={"printer-configuration-controlbox-table"}
        title={"Printer Control Boxes"}
        manuallyFormattedColumns={[
          { title: "Name", dataIndex: "name" },
          { title: "Status", dataIndex: "status" },
          { title: "", dataIndex: "edit_icon", width: "10%" },
        ]}
        data={printerControlBoxes.map((box) => ({
          ...box,
          status: getStatusTag(box.seconds_since_poll),
          edit_icon: <img alt="edit icon" className="icon xx-small" src={PencilIcon} />,
        }))}
        addButtonText={"Add a New PCB"}
        onAdd={() => setShowAddPCBModal(true)}
        manualOnRow={(controlBox) => ({
          onClick: () => {
            setSelectedPCB(controlBox);
            setShowEditPCBModal(true);
          },
        })}
        showCountByTitle={false}
      />
      <AddPCBModal
        showAddPCBModal={showAddPCBModal}
        onHideCallback={() => setShowAddPCBModal(false)}
        onSaveCallback={() => {
          setShowAddPCBModal(false);
          onSaveCallback();
        }}
        network={network}
        customerId={selectedCustomer?.customer_id}
        customerName={selectedCustomer?.customer_name}
        userInfo={userInfo}
      />
      <EditPCBModal
        showEditPCBModal={showEditPCBModal}
        onHideCallback={() => setShowEditPCBModal(false)}
        onSaveCallback={() => {
          onSaveCallback();
        }}
        network={network}
        selectedPCB={selectedPCB}
        customerId={selectedCustomer?.customer_id}
        userInfo={userInfo}
      />
    </>
  );
};

export default PrinterControlBoxesCard;
