import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { BbotTable } from "bbot-component-library";

const PrinterControlBoxesAndVersionStats = ({ printerControlBoxes }) => {
  const [versionSummary, setVersionSummary] = useState([]);
  const [filterDate, setFilterDate] = useState();

  const getVersionSummary = () => {
    let filteredControlBoxes = printerControlBoxes;
    if (filterDate) {
      filteredControlBoxes = printerControlBoxes.filter((box) => {
        return Date.now() - box.time_since_heartbeat * 1000 >= filterDate;
      });
    }

    let versionSummary = filteredControlBoxes?.reduce((versionCounts, box) => {
      let version = 7;
      if (box.current_version) {
        version = box.current_version;
      }
      return (versionCounts[version] = (versionCounts[version] || 0) + 1), versionCounts;
    }, {});

    let formattedVersionSummary = Object.keys(versionSummary).map((key) => {
      return { version: key, total_on_version: versionSummary[key], key: key };
    });

    setVersionSummary(formattedVersionSummary.sort((a, b) => b.version - a.version));
  };

  useEffect(() => {
    getVersionSummary();
  }, [printerControlBoxes, filterDate]);

  const pcbFilter = (
    <DatePicker
      format={"YYYY/MM/DD"}
      disabledDate={(date) => date > Date.now()}
      onChange={(date) => {
        setFilterDate(date);
      }}
      placeholder={"Polled Since"}
    />
  );

  return (
    <BbotTable
      id={"pcb-software-release-table"}
      title={"PCB Version Data"}
      titleRight={pcbFilter}
      columns={["Version", "Total on Version"]}
      data={versionSummary}
      interactive={false}
      showCountByTitle={false}
      bordered
    />
  );
};

export default PrinterControlBoxesAndVersionStats;
